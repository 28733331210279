import { getPlanningById, searchPlannings } from '@/domain/planning/Planning.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const planningKeys = createQueryKeys('planning', {
    search: (...optionalParam: Parameters<typeof searchPlannings>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    getById: (...params: Parameters<typeof getPlanningById>) => ({
        queryKey: params,
    }),
});

export const useGetPlannings = createQueryHook(planningKeys.search, searchPlannings);
export const useGetPlanningById = createRequiredParamsQueryHook(planningKeys.getById, getPlanningById);
