import { DatePickerLocalizationProvider } from '@/app-entry-point/DatePickerLocalizationProvider';
import { useFieldBackspace } from '@/components/use-field-backspace/useFieldBackspace';
import { getCurrentLocalDate, getTimeFormatFromDate, isValid, setTime } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import { TimeField as MuiTimeField, TimeFieldProps, TimeValidationError } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { forwardRef } from 'react';

const TIME_SEPARATOR = ':';
const TIME_FORMAT = `HH${TIME_SEPARATOR}mm`;

export type TimeFieldWrapperProps = Overwrite<
    TimeFieldProps<Date>,
    {
        value?: Nullable<LocalTime>;
        onChange?: (value: LocalTime | null, context: FieldChangeHandlerContext<TimeValidationError>) => void;
    }
>;
/**
 * TimeFieldWrapper is a wrapper around MuiTimeField which manage value as LocalTime
 * /!\ Works only when the field is controlled
 */
export const TimeFieldWrapper = forwardRef<HTMLDivElement, TimeFieldWrapperProps>((props, ref) => {
    const { value, onChange, slotProps, ...rest } = props;
    const dateTime = value ? setTime(getCurrentLocalDate(), value) : value;

    const { selectedSections, setSelectedSections, handleBackspace } = useFieldBackspace({
        valueFormat: TIME_FORMAT,
        separator: TIME_SEPARATOR,
    });

    const handleChange = (date: Date | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
        if (!date) {
            onChange?.(getNull(), context);
            return;
        }

        // case invalid date
        // we just check if is a valid date, we let the form handle valid time
        if (!isValid(date)) {
            // workaround to simulate invalid time as LocalTime
            // we need this case to let the field displays what the user typed
            // otherwise, the field will be totally cleared when the user clear a part of the time (for example, the minutes)
            onChange?.('invalid:time' as LocalTime, context);
            return;
        }

        onChange?.(getTimeFormatFromDate(date), context);
    };

    return (
        <DatePickerLocalizationProvider>
            <MuiTimeField
                ref={ref}
                ampm={false}
                format={TIME_FORMAT}
                value={dateTime}
                {...(onChange
                    ? {
                          onChange: handleChange,
                      }
                    : {})}
                slotProps={{
                    ...slotProps,
                    textField: {
                        ...slotProps?.textField,
                        onKeyDown: handleBackspace,
                    },
                }}
                selectedSections={selectedSections}
                onSelectedSectionsChange={setSelectedSections}
                {...rest}
            />
        </DatePickerLocalizationProvider>
    );
});

TimeFieldWrapper.displayName = 'TimeFieldWrapper';

export type DateTimeFieldWrapperProps = TimeFieldProps<Date>;

/**
 * DateTimeFieldWrapper is a wrapper around MuiTimeField which manage value as Date in case of we need to
 * save the time and the date
 * /!\ Works only when the field is controlled
 */
export const DateTimeFieldWrapper = forwardRef<HTMLDivElement, DateTimeFieldWrapperProps>((props, ref) => {
    const { slotProps, ...restProps } = props;

    const { selectedSections, setSelectedSections, handleBackspace } = useFieldBackspace({
        valueFormat: TIME_FORMAT,
        separator: TIME_SEPARATOR,
    });

    return (
        <DatePickerLocalizationProvider>
            <MuiTimeField
                ref={ref}
                ampm={false}
                {...restProps}
                slotProps={{
                    ...slotProps,
                    textField: {
                        ...slotProps?.textField,
                        onKeyDown: handleBackspace,
                    },
                }}
                selectedSections={selectedSections}
                onSelectedSectionsChange={setSelectedSections}
            />
        </DatePickerLocalizationProvider>
    );
});

DateTimeFieldWrapper.displayName = 'DateTimeFieldWrapper';
