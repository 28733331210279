import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { canApproveRejectProfileUpdatePendingRequest, hasAccessOrganizationChartPolicy } from '@/domain/permission/Permission.service';
import { Page } from '@/page/Pages';

export const getPeoplePages = (employeeId: number, policies: EmployeePolicy[]): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'people.tabs.directory',
            path: '/people/directory',
        },
        {
            labelKey: 'people.tabs.organization_chart',
            path: '/people/organization-chart',
            condition: () => hasAccessOrganizationChartPolicy(policies),
        },
        {
            labelKey: 'people.tabs.profile_update_pending_request',
            path: '/people/employee-requests',
            condition: () => canApproveRejectProfileUpdatePendingRequest(policies, employeeId),
        },
        // Feature not terminated so we hide the page in the menu https://rogerhr.atlassian.net/browse/RP-5726
        // {
        //     labelKey: 'people.tabs.employment-mass-edit',
        //     path: '/people/employment-mass-edit',
        //     condition: () => canManageOtherEmployeeContracts(policies, employeeId),
        // },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(policies));
};
