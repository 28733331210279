import { ColorList } from '@/components/color-list/ColorList';
import { ColorPickerPopover } from '@/components/color-picker-popover/ColorPickerPopover';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldText } from '@/components/form/field-text/FieldText';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    open: boolean;
    handleSave: (data: AreaForm) => void;
    onClose: () => void;
    area?: AreaForm & { id?: number | string };
};

type AreaForm = {
    name: string;
    color: string;
};

const schema = yup.object().shape({
    name: yup.string().trim().required(),
    color: yup.string().trim().required(),
});

export const AreaDialog: FC<Props> = ({ open, handleSave, onClose, area }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { control, handleSubmit } = useForm<AreaForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: area?.name ?? '',
            color: area?.color ?? theme.palette.primary.main,
        },
    });

    const onSave = (data: AreaForm) => {
        handleSave(data);
    };

    const handleCloseDialog = () => {
        onClose();
    };

    return (
        <DialogWrapper open={open} onClose={handleCloseDialog} header={area?.id ? t('locations_page.add_area') : t('locations_page.edit_area')} maxWidth='xs'>
            <Stack component={DialogContent} gap={2}>
                <FormControlLabel label={t('locations_page.name')} control={<FieldText name='name' control={control} fullWidth />} />

                <Typography variant='body2'>{t('planning_setting_page.color')}</Typography>
                <Controller
                    name='color'
                    control={control}
                    render={({ field }) => (
                        <Stack direction='row'>
                            <ColorList color={field.value} onChangeColor={field.onChange} />
                            <ColorPickerPopover color={field.value} onChangeColor={field.onChange} />
                        </Stack>
                    )}
                />
            </Stack>
            <DialogActions>
                <Button fullWidth onClick={() => handleSubmit(onSave, console.error)()}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
