import { getSkill, searchSkills } from '@/domain/skill/Skill.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const reviewSkillKeys = createQueryKeys('reviewSkills', {
    search: (...optionalParam: Parameters<typeof searchSkills>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    getById: (...params: Parameters<typeof getSkill>) => ({
        queryKey: params,
    }),
});

export const useGetReviewSkills = createQueryHook(reviewSkillKeys.search, searchSkills);

export const useGetReviewSkill = createRequiredParamsQueryHook(reviewSkillKeys.getById, getSkill);
