import { cloneElement, ReactElement, useState } from 'react';

export const useOpenDialog = (
    children: ReactElement | undefined,
    open: boolean,
    onClose?: () => void,
): {
    childrenWithOnOpen: ReactElement | undefined;
    handleInternalClose: () => void;
    handleClose: () => void;
    isOpen: boolean;
} => {
    const [internalOpen, setInternalOpen] = useState(open);

    const childrenWithOnOpen = children
        ? cloneElement(children, {
              onClick: (e: MouseEvent) => {
                  // Use React.MouseEvent
                  e.stopPropagation();
                  setInternalOpen(true);
              },
          })
        : undefined;

    const handleInternalClose = () => setInternalOpen(false);

    const handleClose = onClose ?? handleInternalClose;
    const isOpen = onClose ? open : internalOpen;

    return { childrenWithOnOpen, handleInternalClose, handleClose, isOpen };
};
