import { BreadCrumbItem } from '@/routes/PageContainer';
import { Realm } from '@/domain/realm/Realm.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';

export type UiState = {
    declineLeaveRequestDialogLeaveRequest: LeaveRequest | undefined;

    snackbar: SnackbarState;

    currentRealm: Realm | undefined;
    expandMenu: boolean;

    breadCrumb: BreadCrumbItem[];
};

const initialState: UiState = {
    declineLeaveRequestDialogLeaveRequest: undefined,

    snackbar: { open: false, message: '', severity: 'success' },

    currentRealm: undefined,
    expandMenu: true,

    breadCrumb: [],
};

/**
 * @deprecated remove this from the store
 */
export enum UiActionType {
    SNACKBAR_CHANGED = 'SNACKBAR_CHANGED',
    REALM_LOADED = 'REALM_LOADED',
    EXPAND_MENU = 'EXPAND_MENU',
    BREADCRUMB = 'BREADCRUMB',
    INITIALIZE_STATE = 'INITIALIZE_STATE',
}

export interface Breadcrumb {
    type: UiActionType.BREADCRUMB;
    breadCrumb: BreadCrumbItem[];
}

export interface SnackbarChanged {
    type: UiActionType.SNACKBAR_CHANGED;
    snackbar: SnackbarState;
}

export interface RealmLoaded {
    type: UiActionType.REALM_LOADED;
    realm?: Realm;
}

export interface ExpandMenu {
    type: UiActionType.EXPAND_MENU;
    expand: boolean;
}

export const uiSlice = createSlice({
    name: 'ui',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        initializeUIState: (_, action: PayloadAction<UiState>) => {
            return { ...action.payload };
        },
    },
    extraReducers: builder => {
        // This is a workaround to be able to use the old reducer in the new slice
        // to avoid having to change all dispatch places
        builder.addDefaultCase((state, action) => {
            return oldReducer(state, action as UiActions);
        });
    },
});

export const { initializeUIState } = uiSlice.actions;

export const uiReducer = uiSlice.reducer;

export type UiActions = SnackbarChanged | RealmLoaded | ExpandMenu | Breadcrumb;

/**
 * @deprecated
 * @returns
 */
function oldReducer(state = initialState, action: UiActions): UiState {
    switch (action.type) {
        case UiActionType.BREADCRUMB:
            return {
                ...state,
                breadCrumb: action.breadCrumb,
            };
        case UiActionType.SNACKBAR_CHANGED:
            return {
                ...state,
                snackbar: action.snackbar,
            };
        case UiActionType.EXPAND_MENU:
            return {
                ...state,
                expandMenu: action.expand,
            };
        case UiActionType.REALM_LOADED:
            return {
                ...state,
                currentRealm: action.realm,
            };
        default:
            return state;
    }
}

interface SnackbarState {
    message: string;
    open: boolean;
    severity: 'success' | 'error';
}
