import { FC } from 'react';

import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { DocumentType } from '@/domain/document/Document.model';
import { useTranslation } from 'react-i18next';

type Props = {
    documentType: DocumentType;
    onDeleteClicked?: () => void;
    onDownloadClicked: () => void;
    onPreviewClicked: () => void;
    onEditClicked?: () => void;
};

export const DocumentMoreButton: FC<Props> = ({ documentType, onDeleteClicked, onDownloadClicked, onPreviewClicked, onEditClicked }) => {
    const { t } = useTranslation();

    const menuListItems: BasicMenuItem[] = [
        {
            title: t('general.preview'),
            onClick: onPreviewClicked,
            hide: documentType !== 'DOCUMENT',
        },
        {
            title: t('general.edit'),
            onClick: onEditClicked,
        },
        {
            title: documentType === 'LINK' ? t('general.open_in_new_tab') : t('general.download'),
            onClick: onDownloadClicked,
        },
        {
            title: t('general.delete'),
            onClick: onDeleteClicked,
            confirmationRequired: true,
            hide: !onDeleteClicked,
        },
    ];

    return <BasicMenu items={menuListItems} id='user-menu' />;
};
