import { TotalPieChart } from '@/components/chart/TotalPieChart';
import { Select } from '@/components/form/field-select/Select';
import { FullScreenProgress } from '@/components/full-screen-progress/FullScreenProgress';
import { ClampedTypography } from '@/components/typography/ClampedTypography';
import {
    AnswerResult,
    getSurveyCategoryTranslationKey,
    SURVEY_QUESTION_CATEGORIES,
    SurveyQuestion,
    SurveyQuestionCategory,
    SurveyResultFilterType,
    SurveyResults,
} from '@/domain/survey/Survey.model';
import {
    COLORS_SCHEME,
    getResults,
    isLickertScale,
    isNPS,
    isOpinionScale,
    isRenderAsMultipleChoice,
    isRenderAsSection,
    isRenderAsSingleChoice,
    isRenderAsText,
} from '@/domain/survey/Survey.service';
import { useLocalStorage } from '@/hooks/Storage.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { calculatePercentage } from '@/utils/math.util';
import { Box, IconButton, LinearProgress, MenuItem, Paper, SelectChangeEvent, Select as SelectMui, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { InformationCircleIcon, ViewIcon, ViewOffIcon } from 'hugeicons-react';
import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ChoiceResult } from './survey-results/ChoiceResult';
import { SurveyCategories } from './survey-results/SurveyCategories';
import { TextResults } from './survey-results/TextResults';
import { ScaleResult } from '@/page/survey/survey-results/ScaleResult';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

interface CategoriesChartDataList {
    x: string;
    y: number;
}

const GROUPS = [
    SurveyResultFilterType.ALL,
    SurveyResultFilterType.AGE,
    SurveyResultFilterType.GENDER,
    SurveyResultFilterType.DEPARTMENT,
    SurveyResultFilterType.LOCATION,
];

type SurveyResultsPageParams = {
    surveyId: string;
};

export const SurveyResultsPage: FC = () => {
    const { t } = useTranslation();
    const { surveyId } = useParams<SurveyResultsPageParams>();

    const [groupBy, setGroupBy] = useState<SurveyResultFilterType>(SurveyResultFilterType.ALL);
    const [surveyResults, setSurveyResults] = useState<SurveyResults>();
    const [activeTab, setActiveTab] = useState<number>(SURVEY_RESULT_SUMMARY_TAB);
    const [activeCategories, setActiveCategories] = useState<AnswerResult[]>([]);
    const [activeCategory, setActiveCategory] = useState<SurveyQuestionCategory>();
    const [filtersByGroup, setFiltersByGroup] = useState<string[]>();
    const [activeGroupByFilters, setActiveGroupByFilters] = useState<string[]>([]);
    const [annotationNote, setAnnotationNote] = useState<string>();
    const [tableCellId, setTableCellId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [displayMessages, setDisplayMessages] = useLocalStorage<boolean>('Survey.display_comments', true);

    // if there is only one category, set it as active or if all categories are other (meaning, no categories), set other as active
    useEffect(() => {
        const isOtherCategory = !!surveyResults?.answerResults.some(result => !result.question.category);
        const isEveryResultAreOther = !!surveyResults?.answerResults.every(result => !result.question.category);
        const isEveryResultAreSameCategory = !!surveyResults?.answerResults.every(
            result => result.question.category === surveyResults.answerResults[0].question.category,
        );

        if (isEveryResultAreSameCategory && !isOtherCategory) {
            setActiveCategory(surveyResults?.answerResults[0].question.category);
        }

        if (isEveryResultAreOther) {
            setActiveCategory(SurveyQuestionCategory.OTHER);
        }
    }, [surveyResults, activeTab]);

    useEffect(() => {
        setLoading(true);
        const doesCategoriesMatched = (value: AnswerResult, index: number, self: AnswerResult[]) =>
            index === self.findIndex(ar => ar.question.category === value.question.category);
        getResults(Number(surveyId))
            .then(data => {
                setSurveyResults(data);
                const categories = data.answerResults.filter(doesCategoriesMatched);
                setActiveCategories(categories);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [surveyId]);

    const renderHeatMapChart = useCallback(() => {
        const comparedCategoriesWithParticipations =
            surveyResults?.categories[groupBy].filter(category =>
                surveyResults?.participations[groupBy].some(participation => category.key === participation.key),
            ) ?? [];
        const otherGroupRemovedFromCategories =
            comparedCategoriesWithParticipations.filter(item => {
                return (
                    item.key !== 'OTHER' &&
                    (!activeGroupByFilters?.length || activeGroupByFilters.includes(item.key) || activeGroupByFilters.includes(getLabelTranslation(item.label)))
                );
            }) ?? [];

        return otherGroupRemovedFromCategories.map(group => {
            const listOfCategories: CategoriesChartDataList[] = [];

            const newCategory = {
                id: getLabelTranslation(group.label),
                data: listOfCategories,
            };
            SURVEY_QUESTION_CATEGORIES.forEach(category => {
                // HasOwn is not available with the current target version (ES2021)
                // Fix when changing the target version to ES2022 or higher
                if (Object.prototype.hasOwnProperty.call(group.categoryAverageScore, category)) {
                    const categoryValue = {
                        x: category,
                        y: group.categoryAverageScore[category],
                        yColor: 'red',
                    };
                    newCategory.data.push(categoryValue);
                }
            });
            return newCategory;
        });
    }, [activeGroupByFilters, groupBy, surveyResults?.categories, surveyResults?.participations]);

    if (!surveyResults) {
        return;
    }

    const changeTab = (_event: SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
        setActiveCategory(undefined);
    };
    const onSelectGroupBy = (event: SelectChangeEvent<SurveyResultFilterType>) => {
        setGroupBy(event.target.value as SurveyResultFilterType);
        setActiveGroupByFilters([]);
        if (event.target.value !== SurveyResultFilterType.ALL) {
            if (surveyResults.answerResults?.length) {
                const groupByFilters = surveyResults.participations[event.target.value as SurveyResultFilterType]
                    .map(question => question.label.translationEn ?? '')
                    .filter(t => t !== '');
                setFiltersByGroup(groupByFilters);
            }
        } else {
            setFiltersByGroup(undefined);
        }
    };

    const showGroupByTranslationKey = (group: SurveyResultFilterType) => {
        switch (group) {
            case SurveyResultFilterType.ALL:
                return t('survey_results.group_by_all');
            case SurveyResultFilterType.LOCATION:
                return t('survey_results.location');
            case SurveyResultFilterType.GENDER:
                return t('survey_results.gender');
            case SurveyResultFilterType.DEPARTMENT:
                return t('survey_results.department');
            case SurveyResultFilterType.AGE:
                return t('survey_results.age');
            default:
                break;
        }
    };

    const resultTab = (index: number) => {
        return {
            id: `results-tab-${index}`,
            'aria-controls': `results-tabpanell-${index}`,
        };
    };

    const renderQuestions = (questions: SurveyQuestion[], activeCategory: SurveyQuestionCategory | undefined) => {
        if (questions?.length) {
            if (activeCategory) {
                if (activeCategory === SurveyQuestionCategory.OTHER) {
                    return questions.filter(q => !q.category);
                }

                return questions.filter(q => q.category === activeCategory);
            } else {
                return questions.sort((a, b) => {
                    return a.order - b.order;
                });
            }
        } else {
            return [];
        }
    };

    const renderAnswerResult = (question: SurveyQuestion, answerResults: AnswerResult[]) => {
        const answer = answerResults?.find(a => a.question.id === question.id);
        if (!answer) {
            return undefined;
        }

        if (isRenderAsText(question.type)) {
            return (
                <TextResults
                    title={question.question}
                    activeGroupByFilters={activeGroupByFilters}
                    answer={answer}
                    groupBy={groupBy}
                    displayMessage={displayMessages}
                />
            );
        } else if (isRenderAsSection(question.type)) {
            return <Typography variant='h2'>{getLabelTranslation(question.question)}</Typography>;
        } else if (isLickertScale(question.type) || isOpinionScale(question.type) || isNPS(question.type)) {
            return <ScaleResult title={question.question} activeGroupByFilters={activeGroupByFilters} groupBy={groupBy} answer={answer} />;
        } else if (isRenderAsSingleChoice(question.type)) {
            return <ChoiceResult activeGroupByFilters={activeGroupByFilters} answer={answer} groupBy={groupBy} title={question.question} />;
        } else if (isRenderAsMultipleChoice(question.type)) {
            return <ChoiceResult activeGroupByFilters={activeGroupByFilters} answer={answer} groupBy={groupBy} title={question.question} />;
        } else {
            return undefined;
        }
    };

    const setCategory = (category: SurveyQuestionCategory | undefined) => {
        setActiveCategory(category);
    };

    if (loading) {
        return <FullScreenProgress />;
    }

    const totalHeatMapHeight = () => {
        const CELL_HEIGHT = 44;
        const SPACE_AROUND_CHART = 125;
        return renderHeatMapChart() ? renderHeatMapChart().filter(item => item.data.length).length * CELL_HEIGHT + SPACE_AROUND_CHART : 0;
    };

    if (!surveyResults) {
        return <></>;
    }
    const otherBar = {
        width: '100%',
        height: 24,
        borderRadius: 4,
        backgroundColor: '#F8F8F7',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'rgba(1, 21, 60, 0.5)',
        },
    };

    const progressBar = {
        width: '100%',
        height: 31,
        borderRadius: 4,
        backgroundColor: '#F8F8F7',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'rgba(1, 205, 205, 0.5)',
        },
    };

    const totalPieChartDescription = {
        fontStyle: 'italic',
        marginTop: 0.5,
        paddingRight: 3,
    };

    return (
        <Stack gap={2}>
            <Stack component={Paper} elevation={1} p={3}>
                <Stack direction={'column'}>
                    <Typography variant='h2'>{getLabelTranslation(surveyResults.survey.name)}</Typography>
                    <Typography variant='body1'>{getLabelTranslation(surveyResults.survey.description)}</Typography>
                </Stack>
            </Stack>

            <Stack component={Paper} elevation={1} p={3}>
                <Tabs value={activeTab} centered onChange={changeTab} scrollButtons>
                    <Tab label={t('survey_results.summary')} {...resultTab(SURVEY_RESULT_SUMMARY_TAB)} />
                    <Tab label={t('survey_results.all_answers')} {...resultTab(SURVEY_RESULT_ANSWERS_TAB)} />
                    <Tab label={t('survey_results.participation')} {...resultTab(SURVEY_RESULT_PARTICIPATION_TAB)} />
                </Tabs>

                <Stack direction={'row'} alignItems='center' justifyContent='center' gap={1} p={1}>
                    <SelectMui value={groupBy} onChange={onSelectGroupBy}>
                        {GROUPS.map((group: SurveyResultFilterType) => (
                            <MenuItem key={group} value={group}>
                                {showGroupByTranslationKey(group)}
                            </MenuItem>
                        ))}
                    </SelectMui>

                    {filtersByGroup &&
                        // We don't want to apply filters when tab is participation
                        activeTab !== SURVEY_RESULT_PARTICIPATION_TAB && (
                            <Select
                                multiple
                                options={filtersByGroup}
                                value={activeGroupByFilters}
                                autocompleteProps={{
                                    style: { width: 300 },
                                }}
                                fullWidth
                                getOptionLabel={option => option}
                                onChange={setActiveGroupByFilters}
                                placeholder={`Select ${showGroupByTranslationKey(groupBy)}`}
                            />
                        )}

                    <Stack direction='row' flex='1' justifyContent='flex-end' display='flex' alignItems='center'>
                        <Typography>{displayMessages ? t('survey_results.hide_comments') : t('survey_results.display_comments')}</Typography>
                        <IconButton onClick={() => setDisplayMessages(!displayMessages)} size='large'>
                            {displayMessages ? <ViewIcon /> : <ViewOffIcon />}
                        </IconButton>
                    </Stack>
                </Stack>

                {activeTab === SURVEY_RESULT_SUMMARY_TAB && (
                    <Stack gap={2}>
                        {groupBy === SurveyResultFilterType.ALL ? (
                            <SurveyCategories results={surveyResults} activeCategory={activeCategory} categories={activeCategories} setCategory={setCategory} />
                        ) : (
                            <Box
                                style={{
                                    width: '99%',
                                    height: totalHeatMapHeight(),
                                    margin: 'auto',
                                    minWidth: 0,
                                }}
                            >
                                <ResponsiveHeatMap
                                    data={renderHeatMapChart() ? renderHeatMapChart().filter(item => item.data.length) : []}
                                    margin={{ top: 60, right: 30, bottom: 60, left: 208 }}
                                    valueFormat='>-.2s'
                                    xInnerPadding={0.05}
                                    theme={{
                                        text: {
                                            fill: '#585869',
                                            fontSize: 14,
                                        },
                                    }}
                                    yInnerPadding={0.2}
                                    legends={[]}
                                    onClick={cell => {
                                        const surveyQuestionCat = SURVEY_QUESTION_CATEGORIES.find(category => category === cell.data.x);
                                        setTableCellId(cell.id);
                                        setAnnotationNote(`${cell.serieId} in ${t(getSurveyCategoryTranslationKey(surveyQuestionCat))}`);
                                        setActiveGroupByFilters([cell.serieId]);
                                        setCategory(surveyQuestionCat);
                                    }}
                                    axisTop={{
                                        format: v => {
                                            return t(getSurveyCategoryTranslationKey(v));
                                        },
                                        tickSize: 0,
                                        tickPadding: 8,
                                        tickRotation: 0,
                                        legend: '',
                                        legendOffset: -60,
                                    }}
                                    axisLeft={{
                                        truncateTickAt: 22,
                                        tickSize: 0,
                                        tickPadding: 8,
                                        tickRotation: 0,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: -60,
                                    }}
                                    colors={{
                                        type: 'diverging',
                                        scheme: 'red_yellow_green',
                                        divergeAt: 0.5,
                                        minValue: 0,
                                        maxValue: 10,
                                    }}
                                    annotations={[
                                        {
                                            type: 'rect',
                                            match: {
                                                id: tableCellId,
                                            },
                                            note: annotationNote ?? '',
                                            noteX: -5,
                                            noteY: -45,
                                            offset: 3,
                                            noteTextOffset: 5,
                                            borderRadius: 2,
                                        },
                                    ]}
                                    emptyColor='#555555'
                                    borderRadius={5}
                                />
                            </Box>
                        )}
                    </Stack>
                )}

                <ResultTabPanel value={activeTab} index={SURVEY_RESULT_PARTICIPATION_TAB}>
                    {groupBy !== SurveyResultFilterType.ALL ? (
                        <Stack gap={2}>
                            <Typography variant='body1bold'>{t('survey_results.participation')}</Typography>
                            <Stack flex={1} gap={3}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <ClampedTypography variant='body2' width={150} />
                                    <Stack width='100%'>
                                        <LinearProgress
                                            variant='determinate'
                                            sx={progressBar}
                                            value={calculatePercentage(
                                                surveyResults.participations.ALL[0].participated,
                                                surveyResults.participations.ALL[0].invited,
                                            )}
                                        />
                                    </Stack>
                                    <Stack direction='row' justifyContent={'end'} width={150}>
                                        <Typography variant='body1bold'>
                                            {surveyResults.participations.ALL[0].participated} /
                                            <span style={{ fontWeight: 400 }}> {surveyResults.participations.ALL[0].invited}</span>{' '}
                                            {calculatePercentage(surveyResults.participations.ALL[0].participated, surveyResults.participations.ALL[0].invited)}
                                            %
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack flex={1} gap={1}>
                                    {surveyResults.participations[groupBy]?.map((participation, index) =>
                                        participation.key === 'OTHER' ? (
                                            <Stack direction={'row'} key={participation.key} justifyContent={'space-between'} alignItems={'center'}>
                                                <Typography
                                                    variant='body2'
                                                    sx={{
                                                        '& svg': {
                                                            verticalAlign: 'middle',
                                                            marginLeft: 0.5,
                                                        },
                                                    }}
                                                    width={200}
                                                >
                                                    {getLabelTranslation(participation.label)}
                                                    <Tooltip title={t('survey_results.less_5_answers')}>
                                                        <InformationCircleIcon />
                                                    </Tooltip>
                                                </Typography>
                                                <Stack width='100%'>
                                                    <LinearProgress
                                                        variant='determinate'
                                                        sx={otherBar}
                                                        value={calculatePercentage(participation.participated, participation.invited)}
                                                    />
                                                </Stack>
                                                <Stack direction='row' justifyContent={'end'} width={150}>
                                                    <Typography variant='body1bold'>
                                                        {participation.participated} /<span style={{ fontWeight: 400 }}> {participation.invited}</span>{' '}
                                                        {calculatePercentage(participation.participated, participation.invited)}%
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        ) : (
                                            <Stack direction={'row'} key={participation.key} justifyContent={'space-between'} alignItems={'center'}>
                                                <ClampedTypography variant='body2' width={200} ellipsis={2}>
                                                    {getLabelTranslation(participation.label)}
                                                </ClampedTypography>

                                                <Stack width='100%'>
                                                    <LinearProgress
                                                        variant='determinate'
                                                        sx={{
                                                            ...otherBar,
                                                            '& .MuiLinearProgress-barColorPrimary': {
                                                                backgroundColor: COLORS_SCHEME[index < COLORS_SCHEME.length ? index : 0].color,
                                                            },
                                                        }}
                                                        value={calculatePercentage(participation.participated, participation.invited)}
                                                    />
                                                </Stack>
                                                <Stack direction='row' justifyContent={'end'} width={150}>
                                                    <Typography variant='body1bold'>
                                                        {participation.participated} /<span style={{ fontWeight: 400 }}> {participation.invited}</span>{' '}
                                                        {calculatePercentage(participation.participated, participation.invited)}%
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        ),
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack gap={2} alignItems='center'>
                            <TotalPieChart
                                data={{
                                    title: t('survey_results.participation'),
                                    average: {
                                        title: t('survey_results.participated'),
                                        value: surveyResults.participations.ALL[0].participated,
                                    },
                                    total: {
                                        title: t('survey_results.not_participated'),
                                        value: surveyResults.participations.ALL[0].invited,
                                    },
                                }}
                            />
                            <Typography variant='body2' sx={{ totalPieChartDescription }}>
                                {t('survey_results.this_indicate_how_many_people')}
                            </Typography>
                        </Stack>
                    )}
                </ResultTabPanel>
            </Stack>

            {(activeTab === SURVEY_RESULT_ANSWERS_TAB || (activeTab === SURVEY_RESULT_SUMMARY_TAB && activeCategory)) && (
                <Stack gap={2}>
                    {!!surveyResults.survey?.questions?.length &&
                        renderQuestions(surveyResults.survey?.questions, activeCategory).map(question => (
                            <Stack key={question.id}>{renderAnswerResult(question, surveyResults.answerResults)}</Stack>
                        ))}
                </Stack>
            )}
        </Stack>
    );
};

const ResultTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role='tabpanel' hidden={value !== index} id={`results-tabpanel-${index}`} aria-labelledby={`results-tab-${index}`} {...other}>
            {value === index && (
                <Box p={0}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const SURVEY_RESULT_SUMMARY_TAB = 0;
const SURVEY_RESULT_ANSWERS_TAB = 1;
const SURVEY_RESULT_PARTICIPATION_TAB = 2;
