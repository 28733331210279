import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Select } from '@/components/form/field-select/Select';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNull } from '@/utils/object.util';

type OnSelectValue<T> = { onSelectValue: (selectedValue: T) => void; multiple?: false } | { onSelectValue: (selectedValue: T[]) => void; multiple: true };
type SelectResourceDialogProps<T> = {
    options: { id: number; label: string; value: T }[];
    label: string;
    isLoadingOptions?: boolean;
} & OnSelectValue<T> &
    Omit<DialogWrapperProps, 'onSave'>;

export const SelectResourceDialog = <T extends { id: number }>({
    options,
    onSelectValue,
    label,
    multiple,
    isLoadingOptions,
    ...rest
}: SelectResourceDialogProps<T>): JSX.Element => {
    const { t } = useTranslation();
    const [selectedResource, setSelectedResource] = useState<T | T[] | null>(multiple ? [] : getNull());

    const handleSave = () => {
        if (!selectedResource) {
            return;
        }
        // inference will be smart with typescript 5.8 and we will not need to cast selectedResource
        return multiple ? onSelectValue(selectedResource as T[]) : onSelectValue(selectedResource as T);
    };

    return (
        <DialogWrapper {...rest}>
            <Stack gap={2} component={DialogContent}>
                <FormControlLabel
                    label={label}
                    control={
                        <Select
                            fullWidth
                            options={options.map(option => option.value)}
                            getOptionLabel={option => options.find(o => o.id === option.id)?.label ?? ''}
                            onChange={selectedOption => setSelectedResource(selectedOption)}
                            autocompleteProps={{
                                autoFocus: true,
                            }}
                            multiple={multiple}
                            loading={isLoadingOptions}
                        />
                    }
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSave} disabled={!selectedResource} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
