import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Button } from '@mui/material';
import { showSnackbar } from '@/utils/snackbar.util';
import { deleteExpenseCategory } from '@/domain/expense-category/ExpenseCategory.service';
import { useGetExpenseCategoryById } from '@/hooks/expense-category/ExpenseCategory.hook';
import { useGetExpenseTaxes } from '@/hooks/expense-tax/ExpenseTax.hook';
import { handleError } from '@/utils/api.util';
import { Footer } from '@/page/layout/Footer';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { ExpenseCategorySettingsForm } from '@/page/setting/expense/expense-category/ExpenseCategorySettingsForm';
import { CompanySettingsSkeleton } from '@/page/setting/CompanySettingsSkeleton';
import { DeleteConfirmDialog } from '@/components/delete-confirmation-dialog/DeleteConfirmDialog';
import { getLabelTranslation } from '@/utils/language.util';
import { useGetExpenseSetting } from '@/hooks/expense-setting/ExpenseSetting.hook';

export const ExpenseCategorySettingsFormPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { categoryId: categoryIdParam } = useParams<{ categoryId: string }>();
    const isCreateMode = !categoryIdParam || categoryIdParam === 'new';
    const categoryId = isCreateMode ? undefined : Number(categoryIdParam);
    const {
        data: category,
        isLoading: isCategoryLoading,
        error: categoryError,
        isError: isCategoryError,
    } = useGetExpenseCategoryById(categoryId, { enabled: !isCreateMode });
    const { data: expenseSetting, isLoading: isExpenseSettingLoading, error: expenseSettingError, isError: isExpenseSettingError } = useGetExpenseSetting();
    const {
        data: taxes = [],
        isLoading: isExpenseTaxesLoading,
        error: expenseTaxesError,
        isError: isExpenseTaxesError,
    } = useGetExpenseTaxes(undefined, { enabled: expenseSetting?.expenseTaxesEnabled });

    const handleDeleteCategory = async (categoryId: number | undefined) => {
        if (!categoryId) {
            return;
        }

        try {
            await deleteExpenseCategory(categoryId);
            showSnackbar(t('expense_category_settings_page.success.category_deleted'), 'success');
            navigate('/settings/expenses/categories');
        } catch (error) {
            handleError(error);
        }
    };

    const isLoading = isCategoryLoading ?? isExpenseTaxesLoading ?? isExpenseSettingLoading;
    const error = categoryError ?? expenseTaxesError ?? expenseSettingError;
    const isError = isCategoryError ?? isExpenseTaxesError ?? isExpenseSettingError;

    const taxesList = expenseSetting?.expenseTaxesEnabled ? taxes : [];

    return (
        <StateHandler isLoading={isLoading} error={error} isError={isError} loadingComponent={<CompanySettingsSkeleton />}>
            <ExpenseCategorySettingsForm category={category} taxes={taxesList} />
            <Footer>
                {!isCreateMode && (
                    <DeleteConfirmDialog
                        onConfirm={() => handleDeleteCategory(categoryId)}
                        header={t('expense_category_settings_page.delete_dialog.title')}
                        textContent={t('expense_category_settings_page.delete_dialog.confirmation_text', { categoryName: category?.name })}
                        fieldName={getLabelTranslation(category?.name)}
                    >
                        <Button variant='outlined' color='error' aria-label={t('general.delete')}>
                            {t('general.delete')}
                        </Button>
                    </DeleteConfirmDialog>
                )}

                <Button variant='contained' color='primary' aria-label={t('general.save')} type={'submit'} form={'expense-category-form'}>
                    {t('general.save')}
                </Button>
            </Footer>
        </StateHandler>
    );
};
