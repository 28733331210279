import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakPoints = (): { isMobile: boolean; isDesktop: boolean } => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = !isMobile;

    return { isMobile, isDesktop };
};
