import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { SectionFieldContainer } from '../SectionFieldContainer';
import { SectionContainer } from '../SectionContainer';
import { SectionDefinition, SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { SectionDefinitionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { getFormValueByFieldType } from '@/domain/section-setting/Section.service';
import { EmployeeBasicInfoFieldType } from '@/domain/employee/EmployeeFields.model';
import { getLabelTranslation } from '@/utils/language.util';
import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { SectionField } from '@/components/section/types';
import { OnboardingProfileStepFormValues } from '@/page/people/onboarding/employee/OnboardingEmployeeForm.schema';

type BasicInfosSectionProps = {
    baseProfileFormValues: Partial<OnboardingProfileStepFormValues>;
    sectionDefinition: SectionDefinition;
};

export const BasicInfosSection: FC<BasicInfosSectionProps> = ({ baseProfileFormValues, sectionDefinition }) => {
    const { t } = useTranslation();

    const basicInfosSectionFields = mapFormValuesToBasicInfo(baseProfileFormValues, sectionDefinition);

    // Hide the display name field in the onboarding form
    const hiddenFields: SectionFieldDefinition['fieldType'][] = ['EMPLOYEE_DISPLAY_NAME'];
    const basicInfoOnboardingFields = basicInfosSectionFields.filter(field => !hiddenFields.includes(field.fieldType));

    return (
        <SectionContainer title={t('employee.sections.basic_info')}>
            {basicInfoOnboardingFields?.map(basicInfosSectionField => {
                return (
                    <SectionFieldContainer
                        key={basicInfosSectionField.title}
                        formValueName={basicInfosSectionField.formValueName ?? ''}
                        title={basicInfosSectionField.title ?? ''}
                        required={basicInfosSectionField.required}
                    >
                        <EditableSectionFieldComponent field={basicInfosSectionField} />
                    </SectionFieldContainer>
                );
            })}
        </SectionContainer>
    );
};

const mapFormValuesToBasicInfo = (formValues: Partial<SectionDefinitionFormValues>, sectionDefinition: SectionDefinition): SectionField[] => {
    return sectionDefinition.fields.map(fieldDefinition => {
        const employeeBasicInfoFieldType = fieldDefinition.fieldType as EmployeeBasicInfoFieldType;

        let fieldValue = undefined;

        switch (employeeBasicInfoFieldType) {
            case 'EMPLOYEE_FIRSTNAME':
            case 'EMPLOYEE_EMAIL':
            case 'EMPLOYEE_LASTNAME':
                fieldValue = getFormValueByFieldType({
                    sectionDefinition,
                    fieldType: employeeBasicInfoFieldType,
                    formValues,
                    mandatory: true,
                });
                break;
            case 'EMPLOYEE_MAIDEN_NAME':
            case 'EMPLOYEE_DISPLAY_NAME':
            case 'EMPLOYEE_PHONE_NUMBER':
            case 'EMPLOYEE_CODE':
                fieldValue =
                    getFormValueByFieldType({
                        sectionDefinition,
                        fieldType: employeeBasicInfoFieldType,
                        formValues,
                    }) ?? '';
        }

        return {
            fieldDefinitionId: fieldDefinition.id,
            formValueName: fieldDefinition.formId,
            title: getLabelTranslation(fieldDefinition.name),
            valueType: fieldDefinition.valueType,
            [getFieldValueProperty(fieldDefinition.valueType)]: fieldValue,
            required: fieldDefinition.mandatory,
            fieldType: employeeBasicInfoFieldType,
            order: fieldDefinition.order,
            disabled: false,
        };
    });
};
