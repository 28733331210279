import { SkillCategory, SkillCategorySearch } from '@/domain/skill-category/SkillCategory.model';
import { getSkillCategory, searchSkillCategories } from '@/domain/skill-category/SkillCategory.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const skillCategoryKeys = createQueryKeys('skillCategories', {
    search: (...optionalParam: Parameters<typeof searchSkillCategories>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    getById: (...params: Parameters<typeof getSkillCategory>) => ({
        queryKey: params,
    }),
});

export const useGetSkillCategories = createQueryHook<SkillCategory[], SkillCategorySearch>(skillCategoryKeys.search, searchSkillCategories);

export const useGetSkillCategory = createRequiredParamsQueryHook(skillCategoryKeys.getById, getSkillCategory);
