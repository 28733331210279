import { Paper, Stack, StackProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelTranslation } from '@/utils/language.util';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';
import { Job } from '@/domain/job/Job.model';
import { canViewEmployeeJobDescription } from '@/domain/permission/Permission.service';
import { useCurrentPolicies } from '@/stores/store';

type FeedbackJobsProps = {
    jobs: Job[];
    employeeId: number;
} & StackProps;

export const FeedbackJobs: FC<FeedbackJobsProps> = ({ jobs, employeeId, ...rest }) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();

    if (!canViewEmployeeJobDescription(policies, employeeId)) {
        return <></>;
    }

    return (
        <Stack component={Paper} p={2} {...rest}>
            <Stack gap={2}>
                <Typography variant='h2'>{t('reviews.review_summary.review_jobs_title')}</Typography>

                <Stack gap={3}>
                    {jobs.map(job => (
                        <Stack key={job.id} gap={2}>
                            <Typography variant={'h2'}>{getLabelTranslation(job.name)}</Typography>

                            <Stack gap={1}>
                                {(!!job.jobFamily?.name || getLabelTranslation(job.jobLevel?.name)) && (
                                    <Stack gap={0.5} direction={'row'}>
                                        <Typography variant={'h3'} color={'text.secondary'}>
                                            {job.jobFamily?.name}
                                        </Typography>
                                        {getLabelTranslation(job.jobLevel?.name) && job.jobFamily?.name && (
                                            <Typography color={'text.secondary'}> {' - '}</Typography>
                                        )}
                                        <Typography variant={'h3'} color={'text.secondary'}>
                                            {getLabelTranslation(job.jobLevel?.name)}
                                        </Typography>
                                    </Stack>
                                )}
                                {job.purpose && (
                                    <Stack gap={0.5}>
                                        <Typography variant={'h3'}>{t('job_description.purpose')}</Typography>
                                        <Typography>{getLabelTranslation(job.purpose)}</Typography>
                                    </Stack>
                                )}

                                <Stack gap={0.5}>
                                    <Typography variant={'h3'}>{t('job_description.responsibilities')}</Typography>
                                    {job.responsibility ? (
                                        <RichTextTypography>{getLabelTranslation(job.responsibility)}</RichTextTypography>
                                    ) : (
                                        <Typography>{t('job_description.no_responsibilities')}</Typography>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
};
