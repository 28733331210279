import { Button, DialogActions, DialogContent, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { batchUnassignLeaveTypePolicy, unassignLeaveTypePolicy } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { EmployeeLeaveTypePolicy, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { handleError } from '@/utils/api.util';
import { getCurrentLocalDate, isValidDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';

type Props = {
    userLeaveTypePolicies: EmployeeLeaveTypePolicy[];
    onPolicyUnassigned: (newPolicies: EmployeeLeaveTypePolicy[]) => void;
    employeeIds: number[];
    employeeId?: number;
    onClose: () => void;
};

const defaultEndDate: LocalDate = '1990-01-01';

export const UnassignPolicyDialog: FC<Props> = ({ onPolicyUnassigned, userLeaveTypePolicies, employeeIds, employeeId, onClose }) => {
    const { t } = useTranslation();

    const [selectedLeaveType, setSelectedLeaveType] = useState<LeaveType>();
    const [withCustomEndDate, setWithCustomEndDate] = useState<boolean>(false);

    const [endDate, setEndDate] = useState<LocalDate>(getCurrentLocalDate());

    const { data: leaveTypes = [], error } = useGetLeaveTypes();

    const isFormValid = () => {
        return selectedLeaveType;
    };

    const onSave = () => {
        if (error || !selectedLeaveType || !endDate) {
            return;
        }

        const endDateToUse = withCustomEndDate ? endDate : defaultEndDate;

        if (employeeId) {
            unassignLeaveTypePolicy(employeeId, selectedLeaveType, endDateToUse)
                .then(userLeaveTypePolicies => {
                    showSnackbar(t('my_profile.messages.policy_unassigned'), 'success');
                    onPolicyUnassigned(userLeaveTypePolicies);
                    onClose();
                })
                .catch(handleError);
        }
        if (employeeIds.length) {
            batchUnassignLeaveTypePolicy(employeeIds, selectedLeaveType.id, endDateToUse)
                .then(() => {
                    showSnackbar(t('manage_people_page.messages.users_un_assigned'), 'success');
                    onClose();
                })
                .catch(handleError);
        }
    };

    const allLeaveTypes = userLeaveTypePolicies.map(ult => ult.leaveType);
    const uniqueLeaveTypes: LeaveType[] = [...new Map(allLeaveTypes.map(item => [item.id, item])).values()];
    const displayLeaveTypes = leaveTypes ?? [];
    const displayUserAssignedLeaveTypes = uniqueLeaveTypes;

    return (
        <DialogWrapper open={true} onClose={() => onClose()} header={t('user_leave_type_dialog.unassign_policy')}>
            <Stack gap={1} component={DialogContent}>
                <InputLabel id='leaveType'>{t('user_leave_type_dialog.leave_type')}</InputLabel>
                <Select
                    labelId='leaveType'
                    id='leave_type_select'
                    value={selectedLeaveType?.id}
                    fullWidth
                    onChange={event => {
                        const value = event.target.value;
                        if (value) {
                            const newValue = uniqueLeaveTypes.find(lt => lt.id === value);
                            if (newValue) {
                                setSelectedLeaveType(newValue);
                            } else if (employeeIds) {
                                const newValue = displayLeaveTypes.find(lt => lt.id === value);
                                setSelectedLeaveType(newValue);
                            }
                        }
                    }}
                >
                    {!!employeeId &&
                        displayUserAssignedLeaveTypes.map(lt => {
                            return (
                                <MenuItem key={lt.id} value={lt.id}>
                                    {getLabelTranslation(lt.name)}
                                </MenuItem>
                            );
                        })}
                    {!!employeeIds?.length &&
                        displayLeaveTypes.map(lt => {
                            return (
                                <MenuItem key={lt.id} value={lt.id}>
                                    {getLabelTranslation(lt.name)}
                                </MenuItem>
                            );
                        })}
                </Select>
                <FormControlLabel
                    label={t('user_leave_type_dialog.end_date_switch')}
                    labelPlacement='end'
                    control={<Switch color='primary' onChange={event => setWithCustomEndDate(event.target.checked)} />}
                />
                {withCustomEndDate && (
                    <FormControlLabel
                        label={t('user_leave_type_dialog.end_date')}
                        labelPlacement='top'
                        control={
                            <DatePickerWrapper
                                value={endDate}
                                minDate={undefined}
                                onChange={newValue => {
                                    if (isValidDate(newValue)) {
                                        setEndDate(newValue);
                                    }
                                }}
                            />
                        }
                    />
                )}
            </Stack>
            <DialogActions>
                <Button onClick={onSave} disabled={!isFormValid()} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
