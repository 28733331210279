import { createQueryHook, defaultQueryOptions } from '@/page/Query.type';
import { getDefaultCurrency, searchExpenseCurrencies } from '@/domain/expense-currency/ExpenseCurrency.service';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const expenseCurrencyKeys = createQueryKeys('expenseCurrencies', {
    search: {
        queryKey: getNull(),
    },
    defaultCurrency: {
        queryKey: getNull(),
    },
});

export const useGetExpenseCurrencies = createQueryHook(expenseCurrencyKeys.search, searchExpenseCurrencies, defaultQueryOptions);
export const useGetDefaultCurrency = createQueryHook(expenseCurrencyKeys.defaultCurrency, getDefaultCurrency, defaultQueryOptions);
