import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { useBreakPoints } from '@/components/use-break-points/useBreakPoints';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { isCurrentEmployeeManager, startEmployeeReviewDiscussion } from '@/domain/employee-review/EmployeeReview.service';
import { Employee } from '@/domain/employee/Employee.model';
import { Progress } from '@/page/review/employee-review-progress/Progress';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type ViewProgressDialogProps = {
    onClose: () => void;
    employeeReview: EmployeeReview;
    currentEmployee: Employee;
};

export const ViewProgressDialog: FC<ViewProgressDialogProps> = ({ onClose, employeeReview, currentEmployee }) => {
    const { t } = useTranslation();
    const { isMobile } = useBreakPoints();
    const allowManagerToSkipPreparationStep = employeeReview?.review?.allowManagerToSkipPreparationStep;
    const navigate = useNavigate();
    const startDiscussionEnabled = isCurrentEmployeeManager(employeeReview, currentEmployee) && allowManagerToSkipPreparationStep;

    const onStartDiscussion = async (employeeReview: EmployeeReview) => {
        try {
            await startEmployeeReviewDiscussion(employeeReview.id);
            showSnackbar(t('reviews.review_summary.messages.discussion_started'), 'success');
            navigate(`/reviews/${employeeReview.id}/manager-summary`);
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <DialogWrapper open={true} onClose={onClose} header={t('reviews.employee_review.view_progress_modal.title')} maxWidth={'sm'}>
            <DialogContent
                sx={{
                    px: 3,
                    pb: isMobile ? 0 : 3,
                }}
            >
                <Progress employeeReview={employeeReview} currentEmployee={currentEmployee} />
            </DialogContent>
            {allowManagerToSkipPreparationStep && startDiscussionEnabled && (
                <DialogActions>
                    <Button onClick={() => onStartDiscussion(employeeReview)} color='primary'>
                        {t('reviews.start_discussion_dialog.save_button')}
                    </Button>
                </DialogActions>
            )}
        </DialogWrapper>
    );
};
