import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import {
    CreateExpenseCategoryGroupMutation,
    ExpenseCategoryGroup,
    UpdateExpenseCategoryGroupMutation,
} from '@/domain/expense-category-group/ExpenseCategoryGroup.model';

export type ExpenseCategoryGroupDTO = ExpenseCategoryGroup;

type CreateExpenseCategoryGroupRequestDTO = CreateExpenseCategoryGroupMutation;
type UpdateExpenseCategoryGroupRequestDTO = StrictOmit<UpdateExpenseCategoryGroupMutation, 'id'>;

const EXPENSE_CATEGORY_GROUP_API_BASE_PATH = API_BASE_URL + '/expense-category-groups';

const getAllExpenseCategoryGroups = async (): Promise<ExpenseCategoryGroup[]> => {
    const { data } = await client.get<ExpenseCategoryGroupDTO[]>(EXPENSE_CATEGORY_GROUP_API_BASE_PATH);
    return data;
};

const createExpenseCategoryGroup = async (mutation: CreateExpenseCategoryGroupMutation): Promise<ExpenseCategoryGroup> => {
    const { data } = await client.post<ExpenseCategoryGroupDTO, AxiosResponse<ExpenseCategoryGroupDTO>, CreateExpenseCategoryGroupRequestDTO>(
        EXPENSE_CATEGORY_GROUP_API_BASE_PATH,
        mutation,
    );
    return data;
};

const updateExpenseCategoryGroup = async ({ id, ...restMutation }: UpdateExpenseCategoryGroupMutation): Promise<ExpenseCategoryGroup> => {
    const { data } = await client.put<ExpenseCategoryGroupDTO, AxiosResponse<ExpenseCategoryGroupDTO>, UpdateExpenseCategoryGroupRequestDTO>(
        `${EXPENSE_CATEGORY_GROUP_API_BASE_PATH}/${id}`,
        restMutation,
    );
    return data;
};

const deleteExpenseCategoryGroup = async (expenseCategoryGroupId: number): Promise<void> => {
    await client.delete(`${EXPENSE_CATEGORY_GROUP_API_BASE_PATH}/${expenseCategoryGroupId}`);
};

export const expenseCategoryGroupApi = {
    getAllExpenseCategoryGroups,
    createExpenseCategoryGroup,
    updateExpenseCategoryGroup,
    deleteExpenseCategoryGroup,
};
