import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetThirdPartyIntegrationLogs } from '@/hooks/third-party/ThirdParty.hook';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ThirdPartyLogGrid } from '../third-party-log-grid/ThirdPartyLogGrid';
import { ThirdPartyConnector } from '../third-party-setting-page/ThirdPartySettingForm';

type ThirdPartyConnectorLogDialogProps = DialogWrapperProps & {
    connector: ThirdPartyConnector;
};

export const ThirdPartyConnectorLogDialog: FC<ThirdPartyConnectorLogDialogProps> = ({ connector, ...rest }) => {
    const { t } = useTranslation();
    const { data: thirdPartyIntegrationLogs = [], isFetching, error, isError, refetch } = useGetThirdPartyIntegrationLogs(connector.id ?? undefined);

    return (
        <DialogWrapper header={t('third_party.connector_logs.dialog_title')} fullScreen {...rest}>
            <StateHandler isLoading={isFetching} isError={isError} error={error} isEmpty={!thirdPartyIntegrationLogs.length}>
                <ThirdPartyLogGrid thirdPartyIntegrationLogs={thirdPartyIntegrationLogs} refetch={() => refetch()} />
            </StateHandler>
        </DialogWrapper>
    );
};
