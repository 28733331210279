import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    loading: boolean;
    onFindLoginMethodClick: (email: string) => void;
};

export const FindLoginMethod: FC<Props> = ({ loading, onFindLoginMethodClick }) => {
    const { t } = useTranslation();
    const emailMessage = t('general.forms.invalid_email');

    const schema = yup.object().shape({
        email: yup.string().email(emailMessage).required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
        },
    });

    return (
        <Stack direction='column' spacing={2}>
            <FormControlLabel
                label={t('login_form.email')}
                labelPlacement='top'
                control={<TextField fullWidth error={!!errors.email} helperText={errors.email?.message} {...register('email')} />}
            />
            <Button
                fullWidth={true}
                color='primary'
                variant='contained'
                loading={loading}
                loadingPosition={'start'}
                startIcon={<></>}
                type='submit'
                onClick={handleSubmit((data: { email: string }) => onFindLoginMethodClick(data?.email), console.error)}
            >
                {t('login_form.login')}
            </Button>
        </Stack>
    );
};
