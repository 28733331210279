import * as yup from 'yup';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { getLocalDateTestConfig, isFutureDate, isValidTime } from '@/utils/datetime.util';

import I18n from '@/i18n/i18n';
import { UserLanguage } from '@/utils/language.util';

export const surveySetupStartDateValidation = yup.string<LocalDate>().when('sendNotificationNow', {
    is: (sendNotificationNow: boolean) => !sendNotificationNow,
    then: schema =>
        schema
            .required()
            .test(getLocalDateTestConfig())
            .test({
                message: I18n.t('general.validations.future_date'),
                test: function (date) {
                    return date && isFutureDate(date);
                },
            }),
    otherwise: schema => schema.nullable(),
});

export const surveySetupEndDateValidation = yup
    .string<LocalDate>()
    .required()
    .test(getLocalDateTestConfig())
    .test({
        message: I18n.t('general.validations.future_date'),
        test: function (date) {
            return date && isFutureDate(date);
        },
    });

export const surveySetupStartTimeValidation = yup.string<LocalTime>().when('sendNotificationNow', {
    is: (sendNotificationNow: boolean) => !sendNotificationNow,
    then: schema =>
        schema.required().test({
            message: I18n.t('general.validations.valid_date'),
            test: time => isValidTime(time),
        }),
    otherwise: schema => schema.nullable(),
});

export const surveySetupEndTimeValidation = yup
    .string<LocalTime>()
    .required()
    .test({
        message: I18n.t('general.validations.valid_date'),
        test: time => isValidTime(time),
    });

export const surveySetupRecipientIdsValidation = yup
    .array()
    .of(yup.number().defined())
    .required()
    .min(
        1,
        I18n.t('general.validations.min_length', {
            length: 'one',
            item: I18n.t('general.employee').toLowerCase(),
        }),
    );

export const getSurveySetupFormSchema = (translationLanguage: UserLanguage) => {
    return yup.object().shape({
        surveyTemplateId: yup.number().required(),
        recipientIds: surveySetupRecipientIdsValidation,
        emailSubject: getLabelFormSchema(translationLanguage),
        emailBody: getLabelFormSchema(translationLanguage),
        sendNotificationNow: yup.boolean().required(),
        startDate: surveySetupStartDateValidation,
        endDate: surveySetupEndDateValidation,
        startTime: surveySetupStartTimeValidation,
        endTime: surveySetupEndTimeValidation,
    });
};

type getSurveySetupFormSchemaType = ReturnType<typeof getSurveySetupFormSchema>;
export type SurveySetupFormSchemaType = yup.InferType<getSurveySetupFormSchemaType>;
