import { Button, ButtonProps, Paper, Stack, StackProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export type FooterProps = StackProps;

export const Footer: FC<PropsWithChildren<FooterProps>> = ({ children, ...rest }) => {
    return (
        <Paper
            square
            component='footer'
            sx={{
                mx: -2,
                mb: -2,
                height: FOOTER_HEIGHT,
            }}
        >
            <Stack direction='row' justifyContent='flex-end' alignItems={'center'} gap={1} p={2} {...rest}>
                {children}
            </Stack>
        </Paper>
    );
};

export type FooterActionsProps = {
    actions: Overwrite<ButtonProps, { name: string }>[];
};

export const FooterActions: FC<FooterActionsProps> = ({ actions }) => {
    return actions.map(({ children, name, ...restAction }) => {
        return (
            <Button key={name} name={name} {...restAction}>
                {children}
            </Button>
        );
    });
};
// Set the height of the footer
const PADDING = 16;
const FOOTER_HEIGHT = 32 + PADDING * 2;
