import { getJobLevels } from '@/domain/job-level/JobLevel.service';
import { createQueryHook } from '@/page/Query.type';
import { getNull } from '@/utils/object.util';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const jobLevelKeys = createQueryKeys('jobLevels', {
    get: {
        queryKey: getNull(),
    },
});

export const useGetJobLevels = createQueryHook(jobLevelKeys.get, getJobLevels);
