import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import {
    CreateExpenseDistanceRateMutation,
    EditExpenseDistanceRateMutation,
    ExpenseDistanceRate,
    ExpenseDistanceRateSearch,
} from '@/domain/expense-distance-rate/ExpenseDistanceRate.model';

export type ExpenseDistanceRateDTO = ExpenseDistanceRate;
type ExpenseDistanceRateCreateRequestDTO = CreateExpenseDistanceRateMutation;
type ExpenseDistanceRateUpdateRequestDTO = EditExpenseDistanceRateMutation;
type ExpenseDistanceRateSearchRequestDTO = ExpenseDistanceRateSearch;

const mapExpenseDistanceRateDTO = (dto: ExpenseDistanceRateDTO): ExpenseDistanceRate => dto;

const mapUpdateMutationToDTO = (mutation: EditExpenseDistanceRateMutation): ExpenseDistanceRateUpdateRequestDTO => mutation;

const EXPENSE_DISTANCE_RATE_API_BASE_PATH = API_BASE_URL + '/expense-distance-rates';

const getExpenseDistanceRate = async (date: LocalDate): Promise<ExpenseDistanceRate> => {
    const { data } = await client.get<ExpenseDistanceRateDTO>(`${EXPENSE_DISTANCE_RATE_API_BASE_PATH}?date=${date}`);
    return mapExpenseDistanceRateDTO(data);
};

const searchExpenseDistanceRates = async (search: ExpenseDistanceRateSearch): Promise<ExpenseDistanceRate[]> => {
    const { data } = await client.post<ExpenseDistanceRateDTO[], AxiosResponse<ExpenseDistanceRateDTO[]>, ExpenseDistanceRateSearchRequestDTO>(
        `${EXPENSE_DISTANCE_RATE_API_BASE_PATH}/search`,
        search,
    );
    return data.map(mapExpenseDistanceRateDTO);
};

const createExpenseDistanceRate = async (mutation: CreateExpenseDistanceRateMutation): Promise<ExpenseDistanceRate> => {
    const { data } = await client.post<ExpenseDistanceRateDTO, AxiosResponse<ExpenseDistanceRateDTO>, ExpenseDistanceRateCreateRequestDTO>(
        EXPENSE_DISTANCE_RATE_API_BASE_PATH,
        mutation,
    );
    return mapExpenseDistanceRateDTO(data);
};

const updateExpenseDistanceRate = async (expenseDistanceRateId: number, mutation: EditExpenseDistanceRateMutation): Promise<ExpenseDistanceRate> => {
    const request = mapUpdateMutationToDTO(mutation);
    const { data } = await client.put<ExpenseDistanceRateDTO, AxiosResponse<ExpenseDistanceRateDTO>, ExpenseDistanceRateUpdateRequestDTO>(
        `${EXPENSE_DISTANCE_RATE_API_BASE_PATH}/${expenseDistanceRateId}`,
        request,
    );
    return mapExpenseDistanceRateDTO(data);
};

const deleteExpenseDistanceRate = async (expenseDistanceRateId: number): Promise<void> => {
    await client.delete(`${EXPENSE_DISTANCE_RATE_API_BASE_PATH}/${expenseDistanceRateId}`);
};

export const expenseDistanceRateApi = {
    searchExpenseDistanceRates,
    createExpenseDistanceRate,
    updateExpenseDistanceRate,
    deleteExpenseDistanceRate,
    getExpenseDistanceRate,
};
