import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { canManagePendingLeaveRequests, canManagePendingTimesheets } from '@/domain/permission/Permission.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { TimesheetAction } from '@/domain/timesheet/Timesheet.model';
import { LeaveRequestDialog } from '@/page/leave/leave-request-dialog/LeaveRequestDialog';
import { TimesheetDialog } from '@/page/timesheet/timesheet-dialog/TimesheetDialog';
import { useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import { getCurrentLocalDate } from '@/utils/datetime.util';
import { Button, Link, StackProps } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { Add01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router';

export const HomeHeader: FC<StackProps> = props => {
    const { t } = useTranslation();
    const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
    const [openTimesheetsDialog, setOpenTimesheetsDialog] = useState<boolean>(false);

    const currentEmployee = useCurrentEmployee();
    const policies = useCurrentPolicies();

    const items: BasicMenuItem[] = [];
    if (hasRealmFeatureEnabled(RealmFeaturesType.LEAVES) && canManagePendingLeaveRequests(policies, currentEmployee.id)) {
        items.push({
            title: t('my_leaves.leave_label'),
            onClick: () => setOpenLeaveDialog(true),
        });
    }
    if (hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET) && canManagePendingTimesheets(policies, currentEmployee.id)) {
        items.push({
            title: t('timesheets.timesheet'),
            onClick: () => setOpenTimesheetsDialog(true),
        });
    }

    return (
        <Stack gap={1} component={Paper} p={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'} {...props}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Stack>{currentEmployee && <EmployeeAvatar employeeAvatar={currentEmployee} size={'large'} />}</Stack>
                <Stack>
                    <Typography variant='h1'>{t('home_page.hello_user', { name: currentEmployee.displayName })}</Typography>
                    <Link component={RouterLink} to={`/profile/personal-info`} color={'primary'}>
                        {t('home_page.go_to_my_profile')}
                    </Link>
                </Stack>
            </Stack>
            <Stack>
                {items.length > 0 && (
                    <BasicMenu
                        items={items}
                        button={
                            <Button aria-label='quick-add' sx={{ padding: '10px', minWidth: 'auto' }}>
                                <Add01Icon size={16} />
                            </Button>
                        }
                    />
                )}
                {openLeaveDialog && (
                    <LeaveRequestDialog
                        open={true}
                        onClose={() => setOpenLeaveDialog(false)}
                        onSave={() => {
                            setOpenLeaveDialog(false);
                        }}
                        employeeId={currentEmployee.id}
                    />
                )}

                {openTimesheetsDialog && (
                    <TimesheetDialog
                        mode={TimesheetAction.CREATE}
                        open={openTimesheetsDialog}
                        defaultReferenceDate={getCurrentLocalDate()}
                        employee={currentEmployee}
                        onClose={() => {
                            setOpenTimesheetsDialog(false);
                        }}
                        onSave={() => {
                            setOpenTimesheetsDialog(false);
                        }}
                        missingEntriesDates={[]}
                    />
                )}
            </Stack>
        </Stack>
    );
};
