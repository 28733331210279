import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { showCompletedReviewSummary } from '@/domain/employee-review/EmployeeReview.service';
import { Job } from '@/domain/job/Job.model';
import { Objective } from '@/domain/objective/Objective.model';
import { FeedbackItem } from '@/page/review/employee-review-feedback-form-pdf/FeedbackFormPDF';
import { FeedbackLayoutPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackLayoutPDF';
import { SummaryCompletedPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryCompletedPDF';
import { SummaryFeedbackQuestionPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryFeedbackQuestionPDF';
import { SummaryFeedbackSkillsPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryFeedbackSkillsPDF';
import { SummaryFeedbackItemFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { useTheme } from '@mui/material';
import { View } from '@react-pdf/renderer';
import { FC } from 'react';

type SummaryFeedbackPDFProps = {
    managerSummary: EmployeeReviewManagerSummary;
    summaryFeedbackItems: SummaryFeedbackItemFormType[];
    objectives: Objective[];
    employeeReview: EmployeeReview;
    actions: EmployeeReviewAction[];
    jobs: Job[];
};

export const SummaryFeedbackPDF: FC<SummaryFeedbackPDFProps> = ({ objectives, summaryFeedbackItems, managerSummary, employeeReview, actions, jobs }) => {
    const theme = useTheme();
    return (
        <FeedbackLayoutPDF feedback={managerSummary} employeeReview={employeeReview}>
            {showCompletedReviewSummary(employeeReview, managerSummary) && <SummaryCompletedPDF managerSummary={managerSummary} />}
            <View
                style={{
                    gap: theme.spacing(2),
                }}
            >
                {!!summaryFeedbackItems.length &&
                    summaryFeedbackItems.map(summaryFeedbackItem => {
                        // For summary feedback items we are overriding the feedback item component for specific types
                        switch (summaryFeedbackItem.type) {
                            case 'QUESTION':
                            case 'SKILL':
                                return (
                                    <SummaryFeedbackQuestionPDF
                                        key={summaryFeedbackItem.id}
                                        questionItem={summaryFeedbackItem}
                                        rating={summaryFeedbackItem.rating}
                                    />
                                );
                            case 'SKILLS':
                                return <SummaryFeedbackSkillsPDF key={summaryFeedbackItem.id} skills={summaryFeedbackItem} />;
                            default:
                                return (
                                    <FeedbackItem
                                        key={summaryFeedbackItem.id}
                                        objectives={objectives}
                                        feedbackItem={summaryFeedbackItem}
                                        actions={actions}
                                        jobs={jobs}
                                    />
                                );
                        }
                    })}
            </View>
        </FeedbackLayoutPDF>
    );
};
