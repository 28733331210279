import { Button, Paper, Stack, Typography, useTheme } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRight01Icon } from 'hugeicons-react';

type Props = {
    isActive: (step: number) => boolean;
    handleBackStep: () => void;
    backButtonAllowed: () => boolean;
    steps: {
        headerKey: string;
        formName: string;
        step: ReactElement;
    }[];
    isSubmittingTheForm: boolean;
};
export const OnBoardingHeader: FC<Props> = ({ isActive, handleBackStep, backButtonAllowed, steps, isSubmittingTheForm }) => {
    const { t } = useTranslation();

    return (
        <Paper elevation={0}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} paddingY={1} paddingX={2}>
                <Stack>
                    <Breadcrumbs aria-label='breadcrumb' separator={<ArrowRight01Icon fontSize='small' />}>
                        {steps.map((step, index) => (
                            <BreadcrumbTypography key={step.headerKey} title={t('onboarding_form.' + step.headerKey)} isActive={isActive(index)} />
                        ))}
                    </Breadcrumbs>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    {backButtonAllowed() && <Button onClick={handleBackStep}>{t('general.back')}</Button>}

                    {steps.map((step, index) => (
                        <Button
                            key={step.headerKey}
                            type='submit'
                            form={step.formName}
                            variant='contained'
                            color='primary'
                            sx={{ display: isActive(index) ? 'inline-flex' : 'none' }}
                            loading={isSubmittingTheForm}
                        >
                            {index === steps.length - 1 ? t('general.submit') : t('general.next')}
                        </Button>
                    ))}
                </Stack>
            </Stack>
        </Paper>
    );
};

type BreadcrumbTypographyProps = {
    title: string;
    isActive: boolean;
};

const BreadcrumbTypography: FC<BreadcrumbTypographyProps> = ({ title, isActive }) => {
    const { palette } = useTheme();
    return (
        <Typography variant={'body2bold'} color={isActive ? palette.primary.main : palette.text.disabled}>
            {title}
        </Typography>
    );
};
