import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { Label } from '../label/Label.model';
import { ExpenseTax } from '@/domain/expense-tax/ExpenseTax.model';
import { ExpenseCategoryGroup } from '@/domain/expense-category-group/ExpenseCategoryGroup.model';

export const ExpenseCategoryArray = ['AMOUNT', 'DISTANCE'] as const;
export type ExpenseCategoryType = (typeof ExpenseCategoryArray)[number];

export type ExpenseCategoryPreset = {
    id: number;
    name: Label;
    amount: number;
    archived: boolean;
    archivedAt?: Date;
};

export type ExpenseCategory = {
    id: number;
    name: Label;
    mandatoryDescription: boolean;
    expenseTax?: ExpenseTax;
    type: ExpenseCategoryType;
    maxValue: number; // between 0 and 999999
    presets: ExpenseCategoryPreset[];
    categoryGroup?: ExpenseCategoryGroup;
    createdBy: EmployeeAvatar;
};

type UpdatePresetMutation = {
    id?: number;
    amount: number; // between 0 and 999999
    name: Label;
};

export type UpdateExpenseCategoryMutation = {
    maxValue: number | undefined; // between 0 and 999999 ; only if type is AMOUNT or DISTANCE
    expenseTaxId?: number;
    type: ExpenseCategoryType;
    name: Label;
    mandatoryDescription: boolean;
    presets: UpdatePresetMutation[];
    categoryGroupId?: number;
};

type CreatePresetMutation = Redundant<UpdatePresetMutation>;

export type CreateExpenseCategoryMutation = StrictOverwrite<
    UpdateExpenseCategoryMutation,
    {
        presets: CreatePresetMutation[];
    }
>;

export type ExpenseCategorySearch = {
    withArchived?: boolean;
};
