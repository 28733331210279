import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';
import { ContributorType, FeedbackType, ReviewItem, ReviewStatus } from '@/domain/review/Review.model';
import { Objective } from '@/domain/objective/Objective.model';
import { Skill } from '@/domain/skill/Skill.model';
import { ReviewTemplateItemType } from '@/domain/review-template/ReviewTemplate.model';
import { EmployeeReview, EmployeeReviewer } from '@/domain/employee-review/EmployeeReview.model';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { Label } from '@/domain/label/Label.model';
import { Job } from '@/domain/job/Job.model';

export type EmployeeReviewFeedbackResult = {
    employee: EmployeeAvatar;
    items: EmployeeReviewFeedbackResultItem[];
};

export type EmployeeReviewFeedbackResultItem = {
    contributorType: ContributorType;
    feedbackType: FeedbackType;
    employeeReview: EmployeeReview;
    reviewer: EmployeeReviewer;
    reviewItem: ReviewItem;
    score: number;
    comment: string;
    skill: Skill;
    objective: Objective;
    rating: Label;
};

export type EmployeeReviewFeedbackItemSearch = {
    reviewIds?: number[];
    locationIds?: number[];
    departmentIds?: number[];
    managerIds?: number[];
    roleIds?: number[];
    jobIds?: number[];
    contractStartDate?: LocalDate;
    employmentStatuses?: EmploymentStatus[];
};

export interface EmployeeReviewFeedback {
    employeeReviewId: number;
    reviewId: number;
    reviewName: Label;
    reviewStatus: ReviewStatus;
    employeeReviewStatus: EmployeeReviewStatus;
    feedbackDeadlineDate: LocalDate | undefined;
    employee: Employee;
    contributorType: ContributorType;
    feedbacks: EmployeeReviewFeedbackItem[];
    submitted: boolean;
}

export type EmployeeReviewFeedbackSkill = {
    score: number;
    comment: string;
    skill: Skill;
};

export type EmployeeReviewFeedbackSkillQuestion = {
    title: Label;
    score: number;
    comment: string;
    skill: Skill;
};

export type EmployeeReviewFeedbackSkills = {
    skills: EmployeeReviewFeedbackSkill[];
    allSkillsRequired: boolean;
    minSkills: number;
    maxSkills: number;
    instruction: Label | undefined;
};

export enum EmployeeReviewStatus {
    INPUT_NEEDED = 'INPUT_NEEDED',
    DISCUSSION_STARTED = 'DISCUSSION_STARTED',
    DISCUSSION_SUBMITTED = 'DISCUSSION_SUBMITTED',
    EMPLOYEE_APPROVED = 'EMPLOYEE_APPROVED',
    CANCELLED = 'CANCELLED',
    CLOSED = 'CLOSED',
}

export type EmployeeReviewFeedbackMutation = {
    reviewItemId: number;
    score: number | undefined;
    comment: string | undefined;
    skillId: number | undefined;
    objectiveId: number | undefined;
};

export type EmployeeReviewFeedbackQuestion = {
    title: Label;
    score: number;
    comment: string;
    instruction: Label | undefined;
};
export type EmployeeReviewFeedbackSection = {
    title: Label;
};
export type EmployeeReviewFeedbackObjective = {
    objective: Objective | undefined;
};

export type EmployeeReviewFeedbackJob = {
    job: Job | undefined;
};

export type EmployeeReviewFeedbackItem = {
    id: number;
    reviewItemId: number;
    type: ReviewTemplateItemType;
    feedbackQuestion: EmployeeReviewFeedbackQuestion | undefined;
    feedbackSection: EmployeeReviewFeedbackSection | undefined;
    feedbackObjectives: EmployeeReviewFeedbackObjective[] | undefined;
    feedbackSkills: EmployeeReviewFeedbackSkills | undefined;
    feedbackSkillQuestion: EmployeeReviewFeedbackSkillQuestion | undefined;
    feedbackJobs: EmployeeReviewFeedbackJob[] | undefined;
    order: number;
    required: boolean;
    rating: ReviewRatingScale | undefined;
};
