import { StateHandler } from '@/components/state-handler/StateHandler';
import { Employee } from '@/domain/employee/Employee.model';
import { EmployeeLeaveBalanceHistorySearchRequest } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { TimesheetCycle } from '@/domain/timesheet/Timesheet.model';
import { buildCycles, getDefaultCycle } from '@/domain/timesheet/Timesheet.service';
import { useGetEmployeeLeaveTypes } from '@/hooks/employee-leave-type/EmployeeLeaveType.hook';
import { useGetEmployee } from '@/hooks/employee/Employee.hook';
import { useGetEmployeeLeaveRequests } from '@/hooks/leave-request/LeaveRequest.hook';
import { useGetLeaveTypeHistories } from '@/hooks/leave-type-history/LeaveTypeHistory.hook';
import { EmployeeLeaves } from '@/page/employee-profile/employee-profile-leave/EmployeeLeaves';
import { MyAllowance } from '@/page/employee-profile/employee-profile-leave/MyAllowance';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { handleError } from '@/utils/api.util';
import { getCurrentLocalDate, isAfterDate, isBeforeDate } from '@/utils/datetime.util';
import { Stack } from '@mui/material';
import { FC, useState } from 'react';

export const EmployeeProfileLeavesPage: FC = () => {
    const employeeId = useEmployeeProfileId();

    // Employee is loaded to build cycle based on currentWorkingPattern
    const { data: activeEmployee, isLoading: isEmployeeLoading, isError: isEmployeeError, error: employeeError } = useGetEmployee(employeeId);

    return (
        <Stack direction='column' gap={2} alignItems='stretch' flex={1}>
            <StateHandler isLoading={isEmployeeLoading} isError={isEmployeeError} error={employeeError}>
                {activeEmployee && <LeavesOverview activeEmployee={activeEmployee} />}
            </StateHandler>
        </Stack>
    );
};

type LeavesOverviewProps = {
    activeEmployee: Employee;
};

const LeavesOverview: FC<LeavesOverviewProps> = ({ activeEmployee }) => {
    const employeeId = activeEmployee.id;

    const leaveCycles = buildCycles(activeEmployee);

    const currentLeaveCycle =
        leaveCycles.find(cycle => !isAfterDate(cycle.cycleStartDate, getCurrentLocalDate()) && !isBeforeDate(cycle.cycleEndDate, getCurrentLocalDate())) ??
        getDefaultCycle();
    const [selectedLeaveCycle, setSelectedLeaveCycle] = useState<TimesheetCycle>(currentLeaveCycle);

    const searchRequest: EmployeeLeaveBalanceHistorySearchRequest = {
        employeeId,
        cycleStartDate: selectedLeaveCycle.cycleStartDate,
    };

    const {
        data: leaveTypeHistories = [],
        isLoading: isLeaveTypeHistoriesLoading,
        refetch: refetchLeaveTypeHistories,
        isError: isLeaveTypeHistoriesError,
        error: leaveTypeHistoriesError,
    } = useGetLeaveTypeHistories(searchRequest);

    const { data: leaveRequests = [], isLoading: loading, refetch: fetchLeaveRequest, isError } = useGetEmployeeLeaveRequests(employeeId);

    const { data: employeeLeaveTypePolicies = [], refetch: refetchEmployeeLeaveTypePolicies } = useGetEmployeeLeaveTypes(employeeId);

    if (!employeeLeaveTypePolicies.length) {
        return <></>;
    }

    const handleChange = () => {
        fetchLeaveRequest().catch(handleError);
        refetchLeaveTypeHistories().catch(handleError);
    };

    return (
        <Stack direction='column' gap={2} alignItems='stretch' flex={1}>
            <StateHandler isLoading={loading || isLeaveTypeHistoriesLoading} isError={isError || isLeaveTypeHistoriesError} error={leaveTypeHistoriesError}>
                {activeEmployee && (
                    <MyAllowance
                        activeEmployee={activeEmployee}
                        userLeaveTypePolicies={employeeLeaveTypePolicies}
                        onPoliciesUpdated={() => refetchEmployeeLeaveTypePolicies()}
                        onChange={handleChange}
                        leaveTypeHistories={leaveTypeHistories}
                        selectedLeaveCycle={selectedLeaveCycle}
                        leaveCycles={leaveCycles}
                        onChangeLeaveCycle={setSelectedLeaveCycle}
                    />
                )}

                <EmployeeLeaves employeeId={employeeId} leaveRequests={leaveRequests} onChange={handleChange} />
            </StateHandler>
        </Stack>
    );
};
