import { objectiveSettingApi } from '@/api/objective-setting/ObjectiveSetting.api';
import { ObjectiveSetting, ObjectiveSettingUpdateMutation } from '@/domain/objective-setting/ObjectiveSetting.model';

export const getObjectiveSetting = (): Promise<ObjectiveSetting> => {
    return objectiveSettingApi.getObjectiveSetting();
};

export const updateObjectiveSetting = (id: number, request: ObjectiveSettingUpdateMutation): Promise<ObjectiveSetting> => {
    return objectiveSettingApi.updateObjectiveSetting(id, request);
};
