import { getReviewTemplate, searchReviewTemplates } from '@/domain/review-template/ReviewTemplate.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const reviewTemplateKeys = createQueryKeys('reviewTemplate', {
    search: (...optionalParam: Parameters<typeof searchReviewTemplates>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    getById: (...params: Parameters<typeof getReviewTemplate>) => ({
        queryKey: params,
    }),
});

export const useGetReviewTemplate = createRequiredParamsQueryHook(reviewTemplateKeys.search, getReviewTemplate);

export const useSearchReviewTemplates = createQueryHook(reviewTemplateKeys.getById, searchReviewTemplates);
