import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { ApproveTimesheetMutation, Timesheet } from '@/domain/timesheet/Timesheet.model';
import { approveTimesheets } from '@/domain/timesheet/Timesheet.service';
import { handleError } from '@/utils/api.util';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

type ApprovePendingTimesheetsDialogProps = {
    timesheetsToApprove: Timesheet[];
    onApprove: () => void;
    children: ReactElement;
};
export const ApprovePendingTimesheetsDialog: FC<PropsWithChildren<ApprovePendingTimesheetsDialogProps>> = ({ timesheetsToApprove, onApprove, children }) => {
    const { t } = useTranslation();

    const differentEmployeesCount = new Set(timesheetsToApprove.map(t => t.employee?.id)).size;

    const onApproveTimesheets = async () => {
        const approveRequest: ApproveTimesheetMutation = {
            timesheetIds: timesheetsToApprove.map(t => t.id),
        };
        try {
            await approveTimesheets(approveRequest);
            onApprove();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <ConfirmDialog
            content={t('pending_timesheets_dialog.message', {
                numberOfTimesheets: timesheetsToApprove.length,
                numberOfEmployees: differentEmployeesCount,
            })}
            title={t('pending_timesheets_dialog.title')}
            onConfirm={() => {
                onApproveTimesheets();
            }}
        >
            {children}
        </ConfirmDialog>
    );
};
