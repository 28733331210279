import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import {
    employeeReviewService,
    getEmployeeReview,
    searchEmployeeReviewAsContributor,
    searchEmployeeReviews,
} from '@/domain/employee-review/EmployeeReview.service';
import { ReviewStatus } from '@/domain/review/Review.model';
import {
    getEmployeeReviewFeedbacksManagerSummary,
    getEmployeeReviewFeedbacksSelfSummary,
    getEmployeeReviewFeedbacksSummary,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.service';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Employee } from '@/domain/employee/Employee.model';

export const employeeReviewKeys = createQueryKeys('employeeReviews', {
    getById: (...params: Parameters<typeof getEmployeeReview>) => ({
        queryKey: params,
    }),
    search: (...params: Parameters<typeof searchEmployeeReviews>) => ({
        queryKey: params,
    }),
    searchAsContributor: (...params: Parameters<typeof searchEmployeeReviewAsContributor>) => ({
        queryKey: params,
    }),
    countOngoing: (...params: Parameters<typeof countOngoingEmployeeReviews>) => ({
        queryKey: params,
    }),
    feedbackManagerSummary: (...params: Parameters<typeof getEmployeeReviewFeedbacksManagerSummary>) => ({
        queryKey: params,
    }),
    feedbackSelfSummary: (...params: Parameters<typeof getEmployeeReviewFeedbacksSelfSummary>) => ({
        queryKey: params,
    }),
    feedbackSummary: (...params: Parameters<typeof getEmployeeReviewFeedbacksSummary>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeReviewAsContributor = createQueryHook(employeeReviewKeys.searchAsContributor, searchEmployeeReviewAsContributor);

export const useGetEmployeeReview = createRequiredParamsQueryHook(employeeReviewKeys.getById, getEmployeeReview);

export const useGetEmployeeReviews = createQueryHook(employeeReviewKeys.search, searchEmployeeReviews);

export const useGetOngoingEmployeeReviews = (employeeId: number): ReturnType<typeof useGetEmployeeReviews> => {
    return useGetEmployeeReviews({
        reviewStatuses: [ReviewStatus.STARTED],
        employeeId,
    });
};

const countOngoingEmployeeReviews = async (employee: Employee): Promise<number> => {
    return await employeeReviewService.countEmployeeReviews({ reviewStatuses: [ReviewStatus.STARTED] }, employee);
};

export const useCountOngoingEmployeeReviews = createRequiredParamsQueryHook(employeeReviewKeys.countOngoing, countOngoingEmployeeReviews);

export const useGetEmployeeReviewManagerSummary = createRequiredParamsQueryHook(
    employeeReviewKeys.feedbackManagerSummary,
    getEmployeeReviewFeedbacksManagerSummary,
);

export const useGetEmployeeReviewSelfSummary = createRequiredParamsQueryHook(employeeReviewKeys.feedbackSelfSummary, getEmployeeReviewFeedbacksSelfSummary);

export const useGetEmployeeReviewSummary = createRequiredParamsQueryHook(employeeReviewKeys.feedbackSummary, getEmployeeReviewFeedbacksSummary);
