import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridWrapper, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { ExpenseCategory } from '@/domain/expense-category/ExpenseCategory.model';
import { useGetExpenseSetting } from '@/hooks/expense-setting/ExpenseSetting.hook';

type ExpenseCategoriesSettingsGridProps = {
    onRowClick: (category: ExpenseCategory) => void;
    onDeleteCategory: (category: ExpenseCategory) => void;
} & AgGridWrapperProps<ExpenseCategory>;

export const ExpenseCategorySettingsGrid: FC<ExpenseCategoriesSettingsGridProps> = ({ onRowClick, onDeleteCategory, ...restGridProps }) => {
    const { t } = useTranslation();

    const { data: expenseSetting } = useGetExpenseSetting();

    const actionMenuRenderer = ({ data }: ICellRendererParams<ExpenseCategory>) => {
        if (!data) {
            return <></>;
        }

        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => onRowClick(data),
                    },
                    {
                        title: t('general.delete'),
                        onClick: () => onDeleteCategory(data),
                    },
                ]}
            />
        );
    };

    const handleRowClick = (params: RowClickedEvent<ExpenseCategory>) => {
        if (params.event?.defaultPrevented || !params.data) {
            return;
        }
        onRowClick(params.data);
    };

    const columns: ColDef<ExpenseCategory>[] = [
        {
            field: 'name',
            headerName: t('expense_category_settings_page.categories'),
            type: 'label',
        },
        {
            field: 'categoryGroup.name',
            headerName: t('expense_category_settings_page.category_group'),
            type: 'label',
            hide: !expenseSetting?.expenseCategoryParentEnabled,
        },
        {
            type: 'actionMenu',
            cellRenderer: actionMenuRenderer,
        },
    ];

    return <AgGridWrapper columnDefs={columns} onRowClicked={handleRowClick} {...restGridProps} />;
};
