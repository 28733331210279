import { Employment } from '@/domain/employment/Employment.model';
import { getContracts, getEmployments } from '@/domain/employment/Employment.service';
import { createQueryHook } from '@/page/Query.type';
import { UseQueryResult } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employmentKeys = createQueryKeys('employments', {
    search: (...params: Parameters<typeof getEmployments>) => ({
        queryKey: params,
    }),
});

export const useGetEmployments = createQueryHook(employmentKeys.search, getEmployments);

export const useGetContracts = (
    employmentSearchMutation: Parameters<typeof getEmployments>[0] = { employeeIds: [], departmentIds: [] },
    options?: Parameters<typeof useGetEmployments>[1],
): UseQueryResult<Employment[]> => {
    const { data: employments = [], ...rest } = useGetEmployments(employmentSearchMutation, options);

    const contracts = getContracts(employments);

    return {
        data: contracts,
        ...rest,
    } as UseQueryResult<Employment[]>;
};
