import { employeeAddressAPI } from '@/api/employee/EmployeeAddress.api';
import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { SectionLoading } from '@/components/section/SectionLoading';
import { TableSection } from '@/components/section/TableSection/TableSection';
import { SectionActionButton, SectionColumn, SectionField, SectionRow } from '@/components/section/types';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeProfileChange } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { getSectionAction } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import {
    createEmployeeAddressPendingRequest,
    deleteEmployeeAddressPendingRequest,
    getSectionActionButton,
    mapFieldFormValuesToAddressMutation,
    updateEmployeeAddressPendingRequest,
} from '@/domain/employee/Employee.service';
import { EmployeeAddress } from '@/domain/employee/EmployeeAddress.model';
import { canManageEmployeeAddresses, canManagePendingEmployeeAddresses } from '@/domain/permission/Permission.service';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { useGetEmployeeAddresses } from '@/hooks/employee-address/EmployeeAddress.hook';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Country, getCountry } from '@/utils/countries.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EmployeeAddressDialog } from './Components/EmployeeAddressDialog';

import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { EmployeeAddressFieldType } from '@/domain/employee/EmployeeFields.model';
import { employeeKeys } from '@/hooks/employee/Employee.hook';
import { SectionDefinitionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { useQueryClient } from '@tanstack/react-query';
import { employeePendingChangeKeys } from '@/hooks/employee-pending-change/EmployeePendingChanges.hook';

type Props = {
    employeeId: number;
    pendingRows: EmployeeProfileChange['pendingRows'];
    sectionDefinition: SectionDefinition;
};

export const EmployeeAddressSection: FC<Props> = ({ employeeId, pendingRows, sectionDefinition }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [selectedEmployeeAddress, setSelectedEmployeeAddress] = useState<EmployeeAddress>();
    const [employeeAddressDialogOpen, setEmployeeAddressDialogOpen] = useState<boolean>(false);
    const policies = useCurrentPolicies();

    const {
        data: employeeAddresses = [],
        refetch: fetchEmployeeAddresses,
        isLoading,
        isError,
        isFetching = true,
        error,
    } = useGetEmployeeAddresses({ employeeId });

    const columns: SectionColumn[] = sectionDefinition.fields.map(field => {
        return {
            title: getLabelTranslation(field.name),
            valueType: field.valueType,
            fieldDefinitionId: field.id,
        };
    });

    let rows: SectionRow[] = employeeAddresses?.map(employeeAddress => {
        return {
            id: employeeAddress.id,
            isPending: false,
            fields: sectionDefinition.fields?.map(fieldDefinition => ({
                fieldDefinitionId: fieldDefinition.id,
                title: getLabelTranslation(fieldDefinition.name),
                required: fieldDefinition.mandatory,
                valueType: fieldDefinition.valueType,
                fieldType: fieldDefinition.fieldType,
                [getFieldValueProperty(fieldDefinition.valueType)]: getEmployeeAddressFieldValue(
                    employeeAddress,
                    fieldDefinition.fieldType as EmployeeAddressFieldType,
                ),
            })),
        };
    });

    if (pendingRows?.length) {
        const pendingRowToAppend: SectionRow[] = pendingRows.map(({ fields, id }) => ({
            id,
            isPending: true,
            fields: fields
                .map<SectionField>(pendingField => ({
                    ...pendingField,
                    fieldDefinitionId: pendingField.sectionFieldDefinition.id,
                    title: columns.find(column => column.fieldDefinitionId === pendingField.sectionFieldDefinition.id)?.title,
                    valueType: pendingField.sectionFieldDefinition.valueType,
                    sectionFieldDefinitionId: pendingField.sectionFieldDefinition.id,
                    countryValue:
                        pendingField.sectionFieldDefinition.fieldType === 'ADDRESS_COUNTRY' && pendingField.stringValue
                            ? getCountry(pendingField.stringValue)
                            : undefined,
                    fieldType: pendingField.sectionFieldDefinition.fieldType,
                }))
                // This field is not displayed in the table
                .filter(field => field.fieldType !== 'ADDRESS_REGION'),
        }));

        rows = [...(rows ?? []), ...pendingRowToAppend];
    }

    const canApprove = canManageEmployeeAddresses(policies, employeeId);
    const canRequestApproval = canManagePendingEmployeeAddresses(policies, employeeId);

    const openMutationDialogButton: SectionActionButton = {
        title: t('employee.address.add_address'),
        onClick: () => {
            setEmployeeAddressDialogOpen(true);
        },
    };

    const seePendingButton: SectionActionButton = {
        title: t('employee.sections.see_pending_changes'),
        onClick: () => {
            navigate('/people/employee-requests');
        },
    };

    const handleUpdate = (employeeAddressFormValues: SectionDefinitionFormValues) => {
        if (!selectedEmployeeAddress?.id) {
            return;
        }

        const request = mapFieldFormValuesToAddressMutation(employeeAddressFormValues, sectionDefinition, employeeId);

        if (canApprove) {
            employeeAddressAPI
                .updateEmployeeAddress(selectedEmployeeAddress.id, request)
                .then(() => {
                    fetchEmployeeAddresses().catch(handleError);
                })
                .catch(handleError)
                .finally(() => {
                    setEmployeeAddressDialogOpen(false);
                    setSelectedEmployeeAddress(undefined);
                });
        } else if (canRequestApproval) {
            updateEmployeeAddressPendingRequest(selectedEmployeeAddress.id, request)
                .then(() => {
                    setEmployeeAddressDialogOpen(false);
                    setSelectedEmployeeAddress(undefined);
                    refetchProfile();
                })
                .catch(handleError);
        }
    };

    const handleCreate = (employeeAddressFormValues: SectionDefinitionFormValues) => {
        const employeeAddressCreateRequest = mapFieldFormValuesToAddressMutation(employeeAddressFormValues, sectionDefinition, employeeId);

        if (canApprove) {
            employeeAddressAPI
                .createEmployeeAddress(employeeAddressCreateRequest)
                .catch(handleError)
                .finally(() => {
                    fetchEmployeeAddresses().catch(handleError);
                    setEmployeeAddressDialogOpen(false);
                    setSelectedEmployeeAddress(undefined);
                });
        } else if (canRequestApproval) {
            createEmployeeAddressPendingRequest(employeeAddressCreateRequest)
                .then(() => {
                    setEmployeeAddressDialogOpen(false);
                    setSelectedEmployeeAddress(undefined);
                    refetchProfile();
                })
                .catch(handleError);
        }
    };

    const queryClient = useQueryClient();

    const refetchProfile = () => {
        queryClient.invalidateQueries(employeeKeys.employeeById(employeeId));
        queryClient.invalidateQueries(employeePendingChangeKeys.get(employeeId));
    };

    const handleSave = (employeeAddressFormValues: SectionDefinitionFormValues) => {
        if (selectedEmployeeAddress?.id) {
            handleUpdate(employeeAddressFormValues);
        } else {
            handleCreate(employeeAddressFormValues);
        }
    };

    const onClose = () => {
        setEmployeeAddressDialogOpen(false);
        setSelectedEmployeeAddress(undefined);
    };

    const handleDelete = (addressId: number) => {
        return employeeAddressAPI
            .deleteEmployeeAddress(addressId)
            .then(() => {
                fetchEmployeeAddresses().catch(handleError);
            })
            .catch(handleError);
    };

    const handleDeletePendingRequest = async (addressId: number) => {
        try {
            await deleteEmployeeAddressPendingRequest(addressId);
            refetchProfile();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDeleteClicked = (row: SectionRow) => async () => {
        try {
            if (row.isPending) {
                await handleDeletePendingRequest(row.id);
            } else {
                await handleDelete(row.id);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleEditClicked = (row: SectionRow) => () => {
        setSelectedEmployeeAddress(convertSectionRowToEmployeeAddress(row, employeeId));
        setEmployeeAddressDialogOpen(true);
    };

    const action = getSectionAction({ canApprove, canRequestApproval });
    const actionButton = getSectionActionButton(action, openMutationDialogButton, seePendingButton);

    const canHaveActionMenu = canApprove || canRequestApproval;

    const actionMenuCellRenderer = canHaveActionMenu
        ? (params: ICellRendererParams<SectionRow>) => {
              const mutationEnabled = params.data?.isPending ? canRequestApproval : canApprove;
              const data = params.data;
              if (!data) {
                  return;
              }

              const menuItems: BasicMenuItem[] = [
                  {
                      title: t('general.edit'),
                      onClick: canApprove && params.data?.isPending ? seePendingButton.onClick : handleEditClicked(data),
                      hide: !mutationEnabled,
                  },
                  {
                      title: t('general.delete'),
                      onClick: handleDeleteClicked(data),
                      hide: !mutationEnabled,
                  },
              ];
              return <BasicMenu items={menuItems} />;
          }
        : undefined;

    // extract start from all rows (approved and pending)
    const existingAddressDates = rows
        .filter(row => row.id !== selectedEmployeeAddress?.id)
        .map(row => convertSectionRowToEmployeeAddress(row, employeeId).startDate);

    return (
        <StateHandler
            isLoading={isLoading || isFetching}
            isError={isError}
            error={error}
            loadingComponent={<SectionLoading sectionTitle={getLabelTranslation(sectionDefinition.name)} />}
        >
            <TableSection
                sectionTitle={getLabelTranslation(sectionDefinition.name)}
                columns={columns}
                rows={rows}
                actionButton={actionButton}
                actionMenuCellRenderer={actionMenuCellRenderer}
            />
            {employeeAddressDialogOpen && (
                <EmployeeAddressDialog
                    open={employeeAddressDialogOpen}
                    employeeId={employeeId}
                    employeeAddress={selectedEmployeeAddress}
                    sectionDefinition={sectionDefinition}
                    onSave={employeeAddressFormValues => {
                        handleSave(employeeAddressFormValues);
                    }}
                    onClose={() => {
                        onClose();
                    }}
                    excludeDates={existingAddressDates}
                />
            )}
        </StateHandler>
    );
};

const convertSectionRowToEmployeeAddress = (row: SectionRow, employeeId: number): EmployeeAddress => {
    const startDate = row.fields[0].dateValue;
    if (!startDate) {
        throw new Error('Date is required');
    }

    const getField = (fieldType: EmployeeAddressFieldType) => {
        return row.fields.find(field => field.fieldType === fieldType);
    };

    return {
        id: row.id,
        employeeId: employeeId,
        startDate: startDate,
        addressLine1: getField('ADDRESS_ADDRESS_LINE_1')?.stringValue ?? '',
        addressLine2: getField('ADDRESS_ADDRESS_LINE_2')?.stringValue ?? '',
        postCode: getField('ADDRESS_POST_CODE')?.stringValue ?? '',
        city: getField('ADDRESS_CITY')?.stringValue ?? '',
        region: getField('ADDRESS_REGION')?.stringValue ?? '',
        country: getField('ADDRESS_COUNTRY')?.countryValue?.value ?? '',
        endDate: undefined,
    };
};

const getEmployeeAddressFieldValue = (employeeAddress: EmployeeAddress, fieldType: EmployeeAddressFieldType): string | Country | undefined => {
    const employeeAddressFieldValueMapping: Record<EmployeeAddressFieldType, string | Country | undefined> = {
        ADDRESS_START_DATE: employeeAddress.startDate,
        ADDRESS_ADDRESS_LINE_1: employeeAddress.addressLine1,
        ADDRESS_ADDRESS_LINE_2: employeeAddress.addressLine2,
        ADDRESS_POST_CODE: employeeAddress.postCode,
        ADDRESS_CITY: employeeAddress.city,
        ADDRESS_REGION: employeeAddress.region,
        ADDRESS_COUNTRY: getCountry(employeeAddress?.country),
    };

    return employeeAddressFieldValueMapping[fieldType];
};
