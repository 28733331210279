import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { canManageSurveys, canSeeSurveyResults } from '@/domain/permission/Permission.service';
import { Page } from '@/page/Pages';

export const getSurveyPages = (policies: EmployeePolicy[]): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'survey_templates_page.surveys',
            path: '/manage-surveys/surveys',
            condition: policies => canSeeSurveyResults(policies),
        },
        {
            labelKey: 'survey_templates_page.templates',
            path: '/manage-surveys/templates',
            condition: policies => canManageSurveys(policies),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(policies));
};
