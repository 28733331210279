import { DateFilter } from '@/components/filters-bar/FilterBar.type';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { Report, ReportGroupBy, ReportSort, ReportType } from '@/domain/report/Report.model';
import { convertAvailableFieldToColumn, convertReportToUpdateMutation, updateReport, updateReportViewers } from '@/domain/report/Report.service';
import { useReportFilters } from '@/hooks/report/ReportFilters.hook';
import { ReportGridModePage } from '@/page/report/ReportGridModePage';
import { ReportMatrixModePage } from '@/page/report/ReportMatrixModePage';
import { ReportColumn } from '@/page/report/report-columns-selector/ReportColumnsSelector';
import { ReportFilterItemBar } from '@/page/report/report-editor-bar/ReportEditorBar';
import { ShareReportDialog } from '@/page/report/share-report-dialog/ShareReportDialog';
import { handleError } from '@/utils/api.util';
import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useGetAvailableReportGroupedFields, useGetReport, useGetReportRows, useGetReportRowsPreview } from '../../hooks/report/Report.hook';

let abortController = new AbortController();
export const ReportPage: FC = () => {
    type PagePathParams = { reportId: string; reportType: ReportType };
    const { reportType: reportTypePathParam, ...restParams } = useParams<PagePathParams>() as PagePathParams;

    const reportIdPathParam = Number(restParams.reportId);

    const {
        data: report,
        error: reportError,
        refetch,
    } = useGetReport({
        reportId: reportIdPathParam,
        reportType: reportTypePathParam,
    });

    const {
        data: rows,
        isLoading,
        isError,
        error,
    } = useGetReportRows({
        reportId: reportIdPathParam,
        reportType: reportTypePathParam,
    });

    const reportTypeFromReport = report?.reportType ?? 'EMPLOYEE_PERSONAL_REPORT';

    const availableReportFieldsParams =
        reportTypeFromReport === 'EMPLOYEE_SECTION_REPORT'
            ? {
                  reportType: reportTypeFromReport,
                  sectionDefinitionId: report?.reportItemId,
              }
            : {
                  reportType: reportTypeFromReport,
                  sectionDefinitionId: undefined,
              };

    // Available fields are important for the grid display
    // We are using them to get the columns definitions
    // if columns are empty each rows will be empty
    const {
        data: availableGroupedFields = [],
        isLoading: isAvailableFieldsLoading,
        isError: isAvailableFieldsError,
    } = useGetAvailableReportGroupedFields(availableReportFieldsParams, {
        options: { enabled: !!reportTypeFromReport },
    });

    const reportFiltersParams = !isAvailableFieldsLoading
        ? {
              availableFields: availableGroupedFields?.flatMap(g => g.fields) ?? [],
              defaultFilters: report?.filters ?? [],
          }
        : undefined;

    // All the available filters for the report, event if they are not displayed/used by the user
    const [filters, setFilters] = useReportFilters(reportFiltersParams);

    const {
        data: rowsPreview,
        refetchReportRowsPreview: fetchReportRowsPreview,
        isFetching: isFetchingPreview = false,
    } = useGetReportRowsPreview({
        reportType: report?.reportType,
        sectionDefinitionId: report?.reportItemId,
    });

    const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

    const handleOpenShareDialog = () => {
        setIsShareDialogOpen(true);
    };

    const handleViewersUpdate =
        (report: Report) =>
        async ({ viewers }: { viewers: EmployeeAvatar[] }) => {
            try {
                await updateReportViewers({
                    viewers,
                    id: report.id,
                    reportType: report.reportType,
                });
                await refetch();
                setIsShareDialogOpen(false);
            } catch (error) {
                handleError(error);
            }
        };

    const rowsToDisplay = rowsPreview ?? rows ?? [];

    const isDataLoading = isLoading || isAvailableFieldsLoading;

    const isDataError = isError || isAvailableFieldsError;

    const filtersBarProps = {
        filters: filters?.filter(
            (
                f, // we don't want to display the filters if options or fetchOptions are not defined
            ) =>
                (f.type !== 'select' && f.type !== 'multi-select') ||
                (f.selectMode === 'ASYNC' && !!f.fetchOptions) ||
                (f.selectMode === 'SYNC' && !!f.options),
        ),
        onFiltersChange: setFilters,
    };

    const reportMode = report?.displayMode ?? 'TABLE';

    const fetchReportRowsPreviewWithAbort = useCallback(
        (filters: ReportFilterItemBar[], columns: ReportColumn[]) => {
            if (abortController) {
                abortController.abort();
            }
            // make our request cancellable
            abortController = new AbortController();
            return fetchReportRowsPreview(filters, columns, abortController);
        },
        [fetchReportRowsPreview],
    );

    useDeepCompareEffect(() => {
        if (reportMode === 'MATRIX' && availableGroupedFields?.length) {
            // Matrix need to load all the fields to display the graph, because data are grouped by fields
            fetchReportRowsPreviewWithAbort(filters, availableGroupedFields.flatMap(g => g.fields).map(convertAvailableFieldToColumn)).catch(handleError);
        }
    }, [reportMode, filters, availableGroupedFields, fetchReportRowsPreviewWithAbort]);

    if (isDataLoading || isDataError) {
        return <StateHandler isLoading={isDataLoading} isError={isDataError} isEmpty={!report} error={error ?? reportError} />;
    }

    const availableGroupingFilters =
        availableGroupedFields
            ?.flatMap(g =>
                g.fields
                    // we don't allow grouping on all fields
                    .filter(
                        field =>
                            (field.valueType !== 'STRING' && field.valueType !== 'DATE' && field.fieldType !== 'EMPLOYEE') ||
                            field.fieldType === 'CURRENT_EMPLOYMENT_LOCATION',
                    ),
            )
            // TODO handle custom field
            .filter(field => field.fieldType !== 'EMPLOYEE_CUSTOM_FIELD') ?? [];

    const handleSaveGridReport = async ({
        columns,
        highlightRange,
        sorts,
    }: {
        columns: ReportColumn[];
        highlightRange: [DateFilter, DateFilter] | [];
        sorts: ReportSort[];
    }) => {
        if (!report) {
            return;
        }
        try {
            const mutation = convertReportToUpdateMutation(
                {
                    filters,
                    columns,
                    historyRange: highlightRange,
                    groupByFields: [],
                    sorts,
                },
                report,
            );

            await updateReport(mutation);
        } catch (error) {
            handleError(error);
        }
    };
    const handleSaveMatrixReport = async ({ groupByFields }: { groupByFields: [ReportGroupBy | undefined, ReportGroupBy | undefined] }) => {
        try {
            if (!report) {
                return;
            }
            const mutation = convertReportToUpdateMutation(
                {
                    filters,
                    // we can improve this to make visible only the fields that are used in the graph
                    columns: availableGroupedFields?.flatMap(g => g.fields).map(convertAvailableFieldToColumn) ?? [],
                    historyRange: [],
                    groupByFields: groupByFields.filter((g): g is ReportGroupBy => !!g),
                },
                report,
            );

            await updateReport(mutation);
        } catch (error) {
            handleError(error);
        }
    };

    if (!report) {
        return;
    }

    return (
        <>
            {reportMode === 'MATRIX' ? (
                <ReportMatrixModePage
                    report={report}
                    onSaveReport={handleSaveMatrixReport}
                    rows={rowsToDisplay}
                    filtersBarProps={filtersBarProps}
                    availableGroupingFilters={availableGroupingFilters}
                    onOpenShareDialog={handleOpenShareDialog}
                />
            ) : (
                <ReportGridModePage
                    report={report}
                    onSaveReport={handleSaveGridReport}
                    rows={rowsToDisplay}
                    onConfigChange={fetchReportRowsPreviewWithAbort}
                    filtersBarProps={filtersBarProps}
                    availableGroupedFields={availableGroupedFields}
                    onOpenShareDialog={handleOpenShareDialog}
                    isLoading={isDataLoading || isFetchingPreview}
                />
            )}

            {isShareDialogOpen && report && (
                <ShareReportDialog
                    open={true}
                    viewers={report?.viewers ?? []}
                    onSave={handleViewersUpdate(report)}
                    onClose={() => setIsShareDialogOpen(false)}
                />
            )}
        </>
    );
};
