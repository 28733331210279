import { EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { WorkingPatternTemplate, WorkingPatternType } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { DayOfWeek, getLocalDateTestConfig } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import i18next from 'i18next';
import * as yup from 'yup';

export const getWorkPatternSchema = () => {
    return yup.object().shape({
        startDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
        type: yup.string().required().oneOf(Object.values(EmployeeWorkingPatternType)),
        rate: yup.number().when('type', {
            is: (type: EmployeeWorkingPatternType) => type === EmployeeWorkingPatternType.RATE,
            then: schema => schema.required().max(150).min(1, i18next.t('employee.work_pattern.dialog.contract_0_percent_error')),
            otherwise: schema => schema.nullable(),
        }),
        timesheetSettingId: yup.number().when({
            is: () => isTimesheetSettingIdRequired(),
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        weeklyWorkingTimeId: yup.number().when('type', {
            is: (type: EmployeeWorkingPatternType) => type !== EmployeeWorkingPatternType.HOURLY && type !== EmployeeWorkingPatternType.TEMPLATE,
            then: schema => schema.required(),
        }),
        // if type is template, then workingPatternTemplate is required
        workingPatternTemplate: workingPatternTemplateSchema.when('type', {
            is: (type: EmployeeWorkingPatternType) => type === EmployeeWorkingPatternType.TEMPLATE,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable(),
        }),
        startingWeek: yup.number().when(['type', 'workingPatternTemplate'], ([type, workingPatternTemplate], schema) => {
            if (type === EmployeeWorkingPatternType.TEMPLATE) {
                return schema
                    .required()
                    .min(1)
                    .test({
                        name: 'max_starting_week_exceeded',
                        message: i18next.t('employee.work_pattern.dialog.max_starting_week_exceeded'),
                        test: startingWeek => {
                            if (workingPatternTemplate) {
                                return startingWeek <= (workingPatternTemplate?.workingPatternTemplateWeekDays?.length ?? 1);
                            }
                        },
                    });
            }
            return schema.nullable();
        }),
        workingDays: yup
            .object()
            .shape({
                morningWorkingDays: yup
                    .array()
                    .of(yup.string().oneOf(Object.values(DayOfWeek)).required())
                    .required(),
                afternoonWorkingDays: yup
                    .array()
                    .of(yup.string().oneOf(Object.values(DayOfWeek)).required())
                    .required(),
            })
            .required()
            .test({
                name: 'no_days_selected_error',
                message: i18next.t('employee.work_pattern.dialog.contract_0_percent_error'),
                test: workingDays => {
                    const { morningWorkingDays, afternoonWorkingDays } = workingDays;
                    return morningWorkingDays.length > 0 || afternoonWorkingDays.length > 0;
                },
            }),
        calendarId: yup.number().required(),
    });
};

export type EmployeeWorkingPatternFormValues = yup.InferType<ReturnType<typeof getWorkPatternSchema>>;

const workingPatternTemplateSchema = yup
    .object()
    .default(getNull())
    .shape({
        id: yup.number().required(),
        name: yup.string().required(),
        workingPatternType: yup.string().required().oneOf(Object.values(WorkingPatternType)),
        workingPatternTemplateWeekDays: yup.array().required() as unknown as yup.Schema<WorkingPatternTemplate['workingPatternTemplateWeekDays']>,
        weeklyWorkingTime: yup.object().required() as unknown as yup.Schema<WorkingPatternTemplate['weeklyWorkingTime']>,
    });

export const isTimesheetSettingIdRequired = (): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET) || hasRealmFeatureEnabled(RealmFeaturesType.LEAVES);
};
