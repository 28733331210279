import { getDocumentTags } from '@/domain/document-tag/DocumentTag.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const documentTagKeys = createQueryKeys('documentTags', {
    search: (...optionalParam: Parameters<typeof getDocumentTags>) => {
        return { queryKey: [optionalParam ?? {}] };
    },
});

export const useGetDocumentTags = createQueryHook(documentTagKeys.search, getDocumentTags);
