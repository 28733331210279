import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { SectionFieldComponent } from '@/components/section/SectionFieldComponent/SectionFieldComponent';
import { SectionField } from '@/components/section/types';
import { DepartmentHierarchyLabel } from '@/domain-ui/department/DepartmentHierarchyLabel';
import { EmploymentDialog } from '@/domain-ui/employment/employment-dialog/EmploymentDialog';
import { mapToEmploymentDefaultValues } from '@/domain-ui/employment/employment-dialog/EmploymentDialog.util';
import { formatCostCentersAssignment } from '@/domain/cost-center/CostCenter.service';
import { Employee } from '@/domain/employee/Employee.model';
import { Employment, EmploymentCreationMutation, EmploymentUpdateMutation } from '@/domain/employment/Employment.model';
import { createEmployment, getCurrentPrincipalEmployment, updateEmployment } from '@/domain/employment/Employment.service';
import { canManageEmployeeContracts } from '@/domain/permission/Permission.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { EmploymentDialogFormValues } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';
import { EmploymentsHistoryDialog } from '@/page/employee-profile/employee-profile-info/EmploymentSection/EmploymentsHistoryDialog';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { ArrowDown01Icon, Clock02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    employee: Employee;
    onUpdateEmployments: () => void;
};

export const EmploymentSection: FC<Props> = ({ employee, onUpdateEmployments }) => {
    const { t } = useTranslation();

    const policies = useCurrentPolicies();

    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [dialogToCreateEmploymentsIsOpen, setDialogToCreateEmploymentsIsOpen] = useState<boolean>(false);
    const [dialogToUpdateEmploymentsIsOpen, setDialogToUpdateEmploymentsIsOpen] = useState<boolean>(false);

    const principalEmployment = getCurrentPrincipalEmployment(employee);

    const addActionButton = {
        title: t('employee.employment.add_employment'),
        onClick: () => {
            setDialogToCreateEmploymentsIsOpen(true);
        },
        tooltipTitle: t('employee.employment.add_employment_disabled_tooltip_title'),
    };

    const editActionButton = {
        title: t('employee.employment.correct_employment'),
        onClick: () => {
            setDialogToUpdateEmploymentsIsOpen(true);
        },
    };

    const getDropdownActionButtons = () => {
        if (canManageEmployeeContracts(policies, employee?.id)) {
            return [addActionButton, editActionButton];
        }
    };

    const handleUpdate = async (employmentFormValues: EmploymentDialogFormValues) => {
        if (!principalEmployment) {
            return;
        }
        try {
            const employmentUpdateRequest: EmploymentUpdateMutation = {
                // Contract type is not editable in the dialog so we can use the principal employment's contract type
                contractType: principalEmployment.contractType,
                startDate: employmentFormValues?.startDate,
                probationEndDate: principalEmployment?.probationEndDate,
                endDate: principalEmployment.endDate,
                employmentCreateReason: employmentFormValues?.employmentUpdateReason,
                items: employmentFormValues.items.map((item, index) => ({
                    // The backend expects id for existing item
                    id: item.id ?? undefined,
                    principal: index === 0,
                    locationId: item.location?.id,
                    departmentId: item?.department?.id,
                    jobId: item?.job?.id,
                    jobFamilyId: item?.jobFamily?.id,
                    managerIds: item.managers.flatMap(manager => manager.id),
                    employmentCostCenters:
                        item.employmentCostCenters.map(ecc => ({
                            costCenterId: ecc.costCenter.id,
                            percentage: ecc.percentage,
                        })) ?? [],
                })),
            };

            await updateEmployment(principalEmployment.id, employmentUpdateRequest);
            onUpdateEmployments();
            setDialogToUpdateEmploymentsIsOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleCreate = (employmentFormValues: EmploymentDialogFormValues) => {
        if (!principalEmployment) {
            return;
        }
        const employmentCreateRequest: EmploymentCreationMutation = {
            // Contract type is not editable in the dialog so we can use the principal employment's contract type
            contractType: principalEmployment?.contractType,
            employeeId: employee?.id,
            startDate: employmentFormValues?.startDate,
            employmentCreateReason: employmentFormValues?.employmentUpdateReason,
            items: employmentFormValues.items.map((employment, index) => ({
                // First item is defined as principal employment
                principal: index === 0,
                locationId: employment.location?.id,
                departmentId: employment?.department?.id,
                jobId: employment?.job?.id,
                jobFamilyId: employment?.jobFamily?.id,
                managerIds: employment.managers.flatMap(manager => manager.id),
                employmentCostCenters:
                    employment.employmentCostCenters.map(ecc => ({
                        costCenterId: ecc.costCenter.id,
                        percentage: ecc.percentage,
                    })) ?? [],
            })),
        };
        createEmployment(employmentCreateRequest)
            .then(() => {
                onUpdateEmployments();
                setDialogToCreateEmploymentsIsOpen(false);
            })
            .catch(handleError);
    };

    const onClose = () => {
        setDialogToCreateEmploymentsIsOpen(false);
    };

    const dropdownActionButtons = getDropdownActionButtons();

    const currentEmployments = [...employee.currentEmployments].sort(a => (a.principal ? -1 : 1));

    const commonFieldTypes: EmploymentFieldType[] = ['CURRENT_EMPLOYMENT_START_DATE'] as const;

    return (
        <Stack component={Paper} p={2} justifyContent='center' alignItems='flex-start' spacing={1} pl={3}>
            <Stack direction='row' alignItems='flex-start' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='h2' sx={{ flex: 1 }}>
                    {t('employee.sections.employment')}
                </Typography>

                <Button variant='text' onClick={() => setShowHistory(true)} endIcon={<Clock02Icon size={20} />}>
                    {t('employee.employment.view_history')}
                </Button>

                {showHistory && <EmploymentsHistoryDialog open={true} onClose={() => setShowHistory(false)} employee={employee} />}

                {!!dropdownActionButtons?.length && (
                    <BasicMenu items={dropdownActionButtons} dropdownTitle={t('my_profile.actions')} endIcon={<ArrowDown01Icon />} />
                )}
            </Stack>
            <Stack width='100%' sx={{ overflowX: 'auto' }} gap={1}>
                {getFieldTypes().map(fieldType => {
                    return (
                        <Stack key={fieldType} width={'auto'} minWidth={'fit-content'}>
                            <Stack
                                py={1}
                                direction={{ xs: 'column', md: 'row' }}
                                flex={1}
                                justifyContent='flex-start'
                                alignItems={{ md: 'flex-start' }}
                                columnGap={3}
                                rowGap={{ xs: 1, md: 0 }}
                            >
                                <Typography width={200} variant='body1bold' noWrap>
                                    {getFieldDefinitionTranslation({ fieldType })}
                                </Typography>
                                <Stack direction='row' px={{ md: 2 }} flex={1} gap={1}>
                                    {currentEmployments.map(employment => (
                                        <Stack key={employment.id} width={200} flex={1}>
                                            {(employment.principal || !commonFieldTypes.includes(fieldType)) &&
                                                // special display for department
                                                (fieldType === 'CURRENT_EMPLOYMENT_DEPARTMENT' ? (
                                                    <DepartmentHierarchyLabel departmentId={employment.department.id} />
                                                ) : (
                                                    <SectionFieldComponent field={getFieldsByType(employment, fieldType)} />
                                                ))}
                                        </Stack>
                                    ))}
                                </Stack>
                            </Stack>

                            <Divider flexItem sx={{ opacity: 0.6 }} />
                        </Stack>
                    );
                })}
            </Stack>
            {dialogToCreateEmploymentsIsOpen && (
                <EmploymentDialog open={dialogToCreateEmploymentsIsOpen} employee={employee} onSave={handleCreate} onClose={onClose} />
            )}

            {dialogToUpdateEmploymentsIsOpen && (
                <EmploymentDialog
                    open={dialogToUpdateEmploymentsIsOpen}
                    employee={employee}
                    defaultValues={mapToEmploymentDefaultValues(currentEmployments)}
                    onSave={handleUpdate}
                    onClose={() => setDialogToUpdateEmploymentsIsOpen(false)}
                />
            )}
        </Stack>
    );
};

const formatEmploymentStartDate = (employment: Employment) => {
    return formatInDefaultDate(employment.startDate) + (employment.endDate ? ' -> ' + formatInDefaultDate(employment.endDate) : '');
};

type AdditionalFieldType = 'CURRENT_EMPLOYMENT_COST_CENTERS' | 'CURRENT_EMPLOYMENT_JOB_FAMILY';

const baseFieldTypes = [
    'CURRENT_EMPLOYMENT_START_DATE',
    'CURRENT_EMPLOYMENT_JOB',
    'CURRENT_EMPLOYMENT_DEPARTMENT',
    'CURRENT_EMPLOYMENT_MANAGER',
    'CURRENT_EMPLOYMENT_LOCATION',
] as const;

type BaseFieldType = (typeof baseFieldTypes)[number];

type EmploymentFieldType = BaseFieldType | AdditionalFieldType;
const getFieldTypes = (): EmploymentFieldType[] => {
    const fieldTypes: (BaseFieldType | AdditionalFieldType)[] = [...baseFieldTypes];

    if (hasRealmFeatureEnabled(RealmFeaturesType.COST_CENTERS)) {
        fieldTypes.push('CURRENT_EMPLOYMENT_COST_CENTERS');
    }

    if (hasRealmFeatureEnabled(RealmFeaturesType.JOB_FAMILIES)) {
        // Add job family field type after job field type
        fieldTypes.splice(fieldTypes.indexOf('CURRENT_EMPLOYMENT_JOB') + 1, 0, 'CURRENT_EMPLOYMENT_JOB_FAMILY');
    }

    return fieldTypes;
};

const getFieldsByType = (employment: Employment, fieldType: EmploymentFieldType): SectionField => {
    const title = '';
    switch (fieldType) {
        case 'CURRENT_EMPLOYMENT_LOCATION':
            return {
                fieldDefinitionId: undefined,
                title,
                stringValue: employment.location?.name,
                valueType: 'STRING',
                fieldType,
            };
        case 'CURRENT_EMPLOYMENT_DEPARTMENT':
            return {
                fieldDefinitionId: undefined,
                title,
                stringValue: getLabelTranslation(employment.department?.name),
                valueType: 'STRING',
                fieldType,
            };
        case 'CURRENT_EMPLOYMENT_JOB':
            return {
                fieldDefinitionId: undefined,
                title,
                stringValue: getLabelTranslation(employment.job?.name),
                valueType: 'STRING',
                fieldType,
            };
        case 'CURRENT_EMPLOYMENT_JOB_FAMILY':
            return {
                fieldDefinitionId: undefined,
                title,
                stringValue: employment.jobFamily?.name,
                valueType: 'STRING',
                fieldType,
            };
        case 'CURRENT_EMPLOYMENT_MANAGER':
            return {
                fieldDefinitionId: undefined,
                title,
                fieldType,
                stringValue: employment.managers?.flatMap(m => m.displayName).join(', '),
                valueType: 'STRING',
            };

        case 'CURRENT_EMPLOYMENT_START_DATE':
            return {
                fieldDefinitionId: undefined,
                title,
                fieldType,
                valueType: 'STRING',
                stringValue: formatEmploymentStartDate(employment),
            };
        case 'CURRENT_EMPLOYMENT_COST_CENTERS':
            return {
                fieldDefinitionId: undefined,
                title,
                fieldType,
                valueType: 'STRING',
                stringValue: formatCostCentersAssignment(employment?.employmentCostCenters),
            };
    }
};
