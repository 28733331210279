import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';

import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { useCurrentPolicies } from '@/stores/store';
import { Card, CardContent, CardProps, Link, Stack, Typography } from '@mui/material';
import {
    ApiIcon,
    BankIcon as PayrollIcon,
    Building06Icon,
    Clock01Icon as TimesheetsIcon,
    DashboardSquare02Icon as PlanningIcon,
    File01Icon as DocumentIcon,
    Megaphone02Icon,
    MoneyBag02Icon as MoneyIcon,
    Notification02Icon,
    SquareLock01Icon,
    StarIcon as ReviewsIcon,
    Target01Icon as ObjectivesIcon,
    UploadCircle02Icon,
    UserMultiple02Icon as EmployeesIcon,
} from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router';
import { useGetExpenseSetting } from '@/hooks/expense-setting/ExpenseSetting.hook';
import {
    canManageAnnouncements,
    hasConfigureEmployeeSectionPolicy,
    hasConfigureExpensesPolicy,
    hasConfigureFolderPolicy,
    hasConfigureLeavePolicy,
    hasConfigureObjectivesPolicy,
    hasConfigureOrganizationPolicy,
    hasConfigurePayrollPolicy,
    hasConfigurePermissionPolicy,
    hasConfigurePlanningPolicy,
    hasConfigureReviewPolicy,
    hasConfigureThirdPartiesPolicy,
    hasConfigureTimeManagementPolicy,
    hasImportDataPolicy,
} from '@/domain/permission/Permission.service';

export const CompanySettingsPage: FC = () => {
    const { t } = useTranslation();

    const cards = useSettingCards();

    const filteredCards = cards
        // we don't want to show empty cards
        .filter(card => !!card.children?.length);

    return (
        <Stack direction={'row'} flex={1} gap={2} flexWrap={'wrap'}>
            {filteredCards.map(({ title, icon, children }) => (
                <Stack key={title} flex={1} minWidth={340}>
                    <CompanySettingsCard title={t(`company_settings.tabs.${title}`)} icon={icon}>
                        {children.map(({ title, link }) => (
                            <Link key={link} to={link} component={RouterLink}>
                                {t(`company_settings.tabs.${title}`)}
                            </Link>
                        ))}
                    </CompanySettingsCard>
                </Stack>
            ))}
        </Stack>
    );
};

const CompanySettingsCard: FC<{ title: string; icon: FC } & CardProps> = ({ title, icon: Icon, children, ...rest }) => {
    return (
        <Card sx={{ flex: 1, minHeight: '100%' }} {...rest}>
            <CardContent sx={{ padding: 3 }}>
                <Stack direction='row' alignItems='center' gap={1} pb={2}>
                    <Icon />
                    <Typography variant='h2' noWrap>
                        {title}
                    </Typography>
                </Stack>
                <Stack gap={1}>{children}</Stack>
            </CardContent>
        </Card>
    );
};

type SettingGroup = { title: string; icon: FC; children: { title: string; link: string }[] };

const useSettingCards = (): SettingGroup[] => {
    const policies = useCurrentPolicies();

    const objectivesGroup = useObjectivesGroup();
    const expenseGroup = useExpenseGroup(policies);

    return [
        buildOrganizationGroup(policies),
        buildTimeManagementGroup(policies),
        buildDocumentsGroup(policies),
        buildEmployeesGroup(policies),
        buildPlanningGroup(policies),
        buildReviewsGroup(policies),
        objectivesGroup,
        buildPermissionsGroup(policies),
        buildPayrollGroup(policies),
        buildImportsGroup(policies),
        expenseGroup,
        buildAnnouncementsGroup(policies),
        buildThirdPartiesGroup(policies),
        buildNotificationGroup(policies),
    ];
};

const buildOrganizationGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const organization: SettingGroup = {
        title: 'organization',
        icon: Building06Icon,
        children: [],
    };

    if (hasConfigureOrganizationPolicy(policies)) {
        organization.children.push({
            title: 'legal_units',
            link: 'organization/legal-units',
        });
        organization.children.push({
            title: 'departments',
            link: 'organization/departments',
        });
        organization.children.push({
            title: 'jobs',
            link: 'organization/jobs',
        });
        if (hasRealmFeatureEnabled(RealmFeaturesType.JOB_FAMILIES)) {
            organization.children.push({
                title: 'job_families',
                link: 'organization/job-families',
            });
            organization.children.push({
                title: 'job_levels',
                link: 'organization/job-levels',
            });
        }
        if (hasRealmFeatureEnabled(RealmFeaturesType.COST_CENTERS)) {
            organization.children.push({
                title: 'cost_centers',
                link: 'organization/cost-centers',
            });
        }
    }
    return organization;
};

const buildNotificationGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const organization: SettingGroup = {
        title: 'notifications',
        icon: Notification02Icon,
        children: [],
    };

    if (hasConfigureOrganizationPolicy(policies)) {
        organization.children.push({
            title: 'employees_notifications',
            link: 'notifications/employees-notifications',
        });
        organization.children.push({
            title: 'default_notifications',
            link: 'notifications/default-notifications',
        });
    }
    return organization;
};

const buildTimeManagementGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const timeManagement: SettingGroup = {
        title: 'time_management',
        icon: TimesheetsIcon,
        children: [],
    };

    if (hasRealmFeatureEnabled(RealmFeaturesType.LEAVES)) {
        if (hasConfigureOrganizationPolicy(policies)) {
            timeManagement.children.push({
                title: 'holidays_calendars',
                link: 'time-management/calendars',
            });
        }

        if (hasConfigureLeavePolicy(policies)) {
            timeManagement.children.push({
                title: 'leaves',
                link: 'time-management/leaves',
            });
        }

        if (hasConfigureTimeManagementPolicy(policies)) {
            timeManagement.children.push({
                title: 'base_working_time',
                link: 'time-management/base-working-time',
            });
            timeManagement.children.push({
                title: 'work_pattern_template',
                link: 'time-management/working-patterns',
            });

            if (hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET)) {
                timeManagement.children.push({
                    title: 'time_management_settings',
                    link: 'time-management/settings',
                });
            }
        }
    }

    return timeManagement;
};

const buildDocumentsGroup = (policies: EmployeePolicy[]): SettingGroup => {
    return {
        title: 'documents',
        icon: DocumentIcon,
        children:
            hasRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS) && hasConfigureFolderPolicy(policies)
                ? [
                      {
                          title: 'folders',
                          link: 'documents/folders',
                      },
                      {
                          title: 'document_tags',
                          link: 'documents/tags',
                      },
                  ]
                : [],
    };
};

const buildEmployeesGroup = (policies: EmployeePolicy[]): SettingGroup => {
    return {
        title: 'employees',
        icon: EmployeesIcon,
        children:
            hasRealmFeatureEnabled(RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE) && hasConfigureEmployeeSectionPolicy(policies)
                ? [
                      {
                          title: 'custom_fields',
                          link: 'employees/fields',
                      },
                  ]
                : [],
    };
};

const buildPlanningGroup = (policies: EmployeePolicy[]): SettingGroup => {
    return {
        title: 'planning',
        icon: PlanningIcon,
        children:
            hasRealmFeatureEnabled(RealmFeaturesType.PLANNING) && hasConfigurePlanningPolicy(policies)
                ? [
                      {
                          title: 'planning_options',
                          link: 'planning/options',
                      },
                      {
                          title: 'tags',
                          link: 'planning/planningTags',
                      },
                      {
                          title: 'positions',
                          link: 'planning/planningPositions',
                      },
                      {
                          title: 'shift_type',
                          link: 'planning/shift-type',
                      },
                      {
                          title: 'shift_breaks',
                          link: 'planning/shift-breaks',
                      },
                  ]
                : [],
    };
};

const buildReviewsGroup = (policies: EmployeePolicy[]): SettingGroup => ({
    title: 'reviews',
    icon: ReviewsIcon,
    children:
        hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && hasConfigureReviewPolicy(policies)
            ? [
                  {
                      title: 'templates',
                      link: 'reviews/templates',
                  },
                  {
                      title: 'skills',
                      link: 'reviews/skills',
                  },
                  {
                      title: 'skill_categories',
                      link: 'reviews/categories',
                  },
                  {
                      title: 'scales',
                      link: 'reviews/scales',
                  },
              ]
            : [],
});

const useObjectivesGroup = (): SettingGroup => {
    const policies = useCurrentPolicies();
    const canShowObjectives = hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && hasConfigureObjectivesPolicy(policies);

    const { data: objectiveSetting } = useGetObjectiveSetting();

    if (!canShowObjectives) {
        return {
            title: 'objectives',
            icon: ObjectivesIcon,
            children: [],
        };
    }

    const children = [
        {
            title: 'objective_settings',
            link: 'objectives/settings',
        },
    ];

    if (objectiveSetting?.objectiveCategoriesEnabled) {
        children.push({
            title: 'objective_categories',
            link: 'objectives/categories',
        });
    }

    children.push({
        title: 'completion_scale',
        link: 'objectives/completion_scale',
    });

    return {
        title: 'objectives',
        icon: ObjectivesIcon,
        children: children,
    };
};

const buildImportsGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const imports: SettingGroup = {
        title: 'imports',
        icon: UploadCircle02Icon,
        children: [],
    };

    if (hasImportDataPolicy(policies)) {
        imports.children.push({
            title: 'employees',
            link: 'import/employees',
        });

        imports.children.push({
            title: 'organization',
            link: 'import/organization',
        });

        if (hasRealmFeatureEnabled(RealmFeaturesType.LEAVES)) {
            imports.children.push({
                title: 'leaves',
                link: 'import/leaves',
            });
        }
        if (hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET)) {
            imports.children.push({
                title: 'timesheets',
                link: 'import/timesheets',
            });
        }

        if (hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS)) {
            imports.children.push({
                title: 'objectives',
                link: 'import/objectives',
            });

            imports.children.push({
                title: 'skills',
                link: 'import/skills',
            });
        }

        if (hasRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS)) {
            imports.children.push({
                title: 'documents',
                link: 'import/documents',
            });
        }
    }
    return imports;
};

const buildPayrollGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const payroll: SettingGroup = {
        title: 'payroll',
        icon: PayrollIcon,
        children: [],
    };

    if (hasRealmFeatureEnabled(RealmFeaturesType.PAYROLL) && hasConfigurePayrollPolicy(policies)) {
        payroll.children.push({
            title: 'payroll_fields',
            link: 'payroll',
        });
    }

    return payroll;
};

const useExpenseGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const expense: SettingGroup = {
        title: 'expenses',
        icon: MoneyIcon,
        children: [],
    };
    const { data: expenseSetting } = useGetExpenseSetting();

    if (hasRealmFeatureEnabled(RealmFeaturesType.EXPENSES) && hasConfigureExpensesPolicy(policies)) {
        expense.children.push({
            title: 'expenses_settings',
            link: 'expenses/setting',
        });
        expense.children.push({
            title: 'expenses_categories',
            link: 'expenses/categories',
        });
        if (expenseSetting?.expenseCategoryParentEnabled) {
            expense.children.push({
                title: 'expenses_category_groups',
                link: 'expenses/category-groups',
            });
        }
        expense.children.push({
            title: 'expenses_currencies',
            link: 'expenses/currencies',
        });
        expense.children.push({
            title: 'expenses_distance_rates',
            link: 'expenses/distance-rates',
        });
        if (expenseSetting?.expenseTaxesEnabled) {
            expense.children.push({
                title: 'expenses_taxes',
                link: 'expenses/taxes',
            });
        }
    }

    return expense;
};

const buildAnnouncementsGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const announcements: SettingGroup = {
        title: 'announcements',
        icon: Megaphone02Icon,
        children: [],
    };
    if (canManageAnnouncements(policies)) {
        announcements.children.push({
            title: 'announcements',
            link: 'announcements',
        });
    }
    return announcements;
};

const buildThirdPartiesGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const thirdParties: SettingGroup = {
        title: 'third_parties_groups',
        icon: ApiIcon,
        children: [],
    };
    if (hasRealmFeatureEnabled(RealmFeaturesType.THIRD_PARTIES) && hasConfigureThirdPartiesPolicy(policies)) {
        thirdParties.children.push({
            title: 'third_parties',
            link: 'third-parties',
        });

        thirdParties.children.push({
            title: 'third_party_mappings',
            link: 'third-party-mappings',
        });
    }
    return thirdParties;
};

const buildPermissionsGroup = (policies: EmployeePolicy[]): SettingGroup => {
    const permissions: SettingGroup = {
        title: 'permissions',
        icon: SquareLock01Icon,
        children: [],
    };

    if (hasConfigurePermissionPolicy(policies)) {
        permissions.children.push({
            title: 'permissions_base',
            link: 'permissions',
        });
    }

    if (hasRealmFeatureEnabled(RealmFeaturesType.PLANNING) && hasConfigurePlanningPolicy(policies)) {
        permissions.children.push({
            title: 'permissions_planning',
            link: 'permissions/planning',
        });
    }
    return permissions;
};
