import { ExpenseCurrency } from '@/domain/expense-currency/ExpenseCurrency.model';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import i18next from 'i18next';
import { getCurrencyLabel, isValidCurrencyCode } from '@/utils/currency.util';
import { useGetAvailableCurrencyCodes } from '@/hooks/currency-rate/CurrencyRate.hook';
import { StateHandler } from '@/components/state-handler/StateHandler';

type ExpenseCurrencySettingsDialogProps = {
    onSave: (data: ExpenseCurrencyFormValues) => void;
    currency: ExpenseCurrency | undefined;
} & DialogWrapperProps;

export const ExpenseCurrencySettingsDialog: FC<ExpenseCurrencySettingsDialogProps> = ({ open, onSave, onClose, currency }) => {
    const { t } = useTranslation();

    const { data: currencyCodes = [], isLoading, isError, error } = useGetAvailableCurrencyCodes();

    const { control, handleSubmit } = useForm<ExpenseCurrencyFormValues>({
        resolver: yupResolver(getCurrencySchema()),
        defaultValues: {
            code: currency?.code ?? '',
        },
    });

    const handleOnSave = (data: ExpenseCurrencyFormValues) => {
        onSave(data);
    };

    const title = currency ? t('expense_currencies_settings_page.dialog.edit_title') : t('expense_currencies_settings_page.dialog.add_title');

    return (
        <DialogWrapper header={title} open={open} onClose={onClose}>
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                <Stack component={DialogContent} gap={2}>
                    <FormControlLabel
                        label={t('expense_currencies_settings_page.dialog.currency_code_input')}
                        control={
                            <FieldSelect
                                control={control}
                                fullWidth
                                name='code'
                                options={currencyCodes}
                                getOptionLabel={getCurrencyLabel}
                                isOptionEqualToValue={(option, value) => option === value}
                            />
                        }
                    />
                </Stack>
                <DialogActions>
                    <Button onClick={handleSubmit(handleOnSave, console.error)} fullWidth>
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </StateHandler>
        </DialogWrapper>
    );
};

const getCurrencySchema = () => {
    return yup.object().shape({
        code: yup
            .string()
            .required()
            .test('is-valid-currency', i18next.t('general.validations.invalid_currency'), value => isValidCurrencyCode(value)),
    });
};

export type ExpenseCurrencyFormValues = yup.InferType<ReturnType<typeof getCurrencySchema>>;
