import { Page } from '@/page/Pages';
import { useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import { canApproveDeclineExpenses, canPayExpenseRequests } from '@/domain/permission/Permission.service';

export const useGetExpensePages = (): Page[] => {
    const employeeId = useCurrentEmployee().id;
    const policies = useCurrentPolicies();

    const allPages: Page[] = [
        {
            labelKey: 'expenses.tabs.pending',
            path: '/expenses/pending',
            condition: policies => canApproveDeclineExpenses(policies, employeeId),
        },
        {
            labelKey: 'expenses.tabs.to_control',
            path: '/expenses/to-control',
            condition: policies => canPayExpenseRequests(policies, employeeId),
        },
        {
            labelKey: 'expenses.tabs.paid',
            path: '/expenses/paid',
            condition: policies => canPayExpenseRequests(policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(policies));
};
