import { getObjectiveHistories } from '@/domain/objective-history/ObjectiveHistory.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const objectiveHistoryKeys = createQueryKeys('objectiveHistories', {
    getByObjectiveId: (...params: Parameters<typeof getObjectiveHistories>) => ({
        queryKey: params,
    }),
});

export const useGetObjectiveHistories = createRequiredParamsQueryHook(objectiveHistoryKeys.getByObjectiveId, getObjectiveHistories);
