import { DayOfWeek, MONTHS } from '@/utils/datetime.util';

export enum TimesheetMode {
    SIMPLIFIED = 'SIMPLIFIED',
    NORMAL = 'NORMAL',
}

export const TimesheetMissingModeArray = ['SHOW_MISSING', 'COUNT_AS_UNPAID_LEAVES', 'SHOW_MISSING_AND_COUNT_AS_UNPAID_LEAVES'] as const;
export type TimesheetMissingMode = (typeof TimesheetMissingModeArray)[number];

export type TimesheetSetting = {
    id: number;
    cycleStartMonth: MONTHS;
    name: string;
    timesheetMode: TimesheetMode;
    autofillTimesheet: boolean;
    countDailyDifference: boolean;
    includeShiftsIntoTimesheets: boolean;
    allowFutureInputs: boolean;
    nightBonusPercentage: number;
    nightBonusStartTime?: LocalTime;
    nightBonusEndTime?: LocalTime;
    saturdayBonusPercentage: number;
    saturdayBonusStartTime?: LocalTime;
    saturdayBonusEndTime?: LocalTime;
    saturdayFromDayOfWeek?: DayOfWeek;
    saturdayToDayOfWeek?: DayOfWeek;
    sundayBonusPercentage: number;
    sundayBonusStartTime?: LocalTime;
    sundayBonusEndTime?: LocalTime;
    sundayFromDayOfWeek?: DayOfWeek;
    sundayToDayOfWeek?: DayOfWeek;
    mandatoryComment: boolean;
    breakDisplayEnabled: boolean;
    mobileClockInOut: boolean;
    allowClockInOutOutsideWorkHours: boolean;
    allowClockInOutOnSundayAndPublicHolidays: boolean;
    forceBreakClockInOut: boolean;
    forceFirstBreakDurationInMinutes: number;
    forceFirstBreakAfterXHours: number;
    forceSecondBreakDurationInMinutes: number;
    forceSecondBreakAfterXHours: number;
    forceThirdBreakDurationInMinutes: number;
    forceThirdBreakAfterXHours: number;
    forceBreakToBeTakenFrom?: LocalTime;
    forceBreakToBeTakenTo?: LocalTime;
    paidBreaksSundayPublicHolidays: boolean;
    paidBreaksSundayPublicHolidaysMaximumDurationInMinutes: number;
    paidBreaksSaturday: boolean;
    paidBreaksSaturdayMaximumDurationInMinutes: number;
    rule1PaidBreaksFrom?: LocalTime;
    rule1PaidBreaksTo?: LocalTime;
    rule1PaidBreaksMaximumDurationInMinutes: number;
    rule2PaidBreaksFrom?: LocalTime;
    rule2PaidBreaksTo?: LocalTime;
    rule2PaidBreaksMaximumDurationInMinutes: number;
    forceShiftStartTimeOnClockIn: boolean;
    forceShiftStartTimeBeforeInMinutes: number;
    forceShiftStartTimeAfterInMinutes: number;
    forceShiftEndTimeOnClockOut: boolean;
    forceShiftEndTimeAfterInMinutes: number;
    forceShiftEndTimeBeforeInMinutes: number;
    timesheetMissingMode: TimesheetMissingMode;
    maximumWeeklyAdditionalWorkingTime: number;
    autoApproveDifferenceThresholdInMinutes?: number;
    missingTimesheetThresholdInMinutes?: number;
};

export type TimesheetSettingMutation = MakeOptional<Omit<TimesheetSetting, 'id'>, 'nightBonusPercentage' | 'saturdayBonusPercentage' | 'sundayBonusPercentage'>;
