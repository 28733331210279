import { searchObjectiveCategories } from '@/domain/objective-category/ObjectiveCategory.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const objectiveCategoryKeys = createQueryKeys('objectiveCategory', {
    search: (...optionalParam: Parameters<typeof searchObjectiveCategories>) => ({
        queryKey: [optionalParam ?? {}],
    }),
});

export const useGetObjectivesCategory = createQueryHook(objectiveCategoryKeys.search, searchObjectiveCategories);
