import { hexToRgb, useTheme } from '@mui/material';
import { getSkillsFromEmployeeSkills, groupEmployeeSkillsByEmployeeAvatar, groupEmployeeSkillsBySkill } from '@/domain/employee-skill/EmployeeSkill.service';
import { getLabelTranslation } from '@/utils/language.util';
import { EmployeeSkill } from '@/domain/employee-skill/EmployeeSkill.model';
import { rgbToRgba } from '@/utils/colors.util';
import { getRoundedNumber } from '@/utils/math.util';
import I18n from '@/i18n/i18n';
import { ColDef } from 'ag-grid-community';

export type EmployeeSkillData = Record<string, unknown>;

type SkillMatrixGrid = {
    columnDefs: ColDef<EmployeeSkillData>[];
    rowData: EmployeeSkillData[];
    pinnedBottomRowData: EmployeeSkillData[];
};

const MAX_SKILL_RATING = 6;
const MIN_RATING_FOR_WHITE_TEXT = 4;

/**
 * Prepares data for a skills matrix grid by:
 * 1. Managing cell opacity based on skill ratings.
 * 2. Building dynamic column definitions from skills.
 * 3. Grouping rows by employee and formatting their data.
 *
 * @param {EmployeeSkill[]} employeeSkills - List of employee skills.
 * @returns {SkillMatrixGrid} - Column definitions and row data for the grid.
 */
export const useSkillsMatrixGrid = (employeeSkills: EmployeeSkill[]): SkillMatrixGrid => {
    const theme = useTheme();

    const getOpacity = (value: number): number => value / MAX_SKILL_RATING;

    const getTextColorForRating = (value: Nullable<number>): string =>
        value && value >= MIN_RATING_FOR_WHITE_TEXT ? theme.palette.common.white : theme.palette.text.primary;

    const getCellStyle = ({ value: score }: { value: Nullable<number> }) => {
        const color = hexToRgb(theme.palette.tertiary.main);
        const colorWithOpacity = score ? rgbToRgba(color, getOpacity(score)) : 'none';
        return { textAlign: 'center', backgroundColor: colorWithOpacity, color: getTextColorForRating(score) };
    };

    const commonColumnDefs: ColDef<EmployeeSkillData, number | undefined> = {
        width: 175,
        cellStyle: getCellStyle,
        headerClass: 'header-center',
        valueFormatter: ({ value }) => (value?.toString() ?? 0) || '-',
    };

    const getColumnDefs = (): ColDef<EmployeeSkillData>[] => {
        const availableSkills = getSkillsFromEmployeeSkills(employeeSkills.filter(({ score }) => Boolean(score)));

        return [
            { field: 'employee', type: 'employee', pinned: 'left' },
            ...availableSkills.map(skill => ({
                field: skill.id.toString(),
                headerName: getLabelTranslation(skill.name),
                ...commonColumnDefs,
            })),
        ];
    };

    const getRowData = (): EmployeeSkillData[] => {
        const employeeSkillGroupedByEmployee = groupEmployeeSkillsByEmployeeAvatar(employeeSkills);

        return employeeSkillGroupedByEmployee.map(({ employee, employeeSkills }) => {
            const rowData: EmployeeSkillData = { employee };
            employeeSkills.forEach(({ skill, score }) => {
                rowData[skill.id.toString()] = score;
            });
            return rowData;
        });
    };
    const getAverageRatingForSkill = (employeeSkills: EmployeeSkill[], skillId: number): number | undefined => {
        const relevantSkills = employeeSkills.filter(({ skill, score }) => skill.id === skillId && Boolean(score));

        if (!relevantSkills.length) {
            return undefined;
        }

        const totalScore = relevantSkills.reduce((sum, { score }) => sum + (score ?? 0), 0);
        return getRoundedNumber(totalScore / relevantSkills.length);
    };

    const getPinnedBottomRowData = (employeeSkills: EmployeeSkill[]): EmployeeSkillData[] => {
        const employeeSkillGroupedBySkill = groupEmployeeSkillsBySkill(employeeSkills);
        return [
            {
                // average in display name is a hack
                employee: { id: 0, displayName: I18n.t('skills_matrix.average') },
                ...employeeSkillGroupedBySkill.reduce<EmployeeSkillData>((acc, { skill }) => {
                    acc[skill.id.toString()] = getAverageRatingForSkill(employeeSkills, skill.id);
                    return acc;
                }, {}),
            },
        ];
    };

    return {
        columnDefs: getColumnDefs(),
        rowData: getRowData(),
        pinnedBottomRowData: getPinnedBottomRowData(employeeSkills),
    };
};
