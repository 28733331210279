import { useTranslation } from 'react-i18next';
import { ObjectivesEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { FC } from 'react';
import { canCreateObjectives, canCreateOtherObjectives } from '@/domain/permission/Permission.service';
import { useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import { Add01Icon } from 'hugeicons-react';
import { Button } from '@mui/material';

type EmptyStateObjectivesProps = {
    employeeId: number;
    onOpenObjectiveDialog: () => void;
    isFromProfile: boolean;
};

export const EmptyStateObjectives: FC<EmptyStateObjectivesProps> = ({ employeeId, onOpenObjectiveDialog, isFromProfile }) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();
    const currentEmployeeId = useCurrentEmployee()?.id;
    // - On Objective Profile Page: User can create an objective if canCreateObjective(policies, employeeId) is true (for the employee of the profile).
    // - On Objectives Page: User can create an objective if canCreateOtherObjectives(policies, currentEmployeeId) is true (for someone else).
    const allowObjectiveCreation = isFromProfile ? canCreateObjectives(policies, employeeId) : canCreateOtherObjectives(policies, currentEmployeeId);
    return (
        <EmptyState
            icon={<ObjectivesEmptyStateIcon />}
            flex={1}
            title={t('objectives.no_objectives')}
            subTitle={allowObjectiveCreation ? t('objectives.ask_your_manager_for_a_objective') : undefined}
            action={
                allowObjectiveCreation ? (
                    <Button startIcon={<Add01Icon />} onClick={() => onOpenObjectiveDialog()}>
                        {t('objectives.objective')}
                    </Button>
                ) : undefined
            }
        />
    );
};
