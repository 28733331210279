import { FC } from 'react';
import { Alert, AlertTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentEmployee } from '@/stores/store';
import { impersonationLogout } from '@/domain/authentication/Authentication.service';
import { useLocalStorage } from '@/hooks/Storage.hook';

export const ImpersonationHeader: FC = () => {
    const { t } = useTranslation();
    const currentUser = useCurrentEmployee();
    const [value] = useLocalStorage('impersonatorAuthentication', undefined);
    const impersonationEnabled = !!value;
    const handleImpersonationLogout = () => {
        impersonationLogout();
        location.reload();
    };

    if (!currentUser || !impersonationEnabled) {
        return;
    }

    return (
        <Alert
            severity={'warning'}
            sx={{ borderRadius: 0 }}
            action={
                <Button variant={'text'} color='inherit' onClick={handleImpersonationLogout}>
                    {t('impersonation_header.logout_button')}
                </Button>
            }
        >
            <AlertTitle>{`${t('impersonation_header.title')} ${currentUser.displayName}`}</AlertTitle>
        </Alert>
    );
};
