import { useBreakPoints } from '@/components/use-break-points/useBreakPoints';
import { CurrentEmployeeProfileCard } from '@/page/employee-profile/CurrentEmployeeProfileCard';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';

export const EmployeeProfilePage: FC = () => {
    const { isMobile } = useBreakPoints();
    const navigate = useNavigate();

    useEffect(() => {
        // we don't want to display only the card in desktop
        if (!isMobile) {
            // relative path to handle the case when the user is on /profile/:employeeId or /profile
            navigate('personal-info', { replace: true });
        }
    }, [isMobile, navigate]);

    return <CurrentEmployeeProfileCard />;
};
