import { FilePickerItem, StoredFile, TemporaryFile } from '@/components/file-picker/FilePicker';

/**
 * Check if the file is a temporary file
 * @param file
 * @returns boolean
 */
export const isTemporaryFile = (file: FilePickerItem): file is TemporaryFile => {
    return 'data' in file;
};

/**
 * Check if the file is a stored file
 * @param file
 * @returns boolean
 */
export const isStoredFile = (file: FilePickerItem): file is StoredFile => {
    return !isTemporaryFile(file);
};
