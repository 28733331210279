import { getNull } from '@/utils/object.util';
import { Stack, Tooltip, Typography } from '@mui/material';
import { CustomHeaderProps } from 'ag-grid-react';
import { FC, MouseEventHandler, useEffect, useState } from 'react';

export type ReportColumnHeaderProps = CustomHeaderProps & {
    sectionDisplayName?: string;
    fieldDisplayName?: string;
};

export const ReportColumnHeader: FC<ReportColumnHeaderProps> = ({ sectionDisplayName, column, enableSorting, setSort, fieldDisplayName }) => {
    const [currentSortDirection, setCurrentSortDirection] = useState(column.getSort());

    const handleSort = (): MouseEventHandler<HTMLDivElement> => event => {
        const toggleSortDirection = () => {
            switch (currentSortDirection) {
                case 'asc':
                    return 'desc';
                case 'desc':
                    return getNull();
                default:
                    return 'asc';
            }
        };

        const sortDirection = toggleSortDirection();
        setSort(sortDirection, event.shiftKey);
    };

    useEffect(() => {
        column.addEventListener('sortChanged', () => {
            setCurrentSortDirection(column.getSort());
        });
        return () => {
            column.removeEventListener('sortChanged', () => {
                setCurrentSortDirection(column.getSort());
            });
        };
    }, [column]);

    const tooltipText = `${sectionDisplayName ? sectionDisplayName + ' / ' : ''}${fieldDisplayName}`;

    return (
        <Tooltip title={tooltipText}>
            <Stack
                direction='row'
                width='100%'
                alignItems='center'
                gap={0.25}
                onClick={enableSorting ? handleSort() : undefined}
                sx={{
                    cursor: enableSorting ? 'pointer' : 'default',
                }}
            >
                {/* sectionDisplayName takes all the space it needs and then shrinks in priority */}
                {!!sectionDisplayName && (
                    <>
                        <Typography
                            component='span'
                            variant='body1bold'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            flex={1}
                            minWidth={'22px'}
                            flexShrink={2}
                            flexGrow={1}
                            maxWidth='fit-content'
                        >
                            {sectionDisplayName}
                        </Typography>
                        <span>/</span>
                    </>
                )}
                {/* fieldDisplayName also shrinks if needed */}
                <Typography
                    component='span'
                    variant='body1bold'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    flex={1}
                    minWidth={'22px'}
                    flexShrink={1}
                    flexGrow={2}
                    maxWidth='fit-content'
                >
                    {fieldDisplayName}
                </Typography>
                <span className={`ag-icon ag-icon-${currentSortDirection}`} role='img' />
            </Stack>
        </Tooltip>
    );
};
