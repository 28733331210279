import { getEmployeeShifts, getLeaveShifts } from '@/domain/shift/Shift.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeShiftKeys = createQueryKeys('employeeShifts', {
    leaveShifts: (...params: Parameters<typeof getLeaveShifts>) => ({
        queryKey: params,
    }),
    search: (...params: Parameters<typeof getEmployeeShifts>) => ({
        queryKey: params,
    }),
});

export const useGetLeaveShifts = createQueryHook(employeeShiftKeys.leaveShifts, getLeaveShifts);

export const useGetEmployeeShifts = createQueryHook(employeeShiftKeys.search, getEmployeeShifts);
