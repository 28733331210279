import { getPlanningSettings } from '@/domain/planning-setting/PlanningSetting.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const planningSettingsKeys = createQueryKeys('planning', {
    search: (...optionalParam: Parameters<typeof getPlanningSettings>) => ({
        queryKey: [optionalParam ?? {}],
    }),
});

export const useGetPlanningSettings = createQueryHook(planningSettingsKeys.search, getPlanningSettings);
