import { getLocationById, getLocations } from '@/domain/location/Location.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const locationKeys = createQueryKeys('locations', {
    getAll: {
        queryKey: getNull(),
    },
    getById: (...params: Parameters<typeof getLocationById>) => ({
        queryKey: params,
    }),
});

export const useGetLocations = createQueryHook(locationKeys.getAll, getLocations);

export const useGetLocation = createRequiredParamsQueryHook(locationKeys.getById, getLocationById);
