import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { StackedAvatars } from '@/domain-ui/employee/stacked-avatar/StackedAvatars';
import { Employee, EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import { getDaysUntilNextBirthday, getUserStatusTranslationKey, uploadAvatar } from '@/domain/employee/Employee.service';
import { EmploymentCreateReason, EmploymentStatus } from '@/domain/employment/Employment.model';
import { getCurrentPrincipalEmployment, getDaysUntilProbationEnd } from '@/domain/employment/Employment.service';
import {
    canManageEmployeeAvatars,
    canOnboardOffboardEmployees,
    canSeeOtherEmployeeLeaves,
    canViewAtLeastOneProfileInfoTab,
    canViewEmployeeBirthdays,
    canViewEmployeeContracts,
    canViewEmployeeEmails,
    canViewEmployeePhoneNumbers,
    canViewEmployeesBasicInfo,
    canViewTimesheets,
    hasConfigurePermissionPolicy,
    hasManageThirdPartiesLogsPolicy,
} from '@/domain/permission/Permission.service';
import {
    employeeKeys,
    useGetEmployee,
    useGetEmployeeEmail,
    useGetEmployeeLeaveTypePolicies,
    useGetEmployeePhoneNumber,
    useGetEmployeesBirthdays,
} from '@/hooks/employee/Employee.hook';
import { EmployeeProfileActionButton } from '@/page/employee-profile/employee-profile-info/EmployeeProfileActionButton/EmployeeProfileActionButton';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, ButtonBase, Chip, CircularProgress, IconButton, Link, Paper, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Department } from '@/domain/department/Department.model';
import { getEmployeeLeaveTypeToDisplay } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { Location } from '@/domain/location/Location.model';
import { PermissionGroup } from '@/domain/permission-group/PermissionGroup.model';
import { TimesheetSearch, TimesheetsRequestStatus } from '@/domain/timesheet/Timesheet.model';
import { getOverlappingTimesheets } from '@/domain/timesheet/Timesheet.service';
import { MobileProfileTabsNavigation } from '@/page/employee-profile/employee-profile-mobile-navigation/MobileProfileTabsNavigation';
import { useCurrentPolicies } from '@/stores/store';
import { differenceInDays, getCurrentLocalDate, getEndOfYear, getStartOfYear, isSameDate, toDate } from '@/utils/datetime.util';

import { FilePickerModal, TemporaryFile } from '@/components/file-picker/FilePicker';
import { useBreakPoints } from '@/components/use-break-points/useBreakPoints';
import { getUniqueTranslationLabels } from '@/domain/label/Label.service';
import { useGetEmployeeAvailabilities } from '@/hooks/employee-availability/EmployeeAvailability.hook';
import { useGetPermissionGroupsByEmployeeId } from '@/hooks/permission/Permission.hook';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';
import { useGetTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { EmployeeThirdPartyLogs } from '@/page/employee-profile/employee-profile-info/EmployeeThirdPartyLogs/EmployeeThirdPartyLogs';
import { EmployeeWorkingAreaOrLeaveChip } from '@/page/employee/employee-working-area-or-leave-chip/EmployeeWorkingAreaOrLeaveChip';
import { formatPhoneNumber } from '@/utils/strings.util';
import { useQueryClient } from '@tanstack/react-query';
import { Call02Icon, CallIcon, Mail01Icon, SquareLock02Icon, UserSquareIcon } from 'hugeicons-react';

type EmployeeProfileCardProps = {
    employeeId: number;
    currentEmployeeId: number;
};

export const EmployeeProfileCard: FC<EmployeeProfileCardProps> = ({ employeeId, currentEmployeeId }) => {
    // Load employee data
    const { data: employee, isLoading: isEmployeeByIdLoading, isError: isEmployeeByIdError, error: employeeError } = useGetEmployee(employeeId);

    const isLoading = isEmployeeByIdLoading;

    return (
        <Stack component={Paper} p={2} top={0} bottom={0} flexGrow={1} position={'sticky'}>
            <StateHandler loadingComponent={<EmployeeProfileCardSkeleton />} isLoading={isLoading} isError={isEmployeeByIdError} error={employeeError}>
                {employee && (
                    <Stack gap={3} height={'100%'}>
                        <AvatarInfo employee={employee} />
                        <EmployeeInfos employee={employee} currentEmployeeId={currentEmployeeId} />
                    </Stack>
                )}
            </StateHandler>
        </Stack>
    );
};

const EmployeeProfileCardSkeleton: FC = () => {
    return (
        <Stack gap={3} height={'100%'}>
            <AvatarInfoSkeleton />
            <EmployeeInfosSkeleton />
        </Stack>
    );
};

const AvatarInfoSkeleton: FC = () => {
    return (
        <Stack gap={2}>
            <Skeleton variant='circular' width={120} height={120} />
            <Stack>
                <Skeleton variant='text' width={120} height={36} />
                <Skeleton variant='text' width={100} height={24} />
            </Stack>
        </Stack>
    );
};

const EmployeeInfosSkeleton: FC = () => {
    return (
        <Stack justifyContent={'space-between'} flex={1}>
            <Stack height={'100%'} gap={0.5}>
                <Skeleton variant='text' width={220} height={24} />
                <Skeleton variant='text' width={160} height={24} />
                <Skeleton variant='text' width={120} height={24} />
                <Skeleton variant='text' width={220} height={24} />
                <Skeleton variant='text' width={160} height={24} />
                <Skeleton variant='text' width={120} height={24} />
            </Stack>
            <Skeleton variant='rounded' height={28} width={'60%'} />
        </Stack>
    );
};

type AvatarInfoProps = {
    employee: Employee;
};

const AvatarInfo: FC<AvatarInfoProps> = ({ employee }) => {
    const [isAvatarLoading, setIsAvatarLoading] = useState(false);

    const policies = useCurrentPolicies();
    const queryClient = useQueryClient();
    const canChangeAvatar = canManageEmployeeAvatars(policies, employee?.id);

    const handleFilesUpload = async (file: TemporaryFile) => {
        try {
            setIsAvatarLoading(true);
            await uploadAvatar(employee.id, file.data);
            await queryClient.invalidateQueries(employeeKeys.avatar(employee.id));
            setIsAvatarLoading(false);
        } catch (error) {
            handleError(error);
        }
    };

    const disabled = !policies || !canChangeAvatar || isAvatarLoading;

    return (
        <Stack alignItems={'flex-start'} gap={2}>
            <FilePickerModal disabled={disabled} onFilePicked={handleFilesUpload}>
                {isAvatarLoading ? (
                    <CircularProgress size={120} thickness={1} />
                ) : (
                    <ButtonBase disableRipple disabled={disabled}>
                        <EmployeeAvatar sx={{ width: '120px', height: '120px', fontSize: '50px' }} employeeAvatar={employee} />
                    </ButtonBase>
                )}
            </FilePickerModal>

            <Stack>
                <Typography variant={'h1'} color={'text.primary'}>
                    {employee?.displayName}
                </Typography>
                <Typography variant={'h2'} color={'text.secondary'}>
                    {employee?.jobTitles?.map(title => getLabelTranslation(title)).join(', ')}
                </Typography>
            </Stack>
        </Stack>
    );
};

type EmployeeInfosProps = {
    employee: Employee;
    currentEmployeeId: number;
};

const EmployeeInfos: FC<EmployeeInfosProps> = ({ employee, currentEmployeeId }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const employeeId = employee.id;
    const { isMobile } = useBreakPoints();

    const policies = useCurrentPolicies();

    const showPhoneNumber = canViewEmployeePhoneNumbers(policies, employee.id);
    const { data: employeePhoneNumber } = useGetEmployeePhoneNumber(employee.id, { options: { enabled: showPhoneNumber } });

    const showEmail = canViewEmployeeEmails(policies, employee.id);
    const { data: employeeEmail } = useGetEmployeeEmail(employee.id, { options: { enabled: showEmail } });

    const showContractInfo = canViewEmployeeContracts(policies, employee.id);
    const showEmployeeBasicInfo = canViewEmployeesBasicInfo(policies, employee.id);
    const employeeCode = showEmployeeBasicInfo ? employee?.employeeCode : undefined;

    const { data: sectionDefinitions = [] } = useGetSectionDefinitions();

    const showNavigationButton = canViewAtLeastOneProfileInfoTab(
        policies,
        employee.id,
        sectionDefinitions.map(({ id }) => id),
    );

    const currentEmployment = getCurrentPrincipalEmployment(employee);

    const principalDepartment = currentEmployment?.department;
    const secondaryDepartments = employee.currentEmployments
        .map(employment => employment.department)
        .filter(department => department.id !== principalDepartment?.id);

    const principalLocation = currentEmployment?.location;
    const secondaryLocations = employee.currentEmployments.map(employment => employment.location).filter(location => location.id !== principalLocation?.id);

    // Set managers from principal employment at first, then add managers from other employments
    const managers = [...(currentEmployment?.managers ?? []), ...employee.currentEmployments.flatMap(employment => employment.managers)];

    const isViewBirthdayEnabled = !!employeeId && canViewEmployeeBirthdays(policies, employeeId);

    const birthdaysSearch = {
        startDate: getStartOfYear(),
        endDate: getEndOfYear(),
        employeeIds: employeeId ? [employeeId] : [],
    };
    const { data: employeeBirthdays = [] } = useGetEmployeesBirthdays(birthdaysSearch, { enabled: isViewBirthdayEnabled });

    const birthday = employeeBirthdays.at(0)?.anniversaryDate;

    const clearEmployeeCache = () => {
        queryClient.invalidateQueries(employeeKeys.employeeById(employeeId));
        queryClient.invalidateQueries(employeeKeys.birthdays(birthdaysSearch));
    };

    return (
        <Stack justifyContent={'space-between'} flex={1}>
            <Stack height={'100%'} gap={0.5}>
                {isMobile ? (
                    <ActionsBar
                        showNavigationButton={showNavigationButton}
                        employeeId={employee.id}
                        employeePhoneNumber={employeePhoneNumber}
                        employeeEmail={employeeEmail}
                    />
                ) : (
                    <EmployeeDetails employeeCode={employeeCode} employeeEmail={employeeEmail} employeePhoneNumber={employeePhoneNumber} />
                )}
                <DepartmentInfo principalDepartment={principalDepartment} secondaryDepartments={secondaryDepartments} />
                <LocationInfo principalLocation={currentEmployment?.location} secondaryLocations={secondaryLocations} />
                <ManagersInfo managers={managers} />
                <SubordinateInfo subordinates={employee?.subordinates ?? []} />
                {hasConfigurePermissionPolicy(policies) && <PermissionsGroupsInfo employeeId={employee.id} />}
                {showContractInfo && (
                    <Stack gap={1}>
                        <ContractStartInfoTag
                            employmentStartDate={currentEmployment?.startDate}
                            employmentCreateReason={currentEmployment?.employmentCreateReason}
                            employmentStatus={employee?.employmentStatus}
                        />
                        <ProbationEndInfoTag probationEndDate={currentEmployment?.probationEndDate} employmentStartDate={currentEmployment?.startDate} />
                        <EmployeeAnniversaryInfoTag birthday={birthday} />
                    </Stack>
                )}
                <CurrentWorkingAreaOrLeaveInfo employeeId={employee.id} currentEmployeeId={currentEmployeeId} />
            </Stack>
            <Stack gap={1}>
                {hasManageThirdPartiesLogsPolicy(policies) && <EmployeeThirdPartyLogs employee={employee} />}
                {canOnboardOffboardEmployees(policies) && (
                    <>
                        <Typography variant={'body1'}>
                            {t('employee.login_status_label')} : <b>{t(getUserStatusTranslationKey(), { context: employee.status })}</b>
                        </Typography>
                        <EmployeeProfileActions employee={employee} onSuccess={clearEmployeeCache} />
                    </>
                )}
            </Stack>
        </Stack>
    );
};

type CurrentLocationInfoProps = {
    employeeId: number;
    currentEmployeeId: number;
};
const CurrentWorkingAreaOrLeaveInfo: FC<CurrentLocationInfoProps> = ({ employeeId, currentEmployeeId }) => {
    const policies = useCurrentPolicies();
    const canViewLeaves = canSeeOtherEmployeeLeaves(policies, currentEmployeeId);
    const canViewTimesheet = canViewTimesheets(policies, employeeId);

    const leave = useGetLeave(employeeId, canViewLeaves);
    const timesheet = useGetTimesheet(employeeId, canViewTimesheet);

    const currentWorkingArea = leave ?? timesheet;

    return (
        <Stack direction={'row'} gap={1} alignItems={'left'} height={24} alignSelf={'left'}>
            <EmployeeWorkingAreaOrLeaveChip currentWorkingAreaLeave={currentWorkingArea} />
        </Stack>
    );
};

type EmployeeDetailsProps = {
    employeeCode: string | undefined;
    employeeEmail: string | undefined;
    employeePhoneNumber: string | undefined;
};

const EmployeeDetails: FC<EmployeeDetailsProps> = ({ employeeCode, employeeEmail, employeePhoneNumber }) => {
    if (!employeeEmail && !employeePhoneNumber && !employeeCode) {
        return;
    }

    return (
        <Stack>
            {employeeCode && (
                <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
                    <UserSquareIcon size={20} />
                    <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {employeeCode}
                    </Typography>
                </Stack>
            )}

            {employeeEmail && (
                <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
                    <Mail01Icon size={20} />
                    <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {employeeEmail}
                    </Typography>
                </Stack>
            )}

            {employeePhoneNumber && (
                <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
                    <Call02Icon size={20} />
                    <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {formatPhoneNumber(employeePhoneNumber)}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};

type ActionsBarProps = {
    employeeId: number;
    employeeEmail: string | undefined;
    employeePhoneNumber: string | undefined;
    showNavigationButton: boolean;
};

const ActionsBar: FC<ActionsBarProps> = ({ employeeId, employeeEmail, employeePhoneNumber, showNavigationButton }) => {
    const { t } = useTranslation();
    const [openMenu, setOpenMenu] = useState(false);

    if (!employeeEmail && !employeePhoneNumber && !showNavigationButton) {
        return;
    }
    return (
        <Stack direction={'row'} alignItems='center' gap={1} py={0.5}>
            {employeeEmail && (
                <Link href={`mailto:${employeeEmail}`}>
                    <Button sx={{ p: 1, minWidth: 'auto' }} aria-label={t('employee_profile.email')}>
                        <Mail01Icon size={16} />
                    </Button>
                </Link>
            )}
            {employeePhoneNumber && (
                <Link href={`tel:${employeePhoneNumber}`}>
                    <Button sx={{ p: 1, minWidth: 'auto' }} aria-label={t('employee_profile.phone_number')}>
                        <CallIcon size={16} />
                    </Button>
                </Link>
            )}
            {showNavigationButton && (
                <>
                    <Button onClick={() => setOpenMenu(true)} sx={{ flex: 1 }}>
                        {t('employee_profile.go_to')}
                    </Button>
                    <MobileProfileTabsNavigation employeeId={employeeId} open={openMenu} onClose={() => setOpenMenu(false)} />
                </>
            )}
        </Stack>
    );
};

type DepartmentInfoProps = {
    principalDepartment?: Department;
    secondaryDepartments?: Department[];
};

const DepartmentInfo: FC<DepartmentInfoProps> = ({ principalDepartment, secondaryDepartments = [] }) => {
    const { t } = useTranslation();
    if (!principalDepartment?.name) {
        return;
    }

    const [principalName, ...restNames] = getUniqueTranslationLabels([principalDepartment, ...secondaryDepartments].map(({ name }) => name));
    const hasSecondaries = !!restNames.length;
    const allNamesJoined = [principalName, ...restNames].join(', ');
    const extra = hasSecondaries ? `(+${restNames.length})` : '';

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.department')}
            </Typography>
            <Tooltip title={allNamesJoined}>
                <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {`${principalName} ${extra}`}
                </Typography>
            </Tooltip>
        </Stack>
    );
};

type LocationInfoProps = {
    principalLocation?: Location;
    secondaryLocations?: Location[];
};

const LocationInfo: FC<LocationInfoProps> = ({ principalLocation, secondaryLocations = [] }) => {
    const { t } = useTranslation();
    if (!principalLocation?.name && !principalLocation?.city) {
        return;
    }

    const getUniqueLocationNames = (locations: Location[]): string[] => {
        return Array.from(new Set(locations.map(location => location.name)));
    };

    const [principalName, ...restNames] = getUniqueLocationNames([principalLocation, ...secondaryLocations]);
    const hasSecondaries = !!restNames.length;
    const allNamesJoined = [principalName, ...restNames].join(', ');
    const extra = hasSecondaries ? `(+${restNames.length})` : '';

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.location')}
            </Typography>
            <Tooltip title={hasSecondaries ? allNamesJoined : undefined}>
                <Typography variant={'body1bold'} color={'text.secondary'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {`${principalName} ${extra}`}
                </Typography>
            </Tooltip>
        </Stack>
    );
};

type ManagersInfoProps = {
    managers: EmployeeAvatarType[];
};

const ManagersInfo: FC<ManagersInfoProps> = ({ managers }) => {
    const { t } = useTranslation();

    if (!managers.length) {
        return;
    }

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.manager')}
            </Typography>
            <StackedAvatars employeeAvatars={managers} profileLink />
        </Stack>
    );
};

type SubordinateInfoProps = {
    subordinates: Employee[];
};

const SubordinateInfo: FC<SubordinateInfoProps> = ({ subordinates }) => {
    const { t } = useTranslation();

    if (!subordinates?.length) {
        return;
    }

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.direct_reports')}
            </Typography>
            <StackedAvatars employeeAvatars={subordinates} profileLink />
        </Stack>
    );
};

type ContractStartInfoTagProps = {
    employmentStartDate?: LocalDate;
    employmentStatus?: EmploymentStatus;
    employmentCreateReason?: EmploymentCreateReason;
};

export const ContractStartInfoTag: FC<ContractStartInfoTagProps> = ({ employmentStartDate, employmentStatus, employmentCreateReason }) => {
    const { t } = useTranslation();

    const getDaysUntilContractStart = (employmentStartDate?: LocalDate) => {
        if (!employmentStartDate) {
            return 0;
        }
        const today = getCurrentLocalDate();
        const diffInDays = differenceInDays(employmentStartDate, today);
        if (diffInDays === 0 && isSameDate(employmentStartDate, today)) {
            return 0;
        }
        if (diffInDays < 0) {
            return diffInDays;
        }
        return diffInDays;
    };

    const getDaysUntilContractStartLabel = (daysUntilContractStart: number) => {
        return t('employee_profile.contract_start_future_employee_info_tag', {
            count: daysUntilContractStart,
        });
    };

    const daysUntilContractStart = getDaysUntilContractStart(employmentStartDate);
    const isContractStartDateInFuture = daysUntilContractStart >= 0;
    const isEmployeeHired = employmentStatus === EmploymentStatus.HIRED || employmentStatus === EmploymentStatus.EMPLOYED;
    const isNewEmployee = employmentCreateReason === EmploymentCreateReason.NEW_EMPLOYEE || employmentCreateReason === EmploymentCreateReason.REHIRED;
    const showLabelForFutureEmployee = isContractStartDateInFuture && isEmployeeHired;

    if (!showLabelForFutureEmployee || !isNewEmployee) {
        return;
    }

    return <Chip icon={<>🚀</>} color='error' label={getDaysUntilContractStartLabel(daysUntilContractStart)} />;
};

type ProbationEndInfoTagProps = {
    probationEndDate?: LocalDate;
    employmentStartDate?: LocalDate;
};

export const ProbationEndInfoTag: FC<ProbationEndInfoTagProps> = ({ probationEndDate, employmentStartDate }) => {
    const { t } = useTranslation();
    const getDaysUntilProbationEndLabel = (daysUntilProbationEnd: number) => {
        return t('employee_profile.probation_end_info_tag', {
            count: daysUntilProbationEnd,
        });
    };

    const showProbationEndInfoTag = (probationEndDate?: LocalDate, employmentStartDate?: LocalDate) => {
        const today = getCurrentLocalDate();
        if (!probationEndDate || !employmentStartDate) {
            return false;
        }
        const isTodayAfterEmploymentStartDate = toDate(today) > toDate(employmentStartDate);
        const isTodayBeforeProbationEndDate = toDate(today) < toDate(probationEndDate) || isSameDate(today, probationEndDate);
        return isTodayAfterEmploymentStartDate && isTodayBeforeProbationEndDate;
    };

    if (!showProbationEndInfoTag(probationEndDate, employmentStartDate)) {
        return;
    }

    const daysUntilProbationEnd = probationEndDate ? getDaysUntilProbationEnd(probationEndDate) : 0;
    const daysUntilProbationEndLabel = getDaysUntilProbationEndLabel(daysUntilProbationEnd);

    return <Chip icon={<>🤝</>} color='warning' label={daysUntilProbationEndLabel} />;
};

type EmployeeAnniversaryInfoTagProps = {
    birthday: LocalDate | undefined;
};
export const EmployeeAnniversaryInfoTag: FC<EmployeeAnniversaryInfoTagProps> = ({ birthday }) => {
    const { t } = useTranslation();

    const getDaysUntilBirthdayLabel = (birthday: LocalDate) => {
        const daysUntilBirthday = getDaysUntilNextBirthday(birthday);
        return t('employee_profile.birthday_info_tag', { daysUntilBirthday, count: daysUntilBirthday });
    };
    const showBirthdayInfoTag = (birthday: LocalDate) => {
        const daysUntilBirthday = getDaysUntilNextBirthday(birthday);

        const maxDaysToShowBirthdayInfoTag = 1;
        return daysUntilBirthday <= maxDaysToShowBirthdayInfoTag;
    };

    if (!birthday || !showBirthdayInfoTag(birthday)) {
        return;
    }

    return <Chip icon={<>🥳</>} color='info' label={getDaysUntilBirthdayLabel(birthday)} />;
};

const PermissionsGroupsInfo: FC<{ employeeId: number }> = ({ employeeId }) => {
    const { t } = useTranslation();

    const policies = useCurrentPolicies();

    const { data: permissionGroupEmployee = [] } = useGetPermissionGroupsByEmployeeId(employeeId, {
        options: { enabled: hasConfigurePermissionPolicy(policies) },
    });

    const showPermissionGroupsInfo = (permissionGroupEmployee: PermissionGroup[]) => {
        return !!permissionGroupEmployee?.length;
    };

    if (!showPermissionGroupsInfo(permissionGroupEmployee)) {
        return;
    }
    return (
        <Stack direction={'row'} alignItems={'center'} gap={0.5} height={32}>
            <Typography variant={'body1'} color={'text.secondary'}>
                {t('employee_profile.permissions_groups')}
            </Typography>
            <Tooltip
                title={permissionGroupEmployee
                    .map(permissionGroup => {
                        return permissionGroup.groupName;
                    })
                    .join(', ')}
            >
                <IconButton sx={{ padding: 0 }} disableRipple>
                    <SquareLock02Icon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

type EmployeeProfileActionsProps = {
    employee: Employee;
    onSuccess: () => void;
};

const EmployeeProfileActions: FC<EmployeeProfileActionsProps> = ({ employee, onSuccess }) => {
    return (
        <Stack direction='row' justifyContent='flex-start' alignItems='center'>
            <EmployeeProfileActionButton onSuccess={onSuccess} employee={employee} />
        </Stack>
    );
};

const useGetLeaveReturn = (employeeId: number) => {
    const { data = [] } = useGetEmployeeAvailabilities({
        atDateTime: getCurrentLocalDate(),
        employeeIds: [employeeId],
    });

    return data.at(0);
};

const useGetLeave = (employeeId: number, enabled: boolean) => {
    const leaveReturnInfo = useGetLeaveReturn(employeeId);

    const { data: userLeavePolicies = [] } = useGetEmployeeLeaveTypePolicies(undefined, { enabled });

    if (leaveReturnInfo?.leaveTypeId) {
        const leaveTypePolicy = getEmployeeLeaveTypeToDisplay(userLeavePolicies, employeeId, leaveReturnInfo.leaveTypeId);
        return {
            displayText: getLabelTranslation(leaveTypePolicy?.name),
            color: leaveTypePolicy?.color,
            leaveEndDate: leaveReturnInfo.returnDate,
            leaveReturnDayPeriod: leaveReturnInfo.returnPeriod,
            leavePercentage: leaveReturnInfo.leavePercentage,
            type: 'LEAVE' as const,
        };
    }
    return undefined;
};

const useGetTimesheet = (employeeId: number, enabled: boolean) => {
    const request: TimesheetSearch = {
        employeeIds: [employeeId],
        startDate: getCurrentLocalDate(),
        endDate: getCurrentLocalDate(),
        statuses: [TimesheetsRequestStatus.APPROVED, TimesheetsRequestStatus.PENDING],
    };

    const { data: timesheets = [] } = useGetTimesheets(request, { enabled });

    const timesheet = getOverlappingTimesheets(timesheets);
    if (timesheet) {
        return {
            displayText: timesheet?.area?.name ?? '',
            color: timesheet?.area?.color,
            type: 'TIMESHEET' as const,
            leaveEndDate: undefined,
            leaveReturnDayPeriod: undefined,
            leavePercentage: undefined,
        };
    }
    return undefined;
};
