import { Button, FormControl, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EnterRealmName: FC = () => {
    const { t } = useTranslation();
    const [realm, setRealm] = useState<string>('');

    const updateRealmName = (event: string) => {
        setRealm(event);
    };

    const newUrl = splitUrl().slice(1);
    const domain = newUrl.join('.');

    const changeRealm = () => {
        const prefix = splitUrl()[0];
        const newUrl = window.location.host.replace(prefix, realm);

        // if we are in dev mode we need to use http, if not, we have ERR_SSL_PROTOCOL_ERROR
        const env = process.env.NODE_ENV;
        if (env === 'development') {
            window.location.href = `http://${newUrl}`;
            return;
        }

        window.location.href = `https://${newUrl}`;
    };

    return (
        <Stack direction={'column'} spacing={2}>
            <Typography variant='body2bold'>{t('login_form.realm_not_found')}</Typography>
            <FormControl fullWidth={true} variant='outlined' size='small'>
                <TextField
                    placeholder={t('login_form.your_company')}
                    id='email-input'
                    value={realm}
                    slotProps={{
                        input: {
                            endAdornment: <InputAdornment position='end'>.{domain}</InputAdornment>,
                        },
                    }}
                    onChange={event => updateRealmName(event.target.value)}
                />
            </FormControl>
            <Button fullWidth disabled={!realm} onClick={changeRealm}>
                {t('login_form.access_roger')}
            </Button>
        </Stack>
    );
};

const splitUrl = (): string[] => {
    return window.location.host.split('.');
};
