import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { CreateExpenseTaxMutation, EditExpenseTaxMutation, ExpenseTax, ExpenseTaxSearch } from '@/domain/expense-tax/ExpenseTax.model';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

type ExpenseTaxDTO = Overwrite<
    ExpenseTax,
    {
        archivedAt?: string;
    }
>;

type ExpenseTaxCreateRequestDTO = CreateExpenseTaxMutation;
type ExpenseTaxUpdateRequestDTO = EditExpenseTaxMutation;
type ExpenseTaxSearchRequestDTO = ExpenseTaxSearch;

const EXPENSE_TAX_API_BASE_PATH = API_BASE_URL + '/expense-taxes';

const mapExpenseTaxDTO = (dto: ExpenseTaxDTO): ExpenseTax => {
    return {
        ...dto,
        archivedAt: dto.archivedAt ? convertUTCIsoStringToDate(dto.archivedAt) : undefined,
    };
};

const mapCreateMutationToDTO = (mutation: CreateExpenseTaxMutation): ExpenseTaxCreateRequestDTO => mutation;

const mapUpdateMutationToDTO = (mutation: EditExpenseTaxMutation): ExpenseTaxUpdateRequestDTO => mutation;

const searchExpenseTaxes = async (search: ExpenseTaxSearch): Promise<ExpenseTax[]> => {
    const { data } = await client.post<ExpenseTaxDTO[], AxiosResponse<ExpenseTaxDTO[]>, ExpenseTaxSearchRequestDTO>(
        `${EXPENSE_TAX_API_BASE_PATH}/search`,
        search,
    );
    return data.map(mapExpenseTaxDTO);
};

const createExpenseTax = async (mutation: CreateExpenseTaxMutation): Promise<ExpenseTax> => {
    const request = mapCreateMutationToDTO(mutation);
    const { data } = await client.post<ExpenseTaxDTO, AxiosResponse<ExpenseTaxDTO>, ExpenseTaxCreateRequestDTO>(EXPENSE_TAX_API_BASE_PATH, request);
    return mapExpenseTaxDTO(data);
};

const updateExpenseTax = async (expenseTaxId: number, mutation: EditExpenseTaxMutation): Promise<ExpenseTax> => {
    const request = mapUpdateMutationToDTO(mutation);
    const { data } = await client.put<ExpenseTaxDTO, AxiosResponse<ExpenseTaxDTO>, ExpenseTaxUpdateRequestDTO>(
        `${EXPENSE_TAX_API_BASE_PATH}/${expenseTaxId}`,
        request,
    );
    return mapExpenseTaxDTO(data);
};

const deleteExpenseTax = async (expenseTaxId: number): Promise<void> => {
    await client.delete(`${EXPENSE_TAX_API_BASE_PATH}/${expenseTaxId}`);
};

const archiveExpenseTax = async (expenseTaxId: number): Promise<ExpenseTax> => {
    const { data } = await client.post<ExpenseTaxDTO, AxiosResponse<ExpenseTaxDTO>>(`${EXPENSE_TAX_API_BASE_PATH}/${expenseTaxId}/archive`);
    return mapExpenseTaxDTO(data);
};

const unarchiveExpenseTax = async (expenseTaxId: number): Promise<ExpenseTax> => {
    const { data } = await client.post<ExpenseTaxDTO, AxiosResponse<ExpenseTaxDTO>>(`${EXPENSE_TAX_API_BASE_PATH}/${expenseTaxId}/unarchive`);
    return mapExpenseTaxDTO(data);
};

export const expenseTaxApi = {
    searchExpenseTaxes,
    createExpenseTax,
    updateExpenseTax,
    deleteExpenseTax,
    archiveExpenseTax,
    unarchiveExpenseTax,
};
