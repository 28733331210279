import { Button, Paper, Stack, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { ClampedTypography } from '@/components/typography/ClampedTypography';
import { SurveyAnswerForm, SurveyQuestionForm } from '@/page/survey/SurveyForm.util';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';

type Props = {
    onUpdate: (content: string, anonymous: boolean) => void;
    onSubmit: () => void;
    question: SurveyQuestionForm;
    answer: SurveyAnswerForm;
    selectedLanguage: UserLanguage;
};

export const SurveyAnswerText: FC<Props> = ({ onUpdate, question, answer, onSubmit, selectedLanguage }) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState<string>('');
    const [isAnonymous, setIsAnonymous] = useState<boolean>(true);

    useEffect(() => {
        if (!comment) {
            setComment(answer?.textAnswer ?? '');
        }
    }, [answer, comment]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsAnonymous(event.target.checked);
        onUpdate(comment, event.target.checked);
    };

    const onUpdateComment = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(event.target.value);
        onUpdate(event.target.value, isAnonymous);
    };

    if (!answer) {
        return <></>;
    }

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Stack component={Paper} p={2} alignItems={'center'} justifyContent={'center'} gap={2}>
                <ClampedTypography
                    variant='h1'
                    ellipsis={2}
                    minWidth={{
                        xs: 250,
                        sm: 500,
                    }}
                >
                    {getLabelTranslation(question.question, selectedLanguage)}
                </ClampedTypography>
                <FormControlLabel
                    sx={{ width: '100%' }}
                    label={t('anonymous_surveys.comment')}
                    control={
                        <TextField
                            fullWidth
                            value={answer?.textAnswer}
                            onChange={onUpdateComment}
                            slotProps={{
                                input: {
                                    multiline: true,
                                    minRows: 4,
                                },
                            }}
                        />
                    }
                />
                <Stack direction='row' justifyContent={'space-between'} width={'100%'}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={isAnonymous} color='primary' onChange={handleChange} name='checkedA' />}
                            label={isAnonymous ? t('anonymous_surveys.anonymous') : t('anonymous_surveys.not_anonymous')}
                            labelPlacement='end'
                        />
                    </FormGroup>
                    {answer?.textAnswer && (
                        <Button color={'primary'} variant='contained' disabled={!answer?.textAnswer} onClick={() => onSubmit()}>
                            {t('anonymous_surveys.submit')}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Slide>
    );
};
