import { ArrowDown01Icon, ArrowUp01Icon } from 'hugeicons-react';
import { RichTreeViewProProps } from '@mui/x-tree-view-pro/RichTreeViewPro/RichTreeViewPro.types';
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { TreeViewItem } from '@/components/tree-view/TreeViewItem';
import { SyntheticEvent, useRef } from 'react';
import { getAllRecursiveItemChildren } from '@/utils/object.util';

export type TreeViewItem<TId extends string | number = string | number> = {
    id: TId;
    label: string;
    children: TreeViewItem<TId>[];
    disabled?: boolean;
};

export type TreeViewProps<R extends TreeViewItem, Multiple extends boolean | undefined = undefined> = RichTreeViewProProps<R, Multiple> & {
    selectionPropagation?: Multiple extends true ? boolean : false;
};

export const TreeView = <R extends TreeViewItem, Multiple extends boolean | undefined = undefined>(props: TreeViewProps<R, Multiple>): JSX.Element => {
    const { items, multiSelect, itemsReordering, onItemSelectionToggle, onSelectedItemsChange, selectionPropagation, ...restProps } = props;
    const toggledItemRef = useRef<Record<string, boolean>>({});

    if (items.length === 0) {
        return <></>;
    }

    const handleItemSelectionToggle = (event: SyntheticEvent, itemId: string, isSelected: boolean) => {
        toggledItemRef.current[itemId] = isSelected;
        onItemSelectionToggle?.(event, itemId, isSelected);
    };

    const handleSelectedItemsChange = (e: SyntheticEvent, newSelectedItems: string[]) => {
        // Only when selectionPropagation and multiple mode is enabled
        // feature does not exist yet in Mui https://mui.com/x/react-tree-view/rich-tree-view/selection/#parent-children-selection-relationship
        // Select / unselect the children of the toggled item
        const itemsToSelect: string[] = [];
        const itemsToUnSelect: Record<string, boolean> = {};
        Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
            const allChildrenIds = getAllRecursiveItemChildren(items as readonly TreeViewItem[], Number(itemId))
                .filter(item => !item.disabled)
                .map(item => item.id.toString());
            if (isSelected) {
                itemsToSelect.push(...allChildrenIds);
            } else {
                allChildrenIds.forEach(descendantId => {
                    itemsToUnSelect[descendantId] = true;
                });
            }
        });

        const newSelectedItemsWithChildren = [...newSelectedItems, ...itemsToSelect].filter(itemId => !itemsToUnSelect[itemId]);

        onSelectedItemsChange?.(e, Array.from(new Set(newSelectedItemsWithChildren)) as Multiple extends true ? string[] : string);

        toggledItemRef.current = {};
    };

    return (
        <RichTreeViewPro
            items={items}
            multiSelect={multiSelect}
            getItemId={item => item.id.toString()}
            {...restProps}
            onItemSelectionToggle={handleItemSelectionToggle}
            onSelectedItemsChange={(e, items) => {
                if (multiSelect && selectionPropagation && Array.isArray(items)) {
                    // handle selection propagation to select the descendents of the toggled item
                    handleSelectedItemsChange(e, items);
                    return;
                }
                onSelectedItemsChange?.(e, items);
            }}
            isItemDisabled={item => Boolean(item?.disabled)}
            slots={{
                expandIcon: props.slots?.expandIcon ?? ArrowDown01Icon,
                collapseIcon: props.slots?.collapseIcon ?? ArrowUp01Icon,
                item: TreeViewItem,
                ...props.slots,
            }}
            slotProps={{
                item: ownerState => {
                    return {
                        ...ownerState,
                        multiSelect, // inject multiselect prop to display a checkbox or a radio button
                    };
                },
                ...props.slotProps,
            }}
            itemsReordering={itemsReordering}
            {...(itemsReordering
                ? {
                      experimentalFeatures: {
                          ...restProps.experimentalFeatures,
                          indentationAtItemLevel: true,
                          itemsReordering: true,
                      },
                  }
                : {})}
        />
    );
};
