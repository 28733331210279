import { getEmployeeLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeLeaveTypeKeys = createQueryKeys('employeeLeaveTypes', {
    policies: (...params: Parameters<typeof getEmployeeLeaveTypePolicies>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeLeaveTypes = createRequiredParamsQueryHook(employeeLeaveTypeKeys.policies, getEmployeeLeaveTypePolicies);
