import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControlLabel, FormHelperText, Paper, Radio, Stack, Typography } from '@mui/material';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldRadioGroup } from '@/components/form/field-radio-group/FieldRadioGroup';
import { Add01Icon } from 'hugeicons-react';
import { ExpenseCategorySettingsPresetsGrid } from '@/page/setting/expense/expense-category/ExpenseCategorySettingsPresetsGrid';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { useTranslatableLabelInput } from '@/components/translatable-label-input/useTranslatableLabelInput';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { FORM_COMPANY_SETTINGS_CONTAINER_CLASS } from '@/page/setting/CompanySettings.constants';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignLocalId } from '@/utils/object.util';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { ExpenseCategorySettingsPresetDialog } from '@/page/setting/expense/expense-category/ExpenseCategorySettingsPresetDialog';
import { CreateExpenseCategoryMutation, ExpenseCategory, ExpenseCategoryType } from '@/domain/expense-category/ExpenseCategory.model';
import { ExpenseTax } from '@/domain/expense-tax/ExpenseTax.model';
import { createExpenseCategory, updateExpenseCategory } from '@/domain/expense-category/ExpenseCategory.service';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { useNavigate } from 'react-router';
import {
    ExpenseCategoryFormValues,
    ExpenseCategoryPresetFormValues,
    getExpenseCategoryFormSchema,
} from '@/page/setting/expense/expense-category/ExpenseCategorySettingsForm.schema';
import { useGetAllExpenseCategoryGroups } from '@/hooks/expense-category-group/ExpenseCategoryGroup.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { useGetExpenseSetting } from '@/hooks/expense-setting/ExpenseSetting.hook';

type ExpenseCategoryFormProps = {
    category: ExpenseCategory | undefined;
    taxes: ExpenseTax[];
};

export const ExpenseCategorySettingsForm: FC<ExpenseCategoryFormProps> = ({ category, taxes }) => {
    const { t } = useTranslation();
    const { translationLanguage, onLanguageChange } = useTranslatableLabelInput();
    const navigate = useNavigate();
    const categoryId = category?.id;
    const isCreateMode = !category;
    const [presetDialogData, setPresetDialogData] = useState<
        | {
              preset: ExpenseCategoryPresetFormValues | undefined;
              open: boolean;
          }
        | undefined
    >(undefined);

    const { data: categoryGroups = [] } = useGetAllExpenseCategoryGroups();
    const { data: expenseSetting } = useGetExpenseSetting();

    const { control, handleSubmit, watch, formState } = useForm<ExpenseCategoryFormValues>({
        resolver: yupResolver(getExpenseCategoryFormSchema(translationLanguage)),
        values: category
            ? {
                  name: category.name,
                  expenseTaxId: category.expenseTax?.id,
                  mandatoryDescription: category.mandatoryDescription,
                  type: category.type,
                  maxValue: category.maxValue,
                  presets: category.presets.map(preset => {
                      return assignLocalId({
                          id: preset.id,
                          name: preset.name,
                          amount: preset.amount,
                      });
                  }),
                  categoryGroupId: category.categoryGroup?.id,
              }
            : undefined,
        defaultValues: {
            name: createDefaultLabel(),
            type: 'AMOUNT',
            mandatoryDescription: false,
        },
    });

    const presets = watch('presets') ?? [];

    const { remove, append, update } = useFieldArray<ExpenseCategoryFormValues>({
        control,
        name: 'presets',
    });

    const type: ExpenseCategoryType | undefined = watch('type');

    const handleUpdatePreset = (preset: ExpenseCategoryPresetFormValues) => {
        const existingPresetIndex = presets.findIndex(p => p.localId === preset.localId);
        if (existingPresetIndex !== -1) {
            update(existingPresetIndex, preset);
        }
        setPresetDialogData(undefined);
    };

    const handleAddNewPreset = (preset: ExpenseCategoryPresetFormValues) => {
        append(preset);
        setPresetDialogData(undefined);
    };

    const handleSavePreset = (preset: ExpenseCategoryPresetFormValues) => {
        if (presetDialogData?.preset) {
            handleUpdatePreset(preset);
        } else {
            handleAddNewPreset(preset);
        }
    };

    const handleDeletePreset = (preset: ExpenseCategoryPresetFormValues) => {
        const existingPresetIndex = presets.findIndex(p => p.localId === preset.localId);
        if (existingPresetIndex !== -1) {
            remove(existingPresetIndex);
        }
    };

    const mapPresets = (presets: ExpenseCategoryPresetFormValues[]) => {
        return presets.map(preset => {
            return {
                id: preset.id,
                name: preset.name,
                amount: preset.amount,
            };
        });
    };

    const handleCreateExpenseCategory = async (data: ExpenseCategoryFormValues) => {
        const createMutation = {
            name: data.name,
            expenseTaxId: data.type === 'AMOUNT' ? data.expenseTaxId : undefined,
            mandatoryDescription: data.mandatoryDescription,
            type: data.type,
            maxValue: data.maxValue ?? undefined,
            presets: mapPresets(data.presets),
            categoryGroupId: data.categoryGroupId ?? undefined,
        } satisfies CreateExpenseCategoryMutation;

        try {
            await createExpenseCategory(createMutation);
            showSnackbar(t('expense_category_settings_page.success.category_saved'), 'success');
            navigate('/settings/expenses/categories');
        } catch (error) {
            handleError(error);
        }
    };

    const handleUpdateExpenseCategory = async (categoryId: number | undefined, data: ExpenseCategoryFormValues) => {
        if (!categoryId) return;
        const updateMutation = {
            name: data.name,
            expenseTaxId: data.type === 'AMOUNT' ? data.expenseTaxId : undefined,
            mandatoryDescription: data.mandatoryDescription,
            type: data.type,
            maxValue: data.maxValue ?? undefined,
            presets: mapPresets(data.presets),
            categoryGroupId: data.categoryGroupId ?? undefined,
        };
        try {
            await updateExpenseCategory(categoryId, updateMutation);
            showSnackbar(t('expense_category_settings_page.success.category_saved'), 'success');
            navigate('/settings/expenses/categories');
        } catch (error) {
            handleError(error);
        }
    };
    const onSave = async (data: ExpenseCategoryFormValues) => {
        if (isCreateMode) {
            await handleCreateExpenseCategory(data);
        } else {
            await handleUpdateExpenseCategory(categoryId, data);
        }
    };

    return (
        <Stack component={ContentContainer}>
            <Stack gap={2} id='expense-category-form' onSubmit={handleSubmit(onSave, console.error)} name={'expense-category-form'} component={'form'} flex={1}>
                <Stack p={2} gap={1} component={Paper}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='h2'>{t('expense_category_settings_page.page_title')}</Typography>
                        <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={onLanguageChange} />
                    </Stack>

                    <Stack gap={2} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS}>
                        <FieldLabel
                            control={control}
                            name='name'
                            language={translationLanguage}
                            label={t('expense_category_settings_page.name_field_title')}
                            fullWidth
                        />

                        <FormControlLabel
                            name='type'
                            label={t('expense_category_settings_page.type_field_title')}
                            control={
                                <FieldRadioGroup control={control} name='type'>
                                    <FormControlLabel
                                        labelPlacement='end'
                                        value='AMOUNT'
                                        control={<Radio disabled={!isCreateMode} />}
                                        label={t('expense_category_settings_page.amount_field_title')}
                                    />
                                    <FormControlLabel
                                        labelPlacement='end'
                                        value='DISTANCE'
                                        control={<Radio disabled={!isCreateMode} />}
                                        label={t('expense_category_settings_page.distance_field_title')}
                                    />
                                </FieldRadioGroup>
                            }
                        />

                        {expenseSetting?.expenseCategoryParentEnabled && (
                            <FormControlLabel
                                label={t('expense_category_settings_page.group_field_title')}
                                control={
                                    <FieldSelect
                                        control={control}
                                        name={'categoryGroupId'}
                                        options={categoryGroups.map(group => group.id)}
                                        getOptionLabel={optionId => getLabelTranslation(categoryGroups.find(group => group.id === optionId)?.name) ?? ''}
                                        fullWidth={true}
                                    />
                                }
                            />
                        )}

                        {type === 'AMOUNT' && !!taxes.length && (
                            <FormControlLabel
                                label={t('expense_category_settings_page.default_tax_field_title')}
                                control={
                                    <FieldSelect
                                        name='expenseTaxId'
                                        control={control}
                                        fullWidth
                                        options={taxes.map(tax => tax.id)}
                                        getOptionLabel={taxId => taxes.find(t => t.id === taxId)?.value.toString() ?? ''}
                                        isOptionEqualToValue={(option, value) => option === value}
                                    />
                                }
                            />
                        )}

                        <FormControlLabel
                            label={t('expense_category_settings_page.max_field_title')}
                            control={<FieldNumber control={control} name='maxValue' precision={2} fullWidth />}
                        />

                        <FormControlLabel
                            labelPlacement='end'
                            control={<FieldSwitch control={control} name='mandatoryDescription' />}
                            label={t('expense_category_settings_page.mandatory_description_field_title')}
                        />
                    </Stack>
                </Stack>

                <Stack p={2} component={Paper} gap={2} flex={presets.length ? 1 : 0}>
                    <Typography variant='h2'>{t('expense_category_settings_page.preset_values_title')}</Typography>
                    <Stack gap={2} flex={1}>
                        <Button
                            sx={{ alignSelf: 'flex-start' }}
                            variant='text'
                            size='small'
                            onClick={() => setPresetDialogData({ open: true, preset: undefined })}
                            startIcon={<Add01Icon width={20} height={20} />}
                        >
                            {t('expense_category_settings_page.add_preset')}
                        </Button>

                        <ExpenseCategorySettingsPresetsGrid
                            rowData={presets}
                            categoryType={type}
                            onEditPreset={preset =>
                                setPresetDialogData({
                                    open: true,
                                    preset: preset,
                                })
                            }
                            loading={false}
                            onDeletePreset={preset => handleDeletePreset(preset)}
                        />

                        {formState.errors.presets?.message && (
                            <FormHelperText sx={{ pl: 1 }} error>
                                {t(formState.errors.presets.message)}
                            </FormHelperText>
                        )}
                    </Stack>
                </Stack>
            </Stack>

            {presetDialogData?.open && (
                <ExpenseCategorySettingsPresetDialog
                    open={presetDialogData.open}
                    type={type}
                    preset={presetDialogData.preset}
                    onClose={() => setPresetDialogData(undefined)}
                    onSave={handleSavePreset}
                />
            )}
        </Stack>
    );
};
