import { Label, LabelRequest } from '@/domain/label/Label.model';
import { Locale, setDefaultOptions } from 'date-fns';
import { de, enGB, es, fr, it, pt } from 'date-fns/locale';
import { toLowerCase } from './strings.util';

import i18next from '@/i18n/i18n';
import { FieldError } from 'react-hook-form';

// application supported languages
export enum UserLanguage {
    FR = 'FR',
    EN = 'EN',
    DE = 'DE',
    IT = 'IT',
    ES = 'ES',
    PT = 'PT',
}

// default language
const DEFAULT_USER_LANGUAGE = UserLanguage.FR;

export const isUserLanguage = (language: string): language is UserLanguage => {
    return Object.values(UserLanguage).includes(language as UserLanguage);
};

export const setUserLanguage = async (language: UserLanguage): Promise<void> => {
    const userLanguage = language ?? getRealmLanguage();
    await i18next.changeLanguage(toLowerCase(userLanguage));
    const locale = getLocale(userLanguage);
    setDefaultOptions({ locale: locale });
    localStorage.setItem('userLanguage', userLanguage);
};

export const getUserLanguage = (): UserLanguage => {
    return (localStorage.getItem('userLanguage') as UserLanguage) || getRealmLanguage();
};

export const setRealmLanguage = (language: UserLanguage): void => {
    const realmLanguage = language ?? DEFAULT_USER_LANGUAGE;
    localStorage.setItem('realmLanguage', realmLanguage);
};

export const getRealmLanguage = (): UserLanguage => {
    return (localStorage.getItem('realmLanguage') as UserLanguage) || DEFAULT_USER_LANGUAGE;
};

export const isRealmLanguage = (language: UserLanguage): boolean => language === getRealmLanguage();

const LANGUAGE_LOCAL_MAP = new Map<UserLanguage, Locale>([
    [UserLanguage.EN, enGB],
    [UserLanguage.FR, fr],
    [UserLanguage.IT, it],
    [UserLanguage.DE, de],
    [UserLanguage.ES, es],
    [UserLanguage.PT, pt],
]);

export const getLocale = (language: UserLanguage): Locale => {
    return LANGUAGE_LOCAL_MAP.get(language ?? DEFAULT_USER_LANGUAGE) ?? fr;
};

export const getUserLocale = (): Locale => {
    return getLocale(getUserLanguage());
};

export function getLabelInUserLanguage(label: Label | undefined, userLanguage: UserLanguage): string {
    if (!label || !userLanguage) {
        return '';
    }

    switch (userLanguage) {
        case UserLanguage.EN:
            return label.translationEn ?? '';
        case UserLanguage.FR:
            return label.translationFr ?? '';
        case UserLanguage.DE:
            return label.translationDe ?? '';
        case UserLanguage.IT:
            return label.translationIt ?? '';
        case UserLanguage.ES:
            return label.translationEs ?? '';
        case UserLanguage.PT:
            return label.translationPt ?? '';
        default:
            return '';
    }
}

export const isLabelContainLanguageTranslation = (label: Label, language: UserLanguage): boolean => {
    const property = getLabelPropertyName(language);
    return label && !!label[property];
};

export const getLabelTranslation = (label: Nullable<Label>, userLanguage?: UserLanguage): string => {
    if (!label) {
        return '';
    }
    return (getLabelInUserLanguage(label, userLanguage ?? getUserLanguage()) || getLabelInUserLanguage(label, getRealmLanguage())) ?? '';
};

type LabelRequestPropertyName = keyof LabelRequest;

export function getLabelPropertyName(userLanguage: UserLanguage): LabelRequestPropertyName {
    switch (userLanguage) {
        case UserLanguage.EN:
            return 'translationEn';
        case UserLanguage.FR:
            return 'translationFr';
        case UserLanguage.DE:
            return 'translationDe';
        case UserLanguage.IT:
            return 'translationIt';
        case UserLanguage.ES:
            return 'translationEs';
        case UserLanguage.PT:
            return 'translationPt';
        default:
            throw new Error('Unknown language');
    }
}

export function getLanguageTranslationKey(userLanguage: UserLanguage): string {
    switch (userLanguage) {
        case UserLanguage.EN:
            return 'domain.user_language.english';
        case UserLanguage.FR:
            return 'domain.user_language.french';
        case UserLanguage.DE:
            return 'domain.user_language.german';
        case UserLanguage.IT:
            return 'domain.user_language.italian';
        case UserLanguage.ES:
            return 'domain.user_language.spanish';
        case UserLanguage.PT:
            return 'domain.user_language.portuguese';
        default:
            return '';
    }
}

export type TranslationErrors = {
    translationEn?: FieldError;
    translationFr?: FieldError;
    translationDe?: FieldError;
    translationIt?: FieldError;
    translationEs?: FieldError;
    translationPt?: FieldError;
};

type Error = FieldError | TranslationErrors | undefined;
const hasMessage = (error: Error): error is FieldError => {
    return typeof (error as FieldError)?.message === 'string';
};

const isTranslationErrors = (error: Error): error is TranslationErrors => {
    return error !== undefined && Object.keys(UserLanguage).some(key => key in UserLanguage);
};

/**
 * This function returns an error message based on the translation language.
 * The `error` object can have the following formats:
 *
 * Format 1 (Error on the label):
 * {
 *   message: "Error message",
 *   type: "required"
 *   // other properties...
 * }
 *
 * Format 2 (Error on nested translation properties):
 * {
 *   translationEn: {
 *     message: "Mandatory field",
 *     type: "required"
 *   },
 *   translationFr: {
 *     message: "Champ obligatoire",
 *     type: "required"
 *   },
 *   // other translation properties...
 * }
 *
 * @param {FieldError | undefined} error - The potential error containing the error messages.
 * @param {UserLanguage} translationLanguage - The translation language to use.
 * @returns {string} - The error message in the specified language, or a default error message.
 */
export const getLocalizedErrorMessage = (error: FieldError | TranslationErrors | undefined, translationLanguage: UserLanguage): string => {
    if (hasMessage(error) && error.message) {
        return error.message;
    }

    if (isTranslationErrors(error)) {
        const translationErrorMessage = getErrorMessage(translationLanguage, error);
        if (translationErrorMessage) {
            return translationErrorMessage;
        }

        const realmLanguage = getRealmLanguage();
        const realmErrorMessage = getErrorMessage(realmLanguage, error);
        if (realmErrorMessage) {
            return realmErrorMessage;
        }
    }
    return '';
};

const getErrorMessage = (language: UserLanguage, error: TranslationErrors | undefined): string => {
    const property = getLabelPropertyName(language) as keyof TranslationErrors;
    const languageError = error?.[property];
    if (languageError?.message) {
        return languageError.message;
    }
    return '';
};

export const getHelperText = (translationLanguage: UserLanguage, defaultValue: Label, defaultLanguage: UserLanguage): string | undefined => {
    return !isRealmLanguage(translationLanguage) ? getLabelTranslation(defaultValue, defaultLanguage) : undefined;
};
