import { getLegalUnit, getLegalUnitLogoUrl, getLegalUnits } from '@/domain/legal-unit/LegalUnit.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const legalUnitKeys = createQueryKeys('legalUnits', {
    getAll: {
        queryKey: getNull(),
    },
    getById: (...params: Parameters<typeof getLegalUnit>) => ({
        queryKey: params,
    }),
    logoUrl: (...params: Parameters<typeof getLegalUnitLogoUrl>) => ({
        queryKey: params,
    }),
});

export const useGetLegalUnits = createQueryHook(legalUnitKeys.getAll, getLegalUnits);

export const useGetLegalUnit = createRequiredParamsQueryHook(legalUnitKeys.getById, getLegalUnit);

export const useGetLegalUnitLogoUrl = createRequiredParamsQueryHook(legalUnitKeys.logoUrl, getLegalUnitLogoUrl);
