import { Announcement, AnnouncementSearch, CreateAnnouncementMutation, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { DepartmentDTO } from '@/api/department/Department.api';
import { LocationDTO } from '@/api/location/Location.api';

type AnnouncementDTO = Overwrite<
    Announcement,
    {
        createdBy: EmployeeAvatarDTO;
        departments: DepartmentDTO[];
        locations: LocationDTO[];
    }
>;

type AnnouncementCreateRequestDTO = DateToString<StrictOmit<CreateAnnouncementMutation, 'imageFile'>>;
type AnnouncementUpdateRequestDTO = DateToString<StrictOmit<EditAnnouncementMutation, 'id' | 'imageFile' | 'hasNoImage'>>;

type AnnouncementSearchRequestDTO = AnnouncementSearch;

const ANNOUNCEMENT_API_BASE_PATH = API_BASE_URL + `/announcements`;

const getAnnouncements = async (search: AnnouncementSearch): Promise<Announcement[]> => {
    return (
        await client.post<AnnouncementDTO[], AxiosResponse<AnnouncementDTO[]>, AnnouncementSearchRequestDTO>(ANNOUNCEMENT_API_BASE_PATH + '/search', search)
    ).data;
};

const getAnnouncementsAsEditor = async (search: AnnouncementSearch): Promise<Announcement[]> => {
    return (
        await client.post<AnnouncementDTO[], AxiosResponse<AnnouncementDTO[]>, AnnouncementSearchRequestDTO>(
            ANNOUNCEMENT_API_BASE_PATH + '/editor/search',
            search,
        )
    ).data;
};

const createAnnouncement = async (mutation: Omit<CreateAnnouncementMutation, 'imageFile'>): Promise<Announcement> => {
    return (await client.post<AnnouncementDTO, AxiosResponse<AnnouncementDTO>, AnnouncementCreateRequestDTO>(ANNOUNCEMENT_API_BASE_PATH, mutation)).data;
};

const updateAnnouncement = async (mutation: Omit<EditAnnouncementMutation, 'hasNoImage' | 'imageFile'>): Promise<Announcement> => {
    const { id, ...restMutation } = mutation;
    return (
        await client.put<AnnouncementDTO, AxiosResponse<AnnouncementDTO>, AnnouncementUpdateRequestDTO>(`${ANNOUNCEMENT_API_BASE_PATH}/${id}`, restMutation)
    ).data;
};

const deleteAnnouncement = async (announcementId: number): Promise<void> => {
    await client.delete(`${ANNOUNCEMENT_API_BASE_PATH}/${announcementId}`);
};

const editAnnouncementImage = async (announcementId: number, imageKey: File): Promise<Announcement> => {
    const form = new FormData();

    form.append('image', imageKey);
    return (await client.putForm<Announcement, AxiosResponse<Announcement>, FormData>(`${ANNOUNCEMENT_API_BASE_PATH}/${announcementId}/image`, form)).data;
};

const deleteAnnouncementImage = async (announcementId: number): Promise<void> => {
    await client.delete(`${ANNOUNCEMENT_API_BASE_PATH}/${announcementId}/image`);
};

const getAnnouncementImageUrl = async (announcementId: number): Promise<string> => {
    return (await client.get<string, AxiosResponse<string>>(`${ANNOUNCEMENT_API_BASE_PATH}/${announcementId}/image`)).data;
};

export const announcementAPI = {
    getAnnouncements,
    getAnnouncementsAsEditor,
    createAnnouncement,
    updateAnnouncement,
    editAnnouncementImage,
    deleteAnnouncement,
    deleteAnnouncementImage,
    getAnnouncementImageUrl,
};
