import { Select } from '@/components/form/field-select/Select';
import { useBreakPoints } from '@/components/use-break-points/useBreakPoints';
import { Area } from '@/domain/area/Area.model';
import {
    ClockInOutCreationMutation,
    EmployeeTimesheetMutation,
    Timesheet,
    TimesheetClockInAreaUpdateMutation,
    TimesheetCreationMethod,
} from '@/domain/timesheet/Timesheet.model';
import { updateTimesheet, updateTimesheetArea } from '@/domain/timesheet/Timesheet.service';
import { useGetAreas } from '@/hooks/area/Area.hook';
import {
    clockInOutMutation,
    deleteLastTimesheetClockInOutMutation,
    timesheetKeys,
    useGetAllowClockInOutsideWorkingHours,
    useGetLastTimesheetClockInOut,
} from '@/hooks/timesheet/Timesheet.hook';
import { TimesheetClockOutDialog } from '@/page/timesheet/timesheet-clock-out-dialog/TimesheetClockOutDialog';
import { useCurrentEmployee } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { differenceInHours, differenceInMinutes, differenceInSeconds, getTodayDate, isSameDate } from '@/utils/datetime.util';
import { defaultToNull } from '@/utils/object.util';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Cancel01Icon, PauseIcon, PlayIcon } from 'hugeicons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ClockInMode = 'CLOCKIN' | 'CLOCKOUT' | 'CANCEL';

const HOURS_TO_CLOCK_IN_OVERNIGHT = 11;
const HOURS_TO_CLOCK_IN_SAME_DAY = 14;
const MINIMUM_SECONDS_BEFORE_ENABLING_CLOCKOUT = 60;
const UPDATE_TIME_ELAPSED_EVERY_MILLISECONDS = 5000;

export const ClockInOut: FC = () => {
    const [timesheetToClockOut, setTimesheetToClockOut] = useState<Timesheet>();

    const { t } = useTranslation();
    const { isMobile } = useBreakPoints();

    const currentEmployee = useCurrentEmployee();
    const currentEmployeeId = currentEmployee.id;

    // fetching timesheet and restrictions
    const { data: timesheet, isFetching: isLastTimesheetClockInOutFetching } = useGetLastTimesheetClockInOut(currentEmployeeId);
    const { data: clockInRestrictionReason, isFetching: isAllowClockInOutsideWorkingHoursFetching } = useGetAllowClockInOutsideWorkingHours(currentEmployeeId);

    const isTimesheetFetching = isAllowClockInOutsideWorkingHoursFetching || isLastTimesheetClockInOutFetching;

    // fetching areas
    const locationIds = currentEmployee?.currentEmployments.map(employment => employment.location.id) ?? [];
    const { data: areas = [], isLoading: isLoadingAreas } = useGetAreas({
        // Search all areas for the current employee based on current employments
        locationIds,
    });

    const isManualClockIn = timesheet?.clockInCreationMethod === TimesheetCreationMethod.MANUAL_CLOCK_IN;
    const startAt = isManualClockIn ? timesheet.startAt : timesheet?.originalStartAt;

    const { clockInMode } = useGetClockInMode({ startAt, endAt: timesheet?.endAt });

    const currentHour = useGetTimeElapsedSinceClockIn(startAt, UPDATE_TIME_ELAPSED_EVERY_MILLISECONDS);

    const buttonProps = useGetClockInOutDisplayProps(clockInMode, currentHour);

    const { mutateAsync: clockInOutMutate, isPending: isClockInOutPending } = clockInOutMutation();
    const { mutateAsync: deleteLastTimesheetClockInOutMutate, isPending: isDeleteLastTimesheetClockInOutPending } = deleteLastTimesheetClockInOutMutation();

    const isClockInMutationPending = isClockInOutPending || isDeleteLastTimesheetClockInOutPending;
    const isClockinDisallowed = clockInRestrictionReason != 'CLOCK_IN_ALLOWED';
    const displayArea = !isLoadingAreas && areas && areas.length >= 1 && clockInMode !== 'CLOCKIN' && clockInRestrictionReason == 'CLOCK_IN_ALLOWED';

    const handleClick = async () => {
        switch (clockInMode) {
            case 'CANCEL':
                await handleCancelClockInOut();
                break;

            case 'CLOCKOUT':
                setTimesheetToClockOut(timesheet);
                break;

            case 'CLOCKIN': {
                const mutation: ClockInOutCreationMutation = {
                    employeeId: currentEmployeeId,
                    dateTime: getTodayDate(),
                    areaId: undefined,
                    comment: undefined,
                };
                await handleClockInOutRequest(mutation);
                break;
            }
            default:
                break;
        }
    };

    const handleClockInOutRequest = async (mutation: ClockInOutCreationMutation, employeeTimesheetMutation?: EmployeeTimesheetMutation) => {
        try {
            await clockInOutMutate(mutation);
            //on success we close the dialog (in case it was open)
            setTimesheetToClockOut(undefined);
            if (employeeTimesheetMutation) {
                await updateTimesheet(employeeTimesheetMutation);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleCancelClockInOut = async () => {
        try {
            await deleteLastTimesheetClockInOutMutate(currentEmployeeId);
        } catch (error) {
            handleError(error);
        }
    };

    const onSaveFromClockOutDialog = async (mutation: ClockInOutCreationMutation, employeeTimesheetMutation: EmployeeTimesheetMutation | undefined) => {
        await handleClockInOutRequest(mutation, employeeTimesheetMutation);
    };

    const onCloseFromClockOutDialog = () => {
        setTimesheetToClockOut(undefined);
    };

    const tooltipTitle = () => {
        switch (clockInRestrictionReason) {
            case 'OUTSIDE_WORKING_HOURS':
                return t('header.clock_in_outside_working_hours');
            case 'SUNDAY_AND_PUBLIC_HOLIDAYS':
                return t('header.clock_in_sunday_and_public_holidays');
            default:
                return '';
        }
    };

    if (isTimesheetFetching) {
        return;
    }

    return (
        <>
            {displayArea && !isMobile && !!timesheet?.id && <ClockInAreaAutocomplete areas={areas} timesheet={timesheet} employeeId={currentEmployeeId} />}
            <Tooltip placement='bottom' title={tooltipTitle()}>
                <span>
                    {/*Hack to display the tooltip when the button is disabled*/}
                    <Button
                        onClick={handleClick}
                        disabled={isClockInMutationPending || isClockinDisallowed}
                        variant='text'
                        sx={{ textWrap: 'nowrap' }}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
            {timesheetToClockOut && (
                <TimesheetClockOutDialog
                    timesheetSetting={currentEmployee?.currentWorkingPattern?.timesheetSetting}
                    timesheet={timesheetToClockOut}
                    onSave={onSaveFromClockOutDialog}
                    onClose={onCloseFromClockOutDialog}
                    currentEmployee={currentEmployee}
                />
            )}
        </>
    );
};

const hasPassedHoursForClockIn = (startAt: Date) => {
    if (isSameDate(startAt, getTodayDate())) {
        return differenceInHours(getTodayDate(), startAt) >= HOURS_TO_CLOCK_IN_SAME_DAY;
    }
    return differenceInHours(getTodayDate(), startAt) >= HOURS_TO_CLOCK_IN_OVERNIGHT;
};

type ClockInAreaAutocompleteProps = {
    areas: Area[];
    timesheet: Timesheet;
    employeeId: number;
} & ButtonProps;

const ClockInAreaAutocomplete: FC<ClockInAreaAutocompleteProps> = ({ areas, timesheet, employeeId }) => {
    const { t } = useTranslation();
    const [areaId, setAreaId] = useState<number | undefined>(timesheet?.area?.id);
    const getAreaResourceOptions = () => {
        const allAreas = areas?.map(area => {
            return {
                id: area.id,
                name: area.name,
            };
        });
        return allAreas ?? [];
    };

    const queryClient = useQueryClient();
    const handleOnAreaChange = async (areaId: number) => {
        const mutation: TimesheetClockInAreaUpdateMutation = {
            areaId: areaId,
            employeeId: employeeId,
        };
        try {
            await updateTimesheetArea(mutation, timesheet.id);

            await queryClient.invalidateQueries({
                queryKey: timesheetKeys.searchTimesheets._def,
            });
            await queryClient.invalidateQueries(timesheetKeys.getLastTimesheetClockInOut(employeeId));
        } catch (error) {
            handleError(error);
        }
    };

    const allAreas = getAreaResourceOptions() ?? [];

    return (
        <Select
            placeholder={t('header.area_placeholder')}
            autocompleteProps={{
                id: 'area-select',
                sx: { width: '200px' },
                disableListWrap: true,
            }}
            value={defaultToNull(allAreas.find(option => option.id === areaId)) as Area}
            options={allAreas}
            disableClearable
            getOptionLabel={option => option.name}
            onChange={newValue => {
                setAreaId(newValue?.id);
                handleOnAreaChange(newValue?.id);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};

const useGetClockInMode = ({ startAt, endAt }: { startAt: Date | undefined; endAt: Date | undefined }): { clockInMode: ClockInMode } => {
    const [clockInMode, setClockInMode] = useState<ClockInMode>('CLOCKIN');

    useEffect(() => {
        // MODE CLOCKIN: we can clock in if :
        // the employee has not clocked in yet
        // or if too late to clockin
        // or if the timesheet has an end date
        const canClockIn = !startAt || hasPassedHoursForClockIn(startAt) || !!endAt;
        if (canClockIn) {
            setClockInMode('CLOCKIN');
            return;
        }

        // MODE CLOCKOUT
        const remainingSecondsBeforeEnablingClockout = MINIMUM_SECONDS_BEFORE_ENABLING_CLOCKOUT - differenceInSeconds(getTodayDate(), startAt);
        const canClockOut = remainingSecondsBeforeEnablingClockout < 0;
        if (canClockOut) {
            setClockInMode('CLOCKOUT');
            return;
        }

        // MODE CANCEL : Between Clock-in and Clock-out
        setClockInMode('CANCEL');
        // Switch to clock-out mode after a certain time
        const switchToClockoutTimeoutId = setTimeout(() => {
            setClockInMode('CLOCKOUT');
        }, remainingSecondsBeforeEnablingClockout * 1000);

        return () => clearTimeout(switchToClockoutTimeoutId);
    }, [startAt, endAt]);

    return { clockInMode };
};

// return a string formatted that represents time elapsed since the clock in string
const useGetTimeElapsedSinceClockIn = (startAt: Date | undefined, intervalMs?: number) => {
    const [currentHour, setCurrentHour] = useState<string>('');

    useEffect(() => {
        if (!startAt) {
            return;
        }

        const calculateTimeElapsedSinceClockIn = (startDate: Date) => {
            const currentDateTime = getTodayDate();
            const hoursElapsed = differenceInHours(currentDateTime, startDate);
            const minutesElapsed = differenceInMinutes(currentDateTime, startDate) % 60;
            setCurrentHour(hoursElapsed.toString().padStart(2, '0') + ':' + minutesElapsed.toString().padStart(2, '0'));
        };

        calculateTimeElapsedSinceClockIn(startAt);
        const timer = setInterval(() => {
            calculateTimeElapsedSinceClockIn(startAt);
        }, intervalMs ?? 1000);

        return () => clearInterval(timer);
    }, [intervalMs, startAt]);

    return currentHour;
};

const useGetClockInOutDisplayProps = (clockInMode: ClockInMode, currentHour: string): ButtonProps => {
    const { t } = useTranslation();
    switch (clockInMode) {
        case 'CLOCKIN':
            return {
                endIcon: <PlayIcon />,
                children: t('header.clock_in'),
                color: 'warning',
            };

        case 'CANCEL':
            return {
                endIcon: <Cancel01Icon />,
                children: `${currentHour} ${t('header.cancel_clock_in')}`,
                color: 'error',
            };
        case 'CLOCKOUT':
        default:
            return {
                endIcon: <PauseIcon />,
                children: `${currentHour} ${t('header.clock_out')}`,
                color: 'primary',
            };
    }
};
