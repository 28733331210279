import { getPermissionGroupById, getPermissionGroupsByEmployeeId } from '@/domain/permission-group/PermissionGroup.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const permissionGroupKeys = createQueryKeys('permissionGroup', {
    getById: (...params: Parameters<typeof getPermissionGroupById>) => ({
        queryKey: params,
    }),
    getByEmployeeId: (...params: Parameters<typeof getPermissionGroupsByEmployeeId>) => ({
        queryKey: params,
    }),
});

export const useGetPermissionGroup = createRequiredParamsQueryHook(permissionGroupKeys.getById, getPermissionGroupById);

export const useGetPermissionGroupsByEmployeeId = createRequiredParamsQueryHook(permissionGroupKeys.getByEmployeeId, getPermissionGroupsByEmployeeId);
