import { getCalendarById, getCalendars } from '@/domain/calendar/Calendar.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const calendarKeys = createQueryKeys('calendars', {
    getAll: {
        queryKey: getNull(),
    },
    getById: (calendarId: number) => ({
        queryKey: [calendarId],
    }),
});

export const useGetCalendars = createQueryHook(calendarKeys.getAll, getCalendars);

export const useGetCalendar = createRequiredParamsQueryHook(calendarKeys.getById, getCalendarById);
