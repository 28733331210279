import { getOwnEmployeeNotifications, searchEmployeeNotifications } from '@/domain/employee-notification/EmployeeNotification.service';
import { createQueryHook } from '@/page/Query.type';
import { getNull } from '@/utils/object.util';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeNotificationKeys = createQueryKeys('employeeNotifications', {
    ownEmployeeNotification: {
        queryKey: getNull(),
    },
    search: (...params: Parameters<typeof searchEmployeeNotifications>) => ({
        queryKey: params,
    }),
});

export const useGetOwnEmployeeNotification = createQueryHook(employeeNotificationKeys.ownEmployeeNotification, getOwnEmployeeNotifications);

export const useSearchEmployeeNotification = createQueryHook(employeeNotificationKeys.search, searchEmployeeNotifications);
