import { Timesheet, TimesheetAutomaticCorrectionType, TimesheetCreationMethod } from '@/domain/timesheet/Timesheet.model';
import i18next from '@/i18n/i18n';
import { formatInDefaultDate, formatInDefaultHours } from '@/utils/datetime.util';
import { SvgIconOwnProps } from '@mui/material/SvgIcon/SvgIcon';

export const getColor = (timesheet: Timesheet): SvgIconOwnProps['color'] => {
    const timecardStatus = timesheet.timecardStatus;
    const clockInCreationMethod = timesheet.clockInCreationMethod;
    switch (timecardStatus) {
        case 'MISSING_CLOCK_OUT':
            return 'error';
        case 'CLOCK_IN_OUT':
            if (clockInCreationMethod === TimesheetCreationMethod.MANUAL_CLOCK_IN) {
                return 'warning';
            }
            return 'success';
        case 'AUTOMATIC_CORRECTION':
            return 'secondary';
        case 'MANUAL_UPDATE':
            return 'warning';
        default:
            return undefined;
    }
};
export const getText = ({
    timecardStatus,
    updatedBy,
    updatedAt,
    createdBy,
}: Pick<Timesheet, 'timecardStatus' | 'updatedBy' | 'updatedAt' | 'createdBy'>): string => {
    switch (timecardStatus) {
        case 'MISSING_CLOCK_OUT':
            return i18next.t('timesheets.timecards.missing_clock_out');
        case 'CLOCK_IN_OUT':
            return i18next.t('timesheets.timecards.clock_in_out');
        case 'AUTOMATIC_CORRECTION':
            return '';
        default:
            if (updatedBy || createdBy) {
                const lastUpdatedBy = updatedBy ?? createdBy;
                if (lastUpdatedBy && updatedAt) {
                    return i18next.t('timesheets.timecards.manual_input_updated_by', {
                        updatedByName: lastUpdatedBy.displayName,
                        updatedAt: formatInDefaultDate(updatedAt),
                    });
                }
            }
            return i18next.t('timesheets.timecards.manual_input');
    }
};
export const getTextMissingClockinout = (timesheet: Timesheet): string => {
    const { timecardStatus, originalStartAt, originalEndAt, clockInCreationMethod, startAt, endAt } = timesheet;

    const previouslyText = i18next.t('timesheets.timecards.previously');
    const missingText = i18next.t('timesheets.timecards.missing');

    if (timecardStatus !== 'MANUAL_UPDATE') {
        return '';
    }

    if (!originalStartAt && !originalEndAt) {
        return '';
    }

    if (clockInCreationMethod !== TimesheetCreationMethod.TIMEMOTO_INTEGRATION && clockInCreationMethod !== TimesheetCreationMethod.FRONTEND_CLOCK_IN) {
        return '';
    }

    if (!originalEndAt && originalStartAt) {
        return `${previouslyText} ${formatInDefaultHours(originalStartAt)} -> ${missingText}`;
    }

    if ((startAt !== originalStartAt || endAt !== originalEndAt) && originalStartAt && originalEndAt) {
        return `${previouslyText} ${formatInDefaultHours(originalStartAt)} -> ${formatInDefaultHours(originalEndAt)}`;
    }

    return '';
};
export const getAutomaticCorrectionText = (automaticCorrection: TimesheetAutomaticCorrectionType, timesheet: Timesheet): string => {
    switch (automaticCorrection) {
        case 'BREAK':
            return i18next.t('timesheets.timecards.automatic_correction_break', { break: timesheet.systemCorrection });
        case 'START_DATE': {
            const date = timesheet.originalStartAt ?? '';
            if (timesheet.clockInCreationMethod === TimesheetCreationMethod.MANUAL_CLOCK_IN) {
                return i18next.t('timesheets.timecards.manual_clock_in_start_date', {
                    createdByName: timesheet.createdBy?.displayName,
                    date: date,
                });
            }
            return i18next.t('timesheets.timecards.automatic_correction_start_date', { date: date });
        }
        case 'END_DATE': {
            const date = timesheet.originalEndAt ?? '';
            return i18next.t('timesheets.timecards.automatic_correction_end_date', { date: date });
        }
        default:
            return '';
    }
};
