import { API_BASE_URL, buildImportFormData, client } from '@/api/common';
import { EmploymentDTO, mapEmploymentDTO } from '@/api/employment/Employment.api';
import {
    Employee,
    EmployeeAnniversary,
    EmployeeAnniversaryRequest,
    EmployeeAuthentication,
    EmployeeAvatar,
    EmployeeBasicInfo,
    EmployeeBasicInfoUpdateMutation,
    EmployeeEmailUpdateMutation,
    EmployeeLanguageUpdateRequest,
    EmployeeLoginMethod,
    EmployeeLoginMethodUpdateMutation,
    EmployeePlanningSettings,
    EmployeePlanningSettingsUpdateMutation,
    EmployeeSearch,
    EmployeeSearchLoginMethodRequest,
    EmployeeSyncRequest,
} from '@/domain/employee/Employee.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { LoginMethod } from '@/domain/realm/Realm.model';
import { AxiosResponse } from 'axios';

type EmployeeAuthenticationDTO = Overwrite<
    EmployeeAuthentication,
    {
        employee: EmployeeDTO;
    }
>;
type EmployeeLoginMethodDTO = EmployeeLoginMethod;
type EmployeeAnniversaryRequestDTO = DateToString<EmployeeAnniversaryRequest>;

export type EmployeeDTO = Overwrite<Employee, { currentEmployments: EmploymentDTO[] }>;
export type EmployeeAvatarDTO = EmployeeAvatar;
export type EmployeeSearchRequestDTO = EmployeeSearch;
export type EmployeeSyncRequestDTO = EmployeeSyncRequest;
export type EmployeeAnniversaryDTO = EmployeeAnniversary;

const EMPLOYEE_API_BASE_URL = API_BASE_URL + '/employees';

const getCurrentEmployee = async (): Promise<EmployeeAuthentication> => {
    const { data } = await client.get<EmployeeAuthenticationDTO>(EMPLOYEE_API_BASE_URL + '/me');
    return {
        ...data,
        employee: mapEmployeeDTO(data.employee),
    };
};

const getEmployeeById = async (employeeId: number): Promise<Employee> => {
    const { data } = await client.get<EmployeeDTO>(EMPLOYEE_API_BASE_URL + `/${employeeId}`);
    return mapEmployeeDTO(data);
};

const searchEmployees = async (request: EmployeeSearch = {}): Promise<Employee[]> => {
    const { data } = await client.post<EmployeeDTO[], AxiosResponse<EmployeeDTO[]>, EmployeeSearchRequestDTO>(EMPLOYEE_API_BASE_URL + '/search', request);

    return data.map(mapEmployeeDTO);
};

const searchEmployeeBirthdays = async (request: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    const url = EMPLOYEE_API_BASE_URL + '/birthday';

    return (await client.post<EmployeeAnniversaryDTO[], AxiosResponse<EmployeeAnniversaryDTO[]>, EmployeeAnniversaryRequestDTO>(url, request)).data;
};

const searchEmployeeWorkAnniversaries = async (request: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    const url = EMPLOYEE_API_BASE_URL + '/work-anniversary';
    return (await client.post<EmployeeAnniversaryDTO[], AxiosResponse<EmployeeAnniversaryDTO[]>, EmployeeAnniversaryRequestDTO>(url, request)).data;
};

const deleteEmployee = async (employeeId: number, email: string): Promise<void> => {
    await client.delete(EMPLOYEE_API_BASE_URL + `/${employeeId}/${email}`);
};

const searchCurrentEmployeeLoginMethod = async (request: EmployeeSearchLoginMethodRequest): Promise<EmployeeLoginMethodDTO> => {
    return (
        await client.post<EmployeeLoginMethodDTO, AxiosResponse<EmployeeLoginMethodDTO>, EmployeeSearchLoginMethodRequest>(
            EMPLOYEE_API_BASE_URL + '/login-method/search',
            request,
        )
    ).data;
};

const getEmployeeLoginMethod = async (employeeId: number): Promise<LoginMethod> => {
    return (await client.get<LoginMethod>(EMPLOYEE_API_BASE_URL + `/${employeeId}/login-method`)).data;
};

const updateLoginMethod = async (employeeId: number, mutation: EmployeeLoginMethodUpdateMutation): Promise<Employee> => {
    return (
        await client.put<Employee, AxiosResponse<Employee>, EmployeeLoginMethodUpdateMutation>(EMPLOYEE_API_BASE_URL + `/${employeeId}/login-method`, mutation)
    ).data;
};

const getEmployeeBasicInfo = async (employeeId: number): Promise<EmployeeBasicInfo> => {
    return (await client.get<EmployeeBasicInfo>(EMPLOYEE_API_BASE_URL + `/${employeeId}/basic-info`)).data;
};

const updateEmployeeBasicInfo = async (employeeId: number, mutation: EmployeeBasicInfoUpdateMutation): Promise<EmployeeBasicInfo> => {
    return (
        await client.put<EmployeeBasicInfo, AxiosResponse<EmployeeBasicInfo>, EmployeeBasicInfoUpdateMutation>(
            EMPLOYEE_API_BASE_URL + `/${employeeId}/basic-info`,
            mutation,
        )
    ).data;
};

const updateEmail = async (employeeId: number, mutation: EmployeeEmailUpdateMutation): Promise<void> => {
    return (await client.put(EMPLOYEE_API_BASE_URL + `/${employeeId}/email`, mutation)).data;
};

const deactivateEmployee = async (employeeId: number): Promise<Employee> => {
    const data = (await client.put<EmployeeDTO>(EMPLOYEE_API_BASE_URL + `/${employeeId}/deactivate`, {})).data;
    return mapEmployeeDTO(data);
};

const syncEmployees = async (employeeSyncRequest: EmployeeSyncRequest): Promise<void> => {
    await client.post<EmployeeDTO, AxiosResponse<EmployeeDTO>, EmployeeSyncRequestDTO>(EMPLOYEE_API_BASE_URL + `/sync`, employeeSyncRequest);
};

const activateEmployee = async (employeeId: number): Promise<Employee> => {
    const data = (await client.put<EmployeeDTO>(EMPLOYEE_API_BASE_URL + `/${employeeId}/activate`, {})).data;
    return mapEmployeeDTO(data);
};

const getEmployeeEmail = async (employeeId: number): Promise<string> => {
    return (await client.get<string>(EMPLOYEE_API_BASE_URL + `/${employeeId}/email`)).data;
};

const getEmployeePhoneNumber = async (employeeId: number): Promise<string> => {
    return (await client.get<string>(EMPLOYEE_API_BASE_URL + `/${employeeId}/phone-number`)).data;
};

const getEmployeeAvatar = async (employeeId: number): Promise<string> => {
    return (await client.get<string>(EMPLOYEE_API_BASE_URL + `/${employeeId}/avatar`)).data;
};

const uploadAvatar = async (employeeId: number, file: File): Promise<Employee> => {
    // Build form data with file
    const form = new FormData();
    form.append('avatar', file);
    const { data } = await client.putForm<EmployeeDTO, AxiosResponse<EmployeeDTO>, FormData>(EMPLOYEE_API_BASE_URL + `/${employeeId}/avatar`, form);
    return mapEmployeeDTO(data);
};

const updateEmployeePlanningSettings = async (employeeId: number, mutation: EmployeePlanningSettingsUpdateMutation): Promise<EmployeePlanningSettings> => {
    return (
        await client.put<EmployeePlanningSettings, AxiosResponse<EmployeePlanningSettings>, EmployeePlanningSettingsUpdateMutation>(
            EMPLOYEE_API_BASE_URL + `/${employeeId}/planning-settings`,
            mutation,
        )
    ).data;
};

const updateEmployeeLanguage = async (mutation: EmployeeLanguageUpdateRequest): Promise<void> => {
    await client.put(EMPLOYEE_API_BASE_URL + `/me/language`, mutation);
};

const importEmployees = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(EMPLOYEE_API_BASE_URL + `/import`, formData)).data;
};

export const mapEmployeeDTO = (employee: EmployeeDTO): Employee => {
    const { currentEmployments, ...restEmployee } = employee ?? {};
    return {
        ...restEmployee,
        currentEmployments:
            currentEmployments?.map(mapEmploymentDTO).sort((e1, e2) => {
                // principal employment should be first
                if (e1.principal) {
                    return -1;
                }
                if (e2.principal) {
                    return 1;
                }
                // sort by id
                return e1.id < e2.id ? -1 : 1;
            }) ?? [],
    };
};

export const employeeAPI = {
    getCurrentEmployee,
    getEmployeeById,
    searchEmployees,
    searchEmployeeBirthdays,
    searchEmployeeWorkAnniversaries,
    deleteEmployee,
    searchCurrentEmployeeLoginMethod,
    getEmployeeLoginMethod,
    updateLoginMethod,
    getEmployeeBasicInfo,
    updateEmployeeBasicInfo,
    updateEmail,
    deactivateEmployee,
    activateEmployee,
    uploadAvatar,
    getEmployeeAvatar,
    getEmployeeEmail,
    getEmployeePhoneNumber,
    updateEmployeePlanningSettings,
    importEmployees,
    updateEmployeeLanguage,
    syncEmployees,
};
