import { createDefaultLeaveTypePolicy } from '@/domain/leave-request/LeaveRequest.service';
import { AllowanceType, EmployeeLeaveTypePolicy, LeaveType, LeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { Button, DialogActions, DialogContent, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { assignLeaveTypePolicy, batchAssignLeaveTypePolicy } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { handleError } from '@/utils/api.util';
import { getCurrentLocalDate, getStartOfYear, isValidDate } from '@/utils/datetime.util';

import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';

type Props = {
    onPolicyAssigned: (newPolicy: EmployeeLeaveTypePolicy[]) => void;
    employeeIds: number[];
    employeeId?: number;
    onClose: () => void;
};

// By default, the apply from date is the first day of the current year
const defaultApplyFromDate = getStartOfYear();

export const AssignPolicyDialog: FC<Props> = ({ onPolicyAssigned, employeeIds, employeeId, onClose }) => {
    const { t } = useTranslation();

    const { data: leaveTypes = [], error } = useGetLeaveTypes();

    const [selectedLeaveType, setSelectedLeaveType] = useState<LeaveType>();
    const [selectedLeaveTypePolicy, setSelectedLeaveTypePolicy] = useState<LeaveTypePolicy>();
    const [withCustomApplyFromDate, setWithCustomApplyFromDate] = useState<boolean>(false);
    const [applyFromDate, setApplyFromDate] = useState<LocalDate>(getCurrentLocalDate());

    const isFormValid = () => {
        if (!selectedLeaveType) {
            return false;
        }
        if (!applyFromDate || !isValidDate(applyFromDate)) {
            return false;
        }
        if (selectedLeaveType.allowanceType !== AllowanceType.UNLIMITED && !selectedLeaveTypePolicy) {
            return false;
        }
        return !error;
    };

    const onSave = () => {
        if (!applyFromDate || error || !selectedLeaveType || (selectedLeaveType.allowanceType !== AllowanceType.UNLIMITED && !selectedLeaveTypePolicy)) {
            return;
        }

        let leaveTypePolicy = selectedLeaveTypePolicy ?? createDefaultLeaveTypePolicy();
        const applyFromDateToUse = withCustomApplyFromDate ? applyFromDate : defaultApplyFromDate;

        // if the selected leave type has only one policy, we assign it by default
        if (selectedLeaveType.policies.length === 1) {
            leaveTypePolicy = selectedLeaveType.policies[0];
        }

        if (employeeId) {
            const userLeaveType: EmployeeLeaveTypePolicy = {
                id: 0,
                leaveType: selectedLeaveType,
                leaveTypePolicy: leaveTypePolicy,
                applyFrom: applyFromDateToUse,
                endDate: undefined,
            };
            assignLeaveTypePolicy(employeeId, userLeaveType)
                .then(userLeaveTypePolicies => {
                    showSnackbar(t('my_profile.messages.leave_type_added'), 'success');
                    onPolicyAssigned(userLeaveTypePolicies);
                    onClose();
                })
                .catch(handleError);
        }

        if (employeeIds.length) {
            if (!leaveTypePolicy?.id) {
                return;
            }
            batchAssignLeaveTypePolicy(employeeIds, selectedLeaveType.id, leaveTypePolicy.id, applyFromDateToUse)
                .then(() => {
                    showSnackbar(t('manage_people_page.messages.users_assigned'), 'success');
                    onClose();
                })
                .catch(handleError);
        }
    };

    return (
        <DialogWrapper open={true} onClose={() => onClose()} header={t('user_leave_type_dialog.add_leave_type')}>
            <Stack gap={1} component={DialogContent}>
                <InputLabel id='leaveType'>{t('user_leave_type_dialog.leave_type')}</InputLabel>
                <Select
                    labelId='leaveType'
                    id='leave_type_select'
                    value={selectedLeaveType?.id}
                    fullWidth
                    onChange={event => {
                        const value = event.target.value;
                        if (value) {
                            const newValue = leaveTypes.find(lt => lt.id === value);
                            if (newValue) {
                                setSelectedLeaveType(newValue);
                                if (newValue.policies.length === 1) {
                                    setSelectedLeaveTypePolicy(newValue.policies[0]);
                                } else {
                                    setSelectedLeaveTypePolicy(undefined);
                                }
                            }
                        }
                    }}
                >
                    {leaveTypes.map(lt => {
                        return (
                            <MenuItem key={lt.id} value={lt.id}>
                                {getLabelTranslation(lt.name)}
                            </MenuItem>
                        );
                    })}
                </Select>
                {/* we only display the policy select if there are more than one policy otherwise the policy is automatically selected */}
                {(selectedLeaveType?.policies?.length ?? 0) > 1 && (
                    <>
                        <InputLabel id='policy'>{t('user_leave_type_dialog.policy')}</InputLabel>
                        <Select
                            labelId='policy'
                            id='policy_select'
                            value={selectedLeaveTypePolicy?.id}
                            disabled={!selectedLeaveType}
                            fullWidth
                            onChange={event => {
                                if (event.target.value && selectedLeaveType) {
                                    const newValue = selectedLeaveType?.policies.find(p => p.id === event.target.value);
                                    setSelectedLeaveTypePolicy(newValue);
                                }
                            }}
                        >
                            {selectedLeaveType?.policies.map(p => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}

                <FormControlLabel
                    label={t('user_leave_type_dialog.apply_from_switch')}
                    labelPlacement='end'
                    control={<Switch color='primary' onChange={event => setWithCustomApplyFromDate(event.target.checked)} />}
                />

                {withCustomApplyFromDate && (
                    <FormControlLabel
                        label={t('user_leave_type_dialog.apply_from')}
                        labelPlacement='top'
                        control={
                            <DatePickerWrapper
                                value={applyFromDate}
                                onChange={newValue => {
                                    if (isValidDate(newValue)) {
                                        setApplyFromDate(newValue);
                                    }
                                }}
                            />
                        }
                    />
                )}
            </Stack>
            <DialogActions>
                <Button onClick={onSave} disabled={!isFormValid()} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
