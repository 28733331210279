import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridWrapper, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { ExpenseTax } from '@/domain/expense-tax/ExpenseTax.model';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';

type ExpenseTaxesGridProps = {
    taxes: ExpenseTax[];
    onRowClick: (tax: ExpenseTax) => void;
    onDeleteTax: (tax: ExpenseTax) => Promise<void>;
} & AgGridWrapperProps<ExpenseTax>;

export const ExpenseTaxesGrid: FC<ExpenseTaxesGridProps> = ({ taxes, onRowClick, onDeleteTax, ...restGridProps }) => {
    const { t } = useTranslation();

    const actionMenuRenderer = ({ data }: ICellRendererParams<ExpenseTax>) => {
        if (!data) {
            return <></>;
        }

        return <ActionMenuRenderer tax={data} onEdit={onRowClick} onDelete={tax => onDeleteTax(tax).catch(console.error)} />;
    };

    const handleRowClick = (params: RowClickedEvent<ExpenseTax>) => {
        if (params.event?.defaultPrevented || !params.data) {
            return;
        }
        onRowClick(params.data);
    };

    const columns: ColDef<ExpenseTax>[] = [
        {
            field: 'value',
            headerName: t('expense_settings_page.tax_value_header'),
            cellClass: ['display-flex'],
        },
        {
            type: 'actionMenu',
            cellRenderer: actionMenuRenderer,
        },
    ];

    return <AgGridWrapper rowData={taxes} columnDefs={columns} onRowClicked={handleRowClick} {...restGridProps} />;
};

type ActionMenuRendererProps = {
    tax: ExpenseTax;
    onEdit: (tax: ExpenseTax) => void;
    onDelete: (tax: ExpenseTax) => void;
};

const ActionMenuRenderer: FC<ActionMenuRendererProps> = ({ tax, onEdit, onDelete }) => {
    const { t } = useTranslation();

    return (
        <BasicMenu
            items={[
                {
                    title: t('general.edit'),
                    onClick: () => onEdit(tax),
                },
                {
                    title: t('general.delete'),
                    onClick: () => onDelete(tax),
                },
            ]}
        />
    );
};
