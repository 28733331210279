import { FC } from 'react';
import Chip, { chipClasses } from '@mui/material/Chip';
import { getLabelTranslation } from '@/utils/language.util';
import { DocumentTag } from '@/domain/document-tag/DocumentTag.model';
import { Typography } from '@mui/material';

export const DocumentTagChip: FC<{ tag: DocumentTag }> = ({ tag }) => {
    return (
        <Chip
            key={tag.id}
            label={
                <Typography variant={'body3'} color={'primary'}>
                    {getLabelTranslation(tag.name)}
                </Typography>
            }
            color={'primary'}
            sx={{
                height: '20px',
                p: 0.5,
                borderRadius: 0.5,
                [`& .${chipClasses.label}`]: {
                    padding: 0,
                },
            }}
        />
    );
};
