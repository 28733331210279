import {
    createAnnouncement,
    editAnnouncement,
    getAnnouncementImageUrl,
    getAnnouncements,
    getAnnouncementsAsEditor,
} from '@/domain/announcement/Announcement.service';
import { createMutationHook, createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const announcementKeys = createQueryKeys('announcements', {
    search: (...optionalParam: Parameters<typeof getAnnouncements>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    searchAsEditor: (...optionalParam: Parameters<typeof getAnnouncementsAsEditor>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    imageUrl: (...params: Parameters<typeof getAnnouncementImageUrl>) => ({
        queryKey: params,
    }),
});

export const useGetAnnouncements = createQueryHook(announcementKeys.search, getAnnouncements);

export const useGetAnnouncementsAsEditor = createQueryHook(announcementKeys.searchAsEditor, getAnnouncementsAsEditor);

export const useGetAnnouncementImageUrl = createQueryHook(announcementKeys.imageUrl, getAnnouncementImageUrl);

export const useCreateAnnouncementMutation = createMutationHook(createAnnouncement, {
    onSuccess: ({ queryClient }) => {
        return Promise.all([
            queryClient.invalidateQueries({ queryKey: announcementKeys.search._def }),
            queryClient.invalidateQueries({ queryKey: announcementKeys.searchAsEditor._def }),
        ]);
    },
});

export const useEditAnnouncementMutation = createMutationHook(editAnnouncement, {
    onSuccess: ({ variables: editMutation, queryClient }) => {
        return Promise.all([
            queryClient.invalidateQueries({ queryKey: announcementKeys.search._def }),
            queryClient.invalidateQueries({ queryKey: announcementKeys.searchAsEditor._def }),
            queryClient.invalidateQueries({ queryKey: announcementKeys.imageUrl(editMutation.id).queryKey }),
        ]);
    },
});
