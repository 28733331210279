import { SCALE_COLOR_SCHEME } from '@/domain/survey/Survey.service';
import { getColorProgressBarIndex } from '@/utils/colors.util';
import { Stack, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    boxHeight: number;
    scales: Scale[];
};

interface Scale {
    value: number;
    title: string;
    order: number;
    score: number;
    count: number;
}

export const ScaleProgressBar: FC<Props> = ({ boxHeight, scales }) => {
    const { t } = useTranslation();

    const roundNumber = (value: number) => {
        if (Math.round(value) !== value) {
            return `${value.toFixed(1)}%`;
        } else {
            return `${value}%`;
        }
    };
    const printScaleRoundNumber = (value: number) => {
        return roundNumber(value);
    };

    return (
        <Stack
            direction={'row'}
            sx={{
                '& div': {
                    '&:first-of-type': {
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                    },
                    '&:last-child': {
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                    },
                },
            }}
            flex={1}
        >
            {scales.map(scale => (
                <Tooltip
                    sx={{ maxWidth: 130, textAlign: 'center' }}
                    key={scale.title}
                    title={`${printScaleRoundNumber(scale.value)} (${scale.count} ${t('survey_results.people')}) \n ${scale.title}`}
                >
                    {scale.value > 0 ? (
                        <div
                            style={{
                                width: `${scale.value > 0 ? scale.value : 0}%`,
                                background: SCALE_COLOR_SCHEME[getColorProgressBarIndex(scale.score)].color,
                                height: `${boxHeight}px`,
                            }}
                        >
                            <Typography
                                variant='body2'
                                component={'span'}
                                color='white'
                                pl={0.5}
                                lineHeight={'24px'}
                                sx={({ breakpoints }) => ({
                                    [breakpoints.down('lg')]: {
                                        display: 'none',
                                    },
                                })}
                            >
                                {printScaleRoundNumber(scale.value)}
                            </Typography>
                        </div>
                    ) : (
                        <></>
                    )}
                </Tooltip>
            ))}
        </Stack>
    );
};
