import { API_BASE_URL, client } from '@/api/common';
import { EmployeeAvatarDTO, EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { mapObjectiveDTO, ObjectiveDTO } from '@/api/objective/Objective.api';
import { reviewRatingScaleApi, ReviewRatingScaleDTO } from '@/api/review-rating-scale/ReviewRatingScale.api';
import { SkillDTO } from '@/api/skill/Skill.api';
import {
    EmployeeReviewFeedback,
    EmployeeReviewFeedbackItem,
    EmployeeReviewFeedbackItemSearch,
    EmployeeReviewFeedbackJob,
    EmployeeReviewFeedbackMutation,
    EmployeeReviewFeedbackObjective,
    EmployeeReviewFeedbackQuestion,
    EmployeeReviewFeedbackResult,
    EmployeeReviewFeedbackSection,
    EmployeeReviewFeedbackSkill,
    EmployeeReviewFeedbackSkillQuestion,
    EmployeeReviewFeedbackSkills,
} from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeReviewSearch } from '@/domain/employee-review/EmployeeReview.model';
import { ReviewFeedback } from '@/domain/review/Review.model';
import { AxiosResponse } from 'axios';
import { JobDTO } from '@/api/job/Job.api';

type EmployeeReviewFeedbackResultDTO = EmployeeReviewFeedbackResult;
type EmployeeReviewFeedbackItemSearchDTO = EmployeeReviewFeedbackItemSearch;

export type EmployeeReviewFeedbackDTO = Overwrite<
    EmployeeReviewFeedback,
    {
        employee: EmployeeDTO;
        feedbacks: EmployeeReviewFeedbackItemDTO[];
    }
>;

export type EmployeeReviewFeedbackRequest = EmployeeReviewFeedbackMutation;

export type EmployeeReviewFeedbackItemDTO = Omit<
    EmployeeReviewFeedbackItem,
    'feedbackQuestion' | 'feedbackSkillQuestion' | 'feedbackSection' | 'reviewItem' | 'feedbackObjectives' | 'feedbackSkills' | 'rating'
> & {
    question: EmployeeReviewFeedbackQuestionDTO | undefined;
    section: EmployeeReviewFeedbackSectionDTO | undefined;
    objectives: EmployeeReviewFeedbackObjectiveDTO[] | undefined;
    skills: EmployeeReviewFeedbackSkillsDTO | undefined;
    skillQuestion: EmployeeReviewFeedbackSkillQuestionDTO | undefined;
    jobs: EmployeeReviewFeedbackJobDTO[] | undefined;
    rating: ReviewRatingScaleDTO | undefined;
    reviewItem: EmployeeReviewFeedbackItemDTO | undefined;
};

export type EmployeeReviewFeedbackSkillDTO = Omit<EmployeeReviewFeedbackSkill, 'skill'> & {
    skill: SkillDTO;
};

export type EmployeeReviewFeedbackSkillsDTO = Omit<EmployeeReviewFeedbackSkills, 'skills'> & {
    skills: EmployeeReviewFeedbackSkillDTO[];
};

export type EmployeeReviewFeedbackSkillQuestionDTO = Omit<EmployeeReviewFeedbackSkillQuestion, 'skill'> & {
    skill: SkillDTO;
};

export type EmployeeReviewFeedbackObjectiveDTO = Omit<EmployeeReviewFeedbackObjective, 'objective'> & {
    objective: ObjectiveDTO;
};

export type EmployeeReviewFeedbackJobDTO = Omit<EmployeeReviewFeedbackJob, 'job'> & {
    job: JobDTO;
};

export const mapFeedbackObjectiveDTO = (feedbackObjective: EmployeeReviewFeedbackObjectiveDTO): EmployeeReviewFeedbackObjective => {
    return {
        ...feedbackObjective,
        objective: mapObjectiveDTO(feedbackObjective.objective),
    };
};

export const mapFeedbackItemDTO = (item: EmployeeReviewFeedbackItemDTO): EmployeeReviewFeedbackItem => {
    return {
        ...item,
        feedbackQuestion: item.question,
        feedbackSection: item.section,
        feedbackObjectives: (item.objectives ?? []).map(objective => mapFeedbackObjectiveDTO(objective)),
        feedbackJobs: (item.jobs ?? []).map(job => ({ job: job.job })),
        feedbackSkills: item.skills,
        feedbackSkillQuestion: item.skillQuestion,
        rating: item.rating ? reviewRatingScaleApi.mapToReviewRatingScale(item.rating) : undefined,
    };
};

export type EmployeeReviewFeedbackQuestionDTO = EmployeeReviewFeedbackQuestion;
export type EmployeeReviewFeedbackSectionDTO = EmployeeReviewFeedbackSection;

export type ReviewFeedbackDTO = Omit<ReviewFeedback, 'reviewer'> & {
    reviewer: EmployeeAvatarDTO;
};

export const mapFeedbackDto = (feedback: EmployeeReviewFeedbackDTO): EmployeeReviewFeedback => {
    return {
        ...feedback,
        employee: mapEmployeeDTO(feedback.employee),
        feedbacks: feedback.feedbacks.map(item => mapFeedbackItemDTO(item)),
    };
};

const getFeedbacksResultsItems = async (filters: EmployeeReviewFeedbackItemSearch = {}): Promise<EmployeeReviewFeedbackResult[]> => {
    const { data } = await client.post<EmployeeReviewFeedbackResultDTO, AxiosResponse<EmployeeReviewFeedbackResultDTO[]>, EmployeeReviewFeedbackItemSearchDTO>(
        API_BASE_URL + `/employee-review-feedback/results/items/search`,

        filters,
    );

    return data;
};

export async function getFeedback(employeeReviewId: number): Promise<EmployeeReviewFeedback> {
    const { data } = await client.get<EmployeeReviewFeedbackDTO>(API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}`);
    return {
        ...data,
        feedbacks: data.feedbacks.map(feedback => mapFeedbackItemDTO(feedback)),
        employee: mapEmployeeDTO(data.employee),
    };
}

export async function submitFeedback(employeeReviewId: number, request: EmployeeReviewFeedbackMutation[]): Promise<EmployeeReviewFeedbackItem> {
    const { data } = await client.post<EmployeeReviewFeedbackItemDTO, AxiosResponse<EmployeeReviewFeedbackItemDTO, EmployeeReviewFeedbackMutation>>(
        API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}`,
        request,
    );
    return mapFeedbackItemDTO(data);
}

export async function updateFeedbacksItem(employeeReviewId: number, request: EmployeeReviewFeedbackMutation): Promise<EmployeeReviewFeedbackItem> {
    const { data } = await client.post<EmployeeReviewFeedbackItemDTO, AxiosResponse<EmployeeReviewFeedbackItemDTO, EmployeeReviewFeedbackRequest>>(
        API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/item`,
        request,
    );
    return mapFeedbackItemDTO(data);
}

export async function searchFeedbacks(request: EmployeeReviewSearch): Promise<EmployeeReviewFeedback[]> {
    const { data } = await client.post<EmployeeReviewFeedbackDTO[]>(API_BASE_URL + `/employee-review-feedbacks/search`, request);
    return data.map(feedback => mapFeedbackDto(feedback));
}

export const employeeReviewFeedbackApi = {
    getFeedback,
    submitFeedback,
    updateFeedbacksItem,
    searchFeedbacks,
    getFeedbacksResultsItems,
};
