import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { RequestStatusChip } from '@/components/request-status-chip/RequestStatusChip';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { DurationUnit } from '@/i18n/i18n';
import { UnitType } from '@/domain/date/Date.model';
import { getDayPeriodTranslationKey } from '@/domain/date/Date.service';
import { doesEmployeeMatchFilter, isValidEmployeeFilterType } from '@/domain/employee/Employee.service';
import { Employment } from '@/domain/employment/Employment.model';
import { getRequestStatusTranslationKey } from '@/domain/leave-request/LeaveRequest.service';
import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { Timesheet, TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { useGetEmployeeLeaveTypePolicies } from '@/hooks/employee/Employee.hook';
import { useAllTimesheetTableFilters } from '@/page/timesheet/AllTimesheetTableFilters.hook';
import { useGetTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { formatInDefaultHours, formatToLocalDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Paper, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterType, isSelectFilter, SelectFilterOption } from '@/components/filters-bar/FilterBar.type';
import { ColDef } from 'ag-grid-enterprise';

export const TimesheetsAllPage: FC = () => {
    const { t } = useTranslation();

    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<Timesheet>();

    const { filters: availableFilters } = useAllTimesheetTableFilters();
    const [filters, setFilters] = useFiltersStorage('all-timesheets-filters', availableFilters);
    const { dateRange, dateRangeViewType, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'all-timesheets-date-range',
    });

    const {
        data: timesheets = [],
        isFetching = true,
        isError,
        error,
    } = useGetTimesheets({
        startDate: dateRange[0],
        endDate: dateRange[1],
    });

    const { data: employeeLeavesTypePolicies = [] } = useGetEmployeeLeaveTypePolicies();

    const onBtnExport = () =>
        gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });

    const statusCellRenderer = ({ value }: { value?: string }) => (value ? <RequestStatusChip status={value} /> : undefined);

    const columnDefs: ColDef<Timesheet>[] = [
        {
            field: 'employee.employeeCode',
            headerName: t('employee.employee_code'),
            hide: true,
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
        {
            field: 'employee.currentEmployments',
            colId: 'location',
            headerName: t('timesheets.all_page.table_headers.main_location'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => employment.location.name).join(', '),
        },
        {
            headerName: t('timesheets.all_page.table_headers.department'),
            field: 'employee.currentEmployments',
            colId: 'department',
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => getLabelTranslation(employment.department.name)).join(', '),
        },
        {
            headerName: t('timesheets.all_page.table_headers.area'),
            field: 'area.name',
            valueFormatter: data => {
                return (data.data?.area?.name ?? '') + (data.data?.area?.location?.name ? ` | ${data.data.area.location.name}` : '');
            },
        },
        {
            headerName: t('timesheets.all_page.table_headers.type'),
            field: 'type',
            valueGetter: ({ data }) => {
                if (
                    data?.type === TimesheetType.TIMESHEET_PAYMENT ||
                    data?.type === TimesheetType.TIMESHEET_ADJUSTMENT ||
                    data?.type === TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT ||
                    data?.type === TimesheetType.TIMESHEET ||
                    data?.type === TimesheetType.SHIFT_TIMESHEET
                ) {
                    return t(`timesheets.all_page.timesheet_types.enum_${data?.type}`);
                }
                return getLabelTranslation(employeeLeavesTypePolicies?.find(lt => lt.leaveType.id === data?.leaveTimesheet?.leaveTypeId)?.leaveType.name);
            },
        },
        {
            headerName: t('timesheets.all_page.table_headers.day'),
            field: 'startAt',
            type: 'date',

            valueGetter: ({ data }) =>
                data?.recurringAdjustmentId
                    ? ''
                    : // It's better to use local date for the day column to avoid issues during export
                      formatToLocalDate(data?.startAt),
        },
        {
            headerName: t('timesheets.all_page.table_headers.start'),
            colId: 'timesheetStartAt',
            valueGetter: ({ data }) => {
                if (data?.type === TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT) {
                    return formatToLocalDate(data?.startAt);
                }
                if (data?.type === TimesheetType.TIMESHEET_ADJUSTMENT || data?.type === TimesheetType.TIMESHEET_PAYMENT) {
                    return '';
                }
                if (
                    data?.type === TimesheetType.LEAVE ||
                    data?.type === TimesheetType.UNPAID_LEAVE ||
                    data?.type === TimesheetType.FUTURE_LEAVE ||
                    data?.type === TimesheetType.FUTURE_COMPENSATION ||
                    data?.type === TimesheetType.COMPENSATION
                ) {
                    //return special cases
                    const leaveType = employeeLeavesTypePolicies.find(lt => lt.leaveType.id === data?.leaveTimesheet?.leaveTypeId)?.leaveType;
                    if (leaveType?.unitType === UnitType.DAYS) {
                        return t(getDayPeriodTranslationKey(data?.leaveTimesheet?.startTimePeriod));
                    }
                }
                const startDate = data?.startAt ? formatInDefaultHours(data.startAt) : data?.startAt;
                return data?.timesheetSetting?.timesheetMode === TimesheetMode.SIMPLIFIED ? '' : startDate;
            },
        },
        {
            headerName: t('timesheets.all_page.table_headers.end'),
            field: 'endAt',
            valueGetter: ({ data }) => {
                if (data?.type === TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT) {
                    return formatToLocalDate(data?.endAt);
                }
                if (data?.type === TimesheetType.TIMESHEET_ADJUSTMENT || data?.type === TimesheetType.TIMESHEET_PAYMENT) {
                    return '';
                }
                if (
                    data?.type === TimesheetType.LEAVE ||
                    data?.type === TimesheetType.UNPAID_LEAVE ||
                    data?.type === TimesheetType.FUTURE_LEAVE ||
                    data?.type === TimesheetType.FUTURE_COMPENSATION ||
                    data?.type === TimesheetType.COMPENSATION
                ) {
                    const leaveType = employeeLeavesTypePolicies?.find(lt => lt.leaveType.id === data?.leaveTimesheet?.leaveTypeId)?.leaveType;
                    if (leaveType?.unitType === UnitType.DAYS) {
                        return t(getDayPeriodTranslationKey(data?.leaveTimesheet?.endTimePeriod));
                    }
                }
                const endDate = data?.endAt ? formatInDefaultHours(data.endAt) : data?.endAt;
                return data?.timesheetSetting?.timesheetMode === TimesheetMode.SIMPLIFIED ? '' : endDate;
            },
        },
        {
            headerName: t('timesheets.all_page.table_headers.manual_break'),
            field: 'breakDuration',
            valueFormatter: data => data.data?.breakDuration.toString() ?? '',
        },
        {
            headerName: t('timesheets.all_page.table_headers.system_break'),
            field: 'systemCorrection',
            valueFormatter: data => data.data?.systemCorrection.toString() ?? '',
        },

        {
            // Worked count is based on the type of timesheet
            field: 'workedCount',
            headerName: t('timesheets.all_page.table_headers.duration'),
            valueGetter: data => (getWorkedCount(data.data) ?? 0) / 60,
            valueFormatter: ({ value }) =>
                value
                    ? t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
        },
        {
            field: 'comment',
            headerName: t('timesheets.all_page.table_headers.comment'),
        },
        {
            field: 'status',
            headerName: t('timesheets.all_page.table_headers.status'),
            valueFormatter: ({ value }) => t(getRequestStatusTranslationKey(value)),
            cellRenderer: statusCellRenderer,
        },
        {
            field: 'leaveTimesheet.id',
            hide: true,
            headerName: t('timesheets.table_headers.leave_id'),
        },
    ];

    const getTimesheetsFiltered = () => {
        // if one of the filters is filled, we need to filter the employees
        const filtersFilled = filters?.filter(filter => isSelectFilter(filter) && !!filter.value?.length);

        return !filtersFilled?.length
            ? timesheets
            : timesheets.filter(timesheet => {
                  // if one of the filter is not matching, we don't want to display the employee
                  return filtersFilled.every(f => isFilterMatched(f, timesheet));
              });
    };

    const isFilterMatched = (filter: FilterType, timesheet: Timesheet) => {
        const key = filter.key;
        if (isValidEmployeeFilterType(key)) {
            return doesEmployeeMatchFilter(timesheet.employee, getSelectFilterNumberValues(filter), key);
        } else if (filter.key === 'leaveTimesheet.leaveTypeId' && isSelectFilter(filter)) {
            return timesheetContainsAtLeastOneLeaveType(timesheet, filter.value ?? []);
        } else {
            const pathKey = key === 'areaIds' ? 'area.id' : key;
            const valueFromEmployee = getNestedValueByPath(timesheet, pathKey);
            return isSelectFilter(filter) && filter.value?.find(option => option.value === valueFromEmployee);
        }
    };

    const rowData = getTimesheetsFiltered();

    return (
        <Stack spacing={2} flexGrow={1}>
            <Stack component={Paper} p={1} spacing={3} alignItems='center' direction='row' justifyContent='space-between'>
                <Stack direction='row' alignItems='flex-start' gap={1} flexWrap={'wrap'}>
                    <DateRangePicker
                        dates={dateRange}
                        onDatesChanged={onDateRangeChange}
                        defaultViewType={dateRangeViewType}
                        availableViews={['MONTH', 'RANGE']}
                    />
                    <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />
                </Stack>
                <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
            </Stack>
            <StateHandler isLoading={isFetching} isError={isError} error={error} isEmpty={!timesheets?.length}>
                <Stack component={Paper} flex={1}>
                    <AgGridWrapper<Timesheet> initRef={setGridRef} rowData={rowData} columnDefs={columnDefs} loading={isFetching} />
                </Stack>
            </StateHandler>
        </Stack>
    );
};

const getWorkedCount = (timesheet: Timesheet | undefined): number | undefined => {
    if (timesheet?.type === TimesheetType.TIMESHEET_ADJUSTMENT || timesheet?.type === TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT) {
        return timesheet?.adjustmentCount;
    }
    if (timesheet?.type === TimesheetType.TIMESHEET_PAYMENT) {
        return timesheet?.paymentCount;
    }
    if (timesheet?.type === TimesheetType.TIMESHEET) {
        return timesheet?.workedCount;
    }
    if (timesheet?.type === TimesheetType.SHIFT_TIMESHEET) {
        return timesheet?.workedCount;
    }
    if (timesheet?.type === TimesheetType.UNPAID_LEAVE) {
        return timesheet?.unpaidLeaveCount;
    }
    return timesheet?.leaveCount ?? timesheet?.compensationCount;
};

const timesheetContainsAtLeastOneLeaveType = (timesheet: Timesheet, value: SelectFilterOption[]): boolean => {
    const isTimesheetOfTypeFilter = (value: string | number) => {
        switch (timesheet.type) {
            case TimesheetType.FUTURE_COMPENSATION:
            case TimesheetType.COMPENSATION:
            case TimesheetType.FUTURE_LEAVE:
            case TimesheetType.LEAVE:
            case TimesheetType.UNPAID_LEAVE:
            case TimesheetType.FUTURE_UNPAID_LEAVE:
                // it is a leave type id (LeaveType)
                return timesheet?.leaveTimesheet?.leaveTypeId === (value as number);
            case TimesheetType.TIMESHEET_PAYMENT:
            case TimesheetType.TIMESHEET:
            case TimesheetType.TIMESHEET_ADJUSTMENT:
            case TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT:
                // it is a timesheet type (AllPageTimesheetTypes)
                if (value === TimesheetType.TIMESHEET_ADJUSTMENT) {
                    //special case when we search for an adjustment we also want to see recurring adjustments
                    return timesheet.type === value || timesheet.type === TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT;
                }
                return timesheet.type === value;
            default:
                return false;
        }
    };

    return value.some(filterType => isTimesheetOfTypeFilter(filterType.value));
};
