import { createMutationHook, createQueryHook, defaultQueryOptions } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
    createExpenseCategoryGroup,
    deleteExpenseCategoryGroup,
    getAllExpenseCategoryGroups,
    updateExpenseCategoryGroup,
} from '@/domain/expense-category-group/ExpenseCategoryGroup.service';
import { getNull } from '@/utils/object.util';

export const expenseCategoryGroupKeys = createQueryKeys('expenseCategoryGroups', {
    getAll: {
        queryKey: getNull(),
    },
});

export const useGetAllExpenseCategoryGroups = createQueryHook(expenseCategoryGroupKeys.getAll, getAllExpenseCategoryGroups, defaultQueryOptions);

export const useCreateExpenseCategoryGroupMutation = createMutationHook(createExpenseCategoryGroup, {
    onSuccess: ({ queryClient }) => {
        return queryClient.invalidateQueries(expenseCategoryGroupKeys.getAll);
    },
});

export const useUpdateExpenseCategoryGroupMutation = createMutationHook(updateExpenseCategoryGroup, {
    onSuccess: ({ queryClient }) => {
        return queryClient.invalidateQueries(expenseCategoryGroupKeys.getAll);
    },
});

export const useDeleteExpenseCategoryGroupMutation = createMutationHook(deleteExpenseCategoryGroup, {
    onSuccess: ({ queryClient }) => {
        return queryClient.invalidateQueries(expenseCategoryGroupKeys.getAll);
    },
});
