import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';
import {
    CreateExpenseRequestMutation,
    ExpenseRequest,
    ExpenseRequestBatchMutation,
    ExpenseRequestDeclineMutation,
    ExpenseRequestSearch,
    UpdateExpenseRequestMutation,
} from '@/domain/expense-request/ExpenseRequest.model';
import { getAppConfig } from '@/config/config';

type ExpenseRequestDTO = DateToString<ExpenseRequest>;

type ExpenseRequestSearchRequestDTO = ExpenseRequestSearch;
type ExpenseRequestBatchRequestDTO = ExpenseRequestBatchMutation;
type ExpenseRequestDeclineRequestDTO = ExpenseRequestDeclineMutation;

const mapExpenseRequestDTO = (dto: ExpenseRequestDTO): ExpenseRequest => ({
    ...dto,
    date: convertUTCIsoStringToDate(dto.date),
    createdAt: convertUTCIsoStringToDate(dto.createdAt),
    submittedAt: convertUTCIsoStringToDate(dto.submittedAt),
    approvedAt: convertUTCIsoStringToDate(dto.approvedAt),
    declinedAt: convertUTCIsoStringToDate(dto.declinedAt),
    transmittedPaymentAt: convertUTCIsoStringToDate(dto.transmittedPaymentAt),
});

const EXPENSE_REQUEST_API_BASE_PATH = API_BASE_URL + '/expense-requests';

const searchExpenseRequests = async (search: ExpenseRequestSearch): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestSearchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/search`,
        search,
    );
    return data.map(mapExpenseRequestDTO);
};

// the statuses on the ExpenseRequestSearch will be ignored in the BE
const searchPendingExpenseRequests = async (search: ExpenseRequestSearch): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestSearchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/pending/search`,
        search,
    );
    return data.map(mapExpenseRequestDTO);
};

// the statuses on the ExpenseRequestSearch will be ignored in the BE
const searchApprovedExpenseRequests = async (search: ExpenseRequestSearch): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestSearchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/approved/search`,
        search,
    );
    return data.map(mapExpenseRequestDTO);
};

const getExpenseRequest = async (expenseRequestId: number): Promise<ExpenseRequest> => {
    const { data } = await client.get<ExpenseRequestDTO, AxiosResponse<ExpenseRequestDTO>>(`${EXPENSE_REQUEST_API_BASE_PATH}/${expenseRequestId}`);
    return mapExpenseRequestDTO(data);
};

const buildFormData = (mutation: CreateExpenseRequestMutation): FormData => {
    const dto = {
        ...mutation,
        files: undefined,
    };

    const formData = new FormData();
    formData.append('request', new Blob([JSON.stringify(dto)], { type: getAppConfig().MIME_TYPES.JSON }));

    if (mutation.files && mutation.files.length > 0) {
        mutation.files.forEach(file => {
            formData.append('images', file.data, file.name);
        });
    }

    return formData;
};

const createExpenseRequest = async (mutation: CreateExpenseRequestMutation): Promise<ExpenseRequest> => {
    const formData = buildFormData(mutation);

    const { data } = await client.postForm<ExpenseRequestDTO, AxiosResponse<ExpenseRequestDTO>, FormData>(EXPENSE_REQUEST_API_BASE_PATH, formData);

    return mapExpenseRequestDTO(data);
};

const updateExpenseRequest = async (expenseRequestId: number, mutation: UpdateExpenseRequestMutation): Promise<ExpenseRequest> => {
    const formData = buildFormData(mutation);

    const { data } = await client.putForm<ExpenseRequestDTO, AxiosResponse<ExpenseRequestDTO>>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/${expenseRequestId}`,
        formData,
    );

    return mapExpenseRequestDTO(data);
};

const cancelPendingExpenseRequests = async (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestBatchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/pending/cancel`,
        request,
    );
    return data.map(mapExpenseRequestDTO);
};

const cancelApprovedExpenseRequests = async (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestBatchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/approved/cancel`,
        request,
    );
    return data.map(mapExpenseRequestDTO);
};

const submitExpenseRequests = async (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestBatchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/submit`,
        request,
    );
    return data.map(mapExpenseRequestDTO);
};

const approveExpenseRequests = async (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestBatchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/approve`,
        request,
    );
    return data.map(mapExpenseRequestDTO);
};

const declineExpenseRequests = async (request: ExpenseRequestDeclineMutation): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestDeclineRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/decline`,
        request,
    );
    return data.map(mapExpenseRequestDTO);
};

const declineApprovedExpenseRequests = async (request: ExpenseRequestDeclineMutation): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestDeclineRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/approved/decline`,
        request,
    );
    return data.map(mapExpenseRequestDTO);
};

const transmitToPaymentExpenseRequests = async (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestBatchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/transmit-payment`,
        request,
    );
    return data.map(mapExpenseRequestDTO);
};

const undoPaymentExpenseRequests = async (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    const { data } = await client.post<ExpenseRequestDTO[], AxiosResponse<ExpenseRequestDTO[]>, ExpenseRequestBatchRequestDTO>(
        `${EXPENSE_REQUEST_API_BASE_PATH}/undo-payment`,
        request,
    );
    return data.map(mapExpenseRequestDTO);
};

export const expenseRequestApi = {
    searchExpenseRequests,
    searchPendingExpenseRequests,
    searchApprovedExpenseRequests,
    getExpenseRequest,
    createExpenseRequest,
    updateExpenseRequest,
    cancelPendingExpenseRequests,
    cancelApprovedExpenseRequests,
    submitExpenseRequests,
    approveExpenseRequests,
    declineExpenseRequests,
    declineApprovedExpenseRequests,
    transmitToPaymentExpenseRequests,
    undoPaymentExpenseRequests,
};
