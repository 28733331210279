import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { canApproveRejectOtherEmployeeTimesheets, canManageOtherEmployeeTimesheets } from '@/domain/permission/Permission.service';
import { Page } from '@/page/Pages';

export const getTimesheetPages = (employeeId: number, policies: EmployeePolicy[]): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'timesheets.tabs.monthly_summary',
            path: '/timesheets/monthly-summary',
            condition: (policies: EmployeePolicy[]) => canManageOtherEmployeeTimesheets(policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.pending_timesheets',
            path: '/timesheets/pending',
            condition: (policies: EmployeePolicy[]) => canApproveRejectOtherEmployeeTimesheets(policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.all',
            path: '/timesheets/all',
            condition: (policies: EmployeePolicy[]) => canManageOtherEmployeeTimesheets(policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.balances',
            path: '/timesheets/balances',
            condition: (policies: EmployeePolicy[]) => canManageOtherEmployeeTimesheets(policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.insights',
            path: '/timesheets/insights',
            condition: (policies: EmployeePolicy[]) => canManageOtherEmployeeTimesheets(policies, employeeId),
        },
        {
            labelKey: 'timesheets.tabs.statutory_overtime',
            path: '/timesheets/weekly-insights',
            condition: (policies: EmployeePolicy[]) => canManageOtherEmployeeTimesheets(policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(policies));
};
