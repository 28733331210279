import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FC } from 'react';
import { ThirdPartyLogGrid } from '../third-party-log-grid/ThirdPartyLogGrid';
import { ThirdPartyIntegrationLog } from '@/domain/third-party/ThirdParty.model';

type ThirdPartyConnectorLogDialogProps = DialogWrapperProps & {
    title: string;
    thirdPartyIntegrationLogs: ThirdPartyIntegrationLog[];
};

export const ThirdPartyConnectorEmployeeLogDialog: FC<ThirdPartyConnectorLogDialogProps> = ({ title, thirdPartyIntegrationLogs, ...rest }) => {
    return (
        <DialogWrapper header={title} fullScreen {...rest}>
            <ThirdPartyLogGrid thirdPartyIntegrationLogs={thirdPartyIntegrationLogs} />
        </DialogWrapper>
    );
};
