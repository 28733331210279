import { FC } from 'react';

import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router';

type RouteLeavingGuardProps = {
    when: boolean;
};

export const RouteLeavingGuard: FC<RouteLeavingGuardProps> = ({ when }) => {
    const { t } = useTranslation();

    // Block navigating elsewhere when data has been entered into the input
    const blocker = useBlocker(({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && when);

    if (blocker.state === 'blocked') {
        return (
            <ConfirmDialog
                open={true}
                onConfirm={() => blocker.proceed()}
                content={t('confirmation_dialog.you_will_lose_your_changes')}
                onClose={() => blocker.reset()}
            />
        );
    }
};
