import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { deleteTimesheetSettings } from '@/domain/timesheet-setting/TimesheetSetting.service';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { useGetTimesheetSettings } from '@/hooks/timesheet-setting/TimesheetSettings.hook';
import { ConfigType } from '@/page/setting/types';
import { handleError } from '@/utils/api.util';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Stack } from '@mui/material';
import { Tick02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const TimeManagementAllSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [timesheetSettingToDelete, setTimesheetSettingToDelete] = useState<TimesheetSetting>();

    const navigate = useNavigate();

    const { data: timesheetSettings = [], refetch: refetchTimesheetSettings } = useGetTimesheetSettings();

    const getNightWorkWordings = (timesheetSetting: TimesheetSetting) => {
        const { nightBonusStartTime, nightBonusEndTime, nightBonusPercentage } = timesheetSetting;

        const nightBonusEndTimeString = `${nightBonusStartTime ? ' - ' : ''}${nightBonusEndTime}`;
        const sentences = [
            nightBonusStartTime ?? '',
            nightBonusEndTime ? nightBonusEndTimeString : '',
            nightBonusPercentage ? ` (${t('time_management_settings_page.bonus')} ${nightBonusPercentage}%)` : '',
        ];

        return sentences.join('');
    };

    const handleDeleteTimesheetSettings = async (timesheetSettingsId: number) => {
        try {
            await deleteTimesheetSettings(timesheetSettingsId);
        } catch (error) {
            handleError(error);
        } finally {
            refetchTimesheetSettings().catch(handleError);
        }
    };

    const getItems = (data: TimesheetSetting | undefined): BasicMenuItem[] => {
        const id = data?.id;
        if (!id) {
            return [];
        }
        return [
            {
                title: t('general.edit'),
                onClick: () => navigate(id.toString()),
            },
            {
                title: t('general.delete'),
                onClick: () => setTimesheetSettingToDelete(data),
            },
        ];
    };

    const cellActionRenderer = ({ data }: ICellRendererParams<TimesheetSetting>) => {
        return <BasicMenu items={getItems(data)} />;
    };

    const options: ConfigType<TimesheetSetting> = {
        header: {
            primaryButtonCaption: t('time_management_settings_page.add_working_time'),
            primaryButtonAction: () => navigate('new'),
        },
        type: 'table',
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: ({ data }) => {
                    if (!data) {
                        return;
                    }
                    navigate(data.id.toString());
                },
            },

            columns: [
                {
                    field: 'name',
                    headerName: t('time_management_settings_page.setting_name'),
                },
                {
                    field: 'timesheetMode',
                    headerName: t('time_management_settings_page.timesheet_mode'),
                    valueFormatter: ({ value }) =>
                        value === 'NORMAL' ? t('timesheets.timesheet_mode.mode_normal') : t('timesheets.timesheet_mode.mode_simplified'),
                },
                {
                    field: 'autofillTimesheet',
                    headerName: t('time_management_settings_page.auto_fill'),
                    cellRenderer: StatusTick,
                },
                {
                    field: 'mandatoryComment',
                    headerName: t('time_management_settings_page.requires_comment'),
                    cellRenderer: StatusTick,
                },
                {
                    colId: 'nightWork',
                    headerName: t('time_management_settings_page.night_work'),
                    valueFormatter: ({ data }: { data: TimesheetSetting | undefined }) => (data ? getNightWorkWordings(data) : ''),
                },
                {
                    field: 'saturdayBonusPercentage',
                    headerName: t('time_management_settings_page.saturday'),
                    valueFormatter: ({ value }) => `${value}%`,
                },
                {
                    field: 'sundayBonusPercentage',
                    headerName: t('time_management_settings_page.sunday_holidays'),
                    valueFormatter: ({ value }) => `${value}%`,
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ] satisfies ColDef<TimesheetSetting>[],
        },
    };

    return (
        <Stack flex={1}>
            <CompanySettingsLayout options={options} data={timesheetSettings} />

            <ConfirmDialog
                title={t('time_management_settings_page.are_you_sure_delete_setting')}
                content={t('general.action_irreversible')}
                open={!!timesheetSettingToDelete?.id}
                onClose={() => {
                    setTimesheetSettingToDelete(undefined);
                }}
                onConfirm={() => {
                    if (timesheetSettingToDelete?.id) {
                        handleDeleteTimesheetSettings(timesheetSettingToDelete.id)
                            .then(() => {
                                setTimesheetSettingToDelete(undefined);
                            })
                            .catch(handleError);
                    }
                }}
                // Override default confirm button props
                confirmButtonProps={{
                    children: t('general.delete'),
                    color: 'error',
                }}
            />
        </Stack>
    );
};

const StatusTick: FC<{ value: boolean }> = ({ value }) => {
    if (value) {
        return <Tick02Icon />;
    }
    return <></>;
};
