import { TranslatableLabelTypography } from '@/components/translatable-label-typography/TranslatableLabelTypography';
import { SectionFieldDefinitionDraggable } from '@/page/setting/section/SectionFieldDefinitionDraggable';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material';
import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { DraggableItem } from '@/components/dnd/DraggableItem';
import { useReorderOnDrop } from '@/components/dnd/useReorderOnDrop';
import { SectionDefinition, SectionFieldDefinition, SectionType } from '@/domain/section-setting/Section.model';
import Chip from '@mui/material/Chip';
import { ArrowDown01Icon, ArrowUp01Icon, DragDropHorizontalIcon } from 'hugeicons-react';
import i18next from 'i18next';

type SectionDefinitionDraggableProps = {
    section: SectionDefinition;
    onDelete: () => void;
    onUpdate: () => void;
    onArchive: () => void;
    onUnarchive: () => void;
    onFieldAdd: () => void;
    onFieldUpdate: (field: SectionFieldDefinition) => void;
    onFieldDelete: (field: SectionFieldDefinition) => void;
    onFieldsOrderChange: (fields: SectionFieldDefinition[]) => void;
    onFieldArchive: (field: SectionFieldDefinition) => void;
    onFieldUnarchive: (field: SectionFieldDefinition) => void;
};

export const SectionDefinitionDraggable: FC<SectionDefinitionDraggableProps> = ({
    section,
    onDelete = () => {},
    onUpdate = () => {},
    onArchive = () => {},
    onUnarchive = () => {},
    onFieldAdd = () => {},
    onFieldUpdate = () => {},
    onFieldDelete = () => {},
    onFieldsOrderChange = () => {},
    onFieldArchive = () => {},
    onFieldUnarchive = () => {},
}) => {
    const { t } = useTranslation();

    // We control the expanded state of the accordion ourselves
    // to manage icon position
    const [expanded, setExpanded] = useState<boolean>(false);
    const expandable = !section.archived && !section.unmodifiable;

    useReorderOnDrop({
        list: section.fields.map(item => ({
            ...item,
            id: item.formId,
            _id: item.id,
        })),
        onChange: newList => {
            // We update the order of the fields in the section
            onFieldsOrderChange(
                newList.map((item, index) => ({
                    ...item,
                    id: item._id,
                    formId: item.id,
                    order: index,
                })),
            );
        },
        keyId: 'data-section-field-id',
        axis: 'vertical',
    });
    const onDragStart = useCallback(() => {
        setExpanded(false);
    }, []);

    if (!section) {
        return undefined;
    }

    const isCustomSection = section.type === SectionType.CUSTOM_SINGLE_ROW || section.type === SectionType.CUSTOM_MULTI_ROW;

    const handleExpandedChange = (_: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    const sectionMenuItems: BasicMenuItem[] = section.unmodifiable
        ? []
        : [
              {
                  title: i18next.t('general.edit'),
                  onClick: onUpdate,
                  hide: section.archived,
              },
              {
                  title: i18next.t('general.delete'),
                  onClick: onDelete,
                  hide: !isCustomSection || !section.archived,
              },
              {
                  title: i18next.t('general.archive'),
                  onClick: () => {
                      onArchive();
                      if (expanded) {
                          setExpanded(false);
                      }
                  },
                  hide: !isCustomSection || section.archived,
                  confirmationRequired: true,
              },
              {
                  title: i18next.t('general.unarchive'),
                  onClick: onUnarchive,
                  hide: !isCustomSection || !section.archived,
              },
          ];

    return (
        <Stack>
            <Accordion onChange={expandable ? handleExpandedChange : undefined} expanded={expanded} data-section-id={section.id}>
                <DraggableItem id={section.id.toString()} allowedEdges={['bottom', 'top']} onDragStart={onDragStart}>
                    <AccordionSummary>
                        <Stack flex='1' direction='row' gap={2} alignItems='center' justifyContent='space-between'>
                            <Box display='flex' alignSelf='center'>
                                <DragDropHorizontalIcon cursor={'move'} />
                            </Box>
                            <Box flex='1'>
                                {/* section definition are only display with default language */}
                                <Stack flexDirection={'row'} gap={1} alignItems='center'>
                                    <TranslatableLabelTypography variant='h2' label={section?.name} sx={{ wordBreak: 'break-word' }} />
                                    {section.archived && <Chip color={'warning'} label={t('general.archived')} />}
                                </Stack>
                                {isCustomSection && (
                                    <Typography variant='body2bold' color='primary'>
                                        {t(`employee_fields_page.${section.type}`)}
                                    </Typography>
                                )}
                            </Box>
                            <Stack direction={'row'} alignItems={'center'} alignSelf={'flex-start'}>
                                {expandable && (expanded ? <ArrowUp01Icon /> : <ArrowDown01Icon />)}
                                {!section.unmodifiable && <BasicMenu items={sectionMenuItems} />}
                            </Stack>
                        </Stack>
                    </AccordionSummary>
                </DraggableItem>
                <AccordionDetails>
                    <Stack gap={2}>
                        {isCustomSection && (
                            <Stack direction='row-reverse'>
                                <Button variant='text' onClick={onFieldAdd}>
                                    {t('employee_fields_page.new_field')}
                                </Button>
                            </Stack>
                        )}

                        {/* Display fields */}
                        <Stack gap={2}>
                            {section.fields.map(field => (
                                <DraggableItem
                                    id={field.formId}
                                    key={field.formId}
                                    allowedEdges={['bottom', 'top']}
                                    canDrop={({ id: formId }: { id: string }) => {
                                        const parent = section.fields.find(item => item.formId === formId);

                                        // Not droppable if the item is not a field of this section
                                        if (!parent) {
                                            return false;
                                        }

                                        return true;
                                    }}
                                >
                                    <SectionFieldDefinitionDraggable
                                        sectionField={field}
                                        onFieldUpdate={onFieldUpdate}
                                        onFieldDelete={onFieldDelete}
                                        onFieldArchive={onFieldArchive}
                                        onFieldUnarchive={onFieldUnarchive}
                                    />
                                </DraggableItem>
                            ))}
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};
