import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelTranslation } from '@/utils/language.util';
import { handleError } from '@/utils/api.util';
import { Box, Paper, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { SectionLoading } from '@/components/section/SectionLoading';
import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { SectionDefinition, SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { ImportType } from '@/domain/import/Import.model';
import { getColumnDefs, getRowData, getSectionTypeTranslationKey } from '@/page/setting/import/custom-section-import-card/CustomSectionsImportCard.util';
import { Download02Icon } from 'hugeicons-react';
import { getSectionDefinitions } from '@/domain/section-setting/Section.service';
import { isDefined } from '@/utils/collections.util';

export type CustomSectionAgGridData = Record<string, string>;

type CustomSectionsImportCardProps = {
    handleUploadClick?: (importType: ImportType, sectionId: number) => void;
};
export const CustomSectionsImportCard: FC<CustomSectionsImportCardProps> = ({ handleUploadClick }) => {
    const { t } = useTranslation();
    const [customSections, setCustomSections] = useState<SectionDefinition[]>();

    const getCustomSections = (sectionDefinitions: SectionDefinition[]) => {
        return sectionDefinitions.filter(sectionDefinition => {
            return sectionDefinition.type === 'CUSTOM_SINGLE_ROW' || sectionDefinition.type === 'CUSTOM_MULTI_ROW';
        });
    };

    useEffect(() => {
        const fetchSectionDefinitions = async () => {
            try {
                const data = await getSectionDefinitions();
                const customSections = getCustomSections(data);
                setCustomSections(customSections);
            } catch (e) {
                handleError(e);
            }
        };

        fetchSectionDefinitions().catch(handleError);
    }, []);

    if (!customSections) {
        return <SectionLoading />;
    }

    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            <Stack gap={2}>
                <Typography variant={'h2'}>{t('import_page.custom_sections_tables')}</Typography>
                {customSections.length === 0 ? (
                    <Typography variant={'body1'}>{t('import_page.no_custom_sections')}</Typography>
                ) : (
                    <Stack gap={2}>
                        {customSections.map(section => {
                            return <CustomSection key={section.id} section={section} handleUploadClick={handleUploadClick} />;
                        })}
                    </Stack>
                )}
            </Stack>
        </Paper>
    );
};

export interface CustomSectionProps {
    section: SectionDefinition;
    handleUploadClick?: (importType: ImportType, sectionId: number) => void;
}

export const CustomSection: FC<CustomSectionProps> = ({ section, handleUploadClick }) => {
    const { t } = useTranslation();
    const agGridWrapper = useAgGridWrapper<CustomSectionAgGridData>();

    const generateExcelTemplate = (fields: SectionFieldDefinition[]) => () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
            columnKeys: getColumnDefs(fields)
                .map(colDef => colDef.colId ?? colDef.field)
                .filter(isDefined),
        });
    };

    return (
        <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} gap={1}>
                    <Typography variant={'body2bold'}>{getLabelTranslation(section.name)}</Typography>
                    <Typography variant={'body2'}>({t(getSectionTypeTranslationKey(section.type))})</Typography>
                </Stack>
                <Stack direction={'row'} gap={2}>
                    <Button variant='text' endIcon={<Download02Icon />} onClick={generateExcelTemplate(section.fields)}>
                        {t('import_page.download_template')}
                    </Button>
                    <Button onClick={() => handleUploadClick?.(ImportType.CUSTOM_SECTION, section.id)}>{t('general.upload')}</Button>
                </Stack>
            </Stack>

            <Box display={'none'}>
                <AgGridWrapper<CustomSectionAgGridData>
                    rowData={getRowData(section.fields)}
                    initRef={agGridWrapper.setGridRef}
                    columnDefs={getColumnDefs(section.fields)}
                    loading={false}
                    excelStyles={[
                        {
                            id: 'dateISO',
                            dataType: 'Number',
                            numberFormat: {
                                format: 'dd.mm.yyyy',
                            },
                        },
                    ]}
                />
            </Box>
        </>
    );
};
