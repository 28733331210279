import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';
import {
    CreateExpenseCategoryMutation,
    ExpenseCategory,
    ExpenseCategoryPreset,
    ExpenseCategorySearch,
    UpdateExpenseCategoryMutation,
} from '@/domain/expense-category/ExpenseCategory.model';

export type ExpenseCategoryPresetDTO = Omit<ExpenseCategoryPreset, 'archivedAt'> & {
    archivedAt?: string;
};

export type ExpenseCategoryDTO = Omit<ExpenseCategory, 'presets'> & {
    presets: ExpenseCategoryPresetDTO[];
};

type ExpenseCategorySearchRequestDTO = ExpenseCategorySearch;
type CreateExpenseCategoryRequestDTO = CreateExpenseCategoryMutation;
type UpdateExpenseCategoryRequestDTO = UpdateExpenseCategoryMutation;

const mapExpenseCategoryPresetDTO = (dto: ExpenseCategoryPresetDTO): ExpenseCategoryPreset => ({
    ...dto,
    archivedAt: dto.archivedAt ? convertUTCIsoStringToDate(dto.archivedAt) : undefined,
});

const mapExpenseCategoryDTO = (dto: ExpenseCategoryDTO): ExpenseCategory => ({
    ...dto,
    presets: dto.presets.map(mapExpenseCategoryPresetDTO),
});

const mapCreateMutationToDTO = (mutation: CreateExpenseCategoryMutation): CreateExpenseCategoryRequestDTO => mutation;

const mapUpdateMutationToDTO = (mutation: UpdateExpenseCategoryMutation): UpdateExpenseCategoryRequestDTO => mutation;

const EXPENSE_CATEGORY_API_BASE_PATH = API_BASE_URL + '/expense-categories';

const searchExpenseCategories = async (search: ExpenseCategorySearch): Promise<ExpenseCategory[]> => {
    const { data } = await client.post<ExpenseCategoryDTO[], AxiosResponse<ExpenseCategoryDTO[]>, ExpenseCategorySearchRequestDTO>(
        `${EXPENSE_CATEGORY_API_BASE_PATH}/search`,
        search,
    );
    return data.map(mapExpenseCategoryDTO);
};

const createExpenseCategory = async (mutation: CreateExpenseCategoryMutation): Promise<ExpenseCategory> => {
    const request = mapCreateMutationToDTO(mutation);
    const { data } = await client.post<ExpenseCategoryDTO, AxiosResponse<ExpenseCategoryDTO>, CreateExpenseCategoryRequestDTO>(
        EXPENSE_CATEGORY_API_BASE_PATH,
        request,
    );
    return mapExpenseCategoryDTO(data);
};

const updateExpenseCategory = async (expenseCategoryId: number, mutation: UpdateExpenseCategoryMutation): Promise<ExpenseCategory> => {
    const request = mapUpdateMutationToDTO(mutation);
    const { data } = await client.put<ExpenseCategoryDTO, AxiosResponse<ExpenseCategoryDTO>, UpdateExpenseCategoryRequestDTO>(
        `${EXPENSE_CATEGORY_API_BASE_PATH}/${expenseCategoryId}`,
        request,
    );
    return mapExpenseCategoryDTO(data);
};

const deleteExpenseCategory = async (expenseCategoryId: number): Promise<void> => {
    await client.delete(`${EXPENSE_CATEGORY_API_BASE_PATH}/${expenseCategoryId}`);
};

const unarchiveExpenseCategory = async (expenseCategoryId: number): Promise<ExpenseCategory> => {
    const { data } = await client.post<ExpenseCategoryDTO, AxiosResponse<ExpenseCategoryDTO>>(
        `${EXPENSE_CATEGORY_API_BASE_PATH}/${expenseCategoryId}/unarchive`,
    );
    return mapExpenseCategoryDTO(data);
};

const archiveExpenseCategory = async (expenseCategoryId: number): Promise<ExpenseCategory> => {
    const { data } = await client.post<ExpenseCategoryDTO, AxiosResponse<ExpenseCategoryDTO>>(`${EXPENSE_CATEGORY_API_BASE_PATH}/${expenseCategoryId}/archive`);
    return mapExpenseCategoryDTO(data);
};

const getExpenseCategoryById = async (expenseCategoryId: number): Promise<ExpenseCategory> => {
    const { data } = await client.get<ExpenseCategoryDTO, AxiosResponse<ExpenseCategoryDTO>>(`${EXPENSE_CATEGORY_API_BASE_PATH}/${expenseCategoryId}`);
    return mapExpenseCategoryDTO(data);
};

export const expenseCategoryApi = {
    getExpenseCategoryById,
    searchExpenseCategories,
    createExpenseCategory,
    updateExpenseCategory,
    deleteExpenseCategory,
    unarchiveExpenseCategory,
    archiveExpenseCategory,
};
