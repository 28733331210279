import { getEmployeesAvailability } from '@/domain/employee-availability/EmployeeAvailability.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeAvailabilityKeys = createQueryKeys('employeeAvailability', {
    get: (...params: Parameters<typeof getEmployeesAvailability>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeAvailabilities = createQueryHook(employeeAvailabilityKeys.get, getEmployeesAvailability);
