import { Environment, getAppConfig, getAppEnv, getCurrentAppVersion } from '@/config/config';
import Bugsnag, { BreadcrumbType } from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
// eslint-disable-next-line no-restricted-imports
import React, { Fragment } from 'react';

const appEnv = getAppEnv();
const config = getAppConfig();
const bugsnagApiKey = appEnv === Environment.PROD ? config.BUGSNAG.API_KEY : config.BUGSNAG.DEV_API_KEY;
const appVersion = getCurrentAppVersion();
const enabledReleaseStages = [Environment.PROD, Environment.PREPROD, Environment.STAGING, Environment.TEST];
const prodBreadcrumbTypes: BreadcrumbType[] = ['error', 'manual', 'navigation', 'process', 'request', 'state', 'user'];
const otherBreadcrumbTypes: BreadcrumbType[] = [...prodBreadcrumbTypes, 'log'];

export const bugsnagOptions = {
    appType: 'client',
    apiKey: bugsnagApiKey,
    releaseStage: appEnv,
    enabledReleaseStages,
    appVersion,
    enabledBreadcrumbTypes: appEnv === Environment.PROD ? prodBreadcrumbTypes : otherBreadcrumbTypes,
};

// configure Bugsnag
Bugsnag.start({
    ...bugsnagOptions,
    plugins: [new BugsnagPluginReact()],
});

export const BugsnagErrorCatcher: BugsnagErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? Fragment;
