import { ExpenseCurrency, ExpenseCurrencyCreateMutation, ExpenseCurrencyUpdateMutation } from '@/domain/expense-currency/ExpenseCurrency.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';

export type ExpenseCurrencyDTO = ExpenseCurrency;

type ExpenseCurrencyCreateRequestDTO = ExpenseCurrencyCreateMutation;
type ExpenseCurrencyUpdateRequestDTO = ExpenseCurrencyUpdateMutation;

const mapExpenseCurrencyDTO = (dto: ExpenseCurrencyDTO): ExpenseCurrency => dto;

const mapUpdateMutationToDTO = (mutation: ExpenseCurrencyUpdateMutation): ExpenseCurrencyUpdateRequestDTO => mutation;

const EXPENSE_CURRENCY_API_BASE_PATH = API_BASE_URL + '/expense-currencies';

const searchExpenseCurrencies = async (): Promise<ExpenseCurrency[]> => {
    const { data } = await client.post<ExpenseCurrencyDTO[]>(`${EXPENSE_CURRENCY_API_BASE_PATH}/search`);
    return data.map(mapExpenseCurrencyDTO);
};

const createExpenseCurrency = async (mutation: ExpenseCurrencyCreateMutation): Promise<ExpenseCurrency> => {
    const { data } = await client.post<ExpenseCurrencyDTO, AxiosResponse<ExpenseCurrencyDTO>, ExpenseCurrencyCreateRequestDTO>(
        EXPENSE_CURRENCY_API_BASE_PATH,
        mutation,
    );
    return mapExpenseCurrencyDTO(data);
};

const updateExpenseCurrency = async (expenseCurrencyId: number, mutation: ExpenseCurrencyUpdateMutation): Promise<ExpenseCurrency> => {
    const request = mapUpdateMutationToDTO(mutation);
    const { data } = await client.put<ExpenseCurrencyDTO, AxiosResponse<ExpenseCurrencyDTO>, ExpenseCurrencyUpdateRequestDTO>(
        `${EXPENSE_CURRENCY_API_BASE_PATH}/${expenseCurrencyId}`,
        request,
    );
    return mapExpenseCurrencyDTO(data);
};

const deleteExpenseCurrency = async (expenseCurrencyId: number): Promise<void> => {
    await client.delete(`${EXPENSE_CURRENCY_API_BASE_PATH}/${expenseCurrencyId}`);
};

export const expenseCurrencyApi = {
    searchExpenseCurrencies,
    createExpenseCurrency,
    updateExpenseCurrency,
    deleteExpenseCurrency,
};
