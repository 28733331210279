import * as yup from 'yup';
import { getLocalDateMinTestConfig, getLocalDateTestConfig } from '@/utils/datetime.util';
import i18next from 'i18next';
import { isDateInAllowedRanges } from '@/domain/employment/Employment.service';
import { getNull } from '@/utils/object.util';
import { ContractType, TerminationReason, TerminationType } from '@/domain/employment/Employment.model';
import { getEmploymentItemSchema } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';
import { isDefined } from '@/utils/collections.util';

export type ContractSchemaConfig = {
    range: [LocalDate | undefined, LocalDate | undefined];
};

export const getContractSchema = (config: ContractSchemaConfig) => {
    return yup.object().shape({
        startDate: yup
            .string<LocalDate>()
            .required()
            .test(getLocalDateTestConfig())
            .test({
                name: 'start-date-out-of-bounds',
                message: i18next.t('employee.employment.contract.modify_contract_overlapping_date_error'),
                test: startDate => {
                    const range = config.range;

                    // If there are no ranges, we can't check for overlapping dates
                    if (!range.filter(isDefined).length) {
                        return true;
                    }

                    return !startDate || isDateInAllowedRanges(startDate, [range]);
                },
            }),
        probationEndDate: yup
            .string<LocalDate>()
            .default(getNull())
            .nullable()
            .test(getLocalDateTestConfig())
            .when('startDate', ([startDate], schema) => schema.test(getLocalDateMinTestConfig(startDate))),
        contractType: yup.string().required().oneOf(Object.values(ContractType), i18next.t('general.validations.required')),
        endDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .nullable()
            .default(getNull())
            .when('startDate', ([startDate], schema) => schema.test(getLocalDateMinTestConfig(startDate)))
            .when('probationEndDate', ([probationEndDate], schema) => (probationEndDate ? schema.test(getLocalDateMinTestConfig(probationEndDate)) : schema)),
        terminationType: yup
            .string()
            .oneOf(Object.values(TerminationType))
            .when('endDate', ([endDate], schema) => (endDate ? schema.required() : schema.nullable().default(getNull()))),
        terminationReason: yup
            .string()
            .oneOf(Object.values(TerminationReason))
            .when('endDate', ([endDate], schema) => (endDate ? schema.required() : schema.nullable().default(getNull()))),
        terminationNoticeDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).nullable().default(getNull()),
        terminationLastDayAtWork: yup.string<LocalDate>().test(getLocalDateTestConfig()).nullable().default(getNull()),
        terminationComment: yup.string().trim().default(''),
    });
};
export type ContractFormValues = yup.InferType<ReturnType<typeof getContractSchema>>;

export const getNewContractSchema = (config: ContractSchemaConfig) => {
    return getContractSchema(config)
        .pick(['startDate', 'probationEndDate', 'contractType', 'endDate'])
        .concat(getEmploymentItemSchema().pick(['department', 'location', 'job', 'managers', 'employmentCostCenters']));
};
export type NewContractFormValues = yup.InferType<ReturnType<typeof getNewContractSchema>>;
