export const useValidPassword = (
    value: string,
): {
    has8Characters: boolean;
    hasUppercaseLetter: boolean;
    hasLowercaseLetter: boolean;
    hasNumber: boolean;
    isValid: boolean;
} => {
    return {
        isValid: has8Characters(value) && hasUppercaseLetter(value) && hasLowercaseLetter(value) && hasNumber(value),
        has8Characters: has8Characters(value),
        hasUppercaseLetter: hasUppercaseLetter(value),
        hasLowercaseLetter: hasLowercaseLetter(value),
        hasNumber: hasNumber(value),
    };
};

const has8Characters = (value: string): boolean => {
    return value?.length >= 8;
};

const hasUppercaseLetter = (value: string): boolean => {
    for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i);
        if (char >= 'A' && char <= 'Z') {
            return true;
        }
    }
    return false;
};

const isNumber = (ch: string): boolean => ch.length === 1 && ch >= '0' && ch <= '9';

const hasNumber = (value: string): boolean => {
    for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i);
        if (isNumber(char)) {
            return true;
        }
    }
    return false;
};

const hasLowercaseLetter = (value: string): boolean => {
    for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i);
        if (char >= 'a' && char <= 'z') {
            return true;
        }
    }
    return false;
};
