import { DaysAvailableComponent } from '@/components/days-available-component/DaysAvailableComponent';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { MultipleDatePicker } from '@/components/multi-date-picker/MultipleDatePicker';
import { StackedAvatars } from '@/domain-ui/employee/stacked-avatar/StackedAvatars';
import { DayPeriod, UnitType } from '@/domain/date/Date.model';
import { getDayPeriodTranslationKey } from '@/domain/date/Date.service';
import { Employee } from '@/domain/employee/Employee.model';
import { LeaveRequest, LeaveRequestPreview } from '@/domain/leave-request/LeaveRequest.model';
import {
    convertLeavesMinutesToUnit,
    getEndPeriodChoices,
    getLeaveRequestPeriodAsString,
    getStartPeriodChoices,
    isLeaveRequestExceedingMaxDuration,
    isMedicalType,
    isOverlappingExceedsLimitInDays,
} from '@/domain/leave-request/LeaveRequest.service';
import { AllowanceType, LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { getTotalConflictingRequestsLeavePercentage, hasConflicts, hasPartialConflicts } from '@/page/leave/leave-request-dialog/LeaveRequestDialog.util';
import { LeaveRequestFormValues } from '@/page/leave/leave-request-dialog/LeaveRequestForm.schema';
import { convertMinutesToDays, getCurrentLocalDate, isSameDate, toDate } from '@/utils/datetime.util';
import { RoundingType } from '@/utils/math.util';
import { getNull } from '@/utils/object.util';
import { Alert, Button, FormControlLabel, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getLeaveTypeDisplayUnitType } from '@/domain/leave-type/LeaveType.service';
import { getLabelTranslation } from '@/utils/language.util';

type DailyLeaveTypeDetailsProps = {
    previewResult?: LeaveRequestPreview;
    isEdit?: boolean;
    overlappingRequests: LeaveRequest[];
    conflictingRequests: LeaveRequest[];
    displayOverlappingRequestDetails: () => void;
    remainingAmountInDays?: number;
    availableAmountInDays?: number;
    remainingAmountInMinutes?: number;
    availableAmountInMinutes?: number;
};

export const DailyLeaveTypeDetails: FC<PropsWithChildren<DailyLeaveTypeDetailsProps>> = ({
    previewResult,
    isEdit,
    overlappingRequests,
    conflictingRequests,
    displayOverlappingRequestDetails,
    remainingAmountInDays = 0,
    availableAmountInDays = 0,
    remainingAmountInMinutes = 0,
    availableAmountInMinutes = 0,
    children,
}) => {
    const { control, getValues } = useFormContext<LeaveRequestFormValues>();
    const { leaveType, startDate, endDate, isMultipleDays, unitType } = getValues();

    const { t } = useTranslation();
    const overlappingUsers: Employee[] = overlappingRequests?.filter(r => r.employee).map(r => r.employee) ?? [];
    // remove duplicate using map
    const uniqueOverlappingUsers: Employee[] = [...new Map(overlappingUsers.map(u => [u.id, u])).values()];

    const showOverlapping = !!endDate && !!startDate && isOverlappingExceedsLimitInDays(toDate(startDate), toDate(endDate));

    const getDuration = (amount: number, unitType: UnitType, roundingType: RoundingType) => {
        return convertLeavesMinutesToUnit({
            input: amount,
            outputUnit: unitType,
            roundingType: roundingType,
        });
    };

    const getRemaining = () => {
        const duration = unitType === UnitType.DAYS ? remainingAmountInDays : remainingAmountInMinutes;
        return t('duration.formatDuration', {
            duration: getDuration(duration, unitType, leaveType.roundingType),
            unit: unitType,
        });
    };

    const remainingDuration = unitType === UnitType.DAYS ? remainingAmountInDays : remainingAmountInMinutes;

    const getAvailable = () => {
        const duration = unitType === UnitType.DAYS ? availableAmountInDays : availableAmountInMinutes;
        return t('duration.formatDuration', {
            duration: getDuration(duration, unitType, leaveType.roundingType),
            unit: unitType,
        });
    };

    const showDaysAvailable = leaveType && leaveType.allowanceType !== AllowanceType.UNLIMITED;

    const displayUnitType = getLeaveTypeDisplayUnitType(leaveType, unitType);

    return (
        <Stack direction='column' spacing={2}>
            {leaveType.employeeRequestMessage && (
                <Paper>
                    <Typography variant='body2'>{leaveType.employeeRequestMessage}</Typography>
                </Paper>
            )}

            {showDaysAvailable && <DaysAvailableComponent available={getAvailable()} remaining={getRemaining()} remainingDuration={remainingDuration} />}

            {isMultipleDays ? <MultipleDays /> : <StartEndDates />}

            {!isEdit && (
                <FormControlLabel
                    label={t('request_leave_dialog.multiple_days')}
                    labelPlacement='end'
                    sx={{ mr: 0 }}
                    control={<FieldSwitch control={control} name='isMultipleDays' />}
                />
            )}

            {previewResult && (!!remainingAmountInMinutes || remainingAmountInMinutes === 0) ? (
                <Typography variant='body2'>
                    {isMedicalType(leaveType) &&
                    convertLeavesMinutesToUnit({
                        input: displayUnitType == UnitType.DAYS ? previewResult?.totalAmountInDays : previewResult?.totalAmountInMinutes,
                        outputUnit: displayUnitType,
                    }) === 0 ? (
                        <></>
                    ) : (
                        <>
                            {t('request_leave_dialog.hourly.you_will_use', {
                                duration: t('duration.formatDuration', {
                                    duration: convertLeavesMinutesToUnit({
                                        input: displayUnitType === UnitType.DAYS ? previewResult?.totalAmountInDays : previewResult?.totalAmountInMinutes,
                                        outputUnit: displayUnitType,
                                        roundingType: leaveType.roundingType,
                                    }),
                                    unit: displayUnitType,
                                }),
                            })}
                            {displayUnitType === UnitType.DAYS &&
                                previewResult?.totalDurationInDays &&
                                leaveType.showContinuousDuration &&
                                t('request_leave_dialog.hourly.calendar_days', { duration: previewResult?.totalDurationInDays })}
                        </>
                    )}
                </Typography>
            ) : undefined}

            {previewResult && !isMedicalType(leaveType) && !!overlappingRequests?.length && !showOverlapping && (
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Stack>
                        <Typography variant='body2'>{t('request_leave_dialog.team_members_also_on_leave')}</Typography>
                    </Stack>
                    <StackedAvatars
                        employeeAvatars={uniqueOverlappingUsers}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            displayOverlappingRequestDetails();
                        }}
                    />
                </Stack>
            )}

            {leaveType.leaveActivityType === LeaveActivityType.MEDICAL && (
                <Stack>
                    <Typography variant='body2'>{t('request_leave_dialog.medical_certificate_incapacity')}</Typography>
                    <FormControlLabel
                        label=''
                        style={{ width: '100%' }}
                        control={
                            <FieldNumber
                                name='leavePercentage'
                                precision={2}
                                control={control}
                                fullWidth
                                min={0}
                                max={100}
                                // We don't want to clamp the value on blur to avoid to trigger preview request on blur
                                clampValueOnBlur={false}
                            />
                        }
                    />
                </Stack>
            )}

            {children}

            <DailyLeaveTypeAlerts previewResult={previewResult} showOverlapping={showOverlapping} conflictingRequests={conflictingRequests} />
        </Stack>
    );
};

const MultipleDays: FC = () => {
    const { control } = useFormContext<LeaveRequestFormValues>();

    return (
        <Controller
            name='dates'
            control={control}
            render={({ field: { onChange, value, ...field } }) => <MultipleDatePicker selectedDays={value} onClose={onChange} {...field} />}
        />
    );
};

const StartEndDates: FC = () => {
    const { t } = useTranslation();

    const { control, getValues, setValue } = useFormContext<LeaveRequestFormValues>();
    const { leaveType, startDate, endDate } = getValues();

    const isEndDateCanBeEmpty = (): boolean => {
        return leaveType.leaveActivityType === LeaveActivityType.MEDICAL;
    };

    const endTimePeriodChoices = getEndPeriodChoices(toDate(startDate), toDate(endDate));
    const startTimePeriodChoices = getStartPeriodChoices(toDate(startDate), toDate(endDate));
    const getTranslationForDayPeriod = (periodChoices: DayPeriod[], dayPeriod: DayPeriod) => {
        return periodChoices && periodChoices.length == 2 && dayPeriod != DayPeriod.ALL_DAY
            ? t('domain.day_period.half_day')
            : t(getDayPeriodTranslationKey(dayPeriod));
    };

    return (
        <Stack direction='column' spacing={2}>
            <Stack direction='row' spacing={2} alignItems='flex-end'>
                <FormControlLabel
                    label={t('request_leave_dialog.from')}
                    style={{ width: '100%' }}
                    control={<FieldLocalDate name='startDate' control={control} mobileOnly />}
                />
                <Controller
                    name={'startTimePeriod'}
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                        <FormControlLabel
                            label={t('request_leave_dialog.time_period')}
                            labelPlacement='top'
                            style={{ width: '100%' }}
                            control={
                                <Select
                                    {...restField}
                                    fullWidth={true}
                                    onChange={event => {
                                        if (event.target.value) {
                                            onChange(event.target.value);
                                        }
                                    }}
                                >
                                    {startTimePeriodChoices.map(dayPeriod => {
                                        return (
                                            <MenuItem className='Mui-selected-dropdown' key={dayPeriod} value={dayPeriod}>
                                                {getTranslationForDayPeriod(startTimePeriodChoices, dayPeriod)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            }
                        />
                    )}
                />
            </Stack>
            <Stack direction='row' spacing={2} alignItems='flex-end'>
                <FormControlLabel
                    label={
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant={'body2'}>{t('request_leave_dialog.to')}</Typography>
                            {isMedicalType(leaveType) && (
                                <Button
                                    variant={'text'}
                                    color='primary'
                                    size={'small'}
                                    onClick={event => {
                                        event.stopPropagation();
                                        setValue('endDate', getNull());
                                    }}
                                >
                                    {t('request_leave_dialog.clear')}
                                </Button>
                            )}
                        </Stack>
                    }
                    slotProps={{
                        typography: {
                            sx: {
                                width: '100%',
                            },
                        },
                    }}
                    style={{ width: '100%' }}
                    control={
                        <FieldLocalDate
                            control={control}
                            name='endDate'
                            datePickerProps={{
                                slotProps: {
                                    textField: {
                                        placeholder: t('request_leave_dialog.to_be_confirmed'),
                                    },
                                },
                            }}
                            // when the leave type is medical, the end date is not required
                            minDate={startDate ?? getCurrentLocalDate()}
                            mobileOnly
                        />
                    }
                />
                <Controller
                    name={'endTimePeriod'}
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                        <FormControlLabel
                            label={t('request_leave_dialog.time_period')}
                            labelPlacement='top'
                            style={{ width: '100%' }}
                            control={
                                <Select
                                    {...restField}
                                    fullWidth={true}
                                    id='end-time-period-select'
                                    disabled={(endDate === undefined && isEndDateCanBeEmpty()) || (!!endDate && !!startDate && isSameDate(startDate, endDate))}
                                    onChange={event => {
                                        if (event.target.value) {
                                            onChange(event.target.value);
                                        }
                                    }}
                                >
                                    {endTimePeriodChoices.map(dayPeriod => {
                                        return (
                                            <MenuItem className='Mui-selected-dropdown' key={dayPeriod} value={dayPeriod}>
                                                {getTranslationForDayPeriod(endTimePeriodChoices, dayPeriod)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            }
                        />
                    )}
                />
            </Stack>
        </Stack>
    );
};

type DailyLeaveTypeAlertsProps = {
    previewResult?: LeaveRequestPreview;
    showOverlapping: boolean;
    conflictingRequests: LeaveRequest[];
};

const DailyLeaveTypeAlerts: FC<DailyLeaveTypeAlertsProps> = ({ previewResult, showOverlapping, conflictingRequests }) => {
    const { t } = useTranslation();

    const { getValues } = useFormContext<LeaveRequestFormValues>();
    const { leaveType, endDate, unitType } = getValues();
    const maxDurationInMinutes = leaveType?.maxDurationInMinutes;

    const isLeaveRequestOnWorkingDays = (previewResult: LeaveRequestPreview): boolean => {
        return previewResult?.totalAmountInMinutes === 0;
    };

    const shouldDisplayMaxDurationWarning = () =>
        !!maxDurationInMinutes &&
        !!previewResult?.previews?.some(preview =>
            isLeaveRequestExceedingMaxDuration(leaveType, unitType, preview.amountInMinutes, preview.amountInDays, preview.durationInMinutes),
        );

    return (
        <>
            {showOverlapping && (
                <Alert severity='warning' elevation={0}>
                    <Typography variant='body2'>{t('request_leave_dialog.overlapping_not_display')}</Typography>
                </Alert>
            )}
            {shouldDisplayMaxDurationWarning() && (
                <Alert severity='error' elevation={0}>
                    <Typography variant='body2'>
                        {t('request_leave_dialog.messages.you_can_not_request', {
                            days: convertMinutesToDays(maxDurationInMinutes ?? 0),
                        })}
                    </Typography>
                </Alert>
            )}

            {hasConflicts(conflictingRequests) && (
                <Alert severity='error' elevation={0}>
                    <Typography variant='body2'>
                        {t('request_leave_dialog.request_is_in_conflict')}: {getLabelTranslation(conflictingRequests[0]?.leaveType?.name)}{' '}
                        {getLeaveRequestPeriodAsString(conflictingRequests[0], false)}
                    </Typography>
                </Alert>
            )}

            {hasPartialConflicts(conflictingRequests) && (
                <Alert severity='warning' elevation={0}>
                    <Typography variant='body2'>
                        {t('request_leave_dialog.conflicting_medical_leave_requests', {
                            totalConflictingRequestsLeavePercentage: getTotalConflictingRequestsLeavePercentage(conflictingRequests),
                        })}
                    </Typography>
                </Alert>
            )}

            {previewResult && isLeaveRequestOnWorkingDays(previewResult) && endDate && (
                <Alert severity='warning' elevation={0}>
                    <Typography variant='body2'>{t('request_leave_dialog.messages.you_can_only_submit_request_on_working_days')}</Typography>
                </Alert>
            )}
        </>
    );
};
