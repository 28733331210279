import { employeeReviewActionApi } from '@/api/employee-review-action/EmployeeReviewAction.api';
import { CreateActionMutation, EmployeeReviewAction, UpdateActionMutation } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { Objective } from '@/domain/objective/Objective.model';

export const getEmployeeReviewActions = (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.getActions(employeeReviewId);
};

export const updateEmployeeReviewAction = (employeeReviewActionId: number, updatedAction: UpdateActionMutation): Promise<EmployeeReviewAction> => {
    return employeeReviewActionApi.updateAction(employeeReviewActionId, updatedAction);
};

export const deleteEmployeeReviewAction = (employeeReviewActionId: number): Promise<void> => {
    return employeeReviewActionApi.deleteAction(employeeReviewActionId);
};

export const archiveEmployeeReviewAction = (employeeReviewActionId: number): Promise<void> => {
    return employeeReviewActionApi.archiveAction(employeeReviewActionId);
};

export const unarchiveEmployeeReviewAction = (employeeReviewActionId: number): Promise<void> => {
    return employeeReviewActionApi.unarchiveAction(employeeReviewActionId);
};

export const createEmployeeReviewActions = (createAction: CreateActionMutation): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.createActions(createAction);
};

export const getEmployeeReviewActionSummary = (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.getActionSummary(employeeReviewId);
};

export const getEmployeeReviewSelfActionSummary = (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.getSelfActionSummary(employeeReviewId);
};

export const getEmployeeReviewManagerActionSummary = (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.getManagerActionSummary(employeeReviewId);
};

export const isObjectiveInActions = (objective: Objective, actions: EmployeeReviewAction[]): boolean => {
    return actions.some(action => action.objective.id === objective.id);
};
