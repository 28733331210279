import { createMutationHook, createQueryHook, defaultQueryOptions } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';
import { getExpenseSetting, updateExpenseSetting } from '@/domain/expense-setting/ExpenseSetting.service';
import { QueryClient } from '@tanstack/react-query';

export const expenseSettingKeys = createQueryKeys('expenseSetting', {
    get: {
        queryKey: getNull(),
    },
});

export const useGetExpenseSetting = createQueryHook(expenseSettingKeys.get, getExpenseSetting, defaultQueryOptions);

const invalidateSearch = (queryClient: QueryClient) => {
    return queryClient.invalidateQueries({ queryKey: expenseSettingKeys.get.queryKey });
};

export const useUpdateExpenseSetting = createMutationHook(updateExpenseSetting, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});
