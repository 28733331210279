import { Button, ButtonProps, Checkbox, Radio, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type SquareButtonProps = StrictOmit<ButtonProps, 'type'> & {
    title?: string;
    description: string | ReactNode;
    type?: 'checkbox' | 'radio';
    checked: boolean;
};

export const SquareButton: FC<SquareButtonProps> = ({ title = '', description, type = 'checkbox', checked, disabled, onClick, sx, ...rest }) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            {...rest}
            sx={{
                width: '300px',
                height: '120px',
                border: '1px solid',
                borderColor: theme => (checked ? theme.palette.primary.main : theme.palette.grey[300]),
                backgroundColor: theme => (checked ? theme.palette.primary.light : theme.palette.common.white),
                ':hover': {
                    backgroundColor: theme => (checked ? theme.palette.primary.light : theme.palette.common.white),
                },
                px: 0.5,
                pb: 1,
                ...sx,
            }}
        >
            <Stack height={'100%'} alignItems={'center'} width={'100%'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant={'h2'} sx={{ width: '100%' }} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} pl={4}>
                        {title}
                    </Typography>
                    {type === 'radio' ? (
                        <Radio name={title} disableRipple onChange={undefined} checked={checked} />
                    ) : (
                        <Checkbox name={title} disableRipple onChange={undefined} checked={checked} />
                    )}
                </Stack>

                <Stack justifyContent={'center'} flex={1} textOverflow={'ellipsis'} overflow={'auto'}>
                    {description &&
                        (typeof description === 'string' ? (
                            <Typography variant={'body1'} textAlign={'center'} whiteSpace={'wrap'} textOverflow={'ellipsis'} overflow={'auto'}>
                                {description}
                            </Typography>
                        ) : (
                            description
                        ))}
                </Stack>
            </Stack>
        </Button>
    );
};
