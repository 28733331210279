import { FilePickerItem, StoredFile, TemporaryFile } from '@/components/file-picker/FilePicker';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { useFilePicker } from '@/components/file-picker/useFilePicker';
import { SearchLeaveRequestAttachment } from '@/domain/leave-request-attachment/LeaveRequestAttachment.model';
import { canManageLeaveRequestAttachment } from '@/domain/permission/Permission.service';
import {
    createLeaveRequestAttachmentMutation,
    deleteLeaveRequestAttachmentMutation,
    useSearchLeaveRequestAttachments,
} from '@/hooks/leave-request-attachment/LeaveRequestAttachment.hook';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { useEffect } from 'react';

export const useLeaveRequestAttachments = (
    leaveRequestId: number | undefined,
    employeeId: number,
): {
    files: FilePickerItem[];
    handleFileRemoved: (file: FilePickerItem) => Promise<void>;
    handleFileUploaded: (files: TemporaryFile) => Promise<void>;
} => {
    const policies = useCurrentPolicies();
    const canManageAttachments = canManageLeaveRequestAttachment(policies, employeeId);
    const { files, setFiles, removeFile, addFiles } = useFilePicker();

    const allowSearchAttachments = canManageAttachments && !!leaveRequestId;

    const searchAttachmentsRequest = leaveRequestId
        ? ({
              leaveRequestIds: [leaveRequestId],
          } satisfies SearchLeaveRequestAttachment)
        : undefined;

    const { data: attachments = [], isFetching } = useSearchLeaveRequestAttachments(searchAttachmentsRequest, {
        options: {
            enabled: allowSearchAttachments,
        },
    });

    const { mutateAsync: createLeaveRequestAttachmentMutate } = createLeaveRequestAttachmentMutation();

    const { mutateAsync: deleteLeaveRequestAttachmentMutate } = deleteLeaveRequestAttachmentMutation();

    const handleFileRemoved = async (file: FilePickerItem) => {
        try {
            if (leaveRequestId && !isTemporaryFile(file)) {
                await deleteLeaveRequestAttachmentMutate(file.id);
            }
            removeFile(file);
        } catch (error) {
            handleError(error);
        }
    };

    const handleFileUploaded = async (file: TemporaryFile) => {
        if (!isTemporaryFile(file)) {
            return;
        }
        try {
            // In creation mode  we call the backend only after the leave request is created
            if (leaveRequestId) {
                await createLeaveRequestAttachmentMutate({
                    file: file,
                    leaveRequestId,
                    name: file.name ?? '',
                    mimeType: file.data.type,
                });
            } else {
                addFiles([file]);
            }
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        if (!isFetching) {
            if (!attachments.length) {
                return;
            }
            const files: StoredFile[] = attachments.map(attachment => {
                return {
                    id: attachment.id,
                    name: attachment.name,
                    mimeType: attachment.mimeType,
                    createdBy: attachment.createdBy,
                };
            });
            setFiles(files);
        }
    }, [attachments, isFetching, setFiles]);

    return {
        files,
        handleFileRemoved,
        handleFileUploaded,
    };
};
