import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Calendar } from '@/domain/calendar/Calendar.model';
import { EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import {
    EmployeeWorkingPatternCalendarField,
    EmployeeWorkingPatternField,
    EmployeeWorkingPatternRateField,
    EmployeeWorkingPatternStartingWeekField,
    EmployeeWorkingPatternTypeField,
    EmployeeWorkingPatternWeeklyWorkingTimeField,
    WeeklyWorkingTimeField,
} from '@/page/employee-profile/employee-profile-info/EmployeeWorkPatternSection/Components/EmployeeWorkingPatternDialog';
import { EmployeeWorkingPatternFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeWorkPatternSection/EmployeeWorkPattern.schema';
import { SectionContainer } from '@/page/people/onboarding/SectionContainer';
import { SectionFieldContainer } from '@/page/people/onboarding/SectionFieldContainer';
import { useGetTimesheetSettings } from '@/hooks/timesheet-setting/TimesheetSettings.hook';
import { Box, Stack, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const WorkingPatternSection: FC<{
    weeklyWorkingTimes: WeeklyWorkingTime[];
    calendars: Calendar[];
}> = ({ weeklyWorkingTimes, calendars }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext<EmployeeWorkingPatternFormValues>();

    const selectedEmployeeWorkingPatternType = watch('type');
    const selectedWeeklyWorkingTimeId: Nullable<number> = watch('weeklyWorkingTimeId');

    const isLeaveFeatureEnabled = hasRealmFeatureEnabled(RealmFeaturesType.LEAVES);

    const shouldDisplayCalendarSelect = calendars.length > 1 && isLeaveFeatureEnabled;

    const workingPatternTemplate = watch('workingPatternTemplate');
    const numberOfDays = workingPatternTemplate?.workingPatternTemplateWeekDays?.length ?? 0;
    const shouldDisplayWeekSelection = numberOfDays > 1;

    return (
        <SectionContainer title={t('employee.sections.work_pattern')}>
            {shouldDisplayCalendarSelect && (
                <SectionFieldContainer formValueName={'calendar'} title={t('employee.work_pattern.calendar')}>
                    <EmployeeWorkingPatternCalendarField calendars={calendars} />
                </SectionFieldContainer>
            )}

            <EmployeeTimesheetSetting />

            <SectionFieldContainer formValueName={'type'} title={''}>
                <EmployeeWorkingPatternTypeField />
            </SectionFieldContainer>

            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.TEMPLATE && (
                <Stack key={'workingPattern'}>
                    <Stack spacing={1} flex={1} justifyContent='space-between' alignItems={'center'} direction='row'>
                        <Typography variant='body1bold' noWrap flex={{ xs: 6, sm: 4, md: 2 }}>
                            {t('employee.work_pattern.dialog.template')}
                        </Typography>

                        <Box display={'flex'} flex={{ xs: 6, sm: 8, md: 10 }}>
                            <EmployeeWorkingPatternField />
                        </Box>
                    </Stack>
                    {shouldDisplayWeekSelection && (
                        <Stack spacing={1} flex={1} justifyContent='space-between' alignItems={'center'} direction='row'>
                            <Typography variant='body1bold' noWrap flex={{ xs: 6, sm: 4, md: 2 }}>
                                {t('employee.work_pattern.dialog.starting_week')}
                            </Typography>

                            <Box display={'flex'} flex={{ xs: 6, sm: 8, md: 10 }}>
                                <EmployeeWorkingPatternStartingWeekField />
                            </Box>
                        </Stack>
                    )}
                </Stack>
            )}

            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.FIXED && (
                <>
                    {weeklyWorkingTimes.length > 1 && (
                        <SectionFieldContainer formValueName={'weeklyWorkingTimeId'} title={t('employee.work_pattern.dialog.weekly_working_time')}>
                            <WeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} />
                        </SectionFieldContainer>
                    )}

                    {!!selectedWeeklyWorkingTimeId && (
                        <SectionFieldContainer formValueName={'workingDays'} title={t('employee.work_pattern.work_pattern_field')}>
                            <Stack direction='row' display={'flex'} gap={2} alignItems={'flex-end'}>
                                <EmployeeWorkingPatternWeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} />
                            </Stack>
                        </SectionFieldContainer>
                    )}
                </>
            )}
            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.RATE && (
                <>
                    {weeklyWorkingTimes.length > 1 && (
                        <SectionFieldContainer formValueName={'weeklyWorkingTimeId'} title={t('employee.work_pattern.dialog.weekly_working_time')}>
                            <WeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} />
                        </SectionFieldContainer>
                    )}
                    <SectionFieldContainer formValueName={'rate'} title={t('employee.work_pattern.dialog.rate')}>
                        <EmployeeWorkingPatternRateField />
                    </SectionFieldContainer>
                </>
            )}
        </SectionContainer>
    );
};

const EmployeeTimesheetSetting: FC = () => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext<{
        timesheetSettingId: number;
    }>();

    const { data: timesheetSettings = [], isLoading, isError, error } = useGetTimesheetSettings();

    const hasOnlyOneTimesheetSetting = timesheetSettings?.length === 1;
    const firstTimesheetSettingId = timesheetSettings.at(0)?.id;

    useEffect(() => {
        if (hasOnlyOneTimesheetSetting && firstTimesheetSettingId) {
            setValue('timesheetSettingId', firstTimesheetSettingId);
        }
    }, [setValue, hasOnlyOneTimesheetSetting, firstTimesheetSettingId]);

    if (timesheetSettings?.length === 1) {
        return undefined;
    }

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <Stack direction='row' spacing={1}>
                <SectionFieldContainer formValueName={'timesheetSettingId'} title={`${t('employee.timesheet_setting')}*`}>
                    <FieldSelect
                        name='timesheetSettingId'
                        control={control}
                        getOptionLabel={option => timesheetSettings.find(setting => setting.id === option)?.name ?? ''}
                        options={timesheetSettings.flatMap(setting => setting.id)}
                        fullWidth
                    />
                </SectionFieldContainer>
            </Stack>
        </StateHandler>
    );
};
