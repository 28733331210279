import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, FormControlLabel, Stack, Typography } from '@mui/material';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { ExpenseCategoryPresetFormValues, getPresetSchema } from '@/page/setting/expense/expense-category/ExpenseCategorySettingsForm.schema';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { useTranslatableLabelInput } from '@/components/translatable-label-input/useTranslatableLabelInput';
import { assignLocalId } from '@/utils/object.util';
import { ExpenseCategoryType } from '@/domain/expense-category/ExpenseCategory.model';

type ExpenseCategoryPresetDialogProps = {
    type: ExpenseCategoryType;
    preset: ExpenseCategoryPresetFormValues | undefined;
    onSave: (preset: ExpenseCategoryPresetFormValues) => void;
} & DialogProps;

export const ExpenseCategorySettingsPresetDialog: FC<ExpenseCategoryPresetDialogProps> = ({ open, type, preset, onClose, onSave }) => {
    const { t } = useTranslation();
    const { translationLanguage, onLanguageChange } = useTranslatableLabelInput();

    const { control, handleSubmit } = useForm<ExpenseCategoryPresetFormValues>({
        resolver: yupResolver(getPresetSchema(translationLanguage)),
        values: preset
            ? {
                  localId: preset.localId,
                  id: preset.id,
                  name: preset.name,
                  amount: preset.amount,
              }
            : undefined,
        defaultValues: assignLocalId({
            type: 'AMOUNT' as const,
            name: createDefaultLabel(),
        }),
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' component={DialogTitle}>
                <Typography variant={'h1'}>{t(preset ? 'expense_category_settings_page.edit_preset' : 'expense_category_settings_page.add_preset')}</Typography>
                <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={onLanguageChange} />
            </Stack>
            <Stack gap={1} component={DialogContent}>
                <FieldLabel
                    control={control}
                    name='name'
                    language={translationLanguage}
                    label={t('expense_category_settings_page.preset_name_field_title')}
                    fullWidth
                />

                <FormControlLabel
                    label={t(type === 'AMOUNT' ? 'expense_category_settings_page.amount_field_title' : 'expense_category_settings_page.distance_km')}
                    control={<FieldNumber control={control} name='amount' fullWidth precision={2} />}
                />
            </Stack>
            <DialogActions>
                <Button variant='contained' onClick={handleSubmit(onSave, console.error)} color='primary' fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
