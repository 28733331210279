import { skillApi } from '@/api/skill/Skill.api';
import { SearchSkill, Skill, SkillMutation } from '@/domain/skill/Skill.model';

import { OrderMutation } from '@/domain/common';

export const getSkill = (skillId: number): Promise<Skill> => {
    return skillApi.getSkill(skillId);
};

export const updateSkill = (skillId: number, request: SkillMutation): Promise<Skill> => {
    return skillApi.updateSkill(skillId, request);
};

export const deleteSkill = (skillId: number): Promise<void> => {
    return skillApi.deleteSkill(skillId);
};

export const createSkill = (request: SkillMutation): Promise<Skill> => {
    return skillApi.createSkill(request);
};

export const searchSkills = (request: SearchSkill = {}): Promise<Skill[]> => {
    return skillApi.searchSkills(request);
};

export const reorderSkillsRequest = (request: OrderMutation[]): Promise<Skill[]> => {
    return skillApi.reorderSkillsRequest(request);
};

type GroupedSkillsByCategoryId<T1, T2> = {
    groupedSkills: T1[];
    category: T2;
};
export const getGroupedSkillsByCategoryId = <T1 extends { category: T2 }, T2 extends { id: number }>(
    skills: T1[] | undefined,
): GroupedSkillsByCategoryId<T1, T1['category']>[] => {
    return (skills ?? []).reduce<GroupedSkillsByCategoryId<T1, T2>[]>((groupedSkills, skill) => {
        const { category } = skill;
        const categoryId = category.id;

        const existingGroup = groupedSkills.find(group => group.category.id === categoryId);
        if (existingGroup) {
            existingGroup.groupedSkills.push(skill);
        } else {
            groupedSkills.push({
                category,
                groupedSkills: [skill],
            });
        }

        return groupedSkills;
    }, []);
};
