import { WordCloud } from '@/components/word-cloud/WordCloud';
import { Label } from '@/domain/label/Label.model';
import { AnswerResult, SurveyResultFilterType } from '@/domain/survey/Survey.model';
import { renderGroupByWithFilters } from '@/domain/survey/Survey.service';
import { getLabelTranslation } from '@/utils/language.util';
import { Collapse, Paper, Stack, Typography } from '@mui/material';
import { Cancel01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextAnswers } from './TextAnswers';
import { ClampedTypography } from '@/components/typography/ClampedTypography';

type Props = {
    title: Label;
    answer: AnswerResult;
    groupBy: SurveyResultFilterType;
    activeGroupByFilters: string[];
    displayMessage?: boolean;
};

export const TextResults: FC<Props> = ({ title, activeGroupByFilters, groupBy, answer, displayMessage = true }) => {
    const { t } = useTranslation();
    const [selectedWord, setSelectedWord] = useState<{ value: string; count: number }>();

    const removeSelectedWord = () => {
        setSelectedWord(undefined);
    };

    if (!answer?.details || !answer?.completions) {
        return <></>;
    }

    return (
        <Stack component={Paper} p={3}>
            <Stack justifyContent={'space-between'} flex={1} gap={3}>
                <Stack direction={'row'} gap={1} flex={1} justifyContent={'space-between'} alignItems={'center'}>
                    <ClampedTypography flex={1} variant='body1bold'>
                        {getLabelTranslation(title)}
                    </ClampedTypography>
                    <Typography variant='body1'>
                        {answer.completions?.ALL[0].answered} {t('survey_results.answered')} - {answer.completions?.ALL[0].skipped}{' '}
                        {t('survey_results.skipped')}
                    </Typography>
                </Stack>

                <Collapse in={displayMessage}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} flex={1} gap={3}>
                        <Stack flex={1}>
                            <WordCloud
                                data={answer.details.ALL.flatMap(a => a.wordCounts).filter((w, index, array) => array.indexOf(w) === index)}
                                onWordSelected={setSelectedWord}
                                selectedWord={selectedWord}
                            />
                        </Stack>
                        <Stack flex={2} gap={1}>
                            <Typography variant='body2bold'>
                                {selectedWord && (
                                    <>
                                        <span
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {selectedWord.value}
                                        </span>{' '}
                                        - {selectedWord.count} {t('survey_results.times')}
                                        <Cancel01Icon
                                            style={{
                                                color: 'red',
                                                verticalAlign: 'middle',
                                                marginLeft: 1.5,
                                                width: 15,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => removeSelectedWord()}
                                        />
                                    </>
                                )}
                            </Typography>
                            {answer.details[groupBy] &&
                                renderGroupByWithFilters(activeGroupByFilters, answer.details[groupBy]).map(details => (
                                    <TextAnswers key={details.key} selectedWord={selectedWord} details={details} />
                                ))}
                        </Stack>
                    </Stack>
                </Collapse>
            </Stack>
        </Stack>
    );
};
