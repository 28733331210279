import {
    Objective,
    ObjectiveCloseMutation,
    ObjectiveCreateMutation,
    ObjectiveSearch,
    ObjectiveStatus,
    ObjectiveStatusCreateMutation,
    ObjectiveStatusUpdate,
    ObjectiveUpdateMutation,
} from '@/domain/objective/Objective.model';
import { objectiveApi } from '@/api/objective/Objective.api';
import { getCurrentLocalDate, isAfterDate } from '@/utils/datetime.util';
import { Employee } from '@/domain/employee/Employee.model';
import { Department } from '@/domain/department/Department.model';
import { getDepartmentsObjectivesAvailable } from '@/domain/department/Department.service';

export const getObjective = (objectiveId: number): Promise<Objective> => {
    return objectiveApi.getObjective(objectiveId);
};

export const updateObjective = (objectiveId: number, mutation: ObjectiveUpdateMutation): Promise<Objective> => {
    return objectiveApi.updateObjective(objectiveId, mutation);
};

export const deleteObjective = (objectiveId: number): Promise<void> => {
    return objectiveApi.deleteObjective(objectiveId);
};

export const closeObjective = (objectiveId: number, request: ObjectiveCloseMutation): Promise<Objective> => {
    return objectiveApi.closeObjective(objectiveId, request);
};

export const reopenObjective = (objectiveId: number): Promise<Objective> => {
    return objectiveApi.reopenObjective(objectiveId);
};

export const archiveObjective = (objectiveId: number): Promise<Objective> => {
    return objectiveApi.archiveObjective(objectiveId);
};

export const unarchiveObjective = (objectiveId: number): Promise<Objective> => {
    return objectiveApi.unarchiveObjective(objectiveId);
};

export const createObjective = (mutation: ObjectiveCreateMutation): Promise<Objective[]> => {
    return objectiveApi.createObjective(mutation);
};

export const searchObjectives = (request: ObjectiveSearch): Promise<Objective[]> => {
    return objectiveApi.searchObjectives(request);
};

export const createObjectiveStatus = (objectiveId: number, request: ObjectiveStatusCreateMutation): Promise<ObjectiveStatusUpdate> => {
    return objectiveApi.createObjectiveStatus(objectiveId, request);
};

export const updateObjectiveStatus = (
    objectiveId: number,
    objectiveStatusId: number,
    request: ObjectiveStatusCreateMutation,
): Promise<ObjectiveStatusUpdate> => {
    return objectiveApi.updateObjectiveStatus(objectiveId, objectiveStatusId, request);
};

export const deleteObjectiveStatus = (objectiveId: number, objectiveStatusId: number): Promise<void> => {
    return objectiveApi.deleteObjectiveStatus(objectiveId, objectiveStatusId);
};

export const getDueLabelKey = (dueDate: LocalDate): string => {
    if (isAfterDate(getCurrentLocalDate(), dueDate)) {
        return 'objectives.overdue';
    } else {
        return 'objectives.due';
    }
};
export const getObjectiveStatusTranslationKey = (objectiveStatus: ObjectiveStatus): string => {
    switch (objectiveStatus) {
        case ObjectiveStatus.DELAYED:
            return 'objectives.status.off_track';
        case ObjectiveStatus.AT_RISK:
            return 'objectives.status.at_risk';
        case ObjectiveStatus.ON_TRACK:
            return 'objectives.status.on_track';
        default:
            return '';
    }
};

export const getParentObjectiveOptions = (
    assigneeIds: number[],
    employeesOptions: Employee[],
    objectives: Objective[],
    allDepartments: Department[],
    activeObjective: Objective | undefined,
): Objective[] => {
    if (assigneeIds.length === 0) {
        return objectives;
    }
    const assignees = assigneeIds.map(assigneeId => employeesOptions.find(employee => employee.id === assigneeId)).filter(item => !!item);

    const assigneeDepartments = assignees.map(assignee => assignee?.currentEmployments.map(employment => employment.department).flat());
    const departmentsObjectivesAvailableByEmployee = assigneeDepartments.map(departments =>
        departments.map(department => getDepartmentsObjectivesAvailable(department, objectives, allDepartments)).flat(),
    );
    const commonDepartmentObjectives = getCommonDepartmentObjectives(departmentsObjectivesAvailableByEmployee);
    const uniqueDepartmentObjectives = commonDepartmentObjectives.reduce<Objective[]>((acc, current) => {
        if (!acc.find(obj => obj.id === current.id)) {
            acc.push(current);
        }
        return acc;
    }, []);
    return uniqueDepartmentObjectives.filter(objective => objective.id !== activeObjective?.id);
};

export const getCommonDepartmentObjectives = (objectives: Objective[][]): Objective[] => {
    return objectives.reduce((common, current) => common.filter(objective => current.includes(objective)), objectives[0] ?? []);
};

export const getParentsObjective = (objective: Objective): Objective[] => {
    const collectParents = (currentObjective: Objective | undefined, parents: Objective[] = []): Objective[] => {
        if (!currentObjective?.parent) {
            return parents;
        }
        return collectParents(currentObjective.parent, [currentObjective.parent, ...parents]);
    };

    return collectParents(objective);
};
