import { getReview, searchReviews } from '@/domain/review/Review.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { getEmployeeReviewFeedbacksResultsItems } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const reviewKeys = createQueryKeys('reviews', {
    search: (...optionalParam: Parameters<typeof searchReviews>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    getById: (...params: Parameters<typeof getReview>) => ({
        queryKey: params,
    }),
    feedbacksItems: (...params: Parameters<typeof getEmployeeReviewFeedbacksResultsItems>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeReviewFeedbacksItems = createQueryHook(reviewKeys.feedbacksItems, getEmployeeReviewFeedbacksResultsItems);

export const useGetReview = createRequiredParamsQueryHook(reviewKeys.getById, getReview);

export const useGetReviews = createQueryHook(reviewKeys.search, searchReviews);
