import { getJobById, getJobs } from '@/domain/job/Job.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const jobKeys = createQueryKeys('jobs', {
    search: (...optionalParam: Parameters<typeof getJobs>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    getById: (...params: Parameters<typeof getJobById>) => ({
        queryKey: params,
    }),
});

export const useGetJobs = createQueryHook(jobKeys.search, getJobs);

export const useGetJob = createRequiredParamsQueryHook(jobKeys.getById, getJobById);
