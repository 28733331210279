import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { getEmployeeReviewFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeReviewFeedbackKeys = createQueryKeys('employeeReviewFeedback', {
    get: (...params: Parameters<typeof getEmployeeReviewFeedback>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeReviewFeedback = createRequiredParamsQueryHook(employeeReviewFeedbackKeys.get, getEmployeeReviewFeedback);
