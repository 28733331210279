import { ClampedTypography } from '@/components/typography/ClampedTypography';
import { calculatePercentage } from '@/utils/math.util';
import { localeCompareString } from '@/utils/strings.util';
import { Box, Paper, Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { ValueIteratee } from 'lodash';
import groupBy from 'lodash.groupby';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type MatrixProps<T extends Record<string, unknown>> = {
    rows: T[];
    groupBy: {
        xAxis: ValueIteratee<T>;
        yAxis?: ValueIteratee<T>;
    };
    children: ({ items, mode }: { items: T[]; mode: 'STACK' | 'MATRIX' }) => ReactNode;
    getLabel?: (item: string) => string;
    isVisible?: (item: T) => boolean;
    xAxis: string;
    yAxis?: string;
};

type GroupByResult<T> = Record<string, T[]>;

type MatrixResult<T> = Record<string, GroupByResult<T>>;

export const Matrix = <T extends Record<string, unknown>>({
    rows,
    groupBy,
    children,
    getLabel = item => item,
    isVisible = () => true,
    xAxis,
    yAxis = '',
}: MatrixProps<T>): JSX.Element => {
    const { t } = useTranslation();

    const alphabeticOrder = (a: string, b: string) => localeCompareString(a, b);
    const alphabeticReverseOrder = (valueA: string, valueB: string) => localeCompareString(valueB, valueA);

    if (!!groupBy.xAxis && !groupBy.yAxis) {
        const stackedGroup = buildColumns(rows, groupBy.xAxis);
        return (
            <Stack component={Paper} flex={1} justifyContent='center' margin='0 auto' p={1}>
                <Stack direction='row'>
                    {[...Object.entries(stackedGroup)]
                        .sort((a, b) => localeCompareString(a[0], b[0]))
                        .map(([key, items]) => {
                            return (
                                <Stack key={key} gap={1} justifyContent='flex-end' alignItems='center' width='140px'>
                                    {children({
                                        mode: 'STACK',
                                        items,
                                    })}
                                    <Tooltip title={getLabel(key) || t('matrix.unknown')}>
                                        <ClampedTypography variant='body1bold' width='140px' textAlign='center' ellipsis={2} minHeight={40}>
                                            {getLabel(key) || t('matrix.unknown')}
                                        </ClampedTypography>
                                    </Tooltip>
                                    <Percentage groupCount={items.filter(isVisible).length} totalCount={rows.filter(isVisible).length} />
                                </Stack>
                            );
                        })}
                </Stack>
            </Stack>
        );
    }

    if (!!groupBy.xAxis && !!groupBy.yAxis) {
        const reportMatrix = buildMatrix(rows, groupBy.xAxis, groupBy.yAxis);
        // Warning : if we have to change the order of the Y axis, we have also to change the order in the tsx
        const arrayLabelY = [...Object.keys(Object.values(reportMatrix)[0])].sort(alphabeticReverseOrder);

        const innerGridTemplate = `3 / ${Object.keys(reportMatrix).length + 3}`;

        return (
            <Stack component={Paper} flex={1} justifyContent='center' p={1}>
                {/*  Use css display grid to display the matrix */}
                <Box
                    display='grid'
                    p={2}
                    // Number of columns is the number of keys in the reportMatrix + 1 for the Y axis label
                    gridTemplateColumns={`400px 160px repeat(${Object.keys(reportMatrix).length}, 148px)`}
                    gridTemplateRows={`repeat(${arrayLabelY.length}, 1fr) 40px 40px`}
                >
                    {/* First column for Y axis label */}
                    <Box
                        display={'flex'}
                        gridColumn={1}
                        gridRow={`1 / ${arrayLabelY.length + 1}`}
                        justifyContent={'center'}
                        overflow={'hidden'}
                        alignItems={'center'}
                    >
                        <Stack p={1} mr={1} borderRadius={1} bgcolor='grey.100' display={'flex'} width={'100%'}>
                            <Tooltip title={yAxis}>
                                <ClampedTypography variant='body1bold' align='center' ellipsis={2}>
                                    {yAxis}
                                </ClampedTypography>
                            </Tooltip>
                        </Stack>
                    </Box>

                    {/* Second column for Y axis items label */}
                    {arrayLabelY.map((labelY, index) => (
                        <Stack key={labelY} justifyContent='center' alignItems='flex-end' gridColumn={2} gridRow={index + 1} p={1}>
                            <Tooltip title={getLabel(labelY) || t('matrix.unknown')}>
                                <ClampedTypography variant='body1bold' maxWidth={'132px'} ellipsis={2}>
                                    {getLabel(labelY) || t('matrix.unknown')}
                                </ClampedTypography>
                            </Tooltip>
                        </Stack>
                    ))}

                    {/* Nested grid for the matrix, with border radius */}
                    <Box
                        // Start at column 3 (after y axis label and y axis items label) and span all columns
                        gridColumn={innerGridTemplate}
                        gridRow={`1 / ${arrayLabelY.length + 1}`}
                        display='grid'
                        gridTemplateColumns={`repeat(${Object.keys(reportMatrix).length}, 148px)`}
                        border={1}
                        borderColor='grey.300'
                        borderRadius={1}
                    >
                        {Object.entries(reportMatrix)
                            .sort(([keyA], [keyB]) => alphabeticOrder(keyA, keyB))
                            .map(([_, stackY], colIndex) => {
                                return [...Object.entries(stackY)]
                                    .sort((a, b) => alphabeticReverseOrder(a[0], b[0]))
                                    .map(([keyY, items], rowIndex) => {
                                        return (
                                            <Stack
                                                key={keyY}
                                                p={2}
                                                gap={1}
                                                alignItems='flex-start'
                                                gridRow={rowIndex + 1}
                                                gridColumn={colIndex + 1}
                                                borderTop={rowIndex === 0 ? 0 : 1}
                                                borderLeft={colIndex === 0 ? 0 : 1}
                                                borderColor='grey.300'
                                            >
                                                <Percentage groupCount={items.filter(isVisible).length} totalCount={rows.filter(isVisible).length} />
                                                {children({
                                                    mode: 'MATRIX',
                                                    items,
                                                })}
                                            </Stack>
                                        );
                                    });
                            })}
                    </Box>

                    {/* X axis items label */}
                    {Object.keys(reportMatrix)
                        .sort(alphabeticOrder)
                        .map((keyX, index) => {
                            return (
                                <Stack key={keyX} justifyContent='flex-start' alignItems='center' p={1} gridRow={arrayLabelY.length + 1} gridColumn={index + 3}>
                                    <Tooltip title={getLabel(keyX) || t('matrix.unknown')}>
                                        <ClampedTypography variant='body1bold' width='132px' align='center' ellipsis={2}>
                                            {getLabel(keyX) || t('matrix.unknown')}
                                        </ClampedTypography>
                                    </Tooltip>
                                </Stack>
                            );
                        })}

                    {/* X axis label */}
                    <Stack
                        // Start after the matrix and after X axis label row
                        gridRow={arrayLabelY.length + 2}
                        // Start at column 2 and span all columns
                        gridColumn={innerGridTemplate}
                        bgcolor='grey.100'
                        p={1}
                        borderRadius={1}
                    >
                        <Tooltip title={xAxis}>
                            <ClampedTypography variant='body1bold' align='center' ellipsis={2}>
                                {xAxis}
                            </ClampedTypography>
                        </Tooltip>
                    </Stack>
                </Box>
            </Stack>
        );
    }

    return <></>;
};

const buildColumns = <T,>(rows: T[], iteratee: ValueIteratee<T>, keys?: string[]): GroupByResult<T> => {
    const result = groupBy(rows, iteratee) as GroupByResult<T>;

    const defaultState: Record<string, T[]> = keys?.reduce((acc, key) => ({ ...acc, [key]: [] }), {}) ?? {};
    return { ...defaultState, ...result };
};

const buildMatrix = <T,>(rows: T[], xAxis: ValueIteratee<T>, yAxis: ValueIteratee<T>): MatrixResult<T> => {
    const stackX = buildColumns(rows, xAxis);

    const allYKeys = Object.keys(buildColumns(rows, yAxis));

    return Object.entries(stackX).reduce<MatrixResult<T>>((acc, [xKey, stackXValues]) => {
        return {
            ...acc,
            [xKey]: buildColumns(stackXValues, yAxis, allYKeys),
        };
    }, {});
};

const Percentage: FC<{ groupCount: number; totalCount: number } & TypographyProps> = ({ groupCount, totalCount }) => {
    const percentage = calculatePercentage(groupCount, totalCount);
    return (
        <Typography variant='body2' color='text.secondary'>
            {groupCount} ({percentage}%)
        </Typography>
    );
};
