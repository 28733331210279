import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { Department } from '@/domain/department/Department.model';
import { ContractType, Employment } from '@/domain/employment/Employment.model';
import { calculateProbationEndDate } from '@/domain/employment/Employment.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { CostCenterAssignmentListField } from '@/page/cost-center/CostCenterAssignmentListField';
import {
    ContractFormValues,
    ContractSchemaConfig,
    getNewContractSchema,
    NewContractFormValues,
} from '@/page/employee-profile/employee-profile-info/EmployeeContractSection/contract.schema';
import { addDaysAndFormat, getCurrentLocalDate } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DepartmentAutocomplete,
    JobsAutocomplete,
    LocationAutocomplete,
    ManagerAutocomplete,
} from '@/domain-ui/employment/employment-dialog/EmploymentDialogCommonFields';
import { getNull } from '@/utils/object.util';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';

type NewContractDialogProps = {
    employeeId: number;
    onSave: (contractFormValues: NewContractFormValues) => void;
    existingContracts: Employment[];
} & DialogWrapperProps;

export const NewContractDialog: FC<NewContractDialogProps> = ({ employeeId, existingContracts, onSave, ...restDialog }) => {
    const { t } = useTranslation();
    const title = t('employee.employment.new_contract');

    // Existing contracts are sorted by start date in ascending order
    const lastContract = [...existingContracts].pop();

    // The minimum start date is the day after the end date of the last contract
    const minStartDate = lastContract?.endDate ? addDaysAndFormat(lastContract.endDate, 1) : undefined;
    const schemaConfig: ContractSchemaConfig = {
        range: [minStartDate, undefined],
    };

    const schema = getNewContractSchema(schemaConfig);
    const formMethods = useForm<NewContractFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            startDate: getCurrentLocalDate(),
            managers: [],
            employmentCostCenters: [],
        },
    });

    const { handleSubmit, setValue } = formMethods;

    const isCostCentersFeatureEnabled = hasRealmFeatureEnabled(RealmFeaturesType.COST_CENTERS);

    // erase cost centers and manager fields when department changes
    const handleDepartmentChange = (department: Department | null) => {
        const { setValue } = formMethods;
        setValue('managers', department?.managers ?? []);

        if (isCostCentersFeatureEnabled) {
            setValue('employmentCostCenters', department?.departmentCostCenters ?? []);
        }
    };

    const handleStartDateChange = (startDate: LocalDate | null) => {
        if (!startDate) {
            return;
        }
        setValue('probationEndDate', calculateProbationEndDate(startDate));
    };

    return (
        <DialogWrapper header={title} {...restDialog}>
            <FormProvider {...formMethods}>
                <Stack component={DialogContent} gap={2}>
                    <FormControlLabel
                        label={t('employee.employment.start_date')}
                        control={<FieldLocalDate name='startDate' control={formMethods.control} minDate={minStartDate} onChange={handleStartDateChange} />}
                    />

                    <FormControlLabel
                        label={t('employee.employment.probation_end_date')}
                        control={<FieldLocalDate control={formMethods.control} name={'probationEndDate'} />}
                    />

                    {/* theses 2 fields are specifics of EmploymentDialog */}
                    <ContractTypeAutocomplete />
                    <EmploymentEndDateField />

                    <LocationAutocomplete name='location' />
                    <DepartmentAutocomplete name='department' onDepartmentChange={handleDepartmentChange} />
                    <JobsAutocomplete name='job' />
                    <ManagerAutocomplete name='managers' excludeEmployeeIds={[employeeId]} />
                    <CostCenterAssignmentListField fieldName={'employmentCostCenters'} />
                </Stack>
                <DialogActions>
                    <Button onClick={handleSubmit(onSave, console.error)} fullWidth>
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </FormProvider>
        </DialogWrapper>
    );
};

const EmploymentEndDateField: FC = () => {
    const { control, watch } = useFormContext<ContractFormValues>();
    const contractType = watch('contractType');
    const { t } = useTranslation();

    if (!contractType || contractType === ContractType.PERMANENT) {
        return <></>;
    }
    return (
        <Stack direction='column'>
            <Typography variant='body1'>{t('employee.employment.end_date')}</Typography>

            <FieldLocalDate control={control} name={'endDate'} />
        </Stack>
    );
};

const ContractTypeAutocomplete: FC = () => {
    const { control, setValue } = useFormContext<ContractFormValues>();
    const { t } = useTranslation();
    return (
        <Stack>
            <Typography variant='body1'>
                {t('employee.employment.contract_type_title')}
                {'*'}
            </Typography>
            <FieldSelect
                name='contractType'
                control={control}
                options={Object.values(ContractType) ?? []}
                getOptionLabel={contractType => t('employee.employment.contract_type', { context: contractType })}
                onChange={newContractType => {
                    if (newContractType && newContractType !== ContractType.PERMANENT) {
                        setValue('endDate', getNull());
                    }
                }}
                fullWidth
            />
        </Stack>
    );
};
