import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeeAvatarWithDetails } from '@/domain-ui/employee/employee-avatar/EmployeeAvatarWithDetails';
import { DayPeriod, UnitType } from '@/domain/date/Date.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { createLeaveRequestAttachment, getLeaveRequestAttachmentUrl } from '@/domain/leave-request-attachment/LeaveRequestAttachment.service';
import { LeaveCreationMutation, LeaveRequest, LeaveRequestPreview, LeaveRequestStatus, LeaveUpdateMutation } from '@/domain/leave-request/LeaveRequest.model';
import {
    approvePendingLeaveRequest,
    convertLeavesMinutesToUnit,
    createLeaveRequest,
    editLeaveRequest,
    editPendingLeaveRequest,
    getEndPeriodChoices,
    getLeaveRequestPeriodAsString,
    getStartPeriodChoices,
    isLeaveRequestExceedingMaxDuration,
    isOverlappingExceedsLimitInDays,
    previewLeaveRequest,
    searchConflictingRequests,
    searchOverlappingRequests,
    setDayPeriodTime,
    setStartTimeAndEndTime,
    shiftAPISearchRequest,
    shouldFetchEmployeeShifts,
} from '@/domain/leave-request/LeaveRequest.service';
import { AllowanceType, EmployeeLeaveTypePolicy, LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { removeNullsFromLeaveType } from '@/domain/leave-type/LeaveType.service';
import { canApproveRejectLeaveRequests } from '@/domain/permission/Permission.service';
import { Shift, ShiftReleaseRequest, ShiftSearchRequest, ShiftStatus } from '@/domain/shift/Shift.model';
import { ConfirmNegativeBalanceDialog } from '@/page/leave/confirm-negative-balance-dialog/ConfirmNegativeBalanceDialog';
import { CommonLeaveTypeDetails } from '@/page/leave/leave-request-dialog/CommonLeaveTypeDetails';
import { DailyLeaveTypeDetails } from '@/page/leave/leave-request-dialog/DailyLeaveTypeDetails';
import { getLeaveRemainingTimePercentage, hasConflicts } from '@/page/leave/leave-request-dialog/LeaveRequestDialog.util';
import { SelectLeaveType } from '@/page/leave/leave-request-dialog/LeaveRequestDialogLeaveTypeStep';
import { leaveRequestFormSchema, LeaveRequestFormValues } from '@/page/leave/leave-request-dialog/LeaveRequestForm.schema';
import { useLeaveRequestAttachments } from '@/page/leave/leave-request-dialog/useLeaveRequestAttachments';
import { LeavesConflictsDialog } from '@/page/leave/leaves-conflicts-dialog/LeavesConflictsDialog';
import { useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import {
    convertMinutesToDays,
    formatDurationInTime,
    formatToLocalDate,
    getCurrentLocalDate,
    getDurationFromTime,
    getTimeFormatFromDate,
    getTodayDate,
    isBeforeDate,
    isFutureDate,
    isSameDate,
    setTime,
    toDate,
} from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    DialogProps,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HourlyLeaveTypeDetails } from './HourlyLeaveTypeDetails';

import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { FieldCheckbox } from '@/components/form/field-checkbox/FieldCheckbox';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { getEmployeeShifts, shiftRelease } from '@/domain/shift/Shift.service';
import { useGetEmployeeLeaveTypes } from '@/hooks/employee-leave-type/EmployeeLeaveType.hook';
import { useGetLeaveRequest } from '@/hooks/leave-request/LeaveRequest.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { ArrowLeft01Icon } from 'hugeicons-react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export type LeaveRequestDialogProps = Omit<DialogProps, 'onClose'> & {
    leaveRequestId?: number;
    open: boolean;
    employeeId: number;
    onSave: () => void;
    onClose: () => void;
    defaultLeaveRequest?: Partial<LeaveRequestFormValues>;
};

export const LeaveRequestDialog: FC<LeaveRequestDialogProps> = props => {
    const { leaveRequestId, employeeId, ...restProps } = props;

    const currentEmployeeId = useCurrentEmployee().id;
    const policies = useCurrentPolicies();

    const { data: leaveRequest, isLoading: isLoadingLeaveRequest } = useGetLeaveRequest(leaveRequestId);
    const { data: employeeLeaveTypes = [], isFetching: isFetchingEmployeeLeaveTypes } = useGetEmployeeLeaveTypes(employeeId);

    const currentEmployeeLeaveTypes = employeeLeaveTypes.filter(
        empLeaveType => !empLeaveType.endDate || isFutureDate(empLeaveType.endDate) || isSameDate(empLeaveType.endDate, getCurrentLocalDate()),
    );

    if (isLoadingLeaveRequest || isFetchingEmployeeLeaveTypes) {
        return;
    }

    return (
        <LeaveRequestDialogWrapper
            employeeId={employeeId}
            leaveRequest={leaveRequest}
            policies={policies}
            employeeLeaveTypes={currentEmployeeLeaveTypes}
            currentEmployeeId={currentEmployeeId}
            {...restProps}
        />
    );
};

const LeaveRequestDialogWrapper: FC<
    Omit<LeaveRequestDialogProps, 'leaveRequestId'> & {
        leaveRequest: LeaveRequest | undefined;
        policies: EmployeePolicy[];
        employeeLeaveTypes: EmployeeLeaveTypePolicy[];
        currentEmployeeId: number;
    }
> = ({
    open,
    employeeId,
    onClose,
    onSave,
    leaveRequest,
    employeeLeaveTypes,
    policies,
    currentEmployeeId,
    defaultLeaveRequest: defaultLeaveRequestFormValues,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    const canApproveLeaveRequests = canApproveRejectLeaveRequests(policies, employeeId);

    //this values will be used to compare some fields to not send request to the BE when there is no need for it
    const [leaveTypeSelected, setLeaveTypeSelected] = useState<LeaveType | undefined>(leaveRequest?.leaveType ?? undefined);
    //this value is used to display the overlapping request details and hide the form
    const [displayOverlappingRequestDetails, setDisplayOverlappingRequestDetails] = useState<boolean>(false);

    const makeItDisplayOverlappingRequestDetails = () => {
        setDisplayOverlappingRequestDetails(true);
    };

    const leaveTypesWithComputedData = employeeLeaveTypes
        // Display only leave types that are not available in self service
        // when the connected user is not the selected employee and doesn't have the access to approve
        .filter(empLeaveType => empLeaveType.leaveType?.availableInSelfService || employeeId !== currentEmployeeId || canApproveLeaveRequests);

    const isEdit = !!leaveRequest;

    const dialogHeader = () => {
        return (
            <Stack direction='row' justifyContent='space-between'>
                {displayOverlappingRequestDetails && (
                    <Button
                        variant='text'
                        startIcon={<ArrowLeft01Icon />}
                        onClick={() => {
                            setDisplayOverlappingRequestDetails(false);
                        }}
                    >
                        {t('general.back')}
                    </Button>
                )}
                {!displayOverlappingRequestDetails && (
                    <>
                        {!isEdit && <Typography variant='h2'>{t('request_leave_dialog.title')}</Typography>}
                        {isEdit && <Typography variant='h2'>{t('request_leave_dialog.title_edit')}</Typography>}
                    </>
                )}
            </Stack>
        );
    };

    return (
        <DialogWrapper open={open} fullScreen={fullScreen} onClose={onClose} header={dialogHeader()}>
            {!leaveTypeSelected && (
                <DialogContent sx={{ marginBottom: '25px' }}>
                    <SelectLeaveType
                        employeeLeaveTypePolicies={leaveTypesWithComputedData}
                        onLeaveTypeSelected={selectedLeaveType => {
                            setLeaveTypeSelected(removeNullsFromLeaveType(selectedLeaveType));
                        }}
                    />
                </DialogContent>
            )}

            {leaveTypeSelected && (
                <LeaveRequestDialogForm
                    defaultLeaveRequest={leaveRequest}
                    employeeLeaveTypePolicies={leaveTypesWithComputedData}
                    employeeId={employeeId}
                    defaultLeaveType={leaveTypeSelected}
                    onSave={onSave}
                    defaultLeaveRequestFormValues={defaultLeaveRequestFormValues}
                    canApproveLeaveRequests={canApproveLeaveRequests}
                    makeItDisplayOverlappingRequestDetails={makeItDisplayOverlappingRequestDetails}
                    displayOverlappingRequestDetails={displayOverlappingRequestDetails}
                    policies={policies}
                />
            )}
        </DialogWrapper>
    );
};

type LeaveRequestDialogFormProps = {
    defaultLeaveRequest: LeaveRequest | undefined;
    employeeLeaveTypePolicies: EmployeeLeaveTypePolicy[];
    employeeId: number;
    defaultLeaveType: LeaveType;
    onSave: () => void;
    defaultLeaveRequestFormValues?: Partial<LeaveRequestFormValues>;
    canApproveLeaveRequests: boolean;
    makeItDisplayOverlappingRequestDetails: () => void;
    displayOverlappingRequestDetails: boolean;
    policies: EmployeePolicy[];
};

type LeaveRequestAmounts = {
    remainingAmountInDays?: number;
    availableAmountInDays?: number;
    remainingAmountInMinutes?: number;
    availableAmountInMinutes?: number;
};

const LeaveRequestDialogForm: FC<LeaveRequestDialogFormProps> = ({
    defaultLeaveRequest,
    defaultLeaveType,
    employeeLeaveTypePolicies,
    employeeId,
    onSave,
    defaultLeaveRequestFormValues,
    canApproveLeaveRequests,
    makeItDisplayOverlappingRequestDetails,
    displayOverlappingRequestDetails,
    policies,
}) => {
    const { t } = useTranslation();

    // Preview result is used to check if the form is valid
    const [previewResult, setPreviewResult] = useState<LeaveRequestPreview>();
    const [isFetchingInformation, setIsFetchingInformation] = useState<boolean>(false);

    const [isSubmittingMutation, setIsSubmittingMutation] = useState<boolean>(false);

    const [leaveRequestAmounts, setLeaveRequestAmounts] = useState<LeaveRequestAmounts>();

    const [overlappingRequests, setOverlappingRequests] = useState<LeaveRequest[]>([]);

    const [shiftReleaseRequest, setShiftReleaseRequest] = useState<ShiftReleaseRequest>();

    const [conflictingRequests, setConflictingRequests] = useState<LeaveRequest[]>([]);
    const [conflictingShifts, setConflictingShifts] = useState<Shift[]>();
    const [isLeaveConflictsDialogOpen, setIsLeaveConflictsDialogOpen] = useState<boolean>(false);

    const [leaveRequestWithNegativeBalance, setLeaveRequestWithNegativeBalance] = useState<LeaveRequestFormValues>();

    const isEdit = !!defaultLeaveRequest;

    const schema = leaveRequestFormSchema;

    const formMethods = useForm<LeaveRequestFormValues, undefined, LeaveRequestFormValues>({
        resolver: yupResolver(schema),
        defaultValues: getDefaultLeaveRequestFormValues(defaultLeaveRequest, defaultLeaveType, defaultLeaveRequestFormValues),
    });

    const { handleSubmit, control, watch, setValue, getValues, formState } = formMethods;
    const { files, handleFileRemoved, handleFileUploaded } = useLeaveRequestAttachments(defaultLeaveRequest?.id, employeeId);

    const getLeaveTypesWithSameUnitType = (unit: UnitType) =>
        [...(employeeLeaveTypePolicies ?? [])]
            .sort((a, b) => {
                return a.leaveType.order - b.leaveType.order;
            })
            .filter(history => history.leaveType.unitType === unit || history.leaveType.unitType === UnitType.BOTH)
            .map(history => removeNullsFromLeaveType(history.leaveType));

    const saveUploadedAttachments = async (leaveRequestId: number) => {
        if (!isEdit && !!files?.length) {
            const attachmentRequests = files.map(async file => {
                if (!isTemporaryFile(file)) {
                    return;
                }

                return createLeaveRequestAttachment({
                    leaveRequestId,
                    file: file,
                    mimeType: file.data.type,
                    name: file.name ?? '',
                });
            });

            try {
                await Promise.all(attachmentRequests);
            } catch (error) {
                handleError(error);
            }
        }
    };

    const onLeaveRequestCreated = () => {
        showSnackbar(t('request_leave_dialog.messages.leave_request_created'), 'success');
        onSave();
    };
    const onLeaveRequestApproveError = (error: unknown) => {
        showSnackbar(t('request_leave_dialog.messages.could_not_approve_leave_request'), 'error');
        console.error(error);
    };
    const onLeaveRequestCreationError = (error: unknown) => {
        showSnackbar(t('request_leave_dialog.messages.could_not_create_leave_request'), 'error');
        console.error(error);
    };
    const onLeaveRequestUpdateError = (error: unknown) => {
        console.error(error);
        showSnackbar(t('request_leave_dialog.messages.could_not_update_leave_request'), 'error');
    };

    const createLeave = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        try {
            const leavePercentage = getLeaveRemainingTimePercentage(conflictingRequests, leaveRequestFormValue.unitType, leaveRequestFormValue.leavePercentage);
            const request: LeaveCreationMutation = mapLeaveRequestFormValuesToLeaveCreationMutation(leaveRequestFormValue, employeeId, leavePercentage);
            const leaveRequests = await createLeaveRequest(request);
            if (leaveRequestFormValue.isApproveAutomatically) {
                const approvalPromises = leaveRequests.map(async leaveRequest => {
                    try {
                        if (!leaveRequestFormValue.isMultipleDays) {
                            await saveUploadedAttachments(leaveRequest.id);
                        }
                        await approvePendingLeaveRequest(leaveRequest.id);
                        onLeaveRequestCreated();
                    } catch (error) {
                        onLeaveRequestApproveError(error);
                    }
                });
                await Promise.all(approvalPromises);
                onSave();
            } else if (leaveRequests?.length > 0) {
                if (!leaveRequestFormValue.isMultipleDays) {
                    await saveUploadedAttachments(leaveRequests[0].id);
                }
                onLeaveRequestCreated();
            }
        } catch (error) {
            onLeaveRequestCreationError(error);
        }
    };

    const handleAutoApprove = async (leaveRequestId: number) => {
        try {
            await approvePendingLeaveRequest(leaveRequestId);
            onSave();
        } catch (error) {
            onLeaveRequestUpdateError(error);
        }
    };
    const onSaveLeaveRequest = ({ autoApprove, leaveRequestId }: { autoApprove: boolean; leaveRequestId: number }) =>
        autoApprove ? handleAutoApprove(leaveRequestId) : onSave();

    const updateLeaveRequest = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        try {
            const leavePercentage = getLeaveRemainingTimePercentage(conflictingRequests, leaveRequestFormValue.unitType, leaveRequestFormValue.leavePercentage);
            const request: LeaveUpdateMutation = mapLeaveRequestFormValuesToLeaveUpdateMutation(leaveRequestFormValue, leavePercentage);
            if (!isEdit) {
                return; // display an error message
            }

            await editLeaveRequest(defaultLeaveRequest.id, request);
            await onSaveLeaveRequest({
                autoApprove: leaveRequestFormValue.isApproveAutomatically,
                leaveRequestId: defaultLeaveRequest.id,
            });
        } catch (error) {
            onLeaveRequestUpdateError(error);
        }
    };

    const updatePendingLeaveRequest = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        const leavePercentage = getLeaveRemainingTimePercentage(conflictingRequests, leaveRequestFormValue.unitType, leaveRequestFormValue.leavePercentage);
        const request: LeaveUpdateMutation = mapLeaveRequestFormValuesToLeaveUpdateMutation(leaveRequestFormValue, leavePercentage);
        if (!isEdit) {
            return; // display an error message
        }
        try {
            await editPendingLeaveRequest(defaultLeaveRequest.id, request);
            await onSaveLeaveRequest({
                autoApprove: leaveRequestFormValue.isApproveAutomatically,
                leaveRequestId: defaultLeaveRequest.id,
            });
        } catch (error) {
            onLeaveRequestUpdateError(error);
        }
    };

    const handleSave = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        // Temporary fix for the issue where the form is submitted multiple times, we should use useMutation instead
        setIsSubmittingMutation(true);
        if (!isEdit) {
            await createLeave(leaveRequestFormValue);
        } else if (defaultLeaveRequest && defaultLeaveRequest.requestStatus !== 'PENDING') {
            await updateLeaveRequest(leaveRequestFormValue);
        } else {
            await updatePendingLeaveRequest(leaveRequestFormValue);
        }
        setIsSubmittingMutation(false);
    };

    const onSubmit = (leaveRequestFormValue: LeaveRequestFormValues) => {
        if (leaveRequestFormValue.isApproveAutomatically) {
            const balance = convertLeavesMinutesToUnit({
                input:
                    (leaveRequestFormValue.unitType === UnitType.DAYS
                        ? leaveRequestAmounts?.remainingAmountInDays
                        : leaveRequestAmounts?.remainingAmountInMinutes) ?? 0,
                outputUnit: leaveRequestFormValue.unitType,
                roundingType: leaveRequestFormValue.leaveType?.roundingType,
            });
            if (balance < 0 && leaveRequestFormValue.leaveType?.allowanceType !== AllowanceType.UNLIMITED) {
                setLeaveRequestWithNegativeBalance(leaveRequestFormValue);
                return;
            }
            checkConflictsLeave(leaveRequestFormValue);
        } else {
            handleSave(leaveRequestFormValue);
        }
    };

    const isFormValid = useCallback(() => {
        // Not allow to submit the form during fetching information
        if (isFetchingInformation) {
            return false;
        }
        if (!previewResult) {
            return false;
        }

        if (previewResult?.exceedingBalance) {
            return true;
        }

        // rule base on remainingAmountInMinutes
        if (!leaveRequestAmounts?.remainingAmountInDays && leaveRequestAmounts?.remainingAmountInDays !== 0) {
            return false;
        }

        const leaveRequest = getValues();

        // rule base on unitType
        const isDayUnit = leaveRequest.unitType === UnitType.DAYS;

        if (isDayUnit) {
            if (!isFormLeaveTypeDaysValid(leaveRequest) || hasConflicts(conflictingRequests)) {
                return false;
            }
        } else if (!isFormLeaveTypeHoursValid(leaveRequest)) {
            return false;
        }

        return !(
            leaveRequest.leaveType?.maxDurationInMinutes &&
            previewResult?.previews?.some(preview =>
                isLeaveRequestExceedingMaxDuration(
                    leaveRequest.leaveType,
                    leaveRequest.unitType,
                    preview.amountInMinutes,
                    preview.amountInDays,
                    preview.durationInMinutes,
                ),
            )
        );
    }, [isFetchingInformation, previewResult, leaveRequestAmounts?.remainingAmountInDays, getValues, conflictingRequests]);

    const leaveType = watch('leaveType');
    const unitType = watch('unitType');
    const isLeaveTypeDay = unitType === UnitType.DAYS;

    const needToAttachFiles =
        leaveType.allowAttachments &&
        leaveType.attachmentRequiredAfterDays != undefined &&
        leaveType.attachmentRequiredAfterDays != getNull() &&
        files.length === 0 &&
        leaveType.attachmentRequiredAfterDays <= (previewResult?.totalAmountInDays ?? 0);
    const showApproveAutomatically = canApproveLeaveRequests && isFormValid() && !!getValues('endDate') && !needToAttachFiles && !leaveType.autoApproveRequest;

    const leaveTypesBasedOnUnitType = getLeaveTypesWithSameUnitType(unitType);

    const onLeaveTypeChange = (leaveType: LeaveRequestFormValues['leaveType']) => {
        const newValue = leaveTypesBasedOnUnitType.find(lt => lt.id === leaveType.id);
        if (!newValue) {
            return;
        }
        const getNewEndDate = (leaveActivityType: LeaveActivityType, endDate: Nullable<LocalDate>, startDate: Nullable<LocalDate>): Nullable<LocalDate> => {
            if (leaveActivityType === LeaveActivityType.MEDICAL) {
                return endDate;
            }
            return endDate ?? startDate;
        };
        const endDate = getNewEndDate(newValue.leaveActivityType, getValues('endDate'), getValues('startDate'));
        setValue('endDate', endDate ?? getNull());
    };

    const onUnitTypeChange = (type: UnitType) => {
        if (type === UnitType.HOURS) {
            setValue('endDate', getValues('startDate') ?? getNull());
        }
    };

    const defaultLeaveRequestId = defaultLeaveRequest?.id;

    const resetState = useCallback(() => {
        setOverlappingRequests([]);
        setConflictingRequests([]);
        setPreviewResult(undefined);
        setLeaveRequestAmounts(prevState => ({
            ...prevState,
            remainingAmountInDays: prevState?.availableAmountInDays,
            remainingAmountInMinutes: prevState?.availableAmountInMinutes,
        }));
        setIsFetchingInformation(false);
    }, []);

    const mustCheckOverlapping = useCallback((formValues: LeaveRequestFormValues, shouldCheckForOverlapping: boolean): boolean => {
        return (
            !!formValues?.startDate &&
            !!formValues?.endDate &&
            !isOverlappingExceedsLimitInDays(toDate(formValues.startDate), toDate(formValues.endDate)) &&
            shouldCheckForOverlapping
        );
    }, []);

    const fetchOverlappingRequests = useCallback(
        async (formValues: LeaveRequestFormValues) => {
            const overlapping = await searchOverlappingRequests({
                startDate: formValues.startDate,
                endDate: formValues.endDate,
                dates: formValues.dates ?? [],
                employeeId: employeeId,
                startTimePeriod: formValues.startTimePeriod != DayPeriod.ALL_DAY ? formValues.startTimePeriod : undefined,
                endTimePeriod: formValues.endTimePeriod != DayPeriod.ALL_DAY ? formValues.endTimePeriod : undefined,
                statuses: ['APPROVED', 'PENDING'],
            });
            return overlapping?.filter(lr => lr.id !== defaultLeaveRequestId);
        },
        [employeeId, defaultLeaveRequestId],
    );

    const fetchConflictingRequests = useCallback(
        async (formValues: LeaveRequestFormValues) => {
            const conflicting = await searchConflictingRequests({
                startDate: formValues.startDate,
                endDate: formValues.endDate,
                dates: formValues.dates ?? [],
                employeeId: employeeId,
                startTimePeriod: formValues.startTimePeriod != DayPeriod.ALL_DAY ? formValues.startTimePeriod : undefined,
                endTimePeriod: formValues.endTimePeriod != DayPeriod.ALL_DAY ? formValues.endTimePeriod : undefined,
                statuses: ['APPROVED'],
                leaveTypeIds: [formValues.leaveType.id],
            });
            return conflicting?.filter(lr => lr.id !== defaultLeaveRequestId);
        },
        [employeeId, defaultLeaveRequestId],
    );

    const fetchPreview = useCallback(
        async (formValues: LeaveRequestFormValues, conflictingRequests: LeaveRequest[]) => {
            return await previewLeaveRequest({
                employeeId: employeeId,
                leaveRequestId: defaultLeaveRequestId,
                leaveTypeId: formValues.leaveType.id,
                startDate: formValues.startDate,
                endDate: formValues.endDate,
                dates: formValues.dates,
                startTime:
                    formValues.startTimeInMinutes !== undefined && formValues.startTimeInMinutes !== getNull()
                        ? formatDurationInTime(formValues.startTimeInMinutes)
                        : undefined,
                endTime: formValues.endTimeInMinutes ? formatDurationInTime(formValues.endTimeInMinutes) : undefined,
                comment: formValues.comment,
                startTimePeriod: formValues.startTimePeriod,
                endTimePeriod: formValues.endTimePeriod,
                unitType: formValues.unitType,
                leavePercentage: getLeaveRemainingTimePercentage(conflictingRequests, formValues?.leaveType.unitType, formValues.leavePercentage),
            });
        },
        [defaultLeaveRequestId, employeeId],
    );

    const fetchLeaveRequestPreview = useCallback(
        async (formValues: LeaveRequestFormValues) => {
            try {
                setIsFetchingInformation(true);
                const isMedicalLeaveType = formValues.leaveType?.leaveActivityType === LeaveActivityType.MEDICAL;
                const mustCheckConflict =
                    (!formValues.isMultipleDays && !(isMedicalLeaveType && formValues.endDate == undefined)) ||
                    (formValues.isMultipleDays && formValues.dates.length < 30);

                if (mustCheckOverlapping(formValues, mustCheckConflict)) {
                    const overlapping = await fetchOverlappingRequests(formValues);
                    setOverlappingRequests(overlapping?.filter(lr => lr.id !== defaultLeaveRequestId));
                } else {
                    setOverlappingRequests([]);
                }

                let filteredConflictingRequests: LeaveRequest[] = [];
                if (mustCheckConflict) {
                    filteredConflictingRequests = await fetchConflictingRequests(formValues);
                    setConflictingRequests(filteredConflictingRequests);
                } else {
                    setConflictingRequests([]);
                }

                const leaveRequestPreview = await fetchPreview(formValues, filteredConflictingRequests);
                setPreviewResult(leaveRequestPreview);
                const { remainingAmountInDays, availableAmountInDays, remainingAmountInMinutes, availableAmountInMinutes } = leaveRequestPreview ?? {};

                setLeaveRequestAmounts({
                    remainingAmountInDays,
                    availableAmountInDays,
                    remainingAmountInMinutes,
                    availableAmountInMinutes,
                });
            } catch (error) {
                console.error(error);
                handleError(error);
            } finally {
                setIsFetchingInformation(false);
            }
        },
        [mustCheckOverlapping, fetchPreview, fetchOverlappingRequests, defaultLeaveRequestId, fetchConflictingRequests],
    );

    const fetchLeaveRequestDetails = useCallback(
        (formValues: LeaveRequestFormValues) => {
            const isValidLeavePercentage = !formValues.leavePercentage || 0 > formValues.leavePercentage || formValues.leavePercentage > 100;

            //make sure there is no request being sent to the BE if the leave percentage is not valid
            if (isValidLeavePercentage) {
                return;
            }

            //if its multiple days and no dates are selected, reset all the values because we do not have the data to search
            if (formValues.isMultipleDays && formValues.dates.length === 0) {
                resetState();
                return;
            }

            // If start date and end date are the same, reset end time period to make sure they are the same
            if (formValues.startDate && formValues.endDate && isSameDate(formValues.startDate, formValues.endDate)) {
                formValues.endTimePeriod = formValues.startTimePeriod;
            }

            fetchLeaveRequestPreview(formValues);
        },
        [resetState, fetchLeaveRequestPreview],
    );

    const validateStartAndEndDate = useCallback(
        (formValues: LeaveRequestFormValues, fieldName: string) => {
            if (fieldName === 'startDate') {
                const newEndDate = getNewEndDateOnStartDateChange(toDate(formValues.endDate), toDate(formValues.startDate));
                formValues.endDate = formatToLocalDate(newEndDate) ?? getNull();
                setValue('endDate', formatToLocalDate(newEndDate) ?? getNull());
            }

            if ((fieldName === 'startDate' || fieldName === 'endDate') && formValues.startDate && formValues.endDate) {
                if (
                    !formValues.startTimePeriod ||
                    !getStartPeriodChoices(toDate(formValues.startDate), toDate(formValues.endDate)).includes(formValues.startTimePeriod)
                ) {
                    setValue('startTimePeriod', DayPeriod.ALL_DAY);
                    formValues.startTimePeriod = DayPeriod.ALL_DAY;
                }
                if (
                    !formValues.endTimePeriod ||
                    !getEndPeriodChoices(toDate(formValues.startDate), toDate(formValues.endDate)).includes(formValues.endTimePeriod)
                ) {
                    setValue('endTimePeriod', DayPeriod.ALL_DAY);
                    formValues.endTimePeriod = DayPeriod.ALL_DAY;
                }
            }

            if (
                (fieldName === 'startDate' || fieldName === 'endDate' || fieldName === 'startTimePeriod') &&
                formValues.startDate &&
                formValues.endDate &&
                isSameDate(formValues.startDate, formValues.endDate)
            ) {
                setValue('endTimePeriod', formValues.startTimePeriod);
                formValues.endTimePeriod = formValues.startTimePeriod;
            }
        },
        [setValue],
    );

    const validateLeaveType = useCallback(
        (formValues: LeaveRequestFormValues, fieldName: string) => {
            if (fieldName === 'leaveType') {
                if (formValues.leaveType?.leaveActivityType === LeaveActivityType.MEDICAL) {
                    setLeaveRequestAmounts(prevState => ({
                        ...prevState,
                        remainingAmountInDays: 0,
                    }));
                }
                if (formValues.leaveType?.leaveActivityType !== LeaveActivityType.MEDICAL && !formValues.endDate) {
                    formValues.endDate = formValues.startDate ?? getNull();
                    setValue('endDate', formValues.startDate ?? getNull());
                }
            }
        },
        [setValue],
    );

    const validateMultipleDays = useCallback(
        (formValues: LeaveRequestFormValues, fieldName: string) => {
            if (fieldName === 'isMultipleDays') {
                //when we are in multiple days mode, we should always use ALL_DAY as the default value for the time period
                setValue('dates', []);
                setValue('startTimePeriod', DayPeriod.ALL_DAY);
                setValue('endTimePeriod', DayPeriod.ALL_DAY);
                formValues.dates = [];
                formValues.startTimePeriod = DayPeriod.ALL_DAY;
                formValues.endTimePeriod = DayPeriod.ALL_DAY;
            }
        },
        [setValue],
    );

    const validateAndCorrectCurrentFormValues = useCallback(
        (formValues: LeaveRequestFormValues, fieldName: string): LeaveRequestFormValues => {
            validateStartAndEndDate(formValues, fieldName);

            validateLeaveType(formValues, fieldName);

            validateMultipleDays(formValues, fieldName);

            return formValues;
        },
        [validateLeaveType, validateMultipleDays, validateStartAndEndDate],
    );

    const leaveRequestFormValues = watch();
    const displayApproveAutomaticallyCheckbox =
        (!!defaultLeaveRequest && hasRequestStatus(defaultLeaveRequest.requestStatus) && showApproveAutomatically) ||
        (!defaultLeaveRequest && showApproveAutomatically);

    //TODO: maybe we can improve this with a debounce to see later
    //this useEffect is called on every change from the form
    useDeepCompareEffect(() => {
        //on first render call the fetchLeaveRequestDetails, so we can have the initial values
        fetchLeaveRequestDetails(watch());
        //after the first render, we will only call the fetchLeaveRequestDetails only when the form values change
        const { unsubscribe } = watch((value, { name, type }) => {
            if (name === 'comment' || name === 'isApproveAutomatically') {
                return;
            }
            if ((type === 'change' && name) || name === 'endDate') {
                //when clearing the endDate the type will be undefined, but we still need to call the fetchLeaveRequestDetails
                const formValuesValidated = validateAndCorrectCurrentFormValues(value as LeaveRequestFormValues, name);
                fetchLeaveRequestDetails(formValuesValidated);
            }
        });
        return () => unsubscribe();
    }, [fetchLeaveRequestDetails, validateAndCorrectCurrentFormValues, watch]);

    useEffect(() => {
        if (!displayApproveAutomaticallyCheckbox) {
            //in case the employee cant approve automatically, we need to make sure the form contains the correct value
            setValue('isApproveAutomatically', false);
        }
    }, [displayApproveAutomaticallyCheckbox, setValue]);

    const checkConflictsLeave = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        const conflicts = await checkConflictsLeaveRequestFormValues(policies, employeeId, leaveRequestFormValue);
        if (conflicts) {
            setConflictingShifts(conflicts.shifts);
            setIsLeaveConflictsDialogOpen(true);
            setShiftReleaseRequest(conflicts.shiftReleaseRequest);
        } else {
            handleSave(leaveRequestFormValue);
        }
    };

    const durationInDays = unitType === UnitType.HOURS ? convertMinutesToDays(previewResult?.totalAmountInMinutes ?? 0) : previewResult?.totalAmountInDays;

    const commonLeaveTypeDetails = (
        <CommonLeaveTypeDetails
            leaveType={leaveType}
            files={files}
            onFileUploaded={files => handleFileUploaded(files[0])}
            onFileRenamed={undefined}
            onFileRemoved={handleFileRemoved}
            fetchDocumentUrl={itemId => getLeaveRequestAttachmentUrl(itemId, 'ATTACHMENT').then(res => res)}
            durationInDays={durationInDays ?? 0}
            employeeId={employeeId}
            confirmOnRemove={isEdit}
        />
    );

    const renderOverlappingRequestDetails = () => {
        if (!overlappingRequests?.length) {
            return <></>;
        }

        return (
            <Stack direction='column' spacing={1} alignItems='flex-start' pb={2}>
                <Typography variant='body2'>{t('leaves_page.whos_out')}</Typography>
                {overlappingRequests.map(lr => {
                    return (
                        <EmployeeAvatarWithDetails key={lr?.id} employee={lr?.employee}>
                            <Typography> {getLeaveRequestPeriodAsString(lr)}</Typography>
                        </EmployeeAvatarWithDetails>
                    );
                })}
            </Stack>
        );
    };

    const canBeBothUnitType = leaveType.unitType === UnitType.BOTH;

    const isSubmitting = formState.isSubmitting || isSubmittingMutation;

    return (
        <>
            <FormProvider {...formMethods}>
                <Stack spacing={2} component={DialogContent}>
                    {!displayOverlappingRequestDetails && (
                        <Stack spacing={2}>
                            <FormControlLabel
                                label={t('request_leave_dialog.leave_type')}
                                style={{ width: '100%' }}
                                control={
                                    <FieldSelect
                                        name={'leaveType'}
                                        control={control}
                                        options={leaveTypesBasedOnUnitType}
                                        getOptionLabel={option => getLabelTranslation(option.name)}
                                        isOptionEqualToValue={(option, anotherOption) => option.id === anotherOption.id}
                                        fullWidth={true}
                                        disableClearable={true}
                                        onChange={selectedLeaveType => {
                                            if (!selectedLeaveType) {
                                                return;
                                            }
                                            onLeaveTypeChange(selectedLeaveType);
                                        }}
                                    />
                                }
                            />
                            {canBeBothUnitType && (
                                <Controller
                                    render={({ field: { onChange, ...field } }) => (
                                        <RadioGroup
                                            {...field}
                                            onChange={(_, type) => {
                                                onUnitTypeChange(type as UnitType);
                                                onChange(type);
                                            }}
                                        >
                                            <Stack direction='row'>
                                                <FormControlLabel
                                                    value={UnitType.DAYS}
                                                    control={<Radio />}
                                                    label={t('request_leave_dialog.unit_type_days')}
                                                    labelPlacement={'end'}
                                                />
                                                <FormControlLabel
                                                    value={UnitType.HOURS}
                                                    control={<Radio />}
                                                    label={t('request_leave_dialog.unit_type_hours')}
                                                    labelPlacement={'end'}
                                                />
                                            </Stack>
                                        </RadioGroup>
                                    )}
                                    name={'unitType'}
                                    control={control}
                                />
                            )}
                            {isLeaveTypeDay ? (
                                <DailyLeaveTypeDetails
                                    previewResult={previewResult}
                                    isEdit={isEdit}
                                    overlappingRequests={overlappingRequests}
                                    conflictingRequests={conflictingRequests}
                                    remainingAmountInDays={leaveRequestAmounts?.remainingAmountInDays}
                                    availableAmountInDays={leaveRequestAmounts?.availableAmountInDays}
                                    remainingAmountInMinutes={leaveRequestAmounts?.remainingAmountInMinutes}
                                    availableAmountInMinutes={leaveRequestAmounts?.availableAmountInMinutes}
                                    displayOverlappingRequestDetails={() => {
                                        makeItDisplayOverlappingRequestDetails();
                                    }}
                                >
                                    {commonLeaveTypeDetails}
                                </DailyLeaveTypeDetails>
                            ) : (
                                <HourlyLeaveTypeDetails
                                    previewResult={previewResult}
                                    isEdit={isEdit}
                                    remainingAmountInMinutes={leaveRequestAmounts?.remainingAmountInMinutes}
                                    availableAmountInMinutes={leaveRequestAmounts?.availableAmountInMinutes}
                                >
                                    {commonLeaveTypeDetails}
                                </HourlyLeaveTypeDetails>
                            )}
                        </Stack>
                    )}
                    {previewResult?.exceedingBalance && (
                        <Alert severity='warning' elevation={0}>
                            <Typography variant='body2'>{t('request_leave_dialog.exceeding_leave_balance_error')}</Typography>
                        </Alert>
                    )}
                    {displayOverlappingRequestDetails && renderOverlappingRequestDetails()}
                </Stack>
                <DialogActions>
                    {!displayOverlappingRequestDetails && (
                        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                            {displayApproveAutomaticallyCheckbox && (
                                <FieldCheckbox
                                    name={'isApproveAutomatically'}
                                    control={control}
                                    label={t('request_leave_dialog.automatically_approve_request')}
                                    aria-label='isApproveAutomatically'
                                    labelPlacement='end'
                                />
                            )}
                            <Button
                                disabled={!isFormValid() || isSubmitting}
                                variant='contained'
                                color='primary'
                                size='medium'
                                onClick={handleSubmit(onSubmit, console.error)}
                            >
                                {t('general.save')}
                            </Button>
                        </Stack>
                    )}
                </DialogActions>
            </FormProvider>
            {isLeaveConflictsDialogOpen && (
                <LeavesConflictsDialog
                    open={isLeaveConflictsDialogOpen}
                    onClose={() => setIsLeaveConflictsDialogOpen(false)}
                    onSave={() => {
                        if (!shiftReleaseRequest) {
                            return;
                        }
                        shiftRelease(shiftReleaseRequest).then(() => {
                            handleSave(leaveRequestFormValues);

                            setIsLeaveConflictsDialogOpen(false);
                        });
                    }}
                    saveAndKeepConflicts={() => {
                        setIsLeaveConflictsDialogOpen(false);

                        handleSave(leaveRequestFormValues);
                    }}
                    shifts={conflictingShifts ?? []}
                />
            )}
            {leaveRequestWithNegativeBalance && (
                <ConfirmNegativeBalanceDialog
                    onSave={() => {
                        setLeaveRequestWithNegativeBalance(undefined);
                        checkConflictsLeave(leaveRequestFormValues);
                    }}
                    onClose={() => setLeaveRequestWithNegativeBalance(undefined)}
                    leaveRequestUnitType={leaveRequestWithNegativeBalance.unitType}
                    leaveType={leaveRequestWithNegativeBalance.leaveType}
                    availableAmountInDays={leaveRequestAmounts?.remainingAmountInDays}
                    availableAmountInMinutes={leaveRequestAmounts?.remainingAmountInMinutes}
                />
            )}
        </>
    );
};

const getDefaultLeaveRequestFormValues = (
    leaveRequest: LeaveRequest | undefined,
    leaveType: LeaveType,
    defaultLeaveRequestFormValues: Partial<LeaveRequestFormValues> | undefined,
): LeaveRequestFormValues => {
    const getDefaultLeaveTypeUnitType = () => {
        if (leaveType.unitType === UnitType.BOTH) {
            return UnitType.DAYS;
        }
        return leaveType.unitType;
    };
    return {
        ...{
            unitType: leaveRequest?.unitType ?? getDefaultLeaveTypeUnitType(),
            leaveType: leaveRequest?.leaveType ? removeNullsFromLeaveType(leaveRequest?.leaveType) : leaveType,
            dates: [],
            startTimePeriod: leaveRequest?.startTimePeriod ?? DayPeriod.ALL_DAY,
            endTimePeriod: leaveRequest?.endTimePeriod ?? DayPeriod.ALL_DAY,
            startDate: leaveRequest?.startDate ?? getCurrentLocalDate(),
            endDate: leaveRequest ? (leaveRequest.endDate ?? getNull()) : getCurrentLocalDate(),
            startTimeInMinutes: getDurationFromTime(leaveRequest?.startTime) ?? 0,
            endTimeInMinutes: getDurationFromTime(leaveRequest?.endTime) ?? 0,
            comment: leaveRequest?.comment ?? '',
            leavePercentage: leaveRequest?.leavePercentage ?? 100,
            isMultipleDays: false,
            isApproveAutomatically: false,
        },
        ...defaultLeaveRequestFormValues,
    };
};

const mapLeaveRequestFormValuesToLeaveCreationMutation = (
    formValues: LeaveRequestFormValues,
    employeeId: number,
    leavePercentage: number,
): LeaveCreationMutation => {
    const mutation = mapLeaveRequestFormValuesToLeaveUpdateMutation(formValues, leavePercentage);
    return {
        ...mutation,
        employeeId: employeeId,
        dates: formValues.isMultipleDays ? formValues.dates : [],
    };
};

const mapLeaveRequestFormValuesToLeaveUpdateMutation = (formValues: LeaveRequestFormValues, leavePercentage: number): LeaveUpdateMutation => {
    return {
        leaveTypeId: formValues.leaveType.id,
        unitType: formValues.unitType,
        //for the creation in case of being multiple days, the BE will prioritize the dates over the startDate and endDate and it is a mandatory field in the update so this case will probably not happen
        startDate: formValues.startDate ?? getCurrentLocalDate(),
        endDate: formValues.endDate ?? undefined,
        startTime:
            formValues.startTimeInMinutes !== undefined && formValues.startTimeInMinutes !== getNull()
                ? formatDurationInTime(formValues.startTimeInMinutes)
                : undefined,
        endTime: formValues.endTimeInMinutes ? formatDurationInTime(formValues.endTimeInMinutes) : undefined,
        comment: formValues.comment,
        startTimePeriod: formValues.startTimePeriod,
        endTimePeriod: formValues.endTimePeriod,
        leavePercentage: leavePercentage,
    };
};

const hasRequestStatus = (status: LeaveRequestStatus): boolean => {
    return status && (status === 'PENDING' || status === 'APPROVED');
};

function isEndDateCanBeEmpty(formValues: LeaveRequestFormValues): boolean {
    return formValues.leaveType.leaveActivityType === LeaveActivityType.MEDICAL;
}

const isFormLeaveTypeDaysValid = (formValues: LeaveRequestFormValues) => {
    if (!isEndDateCanBeEmpty(formValues) && !formValues.endDate) {
        return false;
    }
    if (!formValues.startTimePeriod) {
        return false;
    }
    return formValues.endTimePeriod;
};

const isFormLeaveTypeHoursValid = (formValues: LeaveRequestFormValues) => {
    if (formValues.startTimeInMinutes === undefined) {
        //this value can be midnight = 0
        return false;
    }
    if (formValues.endTimeInMinutes === undefined) {
        //this value can be midnight = 0
        return false;
    }
    return formValues.startTimeInMinutes < formValues.endTimeInMinutes;
};

const checkConflictsLeaveRequestFormValues = async (
    policies: EmployeePolicy[],
    employeeId: number,
    leaveRequestFormValues: LeaveRequestFormValues,
): Promise<
    | {
          shifts: Shift[];
          shiftReleaseRequest: ShiftReleaseRequest;
      }
    | undefined
> => {
    if (shouldFetchEmployeeShifts(leaveRequestFormValues.leaveType, toDate(leaveRequestFormValues?.endDate), policies)) {
        const shiftStatuses = [ShiftStatus.SHIFT_DRAFT, ShiftStatus.SHIFT_PUBLISHED];
        const shiftSearchRequest = createShiftSearchRequestFromLeaveRequestFormValues(employeeId, shiftStatuses, leaveRequestFormValues);
        try {
            const response = await getEmployeeShifts(shiftSearchRequest);
            if (response[0]?.shifts?.length) {
                const shiftReleaseRequest: ShiftReleaseRequest = {
                    rangeDates: shiftSearchRequest.rangeDates,
                    employeeId: (shiftSearchRequest.employeeIds ?? [])[0],
                };
                const shifts = response.map(shift => shift.shifts).flat();
                return { shifts: shifts, shiftReleaseRequest: shiftReleaseRequest };
            } else {
                return undefined;
            }
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }
    return undefined;
};

const createShiftSearchRequestFromLeaveRequestFormValues = (
    employeeId: number,
    planningShiftStatus: ShiftStatus[],
    leaveRequestFormValues: LeaveRequestFormValues,
): ShiftSearchRequest => {
    const { startDate: newStartDate, endDate: newEndDate } = calculateStartEndDateForLeaveRequestFormValues(leaveRequestFormValues);
    if (leaveRequestFormValues.isMultipleDays) {
        return shiftAPISearchRequestWithMultipleDates(newStartDate, newEndDate, leaveRequestFormValues, employeeId, planningShiftStatus);
    } else {
        return shiftAPISearchRequest(newStartDate, newEndDate, employeeId, planningShiftStatus);
    }
};

const shiftAPISearchRequestWithMultipleDates = (
    startDate: Date,
    endDate: Date,
    leaveRequestFormValues: LeaveRequestFormValues,
    employeeId: number,
    statuses?: ShiftStatus[],
): ShiftSearchRequest => {
    const rangeDates = leaveRequestFormValues.dates.map(date => {
        const startDateTime = setTime(date, getTimeFormatFromDate(startDate));
        const endDateTime = setTime(date, getTimeFormatFromDate(endDate));

        if (leaveRequestFormValues.unitType === UnitType.DAYS && leaveRequestFormValues.endTimePeriod === DayPeriod.ALL_DAY) {
            endDateTime.setDate(endDateTime.getDate());
        }

        return { start: startDateTime, end: endDateTime };
    });
    return {
        rangeDates: rangeDates,
        employeeIds: [employeeId],
        statuses: statuses ?? [],
    };
};

const calculateStartEndDateForLeaveRequestFormValues = (
    leaveRequestFormValues: LeaveRequestFormValues,
): {
    startDate: Date;
    endDate: Date;
} => {
    if (!leaveRequestFormValues.startDate || !leaveRequestFormValues.endDate) {
        return {
            startDate: toDate(leaveRequestFormValues.startDate) ?? getTodayDate(),
            endDate: toDate(leaveRequestFormValues.endDate) ?? getTodayDate(),
        };
    }
    const startDate = toDate(leaveRequestFormValues.startDate);
    const endDate = toDate(leaveRequestFormValues.endDate);

    if (leaveRequestFormValues.unitType === UnitType.HOURS) {
        setStartTimeAndEndTime(startDate, endDate, leaveRequestFormValues.startTimeInMinutes ?? 0, leaveRequestFormValues.endTimeInMinutes ?? 0);
    } else if (leaveRequestFormValues.unitType === UnitType.DAYS) {
        setDayPeriodTime(startDate, endDate, leaveRequestFormValues.startTimePeriod, leaveRequestFormValues.endTimePeriod);
    }

    return { startDate, endDate };
};

const getNewEndDateOnStartDateChange = (endDate: Date | undefined, startDate: Date | undefined): Date | undefined => {
    if (endDate && startDate && isBeforeDate(endDate, startDate)) {
        return startDate;
    }
    return endDate;
};
