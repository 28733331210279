import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { canManagePendingLeaveRequests } from '@/domain/permission/Permission.service';
import { LeaveRequestDialog } from '@/page/leave/leave-request-dialog/LeaveRequestDialog';
import { useCurrentPolicies } from '@/stores/store';
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 *
 * Open a dialog to add a leave request for the employee
 */
export const AddLeaveRequestButton: FC<{ employee: EmployeeAvatar; onSuccess: () => void }> = ({ employee, onSuccess }) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();

    const [openLeaveDialog, setOpenLeaveDialog] = useState(false);

    const handleSave = () => {
        setOpenLeaveDialog(false);
        onSuccess();
    };

    if (!canManagePendingLeaveRequests(policies, employee.id)) {
        return;
    }

    return (
        <>
            <Button variant='contained' size='small' onClick={() => setOpenLeaveDialog(true)}>
                {t('timesheets.history_page.add_leave')}
            </Button>
            {openLeaveDialog && (
                <LeaveRequestDialog
                    leaveRequestId={undefined}
                    open={true}
                    onClose={() => setOpenLeaveDialog(false)}
                    onSave={handleSave}
                    employeeId={employee.id}
                />
            )}
        </>
    );
};
