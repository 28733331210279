import { FC } from 'react';
import { hasEmployeeReviewObjectiveActions } from '@/domain/employee-review/EmployeeReview.service';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetEmployeeReview, useGetEmployeeReviewManagerSummary } from '@/hooks/employee-review/EmployeeReview.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';
import { useEmployeeReviewId } from '../useEmployeeReviewId';
import { useGetEmployeeReviewAction } from '@/hooks/employee-review-action/EmployeeReviewAction.hook';
import { ManagerSummaryFeedbackForm } from '@/page/review/employee-review-summary-feedback-form/ManagerSummaryFeedbackForm';

export const ManagerSummaryFeedbackPage: FC = () => {
    const employeeReviewId = useEmployeeReviewId();
    const {
        data: employeeReview,
        isLoading: isEmployeeReviewLoading,
        isError: isEmployeeReviewError,
        error: employeeReviewError,
    } = useGetEmployeeReview(employeeReviewId);
    const {
        data: managerSummary,
        isLoading: isManagerSummaryLoading,
        isError: isManagerSummaryError,
        error: managerSummaryError,
    } = useGetEmployeeReviewManagerSummary(employeeReviewId);

    const employeeId = employeeReview?.employee.id;

    const {
        data: objectives = [],
        error: objectivesError,
        isError: isObjectivesError,
        isLoading: objectivesLoading,
        refetch: refetchObjectives,
    } = useGetObjectives(employeeId ? { employeeIds: [employeeId] } : undefined);

    const isEmployeeReviewActionManagerSummaryEnabled = !!employeeReview && hasEmployeeReviewObjectiveActions(employeeReview);

    const {
        data: actions = [],
        error: actionsError,
        isError: isActionsError,
        isLoading: actionsLoading,
        refetch: refetchActions,
    } = useGetEmployeeReviewAction(Number(employeeReviewId), { options: { enabled: isEmployeeReviewActionManagerSummaryEnabled } });

    const shouldDisplayFeedbackForm = !!employeeReview && !!managerSummary && !!objectives && !!employeeReviewId;

    return (
        <StateHandler
            isLoading={isEmployeeReviewLoading || isManagerSummaryLoading || objectivesLoading || actionsLoading}
            isError={isEmployeeReviewError || isManagerSummaryError || !employeeId || isObjectivesError || isActionsError}
            error={objectivesError ?? employeeReviewError ?? managerSummaryError ?? actionsError}
        >
            {shouldDisplayFeedbackForm && (
                <ManagerSummaryFeedbackForm
                    employeeReview={employeeReview}
                    managerSummary={managerSummary}
                    objectives={objectives}
                    refetchObjectives={refetchObjectives}
                    employeeReviewId={employeeReviewId}
                    actions={actions}
                    refetchActions={refetchActions}
                />
            )}
        </StateHandler>
    );
};
