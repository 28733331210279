import { Report, ReportCreateMutation, ReportPreview, ReportRow, ReportType } from '@/domain/report/Report.model';
import {
    convertReportColumnToFieldDefinitionMutation,
    convertReportFilterToReportFilterMutation,
    createReport,
    getAvailableReportGroupedFields,
    getReport,
    getReportRows,
    getReportRowsPreview,
    searchReports,
} from '@/domain/report/Report.service';
import { createQueryHook, createRequiredParamsQueryHook, UseMutationResult, UseQueryResult } from '@/page/Query.type';
import { ReportColumn } from '@/page/report/report-columns-selector/ReportColumnsSelector';
import { ReportFilterItemBar } from '@/page/report/report-editor-bar/ReportEditorBar';
import { useCallback, useState } from 'react';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const reportKeys = createQueryKeys('reports', {
    search: (...params: Parameters<typeof searchReports>) => ({
        queryKey: params,
    }),
    getByIdAndType: (...params: Parameters<typeof getReport>) => ({
        queryKey: params,
    }),
    reportRows: (...params: Parameters<typeof getReportRows>) => ({
        queryKey: params,
    }),
    availableReportGroupedFields: (...params: Parameters<typeof getAvailableReportGroupedFields>) => ({
        queryKey: params,
    }),
});

export const useGetReports = createQueryHook(reportKeys.search, searchReports);

export const useGetReport = createRequiredParamsQueryHook(reportKeys.getByIdAndType, getReport);

export const useGetReportRows = createQueryHook(reportKeys.reportRows, getReportRows);

export const useGetAvailableReportGroupedFields = createRequiredParamsQueryHook(reportKeys.availableReportGroupedFields, getAvailableReportGroupedFields);

export const useCreateReport = (): UseMutationResult<Report, ReportCreateMutation> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutateReport = useCallback(async (mutationVariables: ReportCreateMutation) => {
        setIsPending(true);
        try {
            const result = await createReport(mutationVariables);
            setIsPending(false);
            return result;
        } catch (error) {
            setError(error);
            throw error;
        }
    }, []);

    return {
        mutate: mutateReport as (variables: ReportCreateMutation) => Promise<Report>,
        isPending,
        isError: !!error,
        error,
    };
};

export const useGetReportRowsPreview = ({
    reportType,
    sectionDefinitionId,
}: {
    reportType: ReportType | undefined;
    sectionDefinitionId: number | undefined;
}): UseQueryResult<ReportRow[]> & {
    refetchReportRowsPreview: (filters: ReportFilterItemBar[], columns: ReportColumn[], abortController?: AbortController) => Promise<void>;
} => {
    const [rows, setRows] = useState<ReportRow[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const fetchReportRowsPreview = useCallback(
        async (filters: ReportFilterItemBar[], columns: ReportColumn[], abortController?: AbortController) => {
            if (!reportType) {
                setIsLoading(false);
                setIsFetching(false);
                return;
            }
            try {
                setIsFetching(true);
                const previewParams = convertReportToPreviewParams(reportType, filters, columns, sectionDefinitionId);
                const rowsData = await getReportRowsPreview(previewParams, { signal: abortController?.signal });
                setRows(rowsData);
            } catch (error) {
                setError(error);
            }
            setIsFetching(false);
            setIsLoading(false);
        },
        [reportType, sectionDefinitionId],
    );

    return {
        data: rows,
        setData: setRows,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetchReportRowsPreview: fetchReportRowsPreview,
        refetch: () => fetchReportRowsPreview([], []),
    };
};

const convertReportToPreviewParams = (
    reportType: ReportType,
    filters: ReportFilterItemBar[],
    columns: ReportColumn[],
    reportItemId?: number,
): ReportPreview => {
    const params = {
        reportType,
        fieldDefinitions: columns?.filter(c => c.visible).map(convertReportColumnToFieldDefinitionMutation) ?? [],
        filters: filters?.filter(f => !!f.value).map(convertReportFilterToReportFilterMutation) ?? [],
    };

    if ('EMPLOYEE_SECTION_REPORT' === reportType || 'REVIEW_FEEDBACK_REPORT' === reportType) {
        return { ...params, reportType, reportItemId: reportItemId };
    }

    return params;
};
