import { ExpenseDistanceRate } from '@/domain/expense-distance-rate/ExpenseDistanceRate.model';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

type ExpenseDistanceRateDialogProps = {
    onSave: (data: ExpenseDistanceRateFormValues) => void;
    distanceRate: ExpenseDistanceRate | undefined;
} & DialogWrapperProps;

export const ExpenseDistanceRateDialog: FC<ExpenseDistanceRateDialogProps> = ({ open, onSave, onClose, distanceRate }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<ExpenseDistanceRateFormValues>({
        resolver: yupResolver(getDistanceRateSchema()),
        defaultValues: {
            value: distanceRate?.value,
            effectiveDate: distanceRate?.effectiveDate,
        },
    });

    const handleOnSave = (data: ExpenseDistanceRateFormValues) => {
        onSave(data);
    };

    const title = distanceRate ? t('expense_distance_rate_settings_page.dialog.edit_title') : t('expense_distance_rate_settings_page.dialog.add_title');

    return (
        <DialogWrapper header={title} open={open} onClose={onClose}>
            <Stack component={DialogContent} gap={2}>
                <FormControlLabel
                    aria-label={'distance-rate-value'}
                    label={t('expense_distance_rate_settings_page.dialog.distance_rate_value_input')}
                    control={<FieldNumber name={`value`} control={control} fullWidth precision={2} />}
                />

                <FormControlLabel
                    aria-label={'effective-date'}
                    label={t('expense_distance_rate_settings_page.dialog.effective_date_input')}
                    control={<FieldLocalDate name={`effectiveDate`} control={control} />}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getDistanceRateSchema = () => {
    return yup.object().shape({
        value: yup.number().required().min(0),
        effectiveDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
    });
};

export type ExpenseDistanceRateFormValues = yup.InferType<ReturnType<typeof getDistanceRateSchema>>;
