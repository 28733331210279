import { StateHandler } from '@/components/state-handler/StateHandler';
import { filterOutCompensationNotUnlimitedLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { useGetTimesheetSettings } from '@/hooks/timesheet-setting/TimesheetSettings.hook';
import { useCurrentEmployee } from '@/stores/store';

import { LeavesBalanceGrid } from '@/domain-ui/leave/leave-balance-grid/LeavesBalanceGrid';
import { FC } from 'react';

export const LeavesBalancePage: FC = () => {
    const currentEmployee = useCurrentEmployee();

    const { data: leaveTypes = [], isLoading: isLeaveTypesLoading, isError: isLeaveTypesError, error: leaveTypesError } = useGetLeaveTypes();

    const {
        data: timesheetSettings = [],
        isLoading: isTimesheetSettingsLoading,
        isError: isTimesheetSettingsError,
        error: timesheetSettingsError,
    } = useGetTimesheetSettings();

    const leaveTypesFiltered = filterOutCompensationNotUnlimitedLeaveTypes(leaveTypes);
    const defaultLeaveType = leaveTypesFiltered?.[0];
    const defaultTimesheetSetting = timesheetSettings?.[0];

    const isLoading = isLeaveTypesLoading || isTimesheetSettingsLoading;
    const isError = isLeaveTypesError || isTimesheetSettingsError;
    const isEmpty = !leaveTypes?.length || !timesheetSettings?.length || !defaultLeaveType || !defaultTimesheetSetting;
    const error = leaveTypesError ?? timesheetSettingsError;

    return (
        <StateHandler isLoading={isLoading} isError={isError} isEmpty={isEmpty} error={error}>
            <LeavesBalanceGrid
                currentEmployeeId={currentEmployee.id}
                leaveTypes={leaveTypesFiltered}
                defaultLeaveType={defaultLeaveType}
                timesheetSettings={timesheetSettings}
                defaultTimesheetSetting={defaultTimesheetSetting}
            />
        </StateHandler>
    );
};
