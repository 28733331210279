import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilePicker } from '@/components/file-picker/useFilePicker';
import { useGetDocumentTags } from '@/hooks/document-tag/DocumentTag.hook';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { handleError } from '@/utils/api.util';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FilePicker, FilePickerItem, TemporaryFile } from '@/components/file-picker/FilePicker';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { getLabelTranslation } from '@/utils/language.util';
import * as yup from 'yup';

export type DocumentUploadDialogProps = {
    onSave: (
        formValues: Overwrite<
            DocumentUploadFormValues,
            {
                files: TemporaryFile[];
            }
        >,
    ) => Promise<void>;
} & DialogWrapperProps;

export const DocumentUploadDialog: FC<DocumentUploadDialogProps> = ({ onSave, ...restDialog }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();
    const { files, addFiles, removeFile, updateFile } = useFilePicker();
    const { data: documentTags = [] } = useGetDocumentTags();

    const { control, handleSubmit } = useForm<DocumentUploadFormValues>({
        resolver: yupResolver(getDocumentUploadSchema()),
        defaultValues: {
            files: [],
            tagIds: [],
        },
    });

    const handleSettingsDialogSave = async ({ files, tagIds }: DocumentUploadFormValues) => {
        if (!files) {
            return;
        }
        // Filter out files already stored
        const temporaryFiles = files.filter(isTemporaryFile);
        setLoading(true);
        try {
            await onSave({ files: temporaryFiles, tagIds });
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };

    return (
        <DialogWrapper header={t('documents.upload_document_dialog.title')} {...restDialog}>
            <Stack component={DialogContent} gap={1} flex={1}>
                {/* TODO plug this field with react-hook-form https://rogerhr.atlassian.net/browse/RP-6256*/}
                <FormControlLabel
                    control={
                        <FilePicker
                            onFileRenamed={updateFile}
                            onFileUploaded={addFiles}
                            files={files}
                            onFileRemoved={removeFile}
                            fetchDocumentUrl={undefined}
                            sx={{
                                width: '100%',
                            }}
                        />
                    }
                    label={t('documents.upload_document_dialog.upload_field')}
                />

                <FormControlLabel
                    control={
                        <FieldSelect
                            multiple={true}
                            control={control}
                            options={documentTags.map(dt => dt.id)}
                            name={'tagIds'}
                            getOptionLabel={optionId => getLabelTranslation(documentTags.find(dt => dt.id === optionId)?.name)}
                            fullWidth={true}
                        />
                    }
                    label={t('documents.upload_document_dialog.tags_field')}
                />
            </Stack>
            <DialogActions>
                <Button
                    disabled={files?.length === 0 || loading}
                    // TODO get files from react-hook-form https://rogerhr.atlassian.net/browse/RP-6256
                    onClick={handleSubmit(
                        ({ tagIds }) =>
                            handleSettingsDialogSave({
                                files,
                                tagIds,
                            }),
                        console.error,
                    )}
                    loading={loading}
                    fullWidth
                >
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getDocumentUploadSchema = () => {
    return yup.object().shape({
        files: yup
            .array()
            .of(yup.object() as yup.ObjectSchema<FilePickerItem>)
            .default([]),
        tagIds: yup.array().of(yup.number().required()).default([]),
    });
};

type DocumentUploadFormValues = yup.InferType<ReturnType<typeof getDocumentUploadSchema>>;
