import { FC } from 'react';
import { Button, Paper, Stack } from '@mui/material';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { Add01Icon } from 'hugeicons-react';
import { DeleteConfirmDialog } from '@/components/delete-confirmation-dialog/DeleteConfirmDialog';
import { getLabelTranslation } from '@/utils/language.util';
import { useDeleteExpenseCategoryGroupMutation, useGetAllExpenseCategoryGroups } from '@/hooks/expense-category-group/ExpenseCategoryGroup.hook';
import { useTranslation } from 'react-i18next';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { ExpenseCategoryGroup } from '@/domain/expense-category-group/ExpenseCategoryGroup.model';
import { useDialogState } from '@/hooks/useDialog.hook';
import { ExpenseCategoryGroupSettingsGrid } from '@/page/setting/expense/expense-category-group/ExpenseCategoryGroupSettingsGrid';
import { ExpenseCategoryGroupDialog } from '@/domain-ui/expense/expense-category-group-dialog/ExpenseCategoryGroupDialog';
import { handleError } from '@/utils/api.util';

export const ExpenseCategoryGroupSettingsPage: FC = () => {
    const { t } = useTranslation();
    const { setGridRef, quickFilter } = useAgGridWrapper<ExpenseCategoryGroup>();

    const { state: categoryGroupDialogState, open: openCategoryGroupDialog, close: closeCategoryGroupDialog } = useDialogState<ExpenseCategoryGroup>();
    const { state: deleteCategoryDialogState, open: openDeleteCategoryDialog, close: closeDeleteCategoryDialog } = useDialogState<ExpenseCategoryGroup>();
    const categoryToDelete = deleteCategoryDialogState?.data;

    const { data: categoryGroups = [], isLoading } = useGetAllExpenseCategoryGroups();

    const { mutateAsync: deleteCategoryGroupMutate } = useDeleteExpenseCategoryGroupMutation();

    const handleDeleteCategoryGroup = async (expenseCategoryGroup: ExpenseCategoryGroup) => {
        try {
            await deleteCategoryGroupMutate(expenseCategoryGroup.id);
            closeDeleteCategoryDialog();
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack direction='row' gap={1} justifyContent='flex-end' alignItems='center' component={Paper} p={1}>
                <DatatableAdditionalAction quickFilter={quickFilter} />
                <Button variant='contained' onClick={() => openCategoryGroupDialog()} startIcon={<Add01Icon />}>
                    {t('expense_category_group_settings_page.new_group')}
                </Button>
            </Stack>
            <ExpenseCategoryGroupSettingsGrid
                rowData={categoryGroups}
                initRef={setGridRef}
                loading={isLoading}
                onEditCategoryGroupClick={openCategoryGroupDialog}
                onDeleteCategoryGroupClick={openDeleteCategoryDialog}
            />

            {categoryGroupDialogState?.open && (
                <ExpenseCategoryGroupDialog
                    open={true}
                    expenseCategoryGroup={categoryGroupDialogState.data}
                    onSuccess={closeCategoryGroupDialog}
                    onClose={closeCategoryGroupDialog}
                />
            )}

            {categoryToDelete && (
                <DeleteConfirmDialog
                    open={true}
                    onConfirm={() => handleDeleteCategoryGroup(categoryToDelete)}
                    header={t('expense_category_group_settings_page.delete_dialog.title')}
                    textContent={t('expense_category_group_settings_page.delete_dialog.confirmation_text', { categoryName: categoryToDelete.name })}
                    fieldName={getLabelTranslation(categoryToDelete.name)}
                    onClose={closeDeleteCategoryDialog}
                />
            )}
        </Stack>
    );
};
