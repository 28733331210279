import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { useGetEmployee } from '@/hooks/employee/Employee.hook';
import { useTranslation } from 'react-i18next';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getLabelTranslation } from '@/utils/language.util';
import { Paper, Skeleton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';
import { getUniqueEmploymentsJobs } from '@/domain/employment/Employment.service';

export const EmployeeProfileJobDescriptionPage: FC = () => {
    const employeeId = useEmployeeProfileId();
    const { t } = useTranslation();

    const { data: employee, isLoading: isEmployeeByIdLoading, isError: isEmployeeByIdError, error: employeeError } = useGetEmployee(employeeId);

    const jobs = employee ? getUniqueEmploymentsJobs(employee.currentEmployments) : [];

    return (
        <StateHandler
            isLoading={isEmployeeByIdLoading}
            isError={isEmployeeByIdError}
            error={employeeError}
            isEmpty={!jobs.length}
            loadingComponent={<JobDescriptionPageSkeleton />}
        >
            <Stack gap={2}>
                {jobs.map(job => (
                    <Stack key={job.id} gap={2} component={Paper} p={2}>
                        <Typography variant={'h2'}>{getLabelTranslation(job.name)}</Typography>

                        <Stack gap={1}>
                            {(!!job.jobFamily?.name || getLabelTranslation(job.jobLevel?.name)) && (
                                <Stack gap={0.5} direction={'row'}>
                                    <Typography variant={'h3'} color={'text.secondary'}>
                                        {job.jobFamily?.name}
                                    </Typography>
                                    {getLabelTranslation(job.jobLevel?.name) && job.jobFamily?.name && (
                                        <Typography color={'text.secondary'}> {' - '}</Typography>
                                    )}
                                    <Typography variant={'h3'} color={'text.secondary'}>
                                        {getLabelTranslation(job.jobLevel?.name)}
                                    </Typography>
                                </Stack>
                            )}
                            {job.purpose && (
                                <Stack gap={0.5}>
                                    <Typography variant={'h3'}>{t('job_description.purpose')}</Typography>
                                    <Typography>{getLabelTranslation(job.purpose)}</Typography>
                                </Stack>
                            )}
                            {job.responsibility && (
                                <Stack gap={0.5}>
                                    <Typography variant={'h3'}>{t('job_description.responsibilities')}</Typography>
                                    <RichTextTypography>{getLabelTranslation(job.responsibility)}</RichTextTypography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </StateHandler>
    );
};

export const JobDescriptionPageSkeleton: FC = () => {
    return (
        <Paper>
            <Stack direction={'row'}>
                <Stack p={2} gap={2} flex={1} component={Paper}>
                    <Skeleton variant='text' height={36} />
                    <Skeleton variant='text' height={24} />
                    <Skeleton variant='text' height={24} />
                </Stack>
                <Stack p={2} gap={2} flex={3} justifyContent={'flex-end'} component={Paper}>
                    <Skeleton variant='text' height={24} width={'90%'} />
                    <Skeleton variant='text' height={24} width={'20%'} />
                    <Skeleton variant='text' height={24} width={'40%'} />
                </Stack>
            </Stack>
        </Paper>
    );
};
