import { Divider, IconButton, Stack, StackProps, Typography } from '@mui/material';
import { ArrowDown02Icon, ArrowUp02Icon } from 'hugeicons-react';
import { FC, PropsWithChildren, useState } from 'react';

type ToggleSectionProps = StackProps & {
    labelOpen: string;
    labelClose: string;
};

export const ToggleSection: FC<PropsWithChildren<ToggleSectionProps>> = ({ labelOpen, labelClose, children, ...stackProps }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Stack flex={isOpen ? 1 : 0} gap={1} {...stackProps}>
            <Divider sx={{ width: '100%' }}>
                <IconButton
                    onClick={() => setIsOpen(!isOpen)}
                    edge='end'
                    size='small'
                    sx={{ borderRadius: 1, padding: 1 }}
                    aria-label={isOpen ? labelOpen : labelClose}
                >
                    <Typography variant='body1'>{isOpen ? labelOpen : labelClose}</Typography>
                    {isOpen ? <ArrowUp02Icon /> : <ArrowDown02Icon />}
                </IconButton>
            </Divider>
            {isOpen && children}
        </Stack>
    );
};
