import { StepperValues } from '@/components/stepper-form/StepperForm.util';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { useSideBar } from '@/hooks/layout/Sidebar.hook';
import { Button, ButtonProps, Paper, Stack } from '@mui/material';
import { StepperBreadCrumb } from '@/components/stepper-breadcrumb/StepperBreadcrumb';
import { useTranslation } from 'react-i18next';

export type StepForm = {
    stepComponent: ReactElement;
    stepName: string;
    stepTranslationKey: string;
};
type StepperFormProps = {
    steps: StepForm[];
    stepperValues: StepperValues;
    onNextStep: () => void;
    onSave: () => void;
    customButtonConfigs?: StepperButtonConfigs;
};

/**
 * The StepperForm component is a wrapper component that allows you to create a multi-step form on a single page.
 * @param steps is an array of Step objects that define the steps in the stepper.
 * @param stepperValues is a hook that provides the current step, next step, and previous step functions, as well as other values.
 * @param onNextStep is a callback function that is called when the user clicks the next button.
 * @param onSave is a callback function that is called when the user clicks the save button.
 * @param children is an optional prop that allows you to add custom buttons or components to the stepper.
 * @param customButtonConfigs is an optional prop that allows you to customize the buttons in the stepper.
 */
export const StepperForm: FC<PropsWithChildren<StepperFormProps>> = ({ steps, stepperValues, customButtonConfigs, onNextStep, onSave, children }) => {
    const { currentStep } = stepperValues;
    const { drawerWidth } = useSideBar();

    return (
        <>
            <Stack
                overflow={'auto'}
                flex={1}
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
                mb={2}
            >
                {currentStep?.stepComponent}
            </Stack>

            <Paper
                square
                component='footer'
                sx={{
                    mb: -2,
                    mx: -2,
                    width: `calc(100vw - ${drawerWidth}px)`,
                }}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} p={2}>
                    <StepperBreadCrumb steps={steps} currentStep={currentStep} />
                    <StepperButtons customButtonConfigs={customButtonConfigs} onNextStep={onNextStep} onSave={onSave} stepperValues={stepperValues}>
                        {children}
                    </StepperButtons>
                </Stack>
            </Paper>
        </>
    );
};

export type CustomStepperButtonConfig = ButtonProps & {
    customComponent?: ReactElement;
};

export type StepperButtonConfigs = {
    backButton?: CustomStepperButtonConfig;
    nextButton?: CustomStepperButtonConfig;
    saveButton?: CustomStepperButtonConfig;
};

type StepperButtonsType = {
    customButtonConfigs?: StepperButtonConfigs;
    onSave: () => void;
    stepperValues: StepperValues;
    onNextStep: () => void;
};

const StepperButtons: FC<PropsWithChildren<StepperButtonsType>> = ({ customButtonConfigs = {}, onSave, stepperValues, onNextStep, children }) => {
    const { t } = useTranslation();
    const { onPrevStep, showBackButton, showNextButton, showSaveButton } = stepperValues;

    const buttons = [
        {
            show: showBackButton,
            config: customButtonConfigs.backButton,
            defaultLabel: t('stepper.back'),
            defaultAction: onPrevStep,
            name: 'back',
        },
        {
            show: showNextButton,
            config: customButtonConfigs.nextButton,
            defaultLabel: t('stepper.next'),
            defaultAction: onNextStep,
            name: 'next',
        },
        {
            show: showSaveButton,
            config: customButtonConfigs.saveButton,
            defaultLabel: t('stepper.save'),
            defaultAction: onSave,
            name: 'save',
        },
    ];

    return (
        <Stack direction={'row'} gap={1}>
            {children}
            {buttons
                .filter(button => button.show)
                .map(
                    button =>
                        button.config?.customComponent ?? (
                            <Button key={button.name} onClick={button.config?.onClick ?? button.defaultAction} {...button.config}>
                                {button.config?.children ?? button.defaultLabel}
                            </Button>
                        ),
                )}
        </Stack>
    );
};
