import { expenseCategoryGroupApi } from '@/api/expense-category-group/ExpenseCategoryGroup.api';
import {
    CreateExpenseCategoryGroupMutation,
    ExpenseCategoryGroup,
    UpdateExpenseCategoryGroupMutation,
} from '@/domain/expense-category-group/ExpenseCategoryGroup.model';

export const getAllExpenseCategoryGroups = (): Promise<ExpenseCategoryGroup[]> => {
    return expenseCategoryGroupApi.getAllExpenseCategoryGroups();
};

export const createExpenseCategoryGroup = (mutation: CreateExpenseCategoryGroupMutation): Promise<ExpenseCategoryGroup> => {
    return expenseCategoryGroupApi.createExpenseCategoryGroup(mutation);
};

export const updateExpenseCategoryGroup = (mutation: UpdateExpenseCategoryGroupMutation): Promise<ExpenseCategoryGroup> => {
    return expenseCategoryGroupApi.updateExpenseCategoryGroup(mutation);
};

export const deleteExpenseCategoryGroup = (expenseCategoryGroupId: number): Promise<void> => {
    return expenseCategoryGroupApi.deleteExpenseCategoryGroup(expenseCategoryGroupId);
};
