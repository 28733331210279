import { useBreakPoints } from '@/components/use-break-points/useBreakPoints';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { FeedbackSummaryPrivateQuestion } from '@/page/review/employee-review-summary-feedback-form/FeedbackSummaryPrivateQuestion';
import {
    PrivateFeedbackItemsType,
    QuestionFeedbackFormType,
    SummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { linkRef } from '@/utils/dom.util';
import { Box, Stack } from '@mui/material';
import { FC, MutableRefObject, useRef } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type PrivateSummaryFeedbackFormProps = {
    isFormDisabled: boolean;
    scrollRef: MutableRefObject<HTMLDivElement | undefined>;
    onSaveItem?: (item: QuestionFeedbackFormType | SummaryFeedbackSkillsFormType) => Promise<void>;
    expandAll: boolean;
};
export const PrivateSummaryFeedbackFormStep: FC<PrivateSummaryFeedbackFormProps> = ({ isFormDisabled, scrollRef, onSaveItem, expandAll }) => {
    const itemsRefs = useRef<HTMLDivElement[]>([]);
    const { isMobile } = useBreakPoints();

    const { control } = useFormContext<PrivateFeedbackItemsType>();
    const { fields } = useFieldArray<PrivateFeedbackItemsType>({
        control,
        name: 'summaryPrivateFeedbackItems',
    });

    const filteredFields = fields.filter(item => item?.type === 'PRIVATE_EMPLOYEE_QUESTION' || item?.type === 'PRIVATE_MANAGER_QUESTION');

    if (!filteredFields.length) {
        return <></>;
    }

    return (
        <ContentContainer>
            <Box ref={scrollRef} />
            <Stack direction='column' sx={{ pb: isMobile ? 0 : 5 }}>
                <Stack direction='column' gap={2} sx={{ width: { lg: '1000px', xs: '100%' } }} alignSelf={'center'}>
                    <Stack spacing={2} direction='column'>
                        {filteredFields.map((item, index) => (
                            <Controller
                                key={item.id}
                                name={`summaryPrivateFeedbackItems.${index}`}
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <FeedbackSummaryPrivateQuestion
                                        innerRef={linkRef(index, itemsRefs)}
                                        expandAll={expandAll}
                                        feedbackSummaryQuestionForm={value}
                                        disabled={isFormDisabled}
                                        error={error}
                                        onUpdate={value => {
                                            onChange(value);
                                            onSaveItem?.(value).catch(console.error);
                                        }}
                                    />
                                )}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </ContentContainer>
    );
};
