import { StateHandler } from '@/components/state-handler/StateHandler';
import { ThirdParty, ThirdPartyMutation } from '@/domain/third-party/ThirdParty.model';
import { createThirdParty, updateThirdParty } from '@/domain/third-party/ThirdParty.service';
import { useGetThirdParty } from '@/hooks/third-party/ThirdParty.hook';
import { handleError } from '@/utils/api.util';
import { toDate } from '@/utils/datetime.util';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ThirdPartyFormValues, ThirdPartySettingForm } from '@/page/setting/third-party/third-party-setting-page/ThirdPartySettingForm';

export const ThirdPartySettingPage: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const thirdPartyId = params.thirdPartyId ? Number(params.thirdPartyId) : undefined;

    const { data: thirdParty, isLoading, isError, error } = useGetThirdParty(thirdPartyId);

    const goToDetail = (id: number) => {
        navigate(`/settings/third-parties/${id}`);
    };

    const handleSave = async ({ publicApiIntegration, integrations, ...restData }: ThirdPartyFormValues): Promise<ThirdParty | undefined> => {
        // Convert in mutation format and remove null values
        const data: ThirdPartyMutation = {
            ...restData,
            integrations: integrations.map(i => ({ ...i, id: i.id ?? undefined })),
            publicApiIntegration: publicApiIntegration
                ? {
                      ...publicApiIntegration,
                      expireAt: toDate(publicApiIntegration.expireAt),
                  }
                : undefined,
        };

        try {
            if (thirdParty) {
                // We are returning the updated third party to get the id to generate the api key
                return await updateThirdParty(thirdParty.id, data);
            } else {
                const thirdPartyCreated = await createThirdParty(data);
                goToDetail(thirdPartyCreated.id);
                return thirdPartyCreated;
            }
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <ThirdPartySettingForm thirdParty={thirdParty} onSave={handleSave} />
        </StateHandler>
    );
};
