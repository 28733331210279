import {
    countPendingLeaveRequests,
    getLeaveRequestById,
    getPendingLeaveRequests,
    getUserLeaveRequests,
    searchLeaveRequests,
} from '@/domain/leave-request/LeaveRequest.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { getNull } from '@/utils/object.util';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const leaveRequestKeys = createQueryKeys('leaveRequests', {
    search: (...params: Parameters<typeof searchLeaveRequests>) => ({
        queryKey: params,
    }),
    getEmployeeLeaveRequests: (...params: Parameters<typeof getUserLeaveRequests>) => ({
        queryKey: params,
    }),
    pending: {
        queryKey: getNull(),
    },
    countPending: {
        queryKey: getNull(),
    },
    getById: (...params: Parameters<typeof getLeaveRequestById>) => ({
        queryKey: params,
    }),
});

export const useGetLeaveRequests = createQueryHook(leaveRequestKeys.search, searchLeaveRequests);

export const useGetEmployeeLeaveRequests = createRequiredParamsQueryHook(leaveRequestKeys.getEmployeeLeaveRequests, getUserLeaveRequests);

export const useGetPendingLeaveRequests = createQueryHook(leaveRequestKeys.pending, getPendingLeaveRequests);

export const useCountPendingLeaveRequests = createQueryHook(leaveRequestKeys.countPending, countPendingLeaveRequests);

export const useGetLeaveRequest = createRequiredParamsQueryHook(leaveRequestKeys.getById, getLeaveRequestById);
