import * as yup from 'yup';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { ExpenseCategoryArray } from '@/domain/expense-category/ExpenseCategory.model';
import { UserLanguage } from '@/utils/language.util';

const MAX_VALUE_LIMIT = 999999;

export const getPresetSchema = (translationLanguage: UserLanguage) => {
    return yup.object().shape({
        id: yup.number(),
        localId: yup.string().required(),
        name: getLabelFormSchema(translationLanguage),
        amount: yup.number().required().min(0).max(MAX_VALUE_LIMIT),
    });
};

const getPresetsSchema = (translationLanguage: UserLanguage) => {
    return yup.array().of(getPresetSchema(translationLanguage)).default([]);
};

export const getExpenseCategoryFormSchema = (translationLanguage: UserLanguage) => {
    return yup.object().shape({
        name: getLabelFormSchema(translationLanguage),
        categoryGroupId: yup.number().nullable(),
        expenseTaxId: yup.number(),
        mandatoryDescription: yup.boolean().required(),
        type: yup.string().oneOf(ExpenseCategoryArray).required(),
        maxValue: yup.number().nullable().min(0).max(MAX_VALUE_LIMIT),
        presets: getPresetsSchema(translationLanguage),
    });
};

export type ExpenseCategoryPresetFormValues = yup.InferType<ReturnType<typeof getPresetSchema>>;
export type ExpenseCategoryFormValues = yup.InferType<ReturnType<typeof getExpenseCategoryFormSchema>>;
