import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { AsyncSelectFilter, FilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.util';
import { getUserEmploymentStatusTranslationKey } from '@/domain/employee/Employee.service';
import { EmploymentStatus, NOT_TERMINATED_EMPLOYMENT_STATUSES } from '@/domain/employment/Employment.model';
import { SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { getEnumFieldTypeOptions } from '@/domain/section-setting/Section.service';
import { useGetAreas } from '@/hooks/area/Area.hook';
import { useGetDepartmentsNodes } from '@/hooks/department/Department.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetJobFamilies } from '@/hooks/job-family/JobFamily.hook';
import { useGetJobs } from '@/hooks/job/Job.hook';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { useGetLocations } from '@/hooks/location/Location.hook';
import { convertEnumToOptions } from '@/utils/enum.util';
import { getLabelTranslation } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';

export const useFiltersDirectory = (filterList: FilterDirectoryType[]): { filters: FilterType[] } => {
    const filters = filterList.map(filterType => {
        const filterHook = filterHooksDirectory[filterType];
        return filterHook();
    });

    return { filters };
};

type FilterDirectoryType = 'LOCATION' | 'GENDER' | 'JOB' | 'JOB_FAMILY' | 'EMPLOYEE' | 'MANAGER' | 'EMPLOYMENT_STATUS' | 'DEPARTMENT' | 'AREA' | 'LEAVE_TYPE';

const useLocationFilter = (): FilterType => {
    const { refetch } = useGetLocations(undefined, { enabled: false });

    return {
        key: 'locationIds',
        filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_LOCATION' }),
        type: 'multi-select',
        selectMode: 'ASYNC',
        fetchOptions: async () => {
            const { data: locations = [] } = await refetch();
            return locations.map(location => ({
                label: location.name,
                value: location.id,
            }));
        },
        rule: 'EQUALS',
        availableRules: [],
    } satisfies AsyncSelectFilter;
};

const useJobFilter = (): FilterType => {
    const { refetch } = useGetJobs(undefined, { enabled: false });
    return {
        key: 'jobIds',
        filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_JOB' }),
        type: 'multi-select',
        selectMode: 'ASYNC',
        fetchOptions: async () => {
            const { data: jobs = [] } = await refetch();
            return jobs.map(job => ({
                label: getLabelTranslation(job.name),
                value: job.id,
            }));
        },
        rule: 'EQUALS',
        availableRules: [],
    } satisfies AsyncSelectFilter;
};

const useJobFamilyFilter = (): FilterType => {
    const { refetch } = useGetJobFamilies(undefined, { enabled: false });
    return {
        key: 'jobFamilyIds',
        filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_JOB_FAMILY' }),
        type: 'multi-select',
        selectMode: 'ASYNC',
        fetchOptions: async () => {
            const { data: jobFamilies = [] } = await refetch();
            return jobFamilies.map(jobFamily => ({
                label: jobFamily.name,
                value: jobFamily.id,
            }));
        },
        rule: 'EQUALS',
        availableRules: [],
    } satisfies AsyncSelectFilter;
};

const useEmployeeFilter = (): FilterType => {
    const { refetch } = useGetEmployees(
        {
            statuses: NOT_TERMINATED_EMPLOYMENT_STATUSES,
        },
        { enabled: false },
    );

    return {
        filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE' }),
        type: 'multi-select',
        selectMode: 'ASYNC',
        fetchOptions: async () => {
            const { data: employees = [] } = await refetch();
            return employees.map(employee => ({
                label: employee.displayName,
                value: employee.id,
            }));
        },
        key: 'employeeIds',
        rule: 'EQUALS',
        availableRules: [],
    };
};

const useManagerFilter = (): FilterType => {
    const { refetch } = useGetEmployees(
        {
            statuses: NOT_TERMINATED_EMPLOYMENT_STATUSES,
        },
        { enabled: false },
    );
    return {
        key: 'managerIds',
        filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_MANAGER' }),
        type: 'multi-select',
        selectMode: 'ASYNC',
        fetchOptions: async () => {
            const { data: employees = [] } = await refetch();
            return employees.map(employee => ({
                label: employee.displayName,
                value: employee.id,
            }));
        },
        rule: 'EQUALS',
        availableRules: [],
    } satisfies AsyncSelectFilter;
};

const useDepartmentFilter = (): FilterType => {
    const { refetch } = useGetDepartmentsNodes(undefined, { enabled: false });
    return {
        key: 'departmentIds',
        filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_DEPARTMENT' }),
        type: 'tree-multi-select',
        selectMode: 'ASYNC',
        fetchOptions: async () => {
            const { data: departmentsNodes = [] } = await refetch();
            return mapDepartmentNodesToTreeSelectFilterOptions(departmentsNodes);
        },
        rule: 'EQUALS',
        availableRules: [],
    };
};

export const useEnumFilter = (fieldType: SectionFieldDefinition['fieldType']): FilterType => {
    return {
        key: fieldType,
        filterName: getFieldDefinitionTranslation({ fieldType }),
        type: 'multi-select',
        selectMode: 'SYNC',
        rule: 'EQUALS',
        availableRules: [],
        options: getEnumFieldTypeOptions(fieldType),
    } satisfies SelectFilter;
};

const useGenderFilter = (): FilterType => {
    return {
        ...useEnumFilter('EMPLOYEE_GENDER'),
        // TODO remove this line, hack to avoid breaking change
        key: 'gender',
    };
};

const useEmploymentStatusFilter = (): FilterType => {
    const { t } = useTranslation();
    return {
        filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_STATUS' }),
        type: 'multi-select',
        options: convertEnumToOptions(EmploymentStatus, key => t(getUserEmploymentStatusTranslationKey(), { context: key })),
        selectMode: 'SYNC',
        key: 'employmentStatuses',
        rule: 'EQUALS',
        availableRules: [],
    } satisfies SelectFilter;
};

const useAreaFilter = (): FilterType => {
    const { t } = useTranslation();
    const { refetch } = useGetAreas({ locationIds: [] }, { enabled: false });

    return {
        key: 'areaIds',
        filterName: t('filters_bar.filter_name.area'),
        type: 'multi-select',
        selectMode: 'ASYNC',
        fetchOptions: async () => {
            const { data: areas = [] } = await refetch();
            return areas.map(area => ({
                label: area.name,
                value: area.id,
            }));
        },
        rule: 'EQUALS',
        availableRules: [],
    } satisfies AsyncSelectFilter;
};

const useLeaveTypeFilter = (): FilterType => {
    const { refetch } = useGetLeaveTypes(undefined, { enabled: false });
    return {
        filterName: getFieldDefinitionTranslation({ fieldType: 'LEAVE_TYPE_POLICY_LEAVE_TYPE_TITLE' }),
        type: 'multi-select',
        selectMode: 'ASYNC',
        fetchOptions: async () => {
            const { data: leaveTypes = [] } = await refetch();
            return leaveTypes.map(leaveType => ({
                label: getLabelTranslation(leaveType.name),
                value: leaveType.id,
            }));
        },
        key: 'leaveTypeIds',
        rule: 'EQUALS',
        availableRules: [],
    } satisfies AsyncSelectFilter;
};

type FilterHook = () => FilterType;

const filterHooksDirectory: Record<FilterDirectoryType, FilterHook> = {
    LOCATION: useLocationFilter,
    JOB: useJobFilter,
    JOB_FAMILY: useJobFamilyFilter,
    EMPLOYEE: useEmployeeFilter,
    MANAGER: useManagerFilter,
    EMPLOYMENT_STATUS: useEmploymentStatusFilter,
    DEPARTMENT: useDepartmentFilter,
    AREA: useAreaFilter,
    LEAVE_TYPE: useLeaveTypeFilter,
    GENDER: useGenderFilter,
};
