import { expenseSettingApi } from '@/api/expense-setting/ExpenseSetting.api';
import { ExpenseSetting, ExpenseSettingUpdateMutation } from '@/domain/expense-setting/ExpenseSetting.model';

export const getExpenseSetting = (): Promise<ExpenseSetting> => {
    return expenseSettingApi.getExpenseSetting();
};

export const updateExpenseSetting = (params: { id: number; mutation: ExpenseSettingUpdateMutation }): Promise<ExpenseSetting> => {
    return expenseSettingApi.updateExpenseSetting(params.id, params.mutation);
};
