import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { Stack } from '@mui/material';
import { FC } from 'react';

import { Employee } from '@/domain/employee/Employee.model';

type EmployeeReviewDialogHeaderProps = {
    employee: Employee;
};

export const EmployeeReviewDialogHeader: FC<EmployeeReviewDialogHeaderProps> = ({ employee }) => {
    return (
        <Stack gap={1} direction='row' alignItems='center'>
            {employee && (
                <>
                    <EmployeeAvatar employeeAvatar={employee} />
                    {employee.displayName}
                    <b>{employee.planningPositions?.[0]?.name}</b>
                </>
            )}
        </Stack>
    );
};
