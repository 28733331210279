import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { Box, Button, Divider, Paper, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveCreateMutation, ObjectiveUpdateMutation } from '@/domain/objective/Objective.model';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import {
    archiveEmployeeReviewAction,
    createEmployeeReviewActions,
    deleteEmployeeReviewAction,
    unarchiveEmployeeReviewAction,
    updateEmployeeReviewAction,
} from '@/domain/employee-review-action/EmployeeReviewAction.service';
import { EmployeeReview, EmployeeReviewReviewerSearch } from '@/domain/employee-review/EmployeeReview.model';
import { CAN_CREATE_ACTIONS_EMPLOYEE_REVIEW_STATUS_ARRAY, EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { AddIndividualObjectiveDialog, IndividualObjectiveDialogFormType } from '@/page/objective/add-individual-objective-dialog/AddIndividualObjectiveDialog';
import { Target01Icon as ObjectivesIcon } from 'hugeicons-react';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { getAppConfig } from '@/config/config';
import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { formatDate } from 'date-fns';
import { getLabelTranslation } from '@/utils/language.util';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';
import { FeedbackObjectivesActionFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { useFeedbackObjectiveActionsMenuItems } from '@/page/review/employee-review-feedback-form/useFeedbackObjectiveActionsMenuItems';
import { useGetEmployeeReviewAsContributor } from '@/hooks/employee-review/EmployeeReview.hook';
import { useCurrentEmployee } from '@/stores/store';
import { isEmployeeManagerOfReview } from '@/domain/employee-review/EmployeeReview.service';

type FeedbackObjectiveActionsProps = {
    employeeId: number;
    disabled: boolean;
    refetchActions: () => void;
    employeeReview: EmployeeReview;
    actions: EmployeeReviewAction[];
    feedbackObjectiveActionsForm: FeedbackObjectivesActionFormType;
} & StackProps;

type ObjectiveActionDialogState = {
    open: boolean;
    activeObjectiveAction?: EmployeeReviewAction;
    employeeIds?: number[];
};

export const FeedbackObjectiveActions: FC<FeedbackObjectiveActionsProps> = ({
    employeeId,
    disabled,
    refetchActions,
    actions,
    employeeReview,
    feedbackObjectiveActionsForm,
    ...rest
}) => {
    const { t } = useTranslation();
    const currentEmployee = useCurrentEmployee();
    const [objectiveActionDialogState, setObjectiveActionDialogState] = useState<ObjectiveActionDialogState>({
        open: false,
        activeObjectiveAction: undefined,
        employeeIds: undefined,
    });

    const isCurrentEmployeeManagerOfReview = isEmployeeManagerOfReview(employeeReview, currentEmployee);
    const employeeReviewSearch: EmployeeReviewReviewerSearch = {
        reviewId: employeeReview.review?.id,
        statuses: CAN_CREATE_ACTIONS_EMPLOYEE_REVIEW_STATUS_ARRAY,
        contributorType: 'MANAGER',
    };
    const { data: employeeReviews = [], isLoading: isLoadingEmployeeReviews } = useGetEmployeeReviewAsContributor(employeeReviewSearch, {
        enabled: isCurrentEmployeeManagerOfReview && !!employeeReview.review?.id,
    });

    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const emptyState = (
        <Stack p={2}>
            <Typography variant={'body1'}>{t('reviews.write_feedback.objective_actions_empty_state')}</Typography>
        </Stack>
    );

    const resetObjectiveActionDialogState = () => {
        setObjectiveActionDialogState({
            open: false,
            activeObjectiveAction: undefined,
            employeeIds: undefined,
        });
    };
    const handleSave = async (formValues: IndividualObjectiveDialogFormType, employeeReviewId: number, objectiveId?: number) => {
        if (objectiveId) {
            const objectiveMutation = {
                ...formValues,
                assigneeId: formValues.assigneeIds[0],
                parentId: formValues.parentId ?? undefined,
            };
            await handleUpdateObjectiveAction(objectiveMutation, objectiveId);
        } else {
            const objectiveMutation = {
                ...formValues,
                parentId: formValues.parentId ?? undefined,
            };
            await handleCreateObjectiveAction(objectiveMutation, employeeReviewId);
        }
    };

    const handleCreateObjectiveAction = async (objectiveRequest: ObjectiveCreateMutation, employeeReviewId: number) => {
        const createActionMutation = {
            objectiveCreateMutation: objectiveRequest,
            employeeReviewIds: getEmployeeReviewIds(objectiveRequest, employeeReviewId),
        };
        try {
            await createEmployeeReviewActions(createActionMutation);
            showSnackbar(t('reviews.write_feedback.objective_action_created'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const getEmployeeReviewIds = (objectiveRequest: ObjectiveCreateMutation, employeeReviewId: number) => {
        const canOnlyCreateForEmployee = !isCurrentEmployeeManagerOfReview || !employeeReviews.filter(er => er.employee.id !== employeeId).length;
        if (canOnlyCreateForEmployee) {
            return [employeeReviewId];
        } else {
            //we can create for multiple employees so we need to map from the employeeReviews
            const employeeIds = objectiveRequest.assigneeIds;
            return employeeReviews.filter(er => employeeIds.includes(er.employee.id)).map(er => er.id);
        }
    };

    const handleUpdateObjectiveAction = async (objectiveRequest: ObjectiveUpdateMutation, employeeReviewActionId: number) => {
        const updatedAction = {
            objectiveUpdateMutation: objectiveRequest,
        };

        try {
            await updateEmployeeReviewAction(employeeReviewActionId, updatedAction);
            showSnackbar(t('reviews.write_feedback.objective_action_updated'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDeleteObjective = async (employeeReviewActionId: number) => {
        try {
            await deleteEmployeeReviewAction(employeeReviewActionId);
            showSnackbar(t('reviews.write_feedback.objective_action_deleted'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const handleArchivedObjective = async (employeeReviewActionId: number) => {
        try {
            await archiveEmployeeReviewAction(employeeReviewActionId);
            showSnackbar(t('reviews.write_feedback.objective_action_archived'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const handleUnarchivedObjective = async (employeeReviewActionId: number) => {
        try {
            await unarchiveEmployeeReviewAction(employeeReviewActionId);
            showSnackbar(t('reviews.write_feedback.objective_action_unarchived'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const handleCreateObjectiveClick = () => {
        setObjectiveActionDialogState({
            open: true,
            activeObjectiveAction: undefined,
            employeeIds: employeeReviews.map(er => er.employee.id).filter(id => id !== employeeId),
        });
    };

    if (!objectiveSetting) {
        return <></>;
    }

    const actionsWithoutArchived = actions.filter(objectiveAction => objectiveAction.status !== 'ARCHIVED');
    const isEmpty = !actionsWithoutArchived.length;
    return (
        <Stack gap={isEmpty ? 0 : 2} component={Paper} p={2} {...rest}>
            <Stack>
                <Typography variant='h2'>{t('reviews.write_feedback.create_objective_action_title')}</Typography>

                {feedbackObjectiveActionsForm.instruction && (
                    <RichTextTypography variant='body2'>{getLabelTranslation(feedbackObjectiveActionsForm.instruction)}</RichTextTypography>
                )}
            </Stack>

            <StateHandler
                isLoading={isLoadingObjectiveSetting}
                isError={isErrorObjectiveSetting}
                error={errorObjectiveSetting}
                emptyStateComponent={emptyState}
                isEmpty={isEmpty}
            >
                <Stack>
                    <Box display={'grid'} gridTemplateColumns={'70% 1fr 1fr 5%'} gridTemplateRows={'auto'} rowGap={2} p={1}>
                        <Box display={'contents'}>
                            <Typography variant='body1bold'>{t('reviews.write_feedback.create_objective_name_header_title')}</Typography>
                            <Typography variant='body1bold' justifySelf={'center'}>
                                {t('reviews.write_feedback.create_objective_action_create_by_header_title')}
                            </Typography>
                            <Typography variant='body1bold' justifySelf={'center'}>
                                {t('reviews.write_feedback.create_objective_action_due_date_header_title')}
                            </Typography>
                            {!disabled && <Box />}
                        </Box>
                        <Divider sx={{ gridColumn: 'span 4' }} />
                        {actionsWithoutArchived.map(objectiveAction => (
                            <EmployeeReviewFeedbackObjectivesActionItem
                                key={objectiveAction.id}
                                objectiveAction={objectiveAction}
                                disabled={disabled}
                                onEdit={() => {
                                    setObjectiveActionDialogState({
                                        open: true,
                                        activeObjectiveAction: objectiveAction,
                                    });
                                }}
                                onDelete={() => {
                                    handleDeleteObjective(objectiveAction.id);
                                }}
                                onArchived={() => {
                                    handleArchivedObjective(objectiveAction.id);
                                }}
                                onUnarchived={() => {
                                    handleUnarchivedObjective(objectiveAction.id);
                                }}
                                employeeReviewStatus={employeeReview.status}
                            />
                        ))}
                    </Box>
                </Stack>
            </StateHandler>

            <Button fullWidth onClick={handleCreateObjectiveClick} disabled={disabled || isLoadingEmployeeReviews}>
                {t('reviews.write_feedback.create_objective_action_button')}
            </Button>

            {objectiveActionDialogState.open && (
                <AddIndividualObjectiveDialog
                    open={true}
                    activeObjective={objectiveActionDialogState.activeObjectiveAction?.objective}
                    //if there is other possible assignees, then we want to allow the user to select them
                    disabledEmployeeSelection={true}
                    onSave={formValues => {
                        handleSave(formValues, employeeReview.id, objectiveActionDialogState.activeObjectiveAction?.id);
                    }}
                    employeeId={employeeId}
                    otherPossibleEmployeeIds={objectiveActionDialogState.employeeIds}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    onClose={() => {
                        resetObjectiveActionDialogState();
                    }}
                />
            )}
        </Stack>
    );
};

type EmployeeReviewFeedbackObjectivesActionItemProps = {
    objectiveAction: EmployeeReviewAction;
    employeeReviewStatus: EmployeeReviewStatus;
    disabled: boolean;
    onEdit: () => void;
    onDelete: () => void;
    onArchived: () => void;
    onUnarchived: () => void;
};
const EmployeeReviewFeedbackObjectivesActionItem: FC<EmployeeReviewFeedbackObjectivesActionItemProps> = ({
    disabled,
    onEdit,
    onDelete,
    onArchived,
    onUnarchived,
    objectiveAction,
    employeeReviewStatus,
}) => {
    const items = useFeedbackObjectiveActionsMenuItems({
        onEdit,
        onDelete,
        onArchived,
        onUnarchived,
        actionStatus: objectiveAction.status,
        employeeReviewStatus,
    });
    const objective = objectiveAction.objective;
    const theme = useTheme();

    return (
        <Box
            display={'contents'}
            onClick={disabled ? undefined : onEdit}
            sx={{
                '& > *': {
                    backgroundColor: theme.palette.grey[50],
                    cursor: disabled ? 'default' : 'pointer',
                },

                // When any child is hovered
                '&:hover > *': {
                    backgroundColor: theme.palette.grey[200],
                },

                '& > :first-child': {
                    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`, // top left and bottom left corners rounded
                },
                '& > :last-child': {
                    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`, // top right and bottom right corners rounded
                },
            }}
        >
            <Stack direction={'row'} gap={1} p={1} alignContent={'flex-start'}>
                <Box>
                    <ObjectivesIcon />
                </Box>

                <Stack direction={'column'}>
                    <Typography variant='h3'>{objective?.name}</Typography>
                    <Typography variant='body1bold'>{getLabelTranslation(objective?.category?.name)}</Typography>
                    <Typography variant='body2'>{objective?.description}</Typography>
                </Stack>
            </Stack>

            <Box p={1} justifyItems={'center'} alignContent={'center'}>
                <EmployeeAvatar employeeAvatar={objective.createdBy} />
            </Box>

            <Typography variant='body2' py={1} textAlign={'center'} alignContent={'center'}>
                {formatDate(objective?.dueDate, getAppConfig().DATE_FORMAT.STANDARD)}
            </Typography>

            <Box alignContent={'center'}>{!disabled && <BasicMenu items={items} />}</Box>
        </Box>
    );
};
