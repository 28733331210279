import { EmployeeSectionDocumentLink } from '@/components/section/EmployeeSectionDocumentLink/EmployeeSectionDocumentLink';
import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { SectionField } from '@/components/section/types';
import { StackedAvatars } from '@/domain-ui/employee/stacked-avatar/StackedAvatars';
import { getEmployeeSectionFieldDocumentUrl } from '@/domain/employee-section/EmployeeSection.service';
import { differenceInYears, formatInDefaultDate, getCurrentLocalDate } from '@/utils/datetime.util';

import { getLabelTranslation } from '@/utils/language.util';
import { LinkProps, Stack, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';
import i18next from 'i18next';
import { getEnumFieldValueTranslation } from '@/domain/section-setting/Section.service';
import { formatIBAN, formatPhoneNumber } from '@/utils/strings.util';

export type SectionFieldComponentProps = {
    field: SectionField;
    isPending?: boolean;
};

export const SectionFieldComponent: FC<SectionFieldComponentProps> = ({ field, isPending }) => {
    const pendingTypographyProps: TypographyProps = isPending ? { color: 'warning' } : {};
    switch (field.valueType) {
        case 'EMPLOYEE':
            return <StackedAvatars employeeAvatars={field[getFieldValueProperty(field.valueType)] ?? []} />;
        case 'CUSTOM_LIST_ITEM': {
            const label = field[getFieldValueProperty(field.valueType)]?.[0]?.label;
            return <TextField value={getLabelTranslation(label)} {...pendingTypographyProps} />;
        }
        case 'CUSTOM_MULTI_LIST_ITEM':
            return (
                <TextField
                    value={field[getFieldValueProperty(field.valueType)]?.map(item => getLabelTranslation(item.label)).join(', ')}
                    {...pendingTypographyProps}
                />
            );

        case 'DATE':
            if (field.fieldType === 'EMPLOYEE_BIRTH_DATE') {
                return <BirthdayField birthdayDate={field[getFieldValueProperty(field.valueType)]} {...pendingTypographyProps} />;
            }
            return <DateField value={field[getFieldValueProperty(field.valueType)]} {...pendingTypographyProps} />;
        case 'NUMBER':
            return <TextField value={field[getFieldValueProperty(field.valueType)]?.toLocaleString()} {...pendingTypographyProps} />;
        case 'STRING':
        case 'AVS_NUMBER':
        case 'EMAIL':
        case 'AUTO_INCREMENT':
            return <TextField value={field[getFieldValueProperty(field.valueType)]} {...pendingTypographyProps} />;
        case 'PHONE_NUMBER': {
            const phoneNumber = formatPhoneNumber(field[getFieldValueProperty(field.valueType)] ?? '');
            return <TextField value={phoneNumber} {...pendingTypographyProps} />;
        }
        case 'IBAN_NUMBER':
            return <TextField value={formatIBAN(field[getFieldValueProperty(field.valueType)] ?? '')} {...pendingTypographyProps} />;
        case 'ENUM':
            return (
                <TextField
                    value={getEnumFieldValueTranslation(field.fieldType, field[getFieldValueProperty(field.valueType)] ?? '')}
                    {...pendingTypographyProps}
                />
            );
        case 'COUNTRY':
            return <TextField value={field[getFieldValueProperty(field.valueType)]?.label ?? ''} {...pendingTypographyProps} />;
        case 'SECTION_FIELD_DOCUMENT':
            return <DocumentField field={field} linkProps={{ color: pendingTypographyProps.color }} />;
        default:
            return undefined;
    }
};

type DateFieldProps = {
    value: Nullable<LocalDate>;
} & TypographyProps;

const DateField: FC<DateFieldProps> = ({ value, ...rest }) => {
    return <Typography {...rest}>{value ? formatInDefaultDate(value) : ''}</Typography>;
};

type TextFieldProps = {
    value: string | undefined;
} & TypographyProps;

const TextField: FC<TextFieldProps> = ({ value, ...rest }) => {
    return (
        <Typography title={value} variant='body1' noWrap {...rest}>
            {value ?? ''}
        </Typography>
    );
};

type BirthdayFieldProps = {
    birthdayDate: Nullable<LocalDate>;
} & TypographyProps;
const BirthdayField: FC<BirthdayFieldProps> = ({ birthdayDate, ...rest }) => {
    const getAgeDisplay = (birthdate: LocalDate): string => {
        return '(' + differenceInYears(getCurrentLocalDate(), birthdate) + ' ' + i18next.t('employee.years_old') + ')';
    };

    return (
        <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
            <DateField value={birthdayDate} variant='body1' {...rest} />
            <TextField value={birthdayDate ? getAgeDisplay(birthdayDate) : ''} {...rest} />
        </Stack>
    );
};

export interface DocumentFieldProps {
    field: SectionField;
    linkProps?: LinkProps;
}

export const DocumentField: FC<DocumentFieldProps> = ({ field, linkProps }) => {
    return (
        <Stack gap={1} direction={'row'} flexWrap={'wrap'}>
            {field.documents?.map(document => {
                return (
                    <EmployeeSectionDocumentLink
                        document={document}
                        onDownload={contentDisposition => getEmployeeSectionFieldDocumentUrl(document.id, contentDisposition)}
                        key={document.id}
                        {...linkProps}
                    />
                );
            })}
        </Stack>
    );
};
