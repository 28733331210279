import { expenseRequestApi } from '@/api/expense-request/ExpenseRequest.api';
import {
    CreateExpenseRequestMutation,
    ExpenseRequest,
    ExpenseRequestBatchMutation,
    ExpenseRequestDeclineMutation,
    ExpenseRequestSearch,
    GroupDateAttribute,
    GroupedAt,
    GroupedExpenseRequest,
    GroupedExpenseRequestSearch,
    GroupExpensesBy,
    UpdateExpenseRequestMutation,
} from '@/domain/expense-request/ExpenseRequest.model';
import groupBy from 'lodash.groupby';
import { sum } from '@/utils/math.util';
import { formatToLocalDate } from '@/utils/datetime.util';
import invariant from 'tiny-invariant';

export const searchExpenseRequests = (searchRequest: ExpenseRequestSearch): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.searchExpenseRequests(searchRequest);
};

export const searchGroupedExpenseRequests = async ({ groupBy, ...searchRequest }: GroupedExpenseRequestSearch): Promise<GroupedExpenseRequest<GroupedAt>[]> => {
    const expenseRequests = await expenseRequestApi.searchExpenseRequests(searchRequest);
    return groupExpenseRequests(expenseRequests, groupBy);
};

export const searchGroupedPendingExpenseRequests = async ({
    groupBy,
    ...searchRequest
}: GroupedExpenseRequestSearch): Promise<GroupedExpenseRequest<GroupedAt>[]> => {
    const expenseRequests = await expenseRequestApi.searchPendingExpenseRequests(searchRequest);
    return groupExpenseRequests(expenseRequests, groupBy);
};
export const searchGroupedApprovedExpenseRequests = async ({
    groupBy,
    ...searchRequest
}: GroupedExpenseRequestSearch): Promise<GroupedExpenseRequest<GroupedAt>[]> => {
    const expenseRequests = await expenseRequestApi.searchApprovedExpenseRequests(searchRequest);
    return groupExpenseRequests(expenseRequests, groupBy);
};

export const countExpenseRequests = async (searchRequest: ExpenseRequestSearch): Promise<number> => {
    const expenseRequests = await expenseRequestApi.searchExpenseRequests(searchRequest);
    return expenseRequests.length;
};

const getFirstRequest = (requests: ExpenseRequest[]): ExpenseRequest => {
    const firstRequest = requests.at(0);
    if (!firstRequest) {
        throw new Error('Error: No requests found');
    }
    return firstRequest;
};

const mapToGroupedExpenseRequest = <T extends GroupedAt>(requests: ExpenseRequest[], groupCriteria: T): GroupedExpenseRequest<T> => {
    return {
        ...groupCriteria,
        employee: requests[0].employee,
        status: requests[0].status,
        calculatedAmount: sum(requests.map(expense => expense.calculatedAmount)),
        quantity: requests.length,
        expenseRequests: requests,
    };
};

const groupExpenseRequestsByEmployee = (expenseRequests: ExpenseRequest[]): GroupedExpenseRequest<GroupedAt>[] => {
    const groupedByEmployee = groupBy(expenseRequests, request => request.employee.id);
    return Object.values(groupedByEmployee).map(requests => {
        return mapToGroupedExpenseRequest(requests, { groupedAt: undefined });
    });
};

const groupExpenseRequestsByEmployeeAndDateField = (expenseRequests: ExpenseRequest[], dateField: GroupDateAttribute): GroupedExpenseRequest<GroupedAt>[] => {
    const grouped = groupBy(expenseRequests, request => {
        const dateValue = request[dateField];
        invariant(dateValue);
        const localDate = formatToLocalDate(dateValue);
        return `${request.employee.id}_${localDate}`;
    });

    return Object.values(grouped).map(requests => {
        const firstRequest = getFirstRequest(requests);
        return {
            employee: firstRequest.employee,
            groupedAt: firstRequest[dateField],
            status: firstRequest.status,
            calculatedAmount: sum(requests.map(expense => expense.calculatedAmount)),
            quantity: requests.length,
            expenseRequests: requests,
        };
    });
};

export const groupExpenseRequests = (expenseRequests: ExpenseRequest[], groupBy: GroupExpensesBy): GroupedExpenseRequest<GroupedAt>[] => {
    switch (groupBy) {
        case 'submittedAt':
        case 'transmittedPaymentAt':
        case 'approvedAt':
            return groupExpenseRequestsByEmployeeAndDateField(expenseRequests, groupBy);
        default:
            return groupExpenseRequestsByEmployee(expenseRequests);
    }
};
export const getExpenseRequest = (expenseRequestId: number): Promise<ExpenseRequest> => {
    return expenseRequestApi.getExpenseRequest(expenseRequestId);
};

export const createExpenseRequest = (mutation: CreateExpenseRequestMutation): Promise<ExpenseRequest> => {
    return expenseRequestApi.createExpenseRequest(mutation);
};

export const updateExpenseRequest = (params: { expenseRequestId: number; mutation: UpdateExpenseRequestMutation }): Promise<ExpenseRequest> => {
    return expenseRequestApi.updateExpenseRequest(params.expenseRequestId, params.mutation);
};

export const cancelPendingExpenseRequests = (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.cancelPendingExpenseRequests(request);
};

export const cancelApprovedExpenseRequests = (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.cancelApprovedExpenseRequests(request);
};

export const submitExpenseRequests = (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.submitExpenseRequests(request);
};

export const approveExpenseRequests = (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.approveExpenseRequests(request);
};

export const declineExpenseRequests = (request: ExpenseRequestDeclineMutation): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.declineExpenseRequests(request);
};

export const declineApprovedExpenseRequests = (request: ExpenseRequestDeclineMutation): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.declineApprovedExpenseRequests(request);
};

export const transmitToPaymentExpenseRequests = (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.transmitToPaymentExpenseRequests(request);
};

export const undoPaymentExpenseRequests = (request: ExpenseRequestBatchMutation): Promise<ExpenseRequest[]> => {
    return expenseRequestApi.undoPaymentExpenseRequests(request);
};
