import { PageContainer } from '@/routes/PageContainer';
import { Navigate, RouteObject } from 'react-router';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { Suspense } from 'react';
import { Spinner } from '@/components/spinner/Spinner';
import { ExpensePendingLazyPage } from '@/page/expense/ExpensePendingLazyPage';
import { ExpenseToControlLazyPage } from '@/page/expense/ExpenseToControlLazyPage';
import { ExpensePendingEmployeeRequestsLazyPage } from '@/page/expense/ExpensePendingEmployeeRequestsLazyPage';
import { ExpenseToControlEmployeeRequestsLazyPage } from '@/page/expense/ExpenseToControlEmployeeRequestsLazyPage';
import { ExpensePaidEmployeeRequestsLazyPage } from '@/page/expense/ExpensePaidEmployeeRequestsLazyPage';
import { ExpensePaidLazyPage } from '@/page/expense/ExpensePaidLazyPage';

const config: RoutesConfig[] = [
    {
        path: 'pending',
        breadCrumb: [{ nameKey: 'pages.expenses_pending' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpensePendingLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'pending/:employeeId',
        breadCrumb: [{ nameKey: 'pages.expenses_pending' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpensePendingEmployeeRequestsLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'pending/:employeeId/:submittedAt',
        breadCrumb: [{ nameKey: 'pages.expenses_pending' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpensePendingEmployeeRequestsLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'to-control',
        breadCrumb: [{ nameKey: 'pages.expenses_to_control' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpenseToControlLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'to-control/:employeeId',
        breadCrumb: [{ nameKey: 'pages.expenses_to_control' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpenseToControlEmployeeRequestsLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'to-control/:employeeId/:approvedAt',
        breadCrumb: [{ nameKey: 'pages.expenses_to_control' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpenseToControlEmployeeRequestsLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'paid',
        breadCrumb: [{ nameKey: 'pages.expenses_paid' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpensePaidLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'paid/:employeeId',
        breadCrumb: [{ nameKey: 'pages.expenses_paid' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpensePaidEmployeeRequestsLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'paid/:employeeId/:transmittedPaymentAt',
        breadCrumb: [{ nameKey: 'pages.expenses_paid' }],
        component: (
            <Suspense fallback={<Spinner />}>
                <ExpensePaidEmployeeRequestsLazyPage />
            </Suspense>
        ),
    },
];

export const expenseRoutes: RouteObject[] = [
    {
        path: 'expenses',
        errorElement: <RouterBoundary />,
        children: [
            {
                path: '',
                element: <Navigate to='/expenses/pending' replace />,
            },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
