import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { isEqualSectionFieldValue, patchCountryField } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { EmployeeSectionField } from '@/domain/employee-section/EmployeeSection.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { EmployeePayrollProfileChange, PayrollSection, PayrollSectionRow } from '@/domain/payroll/Payroll.model';
import { markAsReviewed, unmarkAsReviewed } from '@/domain/payroll/Payroll.service';
import { SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { isSameFieldDefinition } from '@/domain/section-setting/Section.service';
import { useGetPayrollProfileChanges } from '@/hooks/payroll/Payroll.hook';
import { useLocalStorage } from '@/hooks/Storage.hook';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';
import { handleError } from '@/utils/api.util';
import { isDefined } from '@/utils/collections.util';
import { formatToLocalDate } from '@/utils/datetime.util';
import { Button, Chip, FormControlLabel, IconButton, Paper, Stack, Theme, Tooltip, useTheme } from '@mui/material';
import Switch from '@mui/material/Switch';
import { CellClassParams, ColDef, ICellRendererParams, RowSpanParams } from 'ag-grid-community';
import { FilterHorizontalIcon } from 'hugeicons-react';
import i18next from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

type PayrollProfilePageSettings = {
    showReviewed: boolean;
    showAllFields: boolean;
    changesOnEffectiveDate: boolean;
};
export const PayrollProfileChangesPage: FC = () => {
    const { t } = useTranslation();
    const [pageSettings, setPageSettings] = useLocalStorage<PayrollProfilePageSettings>('payroll-profile-settings', {
        showAllFields: true,
        showReviewed: true,
        changesOnEffectiveDate: false,
    });

    const agGridWrapper = useAgGridWrapper<EmployeePayrollProfileChangeRow>();
    const theme = useTheme();
    const { filters: availableFilters } = useFiltersDirectory(['DEPARTMENT', 'LOCATION', 'EMPLOYEE']);
    const [filters, setFilters] = useFiltersStorage('payroll-changes-filters', availableFilters);

    const { dateRange, dateRangeViewType, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'payroll-changes-date-range',
    });

    const search = {
        startDate: dateRange[0],
        endDate: dateRange[1],
        departmentIds: getFilterValueIdsByKey(filters, 'departmentIds') ?? [],
        employeeIds: getFilterValueIdsByKey(filters, 'employeeIds') ?? [],
        locationIds: getFilterValueIdsByKey(filters, 'locationIds') ?? [],
        changesOnEffectiveDate: pageSettings.changesOnEffectiveDate,
    };
    const { data: changes = [], refetch } = useGetPayrollProfileChanges(search, { enabled: !!filters.length });

    const columnDefs = getColumnDefs(theme);

    const { rows, isLoading: isLoadingRows } = useRows(changes, {
        excludeIdenticalFieldValues: !pageSettings.showAllFields,
        excludeReviewed: !pageSettings.showReviewed,
    });

    const onBtnExport = () =>
        agGridWrapper.gridRef.current?.api.exportDataAsExcel({
            // If we don't provide columnKeys, it will  add an empty column for row selection (I suppose)
            columnKeys: columnDefs.map(columnDef => columnDef.colId ?? columnDef.field).filter(isDefined),
        });

    const selectedRows = agGridWrapper?.selectedRows;

    const handleMarkAsReviewed = async (selectedRows: EmployeePayrollProfileChangeRow[]) => {
        try {
            const rows = selectedRows.filter(row => !row.reviewedAt);
            if (rows.length) {
                const markedReviewPromises = rows.map(row => {
                    return markAsReviewed({
                        employeeId: row.employee.id,
                        sectionType: row.section.sectionDefinition.type,
                        rowId: row.id,
                    });
                });
                await Promise.all(markedReviewPromises);
                refetch();
            }
            agGridWrapper?.gridRef.current?.api.deselectAll();
        } catch (error) {
            handleError(error);
        }
    };

    const handleUnmarkAsReviewed = async (selectedRows: EmployeePayrollProfileChangeRow[]) => {
        try {
            const rows = selectedRows.filter(row => row.reviewedAt);
            if (rows.length) {
                const unmarkedReviewPromises = rows.map(row => {
                    return unmarkAsReviewed({
                        employeeId: row.employee.id,
                        sectionType: row.section.sectionDefinition.type,
                        rowId: row.id,
                    });
                });

                await Promise.all(unmarkedReviewPromises);
                refetch();
            }
            agGridWrapper?.gridRef.current?.api.deselectAll();
        } catch (error) {
            handleError(error);
        }
    };

    const getPageSettingsMenuItems = () => {
        return [
            {
                title: t('payroll_profile_changes_page.show_reviewed'),
                onClick: () => {
                    setPageSettings(prevState => ({ ...prevState, showReviewed: !prevState.showReviewed }));
                },
                children: (
                    <FormControlLabel
                        control={<Switch checked={pageSettings.showReviewed} />}
                        label={t('payroll_profile_changes_page.show_reviewed')}
                        labelPlacement={'end'}
                    />
                ),
                disableCloseOnClick: true,
            },
            {
                title: t('payroll_profile_changes_page.show_identical_fields'),
                onClick: () => {
                    setPageSettings(prevState => ({ ...prevState, showAllFields: !prevState.showAllFields }));
                },
                children: (
                    <FormControlLabel
                        control={<Switch checked={pageSettings.showAllFields} />}
                        label={t('payroll_profile_changes_page.show_identical_fields')}
                        labelPlacement={'end'}
                    />
                ),
                disableCloseOnClick: true,
            },
            {
                title: t('payroll_profile_changes_page.changes_on_effective_date'),
                onClick: () => {
                    setPageSettings(prevState => ({
                        ...prevState,
                        changesOnEffectiveDate: !prevState.changesOnEffectiveDate,
                    }));
                },
                children: (
                    <FormControlLabel
                        control={<Switch checked={pageSettings.changesOnEffectiveDate} />}
                        label={t('payroll_profile_changes_page.changes_on_effective_date')}
                        labelPlacement={'end'}
                    />
                ),
                disableCloseOnClick: true,
            },
        ] satisfies BasicMenuItem[];
    };

    return (
        <Stack flex={1} gap={2}>
            <Stack component={Paper} direction='row' gap={1} alignItems='center' justifyContent='space-between' p={1}>
                <Stack direction='row' alignItems='flex-start' gap={1} flexWrap={'wrap'}>
                    <DateRangePicker
                        dates={dateRange}
                        onDatesChanged={onDateRangeChange}
                        defaultViewType={dateRangeViewType}
                        availableViews={['MONTH', 'RANGE']}
                    />
                    <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />
                </Stack>
                <Stack direction='row' gap={1} alignItems='center'>
                    <DatatableAdditionalAction quickFilter={agGridWrapper?.quickFilter} onBtnExport={onBtnExport} />
                    <BasicMenu
                        items={getPageSettingsMenuItems()}
                        button={
                            <IconButton
                                color='primary'
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'primary.main',
                                    borderRadius: 1,
                                }}
                                size='small'
                            >
                                <FilterHorizontalIcon />
                            </IconButton>
                        }
                    />
                </Stack>
            </Stack>
            <Stack flex={1}>
                <AgGridWrapper
                    initRef={agGridWrapper?.setGridRef}
                    gridId={'payroll-profile-changes'}
                    columnDefs={columnDefs}
                    defaultColDef={{ autoHeight: false }}
                    rowData={isLoadingRows ? [] : rows}
                    getRowId={({ data }) => getEmployeeProfileChangeRowId(data)}
                    disableAutoSize
                    rowSelection={{
                        mode: 'multiRow',
                        hideDisabledCheckboxes: true,
                        isRowSelectable: rowNode => rowNode.data?.rowSpan !== 0,
                    }}
                    selectionColumnDef={{
                        cellClass: ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) =>
                            data?.isLastRowInGroup ? ['last-spanning-cell', 'span-selection-cell'] : ['span-selection-cell'],
                    }}
                    loading={isLoadingRows}
                    toolbarActions={
                        <Stack direction='row' gap={1}>
                            <Button onClick={() => handleMarkAsReviewed(selectedRows)}>{t('payroll_profile_changes_page.mark_as_reviewed')}</Button>
                            <Button color='error' onClick={() => handleUnmarkAsReviewed(selectedRows)}>
                                {t('payroll_profile_changes_page.unmark_as_reviewed')}
                            </Button>
                        </Stack>
                    }
                />
            </Stack>
        </Stack>
    );
};

type RowValue = {
    previousValue?: EmployeeSectionField;
    currentValue: EmployeeSectionField;
} & Omit<PayrollSectionRow, 'fields'>;

type EmployeePayrollProfileChangeRow = {
    employee: EmployeeAvatar;
    section: PayrollSection;
    sectionFieldDefinition: SectionFieldDefinition;
    rowSpan: number;
    isLastRowInGroup: boolean;
} & RowValue;

const getColumnDefs = (theme: Theme): ColDef<EmployeePayrollProfileChangeRow>[] => {
    const rowSpan = (params: RowSpanParams<EmployeePayrollProfileChangeRow, unknown>) => params.data?.rowSpan ?? 1;

    return [
        {
            flex: 1,
            field: 'employee',
            type: 'employee',
            sort: 'asc',
            cellClass: ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ? ['span-cell', 'flex-spanning', 'full-width'] : []),
            cellClassRules: {
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            rowSpan,
        },
        {
            flex: 1,
            field: 'section.sectionDefinition.name',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.section'),
            type: 'label',
            cellClassRules: {
                'span-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            rowSpan,
        },
        {
            flex: 1.5,
            field: 'sectionFieldDefinition',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.field'),
            type: 'fieldDefinition',
            cellClass: ['span-cell'],
            sortable: false,
        },
        {
            flex: 1.5,
            field: 'previousValue',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.old_value'),
            type: 'fieldValue',
            cellClass: ['span-cell'],
            sortable: false,
        },
        {
            flex: 1.5,
            field: 'currentValue',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.new_value'),
            type: 'fieldValue',
            cellClass: ['span-cell'],
            cellStyle: ({ data }) => {
                if (data?.status === 'DELETED') {
                    return {
                        color: theme.palette.error.main,
                        textDecoration: 'line-through',
                    };
                }
            },
            sortable: false,
        },
        {
            flex: 1,
            headerName: i18next.t('payroll_profile_changes_page.table_headers.changed_by'),
            field: 'updatedBy.displayName',
            cellClassRules: {
                'span-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            rowSpan,
        },
        {
            flex: 1,
            headerName: i18next.t('payroll_profile_changes_page.table_headers.changed_at'),
            field: 'updatedAt',
            // Needed for export
            valueGetter: ({ data }) => formatToLocalDate(data?.updatedAt),
            type: 'date',
            cellClassRules: {
                'span-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            rowSpan,
        },
        {
            flex: 1,
            headerName: i18next.t('payroll_profile_changes_page.table_headers.status'),
            field: 'reviewedAt',
            cellClassRules: {
                'flex-spanning': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'span-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            valueGetter: ({ data }) => (data?.reviewedAt ? i18next.t('payroll_profile_changes_page.reviewed') : ''),
            cellRenderer: ({ value, data }: ICellRendererParams<EmployeePayrollProfileChangeRow> & { value: string }) =>
                value ? (
                    <Tooltip
                        title={i18next.t('payroll_profile_changes_page.reviewed_tracking', {
                            reviewedBy: data?.reviewedBy?.displayName,
                            reviewedAt: data?.reviewedAt,
                        })}
                    >
                        <Chip color='primary' label={value} />
                    </Tooltip>
                ) : undefined,
            rowSpan,
        },
    ];
};

const getEmployeeProfileChangeRowId = (row: EmployeePayrollProfileChangeRow) => {
    return `${row.id}-${row.sectionFieldDefinition.id ?? row.sectionFieldDefinition.fieldType}-${row.currentValue.id}`;
};

type GetEmployeePayrollProfileChangeRowsOptions = {
    excludeIdenticalFieldValues: boolean;
    excludeReviewed: boolean;
};
const getEmployeePayrollProfileChangeRows = (
    changes: EmployeePayrollProfileChange[],
    { excludeIdenticalFieldValues, excludeReviewed }: GetEmployeePayrollProfileChangeRowsOptions,
): EmployeePayrollProfileChangeRow[] => {
    // Flatten sections
    const flattenedEmployeeSections = changes.flatMap(change =>
        change.sections.map(section => ({
            employee: change.employee,
            section,
        })),
    );

    // Flatten row values (case of table section with multiple rows)
    const flattenedEmployeeFieldChanges = flattenedEmployeeSections.flatMap(row =>
        row.section.values.map(({ previousValue, currentValue }) => {
            return {
                ...row,
                previousValue,
                currentValue,
            };
        }),
    );

    const filteredFlattenedEmployeeFieldChanges = flattenedEmployeeFieldChanges.map(({ previousValue, currentValue, ...rest }) => {
        // Remove fields with no changes
        const filteredCurrentValue = {
            ...currentValue,
            fields: currentValue.fields.filter(({ sectionFieldDefinition, ...restCurrent }) => {
                const newValue = patchCountryField(sectionFieldDefinition.valueType, restCurrent);
                // previousValue could be null when it is a new employee without changing any field
                if (!previousValue) {
                    // check if the current value is also empty
                    return !isEqualSectionFieldValue({}, newValue);
                }
                return previousValue?.fields.some(
                    ({ sectionFieldDefinition: prevSectionFieldDefinition, ...restPrev }) =>
                        isSameFieldDefinition(sectionFieldDefinition, prevSectionFieldDefinition) &&
                        !isEqualSectionFieldValue(patchCountryField(sectionFieldDefinition.valueType, restPrev), newValue),
                );
            }),
        };

        return {
            ...rest,
            previousValue,
            currentValue: excludeIdenticalFieldValues ? filteredCurrentValue : currentValue,
        };
    });

    // Flatten fields
    const flattenedEmployeeFields = filteredFlattenedEmployeeFieldChanges.flatMap(row =>
        row.currentValue.fields.map(({ sectionFieldDefinition }, index) => ({
            ...row,
            sectionFieldDefinition,
            // We are force to use fields length from current value because section definition can have differences
            // for example in case of adding field after row creation
            rowSpan: index === 0 ? row.currentValue.fields.length : 0,
            isLastRowInGroup: index === row.currentValue.fields.length - 1,
        })),
    );

    // Map previous and current
    const rowsWithPossibleUndefined = flattenedEmployeeFields.map(({ previousValue, currentValue: { fields, ...restCurrentValue }, ...rest }) => {
        const current = fields.find(({ sectionFieldDefinition }) => isSameFieldDefinition(sectionFieldDefinition, rest.sectionFieldDefinition));
        const previous = previousValue?.fields.find(({ sectionFieldDefinition }) => isSameFieldDefinition(sectionFieldDefinition, rest.sectionFieldDefinition));
        if (!current) {
            return;
        }
        return {
            ...rest,
            ...restCurrentValue,
            previousValue: previous,
            currentValue: current,
        };
    });

    // Prevent undefined values from being added to the array
    const rowsDefined = rowsWithPossibleUndefined.filter(isDefined);

    return excludeReviewed ? rowsDefined.filter(row => !row.reviewedAt) : rowsDefined;
};

type UseRowsOptions = {
    excludeIdenticalFieldValues: boolean;
    excludeReviewed: boolean;
};
const useRows = (changes: EmployeePayrollProfileChange[], { excludeIdenticalFieldValues, excludeReviewed }: UseRowsOptions) => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<EmployeePayrollProfileChangeRow[]>([]);

    useDeepCompareEffect(() => {
        setIsLoading(true);
        const updateRows = () => {
            // We need to wait a bit to avoid flickering when rows are filtered in FE side
            setTimeout(() => {
                const rows = getEmployeePayrollProfileChangeRows(changes, {
                    excludeIdenticalFieldValues,
                    excludeReviewed,
                });
                setRows(rows);
                setIsLoading(false);
            }, 200);
        };

        updateRows();
    }, [changes, excludeIdenticalFieldValues, excludeReviewed]);

    return { rows, isLoading };
};
