import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { createWeeklyWorkingTime, deleteWeeklyWorkingTime, updateWeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.service';
import { useGetWeeklyWorkingTimes } from '@/hooks/weekly-working-time/WeeklyWorkingTime.hook';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { WeeklyWorkingTimeDialog, WeeklyWorkingTimeFormValues } from '@/page/setting/time-management/working-pattern/WeeklyWorkingTimeDialog';
import { ConfigType } from '@/page/setting/types';
import { clearSeconds, getMinutesFromHoursMinutes } from '@/utils/datetime.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Stack } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const WeeklyWorkingTimesPage: FC = () => {
    const { t } = useTranslation();

    const [isNewWeeklyWorkingTimeDialogOpen, setIsNewWeeklyWorkingTimeDialogOpen] = useState(false);
    const [weeklyWorkingTimeToUpdate, setWeeklyWorkingTimeToUpdate] = useState<WeeklyWorkingTime>();
    const [weeklyWorkingTimeToDelete, setWeeklyWorkingTimeToDelete] = useState<WeeklyWorkingTime>();

    const { data: weeklyWorkingTimes = [], refetch } = useGetWeeklyWorkingTimes();

    const handleCreate = async (newWeeklyWorkingTime: WeeklyWorkingTimeFormValues) => {
        try {
            await createWeeklyWorkingTime({
                ...newWeeklyWorkingTime,
                weeklyAmountWorked: getMinutesFromHoursMinutes(newWeeklyWorkingTime.weeklyAmountWorked),
            });
            await refetch();
            setIsNewWeeklyWorkingTimeDialogOpen(false);
        } catch {
            showSnackbar(t('settings_time_management.weekly_working_times.create_error'), 'error');
        }
    };

    const handleUpdate =
        ({ id }: WeeklyWorkingTime) =>
        async ({ weeklyAmountWorked, ...rest }: WeeklyWorkingTimeFormValues) => {
            try {
                await updateWeeklyWorkingTime(id, {
                    ...rest,
                    weeklyAmountWorked: getMinutesFromHoursMinutes(weeklyAmountWorked),
                });
                await refetch();
                setWeeklyWorkingTimeToUpdate(undefined);
            } catch {
                showSnackbar(t('settings_time_management.weekly_working_times.update_error'), 'error');
            }
        };

    const handleDelete = async ({ id }: WeeklyWorkingTime) => {
        try {
            await deleteWeeklyWorkingTime(id);
            await refetch();
            setWeeklyWorkingTimeToDelete(undefined);
        } catch {
            showSnackbar(t('settings_time_management.weekly_working_times.delete_error'), 'error');
        }
    };

    const getMoreMenuItems = (weeklyWorkingTime: WeeklyWorkingTime): BasicMenuItem[] => {
        return [
            {
                title: t('general.edit'),
                onClick: () => setWeeklyWorkingTimeToUpdate(weeklyWorkingTime),
            },
            {
                title: t('general.delete'),
                onClick: () => setWeeklyWorkingTimeToDelete(weeklyWorkingTime),
            },
        ] satisfies BasicMenuItem[];
    };

    const cellActionRenderer = ({ data }: ICellRendererParams<WeeklyWorkingTime>) => {
        return <BasicMenu items={data ? getMoreMenuItems(data) : []} />;
    };

    const options: ConfigType<WeeklyWorkingTime> = {
        header: {
            primaryButtonCaption: t('settings_time_management.weekly_working_times.add_weekly_working_time'),
            primaryButtonAction: () => setIsNewWeeklyWorkingTimeDialogOpen(true),
        },
        type: 'table',
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: ({ data }) => {
                    if (!data) {
                        return;
                    }

                    setWeeklyWorkingTimeToUpdate(data);
                },
            },

            columns: [
                {
                    field: 'name',
                    headerName: t('settings_time_management.weekly_working_times.fields.name'),
                },
                {
                    field: 'weeklyAmountWorked',
                    headerName: t('settings_time_management.weekly_working_times.fields.weeklyAmountWorked'),
                    type: 'minutesToHours',
                },
                {
                    colId: 'baseSchedule',
                    headerName: t('settings_time_management.weekly_working_times.base_schedule'),
                    valueFormatter: ({ data }: { data: WeeklyWorkingTime | undefined }) => {
                        return data ? `${clearSeconds(data.startTime)} - ${clearSeconds(data.endTime)} (${data.breakDuration})` : '';
                    },
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ] satisfies ColDef<WeeklyWorkingTime>[],
        },
    };

    return (
        <Stack flex={1}>
            <CompanySettingsLayout options={options} data={weeklyWorkingTimes} />

            {isNewWeeklyWorkingTimeDialogOpen && <WeeklyWorkingTimeDialog onSave={handleCreate} onClose={() => setIsNewWeeklyWorkingTimeDialogOpen(false)} />}
            {weeklyWorkingTimeToUpdate && (
                <WeeklyWorkingTimeDialog
                    onSave={handleUpdate(weeklyWorkingTimeToUpdate)}
                    onClose={() => setWeeklyWorkingTimeToUpdate(undefined)}
                    defaultWeeklyWorkingTime={weeklyWorkingTimeToUpdate}
                />
            )}
            {weeklyWorkingTimeToDelete && (
                <ConfirmDialog
                    open
                    title={t('settings_time_management.weekly_working_times.delete_confirmation_dialog.title')}
                    content={t('settings_time_management.weekly_working_times.delete_confirmation_dialog.content_text')}
                    onConfirm={() => handleDelete(weeklyWorkingTimeToDelete)}
                    onClose={() => setWeeklyWorkingTimeToDelete(undefined)}
                />
            )}
        </Stack>
    );
};
