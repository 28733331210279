import { announcementAPI } from '@/api/announcement/Announcement.api';
import { Announcement, AnnouncementSearch, CreateAnnouncementMutation, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';

export const getAnnouncements = (searchRequest: AnnouncementSearch = {}): Promise<Announcement[]> => {
    return announcementAPI.getAnnouncements(searchRequest);
};

export const getAnnouncementsAsEditor = (searchRequest: AnnouncementSearch = {}): Promise<Announcement[]> => {
    return announcementAPI.getAnnouncementsAsEditor(searchRequest);
};

export const createAnnouncement = async ({ imageFile, ...createMutation }: CreateAnnouncementMutation): Promise<Announcement> => {
    const newAnnouncement = await announcementAPI.createAnnouncement(createMutation);
    if (imageFile) {
        await editAnnouncementImage(newAnnouncement.id, imageFile);
    }
    return newAnnouncement;
};

export const editAnnouncement = async ({ imageFile, hasNoImage, ...editMutation }: EditAnnouncementMutation): Promise<Announcement> => {
    const updatedAnnouncement = await announcementAPI.updateAnnouncement(editMutation);
    const hasPreviousImage = updatedAnnouncement.imageUrl;

    const announcementId = editMutation.id;

    if (imageFile) {
        await editAnnouncementImage(announcementId, imageFile);
    }
    if (hasNoImage && hasPreviousImage) {
        await deleteAnnouncementImage(announcementId);
    }
    return updatedAnnouncement;
};

export const deleteAnnouncement = (announcementId: number): Promise<void> => {
    return announcementAPI.deleteAnnouncement(announcementId);
};

export const editAnnouncementImage = (id: Announcement['id'], image: File): Promise<Announcement> => {
    return announcementAPI.editAnnouncementImage(id, image);
};

export const deleteAnnouncementImage = (announcementId: Announcement['id']): Promise<void> => {
    return announcementAPI.deleteAnnouncementImage(announcementId);
};

export const getAnnouncementImageUrl = (announcementId: Announcement['id']): Promise<string> => {
    return announcementAPI.getAnnouncementImageUrl(announcementId);
};
