import { Label } from '@/domain/label/Label.model';
import { AnswerResult, SurveyResultFilterType } from '@/domain/survey/Survey.model';
import { renderGroupByWithFilters } from '@/domain/survey/Survey.service';
import { getLabelTranslation } from '@/utils/language.util';
import { assignLocalIds } from '@/utils/object.util';
import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Comment } from './Comment';
import { ScaleGroupBy } from './ScaleGroupBy';

type Props = {
    title: Label;
    answer: AnswerResult;
    groupBy: SurveyResultFilterType;
    activeGroupByFilters: string[];
};

export const ScaleResult: FC<Props> = ({ title, activeGroupByFilters, answer, groupBy }) => {
    const { t } = useTranslation();

    if (!answer?.details || !answer?.completions) {
        return <></>;
    }

    const allComments = assignLocalIds(answer.details.ALL[0].comments) ?? [];

    return (
        <Stack component={Paper} p={3} flex={1} gap={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant='body1bold'>{getLabelTranslation(title)}</Typography>
                <Typography component={'span'} variant='body1'>
                    {answer.completions?.ALL[0].answered} {t('survey_results.answered')} - {answer.completions?.ALL[0].skipped} {t('survey_results.skipped')}
                </Typography>
            </Stack>
            <Stack flex={1} gap={2}>
                <ScaleGroupBy question={answer.question} answerDetails={answer.details.ALL[0]} groupBy={SurveyResultFilterType.ALL} />
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack
                        sx={{
                            maxHeight: 300,
                            overflow: 'auto',
                        }}
                    >
                        {allComments?.map(comment => <Comment key={comment.localId} text={comment.comment} employee={comment.user} />)}
                    </Stack>
                </Stack>
            </Stack>
            {groupBy !== SurveyResultFilterType.ALL &&
                answer.details[groupBy] &&
                renderGroupByWithFilters(activeGroupByFilters, answer.details[groupBy]).map(
                    answerDetails =>
                        answerDetails.items.length && (
                            <Stack key={answerDetails.key} flex={1}>
                                <ScaleGroupBy question={answer.question} answerDetails={answerDetails} groupBy={groupBy} />
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Stack
                                        sx={{
                                            maxHeight: 300,
                                            overflow: 'auto',
                                        }}
                                    >
                                        {assignLocalIds(answerDetails.comments)?.map(comment => (
                                            <Comment key={comment.localId} text={comment.comment} employee={comment.user} />
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>
                        ),
                )}
        </Stack>
    );
};
