import { searchLongLeaves } from '@/domain/long-leave/LongLeave.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const longLeaveKeys = createQueryKeys('longLeaves', {
    search: (...params: Parameters<typeof searchLongLeaves>) => ({
        queryKey: params,
    }),
});

export const useGetLongLeaves = createRequiredParamsQueryHook(longLeaveKeys.search, searchLongLeaves);
