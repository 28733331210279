import { BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { SectionLoading } from '@/components/section/SectionLoading';
import { StackSection } from '@/components/section/StackSectionComponent/StackSection';
import { SectionField } from '@/components/section/types';
import { Employee } from '@/domain/employee/Employee.model';
import { ContractCreationMutation, ContractType, Employment, EmploymentCreateReason, EmploymentStatus } from '@/domain/employment/Employment.model';
import { cancelTermination, createContract, getContracts, getCurrentContract, isContractTerminated } from '@/domain/employment/Employment.service';
import { canManageEmployeeContracts, canOnboardOffboardEmployees } from '@/domain/permission/Permission.service';
import { ContractsHistoryDialog } from '@/page/employee-profile/employee-profile-info/EmployeeContractSection/ContractsHistoryDialog';
import { NewContractDialog } from '@/page/employee-profile/employee-profile-info/EmployeeContractSection/NewContractDialog';
import { useCurrentPolicies } from '@/stores/store';
import {
    formatDurationForDates,
    formatInDefaultDate,
    formatRelativeDate,
    getCurrentLocalDate,
    isAfterDate,
    subDaysAndFormat,
    toDate,
} from '@/utils/datetime.util';

import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { NewContractFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeContractSection/contract.schema';
import { handleError } from '@/utils/api.util';
import { Button, capitalize, DialogActions } from '@mui/material';
import { Clock02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ModifyContractDialog } from './ModifyContractDialog';

type EmployeeContractSectionProps = {
    employee: Employee;
    onUpdateEmployeeContract: () => void;
    handleLongLeaveButton: () => void;
    employments: Employment[];
};

export const EmployeeContractSection: FC<EmployeeContractSectionProps> = ({ employee, onUpdateEmployeeContract, handleLongLeaveButton, employments }) => {
    const { t } = useTranslation();

    const [showHistory, setShowHistory] = useState(false);
    const [cancelDepartureDialogOpen, setCancelDepartureDialogOpen] = useState(false);
    const [modifyContractDialogOpen, setModifyContractDialogOpen] = useState(false);
    const policies = useCurrentPolicies();
    const [newContractDialogOpen, setNewContractDialogOpen] = useState<boolean>(false);
    const currentContract = getCurrentContract(employments);
    const existingContracts = getContracts(employments);

    const navigate = useNavigate();

    const cancelDepartureButton: BasicMenuItem = {
        title: t('employee.employment.departure.cancel_departure'),
        onClick: () => {
            setCancelDepartureDialogOpen(!cancelDepartureDialogOpen);
        },
    };

    const modifyContractButton: BasicMenuItem = {
        title: t('employee.employment.contract.modify_contract'),
        onClick: () => {
            setModifyContractDialogOpen(!modifyContractDialogOpen);
        },
    };

    const terminateContractButton: BasicMenuItem = {
        title: t('offboarding_form.offboarding'),
        onClick: () => {
            navigate(`/people/offboarding-form/${employee.id}`);
        },
    };

    const newContractButton: BasicMenuItem = {
        title: t('employee.employment.new_contract'),
        onClick: () => {
            setNewContractDialogOpen(true);
        },
    };

    const addLongLeaveButton: BasicMenuItem = {
        title: t('employee.employment.long_leave.add_long_leave'),
        onClick: () => {
            handleLongLeaveButton();
        },
    };

    const getDropdownActionButtons = (): BasicMenuItem[] => {
        if (!canManageEmployeeContracts(policies, employee?.id)) {
            return [];
        }

        if (!currentContract) {
            return [];
        }

        // contract should not be terminated yet and user should have permission to offboard
        const canTerminateContract = !isContractTerminated(currentContract) && canOnboardOffboardEmployees(policies);
        const isLastContract = existingContracts.find(contract => isAfterDate(contract.startDate, currentContract.startDate)) === undefined;
        const canCancelTerminationOrRehire = isContractTerminated(currentContract) && canOnboardOffboardEmployees(policies) && isLastContract;

        return [
            modifyContractButton,
            ...(canCancelTerminationOrRehire ? [cancelDepartureButton, newContractButton] : []),
            ...(canTerminateContract ? [terminateContractButton] : []),
            addLongLeaveButton,
        ];
    };

    const handleCancelDeparture = () => {
        if (!currentContract) {
            return;
        }
        cancelTermination(currentContract.id)
            .then(() => {
                onUpdateEmployeeContract();
                setCancelDepartureDialogOpen(false);
            })
            .catch(handleError);
    };

    const handleOnUpdateContract = () => {
        onUpdateEmployeeContract();
        setModifyContractDialogOpen(false);
    };

    const handleEmploymentCreation = (contractFormValues: NewContractFormValues) => {
        const contractCreateRequest: ContractCreationMutation = {
            employeeId: employee.id,
            startDate: contractFormValues.startDate,
            probationEndDate: contractFormValues.probationEndDate ?? undefined,
            contractType: contractFormValues.contractType,
            employmentCreateReason: EmploymentCreateReason.REHIRED,
            endDate: contractFormValues.endDate ?? undefined,
            items: [
                {
                    principal: true,
                    locationId: contractFormValues.location?.id,
                    departmentId: contractFormValues.department?.id,
                    jobId: contractFormValues.job?.id,
                    managerIds: contractFormValues.managers.flatMap(manager => manager.id),
                    employmentCostCenters:
                        contractFormValues.employmentCostCenters.map(ecc => ({
                            costCenterId: ecc.costCenter.id,
                            percentage: ecc.percentage,
                        })) ?? [],
                },
            ],
        };
        createContract(contractCreateRequest)
            .then(() => {
                onUpdateEmployeeContract();
            })
            .catch(handleError)
            .finally(() => {
                setNewContractDialogOpen(false);
            });
    };

    const getStartOrSeniorityDateFieldValue = (date: LocalDate | undefined, employmentStatus: EmploymentStatus | undefined) => {
        if (!date) {
            return undefined;
        }

        const startDateFormatted = formatInDefaultDate(date);
        const duration = formatDurationForDates(subDaysAndFormat(date, 1), getCurrentLocalDate());
        const shouldDisplayDuration = !!duration && employmentStatus !== EmploymentStatus.TERMINATED && employmentStatus !== EmploymentStatus.HIRED;

        return shouldDisplayDuration ? `${startDateFormatted} (${duration})` : startDateFormatted;
    };

    const getFields = (employee: Employee): SectionField[] => {
        const startDate = currentContract?.startDate ?? employee.contractStartDate;
        const fields: SectionField[] = [
            {
                fieldDefinitionId: undefined,
                title: t('employee.employment.contract_type_title'),
                valueType: 'STRING',
                stringValue: t('employee.employment.contract_type', { context: currentContract?.contractType }),
                fieldType: 'CURRENT_EMPLOYMENT_CONTRACT_TYPE',
            },
            {
                fieldDefinitionId: undefined,
                title: t('employee.employment.start_date'),
                stringValue: getStartOrSeniorityDateFieldValue(startDate, employee.employmentStatus),
                valueType: 'STRING',
                fieldType: 'CURRENT_EMPLOYMENT_START_DATE',
            },
        ];

        const shouldDisplaySeniorityDate =
            employee?.seniorityDate &&
            currentContract?.startDate !== employee?.seniorityDate &&
            employee.employmentStatus !== EmploymentStatus.TERMINATED &&
            employee.employmentStatus !== EmploymentStatus.HIRED;

        if (shouldDisplaySeniorityDate) {
            fields.push({
                fieldDefinitionId: undefined,
                title: t('employee.employment.seniority_date'),
                stringValue: getStartOrSeniorityDateFieldValue(employee.seniorityDate, employee.employmentStatus),
                valueType: 'STRING',
                fieldType: 'EMPLOYEE_SENIORITY_DATE',
            });
        }

        fields.push({
            fieldDefinitionId: undefined,
            title: t('employee.employment.probation_end_date'),
            dateValue: currentContract?.probationEndDate ?? undefined,
            valueType: 'DATE',
            fieldType: 'CURRENT_EMPLOYMENT_PROBATION_END_DATE',
        });

        if (currentContract?.endDate) {
            if (!!currentContract?.terminationType || currentContract.contractType !== ContractType.PERMANENT) {
                fields.push({
                    fieldDefinitionId: undefined,
                    title: t('employee.employment.end_date'),
                    dateValue: currentContract?.endDate,
                    valueType: 'DATE',
                    fieldType: 'CURRENT_EMPLOYMENT_CONTRACT_END_DATE',
                });
            }

            if (currentContract?.terminationType) {
                fields.push({
                    fieldDefinitionId: undefined,
                    title: t('employee.employment.termination.notice_date'),
                    dateValue: currentContract.terminationNoticeDate,
                    valueType: 'DATE',
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_NOTICE_DATE',
                });
                fields.push({
                    fieldDefinitionId: undefined,
                    title: t('employee.employment.termination.last_day_at_work'),
                    stringValue: capitalize(formatRelativeDate(toDate(currentContract.terminationLastDayAtWork))),
                    valueType: 'STRING',
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY',
                });
                fields.push({
                    fieldDefinitionId: undefined,
                    title: t('domain.employee_field_type.termination_type'),
                    stringValue: t('employee.employment.termination_type.enum', { context: currentContract.terminationType }),
                    valueType: 'STRING',
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_TYPE',
                });
                fields.push({
                    fieldDefinitionId: undefined,
                    title: t('domain.employee_field_type.termination_reason'),
                    stringValue: t('employee.employment.termination_reason.enum', { context: currentContract.terminationReason }),
                    valueType: 'STRING',
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_REASON',
                });
                fields.push({
                    fieldDefinitionId: undefined,
                    title: t('employee.employment.termination.end_comment'),
                    stringValue: currentContract.terminationComment,
                    valueType: 'STRING',
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_COMMENT',
                });
            }
        }

        return fields;
    };
    if (!employee) {
        return <SectionLoading sectionTitle={'employee.sections.contract'} />;
    }

    return (
        <>
            <StackSection
                sectionTitle={t('employee.sections.contract')}
                fields={getFields(employee)}
                actionButtons={[
                    {
                        title: t('employee.contract.view_history'),
                        onClick: () => setShowHistory(true),
                        endIcon: <Clock02Icon size={20} />,
                    },
                ]}
                dropdownActionButtons={getDropdownActionButtons()}
                dropdownTitle={t('my_profile.actions')}
            />

            {showHistory && (
                <ContractsHistoryDialog
                    open={true}
                    onClose={() => setShowHistory(false)}
                    onContractChange={onUpdateEmployeeContract}
                    employeeId={employee.id}
                />
            )}
            <DialogWrapper open={cancelDepartureDialogOpen} onClose={() => setCancelDepartureDialogOpen(false)} header={t('employee.employment.are_you_sure')}>
                <DialogActions>
                    <Button onClick={() => setCancelDepartureDialogOpen(false)} fullWidth>
                        {t('general.no')}
                    </Button>
                    <Button onClick={handleCancelDeparture} fullWidth>
                        {t('general.yes')}
                    </Button>
                </DialogActions>
            </DialogWrapper>

            {modifyContractDialogOpen && currentContract && (
                <ModifyContractDialog
                    open={true}
                    onClose={() => setModifyContractDialogOpen(false)}
                    onSave={() => handleOnUpdateContract()}
                    contract={currentContract}
                    existingContracts={existingContracts}
                />
            )}

            {newContractDialogOpen && (
                <NewContractDialog
                    open={newContractDialogOpen}
                    employeeId={employee.id}
                    onSave={handleEmploymentCreation}
                    onClose={() => setNewContractDialogOpen(false)}
                    existingContracts={existingContracts}
                />
            )}
        </>
    );
};
