import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { fileSchema } from '@/components/file-picker/FilePicker.schema';
import { isNumberOrEmpty, MAX_NUMBER_VALUE } from '@/utils/math.util';
import { checkIBANValidity, isValidAvs, isValidPhoneNumber } from '@/utils/validator.util';
import i18next from 'i18next';
import * as yup from 'yup';
import { AnyObject } from 'yup';

export const getSectionDefinitionSchema = ({ sectionDefinition }: { sectionDefinition: SectionDefinition }): yup.ObjectSchema<AnyObject> => {
    const shape = sectionDefinition.fields.reduce((acc, curr) => {
        const sectionFieldDefinition = curr;

        let fieldSchema;

        switch (sectionFieldDefinition.valueType) {
            case 'STRING':
                fieldSchema = yup.string();
                break;
            case 'CUSTOM_MULTI_LIST_ITEM':
                fieldSchema = yup
                    .array()
                    .nullable()
                    .transform((_, val) => {
                        return val?.length ? val : undefined;
                    });
                break;
            case 'CUSTOM_LIST_ITEM':
                fieldSchema = yup.number().nullable();
                break;
            case 'NUMBER':
            case 'AUTO_INCREMENT':
                fieldSchema = yup
                    .number()
                    .nullable()
                    .max(MAX_NUMBER_VALUE, i18next.t('general.validations.valid_number_max'))
                    .min(0, i18next.t('general.validations.valid_number_min'))
                    .transform((_, val) => {
                        return isNumberOrEmpty(val);
                    })
                    .typeError(i18next.t('general.validations.not_type_number'));
                break;
            case 'EMPLOYEE':
                fieldSchema = yup
                    .number()
                    .nullable()
                    .transform((_, val) => {
                        return val || val === 0 ? Number(val) : undefined;
                    });
                break;
            case 'DATE':
                fieldSchema = yup.string<LocalDate>().nullable().test(getLocalDateTestConfig());
                break;
            case 'SECTION_FIELD_DOCUMENT':
                fieldSchema = yup
                    .array()
                    .of(fileSchema)
                    .nullable()
                    .test({
                        message: i18next.t('general.validations.required'),
                        test: val => {
                            if (sectionFieldDefinition.mandatory) {
                                return !!val && val.length > 0;
                            }
                            return true;
                        },
                    });
                break;
            case 'ENUM':
                fieldSchema = yup.string().nullable();
                break;
            case 'COUNTRY':
                fieldSchema = yup
                    .object()
                    .shape({
                        label: yup.string(),
                        value: yup.string(),
                    })
                    .nullable()
                    .test({
                        message: i18next.t('general.validations.required'),
                        test: val => {
                            if (sectionFieldDefinition.mandatory) {
                                return !!val?.value;
                            }
                            return true;
                        },
                    });
                break;
            case 'PHONE_NUMBER':
                fieldSchema = yup
                    .string()
                    .trim()
                    .nullable()
                    .test({
                        message: i18next.t('general.validations.not_type_phone_number'),
                        test: phoneNumber => {
                            if (!sectionFieldDefinition.mandatory && !phoneNumber) {
                                return true;
                            }
                            return isValidPhoneNumber(phoneNumber);
                        },
                    });
                break;
            case 'IBAN_NUMBER':
                fieldSchema = yup
                    .string()
                    .trim()
                    .nullable()
                    .test({
                        message: i18next.t('general.validations.not_typ_iban_number'),
                        test: val => (!val ? !sectionFieldDefinition.mandatory : checkIBANValidity(val)),
                    });
                break;
            case 'AVS_NUMBER':
                fieldSchema = yup
                    .string()
                    .nullable()
                    .test({
                        message: i18next.t('employee.avs_number_not_valid'),
                        test: avsNumber => (avsNumber ? isValidAvs(avsNumber) : true),
                    });
                break;
            case 'EMAIL':
                fieldSchema = yup.string().email();
                break;
            default:
                fieldSchema = yup.string();
                break;
        }

        if (sectionFieldDefinition.mandatory) {
            fieldSchema = fieldSchema.required();
        }

        return { ...acc, [getFieldFormName(curr)]: fieldSchema };
    }, {});

    return yup.object().shape(shape);
};

export type SectionDefinitionFormValues = yup.InferType<ReturnType<typeof getSectionDefinitionSchema>>;

export const getFieldFormName = (fieldDefinition: { formId: string }): string => {
    return fieldDefinition.formId;
};
