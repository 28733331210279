import { searchEmployees } from '@/domain/employee/Employee.service';
import { AvailableReportField, Report, ReportFieldType, ReportFilter, ReportFilterRule } from '@/domain/report/Report.model';
import { ReportFilterItemBar } from '@/page/report/report-editor-bar/ReportEditorBar';
import { handleError } from '@/utils/api.util';
import { getCountryList } from '@/utils/countries.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Dispatch, SetStateAction, useState } from 'react';

import { searchDepartmentNodes, searchDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { SectionFieldValueType } from '@/domain/section-setting/Section.model';

import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/useColumnTypes';
import { searchCostCenters } from '@/domain/cost-center/CostCenter.service';
import { getFolders } from '@/domain/document/Document.service';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { getLocations } from '@/domain/location/Location.service';
import { searchObjectiveCategories } from '@/domain/objective-category/ObjectiveCategory.service';
import { searchCompletionStatuses } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.service';

import useDeepCompareEffect from 'use-deep-compare-effect';
import { getJobFamilies } from '@/domain/job-family/JobFamily.service';
import { getEnumFieldTypeOptions, isSectionFieldType } from '@/domain/section-setting/Section.service';
import {
    AsyncSelectFilter,
    DateFilter,
    DateRangeType,
    FilterCommonType,
    FilterType,
    SelectFilter,
    SelectFilterOption,
} from '@/components/filters-bar/FilterBar.type';
import { mapDepartmentNodesToTreeSelectFilterOptions } from '@/domain/department/Department.util';
import { getDocumentTags } from '@/domain/document-tag/DocumentTag.service';
import { getCalendars } from '@/domain/calendar/Calendar.service';

export type UseReportFiltersProps = {
    availableFields: AvailableReportField[];
    defaultFilters: Report['filters'];
};
export const useReportFilters = (
    { availableFields, defaultFilters }: UseReportFiltersProps = {
        availableFields: [],
        defaultFilters: [],
    },
): [ReportFilterItemBar[], Dispatch<SetStateAction<ReportFilterItemBar[]>>] => {
    const [filters, setFilters] = useState<ReportFilterItemBar[]>([]);

    useDeepCompareEffect(() => {
        if (!availableFields?.length) {
            return;
        }
        let availableFieldsToFilterItemBar = availableFields.map(convertAvailableFieldToFilterItemBar);

        if (defaultFilters?.length) {
            // Set values from report in filters
            availableFieldsToFilterItemBar = availableFieldsToFilterItemBar.map(filter => {
                // Search the current filter in the report data
                const filterFromReport = defaultFilters.find(isFilterEquals(filter));
                if (filterFromReport) {
                    const values = getValuesFromReportFilter(filter.fieldFilterType, filterFromReport);

                    const filterWithValue = setValueInReportFilter(filter, values, filterFromReport.rule);
                    if (filterWithValue) {
                        return filterWithValue;
                    }
                }
                return filter;
            });
        }

        setFilters(availableFieldsToFilterItemBar);
    }, [availableFields, defaultFilters]);

    // This useEffect is used to fetch visible async filters and set the label in the filter value.
    // The ASYNC mode is changed into SYNC mode
    useDeepCompareEffect(() => {
        if (!availableFields?.length) {
            return;
        }

        const asyncFilters = getVisibleAsyncFilters(filters);

        if (asyncFilters.length) {
            // load async filters and set the label in the filter value from the options
            // filter value without label = [{value: 1, label: ''}, {value: 2, label: ''}]
            // + options = [{value: 1, label: 'IT'}, {value: 2, label: 'HR'}]
            // new filter value = [{value: 1, label: 'IT'}, {value: 2, label: 'HR'}]
            const promises = asyncFilters.map(async filter => {
                try {
                    const options = await filter.fetchOptions();

                    return convertAsyncFilter(filter, options);
                } catch (error) {
                    handleError(error);
                }
            });

            Promise.all(promises).then(updatedFilter => {
                setFilters(filters => mergeFilters(filters, updatedFilter));
            });
        }
    }, [availableFields?.length, filters]);

    return [filters, setFilters];
};

const convertAvailableFieldToFilterItemBar = (reportField: AvailableReportField): ReportFilterItemBar => {
    if (reportField.sectionFieldDefinition?.fieldType === 'EMPLOYEE_CUSTOM_FIELD') {
        return convertCustomFieldToReportFilter(reportField);
    }

    const reportFilterType = getFilterTypeFromNonCustomField(reportField);

    const fieldType = reportField.fieldType;

    const commons: Pick<
        ReportFilterItemBar,
        'fieldType' | 'fieldValueType' | 'fieldFilterType' | 'sectionFieldDefinition' | 'filterName' | 'hide' | 'defaultVisibility' | 'key'
    > = {
        key: fieldType,
        filterName: getFieldDefinitionTranslation(reportField.sectionFieldDefinition ?? { fieldType }),
        fieldType: fieldType,
        hide: true,
        defaultVisibility: 'hidden',
        sectionFieldDefinition: reportField.sectionFieldDefinition,
        fieldValueType: reportField.valueType,
        fieldFilterType: reportField.filterType,
    };

    if (reportFilterType === 'date') {
        return {
            ...commons,
            type: reportFilterType,
            availableRules: ['MORE_THAN', 'WITHIN_THE_LAST', 'BETWEEN'],
        };
    }

    if (reportFilterType === 'tree-select' || reportFilterType === 'tree-multi-select') {
        // for now, we display only departments in a tree-select
        return {
            ...commons,
            type: reportFilterType,
            rule: 'EQUALS',
            availableRules: ['EQUALS', 'NOT_EQUALS'],
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departmentsNodes = await searchDepartmentNodes();
                return mapDepartmentNodesToTreeSelectFilterOptions(departmentsNodes);
            },
        };
    }

    if (reportFilterType === 'multi-select' || reportFilterType === 'select') {
        return {
            ...commons,
            type: reportFilterType,
            rule: 'EQUALS',
            availableRules: ['EQUALS', 'NOT_EQUALS'],
            ...getSelectProps(reportField),
        };
    }

    return {
        ...commons,
        type: reportFilterType,
    };
};

const convertCustomFieldToReportFilter = ({ sectionFieldDefinition, filterType: fieldFilterType }: AvailableReportField): ReportFilterItemBar => {
    if (!sectionFieldDefinition) {
        throw new Error('sectionFieldDefinition is required for custom fields');
    }

    if (!isSectionFieldType(sectionFieldDefinition.valueType)) {
        throw new Error(`Unhandled custom field type: ${sectionFieldDefinition.valueType}`);
    }

    const filterType = getFilterTypeFromCustomField(sectionFieldDefinition.valueType);

    const commons: FilterCommonType & Pick<ReportFilterItemBar, 'fieldType' | 'fieldValueType' | 'fieldFilterType' | 'sectionFieldDefinition' | 'key'> = {
        key: `EMPLOYEE_CUSTOM_FIELD_${sectionFieldDefinition.id}`,
        filterName: getLabelTranslation(sectionFieldDefinition.name),
        fieldType: 'EMPLOYEE_CUSTOM_FIELD',
        hide: true,
        defaultVisibility: 'hidden',
        sectionFieldDefinition: sectionFieldDefinition,
        fieldValueType: sectionFieldDefinition.valueType,
        fieldFilterType,
    };

    const getSelectOptions = (): SelectFilterOption[] => {
        if (sectionFieldDefinition.valueType === 'COUNTRY') {
            return getCountryList();
        }

        return (
            sectionFieldDefinition.customList?.items.map(item => ({
                label: getLabelTranslation(item.label),
                value: item.id,
            })) ?? []
        );
    };

    const options = getSelectOptions();

    switch (filterType) {
        case 'text':
        case 'number':
        case 'document':
            return {
                ...commons,
                // type number and document are not supported yet in the UI
                type: 'text',
            };
        case 'date':
            return {
                ...commons,
                type: filterType,
                availableRules: ['MORE_THAN', 'WITHIN_THE_LAST'],
            };
        case 'multi-select':
            return {
                ...commons,
                type: 'multi-select',
                options,
                selectMode: 'SYNC',
                rule: 'EQUALS',
                availableRules: ['EQUALS', 'NOT_EQUALS'],
            };
        case 'boolean':
            return {
                ...commons,
                type: 'boolean',
            };
    }

    throw new Error(`Unhandled filterType: ${filterType}`);
};

const getFilterTypeFromNonCustomField = (reportField: AvailableReportField): FilterType['type'] => {
    // const currentFieldType = REPORT_FIELD_TYPES[reportFieldType];
    // Specific condition to override the default conversion
    // ex: if we want to use non multi-select for a specific enum
    if (isCountryFilter(reportField)) {
        return 'multi-select';
    }

    if (isDepartmentFilter(reportField)) {
        return 'tree-multi-select';
    }

    return getFilterType(reportField.filterType);
};

const getFilterTypeFromCustomField = (sectionFieldValueType: SectionFieldValueType): FilterType['type'] => {
    // Usually it's not possible to get a custom field from the api with this types
    if (sectionFieldValueType === 'ENUM') {
        return 'text';
    }
    return getFilterType(sectionFieldValueType);
};

/**
 *
 * @param filter state of the filter in the UI
 * @param filterValues store in the report
 * @param rule rule of the filter in the report
 * @returns filter with the value from the report
 */
const setValueInReportFilter = (
    filter: ReportFilterItemBar,
    filterValues: string[] | number[] | LocalDate[] | boolean[],
    rule?: ReportFilterRule,
): ReportFilterItemBar => {
    if (!filterValues?.length) {
        return filter;
    }

    switch (filter.type) {
        case 'select':
        case 'multi-select':
        case 'tree-select':
        case 'tree-multi-select':
            if (filter.selectMode === 'ASYNC') {
                const values = filterValues.map(val => ({
                    // In async mode we don't have the options so we can't display the label without a call to the API
                    label: '',
                    value: val.toString(),
                }));
                return {
                    ...filter,
                    hide: !filterValues?.length,
                    value: values,
                    rule: getSelectFilterRule(rule),
                };
            } else {
                const selectedOptions = filter.options?.filter(option => filterValues.find(val => option.value.toString() === val.toString()));
                const values = filterValues?.length ? selectedOptions : [];

                return {
                    ...filter,
                    rule: getSelectFilterRule(rule),
                    value: values,
                    hide: !filterValues?.length,
                };
            }
        case 'date': {
            const dateType = getFilterDateType(rule);
            if (dateType === 'BETWEEN') {
                return {
                    ...filter,
                    value: filterValues as DateRangeType,
                    hide: !filterValues,
                    dateType,
                };
            }
            return {
                ...filter,
                value: filterValues[0] as LocalDate,
                hide: !filterValues,
                dateType,
            };
        }
        case 'text':
            return {
                ...filter,
                value: filterValues[0] as string,
                hide: !filterValues,
            };
        case 'boolean':
            return {
                ...filter,
                value: filterValues[0] as boolean,
                hide: !filterValues,
            };
        default:
            return filter;
    }
};

const getFilterType = (filterType: SectionFieldValueType | 'BOOLEAN'): FilterType['type'] => {
    //TODO: RP-6366 - remove or handle the case 'BOOLEAN'
    switch (filterType) {
        case 'NUMBER':
            return 'number';
        case 'STRING':
        case 'PHONE_NUMBER':
        case 'IBAN_NUMBER':
        case 'AVS_NUMBER':
        case 'EMAIL':
        case 'AUTO_INCREMENT':
            return 'text';
        case 'REFERENCE':
        case 'EMPLOYEE':
        case 'CUSTOM_LIST_ITEM':
        case 'CUSTOM_MULTI_LIST_ITEM':
        case 'ENUM':
        case 'LABEL':
            return 'multi-select';
        case 'DATE':
            return 'date';
        case 'DOCUMENT':
        case 'SECTION_FIELD_DOCUMENT':
            return 'document';
        case 'COUNTRY':
            return 'multi-select';
        case 'BOOLEAN':
            return 'boolean';
        default:
            throw new Error(`Unhandled filterType: ${filterType}`);
    }
};

const getFilterDateType = (rule?: ReportFilterRule): DateFilter['dateType'] => {
    switch (rule) {
        case ReportFilterRule.GREATER_THAN_EQUALS:
            return 'WITHIN_THE_LAST';
        case ReportFilterRule.LOWER_THAN_EQUALS:
            return 'MORE_THAN';
        case ReportFilterRule.BETWEEN:
            return 'BETWEEN';
        default:
            return undefined;
    }
};
const getSelectFilterRule = (rule?: ReportFilterRule): SelectFilter['rule'] => {
    switch (rule) {
        case ReportFilterRule.NOT_EQUALS:
        case ReportFilterRule.NOT_IN:
            return 'NOT_EQUALS';
        case ReportFilterRule.IN:
        case ReportFilterRule.EQUALS:
            return 'EQUALS';
    }
    return 'EQUALS';
};

const getValuesFromReportFilter = (filterType: SectionFieldValueType, filter: ReportFilter): string[] | number[] | LocalDate[] | boolean[] => {
    // Based on BE enum ReportFilterType
    switch (filterType) {
        case 'STRING':
        case 'ENUM':
            return filter.values?.map(v => v.stringValue).filter(value => value);
        case 'NUMBER':
            return filter.values?.map(v => v.numberValue).filter(value => value);
        case 'REFERENCE':
            return filter.values?.map(v => v.referenceId).filter(value => value);
        case 'DATE':
            return filter.values?.map(v => v.dateValue).filter(value => value);
        default:
            return [];
    }
};

const isCountryFilter = (filter: { fieldType: ReportFieldType }): boolean => {
    return (['EMPLOYEE_NATIONALITY', 'ADDRESS_COUNTRY'] satisfies ReportFieldType[] as ReportFieldType[]).includes(filter.fieldType);
};

const isDepartmentFilter = (filter: { fieldType: ReportFieldType }): boolean => {
    return (['EMPLOYMENT_DEPARTMENT', 'CURRENT_EMPLOYMENT_DEPARTMENT'] satisfies ReportFieldType[] as ReportFieldType[]).includes(filter.fieldType);
};

const getSelectProps = (
    filter: AvailableReportField,
): Pick<SelectFilter, 'selectMode' | 'options'> | Pick<AsyncSelectFilter, 'selectMode' | 'fetchOptions'> => {
    // override the default options for specific filterType
    if (isCountryFilter(filter)) {
        return {
            selectMode: 'SYNC',
            options: getCountryList(),
        };
    }

    if (filter.filterType === 'ENUM') {
        const enumOptions = getEnumFieldTypeOptions(filter.fieldType);

        if (enumOptions.length) {
            return {
                selectMode: 'SYNC',
                options: enumOptions,
            };
        }
    } else {
        const fetchEmployees = () =>
            searchEmployees().then(employees =>
                employees?.map(
                    employee =>
                        ({
                            label: employee.displayName,
                            value: employee.id,
                        }) as SelectFilterOption,
                ),
            );
        const filtersOfTypeReference = {
            EMPLOYEE: fetchEmployees,
            EMPLOYMENT_MANAGER: fetchEmployees,
            OBJECTIVE_ASSIGNEE: fetchEmployees,
            OBJECTIVE_CREATED_BY: fetchEmployees,
            DOCUMENT_CREATED_BY: fetchEmployees,
            ADDRESS_UPDATED_BY: fetchEmployees,
            EMPLOYEE_SECTION_ROW_UPDATED_BY: fetchEmployees,
            EMPLOYMENT_SUBORDINATES: fetchEmployees,
            EMPLOYMENT_LOCATION: () =>
                getLocations().then(locations =>
                    locations?.map(
                        location =>
                            ({
                                label: location.name,
                                value: location.id,
                            }) as SelectFilterOption,
                    ),
                ),
            DOCUMENT_FOLDER_NAME: () =>
                getFolders().then(folders =>
                    folders?.map(
                        folder =>
                            ({
                                label: getLabelTranslation(folder.name),
                                value: folder.id,
                            }) as SelectFilterOption,
                    ),
                ),
            DOCUMENT_TAGS: () =>
                getDocumentTags().then(documentTags =>
                    documentTags?.map(
                        tag =>
                            ({
                                label: getLabelTranslation(tag.name),
                                value: tag.id,
                            }) as SelectFilterOption,
                    ),
                ),
            EMPLOYMENT_DEPARTMENT: () =>
                searchDepartments().then(departments =>
                    departments?.map(
                        department =>
                            ({
                                label: getLabelTranslation(department.name),
                                value: department.id,
                            }) as SelectFilterOption,
                    ),
                ),
            EMPLOYMENT_JOB: () =>
                getJobs().then(jobs =>
                    jobs?.map(
                        job =>
                            ({
                                label: getLabelTranslation(job.name),
                                value: job.id,
                            }) as SelectFilterOption,
                    ),
                ),
            EMPLOYMENT_JOB_FAMILY: () =>
                getJobFamilies().then(jobFamilies =>
                    jobFamilies?.map(
                        jobFamily =>
                            ({
                                label: jobFamily.name,
                                value: jobFamily.id,
                            }) as SelectFilterOption,
                    ),
                ),
            LEAVE_CORRECTION_LEAVE_TYPE_TITLE: () =>
                getLeaveTypes().then(leaveTypes =>
                    leaveTypes?.map(
                        leaveType =>
                            ({
                                label: getLabelTranslation(leaveType.name),
                                value: leaveType.id,
                            }) as SelectFilterOption,
                    ),
                ),
            OBJECTIVE_CATEGORY: () =>
                searchObjectiveCategories({}).then(categories =>
                    categories?.map(
                        category =>
                            ({
                                label: getLabelTranslation(category.name),
                                value: category.id,
                            }) as SelectFilterOption,
                    ),
                ),
            OBJECTIVE_COMPLETION_STATUS: () =>
                searchCompletionStatuses().then(statuses =>
                    statuses?.map(
                        status =>
                            ({
                                label: getLabelTranslation(status.name),
                                value: status.id,
                            }) as SelectFilterOption,
                    ),
                ),
            REVIEW_FEEDBACK_REVIEWER: fetchEmployees,
            EMPLOYMENT_COST_CENTERS: () =>
                searchCostCenters().then(costCenters =>
                    costCenters?.map(
                        costCenter =>
                            ({
                                label: costCenter.name,
                                value: costCenter.id,
                            }) as SelectFilterOption,
                    ),
                ),
            WORKING_PATTERN_CALENDAR: () =>
                getCalendars().then(calendar =>
                    calendar?.map(
                        calendar =>
                            ({
                                label: calendar.name,
                                value: calendar.id,
                            }) as SelectFilterOption,
                    ),
                ),
        };

        // Utility type to remove prefix 'CURRENT_' from a string union type
        type RemovePrefix<T extends string, Prefix extends string> = T extends `${Prefix}${infer U}` ? U : T;

        const fieldType = filter.fieldType?.replace('CURRENT_', '') as RemovePrefix<ReportFieldType, 'CURRENT_'>;

        if (fieldType in filtersOfTypeReference) {
            return {
                selectMode: 'ASYNC',
                fetchOptions: filtersOfTypeReference[fieldType as keyof typeof filtersOfTypeReference],
            };
        }
    }
    throw new Error(`Impossible to get props for filter: ${filter.fieldType}`);
};

const isFilterEquals =
    (filter: ReportFilterItemBar) =>
    (f: ReportFilter): boolean =>
        // For non custom fields, we compare the field type
        f.fieldType === filter?.fieldType &&
        // For custom fields, we compare the section field definition id
        (!filter.sectionFieldDefinition?.id || f.fieldDefinition.id === filter.sectionFieldDefinition.id);

/**
 * Return visible async filters
 * ex: department, job, ...
 * @param filters
 */
const getVisibleAsyncFilters = (filters: ReportFilterItemBar[]): AsyncSelectFilter[] => {
    return filters.filter(filter => {
        return (
            (filter.type === 'multi-select' || filter.type === 'select') &&
            filter.selectMode === 'ASYNC' &&
            filter.hide === false &&
            // search value with empty label
            filter.value?.find(option => !option.label)
        );
    }) as AsyncSelectFilter[];
};

const convertAsyncFilter = (filter: AsyncSelectFilter, options: SelectFilterOption[]) => {
    return {
        ...(filter as ReportFilterItemBar),
        value: filter.value?.map(value => {
            const option = options.find(option => option.value?.toString() === value.value.toString());
            return option ?? value;
        }),
        options,
        selectMode: 'SYNC',
    } as ReportFilterItemBar;
};

const mergeFilters = (filters: ReportFilterItemBar[], updatedFilter: (ReportFilterItemBar | undefined)[]) =>
    filters.map(f => {
        const newFilter = updatedFilter.find(nf => nf?.fieldType === f.fieldType);
        return newFilter ?? f;
    });
