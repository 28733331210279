import { API_BASE_URL, client } from '@/api/common';
import {
    ThirdParty,
    ThirdPartyIntegrationLog,
    ThirdPartyIntegrationLogGroup,
    ThirdPartyLogSearchRequest,
    ThirdPartyMutation,
    ThirdPartyPublicApiIntegration,
    ThirdPartyUpdateFixedLog,
} from '@/domain/third-party/ThirdParty.model';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';
import { AxiosResponse } from 'axios';

type ThirdPartyIntegrationLogsDTO = DateToString<ThirdPartyIntegrationLog>;
type ThirdPartyPublicApiIntegrationDTO = DateToString<ThirdPartyPublicApiIntegration>;
type ThirdPartyIntegrationLogsWithNameDTO = Overwrite<
    ThirdPartyIntegrationLogGroup,
    {
        thirdPartyIntegrationLogs: ThirdPartyIntegrationLogsDTO[];
    }
>;

type ThirdPartyDTO = Overwrite<
    ThirdParty,
    {
        publicApiIntegration?: ThirdPartyPublicApiIntegrationDTO;
    }
>;

type ThirdPartyMutationDTO = ThirdPartyMutation;

const THIRD_PARTY_API_BASE_PATH = API_BASE_URL + `/third-parties`;

const searchThirdParties = async (): Promise<ThirdParty[]> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/search`;
    return (await client.post<ThirdPartyDTO[], AxiosResponse<ThirdPartyDTO[]>>(url, {})).data.map(convertToThirdParty);
};

const getThirdParty = async (thirdPartyId: number): Promise<ThirdParty> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}`;
    const { data } = await client.get<ThirdPartyDTO, AxiosResponse<ThirdPartyDTO>>(url);
    return convertToThirdParty(data);
};

const createThirdParty = async (request: ThirdPartyMutation): Promise<ThirdParty> => {
    const { data } = await client.post<ThirdPartyDTO, AxiosResponse<ThirdPartyDTO>, ThirdPartyMutationDTO>(THIRD_PARTY_API_BASE_PATH, request);
    return convertToThirdParty(data);
};

const updateThirdParty = async (thirdPartyId: number, request: ThirdPartyMutation): Promise<ThirdParty> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}`;
    const { data } = await client.put<ThirdPartyDTO, AxiosResponse<ThirdPartyDTO>, ThirdPartyMutationDTO>(url, request);
    return convertToThirdParty(data);
};

const deleteThirdParty = async (thirdPartyId: number): Promise<void> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}`;
    await client.delete(url);
};

const toggleThirdParty = async (thirdPartyId: number, enabled: boolean): Promise<ThirdParty> => {
    const action = enabled ? 'enable' : 'disable';
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}/${action}`;
    const { data } = await client.post<
        ThirdPartyDTO,
        AxiosResponse<ThirdPartyDTO>,
        {
            enabled: boolean;
        }
    >(url, { enabled });
    return convertToThirdParty(data);
};

const generateApiKey = async (thirdPartyId: number): Promise<string> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}/api-key`;
    const { data } = await client.post<string, AxiosResponse<string>>(url);
    return data;
};

const convertToThirdParty = ({ publicApiIntegration, ...restDto }: ThirdPartyDTO): ThirdParty => {
    const publicApi: ThirdParty['publicApiIntegration'] = publicApiIntegration
        ? {
              ...publicApiIntegration,
              lastSignInAt: convertUTCIsoStringToDate(publicApiIntegration.lastSignInAt),
              expireAt: publicApiIntegration.expireAt,
          }
        : undefined;
    return {
        ...restDto,
        publicApiIntegration: publicApi,
    };
};

const convertThirdPartyIntegrationLog = (t: ThirdPartyIntegrationLogsDTO) => ({
    ...t,
    createdAt: convertUTCIsoStringToDate(t.createdAt),
    updatedAt: convertUTCIsoStringToDate(t.updatedAt),
    publishedAt: convertUTCIsoStringToDate(t.publishedAt),
    processedAt: convertUTCIsoStringToDate(t.processedAt),
});

const getThirdPartyIntegrationLogs = async (thirdPartyIntegrationId: number): Promise<ThirdPartyIntegrationLog[]> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/integrations/${thirdPartyIntegrationId}/logs`;
    const { data } = await client.get<ThirdPartyIntegrationLogsDTO[], AxiosResponse<ThirdPartyIntegrationLogsDTO[]>>(url);
    return data.map(t => convertThirdPartyIntegrationLog(t));
};

const convertThirdPartyIntegrationLogsWithGroup = (t: ThirdPartyIntegrationLogsWithNameDTO) => ({
    ...t,
    thirdPartyIntegrationLogs: t.thirdPartyIntegrationLogs.map(log => ({
        ...log,
        createdAt: convertUTCIsoStringToDate(log.createdAt),
        updatedAt: convertUTCIsoStringToDate(log.updatedAt),
        publishedAt: convertUTCIsoStringToDate(log.publishedAt),
        processedAt: convertUTCIsoStringToDate(log.processedAt),
    })),
});

const searchThirdPartyIntegrationLogs = async (request: ThirdPartyLogSearchRequest): Promise<ThirdPartyIntegrationLogGroup[]> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/integrations/logs/search`;
    const { data } = await client.post<
        ThirdPartyIntegrationLogsWithNameDTO[],
        AxiosResponse<ThirdPartyIntegrationLogsWithNameDTO[]>,
        ThirdPartyLogSearchRequest
    >(url, request);
    return data.map(t => convertThirdPartyIntegrationLogsWithGroup(t));
};

const updateThirdPartyIntegrationLogsFixed = async (
    thirdPartyIntegrationId: number,
    thirdPartyIntegrationLogId: number,
    request: ThirdPartyUpdateFixedLog,
): Promise<void> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/integrations/${thirdPartyIntegrationId}/logs/${thirdPartyIntegrationLogId}/fixed`;
    const { data } = await client.patch<ThirdPartyUpdateFixedLog, AxiosResponse<void>>(url, request);
    return data;
};

export const thirdPartyAPI = {
    searchThirdParties,
    getThirdParty,
    getThirdPartyIntegrationLogs,
    createThirdParty,
    updateThirdParty,
    deleteThirdParty,
    toggleThirdParty,
    searchThirdPartyIntegrationLogs,
    updateThirdPartyIntegrationLogsFixed,
    generateApiKey,
};
