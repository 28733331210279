import { getObjectiveSetting } from '@/domain/objective-setting/ObjectiveSetting.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const objectiveSettingKeys = createQueryKeys('objectiveSetting', {
    get: {
        queryKey: getNull(),
    },
});

export const useGetObjectiveSetting = createQueryHook(objectiveSettingKeys.get, getObjectiveSetting);
