import { timesheetPaymentsSearch } from '@/domain/timesheet-payment/TimesheetPayment.service';
import {
    countPendingTimesheets,
    getClockOutForceBreakDuration,
    searchEmployeeMonthTimesheets,
    searchEmployeeMonthlyTimesheets,
    searchEmployeeTimesheets,
    searchPendingTimesheets,
    searchTimesheets,
    getAllowClockInOutsideWorkingHours,
    getLastTimesheetClockInOut,
    clockInOut,
    deleteLastTimesheetClockInOut,
} from '@/domain/timesheet/Timesheet.service';
import { createMutationHook, createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const timesheetKeys = createQueryKeys('timesheets', {
    searchEmployeeTimesheets: (...params: Parameters<typeof searchEmployeeTimesheets>) => ({
        queryKey: params,
    }),
    searchPendingTimesheets: (...params: Parameters<typeof searchPendingTimesheets>) => ({
        queryKey: params,
    }),
    countPendingTimesheets: (...optionalParam: Parameters<typeof countPendingTimesheets>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    searchTimesheets: (...params: Parameters<typeof searchTimesheets>) => ({
        queryKey: params,
    }),
    searchEmployeeMonthlyTimesheets: (...params: Parameters<typeof searchEmployeeMonthlyTimesheets>) => ({
        queryKey: params,
    }),
    searchTimesheetPayments: (...params: Parameters<typeof timesheetPaymentsSearch>) => ({
        queryKey: params,
    }),
    searchEmployeeMonthTimesheets: (...params: Parameters<typeof searchEmployeeMonthTimesheets>) => ({
        queryKey: params,
    }),
    getClockOutForceBreakDuration: (...params: Parameters<typeof getClockOutForceBreakDuration>) => ({
        queryKey: params,
    }),
    getAllowClockInOutsideWorkingHours: (...params: Parameters<typeof getAllowClockInOutsideWorkingHours>) => ({
        queryKey: params,
    }),
    getLastTimesheetClockInOut: (...params: Parameters<typeof getLastTimesheetClockInOut>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeTimesheets = createQueryHook(timesheetKeys.searchEmployeeTimesheets, searchEmployeeTimesheets);

export const useGetPendingTimesheets = createQueryHook(timesheetKeys.searchPendingTimesheets, searchPendingTimesheets);

export const useCountTimesheetPending = createQueryHook(timesheetKeys.countPendingTimesheets, countPendingTimesheets);

export const useGetTimesheets = createQueryHook(timesheetKeys.searchTimesheets, searchTimesheets);

export const useGetEmployeeMonthlyTimesheets = createRequiredParamsQueryHook(
    timesheetKeys.searchEmployeeMonthlyTimesheets,
    searchEmployeeMonthlyTimesheets,
    // By default, queries that unmount or become unused before their promises are resolved are not cancelled.
    // This means that after the promise has resolved, the resulting data will be available in the cache.
    // This is helpful if you've started receiving a query, but then unmount the component before it finishes.
    //  If you mount the component again and the query has not been garbage collected yet, data will be available.
    {
        gcTime: 0,
    },
);

export const useTimesheetPaymentsSearch = createQueryHook(timesheetKeys.searchTimesheetPayments, timesheetPaymentsSearch);

export const useGetEmployeeMonthTimesheets = createQueryHook(timesheetKeys.searchEmployeeMonthTimesheets, searchEmployeeMonthTimesheets);

// CLOCK IN/OUT hooks

export const useGetClockOutForceBreakDuration = createQueryHook(timesheetKeys.getClockOutForceBreakDuration, getClockOutForceBreakDuration);

export const useGetAllowClockInOutsideWorkingHours = createRequiredParamsQueryHook(
    timesheetKeys.getAllowClockInOutsideWorkingHours,
    getAllowClockInOutsideWorkingHours,
);

export const useGetLastTimesheetClockInOut = createRequiredParamsQueryHook(timesheetKeys.getLastTimesheetClockInOut, getLastTimesheetClockInOut);

export const clockInOutMutation = createMutationHook(clockInOut, {
    onSuccess: ({ variables: { employeeId }, queryClient }) => {
        return Promise.all([
            queryClient.invalidateQueries(timesheetKeys.getLastTimesheetClockInOut(employeeId)),
            queryClient.invalidateQueries({
                queryKey: timesheetKeys.searchTimesheets._def,
            }),
        ]);
    },
});

export const deleteLastTimesheetClockInOutMutation = createMutationHook(deleteLastTimesheetClockInOut, {
    onSuccess: ({ variables: employeeId, queryClient }) => {
        return Promise.all([
            queryClient.invalidateQueries(timesheetKeys.getLastTimesheetClockInOut(employeeId)),
            queryClient.invalidateQueries({
                queryKey: timesheetKeys.searchTimesheets._def,
            }),
        ]);
    },
});
