import { planningApi } from '@/api/planning/Planning.api';
import { Planning, PlanningMutation, PlanningSearch } from '@/domain/planning/Planning.model';
import { ShiftCoverage, ShiftCoverageCreationRequest } from '@/domain/shift/Shift.model';
import { ShiftCoverageFormValues } from '@/page/planning/shift-coverage-dialog/ShiftCoverageDialog';

export const getPlanningById = (planningId: number): Promise<Planning> => {
    return planningApi.getPlanningById(planningId);
};

export const updatePlanning = (planningId: number, mutation: PlanningMutation): Promise<Planning> => {
    return planningApi.updatePlanning(planningId, mutation);
};

export const deletePlanning = (planningId: number): Promise<void> => {
    return planningApi.deletePlanning(planningId);
};

export const createPlanning = (mutation: PlanningMutation): Promise<Planning> => {
    return planningApi.createPlanning(mutation);
};

export const searchPlannings = (request: PlanningSearch = {}): Promise<Planning[]> => {
    return planningApi.searchPlannings(request);
};
export const convertShiftCoverageToShiftCoverageFormValues = (shiftCoverage: ShiftCoverage): ShiftCoverageFormValues => {
    return {
        tagId: shiftCoverage.tag.id,
        locationId: shiftCoverage.location.id,
        startDate: shiftCoverage.startDate,
        endDate: shiftCoverage.endDate,
        repeatEveryXWeek: shiftCoverage.repeatEveryXWeek,
        repeatOn: shiftCoverage.repeatOn,
        startTime: shiftCoverage.startTime,
        endTime: shiftCoverage.endTime,
        requiredAmount: shiftCoverage.requiredAmount,
    };
};
export const convertFormValuesToMutationRequest = (formValues: ShiftCoverageFormValues): ShiftCoverageCreationRequest => {
    const { tagId, locationId, ...restValues } = formValues;
    return {
        tagId: Number(tagId),
        locationId: Number(locationId),
        ...restValues,
    };
};
