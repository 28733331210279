import thankYouImg from '@/assets/images/survey-thank-you.svg';
import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const SurveyQuestionsAnswered: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack component={Paper} p={6} maxWidth={720} margin='auto'>
            <img src={thankYouImg} alt='questions' />
            <Typography
                variant='h1'
                sx={theme => ({
                    margin: '32px auto',
                    fontSize: 18,
                    [theme.breakpoints.down('md')]: {
                        marginBottom: 24,

                        display: 'block',
                    },
                })}
            >
                {t('anonymous_surveys.you_have_completed_this_survey')}
            </Typography>
        </Stack>
    );
};
