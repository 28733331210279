import { getReviewRatingScale, searchReviewRatingScales } from '@/domain/review-rating-scale/ReviewRatingScale.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const reviewRatingScaleKeys = createQueryKeys('reviewRatingScales', {
    search: (...params: Parameters<typeof searchReviewRatingScales>) => ({
        queryKey: params,
    }),
    getById: (...params: Parameters<typeof getReviewRatingScale>) => ({
        queryKey: params,
    }),
});

export const useGetReviewRatingScales = createQueryHook(reviewRatingScaleKeys.search, searchReviewRatingScales);

export const useGetReviewRatingScale = createRequiredParamsQueryHook(reviewRatingScaleKeys.getById, getReviewRatingScale);
