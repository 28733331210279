import { TimesheetMissingModeArray, TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { DayOfWeek, getDurationFromTime, isBeforeTime, isSameTime, isValidTime, MONTHS } from '@/utils/datetime.util';
import * as yup from 'yup';
import i18next from 'i18next';

export const getTimesheetSettingSchema = () => {
    return yup.object().shape({
        name: yup.string().required(),
        timesheetMode: yup.string().oneOf(Object.values(TimesheetMode)).required(),
        autofillTimesheet: yup.boolean().required(),
        includeShiftsIntoTimesheets: yup.boolean().default(false),
        allowFutureInputs: yup.boolean().default(true).required(),
        isBonusEnabled: yup.boolean().required(),
        timesheetMissingMode: yup.string().oneOf(Object.values(TimesheetMissingModeArray)).required(),
        countDailyDifference: yup.boolean().required(),
        nightBonusPercentage: yup
            .number()
            .when(['isBonusEnabled', 'timesheetMode'], {
                is: (isBonusEnabled: boolean, timesheetMode: TimesheetMode) => {
                    return !isBonusEnabled || timesheetMode === TimesheetMode.SIMPLIFIED;
                },
                then: schema => schema.nullable(),
                otherwise: schema => schema.required().min(0, i18next.t('time_management_settings_page.time_management_configuration.percentage_validation')),
            })
            .default(0),
        nightBonusStartTime: yup.string<LocalTime>().when(['nightBonusPercentage', 'timesheetMode'], {
            is: (nightBonusPercentage: number, timesheetMode: TimesheetMode) => {
                return nightBonusPercentage === 0 || timesheetMode === TimesheetMode.SIMPLIFIED;
            },
            then: schema => schema,
            otherwise: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.start_time_validation'),
                    test: time => validateBonusStartTime(time),
                }),
        }),
        nightBonusEndTime: yup.string<LocalTime>().when(['nightBonusPercentage', 'timesheetMode'], {
            is: (nightBonusPercentage: number, timesheetMode: TimesheetMode) => {
                return nightBonusPercentage === 0 || timesheetMode === TimesheetMode.SIMPLIFIED;
            },
            then: schema => schema,
            otherwise: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.end_time_validation'),
                    test: (time, meta) => validateBonusEndTime(meta.parent.nightBonusStartTime, time),
                }),
        }),
        saturdayBonusPercentage: yup
            .number()
            .when('isBonusEnabled', {
                is: false,
                then: schema => schema,
                otherwise: schema => schema.required().min(0, i18next.t('time_management_settings_page.time_management_configuration.percentage_validation')),
            })
            .default(0),
        saturdayBonusStartTime: yup.string<LocalTime>().when(['isBonusEnabled', 'saturdayBonusPercentage'], {
            is: (isBonusEnabled: boolean, saturdayBonusPercentage: number) => {
                return isBonusEnabled && saturdayBonusPercentage !== 0;
            },
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        saturdayBonusEndTime: yup.string<LocalTime>().when(['isBonusEnabled', 'saturdayBonusPercentage'], {
            is: (isBonusEnabled: boolean, saturdayBonusPercentage: number) => {
                return isBonusEnabled && saturdayBonusPercentage !== 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.end_time_bonus_validation'),
                    test: function (saturdayBonusEndTime) {
                        const { saturdayBonusStartTime, saturdayFromDayOfWeek, saturdayToDayOfWeek, saturdayBonusPercentage } = this.parent;
                        if (saturdayBonusPercentage === 0) {
                            return true;
                        }
                        if (saturdayBonusEndTime) {
                            return validateBonusDates(saturdayBonusStartTime, saturdayBonusEndTime, saturdayFromDayOfWeek, saturdayToDayOfWeek);
                        }
                        return false;
                    },
                }),
            otherwise: schema => schema,
        }),
        saturdayFromDayOfWeek: yup
            .string()
            .oneOf(Object.values(DayOfWeek) as DayOfWeek[])
            .when(['isBonusEnabled', 'saturdayBonusPercentage'], {
                is: (isBonusEnabled: boolean, saturdayBonusPercentage: number) => {
                    return isBonusEnabled && saturdayBonusPercentage !== 0;
                },
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        saturdayToDayOfWeek: yup
            .string()
            .oneOf(Object.values(DayOfWeek) as DayOfWeek[])
            .when(['isBonusEnabled', 'saturdayBonusPercentage'], {
                is: (isBonusEnabled: boolean, saturdayBonusPercentage: number) => {
                    return isBonusEnabled && saturdayBonusPercentage !== 0;
                },
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        sundayBonusPercentage: yup.number().when('isBonusEnabled', {
            is: false,
            then: schema => schema,
            otherwise: schema => schema.min(0, i18next.t('time_management_settings_page.time_management_configuration.percentage_validation')).required(),
        }),
        sundayBonusStartTime: yup.string<LocalTime>().when(['isBonusEnabled', 'sundayBonusPercentage'], {
            is: (isBonusEnabled: boolean, sundayBonusPercentage: number) => {
                return isBonusEnabled && sundayBonusPercentage !== 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.start_time_bonus_validation'),
                    test: function (sundayBonusStartTime) {
                        const { saturdayToDayOfWeek, saturdayBonusEndTime, sundayFromDayOfWeek, saturdayBonusPercentage, sundayBonusPercentage } = this.parent;
                        if (saturdayBonusPercentage === 0 || sundayBonusPercentage === 0) {
                            return true;
                        }
                        if (saturdayToDayOfWeek === DayOfWeek.SUNDAY && sundayFromDayOfWeek === DayOfWeek.SATURDAY) {
                            return false;
                        }
                        if (sundayBonusStartTime) {
                            return validateBonusDates(saturdayBonusEndTime, sundayBonusStartTime, saturdayToDayOfWeek, sundayFromDayOfWeek);
                        }
                        return false;
                    },
                }),
            otherwise: schema => schema,
        }),
        sundayBonusEndTime: yup.string<LocalTime>().when(['isBonusEnabled', 'sundayBonusPercentage'], {
            is: (isBonusEnabled: boolean, sundayBonusPercentage: number) => {
                return isBonusEnabled && sundayBonusPercentage !== 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.end_time_bonus_validation'),
                    test: function (sundayBonusEndTime) {
                        const { sundayFromDayOfWeek, sundayBonusStartTime, sundayToDayOfWeek, sundayBonusPercentage } = this.parent;
                        if (sundayBonusPercentage === 0) {
                            return true;
                        }
                        if (sundayBonusEndTime) {
                            return validateBonusDates(sundayBonusStartTime, sundayBonusEndTime, sundayFromDayOfWeek, sundayToDayOfWeek);
                        }
                        return false;
                    },
                }),
            otherwise: schema => schema,
        }),
        sundayFromDayOfWeek: yup
            .string()
            .oneOf(Object.values(DayOfWeek) as DayOfWeek[])
            .when(['isBonusEnabled', 'sundayBonusPercentage'], {
                is: (isBonusEnabled: boolean, sundayBonusPercentage: number) => {
                    return isBonusEnabled && sundayBonusPercentage !== 0;
                },
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        sundayToDayOfWeek: yup
            .string()
            .oneOf(Object.values(DayOfWeek) as DayOfWeek[])
            .when(['isBonusEnabled', 'sundayBonusPercentage'], {
                is: (isBonusEnabled: boolean, sundayBonusPercentage: number) => {
                    return isBonusEnabled && sundayBonusPercentage !== 0;
                },
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        cycleStartMonth: yup
            .string()
            .oneOf(Object.values(MONTHS) as MONTHS[])
            .required(),
        mandatoryComment: yup.boolean().required(),
        breakDisplayEnabled: yup.boolean().required(),
        mobileClockInOut: yup.boolean().required(),
        allowClockInOutOutsideWorkHours: yup.boolean().required(),
        allowClockInOutOnSundayAndPublicHolidays: yup.boolean().required(),
        forceBreakClockInOut: yup.boolean().required(),
        forceFirstBreakDurationInMinutes: yup
            .number()
            .required()
            .when(['forceBreakClockInOut', 'timesheetMode'], {
                is: (forceBreakClockInOut: boolean, timesheetMode: TimesheetMode) => {
                    return forceBreakClockInOut && timesheetMode !== TimesheetMode.SIMPLIFIED;
                },
                then: schema => schema.min(0.5, i18next.t('time_management_settings_page.time_management_configuration.force_break_clock_in_out_not_empty')),
                otherwise: schema => schema.min(0),
            }),
        forceFirstBreakAfterXHours: yup
            .number()
            .when(['forceBreakClockInOut', 'timesheetMode'], {
                is: (forceBreakClockInOut: boolean, timesheetMode: TimesheetMode) => {
                    return forceBreakClockInOut && timesheetMode !== TimesheetMode.SIMPLIFIED;
                },
                then: schema =>
                    schema.required().min(0.5, i18next.t('time_management_settings_page.time_management_configuration.force_break_clock_in_out_not_empty')),
                otherwise: schema => schema.min(0),
            })
            .default(0),
        forceSecondBreakDurationInMinutes: yup
            .number()
            .required()
            .min(0)
            .test({
                message: i18next.t('time_management_settings_page.time_management_configuration.force_break_rule_2_must_be_bigger'),
                test: function (forceSecondBreakDurationInMinutes) {
                    const { forceFirstBreakDurationInMinutes } = this.parent;
                    if (forceSecondBreakDurationInMinutes === 0) {
                        return true;
                    }
                    return forceSecondBreakDurationInMinutes >= forceFirstBreakDurationInMinutes;
                },
            }),
        forceSecondBreakAfterXHours: yup
            .number()
            .when(['forceBreakClockInOut', 'forceSecondBreakDurationInMinutes'], {
                is: (forceBreakClockInOut: boolean, forceSecondBreakDurationInMinutes: number) => {
                    return forceBreakClockInOut && forceSecondBreakDurationInMinutes > 0;
                },
                then: schema =>
                    schema
                        .required()
                        .min(0.5, i18next.t('time_management_settings_page.time_management_configuration.force_break_clock_in_out_not_empty'))
                        .test({
                            message: i18next.t('time_management_settings_page.time_management_configuration.force_break_rule_2_must_be_bigger'),
                            test: function (forceSecondBreakAfterXHours) {
                                const { forceFirstBreakAfterXHours } = this.parent;
                                return forceSecondBreakAfterXHours > forceFirstBreakAfterXHours;
                            },
                        }),
                otherwise: schema => schema.min(0),
            })
            .default(0),
        forceThirdBreakDurationInMinutes: yup
            .number()
            .required()
            .min(0)
            .test({
                message: i18next.t('time_management_settings_page.time_management_configuration.force_break_rule_3_must_be_bigger'),
                test: function (forceThirdBreakDurationInMinutes) {
                    const { forceSecondBreakDurationInMinutes } = this.parent;
                    if (forceThirdBreakDurationInMinutes === 0) {
                        return true;
                    }
                    if (forceSecondBreakDurationInMinutes === 0) {
                        return false;
                    }
                    return forceThirdBreakDurationInMinutes >= forceSecondBreakDurationInMinutes;
                },
            }),
        forceThirdBreakAfterXHours: yup
            .number()
            .when(['forceBreakClockInOut', 'forceThirdBreakDurationInMinutes'], {
                is: (forceBreakClockInOut: boolean, forceThirdBreakDurationInMinutes: number) => {
                    return forceBreakClockInOut && forceThirdBreakDurationInMinutes > 0;
                },
                then: schema =>
                    schema
                        .required()
                        .min(0.5, i18next.t('time_management_settings_page.time_management_configuration.force_break_clock_in_out_not_empty'))
                        .test({
                            message: i18next.t('time_management_settings_page.time_management_configuration.force_break_rule_3_must_be_bigger'),
                            test: function (forceThirdBreakAfterXHours) {
                                const { forceSecondBreakAfterXHours } = this.parent;
                                return forceThirdBreakAfterXHours > forceSecondBreakAfterXHours;
                            },
                        }),
                otherwise: schema => schema.min(0),
            })
            .default(0),
        isForceBreakToBeTakenEnabled: yup.boolean().required(),
        forceBreakToBeTakenFrom: yup.string<LocalTime>().when(['isForceBreakToBeTakenEnabled', 'forceBreakClockInOut'], {
            is: (isForceBreakToBeTakenEnabled: boolean, forceBreakClockInOut: boolean) => {
                return isForceBreakToBeTakenEnabled && forceBreakClockInOut;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.start_time_validation'),
                    test: time => validateStartTime(time),
                }),
            otherwise: schema => schema.nullable(),
        }),
        forceBreakToBeTakenTo: yup.string<LocalTime>().when(['isForceBreakToBeTakenEnabled', 'forceBreakClockInOut'], {
            is: (isForceBreakToBeTakenEnabled: boolean, forceBreakClockInOut: boolean) => {
                return isForceBreakToBeTakenEnabled && forceBreakClockInOut;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.end_time_validation'),
                    test: (time, meta) => validateEndTime(meta.parent.forceBreakToBeTakenFrom, time),
                }),
            otherwise: schema => schema.nullable(),
        }),
        paidBreaksSundayPublicHolidays: yup.boolean().required(),
        paidBreaksSundayPublicHolidaysMaximumDurationInMinutes: yup
            .number()
            .max(1120)
            .default(0)
            .required()
            .when(['paidBreaksSundayPublicHolidays', 'timesheetMode'], {
                is: (paidBreaksSundayPublicHolidays: boolean, timesheetMode: TimesheetMode) => {
                    return paidBreaksSundayPublicHolidays && timesheetMode !== TimesheetMode.SIMPLIFIED;
                },
                then: schema => schema.min(1),
                otherwise: schema => schema.min(0),
            }),
        paidBreaksSaturday: yup.boolean().required(),
        paidBreaksSaturdayMaximumDurationInMinutes: yup
            .number()
            .max(1120)
            .default(0)
            .required()
            .when(['paidBreaksSaturday', 'timesheetMode'], {
                is: (paidBreaksSaturday: boolean, timesheetMode: TimesheetMode) => {
                    return paidBreaksSaturday && timesheetMode !== TimesheetMode.SIMPLIFIED;
                },
                then: schema => schema.min(1),
                otherwise: schema => schema.min(0),
            }),
        isPaidBreaksEnabled: yup.boolean().required(),
        rule1PaidBreaksFrom: yup.string<LocalTime>().when(['isPaidBreaksEnabled', 'rule1PaidBreaksMaximumDurationInMinutes'], {
            is: (isPaidBreaksEnabled: boolean, rule1PaidBreaksMaximumDurationInMinutes: number) => {
                return isPaidBreaksEnabled && rule1PaidBreaksMaximumDurationInMinutes > 0;
            },
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        rule1PaidBreaksTo: yup.string<LocalTime>().when(['isPaidBreaksEnabled', 'rule1PaidBreaksMaximumDurationInMinutes'], {
            is: (isPaidBreaksEnabled: boolean, rule1PaidBreaksMaximumDurationInMinutes: number) => {
                return isPaidBreaksEnabled && rule1PaidBreaksMaximumDurationInMinutes > 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.rule_paid_breaks_overlapping'),
                    test: (time, meta) =>
                        validateNoOverlapping(
                            meta.parent.rule2PaidBreaksMaximumDurationInMinutes,
                            meta.parent.rule2PaidBreaksFrom,
                            meta.parent.rule2PaidBreaksTo,
                            meta.parent.rule1PaidBreaksFrom,
                            time,
                        ),
                }),
            otherwise: schema => schema,
        }),
        rule1PaidBreaksMaximumDurationInMinutes: yup.number().min(0).max(1120).default(0).required(),
        rule2PaidBreaksFrom: yup.string<LocalTime>().when(['isPaidBreaksEnabled', 'rule2PaidBreaksMaximumDurationInMinutes'], {
            is: (isPaidBreaksEnabled: boolean, rule2PaidBreaksMaximumDurationInMinutes: number) => {
                return isPaidBreaksEnabled && rule2PaidBreaksMaximumDurationInMinutes > 0;
            },
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        rule2PaidBreaksTo: yup.string<LocalTime>().when(['isPaidBreaksEnabled', 'rule2PaidBreaksMaximumDurationInMinutes'], {
            is: (isPaidBreaksEnabled: boolean, rule2PaidBreaksMaximumDurationInMinutes: number) => {
                return isPaidBreaksEnabled && rule2PaidBreaksMaximumDurationInMinutes > 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.rule_paid_breaks_overlapping'),
                    test: (time, meta) =>
                        validateNoOverlapping(
                            meta.parent.rule1PaidBreaksMaximumDurationInMinutes,
                            meta.parent.rule1PaidBreaksFrom,
                            meta.parent.rule1PaidBreaksTo,
                            meta.parent.rule2PaidBreaksFrom,
                            time,
                        ),
                }),
            otherwise: schema => schema,
        }),
        rule2PaidBreaksMaximumDurationInMinutes: yup.number().min(0).max(1120).default(0).required(),
        forceShiftStartTimeOnClockIn: yup.boolean().required(),
        forceShiftStartTimeBeforeInMinutes: yup
            .number()
            .min(0)
            .when('forceShiftStartTimeOnClockIn', {
                is: true,
                then: schema => schema.max(240),
            })
            .default(0),
        forceShiftStartTimeAfterInMinutes: yup
            .number()
            .min(0)
            .when('forceShiftStartTimeOnClockIn', {
                is: true,
                then: schema => schema.max(240),
            })
            .default(0),
        forceShiftEndTimeOnClockOut: yup.boolean().required(),
        forceShiftEndTimeAfterInMinutes: yup
            .number()
            .min(0)
            .when('forceShiftEndTimeOnClockOut', {
                is: true,
                then: schema => schema.max(240),
            })
            .default(0),
        forceShiftEndTimeBeforeInMinutes: yup
            .number()
            .min(0)
            .when('forceShiftEndTimeOnClockOut', {
                is: true,
                then: schema => schema.max(240),
            })
            .default(0),
        maximumWeeklyAdditionalWorkingTime: yup.number().min(0).max(100).default(45).required(),
        isAutoApproveDifferenceThresholdInMinutesEnabled: yup.boolean().required(),
        autoApproveDifferenceThresholdInMinutes: yup.number().when('isAutoApproveDifferenceThresholdInMinutesEnabled', {
            is: true,
            then: schema => schema.min(1).max(1440).required(), //1440 is 24 hours in minutes (max)
            otherwise: schema => schema.nullable(),
        }),
        isMissingTimesheetThresholdInMinutesEnabled: yup.boolean().required(),
        missingTimesheetThresholdInMinutes: yup.number().min(0).max(480), // 480 is 8hours in minutes (max)
    });
};

const validateBonusEndTime = (startTime: LocalTime, endTime: LocalTime) => {
    const MINUTES_IN_17_HOURS = 1020;
    const MINUTES_IN_9_HOURS = 540;
    const start = getDurationFromTime(startTime);
    const end = getDurationFromTime(endTime);

    if (start < MINUTES_IN_17_HOURS && isBeforeTime(startTime, endTime)) {
        return false;
    }

    return 0 < end && end <= MINUTES_IN_9_HOURS;
};

const validateBonusStartTime = (startTime: LocalTime) => {
    if (!isValidTime(startTime)) {
        return false;
    }

    const MINUTES_IN_17_HOURS = 1020;
    const MINUTES_IN_24_HOURS = 1440;
    const MINUTES_IN_2_HOURS = 120;

    const start = getDurationFromTime(startTime);

    return (MINUTES_IN_17_HOURS <= start && start < MINUTES_IN_24_HOURS) || start <= MINUTES_IN_2_HOURS;
};

const validateEndTime = (startTime: LocalTime, endTime: LocalTime) => {
    return isBeforeTime(startTime, endTime) || isSameTime(startTime, endTime);
};

const validateNoOverlapping = (maximumDuration: number, ruleStartTime: LocalTime, ruleEndTime: LocalTime, startTime: LocalTime, endTime: LocalTime) => {
    if (maximumDuration === 0) {
        //there is no need to validate the overlapping
        return true;
    }

    if (!isValidTime(startTime) || !isValidTime(endTime) || !isValidTime(ruleStartTime) || !isValidTime(ruleEndTime)) {
        return false;
    }

    const start = getDurationFromTime(startTime);
    const end = getDurationFromTime(endTime);
    const ruleStart = getDurationFromTime(ruleStartTime);
    const ruleEnd = getDurationFromTime(ruleEndTime);

    //we need to check if the rules is over the night, if so we need to split into 2
    if (ruleStart > ruleEnd) {
        return isOverlapping(ruleStart, 1440, start, end) && isOverlapping(0, ruleEnd, start, end);
    }

    return isOverlapping(ruleStart, ruleEnd, start, end);
};

const isOverlapping = (ruleStartTime: number, ruleEndTime: number, start: number, end: number) => {
    return end <= ruleStartTime || start >= ruleEndTime;
};

const validateStartTime = (startTime: LocalTime) => {
    return isValidTime(startTime);
};

const validateBonusDates = (bonusStartTime: LocalTime, bonusEndTime: LocalTime, bonusFromDayOfWeek: DayOfWeek, bonusToDayOfWeek: DayOfWeek) => {
    if (bonusFromDayOfWeek === bonusToDayOfWeek) {
        const startMinutes = getDurationFromTime(bonusStartTime);
        const endMinutes = getDurationFromTime(bonusEndTime);
        if (startMinutes > endMinutes) {
            return false;
        }
    }
    return true;
};
