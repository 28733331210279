import { getEmployeeAddresses } from '@/domain/employee/Employee.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeAddressKeys = createQueryKeys('employeeAddresses', {
    get: (...params: Parameters<typeof getEmployeeAddresses>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeAddresses = createRequiredParamsQueryHook(employeeAddressKeys.get, getEmployeeAddresses);
