import { searchEmployees } from '@/domain/employee/Employee.service';
import i18next from 'i18next';
import { TestConfig } from 'yup';
import * as yup from 'yup';

export const checkEmailAvailability = ({ excludedEmployeeId }: { excludedEmployeeId?: number } = {}): TestConfig<string, unknown> => ({
    name: 'emailAvailability',
    message: i18next.t('general.forms.email_already_taken'),
    test: async email => {
        if (!email || !yup.string().email().isValidSync(email)) return true;

        try {
            const employees = await searchEmployees({ email });

            const firstEmployeeId = employees.at(0)?.id;
            return !firstEmployeeId || firstEmployeeId === excludedEmployeeId;
        } catch {
            return false;
        }
    },
});
