import { Spinner } from '@/components/spinner/Spinner';
import { MyColleaguesPage } from '@/page/people/MyColleaguesPage';
import { OffboardingPage } from '@/page/people/OffboardingPage';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { ManageEmployeeProfilePendingRequestPage } from './ManageEmployeeProfilePendingRequestPage';
import { OrganizationChartLazyPage } from '@/page/people/PeopleLazyPages';
import { LazyEmploymentMassEditPage } from '@/page/people/EmploymentMassEditLazyPage';
import { OnboardingPage } from '@/page/people/OnboardingPage';

const config: RoutesConfig[] = [
    {
        path: 'directory',
        breadCrumb: [{ nameKey: 'pages.people' }],
        component: <MyColleaguesPage />,
    },
    {
        path: 'organization-chart',
        breadCrumb: [],
        component: (
            <Suspense fallback={<Spinner />}>
                <OrganizationChartLazyPage />
            </Suspense>
        ),
    },
    {
        path: 'onboarding-form',
        breadCrumb: [{ nameKey: 'pages.onboarding' }],
        component: <OnboardingPage />,
    },
    {
        path: 'offboarding-form/:employeeId',
        breadCrumb: [{ nameKey: 'pages.offboarding' }],
        component: <OffboardingPage />,
    },
    {
        path: 'employee-requests',
        breadCrumb: [{ nameKey: 'pages.profile_update_pending_request' }],
        component: <ManageEmployeeProfilePendingRequestPage />,
    },
    {
        path: 'employment-mass-edit',
        breadCrumb: [{ nameKey: 'pages.employment-mass-edit' }],
        component: <LazyEmploymentMassEditPage />,
    },
];

export const peopleRoutes: RouteObject[] = [
    {
        path: 'people',
        errorElement: <RouterBoundary />,
        children: [
            {
                path: '',
                element: <Navigate to='/people/directory' replace />,
            },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}>{config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
