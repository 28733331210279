import { FilePicker, FilePickerProps } from '@/components/file-picker/FilePicker';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { StackProps } from '@mui/material';
import { FC } from 'react';
import { useLeaveRequestAttachments } from './useLeaveRequestAttachments';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { getLeaveRequestAttachmentUrl } from '@/domain/leave-request-attachment/LeaveRequestAttachment.service';

type Props = FilePickerProps & {
    leaveType: LeaveType;
};

export const LeaveRequestAttachmentsUploader: FC<Props> = ({ leaveType, ...rest }) => {
    if (!leaveType?.allowAttachments) {
        return;
    }
    return <FilePicker {...rest} />;
};

export const LeaveRequestAttachmentsUploaderUncontrolled: FC<
    {
        leaveRequest: LeaveRequest;
    } & StackProps
> = ({ leaveRequest, ...rest }) => {
    const { files, handleFileRemoved, handleFileUploaded } = useLeaveRequestAttachments(leaveRequest.id, leaveRequest.employee.id);

    return (
        <LeaveRequestAttachmentsUploader
            leaveType={leaveRequest?.leaveType}
            files={files}
            onFileRemoved={handleFileRemoved}
            onFileUploaded={files => handleFileUploaded(files[0])}
            fetchDocumentUrl={(itemId: number) => getLeaveRequestAttachmentUrl(itemId, 'INLINE').then(res => res)}
            onFileRenamed={undefined}
            confirmOnRemove
            {...rest}
        />
    );
};
