import { isNPS } from '@/domain/survey/Survey.service';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import { Paper, Stack, TextField, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Slide from '@mui/material/Slide';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { InformationCircleIcon } from 'hugeicons-react';
import { ChangeEvent, FC } from 'react';
import { SurveyAnswerForm, SurveyQuestionForm } from '@/page/survey/SurveyForm.util';
import { useTranslation } from 'react-i18next';

type Props = {
    question: SurveyQuestionForm;
    answer: SurveyAnswerForm;
    onSelect: (selected: string, comment: string) => void;
    selectedLanguage: UserLanguage;
};

const NPSTooltip: FC = () => {
    const { t } = useTranslation();
    return (
        <Stack direction={'row'} gap={0.5} alignItems={'center'} justifyContent={'center'}>
            <Typography display={'block'} textAlign={'center'} fontSize={13}>
                {t('survey_templates_page.nps_question')}
            </Typography>
            <Tooltip
                sx={{
                    maxWidth: 432,
                }}
                placement='top'
                title={<span style={{ whiteSpace: 'pre-line' }}>{t('survey_templates_page.nps_question_explanation')}</span>}
            >
                <Typography>
                    <InformationCircleIcon />
                </Typography>
            </Tooltip>
        </Stack>
    );
};

export const SurveyAnswerScale: FC<Props> = ({ question, answer, onSelect, selectedLanguage }) => {
    const getSelectedItem = question?.items.find(item => item.localId === answer?.items[0]?.localId);
    const onUpdateComment = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onSelect(answer?.items[0]?.localId, event.target.value);
    };
    const handleChange = () => {
        onSelect(answer?.items[0]?.localId, answer.comment);
    };

    const renderScalePhoto = () => {
        const score = getSelectedItem?.score ?? 0;
        if (score <= 3) {
            return question.items[1].imageUrl && <img src={question.items[1].imageUrl} alt={'Scale1'} />;
        } else if (score <= 5) {
            return question.items[4].imageUrl && <img src={question.items[4].imageUrl} alt={'Scale2'} />;
        } else if (score <= 7) {
            return question.items[6].imageUrl && <img src={question.items[6].imageUrl} alt={'Scale3'} />;
        } else if (score <= 10) {
            return question.items[8].imageUrl && <img src={question.items[8].imageUrl} alt={'Scale4'} />;
        }
    };

    const marks = question.items.map(item => ({
        label: item.score,
        value: item.score,
    }));

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Stack component={Paper} p={2} alignItems={'center'} justifyContent={'center'} gap={2}>
                <Stack gap={1.5} alignItems={'center'} minWidth={{ xs: 250, sm: 500 }}>
                    <Typography variant='h1'>{getLabelTranslation(question.question, selectedLanguage)}</Typography>
                    {isNPS(question.type) && <NPSTooltip />}
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} width='100%'>
                    <Typography variant='body1bold'>{getLabelTranslation(question.items[0].label, selectedLanguage)}</Typography>
                    <Typography variant='body1bold'>{getLabelTranslation(question.items[question.items.length - 1].label, selectedLanguage)}</Typography>
                </Stack>
                <Stack width='100%'>
                    <Slider
                        defaultValue={answer?.items[0]?.score ?? 0}
                        onChange={(_, val) => {
                            const score = val as number;
                            const selectedItem = question.items.find(i => i.score === score);
                            if (selectedItem) {
                                onSelect(selectedItem?.localId, answer.comment);
                            }
                        }}
                        min={0}
                        max={question.items.length - 1}
                        step={1}
                        marks={marks}
                    />
                </Stack>
                {question.allowComment && (
                    <>
                        <FormControlLabel
                            label='Comment'
                            sx={theme => ({
                                width: '100%',
                                marginBottom: theme.spacing(2),
                                marginTop: theme.spacing(4),
                                [theme.breakpoints.down('md')]: {
                                    marginTop: theme.spacing(2),
                                },
                                '& .MuiFormControlLabel-label': {
                                    color: '#C4C4C4',
                                    fontSize: 16,
                                },
                            })}
                            control={
                                <TextField
                                    fullWidth
                                    slotProps={{
                                        input: {
                                            multiline: true,
                                            minRows: 4,
                                        },
                                    }}
                                    value={answer.comment}
                                    onChange={event => {
                                        onUpdateComment(event);
                                    }}
                                />
                            }
                        />
                        <Stack
                            direction='row'
                            alignItems={'center'}
                            sx={theme => ({
                                flexFlow: 'inherit',
                                [theme.breakpoints.down('lg')]: {
                                    flexFlow: 'wrap',
                                },
                                '& .MuiFormControlLabel-label': {
                                    color: '#000A1D',
                                    fontSize: 14,
                                    margin: 0,
                                },
                            })}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={answer.anonymous} color='primary' onChange={handleChange} name='checkedA' />}
                                    label={answer.anonymous ? 'Anonymous' : 'Not anonymous'}
                                    labelPlacement='end'
                                />
                            </FormGroup>
                        </Stack>
                    </>
                )}
                <Stack direction={'column'}>
                    <Stack
                        sx={theme => ({
                            textAlign: 'center',
                            marginTop: theme.spacing(3),
                            '& img': {
                                maxWidth: 210,
                            },
                        })}
                    >
                        {renderScalePhoto()}
                    </Stack>
                </Stack>
            </Stack>
        </Slide>
    );
};
