import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { useFiltersDirectory } from '@/page/common/filters-directory/useFiltersDirectory';

export const useGetLeavesFilters = (): {
    filters: FilterType[];
} => {
    const { filters: filtersFromDirectory } = useFiltersDirectory(['LEAVE_TYPE', 'JOB', 'LOCATION', 'DEPARTMENT', 'MANAGER']);
    const filters: FilterType[] = [...filtersFromDirectory];
    return { filters };
};
