import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { Accordion, AccordionDetails, AccordionSummary, Collapse, FormControlLabel, formHelperTextClasses, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { ArrowDown01Icon } from 'hugeicons-react';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';

export const TimeManagementTimeClock: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();
    //same value as the BE to not allow the employee to adjust like crazy
    const MAX_VALUE_FOR_MINUTES_TO_ADJUST = 240;

    const canDisplayPlanningSettings = hasRealmFeatureEnabled(RealmFeaturesType.PLANNING);

    return (
        <Paper>
            <Stack component={Accordion} py={1} px={3}>
                <AccordionSummary expandIcon={<ArrowDown01Icon />} aria-controls='panel-clock-in' id='panel-clock-in' sx={{ p: 0 }}>
                    <Typography variant={'h2'}>{t('time_management_settings_page.time_management_configuration.time_management_clock_in_out')}</Typography>
                </AccordionSummary>

                <Stack component={AccordionDetails} gap={2} p={0} pb={3}>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <FormControlLabel
                            label={t('time_management_settings_page.time_management_configuration.mobile_clock_in_out')}
                            labelPlacement='end'
                            control={<FieldSwitch name='mobileClockInOut' control={control} />}
                            sx={{ mr: 0 }}
                        />
                    </Stack>
                    {canDisplayPlanningSettings && (
                        <Stack gap={2}>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <FormControlLabel
                                    label={t('time_management_settings_page.time_management_configuration.force_shift_start_time_on_clock_in')}
                                    labelPlacement='end'
                                    control={<FieldSwitch name='forceShiftStartTimeOnClockIn' control={control} />}
                                    sx={{ mr: 0 }}
                                />

                                <Collapse in={watch('forceShiftStartTimeOnClockIn')}>
                                    <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                                        <FieldNumber
                                            name='forceShiftStartTimeBeforeInMinutes'
                                            control={control}
                                            min={0}
                                            max={MAX_VALUE_FOR_MINUTES_TO_ADJUST}
                                            step={5}
                                            sx={{
                                                width: '8em',
                                                [`& .${formHelperTextClasses.root}`]: {
                                                    whiteSpace: 'nowrap',
                                                },
                                            }}
                                        />
                                        <Typography variant={'body1'}>
                                            {t(
                                                'time_management_settings_page.time_management_configuration.force_shift_start_time_on_clock_in_minutes_or_after',
                                            )}
                                        </Typography>
                                        <FieldNumber
                                            name='forceShiftStartTimeAfterInMinutes'
                                            control={control}
                                            min={0}
                                            max={MAX_VALUE_FOR_MINUTES_TO_ADJUST}
                                            step={5}
                                            sx={{
                                                width: '8em',
                                                [`& .${formHelperTextClasses.root}`]: {
                                                    whiteSpace: 'nowrap',
                                                },
                                            }}
                                        />
                                        <Typography variant={'body1'}>
                                            {t('time_management_settings_page.time_management_configuration.force_shift_start_time_on_clock_in_minutes')}
                                        </Typography>
                                    </Stack>
                                </Collapse>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <FormControlLabel
                                    label={t('time_management_settings_page.time_management_configuration.force_shift_end_time_on_clock_out')}
                                    labelPlacement='end'
                                    control={<FieldSwitch name='forceShiftEndTimeOnClockOut' control={control} />}
                                    sx={{ mr: 0 }}
                                />

                                <Collapse in={watch('forceShiftEndTimeOnClockOut')}>
                                    <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                                        <FieldNumber
                                            name='forceShiftEndTimeAfterInMinutes'
                                            control={control}
                                            min={0}
                                            max={MAX_VALUE_FOR_MINUTES_TO_ADJUST}
                                            step={5}
                                            sx={{
                                                width: '8em',
                                                [`& .${formHelperTextClasses.root}`]: {
                                                    whiteSpace: 'nowrap',
                                                },
                                            }}
                                        />
                                        <Typography variant={'body1'}>
                                            {t(
                                                'time_management_settings_page.time_management_configuration.force_shift_start_time_on_clock_in_minutes_or_before',
                                            )}
                                        </Typography>
                                        <FieldNumber
                                            name='forceShiftEndTimeBeforeInMinutes'
                                            control={control}
                                            min={0}
                                            max={MAX_VALUE_FOR_MINUTES_TO_ADJUST}
                                            step={5}
                                            sx={{
                                                width: '8em',
                                                [`& .${formHelperTextClasses.root}`]: {
                                                    whiteSpace: 'nowrap',
                                                },
                                            }}
                                        />
                                        <Typography variant={'body1'}>
                                            {t('time_management_settings_page.time_management_configuration.force_shift_start_time_on_clock_in_minutes')}
                                        </Typography>
                                    </Stack>
                                </Collapse>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Paper>
    );
};
