import { FC } from 'react';

import category1 from '@/assets/icons/SurveyCategories/icon1.svg';
import category2 from '@/assets/icons/SurveyCategories/icon2.svg';
import category3 from '@/assets/icons/SurveyCategories/icon3.svg';
import category4 from '@/assets/icons/SurveyCategories/icon4.svg';
import category5 from '@/assets/icons/SurveyCategories/icon5.svg';
import category6 from '@/assets/icons/SurveyCategories/icon6.svg';
import category7 from '@/assets/icons/SurveyCategories/icon7.svg';
import { ClampedTypography } from '@/components/typography/ClampedTypography';
import { AnswerResult, getSurveyCategoryTranslationKey, SurveyQuestionCategory, SurveyResults } from '@/domain/survey/Survey.model';
import { Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';

type Props = {
    categories: AnswerResult[];
    setCategory: (category: SurveyQuestionCategory | undefined) => void;
    activeCategory: SurveyQuestionCategory | undefined;
    results: SurveyResults;
};

export const SurveyCategories: FC<Props> = ({ results, categories, setCategory, activeCategory }) => {
    const { t } = useTranslation();

    const sortedCategories = [...categories].sort(sortByType);

    return (
        <Stack direction={'row'} gap={2} justifyContent={'center'} flex={1} flexWrap={'wrap'}>
            {sortedCategories.map(category => (
                <Stack key={category.question.id}>
                    <Card
                        className={
                            activeCategory === category.question.category || (activeCategory === SurveyQuestionCategory.OTHER && !category.question.category)
                                ? 'active'
                                : ''
                        }
                        variant='outlined'
                        sx={theme => ({
                            width: 144,
                            cursor: 'pointer',
                            '&.active': {
                                background: theme.palette.primary.main,
                                '& .MuiTypography-root': {
                                    color: '#fff',
                                },
                            },
                            '& .MuiCardContent-root': {
                                paddingBottom: theme.spacing(2),
                            },
                        })}
                    >
                        <CardContent onClick={() => setCategory(category.question.category ?? SurveyQuestionCategory.OTHER)}>
                            <Stack
                                borderRadius='50%'
                                justifyContent='space-between'
                                alignItems='center'
                                height={40}
                                width={40}
                                margin={'auto'}
                                sx={theme => ({
                                    background: theme.palette.background.default,
                                })}
                            >
                                <img
                                    src={showCategoryIcon(category.question.category)}
                                    alt={'Icon'}
                                    style={{
                                        display: 'block',
                                        margin: 'auto',
                                    }}
                                />
                            </Stack>
                            <Typography variant='body1bold' minHeight={20}>
                                {results?.categories.ALL[0]?.categoryAverageScore?.[
                                    // TODO fix domaine type
                                    category.question.category ?? ('' as SurveyQuestionCategory)
                                ]?.toFixed(1)}
                            </Typography>
                            <ClampedTypography variant='body1' ellipsis={2} minHeight={40}>
                                {t(getSurveyCategoryTranslationKey(category.question.category))}
                            </ClampedTypography>
                        </CardContent>
                    </Card>
                </Stack>
            ))}
        </Stack>
    );
};

const showCategoryIcon = (category: SurveyQuestionCategory | undefined) => {
    switch (category) {
        case SurveyQuestionCategory.FULFILLING_ROLE:
            return category1;
        case SurveyQuestionCategory.PERSONAL_GROWTH:
            return category2;
        case SurveyQuestionCategory.STRONG_LEADERSHIP:
            return category3;
        case SurveyQuestionCategory.POSITIVE_RELATIONSHIPS:
            return category4;
        case SurveyQuestionCategory.MISSION_VALUES:
            return category7;
        case SurveyQuestionCategory.HEALTH_WELL_BEING:
            return category5;
        case SurveyQuestionCategory.OTHER:
        default:
            return category6;
    }
};

const sortByType = (x: AnswerResult, y: AnswerResult) => {
    if (!x.question.category || !y.question.category) {
        return 0;
    }
    // TODO use better compare function
    if (x.question.category < y.question.category) {
        return -1;
    }
    if (x.question.category > y.question.category) {
        return 1;
    }
    return 0;
};
