import { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProviderProps, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // We disable the gcTime because we got issues with data in cache
            // Don't forget to handle logout cache cleaning
            gcTime: 0,
            refetchOnWindowFocus: false,
        },
    },
});

export const QueryClientProvider: FC<PropsWithChildren & Omit<QueryClientProviderProps, 'client'>> = ({ children, ...rest }) => {
    return (
        <ReactQueryClientProvider {...rest} client={queryClient}>
            {children}
        </ReactQueryClientProvider>
    );
};
