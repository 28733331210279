import { createQueryHook, createRequiredParamsQueryHook, defaultQueryOptions } from '@/page/Query.type';
import { getExpenseDistanceRate, searchExpenseDistanceRates } from '@/domain/expense-distance-rate/ExpenseDistanceRate.service';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const expenseDistanceRateKeys = createQueryKeys('expenseDistanceRates', {
    search: (...optionalParam: Parameters<typeof searchExpenseDistanceRates>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    get: (...params: Parameters<typeof getExpenseDistanceRate>) => ({
        queryKey: params,
    }),
});

export const useGetExpenseDistanceRates = createQueryHook(expenseDistanceRateKeys.search, searchExpenseDistanceRates, defaultQueryOptions);

export const useGetExpenseDistanceRate = createRequiredParamsQueryHook(expenseDistanceRateKeys.search, getExpenseDistanceRate, defaultQueryOptions);
