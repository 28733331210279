import * as yup from 'yup';
import {
    feedbackJobSchema,
    feedbackObjectivesActionSchema,
    feedbackObjectiveSchema,
    feedbackQuestionSchema,
    feedbackSectionSchema,
    feedbackSkillQuestionSchema,
    feedbackSkillSchema,
    feedbackSkillsSchema,
    RatingFormType,
} from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { t } from 'i18next';
import { hasAtLeastOneFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { isEmployeeReviewRatingCommentRequired } from '@/page/review/employee-review-feedback-form/FeedbackForm.util';
import { isDefined } from '@/utils/collections.util';

// Schema for the completed feedback skill question form filled out by the employee, manager, upward, and peer
const feedbackSchema = yup
    .object()
    .shape({
        scoreLevelName: yup.string().default(undefined).notRequired(),
        score: yup.number().default(undefined).notRequired(),
        comment: yup.string().default(undefined).notRequired(),
        reviewer: yup.object().shape({
            displayName: yup.string().required(),
            avatarImageUrl: yup.string(),
            id: yup.number().required(),
        }),
    })
    .default(undefined)
    .notRequired();

export type FeedbackFormType = yup.InferType<typeof feedbackSchema>;

const feedbackSummarySkillSchema = yup
    .object()
    .concat(feedbackSkillSchema)
    .shape({
        selfFeedbackQuestion: feedbackSchema,
        managerFeedbackQuestion: yup.array().of(feedbackSchema.required()),
        upwardFeedbackQuestion: yup.array().of(feedbackSchema.required()),
        peerFeedbackQuestion: yup.array().of(feedbackSchema.required()),
    })
    .default(undefined);
export type FeedbackSummarySkillFormType = yup.InferType<typeof feedbackSummarySkillSchema>;

const summaryFeedbackSectionSchema = feedbackSectionSchema;
export type SummaryFeedbackSectionFormType = yup.InferType<typeof summaryFeedbackSectionSchema>;

const summaryFeedbackSkillsSchema = yup
    .object()
    .concat(feedbackSkillsSchema)
    .omit(['skills'])
    .shape({
        skills: yup.array().of(feedbackSummarySkillSchema).required(),
    })
    .test({
        name: 'manager-skills-validation',
        test: function (value) {
            const { skills } = value;
            return validateManagerSkills(skills);
        },
        message: function ({ value }) {
            const { allSkillsRequired } = value;
            if (allSkillsRequired) {
                return t('reviews.write_feedback.all_skills_required');
            }
            return t('reviews.write_feedback.all_prepared_skills_required');
        },
    });
export type SummaryFeedbackSkillsFormType = yup.InferType<typeof summaryFeedbackSkillsSchema>;

const doAllSkillsHavePositiveScores = (skills: FeedbackSummarySkillFormType[]): boolean =>
    skills.every(skill => {
        return skill.score && skill.score > 0;
    });

const areAllReviewedSkillsHaveSummaryPositiveScores = (skills: FeedbackSummarySkillFormType[]) => {
    const skillsWithFeedback = skills.filter(skill => {
        return hasAtLeastOneFeedback(skill);
    });
    return doAllSkillsHavePositiveScores(skillsWithFeedback);
};

const validateManagerSkills = (skills: FeedbackSummarySkillFormType[]): boolean => {
    return areAllReviewedSkillsHaveSummaryPositiveScores(skills);
};

const summaryFeedbackObjectiveSchema = feedbackObjectiveSchema;
export type ObjectiveFeedbackFormType = yup.InferType<typeof summaryFeedbackObjectiveSchema>;

const summaryFeedbackJobSchema = feedbackJobSchema;
export type JobSummaryFeedbackFormType = yup.InferType<typeof summaryFeedbackJobSchema>;

const getBypassValidation = (managerFeedbacks: FeedbackFormType[], selfFeedback: FeedbackFormType): boolean => {
    const combinedFeedbacks = managerFeedbacks.concat(selfFeedback).filter(isDefined);
    return combinedFeedbacks.length === 1;
};

export const summaryQuestionScoreValidationSchema = yup.number().when(['required', 'rating', 'managerFeedbackQuestion', 'selfFeedbackQuestion'], {
    is: (required: boolean, rating: ReviewRatingScale, managerFeedbackQuestion: FeedbackFormType[], selfFeedbackQuestion: FeedbackFormType) => {
        const bypassValidation = getBypassValidation(managerFeedbackQuestion, selfFeedbackQuestion);
        return required && rating && rating.items.length > 0 && !bypassValidation;
    },
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
});

export const summaryQuestionCommentValidationSchema = yup
    .string()
    .trim()
    .default('')
    .when(['required', 'score', 'rating', 'managerFeedbackQuestion', 'selfFeedbackQuestion'], {
        is: (required: boolean, score: number, rating: RatingFormType, managerFeedbackQuestion: FeedbackFormType[], selfFeedbackQuestion: FeedbackFormType) => {
            const bypassValidation = getBypassValidation(managerFeedbackQuestion, selfFeedbackQuestion);
            return ((!score && required) || (score && rating && isEmployeeReviewRatingCommentRequired(rating, score))) && !bypassValidation;
        },
        then: schema => schema.required(),
        otherwise: schema => schema,
    });

export const summaryFeedbackQuestionValidationSchema = yup.object().concat(feedbackQuestionSchema).shape({
    score: summaryQuestionScoreValidationSchema,
    comment: summaryQuestionCommentValidationSchema,
});

const summaryFeedbackQuestionSchema = yup
    .object()
    .concat(summaryFeedbackQuestionValidationSchema)
    .shape({
        selfFeedbackQuestion: feedbackSchema,
        managerFeedbackQuestion: yup.array().of(feedbackSchema.required()).default(undefined).notRequired(),
        upwardFeedbackQuestion: yup.array().of(feedbackSchema.required()).default(undefined).notRequired(),
        peerFeedbackQuestion: yup.array().of(feedbackSchema.required()).default(undefined).notRequired(),
    });
export type QuestionFeedbackFormType = yup.InferType<typeof summaryFeedbackQuestionSchema>;

export type EmployeeReviewFeedbackForm = QuestionFeedbackFormType | SkillQuestionFeedbackFormType | FeedbackSummarySkillFormType;

const summaryFeedbackSkillQuestionSchema = yup
    .object()
    .concat(feedbackSkillQuestionSchema)
    .shape({
        selfFeedbackQuestion: feedbackSchema,
        managerFeedbackQuestion: yup.array().of(feedbackSchema.required()),
        upwardFeedbackQuestion: yup.array().of(feedbackSchema.required()),
        peerFeedbackQuestion: yup.array().of(feedbackSchema.required()),
    })
    .default(undefined);
export type SkillQuestionFeedbackFormType = yup.InferType<typeof summaryFeedbackSkillQuestionSchema>;

export const feedbackStepItemsSchema = yup.lazy(item => {
    const { type } = item;

    switch (type) {
        case 'SECTION':
            return summaryFeedbackSectionSchema;
        case 'SKILL':
            return summaryFeedbackSkillQuestionSchema;
        case 'REVIEW_OBJECTIVES':
            return summaryFeedbackObjectiveSchema;
        case 'OBJECTIVE_ACTIONS':
            return feedbackObjectivesActionSchema;
        case 'SKILLS':
            return summaryFeedbackSkillsSchema;
        case 'REVIEW_JOBS':
            return summaryFeedbackJobSchema;
        case 'QUESTION':
        default:
            return summaryFeedbackQuestionSchema;
    }
});

export type FeedbackStepItemsType = yup.InferType<typeof feedbackStepItemsSchema>;

export type PrivateFeedbackStepItemsType = yup.InferType<typeof summaryFeedbackQuestionSchema>;

export type SummaryFeedbackItemFormType = FeedbackStepItemsType | PrivateFeedbackStepItemsType;

export const feedbackStepSchema = yup.object().shape({
    summaryFeedbackItems: yup.array().required().of(feedbackStepItemsSchema),
});

export const privateFeedbackStepSchema = yup.object().shape({
    summaryPrivateFeedbackItems: yup.array().required().of(summaryFeedbackQuestionSchema),
});

export type PrivateFeedbackItemsType = yup.InferType<typeof privateFeedbackStepSchema>;
export type FeedbackItemsType = yup.InferType<typeof feedbackStepSchema>;

// todo: find a better name for these types
export type CombinedFeedbackItemsType = PrivateFeedbackItemsType | FeedbackItemsType;
export type MergedFeedbackItemsType = PrivateFeedbackItemsType & FeedbackItemsType;
