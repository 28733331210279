import { FC } from 'react';
import { Paper, Skeleton, Stack } from '@mui/material';
import { FORM_COMPANY_SETTINGS_CONTAINER_CLASS } from '@/page/setting/CompanySettings.constants';

export const CompanySettingsSkeleton: FC = () => {
    return (
        <Stack gap={2} flex={1}>
            <Stack gap={2} alignItems={'space-between'} flex={1}>
                <Stack p={2} gap={2} component={Paper}>
                    <Skeleton variant='text' height={36} width='60%' />
                    <Skeleton variant='rectangular' height={40} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS} />
                    <Skeleton variant='rectangular' height={40} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS} />
                    <Skeleton variant='rectangular' height={40} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS} />
                </Stack>

                <Stack p={2} gap={2} component={Paper}>
                    <Skeleton variant='text' height={36} width='60%' />
                    <Skeleton variant='rectangular' height={40} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS} />
                    <Skeleton variant='rectangular' height={40} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS} />
                </Stack>
            </Stack>

            <Stack direction='row' justifyContent='flex-end' p={2} gap={2} component={Paper}>
                <Skeleton variant='rectangular' height={40} width={100} />
                <Skeleton variant='rectangular' height={40} width={100} />
            </Stack>
        </Stack>
    );
};
