import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { showCompletedReviewSummary } from '@/domain/employee-review/EmployeeReview.service';
import { Job } from '@/domain/job/Job.model';
import { Objective } from '@/domain/objective/Objective.model';
import { EmployeeReviewAvatar } from '@/page/review/employee-review-avatar/EmployeeReviewAvatar';
import { SummaryFeedbackPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryFeedbackPDF';
import { SummaryCompleted } from '@/page/review/employee-review-summary-feedback-form/SummaryCompleted';
import { SummaryFeedbackItemFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Download02Icon, UnfoldLessIcon, UnfoldMoreIcon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type SummaryFeedbackFormHeaderProps = {
    managerSummary: EmployeeReviewManagerSummary;
    objectives: Objective[];
    summaryFeedbackItems: SummaryFeedbackItemFormType[];
    expandAll: boolean;
    handleExpandAllChange: () => void;
    employeeReview: EmployeeReview;
    actions: EmployeeReviewAction[];
    jobs: Job[];
};

export const SummaryFeedbackFormHeader: FC<SummaryFeedbackFormHeaderProps> = ({
    managerSummary,
    objectives,
    summaryFeedbackItems,
    expandAll,
    handleExpandAllChange,
    employeeReview,
    actions,
    jobs,
}) => {
    const { t } = useTranslation();
    return (
        <Stack component={Paper}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                <Typography variant='h2'>{getLabelTranslation(managerSummary?.reviewName)}</Typography>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Button endIcon={<Download02Icon />}>
                        <PDFDownloadLink
                            style={{ textDecoration: 'none' }}
                            document={
                                <SummaryFeedbackPDF
                                    employeeReview={employeeReview}
                                    managerSummary={managerSummary}
                                    summaryFeedbackItems={summaryFeedbackItems}
                                    objectives={objectives ?? []}
                                    actions={actions}
                                    jobs={jobs}
                                />
                            }
                        >
                            <Typography color={'white'}>{t('reviews.review_summary.export')}</Typography>
                        </PDFDownloadLink>
                    </Button>
                    <Button
                        endIcon={expandAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                        variant='outlined'
                        onClick={() => {
                            handleExpandAllChange();
                        }}
                    >
                        {expandAll ? t('reviews.review_summary.collapse_all') : t('reviews.review_summary.expand_all')}
                    </Button>
                </Stack>
            </Stack>

            <EmployeeReviewAvatar employee={managerSummary?.employee} />
            {showCompletedReviewSummary(employeeReview, managerSummary) && <SummaryCompleted managerSummary={managerSummary} />}
        </Stack>
    );
};
