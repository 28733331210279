import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { SectionField } from '@/components/section/types';
import { EmployeeBasicInfoFieldType } from '@/domain/employee/EmployeeFields.model';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { useGetEmployeeBasicInfo } from '@/hooks/employee/Employee.hook';
import { useCurrentRealm } from '@/stores/store';
import { getLabelTranslation } from '@/utils/language.util';

export const useEmployeeBasicInfoSectionFields = (sectionDefinition: SectionDefinition, employeeId: number, editionMode: boolean): SectionField[] => {
    const realm = useCurrentRealm();
    const { data: employeeBasicInfo } = useGetEmployeeBasicInfo(employeeId);

    if (!employeeBasicInfo) {
        return [];
    }

    // Check if the display name is changed to hide it if it is generated by the BE when we are in edit mode
    const beGeneratedDisplayName = realm?.reverseDisplayName
        ? `${employeeBasicInfo.lastName} ${employeeBasicInfo.firstName}`
        : `${employeeBasicInfo.firstName} ${employeeBasicInfo.lastName}`;

    // If the display name is not set, we use the generated one
    const effectiveDisplayName =
        !!employeeBasicInfo.displayName && employeeBasicInfo.displayName !== beGeneratedDisplayName ? employeeBasicInfo.displayName : beGeneratedDisplayName;

    // In edition mode, we don't want to show fake display name
    const displayName = editionMode ? employeeBasicInfo.displayName : effectiveDisplayName;

    const employeeBasicInfoFieldValueMapping: Record<EmployeeBasicInfoFieldType, string | undefined> = {
        EMPLOYEE_FIRSTNAME: employeeBasicInfo.firstName,
        EMPLOYEE_LASTNAME: employeeBasicInfo.lastName,
        EMPLOYEE_MAIDEN_NAME: employeeBasicInfo.maidenName,
        EMPLOYEE_DISPLAY_NAME: displayName,
        EMPLOYEE_EMAIL: employeeBasicInfo.email,
        EMPLOYEE_PHONE_NUMBER: employeeBasicInfo.phoneNumber,
        EMPLOYEE_CODE: employeeBasicInfo.employeeCode,
    };
    return sectionDefinition.fields.map(fieldDefinition => {
        const employeeBasicInfoFieldType = fieldDefinition.fieldType as EmployeeBasicInfoFieldType;
        return {
            fieldDefinitionId: fieldDefinition.id,
            formValueName: fieldDefinition.formId,
            title: getLabelTranslation(fieldDefinition.name),
            valueType: fieldDefinition.valueType,
            [getFieldValueProperty(fieldDefinition.valueType)]: employeeBasicInfoFieldValueMapping[employeeBasicInfoFieldType],
            required: fieldDefinition.mandatory,
            fieldType: employeeBasicInfoFieldType,
            order: fieldDefinition.order,
            disabled: fieldDefinition.fieldType === 'EMPLOYEE_EMAIL',
        };
    });
};
