import { getTimesheetAdjustment } from '@/domain/timesheet-adjustment/TimesheetAdjustment.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const timesheetAdjustmentKeys = createQueryKeys('timesheetAdjustment', {
    getById: (...params: Parameters<typeof getTimesheetAdjustment>) => ({
        queryKey: params,
    }),
});

export const useGetTimesheetAdjustment = createRequiredParamsQueryHook(timesheetAdjustmentKeys.getById, getTimesheetAdjustment);
