import {
    CreateExpenseDistanceRateMutation,
    EditExpenseDistanceRateMutation,
    ExpenseDistanceRate,
    ExpenseDistanceRateSearch,
} from '@/domain/expense-distance-rate/ExpenseDistanceRate.model';
import { expenseDistanceRateApi } from '@/api/expense-distance-rate/ExpenseDistanceRateApi';

export const searchExpenseDistanceRates = (searchRequest: ExpenseDistanceRateSearch = {}): Promise<ExpenseDistanceRate[]> => {
    return expenseDistanceRateApi.searchExpenseDistanceRates(searchRequest);
};

export const createExpenseDistanceRate = (mutation: CreateExpenseDistanceRateMutation): Promise<ExpenseDistanceRate> => {
    return expenseDistanceRateApi.createExpenseDistanceRate(mutation);
};

export const updateExpenseDistanceRate = (expenseDistanceRateId: number, mutation: EditExpenseDistanceRateMutation): Promise<ExpenseDistanceRate> => {
    return expenseDistanceRateApi.updateExpenseDistanceRate(expenseDistanceRateId, mutation);
};

export const deleteExpenseDistanceRate = (expenseDistanceRateId: number): Promise<void> => {
    return expenseDistanceRateApi.deleteExpenseDistanceRate(expenseDistanceRateId);
};

export const getExpenseDistanceRate = (date: LocalDate): Promise<ExpenseDistanceRate> => {
    return expenseDistanceRateApi.getExpenseDistanceRate(date);
};

export const calculateDistanceAmount = (distance: number, distanceRate: number): number => {
    return distance * distanceRate;
};
