import { getEmployeePersonalInfo } from '@/domain/employee/Employee.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeePersonalInfoKeys = createQueryKeys('employeePersonalInfo', {
    get: (...params: Parameters<typeof getEmployeePersonalInfo>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeePersonalInfo = createRequiredParamsQueryHook(employeePersonalInfoKeys.get, getEmployeePersonalInfo);
