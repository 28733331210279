import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Department } from '@/domain/department/Department.model';
import { ObjectiveSetting } from '@/domain/objective-setting/ObjectiveSetting.model';
import { Objective, ObjectiveCategory } from '@/domain/objective/Objective.model';
import { getParentObjectiveOptions } from '@/domain/objective/Objective.service';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';
import { getCurrentLocalDate, getLocalDateTestConfig } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, DialogProps, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { FC, FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { useGetObjectivesCategory } from '@/hooks/objectiveCategory/Objective.hook';
import { Employee } from '@/domain/employee/Employee.model';
import { useCurrentPolicies } from '@/stores/store';
import { canCreateObjectives } from '@/domain/permission/Permission.service';

const getIndividualObjectiveDialogSchema = (objectiveSetting: ObjectiveSetting) => {
    return yup.object().shape({
        name: yup.string().trim().required(),
        assigneeIds: yup.array().of(yup.number().required()).min(1).required(),
        description: yup.string().required(),
        objectiveCategoryId: objectiveSetting.objectiveCategoriesEnabled ? yup.number().required() : yup.number(),
        parentId: yup.number().nullable(),
        dueDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        weight: yup.number().required().min(1).max(100).integer(),
    });
};

export type IndividualObjectiveDialogFormType = yup.InferType<ReturnType<typeof getIndividualObjectiveDialogSchema>>;

type AddObjectiveDialogProps = {
    activeObjective: Objective | undefined;
    onSave: (objectiveValues: IndividualObjectiveDialogFormType) => void;
    onClose: () => void;
    employeeId?: number;
    otherPossibleEmployeeIds?: number[];
    parentObjectiveEnabled: boolean;
    disabledEmployeeSelection: boolean;
} & DialogProps;

export const AddIndividualObjectiveDialog: FC<AddObjectiveDialogProps> = ({
    open,
    activeObjective,
    parentObjectiveEnabled,
    onClose,
    onSave,
    employeeId,
    otherPossibleEmployeeIds = [],
    disabledEmployeeSelection,
}) => {
    const { t } = useTranslation();
    const isEdit = !!activeObjective?.id;
    const title = isEdit ? t('objectives.add_objective_dialog.edit_objective') : t('objectives.add_objective_dialog.create_objective');
    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const { data: departments, isLoading: isDepartmentsLoading, error: departmentsError, isError: isDepartmentsError } = useGetDepartments();

    const {
        data: departmentObjectives = [],
        isLoading: isObjectivesLoading,
        error: objectivesError,
        isError: isObjectivesError,
    } = useGetObjectives({
        objectiveType: 'DEPARTMENT',
    });

    //in the case of having a limited number of employees to select from, we should only fetch them + the employeeId
    const employeeIds = otherPossibleEmployeeIds.length && employeeId ? [...otherPossibleEmployeeIds, employeeId] : [];
    const { data: employeesOptions = [], isLoading: isLoadingEmployees, error: errorEmployees, isError: isErrorEmployees } = useGetEmployees({ employeeIds });
    const {
        data: objectiveCategories = [],
        isLoading: isLoadingObjectiveCategories,
        error: errorObjectiveCategories,
        isError: isErrorObjectiveCategories,
    } = useGetObjectivesCategory();

    if (isEdit && activeObjective?.parent) {
        //hack to display the parent objective if it is archived and not in the list
        const containsParentObjective = departmentObjectives.some(objective => objective.id === activeObjective?.parent?.id);
        if (!containsParentObjective) {
            departmentObjectives.push(activeObjective.parent);
        }
    }

    const isLoading = isLoadingObjectiveSetting || isDepartmentsLoading || isObjectivesLoading || isLoadingEmployees || isLoadingObjectiveCategories;
    const isError = isErrorObjectiveSetting || isDepartmentsError || isObjectivesError || isErrorEmployees || isErrorObjectiveCategories;
    const error = errorObjectiveSetting ?? departmentsError ?? objectivesError ?? errorEmployees ?? errorObjectiveCategories;

    return (
        <DialogWrapper open={open} onClose={onClose} header={title}>
            <DialogContent>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    {objectiveSetting && departments && (
                        <AddIndividualObjectiveDialogForm
                            activeObjective={activeObjective}
                            onSave={onSave}
                            employeeId={employeeId}
                            disabledEmployeeSelection={disabledEmployeeSelection}
                            isEdit={isEdit}
                            departments={departments}
                            objectiveSetting={objectiveSetting}
                            otherPossibleEmployeeIds={otherPossibleEmployeeIds}
                            objectives={departmentObjectives}
                            parentObjectiveEnabled={parentObjectiveEnabled}
                            objectiveCategories={objectiveCategories}
                            employeesOptions={employeesOptions}
                        />
                    )}
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button type='submit' form={'add-objective-form'} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type AddIndividualObjectiveDialogFormProps = {
    isEdit: boolean;
    objectiveSetting: ObjectiveSetting;
    objectives: Objective[];
    departments: Department[];
    parentObjectiveEnabled: boolean;
    disabledEmployeeSelection: boolean;
    activeObjective: Objective | undefined;
    employeeId?: number;
    otherPossibleEmployeeIds: number[];
    onSave: (objectiveValues: IndividualObjectiveDialogFormType) => void;
    objectiveCategories: ObjectiveCategory[];
    employeesOptions: Employee[];
};

const AddIndividualObjectiveDialogForm: FC<AddIndividualObjectiveDialogFormProps> = ({
    activeObjective,
    onSave,
    employeeId,
    otherPossibleEmployeeIds,
    isEdit,
    objectiveSetting,
    objectives,
    departments,
    parentObjectiveEnabled,
    disabledEmployeeSelection,
    employeesOptions,
    objectiveCategories,
}) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();
    const [disableAssigneeSelection, setDisableAssigneeSelection] = useState(isEdit || disabledEmployeeSelection);

    const {
        handleSubmit,
        reset,
        watch,
        register,
        control,
        formState: { errors },
    } = useForm<IndividualObjectiveDialogFormType>({
        resolver: yupResolver(getIndividualObjectiveDialogSchema(objectiveSetting)),
        defaultValues: {
            ...defaultObjective,
            parentId: parentObjectiveEnabled ? activeObjective?.parent?.id : undefined,
            assigneeIds: employeeId ? [employeeId] : [],
            dueDate: getCurrentLocalDate(),
        },
    });

    const assigneeIds = watch('assigneeIds');

    useEffect(() => {
        const resetForm = () => {
            const data: Partial<IndividualObjectiveDialogFormType> = {
                name: activeObjective?.name,
                description: activeObjective?.description,
                assigneeIds: activeObjective?.assignee?.id ? [activeObjective?.assignee?.id] : [],
                dueDate: activeObjective?.dueDate,
                objectiveCategoryId: activeObjective?.category?.id,
                weight: activeObjective?.weight,
            };
            reset(data);
        };
        if (isEdit) {
            resetForm();
        } else {
            const data = {
                ...defaultObjective,
                assigneeIds: employeeId ? [employeeId] : [],
            };
            reset(data);
        }
    }, [employeeId, activeObjective, reset, employeesOptions, isEdit, parentObjectiveEnabled]);

    const canSelectMultipleEmployees = !isEdit;
    const canEnableAssigneeSelection = !!otherPossibleEmployeeIds.length;

    const stopPropagate = (e: FormEvent<HTMLFormElement>, callback: Promise<void>) => {
        // Prevent the default form submission and stop the event from propagating
        // This ensures the form does not trigger parent forms' onSubmit handlers
        e.preventDefault();
        e.stopPropagation();
        return callback;
    };

    const employeesOptionsAvailable = employeesOptions.filter(
        employee =>
            //remove already selected employees
            !assigneeIds.includes(employee.id) &&
            //the default case we need to check for the permission
            (canCreateObjectives(policies, employee.id) ||
                //when we come from the actions we should validate them based on otherPossibleEmployeeIds
                (otherPossibleEmployeeIds.length && (otherPossibleEmployeeIds.includes(employee.id) || employee.id === employeeId))),
    );

    return (
        <Stack gap={2} component='form' id='add-objective-form' onSubmit={e => stopPropagate(e, handleSubmit(onSave, console.error)(e))}>
            <FormControlLabel
                label={
                    <Stack direction={'row'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant={'body2'}>{t('objectives.add_objective_dialog.assignee')}</Typography>
                        {canEnableAssigneeSelection && (
                            <Button
                                variant={'text'}
                                color='primary'
                                size={'small'}
                                onClick={event => {
                                    event.stopPropagation();
                                    setDisableAssigneeSelection(false);
                                }}
                            >
                                {t('objectives.add_objective_dialog.assignee_enable_button')}
                            </Button>
                        )}
                    </Stack>
                }
                disabled={disableAssigneeSelection}
                sx={{ width: '100%' }}
                slotProps={{
                    typography: {
                        sx: {
                            width: '100%',
                        },
                    },
                }}
                control={
                    <FieldSelect
                        name='assigneeIds'
                        control={control}
                        options={employeesOptionsAvailable.map(employee => employee.id)}
                        multiple={canSelectMultipleEmployees && !disableAssigneeSelection}
                        fullWidth
                        getOptionLabel={optionId => employeesOptions.find(emp => emp.id == optionId)?.displayName ?? ''}
                    />
                }
            />

            <FormControlLabel
                label={t('objectives.add_objective_dialog.title')}
                labelPlacement='top'
                control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
            />

            {objectiveSetting.objectiveWeightEnabled && (
                <FormControlLabel
                    label={t('objectives.add_objective_dialog.weight')}
                    control={<FieldNumber control={control} name='weight' step={1} fullWidth />}
                />
            )}

            <FormControlLabel
                label={t('objectives.add_objective_dialog.description')}
                labelPlacement='top'
                control={
                    <TextField
                        fullWidth
                        InputProps={{ multiline: true, minRows: 2 }}
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        {...register('description')}
                    />
                }
            />
            {objectiveSetting.objectiveCategoriesEnabled && (
                <FormControlLabel
                    label={t('objectives.add_objective_dialog.category')}
                    control={
                        <FieldSelect
                            name='objectiveCategoryId'
                            control={control}
                            fullWidth
                            options={objectiveCategories.flatMap(category => category.id)}
                            getOptionLabel={category => getLabelTranslation(objectiveCategories.find(c => c.id === category)?.name)}
                            getOptionKey={category => category}
                        />
                    }
                />
            )}
            {parentObjectiveEnabled && (
                <FormControlLabel
                    label={t('objectives.add_objective_dialog.parent')}
                    control={
                        <FieldSelect
                            name='parentId'
                            control={control}
                            fullWidth
                            options={getParentObjectiveOptions(assigneeIds, employeesOptionsAvailable, objectives, departments, activeObjective).map(
                                objective => objective.id,
                            )}
                            getOptionLabel={option => objectives.find(o => o.id === option)?.name ?? ''}
                        />
                    }
                />
            )}

            <FormControlLabel label={t('objectives.add_objective_dialog.due_date')} control={<FieldLocalDate name='dueDate' control={control} />} />
        </Stack>
    );
};

const defaultObjective = {
    name: '',
    description: '',
    dueDate: getCurrentLocalDate(),
    weight: 1,
};
