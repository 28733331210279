import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { ExpenseSetting, ExpenseSettingUpdateMutation } from '@/domain/expense-setting/ExpenseSetting.model';

type ExpenseSettingDTO = ExpenseSetting;
type ExpenseSettingUpdateMutationDTO = ExpenseSettingUpdateMutation;

const EXPENSE_SETTINGS_API_BASE_PATH = API_BASE_URL + `/expense-settings`;

const updateExpenseSetting = async (id: number, request: ExpenseSettingUpdateMutation): Promise<ExpenseSetting> => {
    const { data } = await client.put<ExpenseSettingDTO, AxiosResponse<ExpenseSettingDTO>, ExpenseSettingUpdateMutationDTO>(
        EXPENSE_SETTINGS_API_BASE_PATH + `/${id}`,
        request,
    );
    return data;
};

const getExpenseSetting = async (): Promise<ExpenseSetting> => {
    const { data } = await client.get<ExpenseSettingDTO>(EXPENSE_SETTINGS_API_BASE_PATH);
    return data;
};

export const expenseSettingApi = {
    updateExpenseSetting,
    getExpenseSetting,
};
