import { formatInDefaultDate } from '@/utils/datetime.util';

import { usePopperlessAutocomplete } from '@/components/autocomplete-wrapper/usePopperlessAutocomplete';
import { DateFilter as DateFilterComponent } from '@/components/filters-bar/filter-bar-components/DateFilter';
import { SelectFilter as SelectFilterComponent } from '@/components/filters-bar/filter-bar-components/SelectFilter';
import { TextFilter as TextFilterComponent } from '@/components/filters-bar/filter-bar-components/TextFieldFilter';
import { TimeFilter } from '@/components/filters-bar/filter-bar-components/TimeFilter';
import { TreeSelectFilter } from '@/components/filters-bar/filter-bar-components/TreeSelectFilter';
import {
    AsyncSelectFilter,
    AsyncTreeSelectFilterType,
    DateFilter,
    FilterType,
    SelectFilter,
    TreeSelectFilterType,
} from '@/components/filters-bar/FilterBar.type';
import { FILTER_HEIGHT } from '@/components/filters-bar/FiltersBar.util';
import { Select } from '@/components/form/field-select/Select';
import { Box, Button, Chip, ChipProps, ClickAwayListener, Divider, Fade, IconButton, Paper, Popper, Stack, StackProps } from '@mui/material';
import { ArrowLeft01Icon, Cancel01Icon } from 'hugeicons-react';
import PopupState, { bindPopper, bindToggle, Props as PopupStateProps } from 'material-ui-popup-state';
import { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@/error/ErrorBoundary';
import { BooleanFilter } from '@/components/filters-bar/filter-bar-components/BooleanFilter';
import i18next from 'i18next';

export type FiltersBarProps<T extends FilterType> = StackProps & {
    filters: T[];
    onFiltersChange: (filters: T[]) => void;
    readOnly?: boolean;
    wrapperProps?: Partial<FilterWrapperProps>;
    clearable?: boolean;
};

export const FiltersBar = <T extends FilterType>(props: FiltersBarProps<T>): JSX.Element => {
    return (
        <ErrorBoundary>
            <InnerFilterBar<T> {...props} />
        </ErrorBoundary>
    );
};

const InnerFilterBar = <T extends FilterType>({
    filters,
    onFiltersChange = () => {},
    readOnly,
    wrapperProps,
    clearable = true,
    ...rest
}: FiltersBarProps<T>): JSX.Element => {
    const visibleFilters = filters.filter(filter => !filter.hide);
    const hiddenFilters = filters.filter(filter => filter.defaultVisibility === 'hidden');

    const getPopupId = (filter: T) => {
        return `filter-popup-id-${filter.key}`;
    };

    /**
     * Add or remove a filter from the visible filters
     */
    const handleFilterRemoved = (filter: T) => {
        const updatedFilters = filters.map(prevFilter => {
            if (prevFilter.key !== filter.key) {
                return prevFilter;
            }

            // if the filter is hidden by default, we reset the value and hide it
            if (filter.defaultVisibility === 'hidden' && !filter.hide) {
                return {
                    ...prevFilter,
                    hide: true,
                    // when hiding a filter, reset its value
                    value: undefined,
                };
            }

            // otherwise we just reset the value
            return clearFilter(prevFilter);
        });

        onFiltersChange(updatedFilters);
    };

    /**
     * Update the value of a filter
     */
    const handleFilterUpdated = (filter: T) => {
        const updatedFilters = filters.map(prevFilter => {
            if (prevFilter.key !== filter.key) {
                return prevFilter;
            }

            // SelectFilter and TreeSelectFilter components will return an empty array if no value is selected
            // but it's important to set the value to undefined in this case
            // filter bar will consider the filter as not set if the value is undefined
            if ((filter.type === 'multi-select' || filter.type === 'tree-multi-select') && filter.value?.length === 0) {
                return {
                    ...filter,
                    value: undefined,
                };
            }

            return filter;
        });
        onFiltersChange(updatedFilters);
    };

    const isClearable =
        clearable && (visibleFilters.some(f => !!f.value && f.clearable !== false) || visibleFilters.some(f => f.defaultVisibility === 'hidden' && !f.hide));

    const clearFilter = (filter: T) => {
        if (filter.clearable === false) {
            return filter;
        }
        return {
            ...filter,
            value: undefined,
            hide: filter.defaultVisibility === 'hidden',
        };
    };
    const handleClearAll = () => {
        onFiltersChange(filters.map(clearFilter));
    };

    return (
        <Stack direction='row' gap={1} alignItems='center' flexWrap='wrap' {...rest}>
            {!readOnly && !!hiddenFilters.length && (
                <MoreFiltersButton filters={hiddenFilters} onFilterRemoved={handleFilterRemoved} onFilterUpdated={handleFilterUpdated} />
            )}
            {visibleFilters.map(filter => (
                <FilterWrapper
                    key={filter.key}
                    popupId={getPopupId(filter)}
                    label={<FilterLabel filter={filter} />}
                    color={filter.value ? 'primary' : undefined}
                    clickable={!readOnly}
                    onFilterRemoved={!readOnly && filter.clearable !== false && filter.value ? () => handleFilterRemoved(filter) : undefined}
                    {...wrapperProps}
                >
                    <Filter filter={filter} onFilterUpdated={handleFilterUpdated} />
                </FilterWrapper>
            ))}

            {!readOnly && isClearable && (
                <>
                    <Divider orientation='vertical' flexItem sx={{ height: '20px', my: 'auto' }} />
                    <IconButton onClick={handleClearAll} size='small' aria-label='clearAll'>
                        <Cancel01Icon fontSize='small' size={18} />
                    </IconButton>
                </>
            )}
        </Stack>
    );
};

type FilterProps<T extends FilterType> = {
    filter: T;
    onFilterUpdated: (filter: T) => void;
};

const Filter = <T extends FilterType = FilterType>({ filter, onFilterUpdated }: FilterProps<T>) => {
    const handleFilterUpdated = (filter: FilterType) => {
        onFilterUpdated(filter as T);
    };

    switch (filter.type) {
        case 'select':
        case 'multi-select':
            return <SelectFilterComponent filter={filter} onFilterUpdated={handleFilterUpdated} />;
        case 'date':
            return <DateFilterComponent filter={filter} onFilterUpdated={handleFilterUpdated} />;
        case 'time':
            return <TimeFilter filter={filter} onFilterUpdated={handleFilterUpdated} />;
        case 'tree-select':
        case 'tree-multi-select':
            return <TreeSelectFilter filter={filter} onFilterUpdated={handleFilterUpdated} />;
        case 'boolean':
            return <BooleanFilter filter={filter} onFilterUpdated={handleFilterUpdated} />;
        case 'text':
        default:
            return <TextFilterComponent filter={filter} onFilterUpdated={handleFilterUpdated} />;
    }
};

type FilterWrapperProps = Omit<PopupStateProps, 'children' | 'variant'> & {
    label: ChipProps['label'];
    color?: ChipProps['color'];
    onFilterRemoved?: () => void;
    onClickAway?: () => void;
    clickable?: boolean;
};

const FilterWrapper: FC<PropsWithChildren<FilterWrapperProps>> = ({ children, label, clickable, onFilterRemoved, color, ...rest }) => {
    return (
        <PopupState variant='popper' {...rest}>
            {popupState => {
                return (
                    <>
                        <Chip
                            label={label}
                            clickable={clickable}
                            onDelete={onFilterRemoved}
                            color={color}
                            {...bindToggle(popupState)}
                            sx={{ height: FILTER_HEIGHT }}
                        />
                        <Popper placement='bottom-start' {...bindPopper(popupState)} transition>
                            {({ TransitionProps }) => (
                                <ClickAwayListener
                                    onClickAway={() => {
                                        rest.onClickAway?.();
                                        popupState.close();
                                    }}
                                >
                                    <Fade {...TransitionProps}>
                                        <Paper variant='outlined' sx={{ width: 320, marginY: 0.5 }}>
                                            {children}
                                        </Paper>
                                    </Fade>
                                </ClickAwayListener>
                            )}
                        </Popper>
                    </>
                );
            }}
        </PopupState>
    );
};

const FilterLabel = <T extends FilterType>({ filter, ...rest }: { filter: T } & StackProps) => {
    return (
        <Stack direction='row' gap={0.5} sx={{ fontWeight: filter.value ? 'bold' : undefined }} {...rest}>
            {/* We can't use typography, because we don't want to override the font style from the parent (chip) */}
            <Box>
                {filter.filterName}
                {filter.value && <FilterLabelValueSeparator filter={filter} />}
            </Box>

            {filter.value && (
                <Box maxWidth={200} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {getFilterValueDisplay(filter)}
                </Box>
            )}
            {['multi-select', 'tree-multi-select'].includes(filter.type) &&
                Array.isArray(filter.value) &&
                !!filter?.value?.length &&
                filter?.value?.length > 1 && <Box minWidth={28}>(+{filter.value?.length - 1})</Box>}
        </Stack>
    );
};

const FilterLabelValueSeparator = <T extends FilterType>({ filter }: { filter: T }) => {
    const DEFAULT_SEPARATOR = ':';

    const getSelectSeparator = (filter: SelectFilter | AsyncSelectFilter | TreeSelectFilterType | AsyncTreeSelectFilterType) => {
        // if the filter has no value, we don't display the separator
        if (!Array.isArray(filter.value) || !filter.value.length) {
            return '';
        }

        const hasRule = 'rule' in filter && !!filter.rule;
        if (hasRule) {
            switch (filter.rule) {
                case 'EQUALS':
                    return ' =';
                case 'NOT_EQUALS':
                    return ' ≠';
                default:
                    return DEFAULT_SEPARATOR;
            }
        }
        return DEFAULT_SEPARATOR;
    };

    const getDateSeparator = (filter: DateFilter) => {
        switch (filter.dateType) {
            case 'WITHIN_THE_LAST':
                return ' >=';
            case 'MORE_THAN':
                return ' <=';
            case 'BETWEEN':
            default:
                return DEFAULT_SEPARATOR;
        }
    };

    switch (filter.type) {
        case 'select':
        case 'multi-select':
        case 'tree-select':
        case 'tree-multi-select':
            return getSelectSeparator(filter);
        case 'date': {
            return getDateSeparator(filter);
        }
        case 'text':
        case 'time':
        default:
            return DEFAULT_SEPARATOR;
    }
};
const formatDateFilterValue = (filter: DateFilter): string => {
    if (filter.dateType === 'WITHIN_THE_LAST' || filter.dateType === 'MORE_THAN') {
        return filter.value ? formatInDefaultDate(filter.value) : '';
    }
    if (filter.dateType === 'BETWEEN') {
        const formatLeftRange = formatInDefaultDate(filter.value?.[0]);
        const formatRightRange = formatInDefaultDate(filter.value?.[1]);

        return formatLeftRange || formatRightRange ? `${formatLeftRange ?? '-'} -> ${formatRightRange ?? '-'}` : '';
    }
    return '';
};

const getFilterValueDisplay = (filter: FilterType): string => {
    switch (filter.type) {
        case 'select':
        case 'multi-select':
        case 'tree-select':
        case 'tree-multi-select':
            return filter.value?.[0]?.label ?? '';
        case 'date': {
            return formatDateFilterValue(filter);
        }
        case 'time': {
            const leftRange = filter.value?.[0];
            const rightRange = filter.value?.[1];
            const hasValue = !!leftRange || !!rightRange;

            return hasValue ? `${leftRange ?? '-'} - ${rightRange ?? '-'}` : '';
        }
        case 'boolean':
            return filter.value ? i18next.t('general.yes') : i18next.t('general.no');
        case 'text':
        default:
            return filter.value ?? '';
    }
};

const MoreFiltersButton = <T extends FilterType>({
    filters,
    onFilterRemoved,
    onFilterUpdated,
}: {
    filters: T[];
    onFilterRemoved: (filter: T) => void;
    onFilterUpdated: (filter: T) => void;
}): JSX.Element => {
    const { t } = useTranslation();

    const autocompleteProps = usePopperlessAutocomplete();
    const [currentFilterKey, setCurrentFilterKey] = useState<string>();

    // it's very important to get the current filter from the filters props and not from the state
    const currentFilter = filters.find(filter => filter.key === currentFilterKey);

    const id = 'more-filters';

    const handleFilterToggled = (filter: T) => {
        if (!filter.hide) {
            onFilterRemoved(filter);
        } else {
            setCurrentFilterKey(filter.key);
        }
    };

    const handleFilterUpdated = (filter: T) => {
        onFilterUpdated({ ...filter, hide: false });
    };

    const resetCurrentFilter = () => {
        setCurrentFilterKey(undefined);
    };

    return (
        <FilterWrapper popupId={id} label={t('general.more_filters')} onClickAway={resetCurrentFilter}>
            {!currentFilterKey && (
                <Stack gap={0.5} p={1}>
                    <Select
                        getOptionKey={option => option.key}
                        value={filters.filter(f => !f.hide)}
                        multiple
                        options={filters}
                        getOptionLabel={option => option.filterName}
                        onChange={(_option, _reason, detail) => {
                            if (detail?.option) {
                                handleFilterToggled(detail.option);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        fullWidth
                        autocompleteProps={autocompleteProps}
                    />
                </Stack>
            )}

            {/* Shortcut to apply a filter */}
            {currentFilter && (
                <Stack>
                    <Button
                        variant='text'
                        color='inherit'
                        size='small'
                        onClick={() => setCurrentFilterKey(undefined)}
                        startIcon={<ArrowLeft01Icon />}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        {currentFilter.filterName}
                    </Button>
                    {currentFilterKey && <Filter filter={currentFilter} onFilterUpdated={handleFilterUpdated} />}
                </Stack>
            )}
        </FilterWrapper>
    );
};
