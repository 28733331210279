import { SelectResource } from '@/components/select-resource/SelectResource';
import { Area } from '@/domain/area/Area.model';
import { Employee } from '@/domain/employee/Employee.model';
import { canApproveRejectTimesheets } from '@/domain/permission/Permission.service';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import {
    EmployeeTimesheetMutation,
    Timesheet,
    TimesheetAction,
    TimesheetConflict,
    TimesheetConflictType,
    TimesheetMutation,
    TimesheetType,
} from '@/domain/timesheet/Timesheet.model';
import { createTimesheets, getTimesheetConflicts, updatePendingTimesheet, updateTimesheet } from '@/domain/timesheet/Timesheet.service';
import { useGetAreas } from '@/hooks/area/Area.hook';
import i18next, { DurationUnit } from '@/i18n/i18n';
import { TIMESHEET_DEFAULT_END_TIME_HOUR, TIMESHEET_DEFAULT_START_TIME_HOUR } from '@/page/employee-timesheet/EmployeeTimesheet.util';
import { SelectOption } from '@/page/planning/scheduler-calendar/types';
import {
    EmployeeTimesheetNormalModeFormValues,
    getEmployeeTimesheetNormalModeSchema,
    TimesheetNormalModeFormValues,
} from '@/page/timesheet/timesheet-dialog/TimesheetDialog.schema';
import {
    diffTimesInMinutesWithOvernight,
    hasApprovedTimesheets,
    hasAtLeastOneTimesheetType,
    isForceCreation,
    SHOW_BREAK_DURATION_BETWEEN_TIMESHEETS_FROM_X_MINUTES,
    TIMESHEET_DEFAULT_DURATION,
} from '@/page/timesheet/timesheet-dialog/TimesheetDialog.util';
import { AreaField, CommentField, MissingDatesField, ReferenceDateField } from '@/page/timesheet/timesheet-dialog/TimesheetDialogCommonFields';
import { handleError } from '@/utils/api.util';
import {
    addDays,
    addMinutes,
    clearSeconds,
    formatDurationInHours,
    formatDurationInTime,
    formatInDefaultDate,
    getDateFromTimeFormat,
    getTimeFormatFromDate,
    isBeforeTime,
    isSameDate,
    isValidDate,
    isValidTime,
    setTime,
    subHours,
    toDate,
} from '@/utils/datetime.util';

import { FieldTime } from '@/components/form/field-time/FieldTime';
import { useCurrentPolicies } from '@/stores/store';
import { getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, DialogActions, DialogContent, Divider, FormControlLabel, IconButton, Stack, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { Add01Icon, Delete02Icon, Moon02Icon } from 'hugeicons-react';
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, UseFieldArrayInsert, UseFieldArrayRemove, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

type TimesheetNormalModeDialogContentProps = {
    referenceDate: LocalDate;
    onReferenceDateChange: (date: LocalDate) => void;
    defaultTimesheets: Timesheet[];
    mode: TimesheetAction;
    onSave: () => void;
    employee: Employee;
    defaultReferenceDate: LocalDate;
    missingEntriesDates?: LocalDate[]; //used when we want to complete multiple missing timesheets at once
    timesheetSetting?: TimesheetSetting;
};

type WithDate<T> = {
    [k in keyof T]: T[k] extends LocalTime | undefined ? Date : T[k];
};

export const TimesheetNormalModeDialogContent: FC<TimesheetNormalModeDialogContentProps> = ({
    referenceDate,
    onReferenceDateChange,
    defaultTimesheets,
    onSave,
    employee,
    mode,
    missingEntriesDates = [],
    timesheetSetting,
}) => {
    const { t } = useTranslation();

    const refTimesheetErrorMessage = useRef<HTMLDivElement>(getNull());
    const policies = useCurrentPolicies();
    const [isFetchingConflicts, setIsFetchingConflicts] = useState<boolean>(false);
    const [conflict, setConflict] = useState<TimesheetConflict>();

    const createDefaultTimesheetFormValues = (date: LocalDate): TimesheetNormalModeFormValues[] => {
        const startTime = toDate(date);
        startTime.setHours(TIMESHEET_DEFAULT_START_TIME_HOUR, 0, 0, 0);
        const endTime = toDate(date);
        endTime.setHours(TIMESHEET_DEFAULT_END_TIME_HOUR, 0, 0, 0);
        return [
            {
                startTime: formatDurationInTime(TIMESHEET_DEFAULT_START_TIME_HOUR * 60),
                endTime: formatDurationInTime(TIMESHEET_DEFAULT_END_TIME_HOUR * 60),
                comment: '',
                area: getNull(),
                breakDuration: 0,
            },
        ];
    };

    const mapTimesheetDefaultValues = useMemo(() => {
        if (!defaultTimesheets) {
            return createDefaultTimesheetFormValues(referenceDate);
        }
        return defaultTimesheets?.filter(
            timesheet =>
                timesheet.type === TimesheetType.TIMESHEET ||
                timesheet.type === TimesheetType.SHIFT_TIMESHEET ||
                timesheet.type === TimesheetType.MISSING ||
                timesheet.type === TimesheetType.MISSING_AND_UNPAID ||
                timesheet.type === TimesheetType.AUTOFILL,
        )?.length > 0
            ? mapTimesheetDialogData(defaultTimesheets)
            : createDefaultTimesheetFormValues(referenceDate);
    }, [defaultTimesheets, referenceDate]);

    const formMethods = useForm<EmployeeTimesheetNormalModeFormValues>({
        //TODO dont use the 'all' and use validate start end and break
        mode: 'all',
        resolver: yupResolver(getEmployeeTimesheetNormalModeSchema(timesheetSetting)),
        // change to values instead of defaultValues, but mapTimesheetDefaultValues should return a valid object
        // endAt can be null in case of a missing clock out, so we need to set it to undefined but it is not possible
        defaultValues: {
            missingDates: missingEntriesDates,
            employeeId: employee.id,
            referenceDate: referenceDate,
            timesheets: mapTimesheetDefaultValues,
        },
    });

    const {
        formState: { errors, isDirty },
        control,
        handleSubmit,
        watch,
        reset,
    } = formMethods;

    const {
        fields: timesheetsFields,
        append: addTimesheet,
        insert: insertTimesheet,
        remove: removeTimesheet,
    } = useFieldArray({
        control,
        name: 'timesheets',
    });

    const timesheets = watch('timesheets');
    const hasTimesheetError = !!errors?.timesheets?.message || !!errors?.timesheets?.root?.message;

    useEffect(() => {
        const elementRef = refTimesheetErrorMessage?.current;
        if (hasTimesheetError && elementRef) {
            elementRef?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [hasTimesheetError]);

    // What is the goal ?
    useDeepCompareEffect(() => {
        reset({
            missingDates: missingEntriesDates,
            employeeId: employee.id,
            referenceDate: referenceDate,
            timesheets: mapTimesheetDefaultValues,
        });
    }, [employee.id, mapTimesheetDefaultValues, missingEntriesDates, referenceDate, reset]);

    const { data: areas = [] } = useGetAreas({
        locationIds: employee.currentEmployments.flatMap(employment => employment.location.id),
    });

    const handleTimesheetEditing = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        if (hasApprovedTimesheets(defaultTimesheets)) {
            await handleUpdateTimesheets(employeeTimesheetMutation);
        } else {
            await handleUpdatePendingTimesheets(employeeTimesheetMutation);
        }
    };

    const handleUpdateTimesheets = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        try {
            await updateTimesheet(employeeTimesheetMutation, { withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId) });
            onSave();
        } catch (error) {
            handleErrorOnSave(error);
        }
    };

    const handleUpdatePendingTimesheets = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        try {
            await updatePendingTimesheet(employeeTimesheetMutation, {
                withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId),
            });
            onSave();
        } catch (error) {
            handleErrorOnSave(error);
        }
    };

    const handleTimesheetCreation = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        try {
            await createTimesheets(employeeTimesheetMutation, { withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId) });
            onSave();
        } catch (error) {
            handleErrorOnSave(error);
        }
    };

    const handleErrorOnSave = (error: unknown) => {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
            showSnackbar(t('timesheets.error_overlapping_timesheets'), 'error');
        } else {
            handleError(error);
        }
    };

    const saveTimesheetsForMissingTimesheets = (formValues: EmployeeTimesheetNormalModeFormValues) => {
        const missingDatesFromForm = watch('missingDates');
        if (!missingEntriesDates || !missingDatesFromForm) {
            return;
        }

        //search with the dates!
        const missingDates = missingEntriesDates.filter(date => {
            return missingDatesFromForm.some(missingDate => {
                return missingDate === date;
            });
        });

        missingDates.forEach(missingDate => {
            const missingEmployeeTimesheet: EmployeeTimesheetMutation = {
                employeeId: employee.id,
                referenceDate: missingDate,
                timesheets: mapToTimesheetFormValuesToMutation(formValues.timesheets, missingDate),
            };

            handleTimesheetEditing(missingEmployeeTimesheet).catch(handleError);
        });
    };

    const handleSaveTimesheet = async (formValues: EmployeeTimesheetNormalModeFormValues) => {
        setConflict(undefined);
        if (mode === TimesheetAction.MISSING_TIMESHEET) {
            saveTimesheetsForMissingTimesheets(formValues);
        } else {
            const payload: EmployeeTimesheetMutation = {
                ...formValues,
                timesheets: mapToTimesheetFormValuesToMutation(formValues.timesheets, formValues.referenceDate),
            };

            //we can remove this and both states, but we need to use useMutation, to improve when we start using useMutation
            setIsFetchingConflicts(true);
            const conflict = await getTimesheetConflicts(payload);
            setConflict(conflict);
            setIsFetchingConflicts(false);

            if (conflict.conflictTypes.length == 0) {
                createOrEditTimesheets(payload);
            }
        }
    };

    const createOrEditTimesheets = (payload: EmployeeTimesheetMutation) => {
        if (isForceCreation(mode, hasExistingTimesheetsInCurrentDate)) {
            handleTimesheetCreation(payload).catch(handleError);
        } else {
            handleTimesheetEditing(payload).catch(handleError);
        }
    };

    const handleAddNewTimesheet = () => {
        const currentTimesheet = timesheets[timesheets.length - 1];
        const newStartTime = currentTimesheet.endTime;
        const newEndTime = newStartTime ? addMinutes(getDateFromTimeFormat(newStartTime), TIMESHEET_DEFAULT_DURATION) : undefined;

        addTimesheet({
            startTime: newStartTime,
            endTime: newEndTime ? getTimeFormatFromDate(newEndTime) : newStartTime,
            breakDuration: 0,
            comment: '',
            area: getNull(),
        });
    };

    const hasExistingTimesheetsInCurrentDate = hasAtLeastOneTimesheetType(defaultTimesheets);

    const isAddTimesheetButtonDisabled = () => {
        return watch('timesheets').some(timesheet => {
            return !timesheet.startTime || !timesheet.endTime || !isValidTime(timesheet.startTime) || !isValidTime(timesheet.endTime);
        });
    };

    const displayNextDayDate = (timesheetIndex: number): boolean => {
        if (timesheetIndex === 0) {
            return false;
        }

        const currentStartTime = timesheets[timesheetIndex]?.startTime;
        const previousStartTime = timesheets[timesheetIndex - 1]?.startTime;
        const previousEndTime = timesheets[timesheetIndex - 1]?.endTime;

        // if one of them is missing, we can't determine if we have to display the next day date
        if (!currentStartTime || !previousStartTime || !previousEndTime) {
            return false;
        }

        const isStartBeforePreviousEnd = isBeforeTime(currentStartTime, previousEndTime);
        const isPreviousOnDifferentDay = isBeforeTime(previousEndTime, previousStartTime);

        return isStartBeforePreviousEnd || isPreviousOnDifferentDay;
    };

    const getTimesheetDayAt = (timesheetIndex: number): Date => {
        const timesheetsWithDate = getTimesheetsWithDateTime(timesheets, referenceDate);
        return timesheetsWithDate[timesheetIndex].startTime;
    };

    const isConflicts = !!conflict?.conflictTypes.length;

    return (
        <>
            <DialogContent>
                <FormProvider {...formMethods}>
                    <Stack alignItems={'start'} gap={2}>
                        {mode === TimesheetAction.MISSING_TIMESHEET && missingEntriesDates ? (
                            <MissingDatesField missingEntriesDates={missingEntriesDates} />
                        ) : (
                            <ReferenceDateField mode={mode} onChange={onReferenceDateChange} />
                        )}

                        <Stack ref={refTimesheetErrorMessage} sx={{ width: '100%' }} gap={1}>
                            {hasTimesheetError && (
                                <Alert severity='error'>
                                    <Typography variant='body2'>{errors?.timesheets?.message ?? errors?.timesheets?.root?.message}</Typography>
                                </Alert>
                            )}
                            {isConflicts && conflict && <ConflictAlert conflict={conflict} />}
                        </Stack>

                        {timesheetsFields?.map((timesheetCreationFormat, timesheetIndex) => {
                            return (
                                <Fragment key={timesheetCreationFormat.id}>
                                    {displayNextDayDate(timesheetIndex) ? (
                                        <DivideTimesheetNewDay startTime={getTimesheetDayAt(timesheetIndex)} />
                                    ) : (
                                        <DivideTimesheet timesheets={timesheets} timesheetIndex={timesheetIndex} addTimesheet={insertTimesheet} />
                                    )}
                                    <TimesheetDetails
                                        timesheetSetting={timesheetSetting}
                                        timesheetIndex={timesheetIndex}
                                        areas={areas}
                                        removeTimesheet={removeTimesheet}
                                    />
                                </Fragment>
                            );
                        })}
                        <Button
                            onClick={handleAddNewTimesheet}
                            disabled={isAddTimesheetButtonDisabled()}
                            startIcon={<Add01Icon />}
                            color='primary'
                            variant={'text'}
                            fullWidth
                        >
                            {t('timesheets.add_another_timeslot')}
                        </Button>
                    </Stack>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit(handleSaveTimesheet, console.error)}
                    fullWidth
                    disabled={(hasExistingTimesheetsInCurrentDate && !isDirty) || isFetchingConflicts}
                >
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </>
    );
};

type ConflictAlertProps = {
    conflict: TimesheetConflict;
};

const ConflictAlert: FC<ConflictAlertProps> = ({ conflict }) => {
    const { t } = useTranslation();
    const getParamsFromConflict = (conflictType: TimesheetConflictType, conflict: TimesheetConflict) => {
        switch (conflictType) {
            case 'TIMESHEET_MISSING_FIRST_BREAK':
                return {
                    minutes: conflict.timesheetSetting?.forceFirstBreakDurationInMinutes,
                    from: clearSeconds(conflict.timesheetSetting?.forceBreakToBeTakenFrom),
                    to: clearSeconds(conflict.timesheetSetting?.forceBreakToBeTakenTo),
                };
            case 'TIMESHEET_MISSING_SECOND_BREAK':
                return {
                    minutes: conflict.timesheetSetting?.forceSecondBreakDurationInMinutes,
                    from: clearSeconds(conflict.timesheetSetting?.forceBreakToBeTakenFrom),
                    to: clearSeconds(conflict.timesheetSetting?.forceBreakToBeTakenTo),
                };
            case 'TIMESHEET_MISSING_THIRD_BREAK':
                return {
                    minutes: conflict.timesheetSetting?.forceThirdBreakDurationInMinutes,
                    from: clearSeconds(conflict.timesheetSetting?.forceBreakToBeTakenFrom),
                    to: clearSeconds(conflict.timesheetSetting?.forceBreakToBeTakenTo),
                };
            case 'TIMESHEET_OUTSIDE_WORKING_HOURS':
                return {
                    opening: clearSeconds(conflict.openingTime),
                    closing: clearSeconds(conflict.closingTime),
                };
            default:
                return {};
        }
    };
    return (
        <Alert severity='error'>
            <ul
                style={{
                    paddingLeft: '16px',
                    marginTop: '0px',
                    marginBottom: '0px',
                }}
            >
                {conflict?.conflictTypes.map(c => (
                    <li key={c}>
                        <Typography variant='body2'>
                            {t('timesheets.enums.conflict.enum', {
                                context: c,
                                ...getParamsFromConflict(c, conflict),
                            })}
                        </Typography>
                    </li>
                ))}
            </ul>
        </Alert>
    );
};

type DivideTimesheetNewDayProps = {
    startTime: Date;
};

const DivideTimesheetNewDay: FC<DivideTimesheetNewDayProps> = ({ startTime }) => {
    const displayText = isValidDate(startTime)
        ? i18next.t('timesheets.next_day', {
              requestDate: formatInDefaultDate(startTime),
          })
        : '';

    return (
        <Stack alignItems={'center'} sx={{ width: '100%' }} spacing={1} textAlign={'center'} paddingBottom={2}>
            <Divider flexItem>
                <Stack direction={'row'} gap={1}>
                    <Typography variant='body1'>{displayText}</Typography>
                    <Moon02Icon size={16} />
                </Stack>
            </Divider>
        </Stack>
    );
};

type DivideTimesheetProps = {
    timesheetIndex: number;
    timesheets: TimesheetNormalModeFormValues[];
    addTimesheet: UseFieldArrayInsert<EmployeeTimesheetNormalModeFormValues>;
};

const DivideTimesheet: FC<DivideTimesheetProps> = ({ timesheets, timesheetIndex, addTimesheet }) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const getInformation = (timesheetIndex: number) => {
        if (timesheetIndex === 0) {
            const endTime = timesheets[timesheetIndex]?.startTime;

            if (!isValidTime(endTime)) {
                return;
            }

            const endDateTime = getDateFromTimeFormat(endTime);
            const startTime = getTimeFormatFromDate(subHours(endDateTime, 1));

            return { displayText: t('timesheets.start_of_day'), startTime: startTime, endTime: endTime };
        }

        const previousPosition = timesheetIndex - 1;
        if (previousPosition < 0) {
            return;
        }

        const previousEndTime = timesheets[previousPosition]?.endTime;
        const startTime = timesheets[timesheetIndex]?.startTime;
        const differenceBetweenTimesheetsInMinutes = diffTimesInMinutesWithOvernight(previousEndTime, startTime);

        const showBreakDuration =
            isValidTime(startTime) &&
            isValidTime(previousEndTime) &&
            differenceBetweenTimesheetsInMinutes > SHOW_BREAK_DURATION_BETWEEN_TIMESHEETS_FROM_X_MINUTES;

        if (!showBreakDuration) {
            return;
        }

        const formatDuration = i18next.t('duration.formatDuration', {
            duration: differenceBetweenTimesheetsInMinutes / 60,
            unit: DurationUnit.HOURS,
        });

        return {
            displayText: t('timesheets.break_duration', { breakDuration: formatDuration }),
            startTime: previousEndTime,
            endTime: startTime,
        };
    };

    const information = getInformation(timesheetIndex);

    if (!information) {
        return;
    }

    const addNewTimesheet = () => {
        const newTimesheetFormValues: TimesheetNormalModeFormValues = {
            startTime: information.startTime,
            endTime: information.endTime,
            breakDuration: 0,
            comment: '',
            area: getNull(),
        };

        addTimesheet(timesheetIndex, newTimesheetFormValues);
    };

    return (
        <Button
            startIcon={<Add01Icon />}
            onClick={addNewTimesheet}
            variant='text'
            fullWidth
            sx={{
                '&:not(:hover)': {
                    '& .MuiButton-startIcon': {
                        display: 'none',
                    },
                    color: palette.text.primary,
                },
                '&:hover': {
                    '& .MuiDivider-root': {
                        display: 'none',
                    },
                    '&::after': {
                        content: `'${t('timesheets.add_another_timeslot')}'`,
                    },
                },
            }}
        >
            <Divider sx={{ width: '100%' }}>{information.displayText}</Divider>
        </Button>
    );
};

type TimesheetDetailsProps = {
    timesheetSetting?: TimesheetSetting;
    timesheetIndex: number;
    areas: Area[];
    removeTimesheet: UseFieldArrayRemove;
};

const TimesheetDetails: FC<TimesheetDetailsProps> = ({ timesheetSetting, timesheetIndex, areas, removeTimesheet }) => {
    const { watch } = useFormContext<EmployeeTimesheetNormalModeFormValues>();
    const timesheets = watch('timesheets');

    return (
        <Stack gap={0.5} sx={{ width: '100%' }}>
            <Stack direction={'row'} alignItems={'flex-end'} sx={{ width: '100%' }} flexGrow={1}>
                <NormalWorkingTimePicker timesheetSetting={timesheetSetting} index={timesheetIndex} />

                {timesheets?.length > 1 && (
                    <IconButton onClick={() => removeTimesheet(timesheetIndex)} sx={{ color: 'text.primary' }}>
                        <Delete02Icon />
                    </IconButton>
                )}
            </Stack>
            {!!areas.length && <AreaField timesheetIndex={timesheetIndex} areas={areas} />}

            <CommentField timesheetIndex={timesheetIndex} />
        </Stack>
    );
};

type NormalWorkingTimePickerProps = {
    index: number;
    timesheetSetting?: TimesheetSetting;
};

const NormalWorkingTimePicker: FC<NormalWorkingTimePickerProps> = ({ index, timesheetSetting }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const AVAILABLE_BREAK_OPTIONS: number[] = Array.from(Array(121).keys());

    const { control, watch, setValue } = useFormContext<EmployeeTimesheetNormalModeFormValues>();
    const timesheets = watch('timesheets');
    const currentTimesheet = timesheets[index];

    if (!timesheetSetting?.breakDisplayEnabled && currentTimesheet.breakDuration !== 0) {
        setValue(`timesheets.${index}.breakDuration`, 0);
    }

    const getBreaks = (): SelectOption[] => {
        return AVAILABLE_BREAK_OPTIONS.map((pause: number) => {
            return {
                id: pause,
                name: pause !== 0 ? `${pause} ${t('general.minutes')}` : t('general.none'),
            };
        });
    };

    const getTotalWorkingTime = () => {
        const { startTime, endTime } = currentTimesheet;
        if (!startTime || !endTime) {
            return formatDurationInHours(0);
        }

        const diffInMinutes = diffTimesInMinutesWithOvernight(currentTimesheet.startTime, currentTimesheet.endTime) - currentTimesheet.breakDuration;
        return diffInMinutes >= 0 ? formatDurationInHours(diffInMinutes) : formatDurationInHours(0);
    };

    return (
        <Stack>
            <Stack direction='row' alignItems='flex-end' gap={1}>
                <Stack direction={'row'} gap={1} flexGrow={1}>
                    <FormControlLabel
                        label={t('general.start')}
                        control={
                            <FieldTime
                                name={`timesheets.${index}.startTime`}
                                control={control}
                                onChange={startDate => {
                                    if (!isValidTime(startDate)) {
                                        return;
                                    }
                                }}
                                timePickerProps={{
                                    slotProps: {
                                        textField: {
                                            // error is displayed outside the field
                                            helperText: '',
                                        },
                                    },
                                }}
                            />
                        }
                    />

                    <FormControlLabel
                        label={t('general.end')}
                        control={
                            <FieldTime
                                name={`timesheets.${index}.endTime`}
                                control={control}
                                onChange={endDate => {
                                    if (!isValidTime(endDate)) {
                                        return;
                                    }
                                }}
                                timePickerProps={{
                                    slotProps: {
                                        textField: {
                                            // error is displayed outside the field
                                            helperText: '',
                                        },
                                    },
                                }}
                            />
                        }
                    />

                    {timesheetSetting?.breakDisplayEnabled && (
                        <Controller
                            name={`timesheets.${index}.breakDuration`}
                            control={control}
                            render={({ field: { onChange, ...restField }, fieldState }) => (
                                <SelectResource
                                    {...restField}
                                    onUpdate={onChange}
                                    label={t('general.pause')}
                                    options={getBreaks()}
                                    isError={!!fieldState.error}
                                />
                            )}
                        />
                    )}
                </Stack>

                <Typography bgcolor={theme.palette.grey[100]} padding={1.2} borderRadius={1} variant='body2bold'>
                    {getTotalWorkingTime()}
                </Typography>
            </Stack>
        </Stack>
    );
};

function mapTimesheetDialogData(timesheets: Timesheet[]): (Omit<TimesheetNormalModeFormValues, 'endTime'> & {
    endTime?: LocalTime;
})[] {
    return timesheets.map(timesheet => {
        return {
            id: timesheet.id,
            startTime: getTimeFormatFromDate(timesheet.startAt),
            //endTime can be null in case of a missing clock out
            endTime: getTimeFormatFromDate(timesheet.endAt),
            comment: timesheet.comment,
            area: timesheet?.area ? { id: timesheet.area.id, name: timesheet.area.name } : getNull(),
            breakDuration: timesheet.breakDuration,
        };
    });
}

const getTimesheetsWithDateTime = (timesheets: TimesheetNormalModeFormValues[], referenceDate: LocalDate): WithDate<TimesheetNormalModeFormValues>[] => {
    return timesheets.reduce<WithDate<TimesheetNormalModeFormValues>[]>((acc, currTimesheet, index) => {
        const startLocalTime = currTimesheet.startTime;

        // first timesheet start with the reference date
        if (index === 0) {
            const newStartDateTime = setTime(referenceDate, startLocalTime);
            const newEndDateTime = getNewEndDateTime(newStartDateTime, currTimesheet.endTime);
            return [...acc, { ...currTimesheet, startTime: newStartDateTime, endTime: newEndDateTime }];
        }

        const previousTimesheet = acc[index - 1];
        const previousEndTime = getTimeFormatFromDate(previousTimesheet.endTime);

        // check if the start time is before the previous end time
        const isStartTimeBeforePreviousEndTime = isBeforeTime(startLocalTime, previousEndTime);

        if (isStartTimeBeforePreviousEndTime) {
            const alreadyOnDifferentDay = !isSameDate(previousTimesheet.startTime, previousTimesheet.endTime);
            // pass to the nex day if the previous timesheet is on the same day
            const newStartDate = alreadyOnDifferentDay ? previousTimesheet.endTime : addDays(previousTimesheet.endTime, 1);
            const newStartDateTime = setTime(newStartDate, startLocalTime);
            const newEndDateTime = getNewEndDateTime(newStartDateTime, currTimesheet.endTime);

            return [...acc, { ...currTimesheet, startTime: newStartDateTime, endTime: newEndDateTime }];
        }

        // same day as the enTime of the  previous timesheet
        const newStartDateTime = setTime(previousTimesheet.endTime, startLocalTime);
        const newEndDateTime = getNewEndDateTime(newStartDateTime, currTimesheet.endTime);
        return [...acc, { ...currTimesheet, startTime: newStartDateTime, endTime: newEndDateTime }];
    }, []);
};

const getNewEndDateTime = (startDateTime: Date, endTime: LocalTime): Date => {
    // /!\ startDateTime contains date and time
    const startTime = getTimeFormatFromDate(startDateTime);
    const newEndDateTime = setTime(startDateTime, endTime);

    const sameDay = isBeforeTime(startTime, endTime);
    // End is Before start, we need to add 1 day to endTime
    if (!sameDay) {
        return addDays(newEndDateTime, 1);
    }

    // End is Before start and it is already on a different day, we do nothing
    return newEndDateTime;
};

const mapToTimesheetFormValuesToMutation = (timesheetsFormValues: TimesheetNormalModeFormValues[], referenceDate: LocalDate): TimesheetMutation[] => {
    const timesheetsWithDate = getTimesheetsWithDateTime(timesheetsFormValues, referenceDate);

    return timesheetsWithDate.map(timesheet => ({
        ...timesheet,
        id: timesheet.id ?? undefined,
        areaId: timesheet.area?.id,
    }));
};
