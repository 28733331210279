import { searchCustomLists } from '@/domain/custom-list/CustomList.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const customListKeys = createQueryKeys('customLists', {
    search: {
        queryKey: getNull(),
    },
});

export const useSearchCustomLists = createQueryHook(customListKeys.search, searchCustomLists);
