import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridWrapper, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { ExpenseCategoryGroup } from '@/domain/expense-category-group/ExpenseCategoryGroup.model';

type ExpenseCategoryGroupSettingsGridProps = {
    onEditCategoryGroupClick: (categoryGroup: ExpenseCategoryGroup) => void;
    onDeleteCategoryGroupClick: (categoryGroup: ExpenseCategoryGroup) => void;
} & AgGridWrapperProps<ExpenseCategoryGroup>;

export const ExpenseCategoryGroupSettingsGrid: FC<ExpenseCategoryGroupSettingsGridProps> = ({
    onEditCategoryGroupClick,
    onDeleteCategoryGroupClick,
    ...restGridProps
}) => {
    const { t } = useTranslation();

    const ActionMenuRenderer = ({ data }: ICellRendererParams<ExpenseCategoryGroup>) => {
        if (!data) {
            return <></>;
        }

        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => onEditCategoryGroupClick(data),
                    },
                    {
                        title: t('general.delete'),
                        onClick: () => onDeleteCategoryGroupClick(data),
                    },
                ]}
            />
        );
    };

    const handleRowClick = ({ event, data }: RowClickedEvent<ExpenseCategoryGroup>) => {
        if (event?.defaultPrevented || !data) {
            return;
        }

        onEditCategoryGroupClick(data);
    };

    const columns: ColDef<ExpenseCategoryGroup>[] = [
        {
            field: 'name',
            headerName: t('general.name'),
            cellClass: ['display-flex'],
            type: 'label',
        },
        {
            type: 'actionMenu',
            cellRenderer: ActionMenuRenderer,
        },
    ];

    return <AgGridWrapper columnDefs={columns} onRowClicked={handleRowClick} {...restGridProps} />;
};
