import { useState } from 'react';
import { FormState } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

/**
 * Hook that scrolls to the first error in the form.
 * @param formState The form state to check for errors
 */
export const useScrollToFirstError = <TFieldValues extends Record<string, unknown>>(
    formState: FormState<TFieldValues>,
): {
    resetFocus: () => void;
} => {
    const { errors } = formState;
    // this state is used to prevent the scroll from happening multiple times, we want to scroll only once, when the error occurs
    // when we scroll to the first error, we set this state to false
    const [canFocus, setCanFocus] = useState(true);

    // Scroll to the element with the attribute aria-invalid='true'
    // Don't forget to propagate the field object from <Controller> to your field.
    // if the Controller component wrap don't wrap a field, don't forget to set aria-invalid based on the error : aria-invalid={!!errors}
    useDeepCompareEffect(() => {
        if (errors && canFocus) {
            const errorElement = document.querySelector("[aria-invalid='true']");
            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setCanFocus(false);
            }
        }
    }, [errors, canFocus]);

    return { resetFocus: () => setCanFocus(true) }; // Optional resetFocus function
};
