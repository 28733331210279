import snackbar from '@/utils/snackbar.util';
import Bugsnag, { Event, NotifiableError, OnErrorCallback } from '@bugsnag/js';
import { AxiosError, isAxiosError } from 'axios';
import { t } from 'i18next';

export const handleError = (error: unknown): void => {
    if (!isAxiosError(error)) {
        sendErrorInBugsnag(error);
        return;
    }

    // We can improve this kind of error handling in the future with a retry mechanism
    if (error?.code === AxiosError.ERR_NETWORK) {
        snackbar.showSnackbar(t('error_message.network_error'), 'error');
        return;
    }

    if (error.code === AxiosError.ERR_CANCELED) {
        // We don't want to send the error to Bugsnag if the request
        return;
    }

    if (error.response?.status === 403) {
        snackbar.showSnackbar(t('error_message.403'), 'error');
        return;
    }

    // TODO: https://rogerhr.atlassian.net/browse/RP-4249 Improve error message handling
    snackbar.showSnackbar(error.response?.data?.message, 'error');
};

const isNotifiableError = (error: unknown): error is NotifiableError => {
    if (!error) {
        return false;
    }

    if (typeof error === 'string') {
        return true;
    }
    if (typeof error === 'object' && 'errorClass' in error && 'errorMessage' in error) {
        return true;
    }
    if (typeof error === 'object' && 'name' in error && 'message' in error) {
        return true;
    }

    return error instanceof Error;
};

/**
 *
 * @param error
 * @returns
 */
export const getOnErrorCallbackForAxiosError =
    (error: AxiosError): OnErrorCallback =>
    (event: Event) =>
        event.addMetadata('endpoint', {
            url: error.config?.url,
            method: error.config?.method,
            status: error.response?.status,
            error: error.response?.data,
        });

export const sendErrorInBugsnag = (error: unknown): void => {
    if (isAxiosError(error)) {
        console.error('Sending axios error to Bugsnag', error);
        Bugsnag.notify(error, getOnErrorCallbackForAxiosError(error));
        return;
    }
    if (isNotifiableError(error)) {
        console.error('Sending application error to Bugsnag', error);
        Bugsnag.notify(error);
        return;
    }

    console.error('Error not handled and not sent to Bugsnag', error);
};
