import { createMutationHook, createQueryHook, createRequiredParamsQueryHook, defaultQueryOptions } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
    approveExpenseRequests,
    cancelApprovedExpenseRequests,
    cancelPendingExpenseRequests,
    countExpenseRequests,
    createExpenseRequest,
    declineApprovedExpenseRequests,
    declineExpenseRequests,
    searchExpenseRequests,
    searchGroupedApprovedExpenseRequests,
    searchGroupedExpenseRequests,
    searchGroupedPendingExpenseRequests,
    submitExpenseRequests,
    transmitToPaymentExpenseRequests,
    undoPaymentExpenseRequests,
    updateExpenseRequest,
} from '@/domain/expense-request/ExpenseRequest.service';
import { QueryClient } from '@tanstack/react-query';

export const expenseRequestKeys = createQueryKeys('expenseRequests', {
    search: (...params: Parameters<typeof searchExpenseRequests>) => ({
        queryKey: params,
    }),
    searchGroupedExpenseRequests: (...params: Parameters<typeof searchGroupedExpenseRequests>) => ({
        queryKey: params,
    }),
    searchGroupedPendingExpenseRequests: (...params: Parameters<typeof searchGroupedExpenseRequests>) => ({
        queryKey: params,
    }),
    searchGroupedApprovedExpenseRequests: (...params: Parameters<typeof searchGroupedExpenseRequests>) => ({
        queryKey: params,
    }),
    count: (...params: Parameters<typeof countExpenseRequests>) => ({
        queryKey: params,
    }),
});

export const useCountExpenseRequest = createQueryHook(expenseRequestKeys.count, countExpenseRequests);

export const useGetExpenseRequests = createRequiredParamsQueryHook(expenseRequestKeys.search, searchExpenseRequests, defaultQueryOptions);

// Grouped by employee by default, to be enhanced in the future
export const useGetGroupedExpenseRequests = createRequiredParamsQueryHook(
    expenseRequestKeys.searchGroupedExpenseRequests,
    searchGroupedExpenseRequests,
    defaultQueryOptions,
);

// Grouped by employee by default, to be enhanced in the future
export const useGetGroupedPendingExpenseRequests = createRequiredParamsQueryHook(
    expenseRequestKeys.searchGroupedPendingExpenseRequests,
    searchGroupedPendingExpenseRequests,
    defaultQueryOptions,
);

// Grouped by employee by default, to be enhanced in the future
export const useGetGroupedApprovedExpenseRequests = createRequiredParamsQueryHook(
    expenseRequestKeys.searchGroupedApprovedExpenseRequests,
    searchGroupedApprovedExpenseRequests,
    defaultQueryOptions,
);

const invalidateSearch = (queryClient: QueryClient) => {
    return Promise.all([
        queryClient.invalidateQueries({ queryKey: expenseRequestKeys.search._def }),
        queryClient.invalidateQueries({ queryKey: expenseRequestKeys.searchGroupedExpenseRequests._def }),
        queryClient.invalidateQueries({ queryKey: expenseRequestKeys.searchGroupedPendingExpenseRequests._def }),
        queryClient.invalidateQueries({ queryKey: expenseRequestKeys.searchGroupedApprovedExpenseRequests._def }),
    ]);
};
export const useCreateExpenseRequestMutation = createMutationHook(createExpenseRequest, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useUpdateExpenseRequestMutation = createMutationHook(updateExpenseRequest, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useCancelPendingExpenseRequestsMutation = createMutationHook(cancelPendingExpenseRequests, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useCancelApprovedExpenseRequestsMutation = createMutationHook(cancelApprovedExpenseRequests, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useSubmitExpenseRequestsMutation = createMutationHook(submitExpenseRequests, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useApproveExpenseRequestsMutation = createMutationHook(approveExpenseRequests, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useDeclineExpenseRequestsMutation = createMutationHook(declineExpenseRequests, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useDeclineApprovedExpenseRequestsMutation = createMutationHook(declineApprovedExpenseRequests, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useTransmitToPaymentExpenseRequestsMutation = createMutationHook(transmitToPaymentExpenseRequests, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});

export const useUndoPaymentExpenseRequestsMutation = createMutationHook(undoPaymentExpenseRequests, {
    onSuccess: ({ queryClient }) => {
        return invalidateSearch(queryClient);
    },
});
