import {
    DailyWorkingTimeBalanceSearchRequest,
    EmployeeDailyWorkingTimeBalance,
    EmployeeWeeklyWorkingTimeBalance,
    EmployeeWorkingTimeBalance,
    EmployeeWorkingTimeWarning,
    WeeklyWorkingTimeBalanceSearchRequest,
    WorkingTimeBalanceSearchRequest,
    WorkingTimeWarningSearchRequest,
} from '@/domain/timesheet-insight/TimesheetInsight.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';

const TIMESHEET_INSIGHT_API_BASE_PATH = API_BASE_URL + `/timesheet-insights`;

type WorkingTimeWarningSearchRequestDTO = WorkingTimeWarningSearchRequest;
type DailyWorkingTimeBalanceSearchRequestDTO = DailyWorkingTimeBalanceSearchRequest;
type WeeklyWorkingTimeBalanceSearchRequestDTO = WeeklyWorkingTimeBalanceSearchRequest;
type WorkingTimeBalanceSearchRequestDTO = WorkingTimeBalanceSearchRequest;
type EmployeeDailyWorkingTimeBalanceDTO = Omit<EmployeeDailyWorkingTimeBalance, 'employee'> & { employee: EmployeeDTO };
type EmployeeWeeklyWorkingTimeBalanceDTO = Omit<EmployeeWeeklyWorkingTimeBalance, 'employee'> & {
    employee: EmployeeDTO;
};
type EmployeeWorkingTimeBalanceDTO = Omit<EmployeeWorkingTimeBalance, 'employee'> & { employee: EmployeeDTO };
type EmployeeWorkingTimeWarningDTO = EmployeeWorkingTimeWarning;

const getEmployeeWorkingTimeWarnings = async (
    search: WorkingTimeWarningSearchRequest,
    { signal }: RequestOptions = {},
): Promise<EmployeeWorkingTimeWarningDTO[]> => {
    const url = TIMESHEET_INSIGHT_API_BASE_PATH + '/warnings';
    const { data } = await client.post<EmployeeWorkingTimeWarning[], AxiosResponse<EmployeeWorkingTimeWarningDTO[]>, WorkingTimeWarningSearchRequestDTO>(
        url,
        search,
        { signal },
    );
    return data;
};

const getEmployeeWorkingTimeBalances = async (
    search: WorkingTimeBalanceSearchRequest,
    { signal }: RequestOptions = {},
): Promise<EmployeeWorkingTimeBalance[]> => {
    const url = TIMESHEET_INSIGHT_API_BASE_PATH + '/time-balances';
    const { data: employeeTimeBalances } = await client.post<
        EmployeeWorkingTimeBalanceDTO[],
        AxiosResponse<EmployeeWorkingTimeBalanceDTO[]>,
        WorkingTimeBalanceSearchRequestDTO
    >(url, search, { signal });
    return employeeTimeBalances.map(mapEmployeeWorkingTimeBalance);
};

const mapEmployeeWorkingTimeBalance = ({ employee, ...workingTimeBalanceDto }: EmployeeWorkingTimeBalanceDTO): EmployeeWorkingTimeBalance => {
    return {
        ...workingTimeBalanceDto,
        employee: mapEmployeeDTO(employee),
    };
};

const getEmployeeDailyWorkingTimeBalances = async (
    search: DailyWorkingTimeBalanceSearchRequest,
    { signal }: RequestOptions = {},
): Promise<EmployeeDailyWorkingTimeBalance[]> => {
    const url = TIMESHEET_INSIGHT_API_BASE_PATH + '/time-balances/daily';
    const { data: employeeTimeBalances } = await client.post<
        EmployeeDailyWorkingTimeBalanceDTO[],
        AxiosResponse<EmployeeDailyWorkingTimeBalanceDTO[]>,
        DailyWorkingTimeBalanceSearchRequestDTO
    >(url, search, { signal });
    return employeeTimeBalances.map(mapEmployeeDailyWorkingTimeBalances);
};

const mapEmployeeDailyWorkingTimeBalances = ({
    employee,
    ...dailyWorkingTimeBalanceDTO
}: EmployeeDailyWorkingTimeBalanceDTO): EmployeeDailyWorkingTimeBalance => {
    return {
        ...dailyWorkingTimeBalanceDTO,
        employee: mapEmployeeDTO(employee),
    };
};

const getEmployeeWeeklyWorkingTimeBalances = async (
    search: WeeklyWorkingTimeBalanceSearchRequest,
    { signal }: RequestOptions = {},
): Promise<EmployeeWeeklyWorkingTimeBalance[]> => {
    const url = TIMESHEET_INSIGHT_API_BASE_PATH + '/time-balances/weekly';
    const { data: employeeTimeBalances } = await client.post<
        EmployeeWeeklyWorkingTimeBalanceDTO[],
        AxiosResponse<EmployeeWeeklyWorkingTimeBalanceDTO[]>,
        WeeklyWorkingTimeBalanceSearchRequestDTO
    >(url, search, { signal });
    return employeeTimeBalances.map(mapEmployeeWeeklyWorkingTimeBalances);
};

const mapEmployeeWeeklyWorkingTimeBalances = (weeklyDTO: EmployeeWeeklyWorkingTimeBalanceDTO): EmployeeWeeklyWorkingTimeBalance => {
    return {
        ...weeklyDTO,
        employee: mapEmployeeDTO(weeklyDTO.employee),
    };
};

export const timesheetInsightApi = {
    getEmployeeWorkingTimeBalances,
    getEmployeeDailyWorkingTimeBalances,
    getEmployeeWeeklyWorkingTimeBalances,
    getEmployeeWorkingTimeWarnings,
};
