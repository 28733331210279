import { getNull } from '@/utils/object.util';

export const isDraggableItem = (data: unknown): data is { id: string } => {
    if (typeof data !== 'object' || data === getNull()) {
        return false;
    }
    const { id } = data as { id: unknown };
    if (typeof id !== 'string') {
        return false;
    }
    return true;
};
