import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { EmployeeAvatar as EmployeeAvatarType, EmployeeStatus } from '@/domain/employee/Employee.model';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
    text: string;
    selectedWord?: string;
    employee?: EmployeeAvatarType;
};

export const Comment: FC<Props> = ({ text, selectedWord, employee }) => {
    const defaultUser = {
        id: 0,
        firstName: 'John',
        lastName: 'Doe',
        email: '',
        userStatus: EmployeeStatus.INACTIVE,
        username: '',
        avatarImageUrl: '',
        jobTitle: createDefaultLabel(),
    };

    const makeBold = (text: string, selectedWord: string) => {
        return text.replace(new RegExp('(\\b)(' + selectedWord + ')(\\b)', 'ig'), '$1<b>$2</b>$3');
    };

    if (!text) {
        return <></>;
    }

    return (
        <Stack direction='row' gap={2} alignItems='center'>
            <Stack direction='row' spacing={1} width={34} alignItems={'center'}>
                <Tooltip placement='left' title={employee ? employee.displayName : ''}>
                    {!employee ? (
                        <Avatar
                            alt={`${defaultUser.firstName} ${defaultUser.lastName}`}
                            src={defaultUser.avatarImageUrl}
                            sx={theme => ({
                                width: 24,
                                height: 24,
                                fontSize: 16,
                                borderRadius: 64,
                                backgroundColor: theme.palette.grey[200],
                                '& svg': {
                                    fill: theme.palette.text.primary,
                                },
                            })}
                        />
                    ) : (
                        <EmployeeAvatar employeeAvatar={employee} />
                    )}
                </Tooltip>
            </Stack>

            {text && selectedWord ? (
                <Typography variant='body2' dangerouslySetInnerHTML={{ __html: makeBold(text, selectedWord) }} />
            ) : (
                <Typography variant='body2'>{text}</Typography>
            )}
        </Stack>
    );
};
