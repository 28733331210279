import { getPermissionGroups } from '@/domain/permission-group/PermissionGroup.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const permissionGroupKeys = createQueryKeys('permissionGroups', {
    get: {
        queryKey: getNull(),
    },
});

export const useGetPermissionGroups = createQueryHook(permissionGroupKeys.get, getPermissionGroups);
