import { Employment } from '@/domain/employment/Employment.model';
import { EmploymentDialogDefaultValues } from '@/domain-ui/employment/employment-dialog/EmploymentDialog';
import { getNull } from '@/utils/object.util';
import { mapDepartmentToRecursiveValue } from '@/domain/department/Department.util';

type MapToEmploymentDefaultValuesArg = Pick<
    Employment,
    'startDate' | 'employmentCreateReason' | 'principal' | 'location' | 'job' | 'jobFamily' | 'managers' | 'department' | 'employmentCostCenters'
> & {
    id?: number;
};
export const mapToEmploymentDefaultValues = (employments: MapToEmploymentDefaultValuesArg[]): EmploymentDialogDefaultValues | undefined => {
    const principalEmployment = employments.find(employment => employment.principal);
    if (!principalEmployment) {
        return undefined;
    }
    const { startDate, employmentCreateReason } = principalEmployment;
    return {
        startDate,
        employmentUpdateReason: employmentCreateReason,
        items: employments.map(mapToEmploymentItem),
    };
};

export const mapToEmploymentItem = (employment: MapToEmploymentDefaultValuesArg): EmploymentDialogDefaultValues['items'][0] => {
    return {
        id: employment.id,
        principal: employment.principal,
        location: employment.location,
        job: employment.job,
        jobFamily: employment.jobFamily ?? getNull(),
        managers: employment.managers,
        department: mapDepartmentToRecursiveValue(employment.department),
        employmentCostCenters: employment.employmentCostCenters,
    };
};
