import { getDefaultRealmNotification } from '@/domain/default-realm-notification/DefaultRealmNotification.service';
import { createQueryHook } from '@/page/Query.type';
import { getNull } from '@/utils/object.util';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const defaultRealmNotificationKeys = createQueryKeys('defaultRealmNotifications', {
    get: {
        queryKey: getNull(),
    },
});
export const useGetDefaultRealmNotification = createQueryHook(defaultRealmNotificationKeys.get, getDefaultRealmNotification);
