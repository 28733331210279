import { StateHandler } from '@/components/state-handler/StateHandler';
import { DayPeriod } from '@/domain/date/Date.model';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { createWorkingPatternTemplate, defaultWorkingPatternDays } from '@/domain/working-pattern-template/WorkingPatternTemplate.service';
import { useGetWeeklyWorkingTimes } from '@/hooks/weekly-working-time/WeeklyWorkingTime.hook';
import { mapFormValuesToCreationRequest } from '@/page/setting/time-management/working-pattern/WorkingPatternFormPage.util';
import {
    About,
    WorkingHours,
    WorkingPatternTemplateDayForm,
    WorkingPatternTemplateForm,
    WorkingPatternTemplateWeekForm,
} from '@/page/setting/time-management/WorkingPatternFormPage';
import { handleError } from '@/utils/api.util';
import { DayOfWeek } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { WorkingPatternTemplateCreationRequest, WorkingPatternType } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';

type WorkingPatternFormDialogProps = {
    onClose: () => void;
};
export const WorkingPatternFormDialog: FC<WorkingPatternFormDialogProps> = ({ onClose }) => {
    const { t } = useTranslation();

    const { data: weeklyWorkingTimes = [], isLoading: loading, error, isError } = useGetWeeklyWorkingTimes();

    const weekSchema: yup.ObjectSchema<WorkingPatternTemplateWeekForm> = yup.object().shape({
        workingPatternTemplateDaysForm: yup
            .array()
            .of(
                yup.object().shape({
                    dayOfWeek: yup.string().required().oneOf(Object.values(DayOfWeek)),
                    amountWorked: yup.string<LocalTime>().required(),
                    dayPeriod: yup.string().required().oneOf(Object.values(DayPeriod)),
                }) satisfies yup.ObjectSchema<WorkingPatternTemplateDayForm>,
            )
            .required(),
    });

    const schema: yup.ObjectSchema<WorkingPatternTemplateForm> = yup.object().shape({
        name: yup.string().required(),
        type: yup.string().required().oneOf(Object.values(WorkingPatternType)),
        workingPatternTemplateWeeksForm: yup.array().of(weekSchema).required(),
        weeklyWorkingTimeId: yup.number().required(),
    });

    const formMethods = useForm<WorkingPatternTemplateForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            type: WorkingPatternType.FIXED,
            workingPatternTemplateWeeksForm: [
                {
                    workingPatternTemplateDaysForm: defaultWorkingPatternDays,
                },
            ],
            weeklyWorkingTimeId: undefined,
        },
    });

    useEffect(() => {
        const getWeeklyWorkingTimesId = (weeklyWorkingTimes: WeeklyWorkingTime[]) => {
            if (formMethods.getValues().weeklyWorkingTimeId) {
                return;
            }
            if (weeklyWorkingTimes.length === 1) {
                return weeklyWorkingTimes[0].id;
            }
            return undefined;
        };

        const weeklyWorkingTimesId = getWeeklyWorkingTimesId(weeklyWorkingTimes);
        if (weeklyWorkingTimesId) {
            formMethods.setValue('weeklyWorkingTimeId', weeklyWorkingTimesId);
        }
    }, [weeklyWorkingTimes, formMethods]);

    const saveWorkingPatternTemplate = async (formValues: WorkingPatternTemplateForm) => {
        const workingPatternTemplateCreationRequest: WorkingPatternTemplateCreationRequest = mapFormValuesToCreationRequest(formValues);
        try {
            await createWorkingPatternTemplate(workingPatternTemplateCreationRequest);
        } catch (error) {
            handleError(error);
        } finally {
            onClose();
        }
    };

    return (
        <DialogWrapper header={t('contract_page.working_pattern_form_dialog_title')} maxWidth={'md'} open={true} onClose={onClose}>
            <StateHandler isLoading={loading} isError={isError} error={error}>
                <Stack gap={2} component={DialogContent}>
                    <FormProvider {...formMethods}>
                        <About weeklyWorkingTimes={weeklyWorkingTimes} elevation={0} p={0} />
                        <WorkingHours elevation={0} p={0} />
                    </FormProvider>
                </Stack>
                <DialogActions>
                    <Button fullWidth onClick={formMethods.handleSubmit(saveWorkingPatternTemplate, console.error)}>
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </StateHandler>
        </DialogWrapper>
    );
};
