import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { SectionField } from '@/components/section/types';
import { EmployeeAddress } from '@/domain/employee/EmployeeAddress.model';
import { EmployeeAddressFieldType } from '@/domain/employee/EmployeeFields.model';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import {
    SectionDefinitionFormValues,
    getSectionDefinitionSchema,
    getFieldFormName,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { Country, getCountry } from '@/utils/countries.util';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
    employeeId: number;
    employeeAddress?: EmployeeAddress;
    sectionDefinition: SectionDefinition;
    onSave: (employeeAddressFormValues: SectionDefinitionFormValues) => void;
    excludeDates?: LocalDate[];
} & DialogWrapperProps;

export const EmployeeAddressDialog: FC<Props> = ({ open, onClose, onSave, employeeAddress, sectionDefinition, excludeDates = [] }) => {
    const { t } = useTranslation();
    const addressSectionSchema = getSectionDefinitionSchema({ sectionDefinition });
    const title = getLabelTranslation(sectionDefinition.name);

    const employeeAddressFieldValueMapping: Record<EmployeeAddressFieldType, string | Country | undefined> = {
        ADDRESS_START_DATE: employeeAddress?.startDate,
        ADDRESS_ADDRESS_LINE_1: employeeAddress?.addressLine1 ?? '',
        ADDRESS_ADDRESS_LINE_2: employeeAddress?.addressLine2 ?? '',
        ADDRESS_POST_CODE: employeeAddress?.postCode ?? '',
        ADDRESS_CITY: employeeAddress?.city ?? '',
        ADDRESS_REGION: employeeAddress?.region ?? '',
        ADDRESS_COUNTRY: employeeAddress?.country ? getCountry(employeeAddress.country) : undefined,
    };

    const fields: SectionField[] = sectionDefinition.fields.map(fieldDefinition => {
        const employeeAddressFieldType = fieldDefinition.fieldType as EmployeeAddressFieldType;
        return {
            fieldDefinitionId: fieldDefinition.id,
            formValueName: fieldDefinition.formId,
            title: getLabelTranslation(fieldDefinition.name),
            valueType: fieldDefinition.valueType,
            [getFieldValueProperty(fieldDefinition.valueType)]: employeeAddressFieldValueMapping[employeeAddressFieldType],
            required: fieldDefinition.mandatory,
            fieldType: employeeAddressFieldType,
            order: fieldDefinition.order,
        };
    });

    const formMethods = useForm<SectionDefinitionFormValues>({
        resolver: yupResolver(addressSectionSchema),
    });

    const { handleSubmit, watch } = formMethods;

    const fieldStartDate = sectionDefinition.fields.find(f => f.fieldType === 'ADDRESS_START_DATE');
    const watchedStartDate: LocalDate | undefined = fieldStartDate ? watch(getFieldFormName(fieldStartDate)) : undefined;
    const isForbiddenDate = !!watchedStartDate && excludeDates.includes(watchedStartDate);

    const onCloseDialog = () => {
        formMethods.reset();
        onClose();
    };

    return (
        <FormProvider {...formMethods}>
            <DialogWrapper header={title} open={open} onClose={onCloseDialog}>
                <Stack spacing={2} component={DialogContent}>
                    {fields.map(field => (
                        <Stack key={field.fieldDefinitionId}>
                            <Typography noWrap>
                                {field.title}
                                {field.required ? '*' : ''}
                            </Typography>
                            <EditableSectionFieldComponent field={field} />
                        </Stack>
                    ))}
                    {isForbiddenDate && <Alert severity={'error'}>{t('employee.address.existing_start_date_error')}</Alert>}
                </Stack>
                <DialogActions>
                    <Button onClick={handleSubmit(onSave)} disabled={isForbiddenDate}>
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </DialogWrapper>
        </FormProvider>
    );
};
