import { getDepartmentNodeOrganizations, searchDepartmentNodes, searchDepartments } from '@/domain/department/Department.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const departmentKeys = createQueryKeys('departments', {
    search: (...optionalParam: Parameters<typeof searchDepartments>) => {
        return { queryKey: [optionalParam ?? {}] };
    },
    searchDepartmentNodeOrganizations: (...optionalParam: Parameters<typeof getDepartmentNodeOrganizations>) => {
        return { queryKey: [optionalParam ?? {}] };
    },
    searchDepartmentNodes: (...optionalParam: Parameters<typeof searchDepartmentNodes>) => {
        return { queryKey: [optionalParam ?? {}] };
    },
});

export const useGetDepartments = createQueryHook(departmentKeys.search, searchDepartments);

// use this hook if you want to get the department nodes with their employees
// if you don't need to load employees, use or create a different hook to get only department nodes
export const useGetDepartmentNodeOrganizations = createQueryHook(departmentKeys.searchDepartmentNodeOrganizations, getDepartmentNodeOrganizations);

export const useGetDepartmentsNodes = createQueryHook(departmentKeys.searchDepartmentNodes, searchDepartmentNodes);
