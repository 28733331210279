import { getEmployeeSkills, searchEmployeeSkills } from '@/domain/employee-skill/EmployeeSkill.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeSkillKeys = createQueryKeys('employeeSkills', {
    get: (...params: Parameters<typeof getEmployeeSkills>) => ({
        queryKey: params,
    }),
    search: (...params: Parameters<typeof searchEmployeeSkills>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeSkills = createRequiredParamsQueryHook(employeeSkillKeys.get, getEmployeeSkills);

export const useSearchEmployeeSkills = createQueryHook(employeeSkillKeys.search, searchEmployeeSkills);
