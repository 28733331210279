import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, formHelperTextClasses, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useGetExpenseSetting, useUpdateExpenseSetting } from '@/hooks/expense-setting/ExpenseSetting.hook';
import { useGetDefaultCurrency } from '@/hooks/expense-currency/ExpenseCurrency.hook';
import { ExpenseSetting, ExpenseSettingUpdateMutation, MAX_VALUE_EXPENSE_SETTING_PAYMENT_THRESHOLD } from '@/domain/expense-setting/ExpenseSetting.model';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer } from '@/page/layout/Footer';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';

export const ExpenseSettingSettingsPage: FC = () => {
    const { data: expenseSetting, isLoading: isLoadingExpenseSetting, isError: isErrorExpenseSetting, error: errorExpenseSetting } = useGetExpenseSetting();

    return (
        <StateHandler isLoading={isLoadingExpenseSetting} isError={isErrorExpenseSetting} error={errorExpenseSetting}>
            {expenseSetting && <ExpenseSettingsForm expenseSetting={expenseSetting} />}
        </StateHandler>
    );
};

type ExpenseSettingsFormProps = {
    expenseSetting: ExpenseSetting;
};

const ExpenseSettingsForm: FC<ExpenseSettingsFormProps> = ({ expenseSetting }) => {
    const { t } = useTranslation();

    const { mutateAsync: expenseSettingMutate, isPending: isSavePending } = useUpdateExpenseSetting();

    const { handleSubmit, control, watch } = useForm<ExpenseSettingFormValues>({
        resolver: yupResolver(getExpenseSettingSchema()),
        values: mapExpenseSettingToFormValues(expenseSetting),
    });

    const { data: defaultCurrency } = useGetDefaultCurrency();

    const isAutomaticPaymentThresholdEnabled = watch('automaticPaymentThresholdEnabled');

    const handleUpdate = async (data: ExpenseSettingFormValues) => {
        try {
            const mutation: ExpenseSettingUpdateMutation = {
                automaticPaymentThreshold: data.automaticPaymentThresholdEnabled ? (data.automaticPaymentThreshold ?? undefined) : undefined,
                expenseTaxesEnabled: data.expenseTaxesEnabled,
                expenseCategoryParentEnabled: data.expenseCategoryParentEnabled,
            };
            await expenseSettingMutate({ id: expenseSetting.id, mutation });
            showSnackbar(t('general.success_saved'), 'success');
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <>
            <ContentContainer flex={1}>
                <Stack component={Paper} gap={2} p={3} mb={2}>
                    <FormControlLabel
                        label={t('expense_setting_settings_page.expense_tax')}
                        labelPlacement='end'
                        control={<FieldSwitch name='expenseTaxesEnabled' control={control} />}
                    />
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <FieldSwitch
                            name='automaticPaymentThresholdEnabled'
                            control={control}
                            label={t('expense_setting_settings_page.expense_auto_pay', {
                                context: isAutomaticPaymentThresholdEnabled ? 'on' : 'off',
                            })}
                            labelPlacement='end'
                        />

                        {isAutomaticPaymentThresholdEnabled && (
                            <>
                                <FieldNumber
                                    name={'automaticPaymentThreshold'}
                                    control={control}
                                    min={0}
                                    max={MAX_VALUE_EXPENSE_SETTING_PAYMENT_THRESHOLD}
                                    sx={{
                                        width: '8em',
                                        [`& .${formHelperTextClasses.root}`]: {
                                            whiteSpace: 'nowrap',
                                        },
                                    }}
                                    precision={2}
                                />

                                {defaultCurrency && <Typography variant={'body1'}>{defaultCurrency?.code}</Typography>}
                            </>
                        )}
                    </Stack>
                    <FormControlLabel
                        label={t('expense_setting_settings_page.expense_category_parent')}
                        labelPlacement='end'
                        control={<FieldSwitch name='expenseCategoryParentEnabled' control={control} />}
                    />
                </Stack>
            </ContentContainer>
            <Footer>
                <Button onClick={handleSubmit(handleUpdate, console.error)} variant='contained' disabled={isSavePending}>
                    {t('general.save')}
                </Button>
            </Footer>
        </>
    );
};

const getExpenseSettingSchema = () => {
    return yup.object().shape({
        automaticPaymentThresholdEnabled: yup.boolean().required(),
        automaticPaymentThreshold: yup
            .number()
            .nullable()
            .when('automaticPaymentThresholdEnabled', {
                is: (automaticPaymentThresholdEnabled: boolean) => automaticPaymentThresholdEnabled,
                then: schema => schema.min(0).max(MAX_VALUE_EXPENSE_SETTING_PAYMENT_THRESHOLD).required(),
            }),
        expenseTaxesEnabled: yup.boolean().required(),
        expenseCategoryParentEnabled: yup.boolean().required(),
    });
};

type ExpenseSettingFormValues = yup.InferType<ReturnType<typeof getExpenseSettingSchema>>;

const mapExpenseSettingToFormValues = (expenseSetting: ExpenseSetting): ExpenseSettingFormValues => ({
    automaticPaymentThresholdEnabled: !!expenseSetting.automaticPaymentThreshold,
    automaticPaymentThreshold: expenseSetting.automaticPaymentThreshold,
    expenseTaxesEnabled: expenseSetting.expenseTaxesEnabled,
    expenseCategoryParentEnabled: expenseSetting.expenseCategoryParentEnabled,
});
