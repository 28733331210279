import { Label } from '@/domain/label/Label.model';
import { AnswerResult, SurveyResultFilterType } from '@/domain/survey/Survey.model';
import { getLabelTranslation } from '@/utils/language.util';
import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyProgressBar } from './SurveyProgressBar';

type Props = {
    title: Label;
    answer: AnswerResult;
    groupBy: SurveyResultFilterType;
    activeGroupByFilters: string[];
};

export const ChoiceResult: FC<Props> = ({ title, activeGroupByFilters, answer, groupBy }) => {
    const { t } = useTranslation();

    if (!answer?.details || !answer.completions) {
        return <></>;
    }
    return (
        <Stack component={Paper} p={3} gap={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant='body1bold'>{getLabelTranslation(title)}</Typography>
                <Typography variant='body1'>
                    {answer.completions?.ALL[0].answered} {t('survey_results.answered')} - {answer.completions?.ALL[0].skipped} {t('survey_results.skipped')}
                </Typography>
            </Stack>
            {answer.question?.items?.map(item => (
                <SurveyProgressBar key={item.id} questionItem={item} answer={answer} groupBy={groupBy} activeGroupByFilters={activeGroupByFilters} />
            ))}
        </Stack>
    );
};
