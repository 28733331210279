import { FC, Fragment } from 'react';
import { Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { Department } from '@/domain/department/Department.model';
import { getLabelTranslation } from '@/utils/language.util';
import { ClampedTypography } from '@/components/typography/ClampedTypography';

type DepartmentHierarchyLabelProps = {
    departmentId: number;
} & StackProps;
/**
 * Department hierarchy label component to display the hierarchy of a department.
 * @param departmentId The id of the department to display its hierarchy.
 * @param rootProps The props of the root Stack component.
 * @returns The DepartmentHierarchyLabel component like a breadcrumb.
 */
export const DepartmentHierarchyLabel: FC<DepartmentHierarchyLabelProps> = ({ departmentId, ...rootProps }) => {
    const { data: departments = [] } = useGetDepartments({ withArchived: true });

    const getParents = (id: number): Department[] => {
        const department = departments.find(department => department.id === id);
        if (!department) {
            return [];
        }
        if (!department.parentId) {
            return [department];
        }

        return [...getParents(department.parentId), department];
    };

    const parents = getParents(departmentId);
    const SEPARATOR = '/';

    const calculateShrink = (index: number): number => {
        const isLast = index === parents.length - 1;
        //we want to shrink other elements as more as possible except the last one
        // the selected department should be visible as much as possible
        return isLast ? 1 : Math.pow(1000, parents.length - index);
    };
    return (
        <Tooltip title={parents.map(parent => getLabelTranslation(parent.name)).join(` ${SEPARATOR} `)}>
            <Stack {...rootProps} direction={'row'}>
                {parents.map((item, index) => (
                    <Fragment key={item.id}>
                        {/* flexShrink depends on the depth. the root is shrunk as more as possible, the last is never shrunk */}
                        <ClampedTypography flexShrink={calculateShrink(index)} sx={{ whiteSpace: 'nowrap', display: 'block' }}>
                            {getLabelTranslation(item.name)}
                        </ClampedTypography>
                        {index < parents.length - 1 && <Typography component='span'>{SEPARATOR}</Typography>}
                    </Fragment>
                ))}
            </Stack>
        </Tooltip>
    );
};
