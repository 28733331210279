import { EmployeeProfileCard } from '@/domain-ui/employee/employee-profile-card/EmployeeProfileCard';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { useCurrentEmployee } from '@/stores/store';
import { FC } from 'react';

export const CurrentEmployeeProfileCard: FC = () => {
    const currentEmployee = useCurrentEmployee();
    const employeeId = useEmployeeProfileId();

    return <EmployeeProfileCard employeeId={employeeId} currentEmployeeId={currentEmployee.id} />;
};
