import { getAvailableCurrencyCodes, getCurrencyRate } from '@/domain/currency-rate/CurrencyRate.service';
import { createQueryHook, defaultQueryOptions } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const currencyRateKeys = createQueryKeys('currencyRates', {
    get: (...params: Parameters<typeof getCurrencyRate>) => ({
        queryKey: params,
    }),
    availableCurrencyCodes: {
        queryKey: getNull(),
    },
});

export const useGetCurrencyRate = createQueryHook(currencyRateKeys.get, getCurrencyRate, defaultQueryOptions);
export const useGetAvailableCurrencyCodes = createQueryHook(currencyRateKeys.availableCurrencyCodes, getAvailableCurrencyCodes, defaultQueryOptions);
