import { SurveyAnswer, SurveyQuestion, SurveyQuestionItem } from '@/domain/survey/Survey.model';
import { SurveyTemplate, SurveyTemplateQuestion, SurveyTemplateQuestionItem } from '@/domain/survey-template/Survey-template.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { assignLocalId } from '@/utils/object.util';

export type SurveyQuestionItemForm = Overwrite<WithLocalId<SurveyQuestionItem>, { id?: number }>;

export type SurveyQuestionForm = Overwrite<
    WithLocalId<SurveyQuestion>,
    {
        id?: number;
        items: SurveyQuestionItemForm[];
    }
>;

export type SurveyTemplateQuestionItemForm = Overwrite<WithLocalId<SurveyTemplateQuestionItem>, { id?: number }>;

export type SurveyTemplateQuestionForm = Overwrite<
    WithLocalId<SurveyTemplateQuestion>,
    {
        id?: number;
        items: SurveyTemplateQuestionItemForm[];
    }
>;

export type SurveyTemplateForm = Overwrite<
    WithLocalId<SurveyTemplate>,
    {
        id?: number;
        questions: SurveyTemplateQuestionForm[];
        createdBy?: EmployeeAvatar;
    }
>;

export type SurveyAnswerForm = Overwrite<
    WithLocalId<SurveyAnswer>,
    {
        id?: number;
        surveyId?: number;
        surveyQuestionId?: number;
        items: SurveyQuestionItemForm[];
    }
>;

export const mapSurveyQuestionToForm = (question: SurveyQuestion): SurveyQuestionForm => {
    return assignLocalId({
        ...question,
        items: question.items.map(assignLocalId),
    });
};

export const mapSurveyAnswerToForm = (answer: SurveyAnswer): SurveyAnswerForm => {
    return assignLocalId({
        ...answer,
        items: answer.items.map(i => assignLocalId(i)),
    });
};

export const mapTemplateToForm = (template: SurveyTemplate): SurveyTemplateForm => {
    return assignLocalId({
        ...template,
        questions: template.questions.map(q => {
            return assignLocalId({
                ...q,
                items: q.items.map(i => assignLocalId(i)),
            });
        }),
    });
};
