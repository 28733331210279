import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridWrapper, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { Label } from '@/domain/label/Label.model';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { ExpenseCategoryType } from '@/domain/expense-category/ExpenseCategory.model';

export type RowPreset = {
    id?: number;
    localId: string;
    name: Label;
    amount: number;
};

type ExpenseCategoryPresetsGridProps = {
    categoryType: ExpenseCategoryType;
    onEditPreset: (preset: RowPreset) => void;
    onDeletePreset: (preset: RowPreset) => void;
} & AgGridWrapperProps<RowPreset>;

export const ExpenseCategorySettingsPresetsGrid: FC<ExpenseCategoryPresetsGridProps> = ({ onEditPreset, onDeletePreset, categoryType, ...restGridProps }) => {
    const { t } = useTranslation();
    const { setGridRef } = useAgGridWrapper<RowPreset>();

    const actionMenuRenderer = (params: ICellRendererParams<RowPreset>) => {
        if (!params.data) {
            return <></>;
        }
        return <ActionMenuRenderer preset={params.data} onEdit={onEditPreset} onDelete={onDeletePreset} />;
    };

    const handleRowClick = (params: RowClickedEvent<RowPreset>) => {
        if (params.event?.defaultPrevented || !params.data) {
            return;
        }
        onEditPreset(params.data);
    };

    const columns: ColDef<RowPreset>[] = [
        {
            field: 'name',
            headerName: t('expense_category_settings_page.preset_name_header'),
            type: 'label',
            width: 300,
            cellClass: ['display-flex'],
        },
        {
            field: 'amount',
            width: 300,
            headerName: t('expense_category_settings_page.preset_value_header'),
            valueFormatter: params => {
                if (!params.data) {
                    return '';
                }
                const unit = categoryType === 'AMOUNT' ? 'CHF' : 'km';
                return `${params.data.amount} ${unit}`;
            },
            cellClass: ['display-flex'],
        },
        {
            type: 'actionMenu',
            cellRenderer: actionMenuRenderer,
            width: 100,
        },
    ];

    return <AgGridWrapper<RowPreset> columnDefs={columns} onRowClicked={handleRowClick} initRef={setGridRef} {...restGridProps} />;
};

type ActionMenuRendererProps = {
    preset: RowPreset;
    onEdit: (preset: RowPreset) => void;
    onDelete: (preset: RowPreset) => void;
};

const ActionMenuRenderer: FC<ActionMenuRendererProps> = ({ preset, onEdit, onDelete }) => {
    const { t } = useTranslation();

    return (
        <BasicMenu
            items={[
                { title: t('general.edit'), onClick: () => onEdit(preset) },
                { title: t('general.delete'), onClick: () => onDelete(preset) },
            ]}
        />
    );
};
