import { ClampedTypography } from '@/components/typography/ClampedTypography';
import { Employee } from '@/domain/employee/Employee.model';
import { ThirdPartyIntegrationLogGroup } from '@/domain/third-party/ThirdParty.model';
import { useSearchThirdPartyIntegrationLogs } from '@/hooks/third-party/ThirdParty.hook';
import { ThirdPartyConnectorEmployeeLogDialog } from '@/page/setting/third-party/third-party-connector-employee-log-dialog/ThirdPartyConnectorEmployeeLogDialog';
import { Chip, chipClasses, IconButton, Stack } from '@mui/material';
import { Tick01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployeeThirdPartyLogs: FC<{ employee: Employee }> = ({ employee }) => {
    const { t } = useTranslation();
    const [selectedLogGroup, setSelectedLogGroup] = useState<ThirdPartyIntegrationLogGroup>();

    const { data: thirdPartyIntegrationLogsGroup = [] } = useSearchThirdPartyIntegrationLogs({
        employeeId: employee.id,
    });

    const getConnectorName = (log: ThirdPartyIntegrationLogGroup) => (log?.name || '') + ' - ' + (log?.description || '');

    return (
        <Stack gap={1}>
            {thirdPartyIntegrationLogsGroup.map(log => {
                return (
                    <Stack direction={'row'} alignItems={'center'} gap={0.5} key={log.thirdPartyIntegrationId}>
                        <ClampedTypography variant={'body1'}>{getConnectorName(log)}</ClampedTypography>
                        <ButtonNumberOfError
                            thirdParty={log}
                            setOpenDialog={() => {
                                setSelectedLogGroup(log);
                            }}
                        />
                    </Stack>
                );
            })}

            {selectedLogGroup && (
                <ThirdPartyConnectorEmployeeLogDialog
                    open={true}
                    title={t('third_party.connector_logs.dialog_employee_title', {
                        connectorName: getConnectorName(selectedLogGroup),
                        employeeName: employee.displayName,
                        employeeId: employee.id,
                    })}
                    thirdPartyIntegrationLogs={selectedLogGroup.thirdPartyIntegrationLogs}
                    onClose={() => {
                        setSelectedLogGroup(undefined);
                    }}
                />
            )}
        </Stack>
    );
};

const ButtonNumberOfError: FC<{
    thirdParty: ThirdPartyIntegrationLogGroup;
    setOpenDialog: (value: boolean) => void;
}> = ({ thirdParty, setOpenDialog }) => {
    const numberOfErrors = thirdParty.thirdPartyIntegrationLogs.filter(log => !!log.errorMessage && !log.fixed).length;
    // TODO replace by common component to display icon with numbers

    if (numberOfErrors === 0) {
        return (
            <IconButton onClick={() => setOpenDialog(true)} color={'success'}>
                <Tick01Icon />
            </IconButton>
        );
    }

    return (
        <Chip
            label={numberOfErrors < 100 ? numberOfErrors : '99+'}
            variant='filled'
            size='small'
            onClick={() => setOpenDialog(true)}
            color='error'
            sx={{
                height: '20px',
                borderRadius: '0.5',
                [`& .${chipClasses.label}`]: {
                    padding: 0,
                    fontSize: '12px',
                    lineHeight: '12px',
                    fontWeight: 500,
                },
            }}
        />
    );
};
