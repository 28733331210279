import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { Button, Paper, Stack } from '@mui/material';
import { Add01Icon } from 'hugeicons-react';
import { useGetExpenseCategories } from '@/hooks/expense-category/ExpenseCategory.hook';
import { ExpenseCategory } from '@/domain/expense-category/ExpenseCategory.model';
import { deleteExpenseCategory } from '@/domain/expense-category/ExpenseCategory.service';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { ExpenseCategorySettingsGrid } from '@/page/setting/expense/expense-category/ExpenseCategorySettingsGrid';
import { DeleteConfirmDialog } from '@/components/delete-confirmation-dialog/DeleteConfirmDialog';
import { getLabelTranslation } from '@/utils/language.util';

export const ExpenseCategorySettingsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const route = useLocation();
    const { data: categories = [], refetch: refetchCategories, isLoading: isCategoriesLoading } = useGetExpenseCategories();
    const { setGridRef, quickFilter, gridRef } = useAgGridWrapper<ExpenseCategory>();

    const [categoryToDelete, setCategoryToDelete] = useState<ExpenseCategory | undefined>(undefined);

    const onClickCategoryRow = (category: ExpenseCategory) => {
        navigate(`${route.pathname}/${category.id}`);
    };

    const onDeleteCategory = async (category: ExpenseCategory) => {
        try {
            await deleteExpenseCategory(category.id);
            showSnackbar(t('expense_category_settings_page.success.category_deleted'), 'success');
            await refetchCategories();
        } catch (error) {
            handleError(error);
        } finally {
            setCategoryToDelete(undefined);
        }
    };

    const closeConfirmationDialog = () => {
        setCategoryToDelete(undefined);
    };

    const onExport = () => {
        gridRef.current?.api?.exportDataAsExcel();
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack direction='row' gap={1} justifyContent='flex-end' alignItems='center' component={Paper} p={1}>
                <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onExport} />
                <Button variant='contained' onClick={() => navigate(`${route.pathname}/new`)} startIcon={<Add01Icon />}>
                    {t('expense_category_settings_page.new_category')}
                </Button>
            </Stack>
            <ExpenseCategorySettingsGrid
                rowData={categories}
                onRowClick={onClickCategoryRow}
                onDeleteCategory={(category: ExpenseCategory) => setCategoryToDelete(category)}
                initRef={setGridRef}
                loading={isCategoriesLoading}
            />

            {categoryToDelete && (
                <DeleteConfirmDialog
                    open={true}
                    onConfirm={() => onDeleteCategory(categoryToDelete)}
                    header={t('expense_category_settings_page.delete_dialog.title')}
                    textContent={t('expense_category_settings_page.delete_dialog.confirmation_text', { categoryName: categoryToDelete.name })}
                    fieldName={getLabelTranslation(categoryToDelete?.name)}
                    onClose={closeConfirmationDialog}
                />
            )}
        </Stack>
    );
};
