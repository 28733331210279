import { Area, AreaSearchRequest } from '@/domain/area/Area.model';
import { searchAreas } from '@/domain/area/Area.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const areaKeys = createQueryKeys('areas', {
    search: (...optionalParam: Parameters<typeof searchAreas>) => {
        return { queryKey: [optionalParam ?? {}] };
    },
});

export const useGetAreas = createQueryHook<Area[], AreaSearchRequest>(areaKeys.search, searchAreas);
