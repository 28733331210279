import { useValidPassword } from '@/page/auth/change-password-form/useValidPassword';
import { Button, FormControl, FormHelperText, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Cancel01Icon, Tick02Icon, ViewIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    title: string;
    buttonLabel: string;
    loading: boolean;
    onSubmitPassword: (password: string) => void;
};
export const PasswordForm: FC<Props> = ({ title, buttonLabel, loading, onSubmitPassword }) => {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState<string>('');
    const [displayPassword, setDisplayPassword] = useState<boolean>(false);

    const { has8Characters, hasUppercaseLetter, hasLowercaseLetter, hasNumber, isValid } = useValidPassword(newPassword);

    const isFormDisabled = () => {
        return !isValid;
    };

    return (
        <Stack spacing={2} alignItems='stretch'>
            <Typography variant='body2bold'>{title}</Typography>

            <FormControl fullWidth={true} variant='outlined' size='small'>
                <Typography variant='body2'>{t('login_form.password')}</Typography>
                <TextField
                    id='password-input'
                    type={!displayPassword ? 'password' : 'text'}
                    value={newPassword}
                    onChange={event => setNewPassword(event.target.value)}
                    autoFocus
                    slotProps={{
                        input: {
                            endAdornment: (
                                <IconButton size='small' onClick={() => setDisplayPassword(!displayPassword)}>
                                    <ViewIcon />
                                </IconButton>
                            ),
                        },
                    }}
                />
            </FormControl>
            <Stack>
                <PasswordHint text={t('login_form.at_least_8_characters')} isValid={has8Characters} />
                <PasswordHint text={t('login_form.contains_lowercase_letter')} isValid={hasLowercaseLetter} />
                <PasswordHint text={t('login_form.contains_uppercase_letter')} isValid={hasUppercaseLetter} />
                <PasswordHint text={t('login_form.contains_number')} isValid={hasNumber} />
            </Stack>
            <Button
                fullWidth={true}
                color='primary'
                variant='contained'
                loading={loading}
                loadingPosition={'start'}
                startIcon={<></>}
                disabled={isFormDisabled() || loading}
                onClick={() => onSubmitPassword(newPassword)}
            >
                {buttonLabel}
            </Button>
        </Stack>
    );
};

type PasswordHintProps = {
    text: string;
    isValid: boolean;
};

const PasswordHint: FC<PasswordHintProps> = ({ text, isValid }) => {
    return (
        <FormHelperText component='div' color={isValid ? 'success' : 'error'}>
            <Stack direction='row' spacing={1} alignItems='center'>
                {isValid ? <Tick02Icon size={16} /> : <Cancel01Icon size={16} />}
                <Typography>{text}</Typography>
            </Stack>
        </FormHelperText>
    );
};
