import { searchWorkingPatternTemplates } from '@/domain/working-pattern-template/WorkingPatternTemplate.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const workingPatternTemplateKeys = createQueryKeys('workingPatternTemplates', {
    search: (...optionalParam: Parameters<typeof searchWorkingPatternTemplates>) => ({
        queryKey: [optionalParam ?? {}],
    }),
});

export const useGetWorkingTemplates = createQueryHook(workingPatternTemplateKeys.search, searchWorkingPatternTemplates);
