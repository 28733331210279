import { createQueryHook, defaultQueryOptions } from '@/page/Query.type';
import { searchExpenseTaxes } from '@/domain/expense-tax/ExpenseTax.service';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const expenseTaxKeys = createQueryKeys('expenseTaxes', {
    search: (...optionalParam: Parameters<typeof searchExpenseTaxes>) => ({
        queryKey: [optionalParam ?? {}],
    }),
});

export const useGetExpenseTaxes = createQueryHook(expenseTaxKeys.search, searchExpenseTaxes, defaultQueryOptions);
