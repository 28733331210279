import { CalendarDayType, getCalendarDayTypeTranslationKey } from '@/domain/calendar/Calendar.model';

import { showSnackbar } from '@/utils/snackbar.util';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DayPeriod } from '@/domain/date/Date.model';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldText } from '@/components/form/field-text/FieldText';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { getDayPeriodTranslationKey } from '@/domain/date/Date.service';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { assignLocalId } from '@/utils/object.util';
import { CalendarDayFormValues, getCalendarDayFormSchema } from '@/page/setting/calendar/calendar-day-dialog/CalendarDayDialog.schema';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';

type Props = {
    open: boolean;
    onClose: () => void;
    onSave: (day: CalendarDayFormValues) => void;
    onDelete: (calendarDayLocalId: string) => void;
    day?: CalendarDayFormValues;
};

export const CalendarDayDialog: FC<Props> = ({ open, onSave, onDelete, day, onClose }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isEdit = !!day;

    const { handleSubmit, control } = useForm({
        resolver: yupResolver(getCalendarDayFormSchema()),
        defaultValues: getDefaultValues(day),
    });

    const handleSave = (newValue: CalendarDayFormValues) => {
        onSave(newValue);
        showSnackbar(isEdit ? t('calendar_dialog.messages.calendar_day_updated') : t('calendar_dialog.messages.calendar_day_added'), 'success');
    };

    const handleDelete = () => {
        if (!isEdit) {
            return;
        }
        onDelete(day.localId);
        showSnackbar(t('calendar_dialog.messages.calendar_day_deleted'), 'success');
    };

    const headerTitle = isEdit ? t('calendar_day_dialog.title_edit_day') : t('calendar_day_dialog.title_add_day');
    return (
        <DialogWrapper onClose={onClose} open={open} fullScreen={fullScreen} header={headerTitle}>
            <DialogContent>
                <Stack spacing={3}>
                    <FormControlLabel label={t('calendar_day_dialog.day_name')} control={<FieldText name='name' control={control} fullWidth />} />
                    <FormControlLabel label={t('calendar_day_dialog.date')} control={<FieldLocalDate name='date' control={control} />} />
                    <FormControlLabel
                        label={t('calendar_day_dialog.day_period')}
                        control={
                            <FieldSelect
                                control={control}
                                name='dayPeriod'
                                options={Object.values(DayPeriod)}
                                getOptionLabel={option => t(getDayPeriodTranslationKey(option))}
                                isOptionEqualToValue={(option, value) => option === value}
                                fullWidth
                            />
                        }
                    />

                    <FormControlLabel
                        label={t('calendar_day_dialog.type')}
                        control={
                            <FieldSelect
                                control={control}
                                name='dayType'
                                options={Object.values(CalendarDayType)}
                                getOptionLabel={option => t(getCalendarDayTypeTranslationKey(option))}
                                isOptionEqualToValue={(option, value) => option === value}
                                fullWidth
                            />
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                {isEdit && (
                    <Button variant='text' color='error' onClick={() => handleDelete()} fullWidth>
                        {t('calendar_day_dialog.delete_day')}
                    </Button>
                )}
                <Button onClick={handleSubmit(handleSave, console.error)} variant='contained' fullWidth>
                    {t(isEdit ? 'general.update' : 'general.create')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getDefaultValues = (day: CalendarDayFormValues | undefined) => {
    if (!day) {
        return assignLocalId({
            dayType: CalendarDayType.HOLIDAY,
            name: '',
            dayPeriod: DayPeriod.ALL_DAY,
            date: undefined,
        });
    }
    return day;
};
