// Review Templates
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import {
    ReviewEmailMutation,
    ReviewEmailTemplate,
    ReviewTemplate,
    ReviewTemplateItem,
    ReviewTemplateItemMutation,
    ReviewTemplateMutation,
    SearchReviewTemplate,
} from '@/domain/review-template/ReviewTemplate.model';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

export type ReviewTemplateDTO = Omit<ReviewTemplate, 'items' | 'createdBy' | 'emailTemplates'> & {
    items: ReviewTemplateItemDTO[];
    createdBy: EmployeeAvatarDTO;
    emailTemplates: ReviewEmailTemplateDTO[];
};

export type ReviewEmailTemplateDTO = Omit<ReviewEmailTemplate, 'createdBy' | 'updatedBy' | 'createdAt' | 'updatedAt'> & {
    createdBy: EmployeeAvatarDTO;
    updatedBy: EmployeeAvatarDTO;
    createdAt: string;
    updatedAt: string;
};

type ReviewTemplateRequest = Omit<ReviewTemplateMutation, 'items' | 'emailTemplates'> & {
    items: ReviewTemplateItemRequest[];
    emailTemplates: ReviewEmailRequest[];
};

type ReviewEmailRequest = ReviewEmailMutation;

export type ReviewTemplateItemDTO = ReviewTemplateItem;

export type ReviewTemplateItemRequest = ReviewTemplateItemMutation;

type SearchReviewTemplateRequest = SearchReviewTemplate;

const mapReviewEmailTemplateDTO = (dto: ReviewEmailTemplateDTO): ReviewEmailTemplate => ({
    ...dto,
    createdAt: convertUTCIsoStringToDate(dto.createdAt),
    updatedAt: convertUTCIsoStringToDate(dto.updatedAt),
});

export const mapReviewTemplateDTO = (dto: ReviewTemplateDTO): ReviewTemplate => ({
    ...dto,
    emailTemplates: dto?.emailTemplates?.map(mapReviewEmailTemplateDTO),
});

const getReviewTemplate = async (reviewTemplateId: number): Promise<ReviewTemplate> => {
    const { data } = await client.get<ReviewTemplateDTO>(API_BASE_URL + `/review-templates/${reviewTemplateId}`);
    return mapReviewTemplateDTO(data);
};

const updateReviewTemplate = async (reviewTemplateId: number, mutation: ReviewTemplateMutation): Promise<ReviewTemplate> => {
    const { data } = await client.put<ReviewTemplateDTO, AxiosResponse<ReviewTemplateDTO>, ReviewTemplateRequest>(
        API_BASE_URL + `/review-templates/${reviewTemplateId}`,
        mutation,
    );
    return mapReviewTemplateDTO(data);
};

const deleteReviewTemplate = async (reviewTemplateId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/review-templates/${reviewTemplateId}`);
};

export async function createReviewTemplate(request: ReviewTemplateMutation): Promise<ReviewTemplate> {
    const { data } = await client.post<ReviewTemplateDTO, AxiosResponse<ReviewTemplateDTO>, ReviewTemplateRequest>(API_BASE_URL + `/review-templates`, request);

    return mapReviewTemplateDTO(data);
}

const searchReviewTemplates = async (request: SearchReviewTemplate = {}): Promise<ReviewTemplate[]> => {
    const { data } = await client.post<ReviewTemplateDTO[], AxiosResponse<ReviewTemplateDTO[]>, SearchReviewTemplateRequest>(
        API_BASE_URL + `/review-templates/search`,
        request,
    );

    return data.map(dto => mapReviewTemplateDTO(dto));
};

const cloneReviewTemplate = async (reviewTemplateId: number): Promise<ReviewTemplate> => {
    const { data } = await client.post<ReviewTemplateDTO, AxiosResponse<ReviewTemplateDTO>>(API_BASE_URL + `/review-templates/${reviewTemplateId}/clone`);

    return mapReviewTemplateDTO(data);
};

export const reviewTemplateAPI = {
    getReviewTemplate,
    updateReviewTemplate,
    deleteReviewTemplate,
    createReviewTemplate,
    searchReviewTemplates,
    cloneReviewTemplate,
};
