import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const SurveyTokenError: FC<unknown> = () => {
    const { t } = useTranslation();

    return (
        <Stack component={Paper} padding={6} textAlign='center' maxWidth={450} margin='auto'>
            <Typography variant='body1'>{t('anonymous_surveys.this_page_does_not_exist')}</Typography>
        </Stack>
    );
};
