import { getApiInfo } from '@/domain/api-info/ApiInfo.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const apiInfoKeys = createQueryKeys('apiInfo', {
    get: {
        queryKey: getNull(),
    },
});

export const useGetApiInfo = createQueryHook(apiInfoKeys.get, getApiInfo);
