import { FC } from 'react';
import { useTheme } from '@mui/material';
import { getLabelTranslation } from '@/utils/language.util';
import { htmlToPlainText } from '@/utils/strings.util';
import { Text, View } from '@react-pdf/renderer';
import { Job } from '@/domain/job/Job.model';
import i18next from '@/i18n/i18n';

type FeedbackJobsPDFProps = {
    jobs: Job[];
};

export const FeedbackJobsPDF: FC<FeedbackJobsPDFProps> = ({ jobs }) => {
    const theme = useTheme();

    return (
        <View
            style={{
                display: 'flex',
                backgroundColor: theme.palette.grey[50],
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                gap: theme.spacing(1),
            }}
        >
            <Text
                style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                }}
            >
                {i18next.t('reviews.review_summary.review_jobs_title')}
            </Text>
            {jobs.map(job => (
                <View key={job.id} style={{ marginBottom: theme.spacing(2) }}>
                    <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{getLabelTranslation(job.name)}</Text>
                    {(!!job.jobFamily?.name || !!job.jobLevel?.name) && (
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: theme.spacing(0.5),
                                marginTop: theme.spacing(0.5),
                            }}
                        >
                            {job.jobFamily?.name && <Text style={{ fontSize: 10 }}>{job.jobFamily.name}</Text>}
                            {job.jobFamily?.name && job.jobLevel?.name && <Text style={{ fontSize: 10 }}> - </Text>}
                            {job.jobLevel?.name && (
                                <Text
                                    style={{
                                        fontSize: 10,
                                    }}
                                >
                                    {getLabelTranslation(job.jobLevel.name)}
                                </Text>
                            )}
                        </View>
                    )}
                    {job.purpose && (
                        <View style={{ marginTop: theme.spacing(1) }}>
                            <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Purpose</Text>
                            <Text style={{ fontSize: 10 }}>{getLabelTranslation(job.purpose)}</Text>
                        </View>
                    )}
                    {job.responsibility && (
                        <View style={{ marginTop: theme.spacing(1) }}>
                            <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Responsibilities</Text>
                            <Text style={{ fontSize: 10 }}>{htmlToPlainText(getLabelTranslation(job.responsibility))}</Text>
                        </View>
                    )}
                </View>
            ))}
        </View>
    );
};
