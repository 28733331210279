import { forwardRef, HTMLAttributes, Ref } from 'react';
import Box from '@mui/material/Box';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import {
    TreeItem2Checkbox,
    TreeItem2Content,
    TreeItem2GroupTransition,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';
import { Link } from '@mui/material';
import { CheckmarkCircle02Icon, CircleIcon } from 'hugeicons-react';
import { useTranslation } from 'react-i18next';

type TreeSelectOptionProps = Omit<UseTreeItem2Parameters, 'rootRef'> &
    Omit<HTMLAttributes<HTMLLIElement>, 'onFocus'> & { multiSelect?: boolean; selectionPropagation?: boolean; onSingleSelect?: (itemId: string) => void };

/**
 * Custom tree item for the TreeSelect component
 * With a button link "only" to select only the current item for multiSelect mode instead of all children
 */
export const TreeSelectOption = forwardRef(function TreeSelectOption(props: TreeSelectOptionProps, ref: Ref<HTMLLIElement>) {
    const { id, itemId, label, disabled, children, multiSelect, selectionPropagation, onSingleSelect, ...other } = props;

    const { t } = useTranslation();

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getCheckboxProps,
        getLabelProps,
        getGroupTransitionProps,
        getDragAndDropOverlayProps,
        status,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const simpleRadioSlotProps = {
        icon: <CircleIcon size={20} />,
        checkedIcon: <CheckmarkCircle02Icon size={20} />,
    }; // typing is not good from the library

    const contentProps = getContentProps();
    const isDisabled = contentProps.status.disabled;
    const showOnlySelectionBtn = multiSelect && selectionPropagation && !isDisabled;

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content
                    {...contentProps}
                    sx={{
                        ':hover :not(ul) .select-only': {
                            display: 'block',
                        },
                    }}
                >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
                        <TreeItem2Checkbox {...getCheckboxProps()} {...(!multiSelect ? simpleRadioSlotProps : {})} />
                        <TreeItem2Label {...getLabelProps()} />
                        {showOnlySelectionBtn && (
                            <Link
                                className={'select-only'}
                                component='button'
                                color='primary'
                                display={'none'}
                                onClick={e => {
                                    e.stopPropagation();
                                    onSingleSelect?.(itemId);
                                }}
                            >
                                {t('general.only')}
                            </Link>
                        )}
                    </Box>
                    <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
                </TreeItem2Content>
                {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});
