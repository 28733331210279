export const MAX_VALUE_EXPENSE_SETTING_PAYMENT_THRESHOLD = 999999;

export type ExpenseSetting = {
    id: number;
    automaticPaymentThreshold?: number;
    expenseTaxesEnabled: boolean;
    expenseCategoryParentEnabled: boolean;
};

export type ExpenseSettingUpdateMutation = StrictOmit<ExpenseSetting, 'id'>;
