import { getExpenseCategoryById, searchExpenseCategories } from '@/domain/expense-category/ExpenseCategory.service';
import { createQueryHook, defaultQueryOptions } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const expenseCategoryKeys = createQueryKeys('expenseCategories', {
    search: (...optionalParam: Parameters<typeof searchExpenseCategories>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    getById: (...params: Parameters<typeof getExpenseCategoryById>) => ({
        queryKey: params,
    }),
});

export const useGetExpenseCategoryById = createQueryHook(expenseCategoryKeys.getById, getExpenseCategoryById, defaultQueryOptions);
export const useGetExpenseCategories = createQueryHook(expenseCategoryKeys.search, searchExpenseCategories, defaultQueryOptions);
