import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { canSeeOtherEmployeeSkills } from '@/domain/permission/Permission.service';
import { Page } from '@/page/Pages';

export const getEmployeeSkillPages = (employeeId: number, policies: EmployeePolicy[]): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'sidebar.employee_skill',
            path: '/employee-skill/skills-matrix',
            condition: (policies: EmployeePolicy[]) => canSeeOtherEmployeeSkills(policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(policies));
};
