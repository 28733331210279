import { API_BASE_URL, client } from '@/api/common';

const CURRENCY_RATE_API_BASE_URL = API_BASE_URL + '/currency-rates';

const getCurrencyRate = async (params: { currency: string; date: LocalDate }): Promise<number> => {
    const { currency, date } = params;
    const url = `${CURRENCY_RATE_API_BASE_URL}?currency=${currency}&date=${date}`;
    const { data } = await client.get<number>(url);
    return data;
};

const getAvailableCurrencyCodes = async (): Promise<string[]> => {
    const { data } = await client.get<string[]>(CURRENCY_RATE_API_BASE_URL + '/available-currencies');
    return data;
};

export const currencyRateApi = {
    getAvailableCurrencyCodes,
    getCurrencyRate,
};
