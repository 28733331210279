import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { FC } from 'react';
import { FilePicker, FilePickerItem, FilePickerProps } from '@/components/file-picker/FilePicker';
import { Stack } from '@mui/system';

type FieldFilePickerRootProps = Pick<FilePickerProps, 'fetchDocumentUrl' | 'maxFiles' | 'accept' | 'disabled'>;
type StackProps = Pick<FilePickerProps, 'sx'>;

type FieldFilePickerProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    filePickerProps?: RemoveFromType<
        RemoveFromType<Omit<FilePickerProps, 'files' | 'onFileUploaded' | 'onFileRemoved' | 'onFileRenamed'>, FieldFilePickerRootProps & StackProps>,
        ControllerRenderProps
    >;
} & Omit<ControllerProps<TFieldValues, TName>, 'render'> &
    FieldFilePickerRootProps &
    StackProps;

export const FieldFilePicker: FC<FieldFilePickerProps> = props => {
    const { fetchDocumentUrl, maxFiles, disabled, accept, sx, filePickerProps, ...controllerProps } = props;

    const renameFile = (currentFiles: FilePickerItem[], fileToReplace: FilePickerItem): FilePickerItem[] => {
        return currentFiles.map(file => (file.id === fileToReplace.id ? fileToReplace : file));
    };

    const removeFile = (currentFiles: FilePickerItem[], fileToRemove: FilePickerItem): FilePickerItem[] => {
        return currentFiles.filter(file => file.id !== fileToRemove.id);
    };

    const addFiles = (currentFiles: FilePickerItem[], newFiles: FilePickerItem[]): FilePickerItem[] => {
        return [...currentFiles, ...newFiles];
    };

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState }) => (
                <Stack sx={() => ({ ...sx })}>
                    <FilePicker
                        {...filePickerProps}
                        fetchDocumentUrl={fetchDocumentUrl}
                        sx={sx}
                        disabled={disabled}
                        maxFiles={maxFiles}
                        accept={accept}
                        files={field.value ?? []}
                        onFileUploaded={files => {
                            const newFiles = addFiles(field.value ?? [], files);
                            field.onChange(newFiles);
                        }}
                        onFileRemoved={fileToRemove => {
                            const newFiles = removeFile(field.value ?? [], fileToRemove);
                            field.onChange(newFiles);
                        }}
                        onFileRenamed={fileToReplace => {
                            const newFiles = renameFile(field.value ?? [], fileToReplace);
                            field.onChange(newFiles);
                        }}
                    />
                    {!!fieldState.error?.message && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                </Stack>
            )}
        />
    );
};
