import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { Button, Paper, Stack } from '@mui/material';
import { Add01Icon } from 'hugeicons-react';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { useGetExpenseCurrencies } from '@/hooks/expense-currency/ExpenseCurrency.hook';
import { createExpenseCurrency, deleteExpenseCurrency, updateExpenseCurrency } from '@/domain/expense-currency/ExpenseCurrency.service';
import { ExpenseCurrency } from '@/domain/expense-currency/ExpenseCurrency.model';
import { ExpenseCurrencyFormValues, ExpenseCurrencySettingsDialog } from '@/page/setting/expense/expense-currency/ExpenseCurrencySettingsDialog';
import { ExpenseCurrencySettingsGrid } from '@/page/setting/expense/expense-currency/ExpenseCurrencySettingsGrid';
import { useDialogState } from '@/hooks/useDialog.hook';

export const ExpenseCurrencySettingsPage: FC = () => {
    const { t } = useTranslation();

    const { data: currencies = [], refetch: refetchCurrencies, isLoading: isCurrenciesLoading } = useGetExpenseCurrencies();
    const { setGridRef, quickFilter } = useAgGridWrapper<ExpenseCurrency>();

    const { state: expenseCurrencyDialogState, open: openExpenseCurrencyDialog, close: closeExpenseCurrencyDialog } = useDialogState<ExpenseCurrency>();

    const onDeleteCurrency = async (currency: ExpenseCurrency) => {
        try {
            await deleteExpenseCurrency(currency.id);
            showSnackbar(t('expense_currencies_settings_page.currency_deleted_succeed'), 'success');
            await refetchCurrencies();
        } catch (error) {
            handleError(error);
        }
    };

    const onSaveCurrency = async (data: ExpenseCurrencyFormValues) => {
        try {
            if (expenseCurrencyDialogState?.data?.id) {
                await updateExpenseCurrency(expenseCurrencyDialogState.data.id, data);
            } else {
                await createExpenseCurrency(data);
            }
            showSnackbar(t('expense_currencies_settings_page.currency_saved_succeed'), 'success');
            closeExpenseCurrencyDialog();
            await refetchCurrencies();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center' component={Paper} p={1}>
                <Stack direction='row' gap={1}>
                    <DatatableAdditionalAction quickFilter={quickFilter} />
                    <Button variant='contained' onClick={() => openExpenseCurrencyDialog()} startIcon={<Add01Icon />}>
                        {t('expense_currencies_settings_page.add_currency')}
                    </Button>
                </Stack>
            </Stack>
            <ExpenseCurrencySettingsGrid
                currencies={currencies}
                loading={isCurrenciesLoading}
                onRowClick={openExpenseCurrencyDialog}
                onDeleteCurrency={onDeleteCurrency}
                initRef={setGridRef}
            />
            {expenseCurrencyDialogState?.open && (
                <ExpenseCurrencySettingsDialog
                    open={expenseCurrencyDialogState.open}
                    currency={expenseCurrencyDialogState.data}
                    onClose={closeExpenseCurrencyDialog}
                    onSave={onSaveCurrency}
                />
            )}
        </Stack>
    );
};
