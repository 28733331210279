import {
    CreateExpenseCategoryMutation,
    ExpenseCategory,
    ExpenseCategorySearch,
    UpdateExpenseCategoryMutation,
} from '@/domain/expense-category/ExpenseCategory.model';
import { expenseCategoryApi } from '@/api/expense-category/ExpenseCategory.api';
import { getLabelTranslation } from '@/utils/language.util';

export const searchExpenseCategories = (searchRequest: ExpenseCategorySearch = {}): Promise<ExpenseCategory[]> => {
    return expenseCategoryApi.searchExpenseCategories(searchRequest);
};

export const getExpenseCategoryById = (expenseCategoryId: number): Promise<ExpenseCategory> => {
    return expenseCategoryApi.getExpenseCategoryById(expenseCategoryId);
};

export const createExpenseCategory = (mutation: CreateExpenseCategoryMutation): Promise<ExpenseCategory> => {
    return expenseCategoryApi.createExpenseCategory(mutation);
};

export const updateExpenseCategory = (expenseCategoryId: number, mutation: UpdateExpenseCategoryMutation): Promise<ExpenseCategory> => {
    return expenseCategoryApi.updateExpenseCategory(expenseCategoryId, mutation);
};

export const deleteExpenseCategory = (expenseCategoryId: number): Promise<void> => {
    return expenseCategoryApi.deleteExpenseCategory(expenseCategoryId);
};

export const archiveExpenseCategory = (expenseCategoryId: number): Promise<ExpenseCategory> => {
    return expenseCategoryApi.archiveExpenseCategory(expenseCategoryId);
};

export const unarchiveExpenseCategory = (expenseCategoryId: number): Promise<ExpenseCategory> => {
    return expenseCategoryApi.unarchiveExpenseCategory(expenseCategoryId);
};

export const isAmountType = (category: ExpenseCategory | undefined): boolean => category?.type === 'AMOUNT';

export const isDistanceType = (category: ExpenseCategory | undefined): boolean => category?.type === 'DISTANCE';

export const getFullCategoryName = (category: ExpenseCategory): string => {
    const groupName = getLabelTranslation(category.categoryGroup?.name);
    const categoryName = getLabelTranslation(category.name);
    // filter out group name if it's empty and join with dot
    return [groupName, categoryName].filter(Boolean).join(' • ');
};
