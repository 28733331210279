import { expenseTaxApi } from '@/api/expense-tax/ExpenseTaxApi';
import { CreateExpenseTaxMutation, EditExpenseTaxMutation, ExpenseTax, ExpenseTaxSearch } from './ExpenseTax.model';

export const searchExpenseTaxes = (searchRequest: ExpenseTaxSearch = {}): Promise<ExpenseTax[]> => {
    return expenseTaxApi.searchExpenseTaxes(searchRequest);
};

export const createExpenseTax = (mutation: CreateExpenseTaxMutation): Promise<ExpenseTax> => {
    return expenseTaxApi.createExpenseTax(mutation);
};

export const updateExpenseTax = (expenseTaxId: number, mutation: EditExpenseTaxMutation): Promise<ExpenseTax> => {
    return expenseTaxApi.updateExpenseTax(expenseTaxId, mutation);
};

export const deleteExpenseTax = (expenseTaxId: number): Promise<void> => {
    return expenseTaxApi.deleteExpenseTax(expenseTaxId);
};

export const archiveExpenseTax = (expenseTaxId: number): Promise<ExpenseTax> => {
    return expenseTaxApi.archiveExpenseTax(expenseTaxId);
};

export const unarchiveExpenseTax = (expenseTaxId: number): Promise<ExpenseTax> => {
    return expenseTaxApi.unarchiveExpenseTax(expenseTaxId);
};
