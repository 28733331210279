import { getTimesheetSettingById, searchTimesheetSettings } from '@/domain/timesheet-setting/TimesheetSetting.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const timesheetSettingKeys = createQueryKeys('timesheetSetting', {
    search: {
        queryKey: getNull(),
    },
    getById: (...params: Parameters<typeof getTimesheetSettingById>) => ({
        queryKey: params,
    }),
});

export const useGetTimesheetSetting = createRequiredParamsQueryHook(timesheetSettingKeys.getById, getTimesheetSettingById);

export const useGetTimesheetSettings = createQueryHook(timesheetSettingKeys.search, searchTimesheetSettings);
