import { getObjective, searchObjectives } from '@/domain/objective/Objective.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const objectiveKeys = createQueryKeys('objectives', {
    search: (...params: Parameters<typeof searchObjectives>) => ({
        queryKey: params,
    }),
    getById: (...params: Parameters<typeof getObjective>) => ({
        queryKey: params,
    }),
});

export const useGetObjectives = createQueryHook(objectiveKeys.search, searchObjectives);

export const useGetObjective = createRequiredParamsQueryHook(objectiveKeys.getById, getObjective);
