import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLeaveRequestPeriodAsString } from '@/domain/leave-request/LeaveRequest.service';
import { getLabelTranslation } from '@/utils/language.util';
import { EmployeeAvatarWithDetails } from '@/domain-ui/employee/employee-avatar/EmployeeAvatarWithDetails';
import { EmployeeAvatar } from '@/domain-ui/employee/employee-avatar/EmployeeAvatar';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { desktopVisible, mobileVisible } from '@/theme/responsive';
import { getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';

type Props = {
    request: LeaveRequest;
};

export const AbsentEmployeesTabPanel: FC<Props> = ({ request }) => {
    const [selectedTab, setSelectedTab] = useState<'approved' | 'pending'>('approved');
    const [data, setData] = useState<LeaveRequest[]>(request.approvedOverlappingLeaveRequests ?? []);
    const { t } = useTranslation();

    useEffect(() => {
        setData((selectedTab === 'approved' ? request.approvedOverlappingLeaveRequests : request.pendingOverlappingLeaveRequests) ?? []);
    }, [request, selectedTab]);

    const employment = getCurrentPrincipalEmployment(request.employee);

    const renderLeaveRequestTooltip = (request: LeaveRequest): ReactElement => {
        return (
            <Stack direction='column' alignItems='center'>
                <Typography color={'white'} variant='body2bold'>
                    {getLabelTranslation(request.leaveType.name)}
                </Typography>
                <Typography color={'white'} variant='body2bold'>
                    {getLeaveRequestPeriodAsString(request)}
                </Typography>
                <Typography color={'white'} variant='body2bold'>
                    {getLabelTranslation(employment?.job?.name)}
                </Typography>
            </Stack>
        );
    };

    if (!request.approvedOverlappingLeaveRequests?.length && !request.pendingOverlappingLeaveRequests?.length) {
        return <></>;
    }

    return (
        <Stack direction='column' gap={1}>
            <Stack>
                <Typography variant='body2'>{t('leaves_page.whos_out')}</Typography>
                <Stack direction={'row'} gap={1}>
                    <Chip
                        color={selectedTab === 'approved' ? 'primary' : 'default'}
                        label={`${t('domain.leave_request.approved')} (${request.approvedOverlappingLeaveRequests?.length})`}
                        onClick={() => {
                            setSelectedTab('approved');
                        }}
                    />
                    <Chip
                        color={selectedTab === 'pending' ? 'primary' : 'default'}
                        label={`${t('domain.leave_request.pending')} (${request.pendingOverlappingLeaveRequests?.length})`}
                        onClick={() => {
                            setSelectedTab('pending');
                        }}
                    />
                </Stack>
            </Stack>
            <Stack>
                <Stack sx={desktopVisible} direction='row' gap={1}>
                    {data.map(lr => {
                        return (
                            <Tooltip
                                key={lr.id}
                                title={renderLeaveRequestTooltip(lr)}
                                PopperProps={{
                                    placement: 'top',
                                }}
                            >
                                <Stack gap={0.5} alignItems='center' direction={'row'}>
                                    <EmployeeAvatar employeeAvatar={lr.employee} disableTooltip />
                                    <Typography variant='body1'>{lr.employee?.displayName}</Typography>
                                </Stack>
                            </Tooltip>
                        );
                    })}
                </Stack>
                <Stack direction='row' sx={mobileVisible}>
                    {data.map(lr => (
                        <EmployeeAvatarWithDetails key={lr?.id} employee={lr?.employee}>
                            <Typography> {getLeaveRequestPeriodAsString(lr)}</Typography>
                        </EmployeeAvatarWithDetails>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
};
