import { Paper, Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

export const ReviewSettingsTabs: FC = () => {
    const navigate = useNavigate();

    const params = useParams();
    const { t } = useTranslation();

    const activeTab = params.tab ?? 'templates';

    const reviewSettingsTabs = [
        {
            label: t('reviews_settings_page.review_templates'),
            value: 'templates',
        },
        {
            label: t('company_settings.tabs.skills'),
            value: 'skills',
        },
        {
            label: t('reviews_settings_page.categories'),
            value: 'categories',
        },
        {
            label: t('reviews_settings_page.rating_scales'),
            value: 'scales',
        },
    ];

    return (
        <Paper
            sx={{
                mb: 0,
                borderRadius: 1,
            }}
        >
            <Tabs
                value={activeTab}
                variant='scrollable'
                scrollButtons={false}
                allowScrollButtonsMobile
                onChange={(_, newValue) => navigate(`/settings/reviews/${newValue}`)}
            >
                {reviewSettingsTabs.map(item => (
                    <Tab key={item.value} value={item.value} label={item.label} />
                ))}
            </Tabs>
        </Paper>
    );
};
