import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridWrapper, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { ExpenseCurrency } from '@/domain/expense-currency/ExpenseCurrency.model';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import cc from 'currency-codes';
import { ValueFormatterParams } from 'ag-grid-enterprise';

type ExpenseCurrencyGridProps = {
    currencies: ExpenseCurrency[];
    onRowClick: (currency: ExpenseCurrency) => void;
    onDeleteCurrency: (currency: ExpenseCurrency) => Promise<void>;
} & AgGridWrapperProps<ExpenseCurrency>;

export const ExpenseCurrencySettingsGrid: FC<ExpenseCurrencyGridProps> = ({ currencies, onRowClick, onDeleteCurrency, ...restGridProps }) => {
    const { t } = useTranslation();

    const actionMenuRenderer = ({ data }: ICellRendererParams<ExpenseCurrency>) => {
        if (!data) {
            return <></>;
        }

        return <ActionMenuRenderer currency={data} onEdit={onRowClick} onDelete={currency => onDeleteCurrency(currency).catch(console.error)} />;
    };

    const handleRowClick = (params: RowClickedEvent<ExpenseCurrency>) => {
        if (params.event?.defaultPrevented || !params.data) {
            return;
        }
        onRowClick(params.data);
    };

    const formatCurrencyLabel = (params: ValueFormatterParams<ExpenseCurrency>) => {
        const code = params.value;
        const currencyDetails = cc.code(code);
        const fullName = currencyDetails ? currencyDetails.currency : '';
        const label = `${code} - ${fullName}`;
        const isDefault = !!params.data?.defaultCurrency;
        return isDefault ? `${label} (${t('general.default')})` : label;
    };

    const columns: ColDef<ExpenseCurrency>[] = [
        {
            field: 'code',
            headerName: t('expense_currencies_settings_page.currency_code_header'),
            cellClass: ['display-flex'],
            width: 300,
            valueFormatter: params => formatCurrencyLabel(params),
        },
        {
            type: 'actionMenu',
            cellRenderer: actionMenuRenderer,
        },
    ];

    return <AgGridWrapper rowData={currencies} columnDefs={columns} onRowClicked={handleRowClick} {...restGridProps} />;
};

type ActionMenuRendererProps = {
    currency: ExpenseCurrency;
    onEdit: (currency: ExpenseCurrency) => void;
    onDelete: (currency: ExpenseCurrency) => void;
};

const ActionMenuRenderer: FC<ActionMenuRendererProps> = ({ currency, onEdit, onDelete }) => {
    const { t } = useTranslation();

    return (
        <BasicMenu
            items={[
                {
                    title: t('general.edit'),
                    onClick: () => onEdit(currency),
                },
                {
                    hide: currency.defaultCurrency,
                    title: t('general.delete'),
                    onClick: () => onDelete(currency),
                },
            ]}
        />
    );
};
