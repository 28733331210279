import { getThirdPartyMappingValues, saveThirdPartyMappingValues, searchThirdPartyMappings } from '@/domain/third-party-mapping/ThirdPartyMapping.service.';
import { createMutationHook, createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const thirdPartyMappingKeys = createQueryKeys('thirdPartyMapping', {
    search: {
        queryKey: getNull(),
    },
    thirdPartyMappingValues: (thirdPartyMappingId: number) => ({
        queryKey: [thirdPartyMappingId],
    }),
});

export const useGetThirdPartyMappings = createQueryHook(thirdPartyMappingKeys.search, searchThirdPartyMappings);

export const useGetThirdPartyMappingValues = createRequiredParamsQueryHook(thirdPartyMappingKeys.thirdPartyMappingValues, getThirdPartyMappingValues);

export const useEditThirdPartyMappingValuesMutation = createMutationHook(saveThirdPartyMappingValues, {
    onSuccess: ({ variables: { thirdPartyMappingId }, queryClient }) => {
        return queryClient.invalidateQueries(thirdPartyMappingKeys.thirdPartyMappingValues(thirdPartyMappingId));
    },
});
