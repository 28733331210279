import { API_BASE_URL, client } from '@/api/common';
import {
    LeaveRequestAttachment,
    LeaveRequestAttachmentCreationRequest,
    SearchLeaveRequestAttachment,
} from '@/domain/leave-request-attachment/LeaveRequestAttachment.model';
import { AxiosResponse } from 'axios';
import { CONTENT_DISPOSITION } from '@/domain/common';

const createLeaveRequestAttachment = async (request: LeaveRequestAttachmentCreationRequest): Promise<LeaveRequestAttachment> => {
    const url = API_BASE_URL + `/leave-request-attachments`;

    const formData = new FormData();
    formData.append('file', request.file.data, request.file.name);
    formData.append('name', request.name);
    formData.append('mimeType', request.mimeType);
    formData.append('leaveRequestId', request.leaveRequestId.toString());
    return (await client.postForm<LeaveRequestAttachmentCreationRequestDTO, AxiosResponse<LeaveRequestAttachment>, FormData>(url, formData)).data;
};

const searchLeaveRequestAttachments = async (request: SearchLeaveRequestAttachment): Promise<LeaveRequestAttachment[]> => {
    return (
        await client.post<LeaveRequestAttachmentDTO[], AxiosResponse<LeaveRequestAttachmentDTO[]>, SearchLeaveRequestAttachmentDTO>(
            API_BASE_URL + `/leave-request-attachments/search`,
            request,
        )
    ).data;
};

const deleteLeaveRequestAttachment = async (leaveRequestAttachmentId: number): Promise<void> => {
    const url = API_BASE_URL + `/leave-request-attachments/${leaveRequestAttachmentId}`;
    await client.delete(url);
};

const getLeaveRequestAttachmentUrl = async (leaveRequestAttachmentId: number, ContentDisposition: CONTENT_DISPOSITION): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/leave-request-attachments/${leaveRequestAttachmentId}/url/${ContentDisposition}`)).data;
};

export const leaveRequestAttachmentApi = {
    createLeaveRequestAttachment,
    searchLeaveRequestAttachments,
    deleteLeaveRequestAttachment,
    getLeaveRequestAttachmentUrl,
};

type LeaveRequestAttachmentDTO = LeaveRequestAttachment;
type SearchLeaveRequestAttachmentDTO = SearchLeaveRequestAttachment;
type LeaveRequestAttachmentCreationRequestDTO = LeaveRequestAttachmentCreationRequest;
