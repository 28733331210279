import { canContributeEmployeeReview, canManageReview } from '@/domain/permission/Permission.service';
import { ManageCyclePage } from '@/page/review/employee-review-cycle-page/ManageCyclePage';
import { FeedbackPage } from '@/page/review/employee-review-feedback-form/FeedbackPage';
import { ManagerSummaryFeedbackPage } from '@/page/review/employee-review-summary-feedback-form/ManagerSummaryFeedbackPage';
import { FeedbackSummaryPage } from '@/page/review/employee-review-summary-feedback-form/FeedbackSummaryPage';
import { EmployeeReviewOneShotPage } from '@/page/review/employee-reviews-management/EmployeeReviewOneShotPage';
import { EmployeeReviewMeetingPage } from '@/page/review/employee-reviews-management/EmployeeReviewMeetingPage';
import { EmployeeReviewOffboardingPage } from '@/page/review/employee-reviews-management/EmployeeReviewOffboardingPage';
import { EmployeeReviewOnboardingPage } from '@/page/review/employee-reviews-management/EmployeeReviewOnboardingPage';
import { AddReviewersPreviewFormPage } from '@/page/review/review-create-cycle-workflow/AddReviewersPreviewFormPage';
import { InvitationsFormPage } from '@/page/review/review-create-cycle-workflow/InvitationsFormPage';
import { ReviewSetupFormPage } from '@/page/review/review-create-cycle-workflow/ReviewSetupFormPage';
import { ReviewCycleReportPage } from '@/page/review/review-cycle-report-page/ReviewCycleReportPage';
import { ReviewTasksPage } from '@/page/review/review-tasks/ReviewTasksPage';
import { ManageReviewsCyclePage } from '@/page/review/reviews-management/ManageReviewsCyclePage';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { useCurrentPolicies } from '@/stores/store';
import { FC } from 'react';
import { Navigate, RouteObject } from 'react-router';

const config: RoutesConfig[] = [
    {
        path: 'tasks',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ReviewTasksPage />,
    },
    {
        path: 'manage-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ManageReviewsCyclePage />,
    },
    {
        path: 'team-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewMeetingPage />,
    },
    {
        path: 'onboarding-employee-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewOnboardingPage />,
    },
    {
        path: 'offboarding-employee-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewOffboardingPage />,
    },
    {
        path: 'one-shot-employee-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewOneShotPage />,
    },
];

const subRoutes: RoutesConfig[] = [
    {
        path: 'manage-reviews/:reviewId/setup',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ReviewSetupFormPage />,
    },
    {
        path: 'manage-reviews/:reviewId/reviewers',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <AddReviewersPreviewFormPage />,
    },
    {
        path: 'manage-reviews/:reviewId/invitations',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <InvitationsFormPage />,
    },
    {
        path: 'manage-reviews/:reviewId',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ManageCyclePage />,
    },
    {
        path: 'manage-reviews/:reviewId/report',
        breadCrumb: [{ nameKey: 'pages.reviews_cycle_report' }],
        component: <ReviewCycleReportPage />,
    },
    {
        path: ':employeeReviewId/manager-summary',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ManagerSummaryFeedbackPage />,
    },
    {
        path: ':employeeReviewId/summary',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <FeedbackSummaryPage />,
    },
    {
        path: ':employeeReviewId/feedbacks',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <FeedbackPage isPreview={false} />,
    },
    {
        path: ':employeeReviewId/feedbacks/preview',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <FeedbackPage isPreview={true} />,
    },
];

export const Fallback: FC = () => {
    const policies = useCurrentPolicies();

    const getDefaultUrl = (): string => {
        if (canContributeEmployeeReview(policies)) {
            return '/reviews/tasks';
        }
        if (canManageReview(policies)) {
            return '/reviews/manage-reviews';
        }
        return '';
    };

    return <Navigate to={getDefaultUrl()} replace />;
};
// eslint-disable-next-line react-refresh/only-export-components
export const reviewRoutes: RouteObject[] = [
    {
        path: 'reviews',
        children: [
            {
                path: '',
                errorElement: <RouterBoundary />,
                children: [
                    {
                        path: '',
                        element: <Fallback />,
                    },
                    ...config.map(config => ({
                        path: config.path,
                        element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                        errorElement: <RouterBoundary />,
                    })),
                ],
            },
            ...subRoutes.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
