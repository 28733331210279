import { timesheetInsightApi } from '@/api/timesheet-insight/TimesheetInsight.api';
import {
    DailyWorkingTimeBalanceSearchRequest,
    EmployeeDailyWorkingTimeBalance,
    EmployeeWeeklyWorkingTimeBalance,
    EmployeeWorkingTimeBalance,
    EmployeeWorkingTimeWarning,
    WeeklyWorkingTimeBalanceSearchRequest,
    WorkingTimeBalanceSearchRequest,
    WorkingTimeWarningSearchRequest,
} from '@/domain/timesheet-insight/TimesheetInsight.model';

export const getEmployeeWorkingTimeWarnings = async (
    search: WorkingTimeWarningSearchRequest,
    options?: RequestOptions,
): Promise<EmployeeWorkingTimeWarning[]> => {
    return timesheetInsightApi.getEmployeeWorkingTimeWarnings(search, options);
};

export const getEmployeeWorkingTimeBalances = async (
    search: WorkingTimeBalanceSearchRequest,
    options?: RequestOptions,
): Promise<EmployeeWorkingTimeBalance[]> => {
    return timesheetInsightApi.getEmployeeWorkingTimeBalances(search, options);
};

export const getEmployeeDailyWorkingTimeBalances = async (
    search: DailyWorkingTimeBalanceSearchRequest,
    options?: RequestOptions,
): Promise<EmployeeDailyWorkingTimeBalance[]> => {
    return timesheetInsightApi.getEmployeeDailyWorkingTimeBalances(search, options);
};

export const getEmployeeWeeklyWorkingTimeBalances = async (
    search: WeeklyWorkingTimeBalanceSearchRequest,
    options?: RequestOptions,
): Promise<EmployeeWeeklyWorkingTimeBalance[]> => {
    return timesheetInsightApi.getEmployeeWeeklyWorkingTimeBalances(search, options);
};
