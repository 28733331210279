import { Objective, ObjectiveCreateMutation, ObjectiveUpdateMutation } from '@/domain/objective/Objective.model';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';

export type EmployeeReviewAction = {
    id: number;
    employeeReviewId: number;
    reviewerId: number;
    objective: Objective;
    status: EmployeeReviewActionStatus;
};

export const EMPLOYEE_REVIEW_ACTION_STATUS_ARRAY = ['DRAFT', 'ARCHIVED', 'VALIDATED'] as const;

export const CAN_CREATE_ACTIONS_EMPLOYEE_REVIEW_STATUS_ARRAY = [
    EmployeeReviewStatus.INPUT_NEEDED,
    EmployeeReviewStatus.DISCUSSION_STARTED,
    EmployeeReviewStatus.DISCUSSION_SUBMITTED,
];

export type EmployeeReviewActionStatus = (typeof EMPLOYEE_REVIEW_ACTION_STATUS_ARRAY)[number];

export type UpdateActionMutation = {
    objectiveUpdateMutation: ObjectiveUpdateMutation;
};

export type CreateActionMutation = {
    objectiveCreateMutation: ObjectiveCreateMutation;
    employeeReviewIds: number[];
};
