import { authApi } from '@/api/auth/AuthApi';
import { LogoRoger } from '@/components/logo-roger/LogoRoger';
import { useBreakPoints } from '@/components/use-break-points/useBreakPoints';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, FormHelperText, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router';
import * as yup from 'yup';

export const ForgotPasswordPage: FC = () => {
    const { t } = useTranslation();
    const realm = useAppSelector(state => state.ui.currentRealm);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const emailMessage = t('general.forms.invalid_email');

    const schema = yup.object().shape({
        email: yup.string().email(emailMessage).required(),
    });
    const { isMobile } = useBreakPoints();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onResetPasswordClicked = (tenantId: string, email: string) => {
        setIsLoading(true);
        authApi
            .resetPassword(tenantId, email)
            .then(() => {
                setShowConfirmation(true);
            })
            .catch(handleError)
            .finally(() => setIsLoading(false));
    };

    const renderForm = () => {
        return (
            realm?.tenantId && (
                <Stack spacing={2} alignItems='stretch'>
                    <Stack>
                        <Typography variant='body2bold'>{t('forgot_password_form.title')}</Typography>
                        <Typography variant='body2'>{t('forgot_password_form.description')}</Typography>
                    </Stack>
                    <FormControl fullWidth={true} variant='outlined' size='small'>
                        <Typography variant='body2'>{t('login_form.email')}</Typography>
                        <TextField
                            {...register('email')}
                            disabled={isLoading}
                            id='input-email'
                            type='text'
                            placeholder={t('login_form.email')}
                            error={!!errors.email}
                        />
                        {!!errors.email && <FormHelperText error>{errors?.email?.message}</FormHelperText>}
                    </FormControl>
                    <Button
                        fullWidth={true}
                        color='primary'
                        variant='contained'
                        // TODO: to be nicer, show also a spinner
                        disabled={isLoading}
                        onClick={handleSubmit(({ email }) => onResetPasswordClicked(realm?.tenantId, email), console.error)}
                    >
                        {t('forgot_password_form.button_label')}
                    </Button>
                </Stack>
            )
        );
    };

    const renderConfirmation = () => {
        return (
            <Stack>
                <Typography variant='body2bold'>{t('forgot_password_form.confirmation.title')}</Typography>
                <Typography variant='body2'>{t('forgot_password_form.confirmation.instruction')}</Typography>
            </Stack>
        );
    };

    return (
        <Stack
            direction='column'
            gap={3}
            component={Paper}
            width={isMobile ? '80vw' : 440}
            p={isMobile ? 3 : 5}
            left='50%'
            top='50%'
            position='absolute'
            sx={{
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
                <Stack direction='row' alignContent='center'>
                    <LogoRoger variant='light' />
                </Stack>
                <Typography variant='body2'>
                    <Link to='/' component={RouterLink} color='primary'>
                        {t('forgot_password_form.back_action')}
                    </Link>
                </Typography>
            </Stack>
            {!showConfirmation && renderForm()}
            {showConfirmation && renderConfirmation()}
        </Stack>
    );
};
