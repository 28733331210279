import { getSurveyTemplates } from '@/api/survey-template/SurveyTemplate.api';
import { createQueryHook } from '@/page/Query.type';
import { getNull } from '@/utils/object.util';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const surveyTemplateKeys = createQueryKeys('surveyTemplates', {
    getAll: {
        queryKey: getNull(),
    },
});

export const useGetSurveyTemplates = createQueryHook(surveyTemplateKeys.getAll, getSurveyTemplates);
