import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { Button, Paper, Stack } from '@mui/material';
import { Add01Icon } from 'hugeicons-react';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { useGetExpenseDistanceRates } from '@/hooks/expense-distance-rate/ExpenseDistanceRate.hook';
import { createExpenseDistanceRate, deleteExpenseDistanceRate, updateExpenseDistanceRate } from '@/domain/expense-distance-rate/ExpenseDistanceRate.service';
import { ExpenseDistanceRate } from '@/domain/expense-distance-rate/ExpenseDistanceRate.model';
import { ExpenseDistanceRateDialog, ExpenseDistanceRateFormValues } from '@/page/setting/expense/expense-distance-rate/ExpenseDistanceRateSettingsDialog';
import { ExpenseDistanceRateSettingsGrid } from '@/page/setting/expense/expense-distance-rate/ExpenseDistanceRateSettingsGrid';

export const ExpenseDistanceRateSettingsPage: FC = () => {
    const { t } = useTranslation();
    const { data: distanceRates = [], refetch: refetchDistanceRates, isLoading: isDistanceRatesLoading } = useGetExpenseDistanceRates();
    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<ExpenseDistanceRate>();

    const [distanceRateDialogData, setDistanceRateDialogData] = useState<
        | {
              distanceRate: ExpenseDistanceRate | undefined;
              open: boolean;
          }
        | undefined
    >(undefined);

    const onBtnExport = () => {
        gridRef.current?.api?.exportDataAsExcel();
    };

    const onClickDistanceRateRow = (distanceRate: ExpenseDistanceRate) => {
        setDistanceRateDialogData({
            distanceRate,
            open: true,
        });
    };

    const onDeleteDistanceRate = async (distanceRate: ExpenseDistanceRate) => {
        try {
            await deleteExpenseDistanceRate(distanceRate.id);
            showSnackbar(t('expense_distance_rate_settings_page.distance_rate_deleted_succeed'), 'success');
            await refetchDistanceRates();
        } catch (error) {
            handleError(error);
        }
    };

    const onSaveDistanceRate = async (data: ExpenseDistanceRateFormValues) => {
        try {
            if (distanceRateDialogData?.distanceRate?.id) {
                await updateExpenseDistanceRate(distanceRateDialogData.distanceRate.id, data);
            } else {
                await createExpenseDistanceRate(data);
            }
            showSnackbar(t('expense_distance_rate_settings_page.distance_rate_saved_succeed'), 'success');
            setDistanceRateDialogData(undefined);
            await refetchDistanceRates();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center' component={Paper} p={1}>
                <Stack direction='row' gap={1}>
                    <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} />
                    <Button variant='contained' onClick={() => setDistanceRateDialogData({ open: true, distanceRate: undefined })} startIcon={<Add01Icon />}>
                        {t('expense_distance_rate_settings_page.distance_rate')}
                    </Button>
                </Stack>
            </Stack>
            <ExpenseDistanceRateSettingsGrid
                distanceRates={distanceRates}
                loading={isDistanceRatesLoading}
                onRowClick={onClickDistanceRateRow}
                onDeleteDistanceRate={onDeleteDistanceRate}
                initRef={setGridRef}
            />
            {distanceRateDialogData?.open && (
                <ExpenseDistanceRateDialog
                    open={distanceRateDialogData?.open}
                    distanceRate={distanceRateDialogData?.distanceRate}
                    onClose={() => setDistanceRateDialogData(undefined)}
                    onSave={onSaveDistanceRate}
                />
            )}
        </Stack>
    );
};
