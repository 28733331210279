import { Paper, Radio, Stack, StackProps, TextField, Typography } from '@mui/material';
import useDebounce from '@/hooks/Debounce.hook';
import { FC, Ref } from 'react';
import { FieldError } from 'react-hook-form';
import { RadioLineGroup, TooltipFormControlLabel } from '@/components/radio-line-group/RadioLineGroup';
import { FeedbackQuestionFormType, FeedbackSkillQuestionFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { getLabelTranslation } from '@/utils/language.util';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';

type FeedbackQuestionProps<T extends FeedbackQuestionFormType | FeedbackSkillQuestionFormType> = {
    questionItem: T;
    onUpdate: (item: T) => void;
    innerRef: Ref<HTMLDivElement>;
    disabled?: boolean;
    error: {
        comment?: FieldError;
        score?: FieldError;
    };
    isSummary: boolean;
} & StackProps;

export const FeedbackQuestion: FC<FeedbackQuestionProps<FeedbackQuestionFormType | FeedbackSkillQuestionFormType>> = ({
    innerRef,
    questionItem,
    onUpdate,
    disabled = false,
    error,
    isSummary,
    ...rest
}) => {
    const debounce = useDebounce();
    const handleScoreChange = (score: number) => {
        onUpdate({ ...questionItem, score });
    };
    const handleCommentChange = (comment: string) => {
        debounce(() => {
            onUpdate({ ...questionItem, comment });
        });
    };

    const PDF_DISPLAY_LENGTH_LIMIT = 7500;

    const ratingItemsOrderByScore = (questionItem.rating?.items ?? []).sort((a, b) => (a.score && b.score ? a.score - b.score : 0));
    return (
        <Stack component={Paper} ref={innerRef} gap={1} {...rest}>
            {!isSummary && (
                <Typography variant='h2' sx={{ whiteSpace: 'pre-wrap' }}>
                    {/*string for skill question, label for question*/}
                    {typeof questionItem.title === 'string' ? questionItem.title : getLabelTranslation(questionItem.title)} {questionItem.required && '*'}
                </Typography>
            )}

            {questionItem.description && !isSummary && <RichTextTypography variant='body2'>{questionItem.description} </RichTextTypography>}

            {questionItem.instruction && !isSummary && (
                <RichTextTypography variant='body2'>
                    {typeof questionItem.instruction === 'string' ? questionItem.instruction : getLabelTranslation(questionItem.instruction)}
                </RichTextTypography>
            )}

            {questionItem.rating && (
                <RadioLineGroup
                    error={!!error?.score}
                    defaultValue={questionItem.score}
                    onChange={e => handleScoreChange(Number(e.target.value))}
                    data-testid={'question_' + questionItem.id + '_rating'}
                >
                    {ratingItemsOrderByScore.map(item => (
                        <TooltipFormControlLabel
                            key={(item.label ?? '') + item.score || '' + (item?.description ?? '')}
                            value={item.score}
                            control={<Radio sx={{ color: error?.score ? 'error.main' : 'text.primary' }} />}
                            disabled={disabled}
                            label={<Typography className={item.description ? 'rp-underline' : ''}>{item.label}</Typography>}
                            description={item.description}
                        />
                    ))}
                </RadioLineGroup>
            )}
            <TextField
                fullWidth
                disabled={disabled}
                defaultValue={questionItem?.comment ?? ''}
                onChange={e => handleCommentChange(e.target.value)}
                error={!!error?.comment}
                helperText={error?.comment?.message}
                slotProps={{
                    input: { multiline: true, minRows: 2 },
                    htmlInput: { maxLength: PDF_DISPLAY_LENGTH_LIMIT },
                }}
            />
        </Stack>
    );
};
