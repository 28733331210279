import { DeleteConfirmDialog } from '@/components/delete-confirmation-dialog/DeleteConfirmDialog';
import { useReorderOnDrop } from '@/components/dnd/useReorderOnDrop';
import { CustomList, CustomListCreateMutation, CustomListUpdateMutation } from '@/domain/custom-list/CustomList.model';
import { createCustomList, updateCustomList } from '@/domain/custom-list/CustomList.service';
import {
    SectionDefinition,
    SectionDefinitionUpdateRequest,
    SectionFieldDefinition,
    SectionFieldDefinitionRequest,
} from '@/domain/section-setting/Section.model';
import {
    archiveSectionDefinition,
    archiveSectionFieldDefinition,
    createSectionDefinition,
    deleteSectionDefinition,
    unarchiveSectionDefinition,
    unarchiveSectionFieldDefinition,
    updateSectionDefinition,
    updateSectionDefinitionsOrder,
} from '@/domain/section-setting/Section.service';
import { sectionDefinitionKeys, useSearchSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';
import { SectionDefinitionDialog, SectionDefinitionFormValues } from '@/page/setting/section/SectionDefinitionDialog';
import { SectionDefinitionDraggable } from '@/page/setting/section/SectionDefinitionDraggable';
import { SectionFieldDefinitionDialog, SectionFieldDefinitionFormValues } from '@/page/setting/section/SectionFieldDefinitionDialog';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

type SectionDialogState = {
    open: boolean;
    section?: SectionDefinition;
    mode?: 'CREATE' | 'UPDATE' | 'DELETE' | 'ARCHIVE' | 'UNARCHIVE' | 'CREATE_FIELD' | 'UPDATE_FIELD' | 'DELETE_FIELD' | 'ARCHIVE_FIELD' | 'UNARCHIVE_FIELD';
    field?: SectionFieldDefinition;
};

/**
 * In this page we manage the section definitions (custom fields) of the employee
 * This page is accessible from the company settings page and only for the admin
 * @returns Page to manage the section definitions of the employee
 */
export const CompanySettingsSectionDefinitionsPage: FC = () => {
    const { t } = useTranslation();

    // Common state to manage dialogs in the page
    const [sectionDialogState, setSectionDialogState] = useState<SectionDialogState>({ open: false });

    const search = { withArchived: true };
    const { data: sectionDefinitions = [], refetch: refetchSectionDefinitions } = useSearchSectionDefinitions(search);

    const queryClient = useQueryClient();

    const setSectionDefinitions = (sections: SectionDefinition[]) => {
        // Be careful with the key, it must be the same as the one used in the query, we will improve this in the future by using
        // https://github.com/lukemorales/query-key-factory
        queryClient.setQueryData(sectionDefinitionKeys.search(search).queryKey, sections);
    };

    useReorderOnDrop({
        list: sectionDefinitions,
        onChange: newList => {
            // Using `flushSync` so we can query the DOM straight after this line
            const sortedList = newList.map((item, index) => ({
                ...item,
                order: index,
            }));
            flushSync(() => {
                setSectionDefinitions(sortedList);
            });

            saveOrderedSectionDefinitions(sortedList);
        },
        // Use the same keyId as the one used in the DraggableItem component
        // Use to query for the element after the drop
        // and trigger the post move flash
        keyId: 'data-section-id',
        // axis and allowedEdges are used to determine the direction of the drag and drop
        axis: 'vertical',
    });

    const saveOrderedSectionDefinitions = async (sortedList: SectionDefinition[]) => {
        try {
            const payload = sortedList.map(sectionDefinition => ({
                resourceId: sectionDefinition.id,
                order: sectionDefinition.order,
            }));
            await updateSectionDefinitionsOrder(payload);
        } catch {
            // if the api call fails we reset the section definitions
            await refetchSectionDefinitions();
        }
    };

    /**
     * Handle the api call to create a new section or update an existing one
     * @param section the section to save (new or update)
     * @param previousSection
     */
    const handleSectionSave = async (section: SectionDefinitionFormValues, previousSection?: SectionDefinition) => {
        const isEdit = !!previousSection?.id;
        try {
            // if the previous section is defined we update the section
            if (isEdit) {
                await handleUpdateSectionDefinition({
                    ...previousSection,
                    ...section,
                });
            } else {
                // else we create a new section
                await createSectionDefinition({
                    ...section,
                    // we add the new section at the end of the list
                    order: sectionDefinitions.length,
                });
            }
            handleCloseDialog();
            showSnackbar(isEdit ? t('employee_fields_page.messages.section_updated') : t('employee_fields_page.messages.new_section_added'), 'success');
            await refetchSectionDefinitions();
        } catch {
            showSnackbar(isEdit ? t('employee_fields_page.messages.section_update_error') : t('employee_fields_page.messages.new_section_add_error'), 'error');
        }
    };

    const handleSectionDelete = async (section: SectionDefinition) => {
        try {
            await deleteSectionDefinition(section.id);
            // Remove the section from the list
            setSectionDefinitions([...sectionDefinitions.filter(sectionDefinition => sectionDefinition.id !== section.id)]);
            setSectionDialogState({ open: false });
            showSnackbar(t('employee_fields_page.messages.section_deleted'), 'success');
        } catch {
            showSnackbar(t('employee_fields_page.messages.section_delete_error'), 'error');
        }
    };

    const handleArchiveSection = async (section: SectionDefinition) => {
        try {
            await archiveSectionDefinition(section.id);
            await refetchSectionDefinitions();
        } catch (e) {
            handleError(e);
        }
    };

    const handleUnarchiveSection = async (section: SectionDefinition) => {
        try {
            await unarchiveSectionDefinition(section.id);
            await refetchSectionDefinitions();
        } catch (e) {
            handleError(e);
        }
    };

    const handleCustomListSave = async (field: SectionFieldDefinitionFormValues, previousField?: SectionFieldDefinition): Promise<CustomList | undefined> => {
        const isEdit = !!previousField?.id;
        // if the section field is a custom list we must create/update the list before creating/updating the field

        if (isEdit) {
            const payload: CustomListUpdateMutation = {
                name: field.name,
                items: field.customList ?? [],
            };

            if (!previousField.customList?.id) {
                return;
            }

            return await updateCustomList(previousField.customList.id, payload);
        } else {
            // we create the list
            const payload: CustomListCreateMutation = {
                name: field.name,
                items: field.customList ?? [],
            };

            return await createCustomList(payload);
        }
    };

    /**
     * Handle the api call to create a new field or update an existing one
     * @param customList
     * @param field the field to save (new or update)
     * @param previousField field value before the update (undefined if it's a new field) and the id is defined
     */
    const handleFieldSave = async ({ customList, ...field }: SectionFieldDefinitionFormValues, previousField?: SectionFieldDefinition) => {
        const isEdit = !!previousField?.id;

        // if the section field is a custom list we must create/update the list before creating/updating the field
        let savedCustomList: CustomList | undefined;

        const valueType = field.valueType;
        const isList = valueType === 'CUSTOM_LIST_ITEM' || valueType === 'CUSTOM_MULTI_LIST_ITEM';
        if (isList) {
            savedCustomList = await handleCustomListSave({ customList, ...field }, previousField);
        }

        const section = sectionDialogState?.section;
        if (!section) {
            return;
        }

        try {
            // To create a new field in section we must update the section, the API doesn't expose a route to create a field
            const currentField: Omit<SectionFieldDefinition, 'id' | 'formId' | 'archived' | 'unmodifiable'> & {
                id?: number;
            } = isEdit
                ? {
                      ...previousField,
                      ...field,
                      customList: savedCustomList,
                  }
                : {
                      id: undefined,
                      ...field,
                      customList: savedCustomList,
                      order: section.fields.length,
                  };

            let fields: SectionFieldDefinition[];
            if (isEdit) {
                fields = section.fields.map(f => {
                    if (f.id === previousField.id) {
                        return {
                            ...f,
                            ...currentField,
                        };
                    }
                    return f;
                });
            } else {
                fields = [...section.fields, currentField] as SectionFieldDefinition[];
            }

            const payload: SectionDefinition = {
                // the rest of the section is the same
                ...section,
                fields,
            };

            await handleUpdateSectionDefinition(payload);
            handleCloseDialog();
            await refetchSectionDefinitions();
        } catch {
            showSnackbar(t('employee_fields_page.messages.section_update_error'), 'error');
        }
    };

    const handleFieldDelete = async () => {
        // To delete a field in section we must update the section, the API doesn't expose a route to delete a field

        const fieldId = sectionDialogState?.field?.id;
        const section = sectionDialogState?.section;

        if (!fieldId || !section?.id) {
            return;
        }

        try {
            await handleUpdateSectionDefinition({
                ...section,
                // Remove the field from the list
                fields: section.fields?.filter(sectionField => sectionField.id !== fieldId) ?? [],
            });
            handleCloseDialog();
            await refetchSectionDefinitions();
        } catch {
            showSnackbar(t('employee_fields_page.messages.section_update_error'), 'error');
        }
    };

    const handleArchiveField = async (field: SectionFieldDefinition) => {
        if (!field.id) {
            return;
        }
        try {
            await archiveSectionFieldDefinition(field.id);
            await refetchSectionDefinitions();
        } catch (e) {
            handleError(e);
        }
    };

    const handleUnarchiveField = async (field: SectionFieldDefinition) => {
        if (!field.id) {
            return;
        }
        try {
            await unarchiveSectionFieldDefinition(field.id);
            await refetchSectionDefinitions();
        } catch (e) {
            handleError(e);
        }
    };

    /**
     * Handle the api call to update the fields order in a section
     * @param section the section to update
     */
    const handleFieldsOrderChange = (section: SectionDefinition) => async (fields: SectionFieldDefinition[]) => {
        const updatedSection = {
            ...section,
            // set the new fields order
            fields,
        };

        try {
            // we replace the old section by the new one to not wait for the API response
            setSectionDefinitions(
                sectionDefinitions.map(sectionDefinition => (sectionDefinition.id === updatedSection.id ? updatedSection : sectionDefinition)),
            );

            // To update the fields order in section we must update the section, the API doesn't expose a route to update the fields order
            await handleUpdateSectionDefinition(updatedSection);
        } catch (e) {
            handleError(e);
        }
    };

    /**
     * Convert the section definition to the request format and call the api
     * @param section the section to update
     */
    const handleUpdateSectionDefinition = ({ id, fields, ...restSection }: SectionDefinition) => {
        const requestFields: SectionFieldDefinitionRequest[] =
            fields?.map(({ customList, ...f }) => ({
                ...f,
                customListId: customList?.id,
            })) ?? [];
        const payload: SectionDefinitionUpdateRequest = { fields: requestFields, ...restSection };

        return updateSectionDefinition(id, payload);
    };

    const handleCloseDialog = () => setSectionDialogState({ open: false });

    return (
        <Stack gap={2}>
            <Button
                sx={{ alignSelf: 'flex-end' }}
                onClick={() =>
                    setSectionDialogState({
                        open: true,
                        mode: 'CREATE',
                    })
                }
            >
                {t('employee_fields_page.add_section')}
            </Button>
            <Stack gap={2}>
                {sectionDefinitions?.map(sectionDefinition => (
                    <DraggableSection
                        key={sectionDefinition.id}
                        sectionDefinition={sectionDefinition}
                        onAction={setSectionDialogState}
                        onFieldsOrderChange={handleFieldsOrderChange}
                        onArchiveSection={handleArchiveSection}
                        onUnarchiveSection={handleUnarchiveSection}
                        onArchiveField={handleArchiveField}
                        onUnarchiveField={handleUnarchiveField}
                    />
                ))}
            </Stack>

            {sectionDialogState?.open && (sectionDialogState?.mode === 'CREATE' || sectionDialogState?.mode === 'UPDATE') && (
                <SectionDefinitionDialog onClose={handleCloseDialog} onSave={handleSectionSave} defaultSection={sectionDialogState?.section} />
            )}
            {sectionDialogState?.open && sectionDialogState?.mode === 'DELETE' && sectionDialogState.section && (
                <DeleteConfirmDialog
                    open
                    header={t('delete_confirmation_dialog.section.delete_field_text_field')}
                    textContent={t('delete_confirmation_dialog.section.delete_field_content')}
                    fieldName={getLabelTranslation(sectionDialogState.section.name)}
                    onClose={handleCloseDialog}
                    onConfirm={() => !!sectionDialogState?.section && handleSectionDelete(sectionDialogState?.section)}
                />
            )}
            {/* Dialogs for the fields section */}
            {sectionDialogState?.open && (sectionDialogState.mode === 'CREATE_FIELD' || sectionDialogState.mode === 'UPDATE_FIELD') && (
                <SectionFieldDefinitionDialog onClose={handleCloseDialog} onSave={handleFieldSave} defaultSectionField={sectionDialogState?.field} />
            )}
            {sectionDialogState?.open && sectionDialogState.mode === 'DELETE_FIELD' && sectionDialogState.field && (
                <DeleteConfirmDialog
                    open
                    onClose={handleCloseDialog}
                    onConfirm={handleFieldDelete}
                    fieldName={getLabelTranslation(sectionDialogState.field.name)}
                />
            )}
        </Stack>
    );
};

type DraggableSectionProps = {
    sectionDefinition: SectionDefinition;
    onAction: (action: SectionDialogState) => void;
    onFieldsOrderChange: (section: SectionDefinition) => (fields: SectionFieldDefinition[]) => void;
    onArchiveSection: (section: SectionDefinition) => void;
    onUnarchiveSection: (section: SectionDefinition) => void;
    onArchiveField: (field: SectionFieldDefinition) => void;
    onUnarchiveField: (field: SectionFieldDefinition) => void;
};
const DraggableSection: FC<DraggableSectionProps> = ({
    sectionDefinition,
    onAction,
    onFieldsOrderChange,
    onArchiveSection,
    onUnarchiveSection,
    onArchiveField,
    onUnarchiveField,
}) => {
    return (
        <SectionDefinitionDraggable
            section={sectionDefinition}
            onDelete={() =>
                onAction({
                    open: true,
                    mode: 'DELETE',
                    section: sectionDefinition,
                })
            }
            onUpdate={() =>
                onAction({
                    open: true,
                    mode: 'UPDATE',
                    section: sectionDefinition,
                })
            }
            onArchive={() => onArchiveSection(sectionDefinition)}
            onUnarchive={() => onUnarchiveSection(sectionDefinition)}
            onFieldAdd={() =>
                onAction({
                    open: true,
                    mode: 'CREATE_FIELD',
                    section: sectionDefinition,
                })
            }
            onFieldUpdate={field =>
                onAction({
                    open: true,
                    mode: 'UPDATE_FIELD',
                    section: sectionDefinition,
                    field,
                })
            }
            onFieldDelete={field =>
                onAction({
                    open: true,
                    mode: 'DELETE_FIELD',
                    section: sectionDefinition,
                    field,
                })
            }
            onFieldArchive={field => onArchiveField(field)}
            onFieldUnarchive={onUnarchiveField}
            onFieldsOrderChange={onFieldsOrderChange(sectionDefinition)}
        />
    );
};
