import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { CostCenter } from '@/domain/cost-center/CostCenter.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useGetCostCenters } from '@/hooks/cost-center/CostCenter.hook';
import { CostCenterAssignmentFormValue } from '@/page/cost-center/CostCentersAssignment.schema';
import { Button, FormHelperText, IconButton, Stack, StackProps, Typography } from '@mui/material';
import { Add01Icon, RemoveCircleIcon } from 'hugeicons-react';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type CostCenterAssignmentListFieldProps = StackProps & {
    fieldName: string;
    displayLabel?: boolean;
    fieldsRowGap?: number;
};

export const CostCenterAssignmentListField: FC<CostCenterAssignmentListFieldProps> = props => {
    const { fieldName, displayLabel = true, fieldsRowGap = 2, ...rootProps } = props;

    const { t } = useTranslation();
    const { data: costCenters = [] } = useGetCostCenters();

    const { control, formState, watch } = useFormContext<Record<string, CostCenterAssignmentFormValue[]>>();

    const {
        fields: CostCenterAssignmentListField,
        append: appendCostCenterAssignment,
        update,
        remove,
    } = useFieldArray({
        control,
        name: fieldName,
    });

    // recalculate the percentage of the first cost center if the total percentage is different from 100
    const recalculatePercentage = (employmentCostCenters: CostCenterAssignmentFormValue[], currentTotalPercentage: number) => {
        if (employmentCostCenters.length === 0) {
            return;
        }

        // diff can be negative
        const diff = 100 - currentTotalPercentage;
        const newPercentage = employmentCostCenters[0].percentage + diff;
        update(0, { ...employmentCostCenters[0], percentage: newPercentage });
    };

    const costCentersAssignmentList = watch(fieldName) ?? [];
    // Total percentage should always be 100%
    const totalPercentage = costCentersAssignmentList.reduce((acc, curr) => acc + curr.percentage, 0);
    if (totalPercentage !== 100) {
        recalculatePercentage(costCentersAssignmentList, totalPercentage);
    }

    const isCostCenterFeatureEnabled = hasRealmFeatureEnabled(RealmFeaturesType.COST_CENTERS);

    if (!isCostCenterFeatureEnabled) {
        return;
    }

    const handleAddCostCenter = () => {
        // first cost center should be 100%
        const percentage = costCentersAssignmentList.length === 0 ? 100 : 0;
        // fake costCenter because it is required in the schema
        appendCostCenterAssignment({ costCenter: undefined as unknown as CostCenter, percentage });
    };

    return (
        <Stack flex={1} {...rootProps}>
            {displayLabel && CostCenterAssignmentListField.length > 0 && (
                <Typography sx={{ px: 0.5 }}>{t('cost_centers.assignment_form.cost_center_field')}</Typography>
            )}
            <Stack gap={fieldsRowGap}>
                {CostCenterAssignmentListField.map((departmentCostCenterField, index) => (
                    <Stack direction={'row'} alignItems={'flex-start'} gap={1} key={departmentCostCenterField.id}>
                        <FieldSelect
                            name={`${fieldName}.${index}.costCenter`}
                            control={control}
                            options={costCenters}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={option => option.name}
                            getOptionKey={option => option.id}
                            autocompleteProps={{ sx: { flex: 2 } }}
                        />

                        <FieldNumber
                            name={`${fieldName}.${index}.percentage`}
                            control={control}
                            suffix={'%'}
                            disabled={index === 0}
                            precision={2}
                            sx={{ flex: 1 }}
                            inputNumberProps={{
                                InputProps: {
                                    inputProps: {
                                        'aria-label': t('cost_centers.assignment_form.cost_center_percentage_field'),
                                    },
                                },
                            }}
                        />

                        <IconButton
                            size='small'
                            onClick={() => {
                                remove(index);
                            }}
                            aria-label={'remove-cost-center'}
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                    </Stack>
                ))}
                {formState.errors.employmentCostCenters && <FormHelperText error>{formState.errors.employmentCostCenters?.root?.message}</FormHelperText>}
                <Button variant='text' sx={{ alignSelf: 'flex-start' }} onClick={handleAddCostCenter} startIcon={<Add01Icon width={20} height={20} />}>
                    {t('cost_centers.assignment_form.add_cost_center')}
                </Button>
            </Stack>
        </Stack>
    );
};
