import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import {
    getEmployeeReviewManagerSummaryComment,
    getEmployeeReviewManagerSummaryDate,
    getEmployeeReviewManagerSummaryReviewerEmployee,
} from '@/domain/employee-review/EmployeeReview.service';
import { useTheme } from '@mui/material';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type SummaryCompletedProps = {
    managerSummary: EmployeeReviewManagerSummary;
};
export const SummaryCompletedPDF: FC<SummaryCompletedProps> = ({ managerSummary }) => {
    const { t } = useTranslation();

    const theme = useTheme();

    return (
        <View
            style={{
                display: 'flex',
                backgroundColor: theme.palette.grey[50],
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                gap: theme.spacing(1.5),
            }}
        >
            <Text
                style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                }}
            >
                {t('reviews.summary.evaluation', { context: managerSummary?.employeeReviewStatus })}
            </Text>
            <View
                style={{
                    borderRadius: theme.shape.borderRadius,
                    gap: theme.spacing(1),
                }}
            >
                <View
                    style={{
                        gap: theme.spacing(0.25),
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                    }}
                >
                    <Text
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {t('reviews.summary.by', { context: managerSummary?.employeeReviewStatus })}:{' '}
                    </Text>
                    <Text>{getEmployeeReviewManagerSummaryReviewerEmployee(managerSummary)?.displayName}</Text>
                </View>
                <View
                    style={{
                        gap: theme.spacing(0.25),
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Text
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {t('reviews.summary.date', { context: managerSummary?.employeeReviewStatus })}:{' '}
                    </Text>
                    <Text>{getEmployeeReviewManagerSummaryDate(managerSummary)}</Text>
                </View>
                {getEmployeeReviewManagerSummaryComment(managerSummary) && (
                    <View
                        style={{
                            flexDirection: 'row',
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {t('reviews.summary.final_comment')}:{' '}
                        </Text>
                        <Text
                            style={{
                                width: '75vw',
                            }}
                        >
                            {getEmployeeReviewManagerSummaryComment(managerSummary)}
                        </Text>
                    </View>
                )}
            </View>
        </View>
    );
};
