import { departmentAPI } from '@/api/department/Department.api';
import { employeeAPI } from '@/api/employee/Employee.api';
import { employmentAPI } from '@/api/employment/Employment.api';
import { OrderMutation } from '@/domain/common';
import {
    Department,
    DepartmentCreationMutation,
    DepartmentNode,
    DepartmentNodeOrganization,
    DepartmentNodesSearchRequest,
    DepartmentSearchRequest,
    DepartmentUpdateMutation,
} from '@/domain/department/Department.model';
import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';
import { Employment, NOT_TERMINATED_EMPLOYMENT_STATUSES } from '@/domain/employment/Employment.model';
import { Objective } from '@/domain/objective/Objective.model';
import { Label } from '@/domain/label/Label.model';
import { findRecursiveItemById, findRecursiveItemParent } from '@/utils/object.util';
import { handleError } from '@/utils/api.util';

export function searchDepartments(search: DepartmentSearchRequest = {}): Promise<Department[]> {
    return departmentAPI.searchDepartments(search);
}

export const searchDepartmentNodes = (search: DepartmentNodesSearchRequest = {}): Promise<DepartmentNode[]> => {
    return departmentAPI.searchDepartmentNodes(search);
};

export const createDepartment = (mutation: DepartmentCreationMutation): Promise<Department> => {
    return departmentAPI.createDepartment(mutation);
};

export const updateDepartment = (departmentId: number, mutation: DepartmentUpdateMutation): Promise<Department> => {
    return departmentAPI.updateDepartment(departmentId, mutation);
};

export const updateDepartmentOrders = (mutations: OrderMutation[]): Promise<void> => {
    return departmentAPI.updateDepartmentOrders(mutations);
};

/**
 * Moves a department to a new parent and index.
 * The department is updated with the new parent and index.
 * The previous parent and the new parent are updated with the new order of their children.
 * @param departmentNodes
 * @param departmentNode
 * @param newParentId
 * @param newIndex
 */
export const moveDepartmentToNewParent = async ({
    departmentNodes,
    departmentNode,
    newParentId,
    newIndex,
}: {
    departmentNodes: DepartmentNode[];
    departmentNode: DepartmentNode;
    newParentId: number | undefined;
    newIndex: number;
}): Promise<void> => {
    const parentNode = findRecursiveItemParent(departmentNodes, departmentNode.id);
    // change the department parent
    const updateMutation: DepartmentUpdateMutation = {
        name: departmentNode.name,
        order: newIndex,
        parentId: newParentId,
        managerIds: departmentNode.managers.map(m => m.id),
        departmentCostCenters: departmentNode.departmentCostCenters.map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        })),
    };

    // build mutations to reorder the previous parent
    const childrenSource = parentNode?.children ?? departmentNodes;
    const childrenSourceOrderMutation = childrenSource
        .filter(child => child.id !== departmentNode.id) // exclude the moved item
        .map((child, index) => ({
            resourceId: child.id,
            order: index,
        }));

    // build mutations to reorder the new parent
    const childrenDestination = newParentId ? (findRecursiveItemById(departmentNodes, newParentId)?.children ?? []) : departmentNodes;
    const newChildrenDestination = [...childrenDestination];
    newChildrenDestination.splice(newIndex, 0, departmentNode); // insert the moved item
    const childrenDestinationOrderMutation = newChildrenDestination.map((child, index) => ({
        resourceId: child.id,
        order: index,
    }));

    try {
        await updateDepartment(departmentNode.id, updateMutation);
        // reorder separately the source and destination children (each branch of the tree has its own order)
        await updateDepartmentOrders([...childrenSourceOrderMutation, ...childrenDestinationOrderMutation]);
    } catch (error) {
        handleError(error);
    }
};

export const deleteDepartment = async (departmentId: number): Promise<void> => {
    await departmentAPI.deleteDepartment(departmentId);
};

export const archiveDepartment = async (departmentId: number): Promise<void> => {
    await departmentAPI.archiveDepartment(departmentId);
};

export const unarchiveDepartment = async (departmentId: number): Promise<void> => {
    await departmentAPI.unarchiveDepartment(departmentId);
};

export const renameDepartment = async (department: Department, name: Label): Promise<Department> => {
    // update the department with the new name
    const mutation: DepartmentUpdateMutation = {
        name,
        // the rest of the fields are not changed
        parentId: department.parentId,
        managerIds: department.managers.map(m => m.id),
        departmentCostCenters: department.departmentCostCenters.map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        })),
        order: department.order,
    };
    return updateDepartment(department.id, mutation);
};

const buildDepartmentNodeOrganizations = (nodes: DepartmentNode[], employees: Employee[]): DepartmentNodeOrganization[] => {
    const employeesMapByDepartment = employees
        .filter(employee => getCurrentDepartments(employee).length > 0) // Ensure the employee has departments
        .flatMap(employee => getCurrentDepartments(employee).map(department => ({ department, employee })))
        .reduce((map, { department, employee }) => {
            if (!map.has(department.id)) {
                map.set(department.id, new Set<EmployeeAvatar>());
            }
            map.get(department.id)?.add(employee);
            return map;
        }, new Map<number, Set<EmployeeAvatar>>());

    return mapEmployeesToDepartmentNodeOrganizations(nodes, employeesMapByDepartment);
};

export const getDepartmentNodeOrganizations = async (search: DepartmentNodesSearchRequest = {}): Promise<DepartmentNodeOrganization[]> => {
    const employeeSearch = {
        statuses: NOT_TERMINATED_EMPLOYMENT_STATUSES,
    };
    const [departmentNodes, employees] = await Promise.all([departmentAPI.searchDepartmentNodes(search), employeeAPI.searchEmployees(employeeSearch)]);

    if (search.date) {
        const employmentsAtDate = await employmentAPI.searchEmployments({ atDate: search.date });
        return buildDepartmentNodeOrganizationsAtDate(departmentNodes, employees, employmentsAtDate);
    }

    return buildDepartmentNodeOrganizations(departmentNodes, employees);
};

const buildDepartmentNodeOrganizationsAtDate = (nodes: DepartmentNode[], employees: Employee[], employments: Employment[]): DepartmentNodeOrganization[] => {
    const employeesMapByDepartment = employments.reduce((map, employment) => {
        const employee = employees.find(emp => emp.id === employment.employeeId);
        if (!employee) {
            return map;
        }

        const department = employment.department;
        if (!map.has(department.id)) {
            map.set(department.id, new Set<EmployeeAvatar>());
        }
        map.get(department.id)?.add(employee);
        return map;
    }, new Map<number, Set<EmployeeAvatar>>());

    return mapEmployeesToDepartmentNodeOrganizations(nodes, employeesMapByDepartment);
};

const mapEmployeesToDepartmentNodeOrganizations = (
    nodes: DepartmentNode[],
    employeesMapByDepartment: Map<number, Set<EmployeeAvatar>>,
): DepartmentNodeOrganization[] => {
    return nodes.map(node => {
        const employees = Array.from(employeesMapByDepartment.get(node.id) ?? []);
        const children = mapEmployeesToDepartmentNodeOrganizations(node.children, employeesMapByDepartment);
        return { ...node, employees, children };
    });
};

const getCurrentDepartments = (employee: Employee): Department[] => {
    return employee.currentEmployments.map(employment => employment.department);
};

/**
 * Retrieves the list of parent departments, including the department itself.
 *
 * @param department - The starting department.
 * @param allDepartments - List of all departments.
 * @param maxDepth - Maximum depth to traverse (default: 6).
 * @returns A list of parent departments including the given department.
 */
export const getParentDepartments = (department: Department | undefined, allDepartments: Department[], maxDepth = 6): Department[] => {
    if (!department || maxDepth <= 0) {
        return [];
    }
    const parent = allDepartments.find(dept => dept.id === department.parentId);
    return [department, ...getParentDepartments(parent, allDepartments, maxDepth - 1)];
};

/**
 * Retrieves available objectives for an employee in a given department.
 *
 * @param employeeDepartment - The department of the employee.
 * @param objectives - A list of all objectives.
 * @param allDepartments - A list of all departments.
 * @returns A list of objectives available within the allowed department hierarchy.
 */
export const getDepartmentsObjectivesAvailable = (employeeDepartment: Department, objectives: Objective[], allDepartments: Department[]): Objective[] => {
    // Retrieve the allowed parent departments (including the employee's own department)
    const allowedDepartments = getParentDepartments(employeeDepartment, allDepartments).map(dept => dept.id);
    // Filter objectives to include only those belonging to the allowed departments
    return objectives.filter(objective => objective.department && allowedDepartments.includes(objective.department.id));
};
