import { realmApi } from '@/api/realm/realm.api';
import { Realm, RealmFeaturesType, RealmUpdateMutation } from './Realm.model';
import { store } from '@/stores/store';

export const hasRealmFeatureEnabled = (feature: RealmFeaturesType): boolean => {
    const realmFeatures = store.getState().ui.currentRealm?.realmFeatures ?? [];
    return !!realmFeatures?.some(rf => rf.feature === feature && rf.enabled);
};

export const getRealmByName = async (realmName: string): Promise<Realm> => {
    const realms = await realmApi.searchRealmByName(realmName);
    const realm = realms[0];
    if (!realm) {
        throw new Error(`Realm not found`);
    }
    return realm;
};

export const updateRealm = async (mutation: RealmUpdateMutation, tenantId: string): Promise<void> => {
    await realmApi.updateRealm(mutation, tenantId);
};
