import { searchCostCenters } from '@/domain/cost-center/CostCenter.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const costCenterKeys = createQueryKeys('costCenters', {
    search: {
        queryKey: getNull(),
    },
});
export const useGetCostCenters = createQueryHook(costCenterKeys.search, searchCostCenters);
