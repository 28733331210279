import { FilePicker, TemporaryFile } from '@/components/file-picker/FilePicker';
import { fileSchema } from '@/components/file-picker/FilePicker.schema';
import { FieldText } from '@/components/form/field-text/FieldText';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { LegalUnit } from '@/domain/legal-unit/LegalUnit.model';
import { createLegalUnit, deleteLegalUnitLogo, updateLegalUnit, updateLegalUnitLogo } from '@/domain/legal-unit/LegalUnit.service';
import { useGetLegalUnit, useGetLegalUnitLogoUrl } from '@/hooks/legal-unit/LegalUnit.hook';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer } from '@/page/layout/Footer';
import { FORM_COMPANY_SETTINGS_CONTAINER_CLASS } from '@/page/setting/CompanySettings.constants';
import { LocationGrid } from '@/page/setting/location/LocationGrid';
import { handleError } from '@/utils/api.util';
import { getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, FormHelperText, Paper, Stack } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { ImageBox } from '@/components/image-box/ImageBox';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';

export const LegalUnitSettingPage: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const legalUnitId = Number(params.legalUnitId) || undefined;

    const {
        data: legalUnit,
        isError: isLegalUnitError,
        isLoading: isLegalUnitLoading,
        error: legalUnitError,
        refetch: refetchLegalUnit,
    } = useGetLegalUnit(legalUnitId);

    const goToDetail = (id: number) => {
        // Redirect to the legal unit detail page
        navigate(`/settings/organization/legal-units/${id}`);
    };

    const handleSave = async ({ logoImage, ...data }: LegalUnitSettingForm) => {
        const temporaryFile = logoImage as TemporaryFile;
        try {
            if (legalUnit) {
                await updateLegalUnit(legalUnit.id, { ...data, locationIds: legalUnit.locations.map(l => l.id) });
                if (temporaryFile) {
                    await updateLegalUnitLogo(legalUnit.id, temporaryFile);
                }
                showSnackbar(t('legal_units_settings_page.legal_unit_saved'), 'success');
            } else {
                const { id } = await createLegalUnit(data);
                if (temporaryFile) {
                    await updateLegalUnitLogo(id, temporaryFile);
                }
                goToDetail(id);
            }
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <StateHandler isLoading={isLegalUnitLoading} isError={isLegalUnitError} error={legalUnitError}>
            <LegalUnitSettingForm legalUnit={legalUnit} onSave={handleSave} onRefetch={refetchLegalUnit} />
        </StateHandler>
    );
};

const LegalUnitSettingForm: FC<{
    legalUnit?: LegalUnit;
    onSave: (data: LegalUnitSettingForm) => void;
    onRefetch: () => void;
}> = ({ legalUnit, onSave, onRefetch }) => {
    const { t } = useTranslation();

    const { data: legalUnitLogoUrl } = useGetLegalUnitLogoUrl(legalUnit?.id);

    const { control, handleSubmit, watch } = useForm<LegalUnitSettingForm>({
        resolver: yupResolver(legalUnitFormSchema),
        defaultValues: {
            legalName: legalUnit?.legalName ?? '',
            displayName: legalUnit?.displayName ?? '',
            logoImage: legalUnit?.logoImageUrl
                ? {
                      // Hack to make the FilePicker work with the saved logo
                      id: -1,
                  }
                : undefined,
        },
    });

    const handleRemoveSavedLogo = async (legalUnitId: number) => {
        try {
            await deleteLegalUnitLogo(legalUnitId);
        } catch (error) {
            handleError(error);
        }
    };

    const image = watch('logoImage');

    return (
        <>
            <Stack component={ContentContainer} flex={1} gap={2}>
                <Stack component={Paper} p={3} gap={2} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS}>
                    <FormControlLabel
                        label={t('legal_units_settings_page.display_name')}
                        control={<FieldText name='displayName' control={control} fullWidth />}
                    />
                    <FormControlLabel label={t('legal_units_settings_page.legal_name')} control={<FieldText name='legalName' control={control} fullWidth />} />

                    {/* todo: use FieldFilePicker */}
                    <Controller
                        name='logoImage'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <FilePicker
                                    accept={'image/*'}
                                    maxFiles={1}
                                    files={field.value ? [field.value] : []}
                                    onFileUploaded={fileToAdd => {
                                        field.onChange(fileToAdd[0]);
                                    }}
                                    onFileRemoved={() =>
                                        legalUnit?.id && legalUnit.logoImageUrl ? handleRemoveSavedLogo(legalUnit.id) : field.onChange(getNull())
                                    }
                                    onFileRenamed={undefined}
                                    fetchDocumentUrl={undefined}
                                />
                                {!!error && (
                                    <FormHelperText sx={{ marginLeft: 2 }} error={!!error}>
                                        {error?.message}
                                    </FormHelperText>
                                )}
                            </>
                        )}
                    />

                    {image && isTemporaryFile(image) && image.preview && <ImageBox src={image.preview} alt={image.name ?? ''} maxHeight={'300px'} />}
                    {/* Hack to display stored file  */}
                    {image?.id === -1 && legalUnitLogoUrl && <ImageBox src={legalUnitLogoUrl} alt={image.name ?? ''} maxHeight={'300px'} />}
                </Stack>

                {legalUnit && (
                    <Stack component={Paper} p={3} gap={2} flex={1}>
                        <LocationGrid legalUnitId={legalUnit.id} locations={legalUnit.locations} onRefetch={onRefetch} />
                    </Stack>
                )}
            </Stack>
            <Footer>
                <Button onClick={handleSubmit(onSave, console.error)} variant='contained'>
                    {t(legalUnit?.id ? 'general.update' : 'general.create')}
                </Button>
            </Footer>
        </>
    );
};

const legalUnitFormSchema = yup.object().shape({
    legalName: yup.string().trim().required(),
    displayName: yup.string().trim().required(),
    logoImage: fileSchema.nullable().default(getNull()),
});

export type LegalUnitSettingForm = yup.InferType<typeof legalUnitFormSchema>;
