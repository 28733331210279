import { getGroupedSkillsByCategoryId } from '@/domain/skill/Skill.service';
import { FeedbackSkillFormType, FeedbackSkillsFormType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { FeedbackSkill } from '@/page/review/employee-review-feedback-form/FeedbackSkill';
import { getEmployeeReviewFeedbackSkillState } from '@/page/review/employee-review-feedback-form/FeedbackSkills.utils';
import { FeedbackSkillsDescription } from '@/page/review/employee-review-feedback-form/FeedbackSkillsDescription';
import { getLabelTranslation } from '@/utils/language.util';
import { Alert, Paper, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { FC, Ref } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type FeedbackSkillsProps = {
    disabled: boolean;
    skillsItem: FeedbackSkillsFormType;
    handleSkillChanged: (feedbackSkillForm: FeedbackSkillFormType) => void;
    error: FieldError | undefined;
    innerRef: Ref<HTMLDivElement>;
} & StackProps;

export const FeedbackSkills: FC<FeedbackSkillsProps> = ({ innerRef, handleSkillChanged, skillsItem, error, disabled, ...rest }) => {
    const { t } = useTranslation();
    const { minSkills, maxSkills, skills, instruction } = skillsItem;
    const theme = useTheme();
    const groupedSkillsByCategoryId = getGroupedSkillsByCategoryId(skills);

    if (skillsItem.skills.length === 0) {
        return <></>;
    }

    return (
        <Stack
            component={Paper}
            p={2}
            gap={2}
            ref={innerRef}
            sx={{
                border: error ? `1px solid ${theme.palette.error.main}` : 'none',
            }}
            {...rest}
        >
            {!!error && error.message && (
                <Alert severity='error' elevation={0}>
                    <Typography variant='body2'>{error.message}</Typography>
                </Alert>
            )}
            <Typography variant='h2'>{t('reviews.write_feedback.review_skills')}</Typography>

            <FeedbackSkillsDescription minSkills={minSkills} maxSkills={maxSkills} instruction={getLabelTranslation(instruction)} />

            {groupedSkillsByCategoryId.map(({ category, groupedSkills }) => (
                <SkillCategoryBlock
                    disabled={disabled}
                    key={category.id}
                    categoryName={category.name ?? ''}
                    groupedFeedbackSkillForm={groupedSkills}
                    skillsItem={skillsItem}
                    handleSkillChanged={handleSkillChanged}
                />
            ))}
        </Stack>
    );
};

type SkillCategoryBlockProps = {
    categoryName: string;
    groupedFeedbackSkillForm: FeedbackSkillFormType[];
    handleSkillChanged: (feedbackSkillForm: FeedbackSkillFormType) => void;
    skillsItem: FeedbackSkillsFormType;
    disabled: boolean;
};

const SkillCategoryBlock: FC<SkillCategoryBlockProps> = ({ categoryName, groupedFeedbackSkillForm, handleSkillChanged, skillsItem, disabled }) => {
    return (
        <Stack gap={2}>
            <Typography variant={'body1bold'}>{categoryName}</Typography>
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {groupedFeedbackSkillForm.map(feedbackSkillForm => (
                    <FeedbackSkill
                        disabled={disabled}
                        key={feedbackSkillForm.skill.id}
                        skillState={getEmployeeReviewFeedbackSkillState(feedbackSkillForm, skillsItem)}
                        feedbackSkillItem={feedbackSkillForm}
                        handleSkillChanged={handleSkillChanged}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
