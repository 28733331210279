import { API_BASE_URL, buildImportFormData, client } from '@/api/common';
import {
    mapSectionDefinitionDTO,
    mapSectionFieldDefinitionDTO,
    SectionDefinitionDTO,
    SectionFieldDefinitionDTO,
} from '@/api/section-definition/SectionDefinition.api';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { getAppConfig } from '@/config/config';
import { EmployeeSectionFieldProfileChange } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import {
    EmployeeSection as EmployeeSectionDomain,
    EmployeeSectionFieldDocument as EmployeeSectionFieldDocumentDomain,
    EmployeeSectionImportRequest,
    EmployeeSectionRow as EmployeeSectionRowDomain,
    EmployeeSectionRowCreationMutation,
    EmployeeSectionRowUpdateMutation,
    EmployeeSectionSearch,
} from '@/domain/employee-section/EmployeeSection.model';
import { ImportResult } from '@/domain/import/Import.model';
import { AxiosResponse } from 'axios';
import { CONTENT_DISPOSITION } from '@/domain/common';

export type EmployeeSectionDTO = Overwrite<
    EmployeeSectionDomain,
    {
        sectionDefinition: SectionDefinitionDTO;
        rows: EmployeeSectionRowDTO[];
    }
>;
export type EmployeeSectionFieldDTO = Overwrite<
    EmployeeSectionFieldProfileChange,
    {
        sectionFieldDefinition: SectionFieldDefinitionDTO;
    }
>;
export type EmployeeSectionRowDTO = Overwrite<
    EmployeeSectionRowDomain,
    {
        fields: EmployeeSectionFieldDTO[];
    }
>;
export type EmployeeSectionFieldDocumentDTO = EmployeeSectionFieldDocumentDomain;
type EmployeeSectionSearchRequestDTO = EmployeeSectionSearch;
type EmployeeSectionRowUpdateRequestDTO = EmployeeSectionRowUpdateMutation;

const searchEmployeeSections = async (request: EmployeeSectionSearch): Promise<EmployeeSectionDomain[]> => {
    const { data } = await client.post<EmployeeSectionDTO[], AxiosResponse<EmployeeSectionDTO[]>, EmployeeSectionSearchRequestDTO>(
        API_BASE_URL + '/employee/sections/search',
        request,
    );
    return data.map(mapEmployeeSectionDTO);
};

const buildSectionRowFormData = (request: EmployeeSectionRowCreationMutation | EmployeeSectionRowUpdateRequestDTO): FormData => {
    const formData = new FormData();
    const files = request.fields.flatMap(field => field.documents ?? []);

    const fields = request.fields.map(field => {
        return {
            ...field,
            documents: field.documents?.map(document => {
                // Check if it is a temporary file or a stored file
                return isTemporaryFile(document)
                    ? {
                          name: document.name,
                          mimeType: document.type,
                      }
                    : document;
            }),
        };
    });

    const rowRequest = {
        order: request.order,
        fields,
    };

    formData.append('rowRequest', new Blob([JSON.stringify(rowRequest)], { type: getAppConfig().MIME_TYPES.JSON }));

    // For each new uploaded file, append it to the form data
    files.forEach(file => {
        if (isTemporaryFile(file)) {
            formData.append('documents', file.data, file.name);
        }
    });

    return formData;
};

const addEmployeeSectionRow = async (employeeSectionId: number, request: EmployeeSectionRowCreationMutation): Promise<EmployeeSectionRowDomain> => {
    const formData = buildSectionRowFormData(request);
    const { data } = await client.postForm<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, FormData>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row`,
        formData,
    );
    return mapEmployeeSectionRowDTO(data);
};

const createEmployeeSectionRowPendingRequest = async (
    employeeSectionId: number,
    request: EmployeeSectionRowCreationMutation,
): Promise<EmployeeSectionRowDomain> => {
    const formData = buildSectionRowFormData(request);
    const { data } = await client.postForm<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, FormData>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row/pending`,
        formData,
    );
    return mapEmployeeSectionRowDTO(data);
};

const updateEmployeeSectionRowPendingRequest = async (
    employeeSectionId: number,
    pendingRowId: number,
    request: EmployeeSectionRowUpdateMutation,
): Promise<EmployeeSectionRowDomain> => {
    const formData = buildSectionRowFormData(request);
    const { data } = await client.putForm<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, FormData>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${pendingRowId}/pending`,
        formData,
    );
    return mapEmployeeSectionRowDTO(data);
};

const updateEmployeeSectionRowApprovedRequest = async (
    employeeSectionId: number,
    pendingRowId: number,
    request: EmployeeSectionRowUpdateMutation,
): Promise<EmployeeSectionRowDomain> => {
    const formData = buildSectionRowFormData(request);
    const { data } = await client.putForm<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, FormData>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${pendingRowId}/approved`,
        formData,
    );
    return mapEmployeeSectionRowDTO(data);
};

const updateEmployeeSectionRow = async (
    employeeSectionId: number,
    employeeSectionRowId: number,
    request: EmployeeSectionRowUpdateRequestDTO,
): Promise<EmployeeSectionRowDomain> => {
    const formData = buildSectionRowFormData(request);

    const { data } = await client.putForm<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, FormData>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}`,
        formData,
    );
    return mapEmployeeSectionRowDTO(data);
};

const deleteEmployeeSectionRow = async (employeeSectionId: number, employeeSectionRowId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}`);
};

const deleteEmployeeSectionRowPendingRequest = async (employeeSectionId: number, employeeSectionRowId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}/cancel`);
};

//On approve we can also update the row, but not a mandatory requirement
const approveEmployeeCustomSectionRowPendingRequest = async (
    employeeSectionId: number,
    pendingRowId: number,
    request?: EmployeeSectionRowUpdateMutation,
): Promise<void> => {
    const formData = request ? buildSectionRowFormData(request) : undefined;
    await client.postForm(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${pendingRowId}/approve`, formData);
};

const getEmployeeSectionFieldDocumentUrl = async (employeeSectionFieldDocumentId: number, ContentDisposition: CONTENT_DISPOSITION): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/employee/sections/document/${employeeSectionFieldDocumentId}/url/${ContentDisposition}`)).data;
};

const importEmployeeSection = async (request: EmployeeSectionImportRequest, sectionDefinitionId: number): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm(API_BASE_URL + `/employee/sections/${sectionDefinitionId}/import`, formData)).data;
};

const getEmployeeSectionRow = async (employeeSectionId: number, employeeSectionRowId: number): Promise<EmployeeSectionRowDomain> => {
    const { data } = await client.get<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}`,
    );
    return mapEmployeeSectionRowDTO(data);
};

export const employeeSectionApi = {
    searchEmployeeSections,
    addEmployeeSectionRow,
    updateEmployeeSectionRow,
    deleteEmployeeSectionRow,
    getEmployeeSectionFieldDocumentUrl,
    createEmployeeSectionRowPendingRequest,
    updateEmployeeSectionRowPendingRequest,
    updateEmployeeSectionRowApprovedRequest,
    deleteEmployeeSectionRowPendingRequest,
    approveEmployeeCustomSectionRowPendingRequest,
    getEmployeeSectionRow,
    importEmployeeSection,
};

export const mapEmployeeSectionDTO = (employeeSectionDTO: EmployeeSectionDTO): EmployeeSectionDomain => {
    return {
        ...employeeSectionDTO,
        sectionDefinition: mapSectionDefinitionDTO(employeeSectionDTO.sectionDefinition),
        rows: employeeSectionDTO.rows.map(mapEmployeeSectionRowDTO),
    };
};

export const mapEmployeeSectionRowDTO = (employeeSectionRowDTO: EmployeeSectionRowDTO): EmployeeSectionRowDomain => {
    return {
        ...employeeSectionRowDTO,
        fields: employeeSectionRowDTO.fields.map(employeeSectionField => ({
            ...employeeSectionField,
            sectionFieldDefinition: mapSectionFieldDefinitionDTO(employeeSectionField.sectionFieldDefinition),
        })),
    };
};

export const mapEmployeeSectionFieldDTO = (employeeSectionFieldDTO: EmployeeSectionFieldDTO): EmployeeSectionFieldProfileChange => {
    return {
        ...employeeSectionFieldDTO,
        sectionFieldDefinition: mapSectionFieldDefinitionDTO(employeeSectionFieldDTO.sectionFieldDefinition),
    };
};
