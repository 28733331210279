import { searchSkillCategories } from '@/domain/skill-category/SkillCategory.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const reviewCategoryKeys = createQueryKeys('reviewCategories', {
    skillCategories: (...optionalParam: Parameters<typeof searchSkillCategories>) => ({
        queryKey: [optionalParam ?? {}],
    }),
});

export const useGetReviewsCategories = createQueryHook(reviewCategoryKeys.skillCategories, searchSkillCategories);
