import { ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnackbar } from '@/utils/snackbar.util';
import {
    createPlanningTag,
    deletePlanningTag,
    searchPlanningTags,
    updatePlanningTag,
    updatePlanningTagOrders,
} from '@/domain/planning-tag/PlanningTag.service';
import { CompanySettingsLayout } from '../CompanySettingsLayout';
import { ConfigType } from '../types';
import { PlanningTagSettingsDialog, TagForm } from '@/page/setting/planning/tag/PlanningTagSettingsDialog';
import { handleError } from '@/utils/api.util';
import { ReorderItem, ReorderModal } from '@/components/reorder-modal/ReorderModal';
import { PlanningTag, PlanningTagCreationMutation, PlanningTagUpdateMutation } from '@/domain/planning-tag/PlanningTag.model';
import { mapToReorderRequest } from '@/domain/common';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';

export const PlanningTagSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [sectionDialogMode, setSectionDialogMode] = useState<'create' | 'edit'>('create');
    const [tagDialogOpen, setTagDialogOpen] = useState<boolean>(false);
    const [activeTagData, setActiveTagData] = useState<PlanningTag>();
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
    const [tags, setTags] = useState<PlanningTag[]>([]);

    const fetchTags = () => {
        searchPlanningTags({})
            .then(tags => setTags(tags))
            .catch(handleError);
    };

    useEffect(() => {
        fetchTags();
    }, []);

    const handleDelete = async (params: ICellRendererParams<PlanningTag>) => {
        if (!params.data?.id) {
            return;
        }
        try {
            await deletePlanningTag(params.data.id);
            fetchTags();
        } catch (e) {
            handleError(e);
        }
    };

    const getMoreButton = (params: ICellRendererParams<PlanningTag>) => {
        const menuItems: BasicMenuItem[] = [
            {
                title: t('general.delete'),
                onClick: () => {
                    handleDelete(params);
                },
            },
        ];
        return <BasicMenu items={menuItems} />;
    };

    const config: ConfigType<PlanningTag> = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => {
                setSectionDialogMode('create');
                setTagDialogOpen(true);
            },
            defaultButtonCaption: t('permissions_setting_page.reorder'),
            defaultButtonAction: () => setIsOrderModalOpen(true),
        },
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<PlanningTag>) => {
                    setSectionDialogMode('edit');
                    setActiveTagData(params.data);
                    setTagDialogOpen(true);
                },
            },
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                },
                {
                    type: 'actionMenu',
                    cellRenderer: getMoreButton,
                },
            ],
        },
    };

    const onSaveTag = (orderList: TagForm, id?: number) => {
        setTagDialogOpen(false);
        setActiveTagData(undefined);

        const request: PlanningTagCreationMutation | PlanningTagUpdateMutation = {
            name: orderList.name,
        };

        if (sectionDialogMode === 'create') {
            createPlanningTag(request)
                .then(data => {
                    const existingTags = [...tags];
                    existingTags.push(data);
                    setTags(existingTags);
                    showSnackbar(t('planning_setting_page.shift_tag_added'), 'success');
                })
                .catch(handleError);
        } else if (id) {
            updatePlanningTag(id, request)
                .then(data => {
                    const existingTags = [...tags];
                    const findTagIndex = existingTags.findIndex(tag => tag.id === data.id);
                    existingTags[findTagIndex] = data;
                    setTags(existingTags);
                    showSnackbar(t('planning_setting_page.shift_tag_edited'), 'success');
                })
                .catch(handleError);
        }
    };

    const onCloseTagDialog = () => {
        setTagDialogOpen(false);
        setActiveTagData(undefined);
    };

    const getReorderItems = (tags: PlanningTag[]) => {
        return tags.map((tag, index) => ({
            id: tag.id,
            name: tag.name,
            order: index,
        }));
    };

    const handleOrderSave = async (reorderItems: ReorderItem[]) => {
        const orderRequest = mapToReorderRequest(reorderItems);
        try {
            await updatePlanningTagOrders(orderRequest);
            fetchTags();
        } catch (e) {
            handleError(e);
        } finally {
            setIsOrderModalOpen(false);
        }
    };

    return (
        <>
            <CompanySettingsLayout options={config} data={tags} />
            <PlanningTagSettingsDialog
                open={tagDialogOpen}
                handleSave={onSaveTag}
                closeDialog={onCloseTagDialog}
                activeTagData={activeTagData}
                mode={sectionDialogMode}
            />
            {isOrderModalOpen && (
                <ReorderModal
                    open={true}
                    onSave={handleOrderSave}
                    onClose={() => {
                        setIsOrderModalOpen(false);
                    }}
                    initialReorderItems={getReorderItems(tags)}
                />
            )}
        </>
    );
};
