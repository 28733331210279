import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { getSectionDefinitionSchema } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { checkEmailAvailability } from '@/page/employee-profile/employee-profile-info/EmployeeProfileActionButton/Email.schema';
import { getWorkPatternSchema } from '@/page/employee-profile/employee-profile-info/EmployeeWorkPatternSection/EmployeeWorkPattern.schema';
import { getEmploymentItemSchema, getEmploymentSchema } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';
import { getLocalDateTestConfig } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import invariant from 'tiny-invariant';
import * as yup from 'yup';

export const getOnboardingEmployeeSchema = (sectionDefinitions: SectionDefinition[]) => {
    // BASIC INFO
    const basicInfoSectionDefinition = sectionDefinitions.find(section => section.type === SectionType.BASIC_INFO);
    invariant(basicInfoSectionDefinition, 'Basic info section definition is required');
    const basicInfoSchema = getSectionDefinitionSchema({ sectionDefinition: basicInfoSectionDefinition });

    // enhance email field to check email availability
    const emailFieldId = basicInfoSectionDefinition.fields.find(field => field.fieldType === 'EMPLOYEE_EMAIL')?.id ?? 0;

    const emailSchema = basicInfoSchema.fields[emailFieldId] as yup.StringSchema<string>;
    // Add email availability check
    const enhancedBasicInfoSchema = basicInfoSchema.concat(
        yup.object().shape({
            [emailFieldId]: emailSchema.test(checkEmailAvailability()),
        }),
    );

    // PERSONAL INFO
    const personalInfoSectionDefinition = sectionDefinitions.find(section => section.type === SectionType.PERSONAL_INFO);
    invariant(personalInfoSectionDefinition, 'Personal info section definition is required');
    const personalInfoSchema = getSectionDefinitionSchema({ sectionDefinition: personalInfoSectionDefinition });

    // CONTRACT
    const contractSchema = getContractSchema();

    // EMPLOYMENT
    const employmentSchema = getEmploymentSchema()
        // Employment update reason and start date are not required are defined in contract section
        .omit(['employmentUpdateReason', 'startDate', 'items'])
        // For now onboarding does not support multiple employments
        .concat(getEmploymentItemSchema());

    // WORK PATTERN
    const workingPatterSchema = getWorkPatternSchema().omit(['startDate']);

    const schema = yup
        .object()
        .concat(enhancedBasicInfoSchema)
        .concat(personalInfoSchema)
        .concat(contractSchema)
        .concat(employmentSchema)
        .concat(workingPatterSchema);

    return schema;
};

export type OnboardingProfileStepFormValues = yup.InferType<ReturnType<typeof getOnboardingEmployeeSchema>>;

const getContractSchema = () => {
    return yup
        .object()
        .shape({
            contractStartDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
            contractEndDate: yup.string<LocalDate>().default(getNull()).nullable().test(getLocalDateTestConfig()),
        })
        .concat(getEmploymentSchema().pick(['probationEndDate', 'contractType']));
};
