import { Spinner } from '@/components/spinner/Spinner';
import { EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { mapWorkingDays } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.service';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { OnboardingMutation } from '@/domain/onboarding/Onboarding.model';
import { submitOnboardingForm } from '@/domain/onboarding/Onboarding.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { NUMBER_DAYS_BEFORE_EOT_REVIEW } from '@/domain/review/Review.model';
import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { getFormValueByFieldType } from '@/domain/section-setting/Section.service';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { useGetCalendars } from '@/hooks/calendar/Calendar.hook';
import { CostCenterAssignmentFormValue } from '@/page/cost-center/CostCentersAssignment.schema';
import { handleError } from '@/utils/api.util';
import { subDaysAndFormat } from '@/utils/datetime.util';
import { Box, Stack, useTheme } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { OnboardingEmployeeForm } from '@/page/people/onboarding/employee/OnboardingEmployeeForm';
import { InviteForm, InviteFormValues } from './onboarding/invite/InviteForm';
import { LeavesForm, LeaveTypeFormValues } from './onboarding/leaves/LeavesForm';
import { OnBoardingHeader } from './onboarding/OnBoardingHeader';
import { PlanningForm, PlanningFormValues } from './onboarding/planning/PlanningForm';
import { ReviewsForm, ReviewsFormValues } from './onboarding/reviews/ReviewsForm';
import { useGetWeeklyWorkingTimes } from '@/hooks/weekly-working-time/WeeklyWorkingTime.hook';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { useSearchReviewTemplates } from '@/hooks/review-template/ReviewTemplate.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetPlannings } from '@/hooks/planning/Planning.hook';
import { useGetPlanningPositions } from '@/hooks/planning-position/PlanningPosition.hook';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { OnboardingProfileStepFormValues } from '@/page/people/onboarding/employee/OnboardingEmployeeForm.schema';

export const OnboardingPage: FC = () => {
    const { palette } = useTheme();

    const [baseProfileFormValues, setBaseProfileFormValues] = useState<Partial<OnboardingProfileStepFormValues>>({});
    const [leavesFormValues, setLeavesFormValues] = useState<LeaveTypeFormValues>();
    const [planningFormValues, setPlanningFormValues] = useState<PlanningFormValues>();
    const [reviewFormValues, setReviewFormValues] = useState<ReviewsFormValues>();

    const scrollRef = useRef<HTMLDivElement>();
    const [isActive, handleBackStep, handleNextStep, backButtonAllowed] = useStep();

    const canConfigureLeaves = hasRealmFeatureEnabled(RealmFeaturesType.LEAVES);
    const canConfigurePlanning = hasRealmFeatureEnabled(RealmFeaturesType.PLANNING);
    const canConfigureReviews = hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS);

    const { data: weeklyWorkingTimes = [] } = useGetWeeklyWorkingTimes();
    const { data: fetchedLeaveTypes = [] } = useGetLeaveTypes(undefined, { enabled: canConfigureLeaves });
    const leaveTypes = fetchedLeaveTypes.filter(leaveType => {
        return !!leaveType.policies?.length;
    });
    const { data: reviewTemplates = [], isFetching: isReviewTemplatesFetching } = useSearchReviewTemplates(
        { reviewType: 'ONBOARDING' },
        { enabled: canConfigureReviews },
    );
    const { data: employees = [], isFetching: isEmployeesFetching } = useGetEmployees(undefined, { enabled: canConfigureReviews });
    const { data: plannings = [], isFetching: isPlanningsFetching } = useGetPlannings(undefined, { enabled: canConfigurePlanning });
    const { data: planningPositions = [], isFetching: isPlanningPositionsFetching } = useGetPlanningPositions(undefined, { enabled: canConfigurePlanning });
    const { data: sectionDefinitions = [], isFetching: isSectionDefinitionsFetching } = useGetSectionDefinitions();
    const { data: calendars = [], isFetching: isCalendarsFetching } = useGetCalendars();

    const isLoading =
        isEmployeesFetching ||
        isPlanningsFetching ||
        isReviewTemplatesFetching ||
        isPlanningPositionsFetching ||
        isSectionDefinitionsFetching ||
        isCalendarsFetching;
    const [isSubmittingTheForm, setIsSubmittingTheForm] = useState<boolean>(false);

    const scrollIntoView = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const navigate = useNavigate();

    const onNextStep = () => {
        handleNextStep();
        scrollIntoView();
    };

    const onBackStep = () => {
        handleBackStep();
        scrollIntoView();
    };

    const onSubmitBaseProfileForm: SubmitHandler<OnboardingProfileStepFormValues> = baseProfileFormValuesData => {
        setBaseProfileFormValues(baseProfileFormValuesData);
        onNextStep();
    };

    const onSubmitLeavesForm: SubmitHandler<LeaveTypeFormValues> = leavesFormValuesData => {
        setLeavesFormValues(leavesFormValuesData);
        onNextStep();
    };

    const onSubmitPlanningForm: SubmitHandler<PlanningFormValues> = planningFormValuesData => {
        setPlanningFormValues(planningFormValuesData);
        onNextStep();
    };
    const onSubmitReviewForm: SubmitHandler<ReviewsFormValues> = reviewFormValuesData => {
        setReviewFormValues(reviewFormValuesData);
        onNextStep();
    };

    const onSubmitInviteForm: SubmitHandler<InviteFormValues> = inviteFormValuesData => {
        // At this point, we have all the data we need to create the employee
        const otherFormValues = {
            baseProfileFormValues,
            leavesFormValues,
            planningFormValues,
            reviewFormValues,
            weeklyWorkingTimes,
        };

        const request = formatToOnboardingMutation(inviteFormValuesData, otherFormValues, sectionDefinitions);

        onSubmitOnBoardingForm(request);
    };

    const onSubmitOnBoardingForm = async (request: OnboardingMutation) => {
        setIsSubmittingTheForm(true);
        try {
            const data = await submitOnboardingForm(request);
            navigate(`/profile/${data.id}/personal-info`);
            // TODO - this is a hack, we should refresh the employee data instead of refreshing the page
            // we can't refresh the employee data because the employee data is loaded in the parent component
            // and we don't want to duplicate the logic here
            // change this when we will use redux toolkit or react-query
            // We refresh the page to display the new avatar
            // https://rogerhr.atlassian.net/browse/RP-2014
            navigate(0);
        } catch (error) {
            handleError(error);
        }
        setIsSubmittingTheForm(false);
    };

    const steps = [
        {
            headerKey: 'information',
            formName: 'base-profile-form',
            step: (
                <OnboardingEmployeeForm
                    weeklyWorkingTimes={weeklyWorkingTimes}
                    calendars={calendars}
                    onSubmitBaseProfileForm={onSubmitBaseProfileForm}
                    baseProfileFormValues={baseProfileFormValues}
                    sectionDefinitions={sectionDefinitions}
                />
            ),
        },
    ];

    if (leaveTypes?.length) {
        steps.push({
            headerKey: 'leaves',
            formName: 'leaves-form',
            step: <LeavesForm onSubmitLeavesForm={onSubmitLeavesForm} leavesFormValues={leavesFormValues} leaveTypes={leaveTypes} />,
        });
    }

    if (plannings?.length) {
        steps.push({
            headerKey: 'planning',
            formName: 'planning-form',
            step: <PlanningForm onSubmitForm={onSubmitPlanningForm} plannings={plannings} planningPositions={planningPositions} />,
        });
    }

    if (reviewTemplates?.length) {
        steps.push({
            headerKey: 'reviews',
            formName: 'review-form',
            step: (
                <ReviewsForm
                    onSubmitForm={onSubmitReviewForm}
                    templates={reviewTemplates}
                    employees={employees}
                    defaultValues={{
                        reviewName: createDefaultLabel(),
                        reviewTemplate: undefined,
                        reviewNotificationDate: baseProfileFormValues?.probationEndDate
                            ? subDaysAndFormat(baseProfileFormValues.probationEndDate, NUMBER_DAYS_BEFORE_EOT_REVIEW)
                            : undefined,
                        reviewEndDate: baseProfileFormValues?.probationEndDate ?? undefined,
                        // Pre fill the review manager with the first manager
                        reviewManager: baseProfileFormValues?.managers?.length
                            ? {
                                  value: baseProfileFormValues?.managers?.[0].id,
                                  label: baseProfileFormValues?.managers?.[0].displayName,
                              }
                            : undefined,
                    }}
                />
            ),
        });
    }

    steps.push({
        headerKey: 'access',
        formName: 'invite-form',
        step: <InviteForm onSubmitInviteForm={onSubmitInviteForm} />,
    });

    return (
        <>
            {isLoading && <Spinner />}
            {!isLoading && (
                <Stack gap={2}>
                    <Box ref={scrollRef} position={'sticky'} top={0} zIndex={100} paddingBottom={0} bgcolor={palette.background.default}>
                        <OnBoardingHeader
                            handleBackStep={onBackStep}
                            isActive={isActive}
                            backButtonAllowed={backButtonAllowed}
                            steps={steps}
                            isSubmittingTheForm={isSubmittingTheForm}
                        />
                    </Box>
                    <Stack>
                        {steps.map((step, index) => (
                            <Box key={step.formName} display={isActive(index) ? 'block' : 'none'}>
                                {isActive(index) && step.step}
                            </Box>
                        ))}
                    </Stack>
                </Stack>
            )}
        </>
    );
};

/**
 * Merge all the data from the different forms to create the request to send to the API
 * @param inviteFormValuesData
 * @param otherValues
 * @param sectionDefinitions
 */
const formatToOnboardingMutation = (
    inviteFormValuesData: InviteFormValues,
    otherValues: {
        baseProfileFormValues: OnboardingProfileStepFormValues;
        leavesFormValues: LeaveTypeFormValues | undefined;
        planningFormValues: PlanningFormValues | undefined;
        reviewFormValues: ReviewsFormValues | undefined;
        weeklyWorkingTimes: WeeklyWorkingTime[];
    },
    sectionDefinitions: SectionDefinition[],
): OnboardingMutation => {
    const { baseProfileFormValues, leavesFormValues, planningFormValues, reviewFormValues, weeklyWorkingTimes } = otherValues;
    const selectedWeeklyWorkingTime = weeklyWorkingTimes.find(w => w.id === baseProfileFormValues.weeklyWorkingTimeId);

    return {
        ...formatBaseProfile(baseProfileFormValues, selectedWeeklyWorkingTime, sectionDefinitions),
        leaveTypeAssignments: leavesFormValues?.leaveTypeAssignments,
        sendInvitation: inviteFormValuesData?.sendInvitation,
        loginMethodId: inviteFormValuesData?.loginMethod?.id,
        language: inviteFormValuesData.language,
        ...formatPlanning(planningFormValues),
        ...formatReviews(reviewFormValues),
    };
};

const formatBaseProfile = (
    baseProfileFormValues: OnboardingProfileStepFormValues,
    selectedWeeklyWorkingTime: WeeklyWorkingTime | undefined,
    sectionDefinitions: SectionDefinition[],
) => {
    const basicInfoSection = sectionDefinitions.find(section => section.type === SectionType.BASIC_INFO);
    const personalInfoSection = sectionDefinitions.find(section => section.type === SectionType.PERSONAL_INFO);
    const phoneNumber = getFormValueByFieldType({
        sectionDefinition: basicInfoSection,
        fieldType: 'EMPLOYEE_PHONE_NUMBER',
        formValues: baseProfileFormValues,
    });

    return {
        employeeCode: getFormValueByFieldType({
            sectionDefinition: basicInfoSection,
            fieldType: 'EMPLOYEE_CODE',
            formValues: baseProfileFormValues,
        }),
        contractStartDate: baseProfileFormValues.contractStartDate,
        probationEndDate: baseProfileFormValues.probationEndDate ?? undefined,
        firstName: getFormValueByFieldType({
            sectionDefinition: basicInfoSection,
            fieldType: 'EMPLOYEE_FIRSTNAME',
            formValues: baseProfileFormValues,
            mandatory: true,
        }),
        contractEndDate: baseProfileFormValues?.contractEndDate ?? undefined,
        contractType: baseProfileFormValues.contractType,
        lastName: getFormValueByFieldType({
            sectionDefinition: basicInfoSection,
            fieldType: 'EMPLOYEE_LASTNAME',
            formValues: baseProfileFormValues,
            mandatory: true,
        }),
        maidenName:
            getFormValueByFieldType({
                sectionDefinition: basicInfoSection,
                fieldType: 'EMPLOYEE_MAIDEN_NAME',
                formValues: baseProfileFormValues,
            }) ?? undefined,
        email: getFormValueByFieldType({
            sectionDefinition: basicInfoSection,
            fieldType: 'EMPLOYEE_EMAIL',
            formValues: baseProfileFormValues,
            mandatory: true,
        })?.toLowerCase(),
        gender:
            getFormValueByFieldType({
                sectionDefinition: personalInfoSection,
                fieldType: 'EMPLOYEE_GENDER',
                formValues: baseProfileFormValues,
            }) ?? undefined,
        avsNumber:
            getFormValueByFieldType({
                sectionDefinition: personalInfoSection,
                fieldType: 'EMPLOYEE_AVS',
                formValues: baseProfileFormValues,
            }) ?? undefined,
        phoneNumber: phoneNumber && phoneNumber !== '' ? phoneNumber : undefined,
        birthdate:
            getFormValueByFieldType({
                sectionDefinition: personalInfoSection,
                fieldType: 'EMPLOYEE_BIRTH_DATE',
                formValues: baseProfileFormValues,
            }) ?? undefined,
        nationality: getFormValueByFieldType({
            sectionDefinition: personalInfoSection,
            fieldType: 'EMPLOYEE_NATIONALITY',
            formValues: baseProfileFormValues,
        })?.value,
        maritalStatus:
            getFormValueByFieldType({
                sectionDefinition: personalInfoSection,
                fieldType: 'EMPLOYEE_MARITAL_STATUS',
                formValues: baseProfileFormValues,
            }) ?? undefined,
        maritalStatusSince:
            getFormValueByFieldType({
                sectionDefinition: personalInfoSection,
                fieldType: 'EMPLOYEE_MARITAL_STATUS_SINCE',
                formValues: baseProfileFormValues,
            }) ?? undefined,
        personalEmail:
            getFormValueByFieldType({
                sectionDefinition: personalInfoSection,
                fieldType: 'EMPLOYEE_PERSONAL_EMAIL',
                formValues: baseProfileFormValues,
            }) ?? undefined,
        personalPhoneNumber:
            getFormValueByFieldType({
                sectionDefinition: personalInfoSection,
                fieldType: 'EMPLOYEE_PERSONAL_PHONE_NUMBER',
                formValues: baseProfileFormValues,
            }) ?? undefined,
        departmentId: baseProfileFormValues?.department?.id,
        jobId: baseProfileFormValues?.job?.id,
        jobFamilyId: baseProfileFormValues?.jobFamily?.id,
        locationId: baseProfileFormValues?.location?.id,
        managerIds: baseProfileFormValues?.managers.map((manager: EmployeeAvatar) => manager.id),
        employmentCostCenters: baseProfileFormValues?.employmentCostCenters?.map((costCenter: CostCenterAssignmentFormValue) => ({
            costCenterId: costCenter.costCenter.id,
            percentage: costCenter.percentage,
        })),
        calendarId: baseProfileFormValues.calendarId,
        workingPatternType: baseProfileFormValues?.type,
        rate: baseProfileFormValues?.type === EmployeeWorkingPatternType.RATE ? baseProfileFormValues?.rate : undefined,
        weeklyWorkingTimeId: getWeeklyWorkingTimeId(baseProfileFormValues),
        workingPatternId: baseProfileFormValues?.type === EmployeeWorkingPatternType.TEMPLATE ? baseProfileFormValues?.workingPatternTemplate?.id : undefined,
        startingWeek: baseProfileFormValues?.type === EmployeeWorkingPatternType.TEMPLATE ? baseProfileFormValues?.startingWeek : undefined,
        workingDays:
            baseProfileFormValues?.type === EmployeeWorkingPatternType.FIXED
                ? mapWorkingDays(
                      baseProfileFormValues?.workingDays.morningWorkingDays,
                      baseProfileFormValues?.workingDays.afternoonWorkingDays,
                      selectedWeeklyWorkingTime,
                  )
                : undefined,
        timesheetSettingId: baseProfileFormValues?.timesheetSettingId,
    };
};

const formatPlanning = (planningFormValues: PlanningFormValues | undefined) => {
    const { managerPlannings, memberPlannings, planningPosition } = planningFormValues ?? {};
    return {
        planningPositionId: planningPosition?.value,
        managerPlannings: managerPlannings?.map(planning => planning.value) ?? [],
        memberPlannings: memberPlannings?.map(planning => planning.value) ?? [],
    };
};

const formatReviews = (reviewFormValues: ReviewsFormValues | undefined) => ({
    reviews: reviewFormValues?.organizeMeeting
        ? (reviewFormValues?.reviews ?? [])
              .filter(review => !!review?.reviewTemplate?.value)
              .map(review => ({
                  name: review?.reviewName,
                  reviewTemplateId: review.reviewTemplate.value,
                  managerIds: review?.reviewManager?.value ? [review?.reviewManager?.value] : [],
                  startDate: review?.reviewNotificationDate,
                  endDate: review?.reviewEndDate,
              }))
        : [],
});

const getWeeklyWorkingTimeId = (baseProfileFormValues: OnboardingProfileStepFormValues) => {
    return baseProfileFormValues?.type === EmployeeWorkingPatternType.FIXED || baseProfileFormValues?.type === EmployeeWorkingPatternType.RATE
        ? baseProfileFormValues?.weeklyWorkingTimeId
        : undefined;
};

const useStep = (): [(step: number) => boolean, () => void, () => void, () => boolean] => {
    const [activeStep, setActiveStep] = useState(0);

    const handleBackStep = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleNextStep = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const isActive = (step: number) => {
        return activeStep === step;
    };

    const backButtonAllowed = () => {
        return activeStep > 0;
    };

    return [isActive, handleBackStep, handleNextStep, backButtonAllowed];
};
