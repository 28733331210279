import { getOrganizationBilling } from '@/domain/organization_billing/OrganizationBilling.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const organizationBillingKeys = createQueryKeys('organizationBilling', {
    search: (...params: Parameters<typeof getOrganizationBilling>) => ({
        queryKey: params,
    }),
});

export const useGetOrganizationBilling = createQueryHook(organizationBillingKeys.search, getOrganizationBilling);
