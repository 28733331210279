import { useDraggable, UseDraggableProps } from '@/components/dnd/useDraggable';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-indicator/box';
import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const DraggableItem: FC<PropsWithChildren & UseDraggableProps> = ({ children, id, allowedEdges, isDisabled = false, onDragStart, canDrop }) => {
    const { ref, state } = useDraggable({
        id,
        allowedEdges,
        isDisabled,
        onDragStart,
        canDrop,
    });

    return (
        <Stack direction='row' position='relative'>
            <Stack ref={ref} width='100%' height='100%'>
                {children}
            </Stack>
            {state.type === 'is-dragging-over' && state.closestEdge && <DropIndicator edge={state.closestEdge} gap={`16px`} />}
        </Stack>
    );
};
