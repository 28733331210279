import { getEmployeeSectionRow, getEmployeeSections } from '@/domain/employee-section/EmployeeSection.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeSectionKeys = createQueryKeys('employeeSections', {
    get: (...params: Parameters<typeof getEmployeeSections>) => ({
        queryKey: params,
    }),
    sectionRow: (...params: Parameters<typeof getEmployeeSectionRow>) => ({
        queryKey: params,
    }),
});
export const useGetEmployeeSection = createRequiredParamsQueryHook(employeeSectionKeys.get, getEmployeeSections);

export const useGetEmployeeSectionRow = createRequiredParamsQueryHook(employeeSectionKeys.sectionRow, getEmployeeSectionRow);
