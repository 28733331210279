import { getSurveyById, getSurveyEmployeesIdById, getSurveys } from '@/domain/survey/Survey.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const surveyKeys = createQueryKeys('survey', {
    getById: (...params: Parameters<typeof getSurveyById>) => ({
        queryKey: params,
    }),
    getAll: {
        queryKey: getNull(),
    },
    surveyEmployeesId: (...params: Parameters<typeof getSurveyEmployeesIdById>) => ({
        queryKey: params,
    }),
});

export const useGetSurvey = createRequiredParamsQueryHook(surveyKeys.getById, getSurveyById);

export const useGetSurveys = createQueryHook(surveyKeys.getAll, getSurveys);

export const useGetSurveyEmployeesIds = createQueryHook(surveyKeys.surveyEmployeesId, getSurveyEmployeesIdById);
