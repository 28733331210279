import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { Button, Paper, Stack } from '@mui/material';
import { Add01Icon } from 'hugeicons-react';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { useGetExpenseTaxes } from '@/hooks/expense-tax/ExpenseTax.hook';
import { createExpenseTax, deleteExpenseTax, updateExpenseTax } from '@/domain/expense-tax/ExpenseTax.service';
import { ExpenseTax } from '@/domain/expense-tax/ExpenseTax.model';
import { ExpenseTaxDialog, ExpenseTaxFormValues } from '@/page/setting/expense/expense-tax/ExpenseTaxSettingsDialog';
import { ExpenseTaxesGrid } from '@/page/setting/expense/expense-tax/ExpenseTaxSettingsGrid';
import { useDialogState } from '@/hooks/useDialog.hook';

export const ExpenseTaxSettingsPage: FC = () => {
    const { t } = useTranslation();
    const { data: taxes = [], refetch: refetchTaxes, isLoading: isTaxesLoading } = useGetExpenseTaxes();
    const { setGridRef, quickFilter } = useAgGridWrapper<ExpenseTax>();

    const { state: taxDialogState, open: openTaxDialog, close: closeTaxDialog } = useDialogState<ExpenseTax>();

    const onDeleteTax = async (tax: ExpenseTax) => {
        try {
            await deleteExpenseTax(tax.id);
            showSnackbar(t('expense_settings_page.expense_deleted_succeed'), 'success');
            await refetchTaxes();
        } catch (error) {
            handleError(error);
        }
    };

    const onSaveTax = async (data: ExpenseTaxFormValues) => {
        try {
            if (taxDialogState?.data?.id) {
                await updateExpenseTax(taxDialogState.data.id, data);
            } else {
                await createExpenseTax(data);
            }
            showSnackbar(t('expense_settings_page.tax_saved_succeed'), 'success');
            closeTaxDialog();
            await refetchTaxes();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center' component={Paper} p={1}>
                <Stack direction='row' gap={1}>
                    <DatatableAdditionalAction quickFilter={quickFilter} />
                    <Button variant='contained' onClick={() => openTaxDialog()} startIcon={<Add01Icon />}>
                        {t('expense_settings_page.tax')}
                    </Button>
                </Stack>
            </Stack>
            <ExpenseTaxesGrid taxes={taxes} loading={isTaxesLoading} onRowClick={openTaxDialog} onDeleteTax={onDeleteTax} initRef={setGridRef} />
            {taxDialogState?.open && <ExpenseTaxDialog open={taxDialogState.open} tax={taxDialogState.data} onClose={closeTaxDialog} onSave={onSaveTax} />}
        </Stack>
    );
};
