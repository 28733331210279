import AnonymousImg from '@/assets/images/anonymous-lock-screen.png';
import logo from '@/assets/images/logo.svg';
import { desktopVisible } from '@/theme/responsive';
import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployeeSurveyHeader: FC<unknown> = () => {
    const { t } = useTranslation();
    return (
        <Stack component={Paper} square direction='row' justifyContent='space-between' bgcolor='background.default' width={'100%'}>
            <Stack direction='row' gap={1} flexWrap='nowrap' alignItems='center' sx={desktopVisible} p={2}>
                <Stack
                    component={'img'}
                    src={logo}
                    style={{
                        width: 45,
                        height: 49,
                    }}
                    alt='Anonymous'
                />
                <Typography align='center' color='textPrimary'>
                    {t('general.brand.title')}
                </Typography>
            </Stack>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent={'space-between'}
                flex={{
                    xs: 1,
                    sm: 'none',
                }}
                gap={2}
                p={2}
            >
                <Typography variant='body1'>{t('anonymous_surveys.your_answers_are_anonymous')}</Typography>
                <Stack
                    component={'img'}
                    src={AnonymousImg}
                    width={{
                        xs: 40,
                        sm: 60,
                    }}
                    alt='Anonymous'
                />
            </Stack>
        </Stack>
    );
};
