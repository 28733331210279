import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import {
    AnalyticsUpIcon,
    BankIcon as PayrollIcon,
    Calendar03Icon as LeavesIcon,
    CheckmarkBadge02Icon,
    Clock01Icon as TimesheetsIcon,
    DashboardSquare02Icon as PlanningIcon,
    DocumentValidationIcon,
    File01Icon as DocumentIcon,
    Home03Icon as HomeIcon,
    Layout3ColumnIcon as ReportIcon,
    Settings02Icon,
    StarIcon as ReviewsIcon,
    Target01Icon as ObjectivesIcon,
    UserIcon as ProfileIcon,
    UserMultiple02Icon as EmployeesIcon,
    Wallet02Icon,
} from 'hugeicons-react';

import {
    canAccessOtherEmployeeReviews,
    canApproveDeclineExpenses,
    canConfigureCompanySettings,
    canManageOtherEmployeeObjectives,
    canManageOtherEmployeeTimesheets,
    canManagePayroll,
    canPayExpenseRequests,
    canSeeOtherEmployeeLeaves,
    canSeeOtherEmployeeSkills,
    canSeeSurveyResults,
    canViewCompanyDocuments,
    canViewDashboard,
    canViewEmployeesDirectory,
    canViewReports,
    canViewReviews,
    canViewShifts,
} from '@/domain/permission/Permission.service';
import { getDashboardPages } from '@/page/dashboard/DashboardPages';
import { getDocumentPages } from '@/page/document/DocumentPages';
import { useGetProfilePages } from '@/page/employee-profile/EmployeeProfilePages.hook';
import { getEmployeeSkillPages } from '@/page/employee-skill/EmployeeSkillPages';
import { getHomePages, homePage } from '@/page/home/pending/HomePages';
import { getLeavePages } from '@/page/leave/LeavePages';
import { useGetObjectivePages } from '@/page/objective/objectives-page/ObjectivePages';
import { getPayrollPages } from '@/page/payroll/PayrollPages';
import { getPeoplePages } from '@/page/people/PeoplesPages';
import { getPlanningPages } from '@/page/planning/PlanningPages';
import { getReportPages } from '@/page/report/ReportPages';
import { getReviewPages } from '@/page/review/ReviewPages';
import { getSettingsPages } from '@/page/setting/CompanySettingsPages';
import { getSurveyPages } from '@/page/survey/SurveyPages';
import { getTimesheetPages } from '@/page/timesheet/TimesheetPages';
import { useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import { Location } from 'react-router';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useGetExpensePages } from '@/page/expense/useGetExpensePages';

export type Page = {
    path: string;
    labelKey: string;
    condition?: (policies: EmployeePolicy[]) => boolean;
};

export type PagesGroupConfig = {
    pages: Page[];
    icon: JSX.Element;
    labelKey: string;
    canSee: boolean;
};

export const myProfileSidebarLabelKey = 'sidebar.my_profile';
export const managePeopleSidebarLabelKey = 'sidebar.manage_people';

// TODO : make all function to get pages as a hook https://rogerhr.atlassian.net/browse/RP-5963
export const useGetPagesGroupsConfig = (): PagesGroupConfig[] => {
    const employeeId = useCurrentEmployee().id;
    const policies = useCurrentPolicies();

    const profilePages = useGetProfilePages();
    const expensePages = useGetExpensePages();

    return [
        {
            pages: getHomePages(),
            icon: <HomeIcon />,
            labelKey: homePage.labelKey,
            canSee: true,
        },
        {
            pages: profilePages,
            icon: <ProfileIcon />,
            labelKey: myProfileSidebarLabelKey,
            canSee: true,
        },
        {
            pages: getPeoplePages(employeeId, policies),
            icon: <EmployeesIcon />,
            labelKey: managePeopleSidebarLabelKey,
            canSee: canViewEmployeesDirectory(policies, employeeId),
        },
        {
            pages: getLeavePages(employeeId, policies),
            icon: <LeavesIcon />,
            labelKey: 'sidebar.manage_leaves',
            canSee: canSeeOtherEmployeeLeaves(policies, employeeId),
        },
        {
            pages: getPlanningPages(),
            // PlanningIcon size seams to be wrong, so we use size 23
            icon: <PlanningIcon size={23} />,
            labelKey: 'sidebar.planning',
            canSee: canViewShifts(policies),
        },
        {
            pages: getTimesheetPages(employeeId, policies),
            icon: <TimesheetsIcon />,
            labelKey: 'sidebar.timesheets',
            canSee: canManageOtherEmployeeTimesheets(policies, employeeId),
        },
        {
            pages: expensePages,
            icon: <Wallet02Icon />,
            labelKey: 'sidebar.expenses',
            canSee: canApproveDeclineExpenses(policies, employeeId) || canPayExpenseRequests(policies, employeeId),
        },
        {
            pages: getDocumentPages(),
            icon: <DocumentIcon />,
            labelKey: 'sidebar.company_documents',
            canSee: canViewCompanyDocuments(policies),
        },
        {
            pages: getSurveyPages(policies),
            icon: <DocumentValidationIcon />,
            labelKey: 'sidebar.surveys',
            canSee: canSeeSurveyResults(policies),
        },
        {
            pages: getReviewPages(policies),
            icon: <ReviewsIcon />,
            labelKey: 'sidebar.reviews',
            canSee: canAccessOtherEmployeeReviews(policies, employeeId) || canViewReviews(policies),
        },
        {
            pages: useGetObjectivePages(),
            icon: <ObjectivesIcon />,
            labelKey: 'sidebar.objectives',
            canSee: canManageOtherEmployeeObjectives(policies, employeeId),
        },
        {
            pages: getEmployeeSkillPages(employeeId, policies),
            icon: <CheckmarkBadge02Icon />,
            labelKey: 'sidebar.employee_skills',
            canSee: canSeeOtherEmployeeSkills(policies, employeeId),
        },
        {
            pages: getPayrollPages(policies, employeeId),
            icon: <PayrollIcon />,
            labelKey: 'sidebar.payroll',
            canSee: canManagePayroll(policies, employeeId),
        },
        {
            pages: getReportPages(),
            icon: <ReportIcon />,
            labelKey: 'sidebar.reports',
            canSee: canViewReports(policies),
        },
        {
            pages: getDashboardPages(),
            icon: <AnalyticsUpIcon />,
            labelKey: 'sidebar.dashboard',
            canSee: hasRealmFeatureEnabled(RealmFeaturesType.BETA) && canViewDashboard(policies, employeeId),
        },
        {
            pages: getSettingsPages(),
            icon: <Settings02Icon />,
            labelKey: 'sidebar.company_settings',
            canSee: canConfigureCompanySettings(policies),
        },
    ].filter(({ canSee }) => canSee);
};

// Add the beta page urls here
const BETA_PAGE_URLS = ['/dashboard', '/people/employment-mass-edit'];
export const isBetaPage = (location: Location): boolean => {
    return BETA_PAGE_URLS.includes(location.pathname);
};
