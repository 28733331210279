import * as yup from 'yup';
import {
    IntegrationType,
    ThirdParty,
    ThirdPartyIntegration,
    ThirdPartyPublicApiIntegration,
    ThirdPartyPublicApiIntegrationScope,
} from '@/domain/third-party/ThirdParty.model';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getNull } from '@/utils/object.util';
import { ThirdPartyConnectorDialog, ThirdPartyConnectorFormValues } from '@/page/setting/third-party/third-party-connector-dialog/ThirdPartyConnectorDialog';
import { ThirdPartyEmailDialog, ThirdPartyNewEmailFormValues } from '@/page/setting/third-party/third-party-monitoring-email/ThirdPartyMonitoringEmailDialog';
import { Button, Chip, FormControlLabel, FormHelperText, Paper, Stack, Typography } from '@mui/material';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { FieldText } from '@/components/form/field-text/FieldText';
import { Add01Icon } from 'hugeicons-react';
import { ThirdPartyConnectorsGrid } from '@/page/setting/third-party/third-party-connectors-grid/ThirdPartyConnectorsGrid';
import { ThirdPartySyncDialog } from '@/page/setting/third-party/third-party-setting-page/ThirdPartySyncDialog';
import { ThirdPartyConnectorLogDialog } from '@/page/setting/third-party/third-party-connector-log-dialog/ThirdPartyConnectorLogDialog';
import { ThirdPartyApiKeyDialog } from '@/page/setting/third-party/third-party-api-key-dialog/ThirdPartyApiKeyDialog';
import { Footer } from '@/page/layout/Footer';
import i18next from 'i18next';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

export type ThirdPartyFormValues = yup.InferType<ReturnType<typeof getThirdPartySchema>>;

export type ThirdPartyConnector =
    | (Pick<ThirdPartyPublicApiIntegration, 'scopes' | 'expireAt'> & {
          id: number | null;
          description?: string | null;
      })
    | (Pick<ThirdPartyIntegration, 'configuration' | 'integrationType'> & { id: number | null });

const scopeSchema = yup.string().required().oneOf(Object.values(ThirdPartyPublicApiIntegrationScope));
const getThirdPartySchema = () =>
    yup.object().shape({
        name: yup.string().trim().required(),
        emails: yup
            .array()
            .min(1, i18next.t('third_party_setting_page.dialog.monitoring_email_validation'))
            .default([])
            .of(yup.string().email().required())
            .required(),
        publicApiIntegration: yup
            .object()
            .default(getNull())
            .shape({
                id: yup.number().nullable().default(getNull()),
                scopes: yup.array().of(scopeSchema).required(),
                expireAt: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
                description: yup.string().nullable(),
            })
            .nullable(),
        integrations: yup
            .array()
            .default([])
            .required()
            .of(
                yup.object().shape({
                    id: yup.number().nullable().default(getNull()),
                    configuration: yup.string().required(),
                    integrationType: yup.string().required().oneOf(Object.values(IntegrationType)),
                    description: yup.string().nullable(),
                }),
            ),
    });

const filterOutEmail = (emails: string[], index: number) => emails.filter((_, i) => i !== index);

export const ThirdPartySettingForm: FC<{
    thirdParty: ThirdParty | undefined;
    onSave: (data: ThirdPartyFormValues) => Promise<ThirdParty | undefined>;
}> = ({ thirdParty, onSave }) => {
    const { t } = useTranslation();

    const [createConnectorDialog, setCreateConnectorDialog] = useState(false);
    const [createMonitoringEmail, setCreateMonitoringEmail] = useState(false);
    const [updateConnectorDialog, setUpdateConnectorDialog] = useState<ThirdPartyConnector>();
    const [logConnectorDialog, setLogConnectorDialog] = useState<ThirdPartyConnector>();
    const [generateApiKeyDialog, setGenerateApiKeyDialog] = useState(false);
    const [isThirdPartySyncDialogOpen, setIsThirdPartySyncDialogOpen] = useState(false);

    const { control, handleSubmit, setValue, watch } = useForm<ThirdPartyFormValues>({
        resolver: yupResolver(getThirdPartySchema()),
        defaultValues: {
            name: thirdParty?.name ?? '',
            emails: thirdParty?.emails ?? [],
            publicApiIntegration: thirdParty?.publicApiIntegration?.id
                ? {
                      id: thirdParty.publicApiIntegration.id ?? getNull(),
                      scopes: thirdParty.publicApiIntegration?.scopes ?? [],
                      expireAt: thirdParty.publicApiIntegration?.expireAt,
                      description: thirdParty.publicApiIntegration?.description ?? '',
                  }
                : getNull(),
            integrations: thirdParty?.integrations ?? [],
        },
    });

    const publicApiIntegration = watch('publicApiIntegration');

    const integrations = watch('integrations');

    const emails = watch('emails') ?? [];

    // Merge public api integration with integrations to have a single array
    const connectors: ThirdPartyConnector[] = [publicApiIntegration, ...integrations].filter(connector => connector !== getNull());

    const publicApiIntegrationExisting = !!publicApiIntegration;

    const handleApiIntegrationSave = async (connector: ThirdPartyConnectorFormValues) => {
        if (!connector.expireAt) {
            throw new Error('Expire date is required');
        }
        const newPublicApiIntegration: ThirdPartyFormValues['publicApiIntegration'] = {
            id: publicApiIntegration?.id ?? getNull(),
            scopes: connector.scopes,
            expireAt: connector.expireAt,
            description: connector.description ?? '',
        };

        // It's weird behavior but after touching a connector we want to automatically save the connector in the third party
        const response = await onSave({
            name: thirdParty?.name ?? '',
            emails: thirdParty?.emails ?? [],
            integrations: thirdParty?.integrations ?? [],
            publicApiIntegration: newPublicApiIntegration,
        });

        // It's a new public api integration and we want to generate the api key
        if (!publicApiIntegration?.id && response?.publicApiIntegration?.id) {
            setGenerateApiKeyDialog(true);
            // We update the public api integration with the id to avoid double key generation after an other save
            setValue('publicApiIntegration', { ...newPublicApiIntegration, id: response?.publicApiIntegration?.id });
        } else {
            setValue('publicApiIntegration', newPublicApiIntegration);
        }
    };

    const handleConnectorSave = async (connector: ThirdPartyConnectorFormValues) => {
        if (connector.type === 'PUBLIC_API') {
            handleApiIntegrationSave(connector);
        } else {
            // We are using id as key to identify the connector EXCEPT for public api or new connectors that will have undefined id
            const previousConnector = getPreviousConnector(connector);

            // Could be an update or a new connector
            const newConnector: ThirdPartyFormValues['integrations'][0] = {
                id: previousConnector?.id ?? getNull(),
                configuration: connector.configuration ?? '',
                integrationType: connector.type,
                description: connector.description ?? '',
            };

            // If we have a previous connector we update it
            // Otherwise we add a new one at the end
            const newIntegrations = previousConnector ? integrations.map(c => (c === previousConnector ? newConnector : c)) : [...integrations, newConnector];

            setValue('integrations', newIntegrations);

            // It's weird behavior but after touching a connector we want to automatically save the connector in the third party
            await onSave({
                name: thirdParty?.name ?? '',
                emails: thirdParty?.emails ?? [],
                integrations: newIntegrations,
                publicApiIntegration: publicApiIntegration,
            });
        }
    };

    const getPreviousConnector = (connector: ThirdPartyConnectorFormValues) => {
        if (connector.type === 'PUBLIC_API') {
            //we can only have 1 public api connector so if it's existing we return it
            return integrations.find(c => c.integrationType === connector.type);
        }
        if (connector.id) {
            return integrations.find(c => c.id === connector.id);
        }
        return undefined;
    };

    const handleCreate = (connector: ThirdPartyConnectorFormValues) => {
        handleConnectorSave(connector);
        setCreateConnectorDialog(false);
    };

    const handleUpdate = (connector: ThirdPartyConnectorFormValues) => {
        handleConnectorSave(connector);
        setUpdateConnectorDialog(undefined);
    };

    const handleDeleteConnector = (connector: ThirdPartyConnector) => {
        if ('scopes' in connector) {
            setValue('publicApiIntegration', getNull());
        } else {
            setValue(
                'integrations',
                integrations.filter(c => c.id !== connector.id),
            );
        }
    };

    const handleAddEmail = (newEmail: ThirdPartyNewEmailFormValues) => {
        setValue('emails', [...emails, newEmail.email]);
        setCreateMonitoringEmail(false);
    };

    return (
        <>
            <Stack component={ContentContainer} flex={1} gap={2}>
                <Stack component={Paper} gap={2} p={3}>
                    <Typography variant='body1bold'>{t('third_party_setting_page.dialog.title')}</Typography>
                    <FormControlLabel
                        aria-label={'third-party-name'}
                        label={t('third_party_setting_page.dialog.name_input')}
                        control={<FieldText name='name' control={control} fullWidth />}
                    />

                    <Stack direction='column' gap={2}>
                        <Stack direction='row' gap={2}>
                            <Button startIcon={<Add01Icon />} variant='outlined' onClick={() => setCreateMonitoringEmail(true)}>
                                {t('third_party_setting_page.dialog.monitoring_email')}
                            </Button>
                            {createMonitoringEmail && <ThirdPartyEmailDialog onSave={handleAddEmail} open onClose={() => setCreateMonitoringEmail(false)} />}
                        </Stack>

                        <Stack direction='row' gap={1}>
                            <Controller
                                control={control}
                                name='emails'
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <>
                                        {value.map((email, index) => (
                                            <Chip color='default' key={email} label={email} onDelete={() => onChange(filterOutEmail(value, index))} />
                                        ))}
                                        {error && <FormHelperText error>{error.message}</FormHelperText>}
                                    </>
                                )}
                            />
                        </Stack>
                    </Stack>
                </Stack>

                {/* We want to handle connectors only if third party is existing */}
                {thirdParty && (
                    <Stack component={Paper} flex={1} gap={2} p={3}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography variant='body1bold'>{t('third_party_setting_page.dialog.connectors')}</Typography>
                            <Button onClick={() => setCreateConnectorDialog(true)} size='small'>
                                {t('third_party_setting_page.dialog.add_connector')}
                            </Button>
                            {createConnectorDialog && (
                                <ThirdPartyConnectorDialog
                                    onSave={handleCreate}
                                    open
                                    onClose={() => setCreateConnectorDialog(false)}
                                    publicApiOptionDisabled={publicApiIntegrationExisting}
                                />
                            )}
                        </Stack>

                        {!!connectors?.length && (
                            <ThirdPartyConnectorsGrid
                                connectors={connectors}
                                onDelete={handleDeleteConnector}
                                onClickShowLog={setLogConnectorDialog}
                                onRowClicked={setUpdateConnectorDialog}
                                onClickEmployeeSync={() => setIsThirdPartySyncDialogOpen(true)}
                            />
                        )}

                        {isThirdPartySyncDialogOpen && <ThirdPartySyncDialog open={true} onClose={() => setIsThirdPartySyncDialogOpen(false)} />}

                        {updateConnectorDialog && (
                            <ThirdPartyConnectorDialog
                                onSave={handleUpdate}
                                connector={updateConnectorDialog}
                                open
                                onClose={() => setUpdateConnectorDialog(undefined)}
                                onGenerateApiKey={() => setGenerateApiKeyDialog(true)}
                            />
                        )}

                        {logConnectorDialog && (
                            <ThirdPartyConnectorLogDialog connector={logConnectorDialog} open onClose={() => setLogConnectorDialog(undefined)} />
                        )}

                        {generateApiKeyDialog && <ThirdPartyApiKeyDialog thirdPartyId={thirdParty.id} open onClose={() => setGenerateApiKeyDialog(false)} />}
                    </Stack>
                )}
            </Stack>
            <Footer>
                <Button onClick={handleSubmit(onSave, console.error)} variant='contained' color='primary'>
                    {t(thirdParty ? 'general.update' : 'general.create')}
                </Button>
            </Footer>
        </>
    );
};
