import { EmployeeSkill, EmployeeSkillSearchRequest } from '@/domain/employee-skill/EmployeeSkill.model';
import { employeeSkillAPI } from '@/api/employee-skill/EmployeeSkill.api';
import { getGroupedSkillsByCategoryId } from '@/domain/skill/Skill.service';
import { SkillCategory } from '@/domain/skill-category/SkillCategory.model';
import { Skill } from '@/domain/skill/Skill.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';

export const getEmployeeSkills = (employeeId: number): Promise<EmployeeSkill[]> => {
    return employeeSkillAPI.getEmployeeSkills(employeeId);
};

export const searchEmployeeSkills = (searchRequest: EmployeeSkillSearchRequest): Promise<EmployeeSkill[]> => {
    return employeeSkillAPI.searchEmployeeSkills(searchRequest);
};

export const getEmployeeSkillsGroupedByCategoryId = (
    employeeSkills: EmployeeSkill[],
): {
    category: SkillCategory;
    groupedSkills: EmployeeSkill[];
}[] => {
    const skills = employeeSkills.map(({ skill }) => skill);
    const groupedSkills = getGroupedSkillsByCategoryId(skills);

    return groupedSkills.map(group => ({
        category: group.category,
        groupedSkills: employeeSkills.filter(({ skill }) => skill.category.id === group.category.id).sort((a, b) => (b?.score ?? 0) - (a?.score ?? 0)),
    }));
};

export const getSkillsFromEmployeeSkills = (employeeSkills: EmployeeSkill[]): Skill[] => {
    return employeeSkills.reduce<Skill[]>((acc, { skill }) => {
        if (!acc.some(({ id }) => id === skill.id)) {
            return [...acc, skill];
        }
        return acc;
    }, []);
};

type EmployeeSkillsGroupedByEmployee = {
    employee: EmployeeAvatar;
    employeeSkills: EmployeeSkill[];
};

export const groupEmployeeSkillsByEmployeeAvatar = (employeeSkills: EmployeeSkill[]): EmployeeSkillsGroupedByEmployee[] => {
    return employeeSkills.reduce<
        {
            employee: EmployeeAvatar;
            employeeSkills: EmployeeSkill[];
        }[]
    >((acc, employeeSkill) => {
        const existingGroup = acc.find(({ employee }) => employee.id === employeeSkill.employee.id);

        if (existingGroup) {
            existingGroup.employeeSkills.push(employeeSkill);
        } else {
            acc.push({ employee: employeeSkill.employee, employeeSkills: [employeeSkill] });
        }

        return acc;
    }, []);
};

type EmployeeSkillsGroupedBySkill = {
    skill: Skill;
    employeeSkills: EmployeeSkill[];
};

export const groupEmployeeSkillsBySkill = (employeeSkills: EmployeeSkill[]): EmployeeSkillsGroupedBySkill[] => {
    return employeeSkills.reduce<{ skill: Skill; employeeSkills: EmployeeSkill[] }[]>((acc, employeeSkill) => {
        const existingGroup = acc.find(({ skill }) => skill.id === employeeSkill.skill.id);

        if (existingGroup) {
            existingGroup.employeeSkills.push(employeeSkill);
        } else {
            acc.push({ skill: employeeSkill.skill, employeeSkills: [employeeSkill] });
        }

        return acc;
    }, []);
};
