import { EmployeeReviewFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { FeedbackItemForm } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { Objective } from '@/domain/objective/Objective.model';
import { Employee } from '@/domain/employee/Employee.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Paper, Stack, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FeedbackFormPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackFormPDF';
import { isManagerHasPreparationAccess } from '@/domain/employee-review/EmployeeReview.service';
import { FeedbackViewFeedbacks } from '@/page/review/employee-review-feedback-form/FeedbackViewFeedbacks';
import { EmployeeReviewAvatar } from '@/page/review/employee-review-avatar/EmployeeReviewAvatar';
import { getLabelTranslation } from '@/utils/language.util';
import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { Job } from '@/domain/job/Job.model';

type FeedbackFormPropsHeader = {
    feedback: EmployeeReviewFeedback | undefined;
    feedbackItems: FeedbackItemForm[];
    actions: EmployeeReviewAction[];
    employeeReview: EmployeeReview;
    isPreview: boolean;
    objectives: Objective[];
    currentEmployee: Employee;
    jobs: Job[];
};

export const FeedbackFormHeader: FC<FeedbackFormPropsHeader> = ({
    feedback,
    employeeReview,
    isPreview,
    objectives,
    currentEmployee,
    feedbackItems,
    actions,
    jobs,
}) => {
    const { t } = useTranslation();

    const getReviewFormTitleName = (employeeReview: EmployeeReview | undefined, feedback: EmployeeReviewFeedback | undefined) => {
        if (isPreview && employeeReview?.review) {
            return getLabelTranslation(employeeReview.review.name);
        }
        if (feedback) {
            return getLabelTranslation(feedback.reviewName);
        }
    };

    return (
        <Stack component={Paper}>
            {feedback && (
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                    <Typography variant='h2'>{getReviewFormTitleName(employeeReview, feedback)}</Typography>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        {isPreview && <Chip color='warning' size='small' label={t('reviews.write_feedback.preview_mode')} />}
                        <Button>
                            <PDFDownloadLink
                                style={{ textDecoration: 'none' }}
                                document={
                                    <FeedbackFormPDF
                                        feedback={feedback}
                                        feedbackItems={feedbackItems}
                                        employeeReview={employeeReview}
                                        actions={actions}
                                        objectives={objectives ?? []}
                                        jobs={jobs}
                                    />
                                }
                            >
                                <Typography color={'white'}>{t('reviews.review_summary.export')}</Typography>
                            </PDFDownloadLink>
                        </Button>
                        {employeeReview && currentEmployee && isManagerHasPreparationAccess(employeeReview, currentEmployee) && (
                            <FeedbackViewFeedbacks employeeReview={employeeReview} />
                        )}
                    </Stack>
                </Stack>
            )}

            <Stack direction={'row'} justifyContent={'space-between'}>
                {feedback?.employee && <EmployeeReviewAvatar employee={feedback?.employee} />}
            </Stack>
        </Stack>
    );
};
