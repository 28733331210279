import { useState } from 'react';

export const useDialogState = <T>(): {
    state: { open: boolean; data?: T } | undefined;
    open: (data?: T) => void;
    close: () => void;
} => {
    const [state, setState] = useState<{ open: boolean; data?: T } | undefined>(undefined);

    const open = (data?: T) => {
        setState({ open: true, data });
    };

    const close = () => {
        setState(undefined);
    };

    return {
        state,
        open,
        close,
    };
};
