import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { ColorList } from '@/components/color-list/ColorList';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldText } from '@/components/form/field-text/FieldText';
import { OptionsBlock } from '@/components/options-block/OptionsBlock';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { UnitType } from '@/domain/date/Date.model';
import { getUnitTypeTranslationKey } from '@/domain/date/Date.service';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { REQUEST_INCREMENT } from '@/domain/leave-request/LeaveRequest.service';
import {
    AllowanceType,
    CarryoverType,
    DisplayCounterArray,
    getLeaveActivityTypeTranslationKey,
    LeaveActivityType,
    LeaveType,
    LeaveTypePolicy,
    LeaveTypeUpdateMutation,
    RequestIncrement,
} from '@/domain/leave-type/LeaveType.model';
import { createNewLeaveType, getAllowanceText, updateLeaveType } from '@/domain/leave-type/LeaveType.service';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer } from '@/page/layout/Footer';
import { LeavePolicyDialog } from '@/page/setting/time-management/leave-setting-dialog/LeavePolicyDialog';
import { handleError } from '@/utils/api.util';
import { convertMinutesToDays, formatDuration } from '@/utils/datetime.util';
import { getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { getRoundingTypeTranslationKey, RoundingType } from '@/utils/math.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Collapse, FormControlLabel, Paper, PaperProps, Stack, Switch, Typography } from '@mui/material';
import { Add01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { Controller, FormProvider, Resolver, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { getNull } from '@/utils/object.util';
import { ColDef } from 'ag-grid-enterprise';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';

type LeaveTypeFormValues = MakeOptional<
    Omit<LeaveType, 'maxDurationInMinutes' | 'id' | 'order' | 'leaveCreationMinimumBalanceMinutes'>,
    'showContinuousDuration'
> & {
    withMaxDuration: boolean;
    maxDuration?: number;
    leaveCreationMinimumBalanceMinutes?: number | null;
};

type LeaveTypeFormProps = {
    leaveType?: LeaveType;
    order: number;
};

export const LeaveTypeSettingForm: FC<LeaveTypeFormProps> = ({ leaveType, order }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [translationLanguage, setTranslationLanguage] = useState<UserLanguage>(getRealmLanguage());

    const isEdit = !!leaveType;

    const handleTranslationLanguageChange = (language: UserLanguage) => {
        setTranslationLanguage(language);
    };

    let defaultLeaveType: LeaveTypeFormValues | undefined;

    if (leaveType) {
        const {
            maxDurationInMinutes = 0, // We default to 0 to avoid undefined
            ...restLeaveType
        } = leaveType;
        const withMaxDuration = !!maxDurationInMinutes;

        const maxDuration = restLeaveType.unitType === UnitType.HOURS ? maxDurationInMinutes / 60 : convertMinutesToDays(maxDurationInMinutes);

        defaultLeaveType = {
            ...restLeaveType,
            name: restLeaveType.name ?? createDefaultLabel(),
            color: restLeaveType.color ?? undefined,
            employeeRequestMessage: restLeaveType.employeeRequestMessage ?? '',
            withMaxDuration,
            maxDuration: maxDuration || undefined,
            leaveCreationMinimumBalanceMinutes: getLeaveCreationMinimumBalanceMinutes(restLeaveType.leaveCreationMinimumBalanceMinutes, restLeaveType.unitType),
        };
    }

    const resolver: Resolver<LeaveTypeFormValues> = yupResolver(getLeaveTypeFormSchema(translationLanguage));

    const formMethods = useForm<LeaveTypeFormValues>({
        resolver,
        defaultValues: {
            // TODO: remove this field from form leave type settings https://rogerhr.atlassian.net/jira/software/projects/RP/issues/RP-4105
            includeInPayroll: false,
            allowAttachments: false,
            withMaxDuration: false,
            unitType: UnitType.DAYS,
            displayUnitType: UnitType.DAYS,
            allowanceType: AllowanceType.NOT_UNLIMITED,
            displayCounter: 'SHOW_IF_THERE_IS_LEAVES',
            color: '#685fff',
            autoApproveRequest: false,
            showContinuousDuration: false,
        },
        values: defaultLeaveType,
    });

    const { handleSubmit } = formMethods;

    const onSave = (data: LeaveTypeFormValues) => {
        const { maxDuration = 0, ...rest } = data;

        const { unitType, displayUnitType, allowanceType, withMaxDuration, attachmentRequiredAfterDays } = rest;

        const getLeaveCreationMinimumBalanceMinutes = () => {
            if (allowanceType === AllowanceType.UNLIMITED) {
                return undefined;
            }
            if (rest.leaveCreationMinimumBalanceMinutes !== undefined && rest.leaveCreationMinimumBalanceMinutes !== getNull()) {
                return unitType === UnitType.HOURS ? rest.leaveCreationMinimumBalanceMinutes * 60 : rest.leaveCreationMinimumBalanceMinutes * 60 * 24;
            }
            return undefined;
        };

        const maxDurationInMinutes = unitType === UnitType.HOURS ? maxDuration * 60 : maxDuration * 60 * 24;
        const carryoverType = allowanceType === AllowanceType.UNLIMITED ? CarryoverType.YEARLY_NO_CARRYOVER : rest.carryoverType;
        const roundingType = displayUnitType === UnitType.HOURS ? RoundingType.NEAREST_2_DECIMALS : rest.roundingType;
        const showContinuousDuration = allowanceType === AllowanceType.UNLIMITED && rest.showContinuousDuration;

        const request: LeaveTypeUpdateMutation = {
            ...rest,
            maxDurationInMinutes: withMaxDuration ? maxDurationInMinutes : undefined,
            leaveCreationMinimumBalanceMinutes: getLeaveCreationMinimumBalanceMinutes(),
            carryoverType,
            roundingType,
            order,
            showContinuousDuration: !!showContinuousDuration,
            attachmentRequiredAfterDays: rest.allowAttachments ? attachmentRequiredAfterDays : undefined,
        };

        if (isEdit) {
            updateLeaveType(request, leaveType.id)
                .then(() => {
                    navigate('/settings/time-management/leaves');
                })
                .catch(handleError);
        } else {
            createNewLeaveType(request)
                .then(() => {
                    navigate('/settings/time-management/leaves');
                })
                .catch(handleError);
        }
    };

    const commonPaperProps: PaperProps = {
        sx: {
            p: 2,
            display: 'flex',
            flex: 1,
            gap: 2,
            flexDirection: 'column',
        },
    };

    return (
        <FormProvider {...formMethods}>
            <ContentContainer>
                <Stack flex={1} gap={2}>
                    <Paper {...commonPaperProps}>
                        <About translationLanguage={translationLanguage} handleTranslationLanguageChange={handleTranslationLanguageChange} />
                    </Paper>
                    <Paper {...commonPaperProps}>
                        <Request />
                    </Paper>
                    <Paper {...commonPaperProps}>
                        <BalanceTracking />
                    </Paper>
                </Stack>
            </ContentContainer>
            <Footer>
                <Button onClick={handleSubmit(onSave, console.error)} variant='contained'>
                    {t(isEdit ? 'general.update' : 'general.create')}
                </Button>
            </Footer>
        </FormProvider>
    );
};

const getLeaveTypeFormSchema = (translationLanguage?: UserLanguage) => {
    return yup.object<LeaveTypeFormValues>().shape({
        name: getLabelFormSchema(translationLanguage),
        color: yup.string().default('#685fff').required(),
        allowanceType: yup.string().oneOf(Object.values(AllowanceType)).required(),
        leaveActivityType: yup.string().oneOf(Object.values(LeaveActivityType)).required(),
        showContinuousDuration: yup.boolean().when(['unit', 'allowanceType'], {
            is: (unit: UnitType, allowanceType: AllowanceType) => unit !== UnitType.HOURS && allowanceType == AllowanceType.UNLIMITED,
            then: schema => schema.required(),
        }),
        policies: yup.array().required(),
        unitType: yup.string().oneOf(Object.values(UnitType)).required(),
        displayUnitType: yup.string().oneOf(Object.values(UnitType)).required(),
        requestIncrement: yup
            .string()
            .oneOf(Object.values(RequestIncrement))
            .when(['unitType'], {
                is: (unitType: UnitType) => unitType !== UnitType.DAYS,
                then: schema => schema.required(),
                otherwise: schema => schema.nullable(),
            }),
        roundingType: yup.string().oneOf(Object.values(RoundingType)).required(),
        displayCounter: yup.string().oneOf(DisplayCounterArray).required(),
        employeeRequestMessage: yup.string().trim(),
        withMaxDuration: yup.boolean().required(),
        maxDuration: yup.number().when(['withMaxDuration'], {
            is: (withMaxDuration: boolean) => withMaxDuration,
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        carryoverType: yup.string().oneOf(Object.values(CarryoverType)).required(),
        prorateBasedOnContract: yup.boolean().required(),
        prorateBasedOnEmployeeStartDate: yup.boolean().required(),
        allowAttachments: yup.boolean().required(),
        availableInSelfService: yup.boolean().required(),
        allowOvertime: yup.boolean().required(),
        attachmentRequiredAfterDays: yup.number().when(['allowAttachments'], {
            is: (allowAttachments: boolean) => allowAttachments,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable(),
        }),
        includeInPayroll: yup.boolean().required(),
        leaveCreationMinimumBalanceMinutes: yup.number().nullable(),
        autoApproveRequest: yup.boolean().required(),
    });
};

type AboutProps = {
    translationLanguage: UserLanguage;
    handleTranslationLanguageChange: (language: UserLanguage) => void;
};

const About: FC<AboutProps> = ({ translationLanguage, handleTranslationLanguageChange }) => {
    const { t } = useTranslation();

    const { control } = useFormContext<LeaveTypeFormValues>();

    return (
        <>
            <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                <Typography variant='body1bold'>{t('add_leave_policy_dialog.section_title_about_leave')}</Typography>
                <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={handleTranslationLanguageChange} />
            </Stack>
            <FieldLabel
                name='name'
                control={control}
                defaultValue={createDefaultLabel()}
                fullWidth
                language={translationLanguage}
                label={t('new_leave_type_dialog.leave_name')}
            />

            <FormControlLabel
                label={t('new_leave_type_dialog.activity')}
                control={
                    <FieldSelect
                        name='leaveActivityType'
                        control={control}
                        fullWidth
                        options={Object.values(LeaveActivityType)}
                        getOptionLabel={leaveActivityType => t(getLeaveActivityTypeTranslationKey(leaveActivityType))}
                    />
                }
            />

            <FormControlLabel
                label={t('new_leave_type_dialog.employee_request_message')}
                control={<FieldText name='employeeRequestMessage' control={control} fullWidth textFieldProps={{ multiline: true, minRows: 4 }} />}
            />

            <Typography variant='body2'>{t('new_leave_type_dialog.leave_color')}</Typography>

            <Controller
                name='color'
                control={control}
                render={({ field: { value: color, onChange } }) => (
                    <Stack direction='row'>
                        <ColorList color={color} onChangeColor={onChange} />
                    </Stack>
                )}
            />
        </>
    );
};

const Request: FC = () => {
    const { t } = useTranslation();

    const { control, watch } = useFormContext<LeaveTypeFormValues>();

    const unitType = watch('unitType');
    const displayUnitType = watch('displayUnitType');
    const allowAttachments = watch('allowAttachments');
    const withMaxDuration = watch('withMaxDuration');

    const DEFAULT_ATTACHMENT_REQUIRED_AFTER_DAYS = 3;
    const DEFAULT_MAX_DURATION = 5;

    const autoApproveRequest = watch('autoApproveRequest');

    return (
        <>
            <Typography variant='body1bold'>{t('add_leave_policy_dialog.section_title_request')}</Typography>

            <OptionsBlock title={t('add_leave_policy_dialog.document_upload')} description={t('add_leave_policy_dialog.document_upload_description')}>
                <Controller
                    name='allowAttachments'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            label={value ? t('general.enabled') : t('general.disabled')}
                            labelPlacement='end'
                            control={<Switch color='primary' checked={value} onChange={onChange} name='Require Attachments' />}
                        />
                    )}
                />
            </OptionsBlock>

            {!!allowAttachments && (
                <FormControlLabel
                    label={t('new_leave_type_dialog.medical_cert_mandatory_after_days')}
                    control={
                        <FieldNumber name='attachmentRequiredAfterDays' defaultValue={DEFAULT_ATTACHMENT_REQUIRED_AFTER_DAYS} control={control} fullWidth />
                    }
                />
            )}

            <OptionsBlock title={t('new_leave_type_dialog.unit')} description={t('new_leave_type_dialog.unit_description')}>
                <FieldSelect
                    name='unitType'
                    control={control}
                    disableClearable
                    fullWidth
                    options={[UnitType.DAYS, UnitType.HOURS, UnitType.BOTH]}
                    getOptionLabel={unit => t(getUnitTypeTranslationKey(unit))}
                />
            </OptionsBlock>

            <OptionsBlock title={t('new_leave_type_dialog.display_unit')} description={t('new_leave_type_dialog.display_unit_description')}>
                <FieldSelect
                    name='displayUnitType'
                    control={control}
                    disableClearable
                    fullWidth
                    options={[UnitType.DAYS, UnitType.HOURS, UnitType.BOTH]}
                    getOptionLabel={unit => t(getUnitTypeTranslationKey(unit))}
                />
            </OptionsBlock>

            <Collapse in={displayUnitType === UnitType.DAYS || displayUnitType === UnitType.BOTH}>
                <DailyLeaveType unit={unitType} />
            </Collapse>
            <Collapse in={unitType === UnitType.HOURS || unitType === UnitType.BOTH}>
                <HourlyLeaveType />
            </Collapse>

            <OptionsBlock
                title={t('new_leave_type_dialog.maximum_duration_title', { context: unitType })}
                description={t('new_leave_type_dialog.maximum_duration_description', { context: unitType })}
            >
                <Controller
                    name='withMaxDuration'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            label={!withMaxDuration ? t('general.disabled') : t('general.enabled')}
                            labelPlacement='end'
                            control={<Switch color='primary' checked={value} onChange={onChange} name='Enable Max Duration Days' />}
                        />
                    )}
                />
            </OptionsBlock>

            {!!withMaxDuration && <FieldNumber name='maxDuration' control={control} defaultValue={DEFAULT_MAX_DURATION} fullWidth />}

            <OptionsBlock
                title={t('new_leave_type_dialog.available_in_self_service_label')}
                description={t('new_leave_type_dialog.available_in_self_service_description')}
            >
                <Controller
                    name='availableInSelfService'
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value, ...restField } }) => (
                        <FormControlLabel
                            label={t('new_leave_type_dialog.available_in_self_service_label')}
                            labelPlacement='end'
                            control={<Checkbox {...restField} checked={value} onChange={onChange} />}
                        />
                    )}
                />
            </OptionsBlock>

            <OptionsBlock title={t('add_leave_policy_dialog.allow_overtime_title')} description={t('add_leave_policy_dialog.allow_overtime_description')}>
                <Controller
                    name='allowOvertime'
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            label={value ? t('general.enabled') : t('general.disabled')}
                            labelPlacement='end'
                            control={<Switch color='primary' checked={value} onChange={onChange} />}
                        />
                    )}
                />
            </OptionsBlock>

            <OptionsBlock title={t('add_leave_policy_dialog.auto_approve_title')} description={t('add_leave_policy_dialog.auto_approve_description')}>
                <FieldSwitch
                    name={'autoApproveRequest'}
                    labelPlacement='end'
                    label={autoApproveRequest ? t('general.enabled') : t('general.disabled')}
                    control={control}
                />
            </OptionsBlock>
        </>
    );
};

const DailyLeaveType: FC<{ unit: UnitType }> = ({ unit }) => {
    const { t } = useTranslation();

    const { control, watch } = useFormContext<LeaveTypeFormValues>();
    const allowanceType = watch('allowanceType');

    const displayContinuousDuration = unit !== UnitType.HOURS && allowanceType == AllowanceType.UNLIMITED;
    return (
        <Stack gap={2}>
            {displayContinuousDuration && (
                <OptionsBlock
                    title={t('new_leave_type_dialog.show_continuous_duration')}
                    description={t('new_leave_type_dialog.show_continuous_duration_description')}
                >
                    <Controller
                        name='showContinuousDuration'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                                label={value ? t('general.enabled') : t('general.disabled')}
                                labelPlacement='end'
                                control={<Switch color='primary' checked={value} onChange={onChange} name='show continuous duration' />}
                            />
                        )}
                    />
                </OptionsBlock>
            )}
            <OptionsBlock title={t('new_leave_type_dialog.rounding_title')} description={t('new_leave_type_dialog.rounding_description')}>
                <FieldSelect
                    name='roundingType'
                    control={control}
                    defaultValue={RoundingType.NEAREST_1_DECIMAL}
                    options={Object.values(RoundingType)}
                    disableClearable
                    fullWidth
                    getOptionLabel={option => t(getRoundingTypeTranslationKey(option))}
                />
            </OptionsBlock>
        </Stack>
    );
};

const HourlyLeaveType: FC = () => {
    const { t } = useTranslation();

    const { control } = useFormContext<LeaveTypeFormValues>();

    const getRequestIncrementLabel = (increment: RequestIncrement) => {
        const minutes = Number(increment.split('_')[1]);
        return formatDuration(minutes);
    };

    return (
        <OptionsBlock
            title={t('leave_types_settings_page.overtime.request_increment')}
            description={t('leave_types_settings_page.overtime.request_increment_desc')}
        >
            <FieldSelect
                name='requestIncrement'
                control={control}
                defaultValue={RequestIncrement.MIN_5}
                disableClearable
                fullWidth
                options={REQUEST_INCREMENT}
                getOptionLabel={getRequestIncrementLabel}
            />
        </OptionsBlock>
    );
};

const BalanceTracking: FC = () => {
    const { t } = useTranslation();

    const { control, watch } = useFormContext<LeaveTypeFormValues>();

    const leaveActivityType = watch('leaveActivityType');
    const allowanceType = watch('allowanceType');

    return (
        <>
            <Typography variant='body1bold'>{t('add_leave_policy_dialog.section_title_balance')}</Typography>
            <OptionsBlock
                title={
                    leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION
                        ? t('add_leave_policy_dialog.allowance_compensation')
                        : t('add_leave_policy_dialog.allowance')
                }
                description={
                    leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION
                        ? t('add_leave_policy_dialog.description_compensation')
                        : t('add_leave_policy_dialog.description')
                }
            >
                <Controller
                    name='allowanceType'
                    control={control}
                    render={({ field: { value, onChange, ...restField } }) => (
                        <FormControlLabel
                            label={value === AllowanceType.NOT_UNLIMITED ? t('general.enabled') : t('general.disabled')}
                            labelPlacement='end'
                            control={
                                <Switch
                                    {...restField}
                                    color='primary'
                                    checked={value === AllowanceType.NOT_UNLIMITED}
                                    onChange={() => {
                                        onChange(value === AllowanceType.NOT_UNLIMITED ? AllowanceType.UNLIMITED : AllowanceType.NOT_UNLIMITED);
                                    }}
                                    name='Allowance Type'
                                />
                            }
                        />
                    )}
                />
            </OptionsBlock>

            <Collapse in={allowanceType === AllowanceType.NOT_UNLIMITED && leaveActivityType !== LeaveActivityType.TIMESHEET_COMPENSATION}>
                <LimitedLeaveType />
            </Collapse>
            <OptionsBlock title={t('new_leave_type_dialog.display_counter')} description={t('new_leave_type_dialog.display_counter_description')}>
                <FieldSelect
                    name='displayCounter'
                    control={control}
                    disableClearable
                    fullWidth
                    options={DisplayCounterArray}
                    getOptionLabel={displayCounter => t('new_leave_type_dialog.display_counter_enum', { context: displayCounter })}
                />
            </OptionsBlock>
        </>
    );
};

const LimitedLeaveType: FC = () => {
    const { t } = useTranslation();
    const { control, watch, setValue } = useFormContext<LeaveTypeFormValues>();

    const unitType = watch('unitType');
    const roundingType = watch('roundingType');
    const policies = watch('policies');

    const [isCreatePolicyDialogOpen, setIsCreatePolicyDialogOpen] = useState<boolean>(false);
    const [leavePolicyToUpdate, setLeavePolicyToUpdate] = useState<LeaveTypePolicy>();

    const columnDefs: ColDef<LeaveTypePolicy>[] = [
        {
            field: 'name',
            headerName: t('new_leave_type_dialog.table_headers.policy_name'),
        },
        {
            field: 'leaveType',
            headerName: t('new_leave_type_dialog.table_headers.annual_allowance'),
            valueFormatter: ({ data }) => (data ? getAllowanceText(data, unitType, roundingType) : ''),
        },
    ];

    const handleAddNewPolicy = (policy: LeaveTypePolicy) => {
        // We use the name to determine if the policy is new or existing
        const newPolicies = [...policies, policy];
        setValue('policies', newPolicies, { shouldDirty: true, shouldValidate: true });
    };

    const handleUpdatePolicy = (policy: LeaveTypePolicy) => {
        // We can't use the id because it could be undefined (new leave type)
        const existing = policies.findIndex(p => JSON.stringify(p) === JSON.stringify(leavePolicyToUpdate));
        if (existing !== undefined) {
            const newPolicies = [...policies];
            newPolicies[existing] = policy;
            setValue('policies', newPolicies, { shouldDirty: true });
        }
    };

    const handleDeletePolicy = () => {
        setValue(
            'policies',
            // We can't use the id because it could be undefined (new leave type)
            policies.filter(p => JSON.stringify(p) !== JSON.stringify(leavePolicyToUpdate)),
            { shouldDirty: true },
        );
    };

    return (
        <Stack gap={2}>
            <OptionsBlock title={t('add_leave_policy_dialog.carryover')} description={t('add_leave_policy_dialog.carryover_description')}>
                <Controller
                    name='carryoverType'
                    control={control}
                    defaultValue={CarryoverType.YEARLY_WITH_CARRYOVER}
                    render={({ field: { value, onChange, ...restField } }) => (
                        <FormControlLabel
                            label={t('add_leave_policy_dialog.carryover_checkbox_label')}
                            labelPlacement='end'
                            control={
                                <Checkbox
                                    {...restField}
                                    checked={value === CarryoverType.YEARLY_WITH_CARRYOVER}
                                    onChange={event => onChange(event.target.checked ? CarryoverType.YEARLY_WITH_CARRYOVER : CarryoverType.YEARLY_NO_CARRYOVER)}
                                />
                            }
                        />
                    )}
                />
            </OptionsBlock>
            <OptionsBlock
                title={t('add_leave_policy_dialog.prorate_based_on_employee_contract_working_days')}
                description={t('add_leave_policy_dialog.prorate_based_on_employee_contract_working_days_description')}
            >
                <Controller
                    name='prorateBasedOnContract'
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value, ...restField } }) => (
                        <FormControlLabel
                            label={t('add_leave_policy_dialog.prorate_based_on_employee_contract_working_days_label')}
                            labelPlacement='end'
                            control={<Checkbox {...restField} checked={value} onChange={onChange} />}
                        />
                    )}
                />
            </OptionsBlock>
            <OptionsBlock
                title={t('add_leave_policy_dialog.prorate_based_on_employee_contract_start_date')}
                description={t('add_leave_policy_dialog.prorate_based_on_employee_contract_start_date_description')}
            >
                <Controller
                    name='prorateBasedOnEmployeeStartDate'
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value, ...restField } }) => (
                        <FormControlLabel
                            label={t('add_leave_policy_dialog.prorate_based_on_employee_contract_start_date_label')}
                            labelPlacement='end'
                            control={<Checkbox {...restField} checked={value} onChange={onChange} />}
                        />
                    )}
                />
            </OptionsBlock>
            <OptionsBlock
                title={t('add_leave_policy_dialog.leave_creation_minimum_balance', { context: unitType === UnitType.BOTH ? UnitType.DAYS : unitType })}
                description={t('add_leave_policy_dialog.leave_creation_minimum_balance_description')}
            >
                <Controller
                    name='leaveCreationMinimumBalanceMinutes'
                    control={control}
                    render={({ field }) => <FieldNumber control={control} fullWidth {...field} />}
                />
            </OptionsBlock>

            <Controller
                name='policies'
                control={control}
                defaultValue={[]}
                render={({ field: { value: policies } }) => (
                    <Stack gap={2}>
                        <Stack direction='row-reverse'>
                            <Button startIcon={<Add01Icon />} variant='contained' size='small' onClick={() => setIsCreatePolicyDialogOpen(true)}>
                                {t('new_leave_type_dialog.add_policy')}
                            </Button>
                        </Stack>
                        <Stack height={getTableHeight({ rowsLength: policies.length })} minHeight={200}>
                            <AgGridWrapper<LeaveTypePolicy>
                                columnDefs={columnDefs}
                                rowData={policies}
                                statusBar={undefined}
                                onRowClicked={({ data }) => setLeavePolicyToUpdate(data)}
                                loading={false}
                            />
                        </Stack>
                    </Stack>
                )}
            />

            {isCreatePolicyDialogOpen && (
                <LeavePolicyDialog open={true} onClose={() => setIsCreatePolicyDialogOpen(false)} unitType={unitType} onSave={handleAddNewPolicy} />
            )}

            {leavePolicyToUpdate && (
                <LeavePolicyDialog
                    open={true}
                    defaultPolicy={leavePolicyToUpdate}
                    onClose={() => setLeavePolicyToUpdate(undefined)}
                    unitType={unitType}
                    onSave={handleUpdatePolicy}
                    onDelete={handleDeletePolicy}
                />
            )}
        </Stack>
    );
};

const getLeaveCreationMinimumBalanceMinutes = (leaveCreationMinimumBalanceMinutes: number | undefined, unitType: UnitType) => {
    if (leaveCreationMinimumBalanceMinutes === undefined || leaveCreationMinimumBalanceMinutes === getNull()) {
        return undefined;
    }
    return unitType === UnitType.HOURS ? leaveCreationMinimumBalanceMinutes / 60 : convertMinutesToDays(leaveCreationMinimumBalanceMinutes);
};
