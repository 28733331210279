import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { hasManageThirdPartiesLogsPolicy } from '@/domain/permission/Permission.service';
import { useCurrentPolicies } from '@/stores/store';
import { ThirdPartyConnector } from '../third-party-setting-page/ThirdPartySettingForm';

export const ThirdPartyConnectorsGrid: FC<{
    connectors: ThirdPartyConnector[];
    onDelete: (connector: ThirdPartyConnector) => void;
    onClickShowLog: (connector: ThirdPartyConnector) => void;
    onClickEmployeeSync: () => void;
    onRowClicked: (connector: ThirdPartyConnector) => void;
}> = ({ connectors, onDelete, onClickShowLog, onClickEmployeeSync, onRowClicked }) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();

    const menuRenderer = (connector: ThirdPartyConnector) => {
        const rowActions: BasicMenuItem[] = [
            {
                title: t('general.delete'),
                onClick: () => onDelete(connector),
                confirmationRequired: true,
            },
            {
                title: t('third_party.connector_logs.show_button'),
                onClick: () => onClickShowLog(connector),
                hide: !hasManageThirdPartiesLogsPolicy(policies),
            },
            {
                title: t('third_party.connector_logs.sync_button'),
                onClick: onClickEmployeeSync,
            },
        ];

        return <BasicMenu items={rowActions} />;
    };

    const columnDefs: ColDef<ThirdPartyConnector>[] = [
        {
            colId: 'type',
            headerName: t('third_party.connectors.type'),
            valueGetter: params => {
                if (!params.data) {
                    return;
                }
                if ('scopes' in params.data) {
                    return 'PUBLIC_API';
                }
                if ('integrationType' in params.data) {
                    return params.data.integrationType;
                }
            },
            valueFormatter: ({ value }: { value: string }) => t('third_party_setting_page.connectors_type', { context: value, defaultValue: value }) ?? '',
        },
        {
            field: 'scopes',
            flex: 1,
            maxWidth: 500,
            headerName: t('third_party.connectors.scopes'),
            valueFormatter: ({ value: scopes }: { value: string[] }) => {
                if (!scopes) {
                    return '';
                }
                return scopes
                    .map(scope =>
                        t('third_party.connectors.scope_type', {
                            context: scope,
                            defaultValue: scope,
                        }),
                    )
                    .join(', ');
            },
        },
        {
            field: 'expireAt',
            headerName: t('third_party.connectors.expire_at'),
        },
        {
            field: 'description',
            headerName: t('third_party.connectors.description'),
        },
        {
            type: 'actionMenu',
            cellRenderer: (params: ICellRendererParams<ThirdPartyConnector>) => (params?.data ? menuRenderer(params.data) : undefined),
        },
    ];

    columnDefs.push();

    const handleRowClicked = ({ data, event }: RowClickedEvent<ThirdPartyConnector>) => (!event?.defaultPrevented && data ? onRowClicked(data) : undefined);

    return (
        <Stack flex={1}>
            <AgGridWrapper rowData={connectors} columnDefs={columnDefs} disableAutoSize statusBar={undefined} onRowClicked={handleRowClicked} loading={false} />
        </Stack>
    );
};
