import { isDraggableItem } from '@/components/dnd/DraggableItem.util';
import { getNull } from '@/utils/object.util';
import { attachClosestEdge, Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

export type UseDraggableProps = {
    id: string;
    allowedEdges: Edge[];
    isDisabled?: boolean;
    onDragStart?: () => void;
    canDrop?: (data: { id: string }) => boolean;
};

type DraggableHookResult = {
    ref: React.RefObject<HTMLDivElement>;
    state: DragAndDropState;
};

export const useDraggable = ({ id, allowedEdges, isDisabled = false, onDragStart, canDrop = () => true }: UseDraggableProps): DraggableHookResult => {
    const ref = useRef<HTMLDivElement | null>(getNull());
    const [state, setState] = useState<DragAndDropState>(idle);
    useEffect(() => {
        const el = ref.current;
        invariant(el);

        return combine(
            draggable({
                element: el,
                getInitialData: () => ({ id }),
                onDragStart() {
                    setState({ type: 'is-dragging' });
                    onDragStart?.();
                },
                onDrop() {
                    setState(idle);
                },
                canDrag: () => !isDisabled,
            }),
            dropTargetForElements({
                element: el,
                canDrop({ source }) {
                    // not allowing dropping on yourself
                    if (source.element === el) {
                        return false;
                    }

                    // only allowing item to be dropped on me
                    if (!isDraggableItem(source.data)) {
                        return false;
                    }

                    return canDrop(source.data);
                },
                getData({ input }) {
                    return attachClosestEdge(
                        { id },
                        {
                            element: el,
                            input,
                            allowedEdges,
                        },
                    );
                },
                getIsSticky() {
                    return true;
                },

                onDragEnter({ self }) {
                    const closestEdge = extractClosestEdge(self.data);
                    setState({ type: 'is-dragging-over', closestEdge });
                },
                onDrag({ self }) {
                    const closestEdge = extractClosestEdge(self.data);

                    // Only need to update react state if nothing has changed.
                    // Prevents re-rendering.
                    setState(current => {
                        if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
                            return current;
                        }
                        return { type: 'is-dragging-over', closestEdge };
                    });
                },
                onDragLeave() {
                    setState(idle);
                },
                onDrop() {
                    setState(idle);
                },
            }),
        );
    }, [allowedEdges, canDrop, id, isDisabled, onDragStart]);

    return { ref, state };
};

type DragAndDropState =
    | {
          type: 'idle';
      }
    | {
          type: 'is-dragging';
      }
    | {
          type: 'is-dragging-over';
          closestEdge: Nullable<Edge>;
      };

const idle: DragAndDropState = { type: 'idle' };
