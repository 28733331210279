import { sendErrorInBugsnag } from '@/utils/api.util';
import {
    MutationFunction,
    QueryClient,
    skipToken,
    useMutation,
    UseMutationOptions,
    UseMutationResult as RQUseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult as RQUseQueryResult,
} from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { isDefined } from '@/utils/collections.util';

/**
 * While waiting to use a library like React Query or SWR, we use this type to represent the result of a query.
 * @deprecated
 */
export declare type UseQueryResult<TData = unknown, TError = unknown> = {
    data: TData | undefined;
    error: TError | null;
    isError: boolean;
    isLoading: boolean;
    isFetching?: boolean;
    // This will be replaced by cache api like with react-query
    setData: Dispatch<SetStateAction<TData | undefined>>;
    refetch: () => Promise<void>;
};

/**
 * @deprecated use createMutationHook to build the hook
 */
export interface UseMutationResult<TData = unknown, TVariables = void, TError = unknown> {
    mutate: (variables: TVariables) => Promise<TData>;
    isPending: boolean;
    isError: boolean;
    error: TError | null;
}

/**
 * @deprecated waiting for the implementation of the builder of infinite query
 */
export type UseInfiniteQueryResult<TData = unknown, TError = unknown> = UseQueryResult<TData, TError> & {
    fetchNextPage: () => Promise<void>;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    // totalCount is not inspired by react-query, but it's a common need in our app
    totalCount: number;
};

type KeyFactory = Parameters<typeof createQueryKeys>[1][number];
type QueryOptions<TData> = MakeOptional<UseQueryOptions<TData>, 'queryKey' | 'queryFn'>;

export const createQueryHook = <TData, TOptionalParams = undefined>(
    queryKeyInfo: KeyFactory,
    queryFn: (params: TOptionalParams, options?: RequestOptions) => Promise<TData>,
    defaultOptions?: QueryOptions<TData>,
) => {
    return (queryParams?: TOptionalParams, options?: QueryOptions<TData>): RQUseQueryResult<TData> => {
        const queryKey = getQueryKey(queryKeyInfo, undefined, queryParams);
        return useQuery({
            queryKey,
            queryFn: async ({ signal }) => {
                try {
                    return await queryFn(queryParams ?? ({} as TOptionalParams), { signal });
                } catch (error) {
                    // We don't want to catch axios errors, they are already handled by the axios interceptor
                    if (!isAxiosError(error)) {
                        sendErrorInBugsnag(error);
                    }
                    // Error will be handled by react-query,
                    // Read throwOnError option in react-query documentation
                    throw error;
                }
            },
            ...{ ...defaultOptions, ...options },
        });
    };
};

export const createRequiredParamsQueryHook = <TData, TRequiredParams, TOptionalParams>(
    queryKeyInfo: KeyFactory,
    queryFn: (requiredParams: TRequiredParams, params?: TOptionalParams, options?: RequestOptions) => Promise<TData>,
    defaultOptions?: QueryOptions<TData>,
) => {
    return (
        requiredParams: TRequiredParams | undefined,
        {
            queryParams,
            options,
        }: {
            queryParams?: TOptionalParams;
            options?: QueryOptions<TData>;
        } = {},
    ): RQUseQueryResult<TData> => {
        return useQuery({
            queryKey: getQueryKey(queryKeyInfo, requiredParams, queryParams), // Include params to uniquely identify the query
            queryFn: requiredParams
                ? async ({ signal }) => {
                      try {
                          return await queryFn(requiredParams, queryParams, { signal });
                      } catch (error) {
                          // We don't want to catch axios errors, they are already handled by the axios interceptor
                          if (!isAxiosError(error)) {
                              sendErrorInBugsnag(error);
                          }

                          // Error will be handled by react-query,
                          // Read throwOnError option in react-query documentation
                          throw error;
                      }
                  }
                : skipToken,
            ...defaultOptions,
            ...options,
        });
    };
};

type MutationOptions<TData, TVariables> = StrictOverwrite<
    MakeOptional<UseMutationOptions<TData, Error, TVariables>, 'mutationFn'>,
    {
        // Overwrite onSuccess to add queryClient
        onSuccess?: (params: {
            data: TData;
            variables: TVariables;
            context: unknown;
            queryClient: QueryClient;
        }) => ReturnType<NonNullable<UseMutationOptions['onSuccess']>>;
    }
>;
export const createMutationHook = <TData, TVariables>(mutationFn: MutationFunction<TData, TVariables>, defaultOptions?: MutationOptions<TData, TVariables>) => {
    return (options?: MutationOptions<TData, TVariables>): RQUseMutationResult<TData, Error, TVariables> => {
        const queryClient = useQueryClient();

        const onSuccess = options?.onSuccess ?? defaultOptions?.onSuccess;

        return useMutation({
            mutationFn: async variables => {
                try {
                    return await mutationFn(variables);
                } catch (error) {
                    // We don't want to catch axios errors, they are already handled by the axios interceptor
                    if (!isAxiosError(error)) {
                        sendErrorInBugsnag(error);
                    }
                    // Error will be handled by react-query,
                    // Read throwOnError option in react-query documentation
                    throw error;
                }
            },
            ...defaultOptions,
            ...options,
            // if needs, we can add a custom onError, onSettled, onMutate, etc. to pass the queryClient
            onSuccess: (data, variables, context) => {
                onSuccess?.({ data, variables, context, queryClient });
            },
        });
    };
};

// function to get query key from the factory that use createQueryKeys
const getQueryKey = <TRequiredParams, TParams>(queryKeyInfo: KeyFactory, requiredParams?: TRequiredParams, queryParams?: TParams): readonly unknown[] => {
    if (!queryKeyInfo) {
        throw new Error('Invalid query key.');
    }

    const definedParams = [requiredParams, queryParams].filter(isDefined);
    const queryKeyInfoResult = typeof queryKeyInfo === 'function' ? queryKeyInfo(...definedParams) : queryKeyInfo;
    if (!('queryKey' in queryKeyInfoResult) || !queryKeyInfoResult.queryKey) {
        throw new Error('Invalid query key result.');
    }
    return queryKeyInfoResult.queryKey;
};

/**
 * Default query options are overridden in global configuration (AppEntryPoint.tsx)
 *
 * This variable is used to rollback to default options in case of a specific query.
 * Should be used on new queries or migrated queries.
 */
export const defaultQueryOptions = {
    gcTime: 5000,
    refetchOnWindowFocus: true,
};
