import { createRequiredParamsQueryHook } from '@/page/Query.type';
import {
    getEmployeeReviewActions,
    getEmployeeReviewActionSummary,
    getEmployeeReviewManagerActionSummary,
    getEmployeeReviewSelfActionSummary,
} from '@/domain/employee-review-action/EmployeeReviewAction.service';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeeReviewActionKeys = createQueryKeys('employeeReviewActions', {
    get: (...params: Parameters<typeof getEmployeeReviewActions>) => ({
        queryKey: params,
    }),
    actionSummary: (...params: Parameters<typeof getEmployeeReviewActionSummary>) => ({
        queryKey: params,
    }),
    actionSelfSummary: (...params: Parameters<typeof getEmployeeReviewSelfActionSummary>) => ({
        queryKey: params,
    }),
    actionManagerSummary: (...params: Parameters<typeof getEmployeeReviewManagerActionSummary>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeReviewActions = createRequiredParamsQueryHook(employeeReviewActionKeys.get, getEmployeeReviewActions);

export const useGetEmployeeReviewActionSummary = createRequiredParamsQueryHook(employeeReviewActionKeys.actionSummary, getEmployeeReviewActionSummary);
export const useGetEmployeeReviewActionSelfSummary = createRequiredParamsQueryHook(
    employeeReviewActionKeys.actionSelfSummary,
    getEmployeeReviewSelfActionSummary,
);
export const useGetEmployeeReviewAction = createRequiredParamsQueryHook(employeeReviewActionKeys.actionManagerSummary, getEmployeeReviewManagerActionSummary);
