import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { getAppConfig } from '@/config/config';
import { ThirdPartyIntegrationLog } from '@/domain/third-party/ThirdParty.model';
import { formatDate } from '@/utils/datetime.util';
import { Chip, DialogContent, IconButton, Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { ColDef } from 'ag-grid-enterprise';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams } from 'ag-grid-community';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { updateThirdPartyIntegrationLogsFixed } from '@/domain/third-party/ThirdParty.service';
import { RefreshIcon } from 'hugeicons-react';
import { FilterType } from '@/components/filters-bar/FilterBar.type';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getBooleanFilterValueByKey, getSelectFilterStringValuesByKey } from '@/components/filters-bar/FiltersBar.util';

type ThirdPartyLogGridProps = {
    thirdPartyIntegrationLogs: ThirdPartyIntegrationLog[];
    refetch?: () => void;
};

const config = getAppConfig();

export const ThirdPartyLogGrid: FC<ThirdPartyLogGridProps> = ({ thirdPartyIntegrationLogs, refetch }) => {
    const { t } = useTranslation();

    const [filters, setFilters] = useFilters();

    const columnDefs: ColDef<ThirdPartyIntegrationLog>[] = [
        {
            colId: 'status',
            headerName: t('third_party.connector_logs.status'),
            cellRenderer: StatusCell,
        },
        {
            field: 'eventType',
            headerName: t('third_party.connector_logs.event_type'),
        },
        {
            field: 'messageId',
            headerName: t('third_party.connector_logs.message_id'),
            hide: true,
        },
        {
            field: 'message',
            headerName: t('third_party.connector_logs.message'),
            maxWidth: 500,
            wrapText: true,
            autoHeight: true,
        },
        {
            field: 'errorMessage',
            headerName: t('third_party.connector_logs.error_message'),
            maxWidth: 500,
            wrapText: true,
            autoHeight: true,
        },
        {
            field: 'publishedAt',
            headerName: t('third_party.connector_logs.published_at'),
            valueFormatter: ({ value }) => formatDate(value, config.DATE_FORMAT.ISO_DATE_TIME),
        },
        {
            field: 'processedAt',
            headerName: t('third_party.connector_logs.processed_at'),
            valueFormatter: ({ value }) => formatDate(value, config.DATE_FORMAT.ISO_DATE_TIME),
        },
        {
            colId: 'menu',
            type: 'actionMenu',
            cellRenderer: CellActionRenderer,
        },
    ];

    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<ThirdPartyIntegrationLog>();

    const statusesFilter = getSelectFilterStringValuesByKey(filters, 'status');
    const hideFixed = getBooleanFilterValueByKey(filters, 'hideFixed');

    const filteredLogs = thirdPartyIntegrationLogs.filter(({ fixed, errorMessage }) => {
        const status = errorMessage ? 'error' : 'success';
        const isStatusMatch = !statusesFilter.length || statusesFilter.includes(status);
        const isFixedMatch = hideFixed ? !fixed : true;
        return isStatusMatch && isFixedMatch;
    });

    const tableHeight = getTableHeight({ rowsLength: filteredLogs.length, disableFooter: true });

    const handleDownloadData = () => {
        gridRef.current?.api?.exportDataAsExcel();
    };

    return (
        <Stack component={DialogContent} minHeight={100} height={tableHeight}>
            <Stack gap={2} flex={1}>
                <Stack component={Paper} direction='row' gap={1} p={1} alignItems='center' justifyContent='space-between'>
                    <FiltersBar filters={filters} onFiltersChange={setFilters} clearable={false} />
                    <Stack direction='row' gap={1} alignItems='center'>
                        <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={handleDownloadData} disabled={!filteredLogs.length} />
                        {refetch && (
                            <IconButton aria-label='refresh-button' onClick={refetch} size='small'>
                                <RefreshIcon width={20} height={20} />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>
                <Stack flex={1}>
                    <AgGridWrapper rowData={filteredLogs} columnDefs={columnDefs} initRef={setGridRef} loading={false} />
                </Stack>
            </Stack>
        </Stack>
    );
};

const StatusCell = (params: ICellRendererParams<ThirdPartyIntegrationLog>) => {
    const { t } = useTranslation();

    if (!params?.data) {
        return;
    }

    const { fixed, errorMessage } = params.data;

    if (fixed) {
        return <Chip color='default' size='small' label={t('third_party.connector_logs.status_resolved')} />;
    } else if (errorMessage) {
        return <Chip color='error' size='small' label={t('third_party.connector_logs.status_error')} />;
    } else {
        return <Chip color='success' size='small' label={t('third_party.connector_logs.status_success')} />;
    }
};

const handleUpdateFixed = async (cell: ICellRendererParams<ThirdPartyIntegrationLog>) => {
    if (!cell?.data) {
        return;
    }
    const log = cell.data;
    await updateThirdPartyIntegrationLogsFixed(log.thirdPartyIntegrationId, log.id, { isFixed: !log.fixed }).then(() => {
        log.fixed = !log.fixed;
        cell.node.updateData(log);
    });
};

const CellActionRenderer: FC<ICellRendererParams<ThirdPartyIntegrationLog>> = params => {
    const { t } = useTranslation();

    const getMenuItems = (params: ICellRendererParams<ThirdPartyIntegrationLog>): BasicMenuItem[] => {
        if (!params.data?.errorMessage) {
            return [];
        }
        return [
            {
                title: params.data.fixed ? t('third_party.connector_logs.undo_resolved') : t('third_party.connector_logs.mark_as_resolved'),
                onClick: () => handleUpdateFixed(params),
            },
        ];
    };
    return <BasicMenu items={getMenuItems(params)} />;
};

const useFilters = (): [FilterType[], (filters: FilterType[]) => void] => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<FilterType[]>([
        {
            key: 'status',
            filterName: t('third_party.connector_logs.status'),
            type: 'multi-select',
            options: [
                { value: 'error', label: t('third_party.connector_logs.status_error') },
                { value: 'success', label: t('third_party.connector_logs.status_success') },
            ],
            selectMode: 'SYNC',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'hideFixed',
            filterName: t('third_party.connector_logs.hide_fixed'),
            type: 'boolean',
        },
    ]);

    return [filters, setFilters];
};
