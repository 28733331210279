import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { ContractType, Employment, ModifyContractMutation, TerminationReason, TerminationType } from '@/domain/employment/Employment.model';
import { addDaysAndFormat, subDaysAndFormat } from '@/utils/datetime.util';

import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldText } from '@/components/form/field-text/FieldText';
import { calculateProbationEndDate, modifyContract } from '@/domain/employment/Employment.service';
import {
    ContractFormValues,
    ContractSchemaConfig,
    getContractSchema,
} from '@/page/employee-profile/employee-profile-info/EmployeeContractSection/contract.schema';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ModifyContractDialogProps = {
    onSave: () => void;
    contract: Employment;
    existingContracts: Employment[];
} & DialogWrapperProps;

export const ModifyContractDialog: FC<ModifyContractDialogProps> = ({ onSave, contract, existingContracts, ...restDialog }) => {
    const { t } = useTranslation();

    // Existing contracts are sorted by start date in ascending order

    const index = existingContracts.findIndex(c => c.id === contract.id);
    // Search for the next contract
    const nextContract = existingContracts[index + 1];
    const prevContract = existingContracts[index - 1];

    // The minimum start date is the day after the end date of the prev contract
    const minStartDate = prevContract?.endDate ? addDaysAndFormat(prevContract.endDate, 1) : undefined;
    // The maximum start date is the day before the start date of the next contract
    const maxStartDate = nextContract?.startDate ? subDaysAndFormat(nextContract.startDate, 1) : undefined;

    const schemaConfig: ContractSchemaConfig = {
        range: [minStartDate, maxStartDate],
    };
    const schema = getContractSchema(schemaConfig);

    const formMethods = useForm<ContractFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            startDate: contract.startDate,
            contractType: contract.contractType,
            probationEndDate: contract.probationEndDate,
            endDate: contract.endDate,
            terminationNoticeDate: contract.terminationNoticeDate,
            terminationLastDayAtWork: contract.terminationLastDayAtWork,
            terminationType: contract.terminationType,
            terminationReason: contract.terminationReason,
            terminationComment: contract.terminationComment ?? '',
        },
    });
    const { setValue, watch, handleSubmit } = formMethods;

    const contractEndDate = watch('endDate');
    const contractType = watch('contractType');

    const showEndDateField = !!contract.endDate || (contractType && contractType !== ContractType.PERMANENT);

    const handleSave = async (contractFormValues: ContractFormValues) => {
        const endDate = showEndDateField ? (contractFormValues?.endDate ?? undefined) : undefined;

        const terminationFieldValues = {
            terminationNoticeDate: contractFormValues?.terminationNoticeDate ?? undefined,
            terminationLastDayAtWork: contractFormValues?.terminationLastDayAtWork ?? undefined,
            terminationType: contractFormValues?.terminationType ?? undefined,
            terminationReason: contractFormValues?.terminationReason ?? undefined,
            terminationComment: contractFormValues?.terminationComment ?? undefined,
        } satisfies Partial<ModifyContractMutation>;

        const contractStartDateMutation: ModifyContractMutation = {
            startDate: contractFormValues.startDate,
            probationEndDate: contractFormValues?.probationEndDate ?? undefined,
            contractType: contractFormValues.contractType,
            endDate: endDate,
            terminationNoticeDate: endDate ? terminationFieldValues.terminationNoticeDate : undefined,
            terminationLastDayAtWork: endDate ? terminationFieldValues.terminationLastDayAtWork : undefined,
            terminationType: endDate ? terminationFieldValues.terminationType : undefined,
            terminationReason: endDate ? terminationFieldValues.terminationReason : undefined,
            terminationComment: endDate ? terminationFieldValues.terminationComment : undefined,
        };

        try {
            // Use the modifyEmploymentStartDate service method
            await modifyContract(contract.id, contractStartDateMutation);
            showSnackbar(t('employee.employment.contract.update_success'), 'success');
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <DialogWrapper header={t('employee.employment.contract.modify_contract')} {...restDialog}>
            <DialogContent>
                <FormProvider {...formMethods}>
                    <Stack direction='column' spacing={2}>
                        <FormControlLabel
                            label={t('employee.employment.start_date')}
                            control={
                                <FieldLocalDate
                                    control={formMethods.control}
                                    name={'startDate'}
                                    minDate={minStartDate}
                                    maxDate={maxStartDate}
                                    onChange={newStartDate => {
                                        // When the contract start date is changed, the probation end date is automatically set to 3 months after the contract start date
                                        if (!newStartDate) {
                                            return;
                                        }
                                        setValue('probationEndDate', calculateProbationEndDate(newStartDate));
                                    }}
                                />
                            }
                        />
                        <FormControlLabel
                            label={t('employee.employment.probation_end_date')}
                            control={<FieldLocalDate control={formMethods.control} name={'probationEndDate'} minDate={minStartDate} maxDate={maxStartDate} />}
                        />

                        <FormControlLabel
                            label={t('domain.employee_field_type.contract_type')}
                            control={
                                <FieldSelect
                                    control={formMethods.control}
                                    name='contractType'
                                    options={Object.values(ContractType)}
                                    getOptionLabel={option => t('employee.employment.contract_type', { context: option })}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    fullWidth
                                />
                            }
                        />

                        {showEndDateField && (
                            <FormControlLabel
                                label={t('employee.employment.contract_end_date')}
                                control={<FieldLocalDate control={formMethods.control} name={'endDate'} minDate={minStartDate} maxDate={maxStartDate} />}
                            />
                        )}
                        {contractEndDate && (
                            <>
                                <FormControlLabel
                                    label={t('employee.employment.termination.notice_date')}
                                    control={<FieldLocalDate control={formMethods.control} name={'terminationNoticeDate'} />}
                                />
                                <FormControlLabel
                                    label={t('employee.employment.termination.last_day_at_work')}
                                    control={<FieldLocalDate control={formMethods.control} name={'terminationLastDayAtWork'} />}
                                />

                                <FormControlLabel
                                    label={t('employee.employment.termination_type.termination_type')}
                                    control={
                                        <FieldSelect
                                            control={formMethods.control}
                                            name='terminationType'
                                            options={Object.values(TerminationType)}
                                            getOptionLabel={option => t('employee.employment.termination_type.enum', { context: option })}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            fullWidth
                                        />
                                    }
                                />

                                <FormControlLabel
                                    label={t('employee.employment.termination_reason.termination_reason')}
                                    control={
                                        <FieldSelect
                                            control={formMethods.control}
                                            name='terminationReason'
                                            options={Object.values(TerminationReason)}
                                            getOptionLabel={option => t('employee.employment.termination_reason.enum', { context: option })}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            fullWidth
                                        />
                                    }
                                />

                                <FormControlLabel
                                    label={t('general.comment')}
                                    control={<FieldText name={'terminationComment'} control={formMethods.control} fullWidth />}
                                />
                            </>
                        )}
                    </Stack>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
