import {
    getCompanyDocuments,
    getCompanyFolders,
    getEmployeeDocuments,
    getEmployeeFolders,
    getFolderById,
    getFolders,
    getFoldersByType,
} from '@/domain/document/Document.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { EmployeeDocumentSearch, FolderType } from '@/domain/document/Document.model';
import { getNull } from '@/utils/object.util';

export const documentKeys = createQueryKeys('documents', {
    foldersByType: (type: FolderType) => ({
        queryKey: [type],
    }),
    folders: {
        queryKey: getNull(),
    },
    folderById: (folderId: number) => ({
        queryKey: [folderId],
    }),
    employeeDocuments: (search: EmployeeDocumentSearch) => ({
        queryKey: [search],
    }),
    employeeFolders: (employeeId: number) => ({
        queryKey: [employeeId],
    }),
    companyFolders: {
        queryKey: getNull(),
    },
    companyDocuments: (companyId: number) => ({
        queryKey: [companyId],
    }),
});

export const useGetFoldersByType = createRequiredParamsQueryHook(documentKeys.foldersByType, getFoldersByType);

export const useGetFolders = createQueryHook(documentKeys.folders, getFolders);

export const useGetFolderById = createRequiredParamsQueryHook(documentKeys.folderById, getFolderById);

export const useGetEmployeeDocuments = createRequiredParamsQueryHook(documentKeys.employeeDocuments, getEmployeeDocuments);

export const useGetEmployeeFolders = createRequiredParamsQueryHook(documentKeys.employeeFolders, getEmployeeFolders);

export const useGetCompanyFolders = createQueryHook(documentKeys.companyFolders, getCompanyFolders);

export const useGetCompanyDocuments = createRequiredParamsQueryHook(documentKeys.companyDocuments, getCompanyDocuments);
