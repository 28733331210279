import { StateHandler } from '@/components/state-handler/StateHandler';
import {
    canApproveDeclineExpenses,
    canApproveRejectOtherEmployeeLeaveRequests,
    canApproveRejectOtherEmployeeTimesheets,
    canApproveRejectProfileUpdatePendingRequest,
    canContributeEmployeeReview,
    canPayExpenseRequests,
} from '@/domain/permission/Permission.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useCountEmployeePendingChanges } from '@/hooks/employee-pending-change/EmployeePendingChanges.hook';
import { useCountOngoingEmployeeReviews } from '@/hooks/employee-review/EmployeeReview.hook';
import { useCountTimesheetPending } from '@/hooks/timesheet/Timesheet.hook';
import { PendingRow } from '@/page/home/pending/PendingRow';
import { useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountPendingLeaveRequests } from '@/hooks/leave-request/LeaveRequest.hook';
import { useCountExpenseRequest } from '@/hooks/expense-request/ExpenseRequest.hook';
import { ExpenseRequestSearch } from '@/domain/expense-request/ExpenseRequest.model';
import { PolicyPermission } from '@/domain/permission/Permission.model';

export const PendingTasks: FC = () => {
    const { t } = useTranslation();

    const currentEmployee = useCurrentEmployee();
    const grantedPolicies = useCurrentPolicies();

    const tasksPermissions = {
        canManageEmployeeInfos:
            hasRealmFeatureEnabled(RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE) &&
            canApproveRejectProfileUpdatePendingRequest(grantedPolicies, currentEmployee.id),
        canContributeReview: hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && canContributeEmployeeReview(grantedPolicies),
        canManageLeaveRequests:
            hasRealmFeatureEnabled(RealmFeaturesType.LEAVES) && canApproveRejectOtherEmployeeLeaveRequests(grantedPolicies, currentEmployee.id),
        canManageEmployeeTimesheet:
            hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET) && canApproveRejectOtherEmployeeTimesheets(grantedPolicies, currentEmployee.id),
        canManagePendingExpenses: hasRealmFeatureEnabled(RealmFeaturesType.EXPENSES) && canApproveDeclineExpenses(grantedPolicies, currentEmployee.id),
        canControlExpenses: hasRealmFeatureEnabled(RealmFeaturesType.EXPENSES) && canPayExpenseRequests(grantedPolicies, currentEmployee.id),
    };

    const {
        data: countEmployeePendingChanges,
        isLoading: isCountEmployeePendingChangesLoading,
        isError: isCountEmployeePendingChangesError,
        error: countEmployeePendingChangesError,
    } = useCountEmployeePendingChanges(undefined, { enabled: tasksPermissions.canManageEmployeeInfos });

    const {
        data: countOngoingEmployeeReviews,
        isLoading: isCountOngoingEmployeeReviewsLoading,
        isError: isCountOngoingEmployeeReviewsError,
        error: countOngoingEmployeeReviewsError,
    } = useCountOngoingEmployeeReviews(currentEmployee, { options: { enabled: tasksPermissions.canContributeReview } });

    const {
        data: countPendingLeaves,
        isLoading: isCountPendingLeavesLoading,
        isError: isCountPendingLeavesError,
        error: countPendingLeavesError,
    } = useCountPendingLeaveRequests(undefined, { enabled: tasksPermissions.canManageLeaveRequests });

    const {
        data: countTimesheetPending,
        isLoading: isCountTimesheetPendingLoading,
        isError: isCountTimesheetPendingError,
        error: countTimesheetPendingError,
    } = useCountTimesheetPending(undefined, { enabled: tasksPermissions.canManageEmployeeTimesheet });

    const pendingExpenseRequestSearch: ExpenseRequestSearch = {
        statuses: ['PENDING'],
        employeeIds:
            grantedPolicies.find(employeePolicy => employeePolicy.policy === PolicyPermission.APPROVE_DECLINE_EXPENSE_REQUESTS)?.targetEmployeeIds ?? [],
    };

    const {
        data: countExpensePendingRequests,
        isLoading: isCountExpensePendingRequestsLoading,
        isError: isCountExpensePendingRequestsError,
        error: countExpensePendingRequestsError,
    } = useCountExpenseRequest(pendingExpenseRequestSearch, { enabled: tasksPermissions.canManagePendingExpenses });

    const toControlExpenseRequestSearch: ExpenseRequestSearch = {
        statuses: ['APPROVED'],
        employeeIds: grantedPolicies.find(employeePolicy => employeePolicy.policy === PolicyPermission.PAY_EXPENSE_REQUESTS)?.targetEmployeeIds ?? [],
    };

    const {
        data: countExpenseToControlRequests,
        isLoading: isCountExpenseToControlRequestsLoading,
        isError: isCountExpenseToControlRequestsError,
        error: countExpenseToControlRequestsError,
    } = useCountExpenseRequest(toControlExpenseRequestSearch, { enabled: tasksPermissions.canControlExpenses });

    const isCountsLoading =
        isCountEmployeePendingChangesLoading ||
        isCountTimesheetPendingLoading ||
        isCountPendingLeavesLoading ||
        isCountOngoingEmployeeReviewsLoading ||
        isCountExpensePendingRequestsLoading ||
        isCountExpenseToControlRequestsLoading;
    const isCountsError =
        isCountEmployeePendingChangesError ||
        isCountTimesheetPendingError ||
        isCountPendingLeavesError ||
        isCountOngoingEmployeeReviewsError ||
        isCountExpensePendingRequestsError ||
        isCountExpenseToControlRequestsError;
    const countsError =
        countEmployeePendingChangesError ??
        countTimesheetPendingError ??
        countPendingLeavesError ??
        countOngoingEmployeeReviewsError ??
        countExpensePendingRequestsError ??
        countExpenseToControlRequestsError;

    return (
        <Stack gap={1.5} component={Paper} p={2}>
            <Typography variant='h1'>{t('home_page.my_tasks.title')}</Typography>
            <StateHandler isLoading={false} isError={isCountsError} error={countsError}>
                {/* Use loading state */}
                {isCountsLoading ? (
                    <TasksSkeleton permissions={tasksPermissions} />
                ) : (
                    <Stack divider={<Divider />} gap={1.5}>
                        {tasksPermissions.canManageEmployeeInfos && (
                            <PendingRow
                                label={t('home_page.my_tasks.profile_updates')}
                                count={countEmployeePendingChanges}
                                path={'/people/employee-requests'}
                            />
                        )}
                        {tasksPermissions.canContributeReview && (
                            <PendingRow label={t('home_page.my_tasks.ongoing_reviews')} count={countOngoingEmployeeReviews} path={'/reviews/tasks'} />
                        )}
                        {tasksPermissions.canManageLeaveRequests && (
                            <PendingRow label={t('home_page.my_tasks.pending_leaves')} count={countPendingLeaves} path={'/manage-leaves/pending'} />
                        )}
                        {tasksPermissions.canManageEmployeeTimesheet && (
                            <PendingRow label={t('home_page.my_tasks.pending_timesheets')} count={countTimesheetPending} path={'/timesheets/pending'} />
                        )}
                        {tasksPermissions.canManagePendingExpenses && (
                            <PendingRow label={t('home_page.my_tasks.pending_expenses')} count={countExpensePendingRequests} path={'/expenses/pending'} />
                        )}
                        {tasksPermissions.canControlExpenses && (
                            <PendingRow
                                label={t('home_page.my_tasks.expenses_to_control')}
                                count={countExpenseToControlRequests}
                                path={'/expenses/to-control'}
                            />
                        )}
                    </Stack>
                )}
            </StateHandler>
        </Stack>
    );
};

const TasksSkeleton: FC<{ permissions: Record<string, boolean> }> = ({ permissions }) => {
    const visiblePermissionsIndexes = Object.values(permissions)
        .filter(canSee => canSee)
        .map((_, i) => i);
    return (
        <Stack divider={<Divider />} gap={1.5}>
            {visiblePermissionsIndexes.map(i => (
                <Stack key={i} direction={'row'} gap={2}>
                    <Skeleton variant={'text'} sx={{ flex: 1 }} />
                    <Skeleton variant={'circular'} width={20} height={20} />
                </Stack>
            ))}
        </Stack>
    );
};
