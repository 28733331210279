import {
    countPendingChanges,
    getEmployeePendingChanges,
    searchEmployeeProfilePendingChanges,
} from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const employeePendingChangeKeys = createQueryKeys('employeePendingChanges', {
    get: (...params: Parameters<typeof getEmployeePendingChanges>) => ({
        queryKey: params,
    }),
    count: (...optionalParam: Parameters<typeof countPendingChanges>) => {
        return { queryKey: [optionalParam ?? {}] };
    },
    search: (...optionalParam: Parameters<typeof searchEmployeeProfilePendingChanges>) => {
        return { queryKey: [optionalParam ?? {}] };
    },
});
export const useGetEmployeeProfilePendingChanges = createRequiredParamsQueryHook(employeePendingChangeKeys.get, getEmployeePendingChanges);

export const useCountEmployeePendingChanges = createQueryHook(employeePendingChangeKeys.count, countPendingChanges);

export const useGetPendingRequests = createQueryHook(employeePendingChangeKeys.search, searchEmployeeProfilePendingChanges);
