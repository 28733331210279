import { EmptyState } from '@/components/empty-state/EmptyState';
import { ErrorEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { ErrorBoundary } from '@/error/ErrorBoundary';
import { Paper } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router';

export const RouterBoundary: FC = () => {
    const error = useRouteError();

    const ThrowError = () => {
        if (error && error instanceof Error) {
            throw error;
        }
        return undefined;
    };

    return (
        <ErrorBoundary fallbackComponent={RetryPaper}>
            <ThrowError />
        </ErrorBoundary>
    );
};

const RetryPaper: FC = () => {
    const { t } = useTranslation();

    const errorTitle = t('general.errors.default_title', {
        // If translation are not available we fallback to a default message
        defaultValue: 'An error occurred',
    });

    const subTitle = t('general.errors.default_sub_title', {
        defaultValue: 'Please try again later',
    });
    return (
        <Paper sx={{ display: 'flex', flex: '1' }}>
            <EmptyState flex={1} icon={<ErrorEmptyStateIcon />} title={errorTitle} subTitle={subTitle} />
        </Paper>
    );
};
