import { searchEmployeePayrollLock, searchLatestEmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.service';
import { getPayrollEmployeeProfileChanges, getPayrollTransactions } from '@/domain/payroll/Payroll.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const payrollKeys = createQueryKeys('payroll', {
    latestEmployeePayrollLock: (...params: Parameters<typeof searchLatestEmployeePayrollLock>) => ({
        queryKey: params,
    }),
    employeePayrollLock: (...optionalParam: Parameters<typeof searchEmployeePayrollLock>) => ({
        queryKey: [optionalParam ?? {}],
    }),
    payrollProfileChanges: (...params: Parameters<typeof getPayrollEmployeeProfileChanges>) => ({
        queryKey: params,
    }),
    payrollTransactions: (...params: Parameters<typeof getPayrollTransactions>) => ({
        queryKey: params,
    }),
});

export const useGetLatestEmployeePayrollLock = createQueryHook(payrollKeys.latestEmployeePayrollLock, searchLatestEmployeePayrollLock);

export const useGetEmployeePayrollLock = createQueryHook(payrollKeys.employeePayrollLock, searchEmployeePayrollLock);

export const useGetPayrollProfileChanges = createQueryHook(payrollKeys.payrollProfileChanges, getPayrollEmployeeProfileChanges);

export const useGetPayrollTransactions = createQueryHook(payrollKeys.payrollTransactions, getPayrollTransactions);
