import { PermissionFilteringConditionType } from '@/domain/permission/Permission.model';
import { useState } from 'react';
import { useGetLocations } from '@/hooks/location/Location.hook';
import { useGetLegalUnits } from '@/hooks/legal-unit/LegalUnit.hook';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { useGetJobs } from '@/hooks/job/Job.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { ContractType, EmploymentStatus } from '@/domain/employment/Employment.model';
import { getContractTypeTranslationKey, getUserEmploymentStatusTranslationKey } from '@/domain/employee/Employee.service';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

export type ConditionValue = {
    key: string | number;
    displayName: string;
};

/**
 * Get possible values by condition type (locations, departments, jobs...)
 * @param conditionType
 */
export const useGetPossibleConditionValues = (
    conditionType: PermissionFilteringConditionType | undefined,
): {
    possibleValues: ConditionValue[] | undefined;
} => {
    const { t } = useTranslation();

    const { data: locations = [], isFetching: isLocationsFetching } = useGetLocations(undefined, {
        enabled: conditionType === PermissionFilteringConditionType.LOCATION,
    });
    const { data: legalUnits = [], isFetching: isLegalUnitsFetching } = useGetLegalUnits(undefined, {
        enabled: conditionType === PermissionFilteringConditionType.LEGAL_UNIT,
    });
    const { data: departments = [], isFetching: isDepartmentsFetching } = useGetDepartments(undefined, {
        enabled: conditionType === PermissionFilteringConditionType.DEPARTMENT,
    });
    const { data: jobs = [], isFetching: isJobsFetching } = useGetJobs(undefined, { enabled: conditionType === PermissionFilteringConditionType.JOB });

    const isFetching = isLocationsFetching || isLegalUnitsFetching || isDepartmentsFetching || isJobsFetching;

    const [possibleValues, setPossibleValues] = useState<ConditionValue[]>();

    useDeepCompareEffect(() => {
        if (!conditionType || isFetching) {
            return;
        }
        switch (conditionType) {
            case PermissionFilteringConditionType.LOCATION:
                setPossibleValues(
                    locations.map(location => ({
                        key: location.id,
                        displayName: location.name,
                    })),
                );
                break;
            case PermissionFilteringConditionType.LEGAL_UNIT:
                setPossibleValues(
                    legalUnits.map(legalUnit => ({
                        key: legalUnit.id,
                        displayName: legalUnit.displayName,
                    })),
                );
                break;
            case PermissionFilteringConditionType.DEPARTMENT:
                setPossibleValues(
                    departments.map(department => ({
                        key: department.id,
                        displayName: getLabelTranslation(department.name),
                    })),
                );
                break;
            case PermissionFilteringConditionType.JOB:
                setPossibleValues(
                    jobs.map(job => ({
                        key: job.id,
                        displayName: getLabelTranslation(job.name),
                    })),
                );
                break;
            case PermissionFilteringConditionType.EMPLOYMENT_STATUS: {
                const employmentStatus = Object.values(EmploymentStatus).map(status => {
                    return {
                        key: status as string,
                        displayName: t(getUserEmploymentStatusTranslationKey(), { context: status }),
                    };
                });
                setPossibleValues(employmentStatus);
                break;
            }
            case PermissionFilteringConditionType.CONTRACT_TYPE: {
                const contractType = Object.values(ContractType).map(status => {
                    return {
                        key: status as string,
                        displayName: t(getContractTypeTranslationKey(), { context: status }),
                    };
                });
                setPossibleValues(contractType);
                break;
            }
        }
    }, [conditionType, departments, isFetching, jobs, legalUnits, locations, t]);

    return {
        possibleValues,
    };
};
