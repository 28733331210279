import { leaveRequestAttachmentApi } from '@/api/leave-request-attachments/LeaveRequestAttachment.api';
import {
    LeaveRequestAttachment,
    LeaveRequestAttachmentCreationRequest,
    SearchLeaveRequestAttachment,
} from '@/domain/leave-request-attachment/LeaveRequestAttachment.model';

import { CONTENT_DISPOSITION } from '@/domain/common';

export const createLeaveRequestAttachment = (request: LeaveRequestAttachmentCreationRequest): Promise<LeaveRequestAttachment> => {
    return leaveRequestAttachmentApi.createLeaveRequestAttachment(request);
};

export const searchLeaveRequestAttachments = (request: SearchLeaveRequestAttachment): Promise<LeaveRequestAttachment[]> => {
    return leaveRequestAttachmentApi.searchLeaveRequestAttachments(request);
};

export const deleteLeaveRequestAttachment = (leaveRequestAttachmentId: number): Promise<void> => {
    return leaveRequestAttachmentApi.deleteLeaveRequestAttachment(leaveRequestAttachmentId);
};

export const getLeaveRequestAttachmentUrl = (leaveRequestAttachmentId: number, ContentDisposition: CONTENT_DISPOSITION): Promise<string> => {
    return leaveRequestAttachmentApi.getLeaveRequestAttachmentUrl(leaveRequestAttachmentId, ContentDisposition);
};
