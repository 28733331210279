import { Spinner } from '@/components/spinner/Spinner';
import { DashboardLazyPage } from '@/page/home/homeLazyPages';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { Suspense } from 'react';
import { RouteObject } from 'react-router';

export const dashboardRoutes: RouteObject[] = [
    {
        path: '/dashboard',
        element: (
            <PageContainer breadCrumb={[{ nameKey: 'pages.dashboard' }]}>
                <Suspense fallback={<Spinner />}>
                    <DashboardLazyPage />
                </Suspense>
            </PageContainer>
        ),
        // Catch errors not caught in the page itself, this must be the last resort to catch errors
        errorElement: <RouterBoundary />,
    },
];
