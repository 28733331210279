import { FilePickerItem, StoredFile } from '@/components/file-picker/FilePicker';
import { getEmployeeSectionFieldDocumentUrl } from '@/domain/employee-section/EmployeeSection.service';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FieldFilePicker } from '@/components/form/field-file-picker/FieldFilePicker';

type EditableDocumentFieldProps = {
    documentsValue: StoredFile[];
    formValueName: string;
};

export const EditableDocumentField: FC<EditableDocumentFieldProps> = ({ formValueName, documentsValue }) => {
    const { setValue, getValues, control } = useFormContext<Record<string, Nullable<FilePickerItem[]>>>();

    // Set documentsValue to the form state
    useDeepCompareEffect(() => {
        // If the documentsValue is already in the form state, we don't need to set it again
        if (getValues(formValueName)?.length) {
            return;
        }
        setValue(formValueName, documentsValue ?? []);
    }, [documentsValue, formValueName, setValue]);

    return (
        <FieldFilePicker
            name={formValueName}
            control={control}
            sx={{ width: '100%' }}
            fetchDocumentUrl={id => getEmployeeSectionFieldDocumentUrl(id, 'ATTACHMENT').then(documentDownloadUrl => documentDownloadUrl)}
        />
    );
};
