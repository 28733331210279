import { searchWeeklyWorkingTimes } from '@/domain/weekly-working-time/WeeklyWorkingTime.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const weeklyWorkingTimeKeys = createQueryKeys('weeklyWorkingTimes', {
    search: {
        queryKey: getNull(),
    },
});

export const useGetWeeklyWorkingTimes = createQueryHook(weeklyWorkingTimeKeys.search, searchWeeklyWorkingTimes);
