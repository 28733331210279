import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FilePicker, FilePickerItem, TemporaryFile } from '@/components/file-picker/FilePicker';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { Select } from '@/components/form/field-select/Select';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeSectionImportRequest } from '@/domain/employee-section/EmployeeSection.model';
import { ImportOption, ImportRequest, ImportResult, ImportType } from '@/domain/import/Import.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { getSectionDefinitions } from '@/domain/section-setting/Section.service';
import { useImportEmployeeSectionMutation, useImportWithTypeMutation } from '@/hooks/import/ImportExcel.hook';
import { useGetThirdParties } from '@/hooks/third-party/ThirdParty.hook';
import { getCheckboxesTranslationKey } from '@/page/setting/import/import-excel-dialog/importExcel.util';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { Button, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material';
import { AlertCircleIcon } from 'hugeicons-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ImportExcelDialogProps = {
    importType: ImportType;
    sectionId?: number;
    handleCloseModal: () => void;
    handleImportResponse: (importResult: ImportResult, importType: ImportType) => void;
};

export const ImportExcelDialog: FC<ImportExcelDialogProps> = ({ importType, sectionId, handleCloseModal, handleImportResponse }) => {
    const [shouldReplace, setShouldReplace] = useState<ImportOption>(getShouldReplaceDefaultValue(importType));
    const isEmployeeSectionImport = !!sectionId;
    // todo: use react hook form
    const [file, setFile] = useState<TemporaryFile>();
    const [activeCustomSection, setActiveCustomSection] = useState<SectionDefinition>();
    const [isLoading, setIsLoading] = useState<boolean>(isEmployeeSectionImport);
    const [isError, setIsError] = useState<boolean>(false);

    const [thirdPartyId, setThirdPartyId] = useState<number>();
    const { t } = useTranslation();

    const canSetThirdParty =
        hasRealmFeatureEnabled(RealmFeaturesType.THIRD_PARTIES) &&
        [ImportType.BASIC_INFORMATION, ImportType.WORKING_PATTERN, ImportType.ADDRESS, ImportType.EMPLOYEE, ImportType.CUSTOM_SECTION].includes(importType);

    const {
        data: thirdParties = [],
        isLoading: isLoadingThirdParties,
        isError: isErrorThirdParties,
    } = useGetThirdParties(undefined, { enabled: canSetThirdParty });

    const { mutateAsync: importByTypeMutate, isPending: isImportByTypePending } = useImportWithTypeMutation();
    const { mutateAsync: importEmployeeSectionMutate, isPending: isImportEmployeeSectionPending } = useImportEmployeeSectionMutation();

    const getActiveCustomSection = useCallback(
        (sectionDefinitions: SectionDefinition[]) => {
            return sectionDefinitions.find(sectionDefinition => {
                return sectionDefinition.id === sectionId;
            });
        },
        [sectionId],
    );

    useEffect(() => {
        const fetchSectionDefinitions = async () => {
            try {
                const data = await getSectionDefinitions();
                const activeCustomSection = getActiveCustomSection(data);
                setActiveCustomSection(activeCustomSection);
            } catch (e) {
                setIsError(true);
                handleError(e);
            } finally {
                setIsLoading(false);
            }
        };

        if (sectionId) {
            setIsLoading(true);
            fetchSectionDefinitions().catch(handleError);
        }
    }, [getActiveCustomSection, sectionId]);

    const handleSettingsDialogClose = () => {
        resetData();
    };

    const showReplaceOption = () => {
        return importType === ImportType.CUSTOM_SECTION || importType === ImportType.BASIC_INFORMATION || importType === ImportType.WORKING_PATTERN;
    };

    // TODO: this is to be removed when the only if empty option is developed for the basic information import by the backend https://rogerhr.atlassian.net/browse/RP-2730
    const areOptionsDisabled = () => {
        return importType === ImportType.BASIC_INFORMATION;
    };

    const onShouldReplaceChange = (shouldReplace: ImportOption) => {
        setShouldReplace(shouldReplace);
    };

    const handleImportEmployeeSection = async () => {
        if (!file || !sectionId) {
            return;
        }
        const employeeSectionImportRequest: EmployeeSectionImportRequest = {
            file: file.data,
            shouldReplace: shouldReplace === ImportOption.SHOULD_REPLACE,
            thirdPartyId: thirdPartyId,
        };

        try {
            const importResult = await importEmployeeSectionMutate({
                employeeSectionImportRequest: employeeSectionImportRequest,
                sectionDefinitionId: sectionId,
            });
            handleImportResponse(importResult, importType);
        } catch (error) {
            handleError(error);
        }
    };

    const handleImportDefaultSection = async () => {
        if (!file) {
            return;
        }
        let request: ImportRequest = {
            file: file.data,
            thirdPartyId: thirdPartyId,
        };

        if (showReplaceOption()) {
            request = {
                ...request,
                shouldReplace: shouldReplace === ImportOption.SHOULD_REPLACE,
            };
        }

        try {
            const importResult = await importByTypeMutate({ importType, importRequest: request });
            handleImportResponse(importResult, importType);
        } catch (error) {
            handleError(error);
        }
    };
    const resetData = () => {
        setFile(undefined);
        handleCloseModal();
    };
    const handleSettingsDialogSave = async () => {
        if (!file) {
            return;
        }

        if (isEmployeeSectionImport) {
            await handleImportEmployeeSection();
        } else {
            await handleImportDefaultSection();
        }
    };

    const onFileRemoved = () => {
        setFile(undefined);
    };

    const onFileUploaded = (uploadedFile: TemporaryFile[]) => {
        setFile(uploadedFile[0]);
    };

    const onFileModified = (file: FilePickerItem) => {
        if (isTemporaryFile(file)) {
            setFile(file);
        }
    };

    const title = t('import_page.import', {
        context: importType,
        sectionName: importType === ImportType.CUSTOM_SECTION ? getLabelTranslation(activeCustomSection?.name)?.toLowerCase() : '',
    });

    return (
        <DialogWrapper header={title} open={true} onClose={handleSettingsDialogClose}>
            <StateHandler isLoading={isLoading} isError={isError} error={new Error('Error to load section definitions')}>
                <Stack component={DialogContent} gap={2}>
                    {showReplaceOption() && (
                        <ImportDataOptionCheckboxes
                            shouldReplace={shouldReplace}
                            onImportOptionChange={onShouldReplaceChange}
                            areCheckboxesDisabled={areOptionsDisabled()}
                            activeCustomSection={activeCustomSection}
                            importType={importType}
                        />
                    )}

                    {canSetThirdParty && !isErrorThirdParties && (
                        <FormControlLabel
                            label={t('import_page.select_third_party')}
                            control={
                                // For now we don't have reason to use react-hook-form here
                                <Select
                                    value={thirdParties.find(thirdParty => thirdParty.id === thirdPartyId) ?? getNull()}
                                    fullWidth
                                    options={thirdParties}
                                    loading={isLoadingThirdParties}
                                    onChange={value => {
                                        setThirdPartyId(value?.id);
                                    }}
                                    getOptionLabel={option => option.name}
                                />
                            }
                        />
                    )}
                    <FilePicker
                        files={file ? [file] : []}
                        onFileRenamed={onFileModified}
                        onFileUploaded={onFileUploaded}
                        onFileRemoved={onFileRemoved}
                        fetchDocumentUrl={undefined}
                    />
                </Stack>
                <DialogActions>
                    <Button onClick={handleSettingsDialogSave} loading={isImportEmployeeSectionPending || isImportByTypePending} fullWidth>
                        {t('import_page.import_data')}
                    </Button>
                </DialogActions>
            </StateHandler>
        </DialogWrapper>
    );
};

type ImportDataOptionCheckboxesType = {
    shouldReplace: ImportOption;
    onImportOptionChange: (shouldReplace: ImportOption) => void;
    areCheckboxesDisabled: boolean;
    activeCustomSection: SectionDefinition | undefined;
    importType: ImportType;
};

export const ImportDataOptionCheckboxes: FC<ImportDataOptionCheckboxesType> = ({
    shouldReplace,
    onImportOptionChange,
    areCheckboxesDisabled,
    activeCustomSection,
    importType,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { onlyIfEmptyTranslationKey, shouldReplaceTranslationKey } = getCheckboxesTranslationKey(importType, activeCustomSection);

    return (
        <Stack gap={1}>
            <Typography variant={'body1'}>{t('import_page.import_question')}</Typography>
            <RadioGroup value={shouldReplace} onChange={param => onImportOptionChange(param.target.value as ImportOption)} sx={{ gap: 1 }}>
                <FormControlLabel
                    disabled={areCheckboxesDisabled}
                    value={ImportOption.ONLY_IF_EMPTY}
                    labelPlacement='end'
                    control={<Radio />}
                    label={<Typography>{t(onlyIfEmptyTranslationKey)}</Typography>}
                />
                <FormControlLabel
                    value={ImportOption.SHOULD_REPLACE}
                    labelPlacement='end'
                    disabled={areCheckboxesDisabled}
                    control={<Radio />}
                    label={
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Typography color={'error'}>{t(shouldReplaceTranslationKey)}</Typography>
                            <AlertCircleIcon size={20} color={theme.palette.error.main} />
                        </Stack>
                    }
                />
            </RadioGroup>
        </Stack>
    );
};

const getShouldReplaceDefaultValue = (importType: ImportType) => {
    // TODO: this is to be removed when the only if empty option is developed for the basic information import by the backend https://rogerhr.atlassian.net/browse/RP-2730
    if (importType === ImportType.BASIC_INFORMATION) {
        return ImportOption.SHOULD_REPLACE;
    }
    return ImportOption.ONLY_IF_EMPTY;
};
