import { ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanySettingsLayout } from '../CompanySettingsLayout';
import { ConfigType } from '../types';
import { handleError } from '@/utils/api.util';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { ShiftBreak } from '@/domain/shift-break/ShiftBreak.model';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetShiftBreaks } from '@/hooks/shift-break/ShiftBreak.hook';
import { deleteShiftBreak } from '@/domain/shift-break/ShiftBreak.service';
import { ShiftBreakDialog } from '@/page/setting/planning/shift-break-dialog/ShiftBreakDialog';
import { clearSeconds } from '@/utils/datetime.util';

export const ShiftBreakSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [openCreateShiftBreakDialog, setOpenCreateShiftBreakDialog] = useState<boolean>(false);
    const [shiftBreakToEdit, setShiftBreakToEdit] = useState<ShiftBreak>();

    const { data: shiftBreaks = [], isLoading, isError, error: errorShiftBreaks, refetch: refetchShiftBreaks } = useGetShiftBreaks();

    const handleDelete = async (shiftBreak: ShiftBreak) => {
        try {
            await deleteShiftBreak(shiftBreak.id);
            await refetchShiftBreaks();
        } catch (e) {
            handleError(e);
        }
    };

    const getMoreButton = (params: ICellRendererParams<ShiftBreak>) => {
        if (!params?.data) {
            return;
        }
        const menuItems: BasicMenuItem[] = [
            {
                title: t('general.edit'),
                onClick: () => {
                    if (!params.data) {
                        return;
                    }
                    handleEditClicked(params.data);
                },
            },
            {
                title: t('general.delete'),
                onClick: () => {
                    if (!params.data) {
                        return;
                    }
                    handleDelete(params.data);
                },
            },
        ];
        return <BasicMenu items={menuItems} />;
    };

    const config: ConfigType<ShiftBreak> = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => {
                setOpenCreateShiftBreakDialog(true);
            },
        },
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<ShiftBreak>) => {
                    if (!params.data) {
                        return;
                    }
                    handleEditClicked(params.data);
                },
            },
            columns: [
                {
                    colId: 'name',
                    headerName: t('shift_break_page.table_header.name'),
                    valueGetter: params =>
                        t(params.data?.breakAt ? 'shift_break_page.name_at' : 'shift_break_page.name', {
                            duration: params.data?.breakTime,
                            time: clearSeconds(params.data?.breakAt),
                        }),
                },
                {
                    field: 'breakTime',
                    headerName: t('shift_break_page.table_header.break_time'),
                },
                {
                    field: 'breakAt',
                    headerName: t('shift_break_page.table_header.break_at'),
                    valueGetter: params => clearSeconds(params.data?.breakAt),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: getMoreButton,
                },
            ],
        },
    };

    const handleEditClicked = (data: ShiftBreak) => {
        setShiftBreakToEdit(data);
    };

    const handleOnClose = () => {
        setOpenCreateShiftBreakDialog(false);
        setShiftBreakToEdit(undefined);
    };

    const handleOnSave = async () => {
        await refetchShiftBreaks();
        handleOnClose();
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={errorShiftBreaks}>
            <CompanySettingsLayout options={config} data={shiftBreaks} />
            {openCreateShiftBreakDialog && (
                <ShiftBreakDialog open={openCreateShiftBreakDialog} onSave={handleOnSave} onClose={handleOnClose} shiftBreak={undefined} />
            )}
            {shiftBreakToEdit && <ShiftBreakDialog open={!!shiftBreakToEdit} onSave={handleOnSave} onClose={handleOnClose} shiftBreak={shiftBreakToEdit} />}
        </StateHandler>
    );
};
