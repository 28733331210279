import { getJobFamilies } from '@/domain/job-family/JobFamily.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const jobFamilyKeys = createQueryKeys('jobFamilies', {
    get: {
        queryKey: getNull(),
    },
});

export const useGetJobFamilies = createQueryHook(jobFamilyKeys.get, getJobFamilies);
