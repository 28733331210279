import { useCurrentEmployee } from '@/stores/store';
import { useParams } from 'react-router'; // Todo: add Nuxt library ?

// Todo: add Nuxt library ?
export const useEmployeeProfileId = (): number => {
    const params = useParams();
    const currentEmployee = useCurrentEmployee();
    // Check params for employeeId, otherwise use the current employee id
    return params.employeeId ? Number(params.employeeId) : currentEmployee.id;
};
