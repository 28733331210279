import { mapObjectiveDTO, ObjectiveCreateRequestDTO, ObjectiveDTO, ObjectiveUpdateRequestDTO } from '@/api/objective/Objective.api';
import { API_BASE_URL, client } from '@/api/common';
import { CreateActionMutation, EmployeeReviewAction, UpdateActionMutation } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { AxiosResponse } from 'axios';

const EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH = API_BASE_URL + `/employee-review-actions`;

export type EmployeeReviewActionDTO = StrictOmit<EmployeeReviewAction, 'objective'> & {
    objective: ObjectiveDTO;
};

type UpdateActionRequestDTO = StrictOmit<UpdateActionMutation, 'objectiveUpdateMutation'> & {
    objectiveRequest: ObjectiveUpdateRequestDTO;
};

type CreateActionRequestDTO = StrictOmit<CreateActionMutation, 'objectiveCreateMutation'> & {
    objectiveRequest: ObjectiveCreateRequestDTO;
};

const getActions = async (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    const { data } = await client.get<EmployeeReviewActionDTO[]>(EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH + `/${employeeReviewId}`);
    return data.map(mapActionDTO);
};

const updateAction = async (actionId: number, updatedAction: UpdateActionMutation): Promise<EmployeeReviewAction> => {
    const updatedActionRequest = mapUpdateActionMutation(updatedAction);

    const { data } = await client.put<EmployeeReviewActionDTO, AxiosResponse<EmployeeReviewActionDTO>, UpdateActionRequestDTO>(
        EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH + `/${actionId}`,
        updatedActionRequest,
    );
    return mapActionDTO(data);
};

const createActions = async (actionCreateMutation: CreateActionMutation): Promise<EmployeeReviewAction[]> => {
    const actionCreateRequest = mapCreateActionMutation(actionCreateMutation);

    const { data } = await client.post<EmployeeReviewActionDTO[], AxiosResponse<EmployeeReviewActionDTO[]>, CreateActionRequestDTO>(
        EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH,
        actionCreateRequest,
    );
    return data.map(mapActionDTO);
};

const deleteAction = async (actionId: number): Promise<void> => {
    await client.delete(EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH + `/${actionId}`);
};

const getActionSummary = async (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    const { data } = await client.get<EmployeeReviewActionDTO[]>(EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH + `/${employeeReviewId}/summary`);
    return data.map(mapActionDTO);
};

const getSelfActionSummary = async (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    const { data } = await client.get<EmployeeReviewActionDTO[]>(EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH + `/${employeeReviewId}/self/summary`);
    return data.map(mapActionDTO);
};

const getManagerActionSummary = async (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    const { data } = await client.get<EmployeeReviewActionDTO[]>(EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH + `/${employeeReviewId}/manager/summary`);
    return data.map(mapActionDTO);
};

const archiveAction = async (employeeReviewActionId: number): Promise<void> => {
    await client.post(EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH + `/${employeeReviewActionId}/archive`);
};

const unarchiveAction = async (employeeReviewActionId: number): Promise<void> => {
    await client.post(EMPLOYEE_REVIEW_ACTIONS_API_BASE_PATH + `/${employeeReviewActionId}/unarchive`);
};

export const employeeReviewActionApi = {
    getActions,
    updateAction,
    deleteAction,
    createActions,
    getActionSummary,
    getSelfActionSummary,
    getManagerActionSummary,
    archiveAction,
    unarchiveAction,
};

const mapActionDTO = (action: EmployeeReviewActionDTO): EmployeeReviewAction => {
    return {
        ...action,
        objective: mapObjectiveDTO(action.objective),
    };
};

const mapUpdateActionMutation = (mutation: UpdateActionMutation): UpdateActionRequestDTO => {
    return {
        objectiveRequest: mutation.objectiveUpdateMutation,
    };
};

const mapCreateActionMutation = (mutation: CreateActionMutation): CreateActionRequestDTO => {
    return {
        objectiveRequest: mutation.objectiveCreateMutation,
        employeeReviewIds: mutation.employeeReviewIds,
    };
};
