import { getShiftBreaks } from '@/domain/shift-break/ShiftBreak.service';
import { createQueryHook } from '@/page/Query.type';
import { getNull } from '@/utils/object.util';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const shiftBreaksKeys = createQueryKeys('shiftBreaks', {
    getAll: {
        queryKey: getNull(),
    },
});

export const useGetShiftBreaks = createQueryHook(shiftBreaksKeys.getAll, getShiftBreaks);
