import { FilterType, isSelectFilter } from '@/components/filters-bar/FilterBar.type';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { isDefined } from '@/utils/collections.util';
import { useSearchLeaveRequestAttachments } from '@/hooks/leave-request-attachment/LeaveRequestAttachment.hook';
import { doesEmployeeMatchFilter, isValidEmployeeFilterType } from '@/domain/employee/Employee.service';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/components/filters-bar/FiltersBar.util';
import { LeaveRequestAttachment } from '@/domain/leave-request-attachment/LeaveRequestAttachment.model';
import { useGetPayrollTransactions } from '@/hooks/payroll/Payroll.hook';
import { getStartOfMonth } from '@/utils/datetime.util';

export type LeaveTransactionWithAttachments = LeaveTransaction & {
    attachments: LeaveRequestAttachment[];
};

const usePayrollData = (
    date: LocalDate | undefined,
): {
    payrollTransactions: LeaveTransaction[];
    attachments: LeaveRequestAttachment[];
    isFetching: boolean;
} => {
    const { data: payrollTransactions = [], isFetching: isFetchingTransactions } = useGetPayrollTransactions({
        startDate: getStartOfMonth(date),
    });

    const leaveRequestIds = payrollTransactions.map(transaction => transaction.leaveRequest?.id).filter(isDefined);

    const { data: attachments = [], isFetching: isFetchingAttachments } = useSearchLeaveRequestAttachments(
        { leaveRequestIds },
        { options: { enabled: !!leaveRequestIds.length } },
    );
    return {
        payrollTransactions,
        attachments,
        isFetching: isFetchingTransactions || isFetchingAttachments,
    };
};

const filterPayrollTransactions = (
    payrollTransactions: LeaveTransaction[],
    attachments: LeaveRequestAttachment[],
    filters: FilterType[],
): LeaveTransactionWithAttachments[] => {
    const clientSideFilters = filters.filter(filter => filter.key !== 'cycleYear' && filter.key !== 'cycleMonth');
    const filtersFilled = clientSideFilters.filter(filter => isSelectFilter(filter) && !!filter.value?.length);

    const isFilterMatched = (filter: FilterType, transaction: LeaveTransaction): boolean => {
        if (isValidEmployeeFilterType(filter.key)) {
            const ids = getSelectFilterNumberValues(filter);
            return !!transaction.leaveRequest?.employee && doesEmployeeMatchFilter(transaction.leaveRequest.employee, ids, filter.key);
        } else {
            const keyToUse = filter.key === 'leaveTypeIds' ? 'leaveRequest.leaveType.id' : filter.key;
            const valueFromRow = getNestedValueByPath(transaction, keyToUse);
            return isSelectFilter(filter) && !!filter.value?.find(option => option.value === valueFromRow);
        }
    };

    const filteredTransactions =
        filtersFilled.length === 0 ? payrollTransactions : payrollTransactions.filter(transaction => filtersFilled.every(f => isFilterMatched(f, transaction)));

    return filteredTransactions.map(transaction => {
        const transactionAttachmentIds = transaction.leaveRequest?.attachmentIds.filter(isDefined) ?? [];
        const transactionAttachments = attachments.filter(attachment => transactionAttachmentIds.includes(attachment.id));
        return {
            ...transaction,
            attachments: transactionAttachments,
        };
    });
};

export const useRowsWithAttachments = (
    date: LocalDate | undefined,
    filters: FilterType[],
): {
    rowsWithAttachments: LeaveTransactionWithAttachments[];
    isFetching: boolean;
} => {
    const { payrollTransactions, attachments, isFetching } = usePayrollData(date);
    const rowsWithAttachments = filterPayrollTransactions(payrollTransactions, attachments, filters);
    return {
        rowsWithAttachments,
        isFetching,
    };
};
