import { getTimesheetRecurringAdjustment } from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const timesheetRecurringAdjustmentKeys = createQueryKeys('timesheetRecurringAdjustment', {
    getById: (...params: Parameters<typeof getTimesheetRecurringAdjustment>) => ({
        queryKey: params,
    }),
});

export const useGetTimesheetRecurringAdjustment = createRequiredParamsQueryHook(timesheetRecurringAdjustmentKeys.getById, getTimesheetRecurringAdjustment);
