import { Label } from '@/domain/label/Label.model';
import { Survey, SurveyAnswerMutation } from '@/domain/survey/Survey.model';
import {
    createSurveyAnswer,
    getUserSurvey,
    isLickertScale,
    isNPS,
    isOpinionScale,
    isRenderAsMultipleChoice,
    isRenderAsSection,
    isRenderAsSingleChoice,
    isRenderAsText,
    updateSurveyAnswer,
} from '@/domain/survey/Survey.service';
import { mapSurveyAnswerToForm, mapSurveyQuestionToForm, SurveyAnswerForm, SurveyQuestionForm, SurveyTemplateForm } from '@/page/survey/SurveyForm.util';
import { handleError } from '@/utils/api.util';
import { isDefined } from '@/utils/collections.util';
import { UserLanguage } from '@/utils/language.util';
import { assignLocalId } from '@/utils/object.util';
import { Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EmployeeSurveyFooter } from './EmployeeSurveyFooter';
import { EmployeeSurveyHeader } from './EmployeeSurveyHeader';
import { SurveyAnswerMultiChoice } from './SurveyAnswerMultiChoice';
import { SurveyAnswerScale } from './SurveyAnswerScale';
import { SurveyAnswerSingleChoice } from './SurveyAnswerSingleChoice';
import { SurveyAnswerText } from './SurveyAnswerText';
import { SurveyQuestionsAnswered } from './SurveyQuestionsAnswered';
import { SurveySection } from './SurveySection';
import { SurveyTokenError } from './SurveyTokenError';
import { WelcomePage } from './Welcome';

type Props = {
    token: string | undefined;
    selectedLanguage: UserLanguage;
    editingSurveyTemplate?: SurveyTemplateForm;
    onLanguageChange: (language: UserLanguage) => void;
};

export const EmployeeSurveyStepQuestions: FC<Props> = ({ token, selectedLanguage, editingSurveyTemplate, onLanguageChange }) => {
    const dispatch = useDispatch();
    const { surveyId } = useParams();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [survey, setSurvey] = useState<Survey>();
    const [questions, setQuestions] = useState<SurveyQuestionForm[]>([]);
    const [answers, setAnswers] = useState<SurveyAnswerForm[]>([]);
    const [totalAnswers, setTotalAnswers] = useState<number>(0);
    const lickertScaleLength = 5;

    // Specific useEffect when Preview Mode with editingSurveyTemplate props
    useEffect(() => {
        if (editingSurveyTemplate) {
            const allAnswers: SurveyAnswerForm[] = editingSurveyTemplate.questions.map(q => {
                return assignLocalId({
                    surveyId: editingSurveyTemplate.id,
                    surveyQuestionId: q.id,
                    anonymous: false,
                    skipped: false,
                    textAnswer: '',
                    items: [],
                    comment: '',
                });
            });
            setQuestions(editingSurveyTemplate.questions);
            setTotalAnswers(editingSurveyTemplate.questions.length);
            setActiveStep(0);
            setAnswers(allAnswers);
        }
    }, [editingSurveyTemplate]);

    // Specific useEffect when open survey with token via email
    useEffect(() => {
        if (token) {
            getUserSurvey(token)
                .then(data => {
                    const survey = data.survey;
                    const remainingQuestions = data.remainingQuestions;
                    setSurvey(survey);
                    setQuestions(remainingQuestions.map(q => mapSurveyQuestionToForm(q)));
                    setTotalAnswers(data.questionCount);
                    if (remainingQuestions.length !== data.questionCount) {
                        setActiveStep(1);
                    }
                    const allAnswers: SurveyAnswerForm[] = remainingQuestions.map(q => {
                        return assignLocalId({
                            surveyId: survey.id,
                            surveyQuestionId: q.id,
                            anonymous: false,
                            skipped: false,
                            textAnswer: '',
                            items: [],
                            comment: '',
                        });
                    });
                    setAnswers(allAnswers);
                })
                .catch(handleError);
        }
    }, [token, dispatch, surveyId]);
    const handleSaveAnswer = (mutation: SurveyAnswerMutation, id?: number) => {
        if (!token) {
            return;
        }
        if (id) {
            updateSurveyAnswer(id, mutation, token).then(data => {
                answers[activeStep - 1] = mapSurveyAnswerToForm(data);
                setAnswers([...answers]);
            });
        } else {
            createSurveyAnswer(mutation, token).then(data => {
                answers[activeStep - 1] = mapSurveyAnswerToForm(data);
                setAnswers([...answers]);
            });
        }
    };

    const saveAnswer = (skipped: boolean, activeStep: number) => {
        if (!answers) {
            return;
        }
        if (activeStep > 0) {
            const answer = answers[activeStep - 1];

            if (!answer?.surveyQuestionId) {
                return;
            }

            if (skipped) {
                answer.skipped = true;

                const skippedMutation: SurveyAnswerMutation = {
                    surveyQuestionId: answer.surveyQuestionId,
                    textAnswer: '',
                    anonymous: answer.anonymous,
                    skipped: true,
                    answerSurveyQuestionItemIds: [],
                    comment: '',
                };

                handleSaveAnswer(skippedMutation, answer.id);
            } else {
                const mutation: SurveyAnswerMutation = {
                    surveyQuestionId: answer.surveyQuestionId,
                    textAnswer: answer.textAnswer ?? '',
                    anonymous: answer.anonymous,
                    skipped: false,
                    answerSurveyQuestionItemIds: answer.items.map(i => i.id).filter(isDefined),
                    comment: answer.comment,
                };

                answer.skipped = false;
                handleSaveAnswer(mutation, answer.id);
            }
        }
    };

    const handleSubmit = (skipped: boolean) => {
        if (!(!token && activeStep === questions.length)) {
            setActiveStep(activeStep + 1);
            saveAnswer(skipped, activeStep);
        }
    };

    if (!survey && !!token) {
        return <SurveyTokenError />;
    }

    if (!questions) {
        return <></>;
    }

    const onTextAnswerUpdated = (answerIndex: number, content: string, isAnonymous: boolean, question: SurveyQuestionForm) => {
        if (!answers) {
            return;
        }
        answers[answerIndex].textAnswer = content;
        answers[answerIndex].anonymous = isAnonymous;
        answers[answerIndex].surveyQuestionId = question.id;
        setAnswers([...answers]);
    };

    const onSingleChoiceAnswerUpdated = (answerIndex: number, selected: string, question: SurveyQuestionForm) => {
        if (!answers) {
            return;
        }
        const selectedOption = answers[answerIndex].items;
        selectedOption?.splice(0, 1);
        if (selectedOption) {
            const originalId = question.items.find(o => o.localId === selected)?.id;
            selectedOption.push({
                label: undefined as unknown as Label,
                order: 0,
                score: 0,
                imageUrl: '',
                id: originalId,
                localId: selected,
            });
        }
        answers[answerIndex].items = selectedOption;
        answers[answerIndex].surveyQuestionId = question.id;
        setAnswers([...answers]);
        handleSubmit(false);
    };

    const onMultiChoiceAnsweredUpdated = (answerIndex: number, checked: boolean, selected: string, question: SurveyQuestionForm) => {
        if (!answers) {
            return;
        }
        const selectedOption = answers[answerIndex].items;
        if (checked) {
            if (selectedOption) {
                const originalId = question.items.find(o => o.localId === selected)?.id;
                selectedOption.push({
                    label: undefined as unknown as Label,
                    localId: selected,
                    id: originalId,
                    order: 0,
                    score: 0,
                    imageUrl: '',
                });
            }
        } else {
            if (!selectedOption) {
                return;
            }
            const selectedOptionIndex = selectedOption.findIndex(o => o.localId === selected);
            selectedOption?.splice(selectedOptionIndex, 1);
        }
        answers[answerIndex].items = selectedOption;
        answers[answerIndex].surveyQuestionId = question.id;
        setAnswers([...answers]);
    };

    const onScaleAnswerUpdated = (answerIndex: number, selected: string, question: SurveyQuestionForm) => {
        if (!answers) {
            return;
        }
        const selectedOption = answers[answerIndex].items;
        selectedOption?.splice(0, 1);
        if (selectedOption) {
            const originalId = question.items.find(o => o.localId === selected)?.id;
            selectedOption.push({
                label: undefined as unknown as Label,
                order: 0,
                score: 0,
                imageUrl: '',
                id: originalId,
                localId: selected,
            });
        }
        answers[answerIndex].items = selectedOption;
        answers[answerIndex].surveyQuestionId = question.id;

        setAnswers([...answers]);
        if (question.items.length <= 5) {
            handleSubmit(false);
        }
    };

    const handleBack = () => {
        setActiveStep(currentStep => currentStep - 1);
    };
    const renderFooter = () => {
        if (survey && activeStep === questions.length + 1) {
            return <SurveyQuestionsAnswered />;
        } else if (questions.length) {
            return (
                <EmployeeSurveyFooter
                    activeStep={activeStep}
                    onSubmit={(skipped: boolean) => handleSubmit(skipped)}
                    onBack={() => handleBack()}
                    questionCount={totalAnswers}
                    onLanguageChange={onLanguageChange}
                    isPreviewMode={!token}
                    answers={answers}
                    questions={questions}
                    selectedLanguage={selectedLanguage}
                />
            );
        }
    };

    const renderActiveSurveyQuestion = (index: number, question: SurveyQuestionForm) => {
        if (activeStep === index + 1) {
            if (isRenderAsText(question.type)) {
                return (
                    <SurveyAnswerText
                        key={question.localId}
                        question={question}
                        answer={answers[index]}
                        selectedLanguage={selectedLanguage}
                        onSubmit={() => handleSubmit(false)}
                        onUpdate={(content, isAnonymous) => {
                            onTextAnswerUpdated(index, content, isAnonymous, question);
                        }}
                    />
                );
            } else if (isRenderAsSection(question.type)) {
                return <SurveySection key={question.localId} question={question} selectedLanguage={selectedLanguage} />;
            } else if (isRenderAsSingleChoice(question.type)) {
                return (
                    <SurveyAnswerSingleChoice
                        key={question.localId}
                        question={question}
                        answer={answers[index]}
                        selectedLanguage={selectedLanguage}
                        onSelect={selected => {
                            onSingleChoiceAnswerUpdated(index, selected, question);
                        }}
                    />
                );
            } else if (isRenderAsMultipleChoice(question.type)) {
                return (
                    <SurveyAnswerMultiChoice
                        key={question.localId}
                        question={question}
                        answer={answers[index]}
                        selectedLanguage={selectedLanguage}
                        onSelect={(selected, checked) => {
                            onMultiChoiceAnsweredUpdated(index, checked, selected, question);
                        }}
                    />
                );
            } else if (isLickertScale(question.type) || isOpinionScale(question.type) || isNPS(question.type)) {
                return question.items.length > lickertScaleLength ? (
                    <SurveyAnswerScale
                        key={question.localId}
                        selectedLanguage={selectedLanguage}
                        question={question}
                        answer={answers[index]}
                        onSelect={(selected: string) => {
                            if (!selected) {
                                return;
                            }
                            onScaleAnswerUpdated(index, selected, question);
                        }}
                    />
                ) : (
                    <SurveyAnswerSingleChoice
                        key={question.localId}
                        question={question}
                        answer={answers[index]}
                        selectedLanguage={selectedLanguage}
                        onSelect={selected => {
                            onScaleAnswerUpdated(index, selected, question);
                        }}
                    />
                );
            }
        }
    };

    const renderQuestions = () => {
        if (!questions.length) {
            return <></>;
        } else {
            return (
                <Stack
                    maxWidth={{
                        xs: 500,
                        sm: 720,
                    }}
                    flex={1}
                    justifyContent={'center'}
                    alignItems={'center'}
                    margin={'auto'}
                    pb={6}
                >
                    {questions.map((question, index) => renderActiveSurveyQuestion(index, question))}
                </Stack>
            );
        }
    };
    return (
        <Stack flex={1} gap={1}>
            {questions.length ? <EmployeeSurveyHeader /> : undefined}
            {questions.length && activeStep === 0 ? (
                <WelcomePage isPreviewMode={!token} selectedLanguage={selectedLanguage} survey={survey} surveyTemplate={editingSurveyTemplate} />
            ) : (
                renderQuestions()
            )}
            {renderFooter()}
        </Stack>
    );
};
