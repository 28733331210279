import * as yup from 'yup';
import { CalendarDayType } from '@/domain/calendar/Calendar.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';
import { DayPeriod } from '@/domain/date/Date.model';

export const getCalendarDayFormSchema = () => {
    return yup.object().shape({
        localId: yup.string().required(),
        dayType: yup.mixed<CalendarDayType>().required(),
        name: yup.string().trim().required(),
        date: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
        dayPeriod: yup.mixed<DayPeriod>().required(),
    });
};
export type CalendarDayFormValues = yup.InferType<ReturnType<typeof getCalendarDayFormSchema>>;
