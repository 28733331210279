import {
    createLeaveRequestAttachment,
    deleteLeaveRequestAttachment,
    searchLeaveRequestAttachments,
} from '@/domain/leave-request-attachment/LeaveRequestAttachment.service';
import { createMutationHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const leaveRequestAttachmentKeys = createQueryKeys('leaveRequestAttachments', {
    search: (...params: Parameters<typeof searchLeaveRequestAttachments>) => ({
        queryKey: params,
    }),
});

export const useSearchLeaveRequestAttachments = createRequiredParamsQueryHook(leaveRequestAttachmentKeys.search, searchLeaveRequestAttachments);

export const createLeaveRequestAttachmentMutation = createMutationHook(createLeaveRequestAttachment, {
    onSuccess: ({ queryClient }) => {
        // When adding a new attachment, we need to invalidate the search query to get the updated list
        return queryClient.invalidateQueries({ queryKey: leaveRequestAttachmentKeys.search._def });
    },
});

export const deleteLeaveRequestAttachmentMutation = createMutationHook(deleteLeaveRequestAttachment, {
    onSuccess: ({ queryClient }) => {
        // When deleting an attachment, we need to invalidate the search query to get the updated list
        return queryClient.invalidateQueries({ queryKey: leaveRequestAttachmentKeys.search._def });
    },
});
