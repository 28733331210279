import { getSectionDefinitions, searchSectionDefinitions } from '@/domain/section-setting/Section.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const sectionDefinitionKeys = createQueryKeys('sectionDefinitions', {
    search: (...params: Parameters<typeof searchSectionDefinitions>) => ({
        queryKey: params,
    }),
    getAll: {
        queryKey: getNull(),
    },
});

export const useGetSectionDefinitions = createQueryHook(sectionDefinitionKeys.getAll, getSectionDefinitions);

export const useSearchSectionDefinitions = createQueryHook(sectionDefinitionKeys.search, searchSectionDefinitions);
