import { ExpenseCurrency, ExpenseCurrencyCreateMutation, ExpenseCurrencyUpdateMutation } from './ExpenseCurrency.model';
import { expenseCurrencyApi } from '@/api/expense-currency/ExpenseCurrency.api';

export const searchExpenseCurrencies = (): Promise<ExpenseCurrency[]> => {
    return expenseCurrencyApi.searchExpenseCurrencies();
};

export const createExpenseCurrency = (mutation: ExpenseCurrencyCreateMutation): Promise<ExpenseCurrency> => {
    return expenseCurrencyApi.createExpenseCurrency(mutation);
};

export const updateExpenseCurrency = (expenseCurrencyId: number, mutation: ExpenseCurrencyUpdateMutation): Promise<ExpenseCurrency> => {
    return expenseCurrencyApi.updateExpenseCurrency(expenseCurrencyId, mutation);
};

export const deleteExpenseCurrency = (expenseCurrencyId: number): Promise<void> => {
    return expenseCurrencyApi.deleteExpenseCurrency(expenseCurrencyId);
};

export const getDefaultCurrency = async (): Promise<ExpenseCurrency> => {
    const currencies = await searchExpenseCurrencies();
    const defaultCurrency = currencies.find(currency => currency.defaultCurrency);
    if (!defaultCurrency) {
        throw new Error('No default currency found');
    }

    return defaultCurrency;
};

export const isDefaultCurrency = (currencyId: number, expenseCurrencies: ExpenseCurrency[]): boolean => {
    return expenseCurrencies.some(currency => currency.id === currencyId && currency.defaultCurrency);
};
