import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { DraggableItem } from '@/components/dnd/DraggableItem';
import { useReorderOnDrop } from '@/components/dnd/useReorderOnDrop';
import { Box, Button, DialogActions, DialogContent, Paper, Stack, Typography, useTheme } from '@mui/material';
import { DragDropHorizontalIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    onSave: (reorderItems: ReorderItem[]) => void;
    onClose: () => void;
    initialReorderItems: ReorderItem[];
};

export const ReorderModal: FC<Props> = ({ open, onSave, onClose, initialReorderItems }) => {
    const { t } = useTranslation();
    const [reorderItems, setReorderItems] = useState<ReorderItem[]>(initialReorderItems);

    const handleSave = () => {
        onSave(reorderItems);
    };

    return (
        <DialogWrapper open={open} onClose={onClose} header={t('general.reorder')} maxWidth='sm'>
            <DialogContent>
                <Reorder reorderItems={reorderItems} onChange={setReorderItems} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>{t('general.done')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};

export interface ReorderItem {
    id: number | string;
    name: string;
    order: number;
    hierarchy?: 'primary' | 'secondary';
}

export interface ReorderProps {
    reorderItems: ReorderItem[];
    onChange: (reorderItems: ReorderItem[]) => void;
}

export const Reorder: FC<ReorderProps> = ({ reorderItems, onChange }) => {
    useReorderOnDrop({
        list: reorderItems,
        onChange: (reorderItems: ReorderItem[]) => {
            const newReorderItems = reorderItems.map((item, index) => ({
                ...item,
                order: index,
            }));
            onChange(newReorderItems);
        },
        // Use the same keyId as the one used in the DraggableItem component
        // Use to query for the element after the drop
        // and trigger the post move flash
        keyId: 'data-item-id',
        // axis and allowedEdges are used to determine the direction of the drag and drop
        axis: 'vertical',
    });

    const getItemsByOrder = (reorderItems: ReorderItem[]) => {
        return reorderItems.sort((a, b) => a.order - b.order);
    };

    return (
        <Stack gap={2} flex={1}>
            {getItemsByOrder(reorderItems)?.map(item => (
                <DraggableItem key={item.id} id={item.id.toString()} allowedEdges={['top', 'bottom']}>
                    <Item item={item} data-item-id={item.id.toString()} />
                </DraggableItem>
            ))}
        </Stack>
    );
};

interface ItemProps {
    item: ReorderItem;
}

const Item: FC<ItemProps> = ({ item, ...rest }) => {
    const theme = useTheme();
    const hierarchy = item.hierarchy ?? 'primary';
    return (
        <>
            {hierarchy === 'primary' && (
                <Paper
                    sx={{
                        border: `1px solid ${theme.palette.grey[300]}`,
                    }}
                    {...rest}
                >
                    <Box p={1}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <DragDropHorizontalIcon style={{ minWidth: 24 }} cursor={'move'} />
                            <Typography variant='body1bold'>{item.name}</Typography>
                        </Stack>
                    </Box>
                </Paper>
            )}

            {hierarchy === 'secondary' && (
                <Paper
                    sx={{
                        border: `1px solid ${theme.palette.grey[300]}`,
                        marginLeft: 2,
                    }}
                    {...rest}
                >
                    <Box p={1}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <DragDropHorizontalIcon style={{ minWidth: 24 }} cursor={'move'} />
                            <Typography variant='body1'>{item.name}</Typography>
                        </Stack>
                    </Box>
                </Paper>
            )}
        </>
    );
};
