import * as yup from 'yup';

export const getNotificationFormSchema = () => {
    return yup
        .object()
        .shape({
            leaveNotificationsEnabled: yup.boolean(),
            leaveAttachmentNotificationsEnabled: yup.boolean(),
            planningUpdatesNotificationsEnabled: yup.boolean(),
            documentNotificationsEnabled: yup.boolean(),
            profileChangeNotificationsEnabled: yup.boolean(),
            timesheetUpdatesNotificationsEnabled: yup.boolean(),
            announcementNotificationsEnabled: yup.boolean(),
            expenseNotificationsEnabled: yup.boolean(),
        })
        .required();
};

export type NotificationFormSchema = yup.InferType<ReturnType<typeof getNotificationFormSchema>>;
