import { FC } from 'react';
import { AgGridWrapper, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useTranslation } from 'react-i18next';
import { Button, Link, Stack, useTheme } from '@mui/material';
import { CellClassParams, ColDef } from 'ag-grid-community';
import { getTodayDate } from '@/utils/datetime.util';
import { TemporaryFile } from '@/components/file-picker/FilePicker';
import { Folder, PreviewData } from '@/domain/document/Document.model';
import { Employee } from '@/domain/employee/Employee.model';
import { DocumentTag } from '@/domain/document-tag/DocumentTag.model';
import { DocumentTagChip } from '@/domain-ui/document/DocumentTagChip';

export type Row = {
    file: TemporaryFile;
    folder: Folder | undefined;
    employee: Employee | undefined;
    tags: DocumentTag[];
    isSetManually: boolean;
};

type ImportDocumentGridProps = {
    onSetEmployeeClick: () => void;
    onSetFolderClick: () => void;
    onRemoveSelectedClick: () => void;
    onSetTagClick: () => void;
    onPreviewClick: (previewData: PreviewData) => void;
} & AgGridWrapperProps<Row>;
export const ImportDocumentGrid: FC<ImportDocumentGridProps> = ({
    rowData,
    onSetEmployeeClick,
    onSetFolderClick,
    onRemoveSelectedClick,
    onSetTagClick,
    onPreviewClick,
    ...restGridProps
}) => {
    const columnsDef = useGetImportDocumentColumns({ onPreviewClick });

    return (
        <AgGridWrapper<Row>
            rowData={rowData}
            columnDefs={columnsDef}
            rowSelection={{
                mode: 'multiRow',
            }}
            getRowId={({ data }) => data.file.id}
            toolbarActions={
                <GridToolbarActions
                    onSetEmployeeClick={onSetEmployeeClick}
                    onSetFolderClick={onSetFolderClick}
                    onRemoveSelectedClick={onRemoveSelectedClick}
                    onSetTagClick={onSetTagClick}
                />
            }
            {...restGridProps}
        />
    );
};

const useGetImportDocumentColumns = ({ onPreviewClick }: { onPreviewClick: (previewData: PreviewData) => void }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const emptyCellColor = theme.palette.error.light;

    // set the background color of the cell based on the value
    const getCellBgColor = ({ value, data }: CellClassParams<Row>) => {
        if (!value) {
            return emptyCellColor;
        }
        if (data?.isSetManually) {
            // highlight the cell if the employee is set manually
            return theme.palette.warning.light;
        }
        // no background
        return '';
    };

    const columnsDef: ColDef<Row>[] = [
        {
            field: 'employee',
            type: 'employee',
            headerName: t('import_page.import_documents_page.matched_employee'),
            cellStyle: params => {
                return {
                    display: 'flex',
                    backgroundColor: getCellBgColor(params),
                };
            },
        },
        {
            field: 'folder.name',
            type: 'label',
            headerName: t('import_page.import_documents_page.matched_folder'),
            cellStyle: ({ value }) => ({
                backgroundColor: value ? '' : emptyCellColor,
            }),
        },
        {
            field: 'tags',
            headerName: t('import_page.import_documents_page.matched_tags'),
            cellRenderer: DocumentTagsCell,
            cellClass: ['display-flex'],
        },
        {
            headerName: t('import_page.import_documents_page.document_name'),
            field: 'file.name',
            onCellClicked: ({ data }) => {
                if (!data) {
                    return;
                }

                onPreviewClick({
                    // id is set to 0 because the document it's not yet imported
                    document: {
                        id: 0,
                        name: data.file.name ?? '',
                        documentType: 'DOCUMENT',
                        mimeType: data.file.type,
                        createdAt: getTodayDate(),
                    },
                    url: data.file.preview ?? '',
                });
            },
            cellRenderer: DocumentNameCell,
        },
    ];
    return columnsDef;
};

const GridToolbarActions: FC<{
    onSetEmployeeClick: () => void;
    onSetFolderClick: () => void;
    onRemoveSelectedClick: () => void;
    onSetTagClick: () => void;
}> = ({ onSetEmployeeClick, onSetFolderClick, onRemoveSelectedClick, onSetTagClick }) => {
    const { t } = useTranslation();
    return (
        <Stack direction='row' gap={1}>
            <Button onClick={onSetEmployeeClick}>{t('import_page.import_documents_page.set_employee')}</Button>

            <Button onClick={onSetFolderClick}>{t('import_page.import_documents_page.set_folder')}</Button>

            <Button onClick={onSetTagClick}>{t('import_page.import_documents_page.set_tags')}</Button>

            <Button onClick={onRemoveSelectedClick}>{t('import_page.import_documents_page.remove_selected')}</Button>
        </Stack>
    );
};

const DocumentNameCell: FC<{ value: string }> = ({ value }) => {
    return (
        <Link component={'button'} color={'primary'} underline={'always'}>
            {value}
        </Link>
    );
};

const DocumentTagsCell: FC<{ value: DocumentTag[] }> = ({ value: tags = [] }) => {
    return (
        <Stack direction={'row'} gap={1} alignItems={'center'}>
            {tags.map(tag => (
                <DocumentTagChip key={tag.id} tag={tag} />
            ))}
        </Stack>
    );
};
