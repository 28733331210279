export const MAX_NUMBER_VALUE = 9999999999.99;

export enum RoundingType {
    NEAREST_HALF = 'NEAREST_HALF',
    UPPER_HALF = 'UPPER_HALF',
    NEAREST_1_DECIMAL = 'NEAREST_1_DECIMAL',
    NEAREST_2_DECIMALS = 'NEAREST_2_DECIMALS',
}

export const getRoundingTypeTranslationKey = (roundingType: RoundingType): string => {
    switch (roundingType) {
        case RoundingType.NEAREST_1_DECIMAL:
            return 'domain.rounding_type.nearest_1_decimal';
        case RoundingType.NEAREST_2_DECIMALS:
            return 'domain.rounding_type.nearest_2_decimals';
        case RoundingType.NEAREST_HALF:
            return 'domain.rounding_type.nearest_half';
        case RoundingType.UPPER_HALF:
            return 'domain.rounding_type.upper_half';
        default:
            return '';
    }
};
export const calculatePercentage = (numerator: number, denominator: number): number => {
    if (numerator > 0 && denominator > 0) {
        const total = (numerator / denominator) * 100;
        return parseInt(total.toFixed(2));
    }
    return 0;
};

export const getSign = (number: number): string => {
    if (number > 0) {
        return '+';
    }
    return '';
};

export const getRoundedNumber = (number: number, type: RoundingType = RoundingType.NEAREST_1_DECIMAL): number => {
    if (!number || Number.isNaN(number)) {
        return 0;
    }
    switch (type) {
        case RoundingType.NEAREST_HALF:
            return Math.round(number * 2) / 2;
        case RoundingType.UPPER_HALF:
            // first round to the nearest decimal and to the upper half
            return Math.ceil(Number(Number(number).toFixed(1)) * 2) / 2;
        case RoundingType.NEAREST_2_DECIMALS:
            return Number(Number(number).toFixed(2));
        default:
            return Number(Number(number).toFixed(1));
    }
};

export const convertInputStringToNumericValueWithDot = (inputString: string): string => {
    // Delete all non-numeric characters, commas and full stops
    const numericValue = inputString.replace(/[^0-9.,]/g, '');
    // Replace commas with periods for a valid numeric format
    return numericValue.replace(/,/g, '.');
};

export const isValidNumber = (numericValueWithDot: string): boolean => {
    // Check if the value is a valid number
    return /^-?\d*\.?\d?$/.test(numericValueWithDot);
};

export const isNumberOrEmpty = (val: string): number | undefined | string => {
    const parsedValue = parseFloat(val);
    if (!isNaN(parsedValue)) {
        return parsedValue;
    }
    if (val === '') {
        return undefined;
    }
    return val;
};

export const sum = (numbers: number[]): number => {
    return numbers.reduce((acc, curr) => acc + curr, 0);
};

export const average = (numbers: number[]): number => {
    if (numbers.length === 0) {
        return 0;
    }
    return sum(numbers) / numbers.length;
};
