import { FC } from 'react';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeeReviewReminder } from '@/domain/employee-review/EmployeeReview.model';
import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { DialogContent } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { isSameDate } from '@/utils/datetime.util';
import { ColDef } from 'ag-grid-enterprise';

type ReviewCycleRemindersProps = DialogWrapperProps & {
    reminders: EmployeeReviewReminder[];
};

type GroupedReminders = Omit<EmployeeReviewReminder, 'recipient'> & {
    recipients: EmployeeReviewReminder['recipient'][];
};

export const ReviewCycleRemindersDialog: FC<ReviewCycleRemindersProps> = ({ reminders, ...restDialog }) => {
    const { t } = useTranslation();

    // format reminders to group them by type and date.
    const formattedReminders = reminders.reduce<GroupedReminders[]>((acc, currReminder) => {
        const existingReminderRow = acc.find(r => r.type === currReminder.type && isSameDate(r.sentAt, currReminder.sentAt));
        if (existingReminderRow) {
            // check if the recipient is already in the list
            if (!existingReminderRow.recipients.map(r => r.id).includes(currReminder.recipient.id)) {
                existingReminderRow.recipients.push(currReminder.recipient);
            }
            return acc;
        }

        return [...acc, { ...currReminder, recipients: [currReminder.recipient] }];
    }, []);

    // sort by date descending
    const sortedReminders = [...formattedReminders].sort((a, b) => b.sentAt.getTime() - a.sentAt.getTime());

    const columnDefs: ColDef<GroupedReminders>[] = [
        {
            headerName: t('reviews.reminder.see_reminders_dialog.type_column'),
            field: 'type',
            valueFormatter: ({ value }) => t('reviews.reminder.see_reminders_dialog.type_value', { context: value }),
            cellStyle: { fontWeight: 'bold' },
        },
        { headerName: t('reviews.reminder.see_reminders_dialog.sent_at_column'), field: 'sentAt', type: 'date' },
        {
            field: 'recipients',
            headerName: t('reviews.reminder.see_reminders_dialog.recipient_column'),
            type: 'stackedAvatars',
        },
        {
            field: 'sentBy',
            headerName: t('reviews.reminder.see_reminders_dialog.sent_by_column'),
            type: 'employee',
        },
    ];

    const height = getTableHeight({ rowsLength: reminders.length });
    return (
        <DialogWrapper {...restDialog} header={t('reviews.reminder.see_reminders_dialog.title')} maxWidth={'md'}>
            <DialogContent>
                <Stack width='100%' minHeight={300} height={height}>
                    <AgGridWrapper<GroupedReminders> rowData={sortedReminders} columnDefs={columnDefs} loading={false} />
                </Stack>
            </DialogContent>
        </DialogWrapper>
    );
};
