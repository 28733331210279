import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { LeaveRequestStatus } from '@/domain/leave-request/LeaveRequest.model';
import { PermissionFilteringConditionRule, PermissionFilteringConditionType, PolicyPermission, PolicyType } from '@/domain/permission/Permission.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';

export type PermissionFilteringConditionDisplay =
    | 'EQUALS'
    | 'NOT_EQUALS'
    | 'LOCATION_CONDITION'
    | 'LEGAL_UNIT_CONDITION'
    | 'ROLE_CONDITION'
    | 'DEPARTMENT_CONDITION'
    | 'DEPARTMENT_CHILDREN_CONDITION'
    | 'JOB_CONDITION';

export const getPermissionFilteringConditionDisplay = (
    rule: PermissionFilteringConditionRule,
    type: PermissionFilteringConditionType | undefined,
): PermissionFilteringConditionDisplay | undefined => {
    if (rule === PermissionFilteringConditionRule.SAME_AS_VIEWER) {
        if (!type) {
            return undefined;
        }
        const typeMap = {
            [PermissionFilteringConditionType.LOCATION]: 'LOCATION_CONDITION',
            [PermissionFilteringConditionType.LEGAL_UNIT]: 'LEGAL_UNIT_CONDITION',
            [PermissionFilteringConditionType.DEPARTMENT]: 'DEPARTMENT_CONDITION',
            [PermissionFilteringConditionType.JOB]: 'JOB_CONDITION',
        } as const;
        return typeMap[type as keyof typeof typeMap];
    } else if (rule === PermissionFilteringConditionRule.SAME_DEPARTMENT_TREE) {
        const typeMap = {
            [PermissionFilteringConditionType.DEPARTMENT]: 'DEPARTMENT_CHILDREN_CONDITION',
        } as const;
        return typeMap[type as keyof typeof typeMap];
    } else {
        return rule;
    }
};

/**
 * Check if the employee has a specific company based policy
 * @param policies
 * @param policy
 */
const hasCompanyPolicy = (policies: EmployeePolicy[], policy: PolicyPermission): boolean => {
    return policies?.some(up => up.policy === policy && up.policyType === PolicyType.COMPANY);
};

/**
 *  Check if the employee has a specific policy on a specific employee
 *  @param policies
 *  @param policy
 *  @param targetEmployeeId
 */
const hasPolicyOnEmployee = (policies: EmployeePolicy[], policy: PolicyPermission, targetEmployeeId: number): boolean => {
    return policies
        ?.filter(p => p.policy === policy && p.policyType === PolicyType.EMPLOYEES)
        .some(p => p.targetEmployeeIds?.length > 0 && p.targetEmployeeIds.includes(targetEmployeeId));
};

/**
 *  Check if the employee has a specific policy on other employees
 *  @param policies
 *  @param policy
 *  @param currentEmployeeId
 */
const hasPolicyOnOtherEmployees = (policies: EmployeePolicy[], policy: PolicyPermission, currentEmployeeId: number): boolean => {
    return (
        policies
            ?.filter(p => p.policy === policy && p.policyType === PolicyType.EMPLOYEES)
            .flatMap(p => p.targetEmployeeIds)
            .filter(id => id !== currentEmployeeId).length > 0
    );
};

// settings

export const hasConfigureOrganizationPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_ORGANIZATION);
};

export const hasConfigureTimeManagementPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_TIME_MANAGEMENT);
};

export const hasImportDataPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.IMPORT_DATA);
};

export const hasConfigureFolderPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_DOCUMENTS);
};

export const hasConfigureLeavePolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_LEAVES);
};

export const canManageLeaveRequestAttachment = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_LEAVE_REQUEST_ATTACHMENTS, targetEmployeeId);
};

export const hasConfigurePlanningPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_PLANNINGS);
};

export const hasConfigureObjectivesPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_OBJECTIVES);
};

export const hasConfigureThirdPartiesPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_THIRD_PARTIES);
};

export const hasManageThirdPartiesLogsPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.MANAGE_THIRD_PARTIES_LOGS);
};

export const hasConfigureExpensesPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_EXPENSES);
};

export const hasConfigureDashboardsPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_DASHBOARDS);
};

export const hasConfigureReviewPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_REVIEWS);
};

export const hasConfigurePermissionPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_PERMISSIONS);
};

export const hasConfigureEmployeeSectionPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONFIGURE_EMPLOYEE_SECTIONS);
};

export const hasDeleteEmployeePolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.DELETE_EMPLOYEES);
};

export const hasConfigurePayrollPolicy = (policies: EmployeePolicy[]): boolean => {
    return policies.some(p => p.policy === PolicyPermission.CONFIGURE_EMPLOYEE_PAYROLL && p.policyType === PolicyType.EMPLOYEES);
};

export const canConfigureCompanySettings = (policies: EmployeePolicy[]): boolean => {
    return (
        hasConfigureOrganizationPolicy(policies) ||
        hasConfigureFolderPolicy(policies) ||
        hasConfigureTimeManagementPolicy(policies) ||
        hasConfigureLeavePolicy(policies) ||
        hasConfigurePlanningPolicy(policies) ||
        hasConfigureObjectivesPolicy(policies) ||
        hasConfigureReviewPolicy(policies) ||
        hasConfigurePermissionPolicy(policies) ||
        hasConfigureEmployeeSectionPolicy(policies) ||
        hasConfigurePayrollPolicy(policies) ||
        hasConfigureThirdPartiesPolicy(policies) ||
        hasConfigureExpensesPolicy(policies) ||
        hasConfigureDashboardsPolicy(policies) ||
        canManageAnnouncements(policies) ||
        hasImportDataPolicy(policies)
    );
};

export const canManagePayroll = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.PAYROLL) &&
        (hasConfigurePayrollPolicy(policies) || canManageOtherEmployeePayrollLock(policies, currentEmployeeId))
    );
};

// Leaves permissions
export const canSeeOtherEmployeeLeaves = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.LEAVES) &&
        (hasPolicyOnOtherEmployees(policies, PolicyPermission.SEE_WHO_IS_ON_LEAVE, currentEmployeeId) ||
            canManageOtherEmployeeLeaves(policies, currentEmployeeId))
    );
};

export const canApproveRejectLeaveRequests = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.APPROVE_REJECT_LEAVE_REQUEST, targetEmployeeId);
};

export const canApproveRejectOtherEmployeeLeaveRequests = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasPolicyOnOtherEmployees(policies, PolicyPermission.APPROVE_REJECT_LEAVE_REQUEST, currentEmployeeId);
};

export const canManageApprovedLeaveRequests = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_APPROVED_LEAVE_REQUEST, targetEmployeeId);
};

export const canManagePendingLeaveRequests = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_PENDING_LEAVE_REQUEST, targetEmployeeId);
};

export const canManageLeaves = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.LEAVES) &&
        (canApproveRejectLeaveRequests(policies, targetEmployeeId) ||
            canManageApprovedLeaveRequests(policies, targetEmployeeId) ||
            canManagePendingLeaveRequests(policies, targetEmployeeId) ||
            hasConfigureLeavePolicy(policies))
    );
};

export const canManageOtherEmployeeLeaves = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.LEAVES) &&
        (canApproveRejectOtherEmployeeLeaveRequests(policies, currentEmployeeId) ||
            hasPolicyOnOtherEmployees(policies, PolicyPermission.MANAGE_PENDING_LEAVE_REQUEST, currentEmployeeId) ||
            hasPolicyOnOtherEmployees(policies, PolicyPermission.MANAGE_APPROVED_LEAVE_REQUEST, currentEmployeeId) ||
            hasPolicyOnOtherEmployees(policies, PolicyPermission.CORRECT_EMPLOYEE_BALANCE, currentEmployeeId))
    );
};

export const canManageTargetLeaveBalances = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.LEAVES) &&
        (hasPolicyOnEmployee(policies, PolicyPermission.APPROVE_REJECT_LEAVE_REQUEST, targetEmployeeId) ||
            hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_PENDING_LEAVE_REQUEST, targetEmployeeId) ||
            hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_APPROVED_LEAVE_REQUEST, targetEmployeeId) ||
            hasPolicyOnEmployee(policies, PolicyPermission.CORRECT_EMPLOYEE_BALANCE, targetEmployeeId) ||
            hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_LEAVE_TYPES, targetEmployeeId) ||
            hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_BALANCE, targetEmployeeId))
    );
};

export const canOnboardOffboardEmployees = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.ONBOARD_OFFBOARD_EMPLOYEES);
};

export const canImpersonateEmployees = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.IMPERSONATE_EMPLOYEES);
};

export const canApproveRejectProfileUpdatePendingRequest = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        [PolicyPermission.MANAGE_EMPLOYEE_PERSONAL_INFOS, PolicyPermission.MANAGE_EMPLOYEE_ADDRESSES, PolicyPermission.MANAGE_EMPLOYEE_SECTIONS].filter(p =>
            hasPolicyOnOtherEmployees(policies, p, currentEmployeeId),
        ).length > 0
    );
};

// Surveys
export const canManageSurveys = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.MANAGE_SURVEYS);
};

export const canSeeSurveyResults = (policies: EmployeePolicy[]): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.SURVEYS) && hasCompanyPolicy(policies, PolicyPermission.VIEW_SURVEY_RESULTS);
};

// Shifts
export const hasManageShiftsPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.MANAGE_SHIFTS);
};

export const hasViewShiftsPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.VIEW_SHIFTS);
};

export const canViewShifts = (policies: EmployeePolicy[]): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.PLANNING) && (hasViewShiftsPolicy(policies) || hasManageShiftsPolicy(policies));
};

// Timesheets
export const canManageOtherEmployeeTimesheets = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET) &&
        (hasPolicyOnOtherEmployees(policies, PolicyPermission.MANAGE_PENDING_TIMESHEETS, currentEmployeeId) ||
            hasPolicyOnOtherEmployees(policies, PolicyPermission.MANAGE_APPROVED_TIMESHEETS, currentEmployeeId) ||
            canApproveRejectOtherEmployeeTimesheets(policies, currentEmployeeId))
    );
};

export const canApproveRejectOtherEmployeeTimesheets = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasPolicyOnOtherEmployees(policies, PolicyPermission.APPROVE_REJECT_TIMESHEETS, currentEmployeeId);
};

export const canClockInOut = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.CLOCK_IN_TIMESHEETS, currentEmployeeId);
};

// Documents
export const canViewCompanyDocuments = (policies: EmployeePolicy[]): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS) &&
        (hasCompanyPolicy(policies, PolicyPermission.VIEW_DOCUMENTS_IN_COMPANY_FOLDER) ||
            hasCompanyPolicy(policies, PolicyPermission.MANAGE_DOCUMENTS_IN_COMPANY_FOLDER) ||
            hasCompanyPolicy(policies, PolicyPermission.DELETE_DOCUMENTS_IN_COMPANY_FOLDER))
    );
};

export const canViewEmployeeDocuments = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS) &&
        (hasPolicyOnEmployee(policies, PolicyPermission.VIEW_DOCUMENTS_IN_EMPLOYEE_FOLDER, targetEmployeeId) ||
            hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_DOCUMENTS_IN_EMPLOYEE_FOLDER, targetEmployeeId) ||
            hasPolicyOnEmployee(policies, PolicyPermission.DELETE_DOCUMENTS_IN_EMPLOYEE_FOLDER, targetEmployeeId))
    );
};

const getPoliciesOfTargetResourceID = (policies: EmployeePolicy[], targetResourceId: number): EmployeePolicy[] => {
    return policies.filter(p => p.targetResourceId === targetResourceId);
};

export const canManageDocumentsInEmployeeFolder = (policies: EmployeePolicy[], targetEmployeeId: number, targetFolderId: number): boolean => {
    const policiesOfTargetResourceID = getPoliciesOfTargetResourceID(policies, targetFolderId);

    return (
        hasRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS) &&
        hasPolicyOnEmployee(policiesOfTargetResourceID, PolicyPermission.MANAGE_DOCUMENTS_IN_EMPLOYEE_FOLDER, targetEmployeeId)
    );
};

export const canDeleteDocumentsInEmployeeFolder = (policies: EmployeePolicy[], targetEmployeeId: number, targetFolderId: number): boolean => {
    const policiesOfTargetResourceID = getPoliciesOfTargetResourceID(policies, targetFolderId);

    return (
        hasRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS) &&
        hasPolicyOnEmployee(policiesOfTargetResourceID, PolicyPermission.DELETE_DOCUMENTS_IN_EMPLOYEE_FOLDER, targetEmployeeId)
    );
};

export const canManageDocumentsInCompanyFolder = (policies: EmployeePolicy[], targetFolderId: number): boolean => {
    const policiesOfTargetResourceID = getPoliciesOfTargetResourceID(policies, targetFolderId);

    return (
        hasRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS) && hasCompanyPolicy(policiesOfTargetResourceID, PolicyPermission.MANAGE_DOCUMENTS_IN_COMPANY_FOLDER)
    );
};

export const canDeleteDocumentsInCompanyFolder = (policies: EmployeePolicy[], targetFolderId: number): boolean => {
    const policiesOfTargetResourceID = getPoliciesOfTargetResourceID(policies, targetFolderId);

    return (
        hasRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS) && hasCompanyPolicy(policiesOfTargetResourceID, PolicyPermission.DELETE_DOCUMENTS_IN_COMPANY_FOLDER)
    );
};

// Announcements
export const canSeeAnnouncements = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.VIEW_ANNOUNCEMENTS) || canManageAnnouncements(policies);
};

export const canManageAnnouncements = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.MANAGE_ANNOUNCEMENTS);
};

// Reviews
export const canManageReview = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.MANAGE_REVIEWS);
};

export const canContributeEmployeeReview = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.CONTRIBUTE_EMPLOYEE_REVIEWS);
};

export const canViewReviews = (policies: EmployeePolicy[]): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && (canManageReview(policies) || canContributeEmployeeReview(policies));
};
export const canViewReports = (policies: EmployeePolicy[]): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.REPORTS) && hasCompanyPolicy(policies, PolicyPermission.VIEW_REPORTS);
};

export const canManageReports = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.EDIT_REPORTS);
};

export const canSeeOrganizationBilling = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.ACCESS_ORGANIZATION_BILLING);
};

// Dashboard

// Complete array of policies when adding new Metrics policies
export const METRICS_POLICIES: PolicyPermission[] = [PolicyPermission.VIEW_EMPLOYEE_METRICS, PolicyPermission.VIEW_LEAVE_METRICS];
export const canViewDashboard = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return METRICS_POLICIES.some(p => hasPolicyOnEmployee(policies, p, targetEmployeeId));
};

// Objectives
export const canManageOtherEmployeeObjectives = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) &&
        (hasPolicyOnOtherEmployees(policies, PolicyPermission.CREATE_OBJECTIVES, currentEmployeeId) ||
            hasPolicyOnOtherEmployees(policies, PolicyPermission.EDIT_OBJECTIVES, currentEmployeeId) ||
            hasPolicyOnOtherEmployees(policies, PolicyPermission.VIEW_OBJECTIVES, currentEmployeeId))
    );
};

// EMPLOYEES
export const canViewEmployeesDirectory = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnOtherEmployees(policies, PolicyPermission.VIEW_EMPLOYEES, targetEmployeeId);
};

export const canViewEmployeePhoneNumbers = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_PHONE_NUMBERS, targetEmployeeId);
};

export const canViewEmployeeEmails = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_EMAILS, targetEmployeeId);
};

/**
 * Check if the employee can manage employees
 * @param policies
 * @param targetEmployeeId
 */
export const canManageEmployees = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_EMPLOYEE_BASIC_INFOS, targetEmployeeId);
};

/**
 * Check if the employee can view employees
 * @param policies
 * @param targetEmployeeId
 */
export const canViewEmployeesBasicInfo = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_BASIC_INFOS, targetEmployeeId) || canManageEmployees(policies, targetEmployeeId);
};

export const canViewOtherEmployeeBasicInfo = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasPolicyOnOtherEmployees(policies, PolicyPermission.VIEW_EMPLOYEE_BASIC_INFOS, currentEmployeeId);
};

export const canViewEmployeeContracts = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_CONTRACTS, targetEmployeeId) || canManageEmployeeContracts(policies, targetEmployeeId);
};

export const canViewEmployeeJobDescription = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_JOBS_DESCRIPTION, targetEmployeeId);
};

export const canManageEmployeeContracts = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_EMPLOYEE_CONTRACTS, targetEmployeeId);
};

export const canManageOtherEmployeeContracts = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasPolicyOnOtherEmployees(policies, PolicyPermission.MANAGE_EMPLOYEE_CONTRACTS, currentEmployeeId);
};

export const canViewCustomSections = (policies: EmployeePolicy[], targetEmployeeId: number, targetSectionId: number): boolean => {
    return (
        hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_SECTIONS, targetEmployeeId) ||
        canManageEmployeeCustomSections(policies, targetEmployeeId, targetSectionId)
    );
};

export const canManageEmployeeCustomSections = (policies: EmployeePolicy[], targetEmployeeId: number, targetSectionId: number): boolean => {
    const policiesOfTargetResourceID = getPoliciesOfTargetResourceID(policies, targetSectionId);
    return hasPolicyOnEmployee(policiesOfTargetResourceID, PolicyPermission.MANAGE_EMPLOYEE_SECTIONS, targetEmployeeId);
};
export const canManagePendingEmployeeCustomSections = (policies: EmployeePolicy[], targetEmployeeId: number, targetSectionId: number): boolean => {
    const policiesOfTargetResourceID = getPoliciesOfTargetResourceID(policies, targetSectionId);
    return hasPolicyOnEmployee(policiesOfTargetResourceID, PolicyPermission.MANAGE_PENDING_EMPLOYEE_SECTIONS, targetEmployeeId);
};

export const canViewEmployeePersonalInfos = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_PERSONAL_INFOS, targetEmployeeId) ||
        canManageEmployeePersonalInfos(policies, targetEmployeeId)
    );
};

export const canManagePendingEmployeePersonalInfos = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_PENDING_EMPLOYEE_PERSONAL_INFOS, targetEmployeeId) ||
        canManageEmployeePersonalInfos(policies, targetEmployeeId)
    );
};

export const canManageEmployeePersonalInfos = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_EMPLOYEE_PERSONAL_INFOS, targetEmployeeId);
};

export const canViewEmployeeAddresses = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_ADDRESSES, targetEmployeeId);
};

export const canManagePendingEmployeeAddresses = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_PENDING_EMPLOYEE_ADDRESSES, targetEmployeeId);
};

export const canManageEmployeeAddresses = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_EMPLOYEE_ADDRESSES, targetEmployeeId);
};

export const canManageEmployeeAvatars = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_EMPLOYEE_AVATARS, targetEmployeeId);
};

// TODO: logic related to the leave domain should be moved to the leave service
export const canEditLeaveRequest = (status: LeaveRequestStatus, policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        (status === 'PENDING' && canManagePendingLeaveRequests(policies, targetEmployeeId)) ||
        (status === 'APPROVED' && canManageApprovedLeaveRequests(policies, targetEmployeeId))
    );
};

export const canSeeEmployeeLeaveTypes = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_LEAVE_TYPES, targetEmployeeId);
};

export const canUpdateObjectiveStatus = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.UPDATE_OBJECTIVE_STATUS, targetEmployeeId);
};

export const canCorrectEmployeeBalance = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.CORRECT_EMPLOYEE_BALANCE, targetEmployeeId);
};

export const canCorrectOtherEmployeeBalance = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasPolicyOnOtherEmployees(policies, PolicyPermission.CORRECT_EMPLOYEE_BALANCE, currentEmployeeId);
};

// Reviews
export const canAccessEmployeeReviews = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) &&
        hasPolicyOnEmployee(policies, PolicyPermission.ACCESS_EMPLOYEE_REVIEWS, targetEmployeeId) &&
        canContributeEmployeeReview(policies)
    );
};

export const canAccessOtherEmployeeReviews = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && hasPolicyOnOtherEmployees(policies, PolicyPermission.ACCESS_EMPLOYEE_REVIEWS, currentEmployeeId)
    );
};

export const canSeeOtherEmployeeSkills = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && hasPolicyOnOtherEmployees(policies, PolicyPermission.VIEW_EMPLOYEE_SKILLS, currentEmployeeId);
};

export const canViewEmployeeSkills = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_SKILLS, targetEmployeeId);
};

export const canCreateOneShotReviews = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.CREATE_ONE_SHOT_REVIEWS, targetEmployeeId);
};

// Objectives Permissions
export const canViewObjectives = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && hasPolicyOnEmployee(policies, PolicyPermission.VIEW_OBJECTIVES, targetEmployeeId);
};
export const canEditObjectives = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.EDIT_OBJECTIVES, targetEmployeeId);
};
export const canCreateObjectives = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.CREATE_OBJECTIVES, targetEmployeeId);
};

export const canCreateOtherObjectives = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasPolicyOnOtherEmployees(policies, PolicyPermission.CREATE_OBJECTIVES, currentEmployeeId);
};

// Timesheet
export const canViewTimesheets = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET) &&
        (hasPolicyOnEmployee(policies, PolicyPermission.VIEW_TIMESHEETS, targetEmployeeId) ||
            canManageApprovedTimesheets(policies, targetEmployeeId) ||
            canManagePendingTimesheets(policies, targetEmployeeId) ||
            canApproveRejectTimesheets(policies, targetEmployeeId))
    );
};

export const canApproveRejectTimesheets = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.APPROVE_REJECT_TIMESHEETS, targetEmployeeId);
};

export const canManageApprovedTimesheets = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_APPROVED_TIMESHEETS, targetEmployeeId);
};

export const canManagePendingTimesheets = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_PENDING_TIMESHEETS, targetEmployeeId);
};

export const canManageTimesheetPayments = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_TIMESHEET_PAYMENTS, targetEmployeeId) ||
        hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_PENDING_TIMESHEET_PAYMENTS, targetEmployeeId)
    );
};

export const canManageTimesheetAdjustments = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_TIMESHEET_ADJUSTMENTS, targetEmployeeId);
};

export const canManageOtherEmployeePayrollLock = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.PAYROLL) &&
        hasPolicyOnOtherEmployees(policies, PolicyPermission.MANAGE_EMPLOYEE_PAYROLL_LOCKS, currentEmployeeId)
    );
};

export const canLockEmployeePayrollLock = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.PAYROLL) && hasPolicyOnEmployee(policies, PolicyPermission.LOCK_EMPLOYEE_PAYROLL_LOCKS, targetEmployeeId);
};

export const canViewEmployeeBirthdays = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasPolicyOnEmployee(policies, PolicyPermission.VIEW_EMPLOYEE_BIRTHDAYS, targetEmployeeId);
};

export const hasViewEmployeeBirthdaysPolicy = (policies: EmployeePolicy[]): boolean => {
    return policies.some(p => p.policy === PolicyPermission.VIEW_EMPLOYEE_BIRTHDAYS && p.policyType === PolicyType.EMPLOYEES);
};

export const hasViewEmployeesWorkAnniversariesPolicy = (policies: EmployeePolicy[]): boolean => {
    return policies.some(p => p.policy === PolicyPermission.VIEW_EMPLOYEE_WORK_ANNIVERSARIES && p.policyType === PolicyType.EMPLOYEES);
};

export const hasAccessRogerHelpCenterPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.ACCESS_ROGER_HELP_CENTER);
};

export const hasAccessOrganizationChartPolicy = (policies: EmployeePolicy[]): boolean => {
    return hasCompanyPolicy(policies, PolicyPermission.VIEW_ORGANIZATION_CHART);
};

export const canViewProfilePersonalInfoTab = (policies: EmployeePolicy[], targetEmployeeId: number, targetSectionIds: number[]): boolean => {
    return (
        canViewEmployeesBasicInfo(policies, targetEmployeeId) ||
        canViewEmployeePersonalInfos(policies, targetEmployeeId) ||
        canViewEmployeeAddresses(policies, targetEmployeeId) ||
        targetSectionIds.some(sectionId => canViewCustomSections(policies, targetEmployeeId, sectionId))
    );
};

export const canViewProfileJobInfoTab = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return canViewEmployeeContracts(policies, targetEmployeeId);
};

export const canViewProfileJobDescriptionTab = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.REVIEWS) && canViewEmployeeJobDescription(policies, targetEmployeeId);
};

export const canViewAtLeastOneProfileInfoTab = (policies: EmployeePolicy[], targetEmployeeId: number, targetSectionIds: number[]): boolean => {
    return (
        canViewProfilePersonalInfoTab(policies, targetEmployeeId, targetSectionIds) ||
        canViewProfileJobInfoTab(policies, targetEmployeeId) ||
        canManageLeaves(policies, targetEmployeeId) ||
        canViewTimesheets(policies, targetEmployeeId) ||
        canViewShifts(policies) ||
        canAccessEmployeeReviews(policies, targetEmployeeId) ||
        canViewObjectives(policies, targetEmployeeId) ||
        canAccessEmployeeReviews(policies, targetEmployeeId) ||
        canViewEmployeeDocuments(policies, targetEmployeeId)
    );
};

// EXPENSE
export const canManageExpenses = (policies: EmployeePolicy[], targetEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.EXPENSES) && hasPolicyOnEmployee(policies, PolicyPermission.MANAGE_PENDING_EXPENSE_REQUESTS, targetEmployeeId)
    );
};

export const canApproveDeclineExpenses = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return (
        hasRealmFeatureEnabled(RealmFeaturesType.EXPENSES) &&
        hasPolicyOnOtherEmployees(policies, PolicyPermission.APPROVE_DECLINE_EXPENSE_REQUESTS, currentEmployeeId)
    );
};

export const canPayExpenseRequests = (policies: EmployeePolicy[], currentEmployeeId: number): boolean => {
    return hasRealmFeatureEnabled(RealmFeaturesType.EXPENSES) && hasPolicyOnOtherEmployees(policies, PolicyPermission.PAY_EXPENSE_REQUESTS, currentEmployeeId);
};
