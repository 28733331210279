import { handleError } from '@/utils/api.util';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showSnackbar } from '@/utils/snackbar.util';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FieldText } from '@/components/form/field-text/FieldText';
import { DeclineTimesheetMutation } from '@/domain/timesheet/Timesheet.model';
import { declineTimesheet } from '@/domain/timesheet/Timesheet.service';

type Props = {
    timesheetIds: number[];
    onCancel: () => void;
    onSuccess: () => void;
};

export const DeclineTimesheetsDialog: FC<Props> = ({ timesheetIds, onCancel, onSuccess }) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        declineComment: yup.string().trim(),
    });

    const { handleSubmit, control, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            declineComment: '',
        },
    });

    type FormValues = yup.InferType<typeof schema>;

    const handleOnDecline = async (values: FormValues) => {
        const declineRequest: DeclineTimesheetMutation = {
            timesheetIds: timesheetIds,
            comment: values.declineComment,
        };
        try {
            await declineTimesheet(declineRequest);
            showSnackbar(t('decline_timesheets_dialog.declined_snackbar'), 'success');
            reset();
            onSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <DialogWrapper open={!!timesheetIds} onClose={() => onCancel()} header={t('decline_timesheets_dialog.title')}>
            <Stack component={DialogContent}>
                <FormControlLabel
                    label={t('decline_timesheets_dialog.comment')}
                    name={'declineComment'}
                    control={
                        <FieldText
                            name='declineComment'
                            control={control}
                            fullWidth
                            placeholder={t('decline_timesheets_dialog.place_provide_decline_reason')}
                            textFieldProps={{
                                slotProps: {
                                    input: {
                                        multiline: true,
                                        minRows: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnDecline, console.error)}>{t('decline_timesheets_dialog.decline')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};
