import {
    getEmployeeDailyWorkingTimeBalances,
    getEmployeeWeeklyWorkingTimeBalances,
    getEmployeeWorkingTimeBalances,
    getEmployeeWorkingTimeWarnings,
} from '@/domain/timesheet-insight/TimesheetInsight.service';
import { createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const timesheetInsightKeys = createQueryKeys('timesheetInsight', {
    workingTimeBalances: (...params: Parameters<typeof getEmployeeWorkingTimeBalances>) => ({
        queryKey: params,
    }),
    dailyWorkingTimeBalances: (...params: Parameters<typeof getEmployeeDailyWorkingTimeBalances>) => ({
        queryKey: params,
    }),
    weeklyTimeBalances: (...params: Parameters<typeof getEmployeeWeeklyWorkingTimeBalances>) => ({
        queryKey: params,
    }),
    workingTimeWarnings: (...params: Parameters<typeof getEmployeeWorkingTimeWarnings>) => ({
        queryKey: params,
    }),
});

export const useGetEmployeeWorkingTimeWarnings = createRequiredParamsQueryHook(timesheetInsightKeys.workingTimeWarnings, getEmployeeWorkingTimeWarnings);

export const useGetEmployeeWorkingTimeBalance = createRequiredParamsQueryHook(timesheetInsightKeys.workingTimeBalances, getEmployeeWorkingTimeBalances);

export const useGetEmployeeDailyWorkingTimeBalances = createRequiredParamsQueryHook(
    timesheetInsightKeys.dailyWorkingTimeBalances,
    getEmployeeDailyWorkingTimeBalances,
);

export const useGetEmployeeWeeklyWorkingTimeBalances = createRequiredParamsQueryHook(
    timesheetInsightKeys.weeklyTimeBalances,
    getEmployeeWeeklyWorkingTimeBalances,
);
