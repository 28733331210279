import { AdditionalActionPaper } from '@/components/autocomplete-wrapper/AdditionalActionPaper';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldTreeSelect } from '@/components/form/field-tree-select/FieldTreeSelect';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { RecursiveValue } from '@/components/tree-select/TreeSelect';
import { Department } from '@/domain/department/Department.model';
import { mapDepartmentsToRecursiveValues } from '@/domain/department/Department.util';
import { Employee } from '@/domain/employee/Employee.model';
import { NOT_TERMINATED_EMPLOYMENT_STATUSES } from '@/domain/employment/Employment.model';
import { hasConfigureOrganizationPolicy } from '@/domain/permission/Permission.service';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetJobs } from '@/hooks/job/Job.hook';
import { JobsManagement } from '@/page/setting/job/JobsManagement';
import { useGetLocations } from '@/hooks/location/Location.hook';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation, getRealmLanguage } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { FC, HTMLAttributes, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const LocationAutocomplete: FC<{ name: string }> = ({ name }) => {
    const { t } = useTranslation();
    return (
        <Stack direction='column'>
            <Typography variant='body1'>
                {t('employee.employment.location')}
                {'*'}
            </Typography>
            <LocationAutocompleteController name={name} />
        </Stack>
    );
};

export const LocationAutocompleteController: FC<{ name: string }> = ({ name }) => {
    const { data: locations = [], isLoading } = useGetLocations();
    const { control } = useFormContext();

    return (
        <FieldSelect
            name={name}
            control={control}
            getOptionLabel={location => location?.name}
            loading={isLoading}
            options={locations}
            getOptionKey={location => location.id}
            isOptionEqualToValue={isOptionEqualToValue}
            fullWidth
        />
    );
};

export const DepartmentAutocomplete: FC<{ onDepartmentChange?: (d: Department | null) => void; name: string }> = props => {
    const { t } = useTranslation();

    return (
        <Stack direction='column'>
            <Typography variant='body1'>
                {t('employee.employment.department')}
                {'*'}
            </Typography>
            <DepartmentAutocompleteController {...props} />
        </Stack>
    );
};

export const DepartmentAutocompleteController: FC<{
    onDepartmentChange?: (d: Department | null) => void;
    name: string;
}> = ({ onDepartmentChange, name }) => {
    const { data: departments = [], isLoading } = useGetDepartments();

    const { control } = useFormContext<Record<typeof name, RecursiveValue | null>>();

    const departmentTreeItems = mapDepartmentsToRecursiveValues(departments);

    const handleDepartmentChange = (newValue: RecursiveValue | null) => {
        if (onDepartmentChange) {
            const departmentOption = newValue ? departments.find(d => d.id === newValue.id) : undefined;
            onDepartmentChange(departmentOption ?? getNull());
        }
    };

    return (
        <Stack flex={1}>
            <FieldTreeSelect
                name={name}
                control={control}
                itemsData={departmentTreeItems}
                loading={isLoading}
                onChange={newValue => {
                    handleDepartmentChange(newValue);
                }}
            />
        </Stack>
    );
};

export const JobsAutocomplete: FC<{ name: string }> = props => {
    const { t } = useTranslation();

    return (
        <Stack direction='column'>
            <Typography variant='body1'>
                {t('employee.employment.job')}
                {'*'}
            </Typography>
            <JobsAutocompleteController {...props} />
        </Stack>
    );
};

export const JobsAutocompleteController: FC<{ name: string }> = ({ name }) => {
    const { data: jobs = [], refetch } = useGetJobs();
    const { control } = useFormContext();
    const [openJobCreationModal, setOpenJobCreationModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const policies = useCurrentPolicies();
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());
    const onCloseJobCreationModal = () => {
        setOpenJobCreationModal(false);
        refetch().catch(handleError);
    };

    const getPaperComponent = (param: HTMLAttributes<HTMLElement>) => (
        <AdditionalActionPaper
            {...param}
            displayAdditionalAction={hasConfigureOrganizationPolicy(policies)}
            additionalActionLabel={t('general.manage_list')}
            onAdditionalActionClick={() => setOpenJobCreationModal(true)}
        />
    );

    return (
        <Stack flex={1}>
            <FieldSelect
                name={name}
                getOptionLabel={job => getLabelTranslation(job?.name)}
                options={jobs}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth
                control={control}
                autocompleteProps={{
                    PaperComponent: getPaperComponent,
                }}
            />
            {openJobCreationModal && (
                <DialogWrapper
                    maxWidth={'md'}
                    open={true}
                    onClose={onCloseJobCreationModal}
                    header={
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
                            <Typography variant='body1bold'>{t('employee.employment.job')}</Typography>
                            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
                        </Stack>
                    }
                >
                    <DialogContent>
                        <JobsManagement translationLanguage={translationLanguage} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCloseJobCreationModal} fullWidth>
                            {t('general.save')}
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            )}
        </Stack>
    );
};

export const ManagerAutocomplete: FC<{ excludeEmployeeIds?: Employee['id'][]; name: string }> = props => {
    const { t } = useTranslation();

    return (
        <Stack direction='column'>
            <Typography variant='body1'>{t('employee.employment.manager')}</Typography>
            <ManagerAutocompleteController {...props} />
        </Stack>
    );
};

export const ManagerAutocompleteController: FC<{
    excludeEmployeeIds?: Employee['id'][];
    name: string;
}> = ({ excludeEmployeeIds = [], name }) => {
    const { data: employees } = useGetEmployees({ statuses: NOT_TERMINATED_EMPLOYMENT_STATUSES });
    const { control } = useFormContext<Record<string, Employee[]>>();
    return (
        <FieldSelect
            name={name}
            control={control}
            multiple
            getOptionLabel={employee => employee?.displayName}
            getOptionKey={option => option.id}
            options={employees?.filter(e => !excludeEmployeeIds.includes(e.id)) ?? []}
            isOptionEqualToValue={isOptionEqualToValue}
            fullWidth
        />
    );
};

const isOptionEqualToValue = <T extends { id: number }>(option: T, value: T) => {
    return option?.id === value?.id;
};
