import { searchPlanningPosition } from '@/domain/planning-position/PlanningPosition.service';
import { createQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const planningPositionKeys = createQueryKeys('planningPositions', {
    search: (...optionalParam: Parameters<typeof searchPlanningPosition>) => ({
        queryKey: [optionalParam ?? {}],
    }),
});

export const useGetPlanningPositions = createQueryHook(planningPositionKeys.search, searchPlanningPosition);
