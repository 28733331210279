import { getLeaveBalance, getLeaveTypeHistories, getLeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const leaveTypeHistoryKeys = createQueryKeys('leaveTypeHistories', {
    search: (...params: Parameters<typeof getLeaveTypeHistories>) => ({
        queryKey: params,
    }),
    leaveBalance: (...params: Parameters<typeof getLeaveBalance>) => ({
        queryKey: params,
    }),
    getByIdAndEmployee: (...params: Parameters<typeof getLeaveTypeHistory>) => ({
        queryKey: params,
    }),
});

export const useGetLeaveTypeHistories = createQueryHook(leaveTypeHistoryKeys.search, getLeaveTypeHistories);

export const useGetLeaveBalance = createRequiredParamsQueryHook(leaveTypeHistoryKeys.leaveBalance, getLeaveBalance);

export const useGetLeaveTypeHistory = createRequiredParamsQueryHook(leaveTypeHistoryKeys.getByIdAndEmployee, getLeaveTypeHistory);
