import { getThirdParty, getThirdPartyIntegrationLogs, searchThirdParties, searchThirdPartyIntegrationLogs } from '@/domain/third-party/ThirdParty.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const thirdPartyKeys = createQueryKeys('thirdParty', {
    search: {
        queryKey: getNull(),
    },
    getById: (...params: Parameters<typeof getThirdParty>) => ({
        queryKey: params,
    }),
    integrationLogs: (...params: Parameters<typeof getThirdPartyIntegrationLogs>) => ({
        queryKey: params,
    }),
    searchThirdPartyIntegrationLogs: (...params: Parameters<typeof searchThirdPartyIntegrationLogs>) => ({
        queryKey: params,
    }),
});

export const useGetThirdParties = createQueryHook(thirdPartyKeys.search, searchThirdParties);

export const useGetThirdParty = createRequiredParamsQueryHook(thirdPartyKeys.getById, getThirdParty);

export const useGetThirdPartyIntegrationLogs = createRequiredParamsQueryHook(thirdPartyKeys.integrationLogs, getThirdPartyIntegrationLogs);

export const useSearchThirdPartyIntegrationLogs = createRequiredParamsQueryHook(
    thirdPartyKeys.searchThirdPartyIntegrationLogs,
    searchThirdPartyIntegrationLogs,
);
