import { EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { DayOfWeek } from '@/utils/datetime.util';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';

import { Calendar } from '@/domain/calendar/Calendar.model';
import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { getFormValueByFieldType } from '@/domain/section-setting/Section.service';
import { useScrollToFirstError } from '@/hooks/useScrollToFirstError';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { BasicInfosSection } from '@/page/people/onboarding/employee/BasicInfosSection';
import { ContractSection } from '@/page/people/onboarding/employee/ContractSection';
import { EmploymentSection } from '@/page/people/onboarding/employee/EmploymentSection';
import { WorkingPatternSection } from '@/page/people/onboarding/employee/WorkingPatternSection';
import { PersonalInfosSection } from '@/page/people/onboarding/employee/PersonalInfosSection';
import { getOnboardingEmployeeSchema, OnboardingProfileStepFormValues } from '@/page/people/onboarding/employee/OnboardingEmployeeForm.schema';

type BaseProfileProps = {
    onSubmitBaseProfileForm: (baseProfileFormValuesData: OnboardingProfileStepFormValues) => void;
    baseProfileFormValues: Partial<OnboardingProfileStepFormValues>;
    weeklyWorkingTimes: WeeklyWorkingTime[];
    calendars: Calendar[];
    sectionDefinitions: SectionDefinition[];
};

export const OnboardingEmployeeForm: FC<BaseProfileProps> = ({
    onSubmitBaseProfileForm,
    baseProfileFormValues,
    weeklyWorkingTimes,
    calendars,
    sectionDefinitions,
}) => {
    const personalInfoSection = sectionDefinitions.find(section => section.type === SectionType.PERSONAL_INFO);

    const getDefaultValues = useCallback((): Partial<OnboardingProfileStepFormValues> => {
        return {
            // Contract
            contractStartDate: baseProfileFormValues?.contractStartDate,
            probationEndDate: baseProfileFormValues?.probationEndDate,
            contractType: baseProfileFormValues?.contractType,
            contractEndDate: baseProfileFormValues?.contractEndDate,
            // Personal info
            ...personalInfoSection?.fields.reduce((acc, field) => {
                return {
                    ...acc,
                    [field.formId]: getFormValueByFieldType({
                        sectionDefinition: personalInfoSection,
                        fieldType: field.fieldType,
                        formValues: baseProfileFormValues,
                    }),
                };
            }, {}),
            // Employment
            location: baseProfileFormValues?.location,
            job: baseProfileFormValues?.job,
            jobFamily: baseProfileFormValues?.jobFamily,
            department: baseProfileFormValues?.department,
            managers: baseProfileFormValues?.managers ?? [],
            employmentCostCenters: baseProfileFormValues?.employmentCostCenters ?? [],
            timesheetSettingId: baseProfileFormValues?.timesheetSettingId,
            //  Working pattern
            ...getDefaultEmployeeWorkingPatternValues(baseProfileFormValues, calendars),
        };
    }, [baseProfileFormValues, personalInfoSection, calendars]);

    const defaultValues = getDefaultValues();

    const schema = getOnboardingEmployeeSchema(sectionDefinitions);

    const formMethods = useForm<OnboardingProfileStepFormValues>({
        resolver: yupResolver(schema),
        defaultValues,
        shouldFocusError: false,
    });
    const { reset: resetForm } = formMethods;
    const { resetFocus } = useScrollToFirstError(formMethods.formState);

    const onError: SubmitErrorHandler<OnboardingProfileStepFormValues> = errors => {
        console.error(errors);
        resetFocus();
    };

    useDeepCompareEffect(() => {
        const selectedWeeklyWorkingTimeId = baseProfileFormValues.weeklyWorkingTimeId;

        resetForm({
            ...getDefaultValues(),
            weeklyWorkingTimeId: weeklyWorkingTimes.length === 1 ? weeklyWorkingTimes[0].id : selectedWeeklyWorkingTimeId,
        });
    }, [weeklyWorkingTimes, getDefaultValues, resetForm]);

    return (
        <form id='base-profile-form' onSubmit={formMethods.handleSubmit(onSubmitBaseProfileForm, onError)}>
            <Stack direction='column' spacing={2}>
                <FormProvider {...formMethods}>
                    {sectionDefinitions.map(sectionDefinition => {
                        if (sectionDefinition.type === SectionType.BASIC_INFO) {
                            return (
                                <BasicInfosSection
                                    key={sectionDefinition.id}
                                    baseProfileFormValues={baseProfileFormValues}
                                    sectionDefinition={sectionDefinition}
                                />
                            );
                        }
                        if (sectionDefinition.type === SectionType.PERSONAL_INFO && hasRealmFeatureEnabled(RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE)) {
                            return (
                                <PersonalInfosSection
                                    key={sectionDefinition.id}
                                    baseProfileFormValues={baseProfileFormValues}
                                    sectionDefinition={sectionDefinition}
                                />
                            );
                        }
                    })}
                    <ContractSection />
                    <EmploymentSection />
                    <WorkingPatternSection weeklyWorkingTimes={weeklyWorkingTimes} calendars={calendars} />
                </FormProvider>
            </Stack>
        </form>
    );
};

const getDefaultEmployeeWorkingPatternValues = (baseProfileFormValues: Partial<OnboardingProfileStepFormValues>, calendars: Calendar[]) => {
    const defaultDays: DayOfWeek[] = Object.keys(DayOfWeek)
        .map((k: string) => DayOfWeek[k as keyof typeof DayOfWeek])
        .filter(dayOfWeek => dayOfWeek !== DayOfWeek.SATURDAY && dayOfWeek !== DayOfWeek.SUNDAY);

    const hasTimesheetLeavesRealmFeature = hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET) || hasRealmFeatureEnabled(RealmFeaturesType.LEAVES);

    const defaultEmployeeWorkingPatternType = hasTimesheetLeavesRealmFeature ? EmployeeWorkingPatternType.FIXED : EmployeeWorkingPatternType.RATE;

    return {
        type: baseProfileFormValues?.type ?? defaultEmployeeWorkingPatternType,
        rate: baseProfileFormValues?.rate ?? 100,
        workingDays: baseProfileFormValues?.workingDays ?? {
            morningWorkingDays: defaultDays,
            afternoonWorkingDays: defaultDays,
        },
        workingPatternTemplate: baseProfileFormValues?.workingPatternTemplate,
        startingWeek: baseProfileFormValues?.workingPatternTemplate ? baseProfileFormValues?.startingWeek : undefined,
        weeklyWorkingTimeId: baseProfileFormValues?.weeklyWorkingTimeId,
        calendarId: calendars.length === 1 ? calendars[0].id : baseProfileFormValues?.calendarId,
    };
};
