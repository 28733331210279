import { thirdPartyMappingAPI } from '@/api/third-party-mapping/ThirdPartyMapping.api';
import {
    ThirdPartyMapping,
    ThirdPartyMappingMutation,
    ThirdPartyMappingValue,
    ThirdPartyMappingValueRequest,
} from '@/domain/third-party-mapping/ThirdPartyMapping.model';

export const searchThirdPartyMappings = async (): Promise<ThirdPartyMapping[]> => {
    return thirdPartyMappingAPI.searchThirdPartyMappings();
};

export const createThirdPartyMapping = async (request: ThirdPartyMappingMutation): Promise<ThirdPartyMapping> => {
    return thirdPartyMappingAPI.createThirdPartyMapping(request);
};

export const deleteThirdPartyMapping = async (thirdPartyMappingId: number): Promise<void> => {
    return thirdPartyMappingAPI.deleteThirdPartyMapping(thirdPartyMappingId);
};

export const getThirdPartyMappingValues = async (thirdPartyMappingId: number): Promise<ThirdPartyMappingValue[]> => {
    return thirdPartyMappingAPI.getThirdPartyMappingValues(thirdPartyMappingId);
};

export const saveThirdPartyMappingValues = async ({
    thirdPartyMappingId,
    values,
}: {
    thirdPartyMappingId: number;
    values: ThirdPartyMappingValueRequest[];
}): Promise<void> => {
    return thirdPartyMappingAPI.saveThirdPartyMappingValues(thirdPartyMappingId, values);
};
