import { ReorderItem, ReorderModal } from '@/components/reorder-modal/ReorderModal';
import { mapToReorderRequest } from '@/domain/common';
import { Folder } from '@/domain/document/Document.model';
import { updateFolderOrder } from '@/domain/document/Document.service';
import { useGetFolders } from '@/hooks/document/Document.hook';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { getLabelTranslation } from '@/utils/language.util';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const FoldersSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

    const { data: folders = [], refetch } = useGetFolders();

    const handleOrderModal = () => {
        setIsOrderModalOpen(true);
    };

    const config: ConfigType<Folder> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('folder_settings_page.folder')}`,
            defaultButtonCaption: t('permissions_setting_page.reorder'),
            defaultButtonAction: () => handleOrderModal(),
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    field: 'name',
                    type: 'label',
                    headerName: t('folder_settings_page.table.folderName'),
                },
                {
                    field: 'description',
                    type: 'label',
                    headerName: t('folder_settings_page.table.folderDescription'),
                },
                {
                    field: 'folderType',
                    headerName: t('folder_settings_page.table.folderType'),
                    valueFormatter: ({ value }) => t('folder_settings_page.folder_type', { context: value }),
                },
            ],
        },
    };

    const onSaveReorder = (reorderItems: ReorderItem[]) => {
        const reorderRequest = mapToReorderRequest(reorderItems);

        updateFolderOrder(reorderRequest).then(() => {
            refetch();
            setIsOrderModalOpen(false);
        });
    };

    const getReorderItems = (items: Folder[]) => {
        return items.map(item => {
            return {
                id: item.id,
                name: getLabelTranslation(item.name),
                order: item.order,
            };
        });
    };

    return (
        <>
            <CompanySettingsLayout options={config} data={folders} />
            {isOrderModalOpen && (
                <ReorderModal
                    open={isOrderModalOpen}
                    initialReorderItems={getReorderItems(folders)}
                    onSave={onSaveReorder}
                    onClose={() => {
                        setIsOrderModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
