import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridWrapper, AgGridWrapperProps } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { ExpenseDistanceRate } from '@/domain/expense-distance-rate/ExpenseDistanceRate.model';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';

type ExpenseDistanceRateGridProps = {
    distanceRates: ExpenseDistanceRate[];
    onRowClick: (distanceRate: ExpenseDistanceRate) => void;
    onDeleteDistanceRate: (distanceRate: ExpenseDistanceRate) => Promise<void>;
} & AgGridWrapperProps<ExpenseDistanceRate>;

export const ExpenseDistanceRateSettingsGrid: FC<ExpenseDistanceRateGridProps> = ({ distanceRates, onRowClick, onDeleteDistanceRate, ...restGridProps }) => {
    const { t } = useTranslation();

    const actionMenuRenderer = ({ data }: ICellRendererParams<ExpenseDistanceRate>) => {
        if (!data) {
            return <></>;
        }

        return (
            <ActionMenuRenderer distanceRate={data} onEdit={onRowClick} onDelete={distanceRate => onDeleteDistanceRate(distanceRate).catch(console.error)} />
        );
    };

    const handleRowClick = (params: RowClickedEvent<ExpenseDistanceRate>) => {
        if (params.event?.defaultPrevented || !params.data) {
            return;
        }
        onRowClick(params.data);
    };

    const columns: ColDef<ExpenseDistanceRate>[] = [
        {
            field: 'value',
            headerName: t('expense_distance_rate_settings_page.distance_rate_value_header'),
            cellClass: ['display-flex'],
        },
        {
            field: 'effectiveDate',
            headerName: t('expense_distance_rate_settings_page.distance_rate_effective_date_header'),
            type: 'date',
            cellClass: ['display-flex'],
        },
        {
            type: 'actionMenu',
            cellRenderer: actionMenuRenderer,
        },
    ];

    return <AgGridWrapper rowData={distanceRates} columnDefs={columns} onRowClicked={handleRowClick} {...restGridProps} />;
};

type ActionMenuRendererProps = {
    distanceRate: ExpenseDistanceRate;
    onEdit: (distanceRate: ExpenseDistanceRate) => void;
    onDelete: (distanceRate: ExpenseDistanceRate) => void;
};

const ActionMenuRenderer: FC<ActionMenuRendererProps> = ({ distanceRate, onEdit, onDelete }) => {
    const { t } = useTranslation();

    return (
        <BasicMenu
            items={[
                {
                    title: t('general.edit'),
                    onClick: () => onEdit(distanceRate),
                },
                {
                    title: t('general.delete'),
                    onClick: () => onDelete(distanceRate),
                },
            ]}
        />
    );
};
