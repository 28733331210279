import { Box, Stack, StackProps, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { CloseObjectiveDialog } from './CloseObjectiveDialog';
import { ObjectiveItem } from './ObjectiveItem';

import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { useBreakPoints } from '@/components/use-break-points/useBreakPoints';
import { Objective, ObjectiveCloseMutation } from '@/domain/objective/Objective.model';
import { archiveObjective, closeObjective, deleteObjective, reopenObjective, unarchiveObjective } from '@/domain/objective/Objective.service';
import { ObjectiveHistoryDialog } from '@/page/objective/objective-history-dialog/ObjectiveHistoryDialog';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

type Props = {
    objectives: Objective[];
    displayWeight: boolean;
    onSuccess: () => void;
    onEditObjective: (id: number) => void;
    editable?: boolean;
    disabled?: boolean;
    showAssignee?: boolean;
    displayAvatarsMobile: boolean;
} & StackProps;

export const ObjectivesList: FC<Props> = ({
    objectives = [],
    displayWeight,
    onSuccess,
    onEditObjective,
    displayAvatarsMobile,
    editable = true,
    disabled = false,
    showAssignee = true,
    ...rest
}) => {
    const { isMobile } = useBreakPoints();
    const { t } = useTranslation();

    const [closeObjectiveDialogOpen, setCloseObjectiveDialogOpen] = useState<boolean>(false);
    const [activeObjective, setActiveObjective] = useState<Objective>();
    const [objectiveToOpenInDetailView, setObjectiveToOpenInDetailView] = useState<Objective>();
    const [currentObjectiveToDeleteId, setCurrentObjectiveToDeleteId] = useState<number>();

    const handleDeleteConfirmed = async (id: number) => {
        try {
            await deleteObjective(id);
        } catch (e) {
            handleError(e);
        } finally {
            onSuccess();
            setCurrentObjectiveToDeleteId(undefined);
        }
    };

    const onCompleteObjective = (id: number) => (data: ObjectiveCloseMutation) => {
        closeObjective(id, data)
            .then(() => {
                showSnackbar(t('objectives.messages.closed'), 'success');
                setCloseObjectiveDialogOpen(false);
                onSuccess();
            })
            .catch(handleError);
    };

    const onReopenObjective = (id: number) => {
        reopenObjective(id)
            .then(() => {
                showSnackbar(t('objectives.messages.reopened'), 'success');
                onSuccess();
            })
            .catch(handleError);
    };

    const onArchiveObjective = async (id: number) => {
        try {
            await archiveObjective(id);
            showSnackbar(t('objectives.messages.archived'), 'success');
            onSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    const onUnarchiveObjective = async (id: number) => {
        try {
            await unarchiveObjective(id);
            showSnackbar(t('objectives.messages.unarchived'), 'success');
            onSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Box overflow={'auto'}>
            <Stack direction='column' p={{ xs: 0, md: 2 }} minWidth={'800px'} {...rest}>
                {!isMobile && (
                    <Stack>
                        <Stack gap={1} alignItems={'center'} direction={'row'} p={1} py={0}>
                            <Stack flex={3}>
                                <Typography variant='body1bold'>{t('objectives.table.objective')}</Typography>
                            </Stack>
                            {showAssignee && (
                                <Stack
                                    style={{
                                        justifyContent: 'center',
                                    }}
                                    flex={1}
                                >
                                    <Typography variant='body1bold' alignItems={'center'}>
                                        {t('objectives.table.assignee')}
                                    </Typography>
                                </Stack>
                            )}
                            <Stack
                                flex={1}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant='body1bold'>{t('objectives.table.deadline')}</Typography>
                            </Stack>
                            <Stack
                                flex={1}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant='body1bold'>{t('objectives.table.updated_on')}</Typography>
                            </Stack>
                            <Stack
                                flex={1}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant='body1bold'>{t('objectives.table.status')}</Typography>
                            </Stack>
                            <Stack
                                style={{
                                    width: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            />
                        </Stack>
                        <Divider sx={{ width: '100%', marginTop: '8px' }} />
                    </Stack>
                )}
                <Stack divider={<Divider />}>
                    {objectives.map(objective => (
                        <ObjectiveItem
                            key={`objective_${objective.id}_${objective.name}_${objective.completedAt?.toString()}`}
                            objective={objective}
                            displayWeight={displayWeight}
                            onCloseObjective={() => {
                                setActiveObjective(objective);
                                setCloseObjectiveDialogOpen(true);
                            }}
                            onDelete={() => setCurrentObjectiveToDeleteId(objective.id)}
                            onEdit={onEditObjective}
                            onReopen={onReopenObjective}
                            onArchive={onArchiveObjective}
                            onUnarchive={onUnarchiveObjective}
                            editable={editable}
                            onOpenDetailClick={() => setObjectiveToOpenInDetailView(objective)}
                            showUsersMobile={!displayAvatarsMobile}
                            disabled={disabled}
                            showAssignee={showAssignee}
                        />
                    ))}
                </Stack>
            </Stack>
            {closeObjectiveDialogOpen && activeObjective?.id && (
                <CloseObjectiveDialog
                    onComplete={onCompleteObjective(activeObjective.id)}
                    onClose={() => {
                        setActiveObjective(undefined);
                        setCloseObjectiveDialogOpen(false);
                    }}
                />
            )}

            {objectiveToOpenInDetailView && (
                <ObjectiveHistoryDialog
                    open={true}
                    objectiveId={objectiveToOpenInDetailView.id}
                    displayWeight={displayWeight}
                    onClose={() => {
                        setObjectiveToOpenInDetailView(undefined);
                        onSuccess();
                    }}
                />
            )}

            {!!currentObjectiveToDeleteId && (
                <ConfirmDialog
                    open={true}
                    title={t('objectives.are_you_sure_delete_objective')}
                    onClose={() => setCurrentObjectiveToDeleteId(undefined)}
                    onConfirm={() => handleDeleteConfirmed(currentObjectiveToDeleteId)}
                />
            )}
        </Box>
    );
};
