import { Paper, Stack } from '@mui/material';
import { FC } from 'react';

import { SquareButton } from '@/components/square-button/SquareButton';
import { ClampedTypography } from '@/components/typography/ClampedTypography';
import { SurveyAnswerForm, SurveyQuestionForm } from '@/page/survey/SurveyForm.util';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

type Props = {
    onSelect: (value: string) => void;
    question: SurveyQuestionForm;
    answer: SurveyAnswerForm;
    selectedLanguage: UserLanguage;
};

export const SurveyAnswerSingleChoice: FC<Props> = ({ onSelect, question, answer, selectedLanguage }) => {
    const isSingleChoiceSelected = (option: string) => {
        if (!question && !answer) {
            return true;
        }
        return answer?.items?.some(item => item.localId === option);
    };

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Stack component={Paper} p={2} alignItems={'center'} justifyContent={'center'} gap={2}>
                <ClampedTypography
                    variant='h1'
                    ellipsis={2}
                    minWidth={{
                        xs: 250,
                        sm: 500,
                    }}
                >
                    {getLabelTranslation(question.question, selectedLanguage)}
                </ClampedTypography>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)', // Two equal columns
                        gap: 2,
                        justifyContent: 'center',
                        alignItems: 'stretch', // Ensures all items stretch in their grid cell
                        gridAutoRows: '1fr', // Makes all rows the same height based on the tallest one
                    }}
                >
                    {question.items?.map(item => (
                        <SquareButton
                            key={item.localId}
                            checked={isSingleChoiceSelected(item.localId)}
                            onClick={() => onSelect(item.localId)}
                            type='radio'
                            description={
                                <Stack gap={0.5} alignItems={'center'} justifyContent={'space-between'} flex={1}>
                                    <Box>
                                        {item.imageUrl && (
                                            <Box component='img' src={item.imageUrl} alt='Icon' width='200px' height='160px' sx={{ objectFit: 'contain' }} />
                                        )}
                                    </Box>
                                    <Stack alignItems={'center'} justifyContent={'center'} justifySelf={'flex-end'}>
                                        <ClampedTypography ellipsis={7}>{getLabelTranslation(item.label, selectedLanguage)}</ClampedTypography>
                                    </Stack>
                                </Stack>
                            }
                            sx={{
                                width: '320px',
                                minHeight: '120px',
                                height: '100%', // Ensures equal height
                            }}
                        />
                    ))}
                </Box>
            </Stack>
        </Slide>
    );
};
