import { isDraggableItem } from '@/components/dnd/DraggableItem.util';
import { useEffect } from 'react';

import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';

export const useReorderOnDrop = <T extends { id: string | number }>({
    list,
    onChange,
    keyId,
    axis,
}: {
    list: T[];
    onChange: (list: T[]) => void;
    keyId: string;
    axis: 'horizontal' | 'vertical';
}): void => {
    useEffect(() => {
        return monitorForElements({
            // To watch for drag events on the whole page and save new position
            onDrop({ location, source }) {
                const target = location.current.dropTargets.at(0);

                if (!target) {
                    return;
                }

                const sourceData = source.data;
                const targetData = target.data;

                if (!isDraggableItem(sourceData) || !isDraggableItem(targetData)) {
                    return;
                }

                const indexOfSource = list.findIndex(item => item.id?.toString() === sourceData.id?.toString());
                const indexOfTarget = list.findIndex(item => item.id?.toString() === targetData.id?.toString());

                if (indexOfTarget < 0 || indexOfSource < 0) {
                    return;
                }

                const closestEdgeOfTarget = extractClosestEdge(targetData);

                const reorderedList = reorderWithEdge({
                    list,
                    startIndex: indexOfSource,
                    indexOfTarget,
                    closestEdgeOfTarget,
                    axis,
                });

                onChange(reorderedList);

                // Being simple and just querying for the task after the drop.
                // We could use react context to register the element in a lookup,
                // and then we could retrieve that element after the drop and use
                // `triggerPostMoveFlash`. But this gets the job done.
                const element = document.querySelector(`[${keyId}="${sourceData.id?.toString()}"]`);
                if (element instanceof HTMLElement) {
                    triggerPostMoveFlash(element);
                }
            },
        });
    }, [axis, keyId, list, onChange]);
};
