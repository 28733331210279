import { UserLanguage } from '@/utils/language.util';

export enum RealmFeaturesType {
    ADVANCED_EMPLOYEE_PROFILE = 'ADVANCED_EMPLOYEE_PROFILE',
    LEAVES = 'LEAVES',
    DOCUMENTS = 'DOCUMENTS',
    TIMESHEET = 'TIMESHEET',
    PLANNING = 'PLANNING',
    SURVEYS = 'SURVEYS',
    REVIEWS = 'REVIEWS',
    PAYROLL = 'PAYROLL',
    CLOCK_IN_OUT = 'CLOCK_IN_OUT',
    REPORTS = 'REPORTS',
    OBJECTIVES = 'OBJECTIVES',
    COST_CENTERS = 'COST_CENTERS',
    THIRD_PARTIES = 'THIRD_PARTIES',
    JOB_FAMILIES = 'JOB_FAMILIES',
    EXPENSES = 'EXPENSES',
    BETA = 'BETA',
}

export type RealmFeature = {
    id: number;
    feature: RealmFeaturesType;
    enabled: boolean;
};
export type Realm = {
    name: string;
    realmStatus: RealmStatus;
    availableLoginMethods: LoginMethod[];
    tenantId: string;
    defaultLanguage: UserLanguage;
    defaultTimezone: string;
    realmFeatures: RealmFeature[];
    reverseDisplayName: boolean;
};

export type LoginMethod = {
    id: number;
    name: string;
    type: LoginMethodType;
};

export enum LoginMethodType {
    STANDARD = 'STANDARD',
    MICROSOFT = 'MICROSOFT',
    GOOGLE = 'GOOGLE',
    OPENID = 'OPENID',
    OKTA = 'OKTA',
}

export enum RealmStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    WAITING_FOR_ACTIVATION = 'WAITING_FOR_ACTIVATION',
}

export type RealmUpdateMutation = {
    name: string;
    realmStatus: RealmStatus;
    availableLoginMethods: LoginMethod[];
    defaultLanguage: UserLanguage;
    defaultTimezone: string;
    enabledFeatures: RealmFeaturesType[];
};
