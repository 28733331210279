import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SectionContainer } from '../SectionContainer';
import { LeavesTypeSection } from './LeavesTypesSection';
import { LeaveType, LeaveTypePolicyAssignRequest } from '@/domain/leave-type/LeaveType.model';

export type LeaveTypeFormValues = {
    leaveTypeAssignments: LeaveTypePolicyAssignRequest[];
};

type OnBoardingLeavesFormProps = {
    onSubmitLeavesForm: (data: LeaveTypeFormValues) => void;
    leavesFormValues?: LeaveTypeFormValues;
    leaveTypes: LeaveType[];
};
export const LeavesForm: FC<OnBoardingLeavesFormProps> = ({ onSubmitLeavesForm, leavesFormValues, leaveTypes }) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        leaveTypeAssignments: yup.array().of(
            yup.object().shape({
                leaveTypeId: yup.number().required(),
                leaveTypePolicyId: yup.number().nullable().notRequired(),
            }),
        ),
    });

    const formMethods = useForm<LeaveTypeFormValues>({
        // TODO: fix this type
        resolver: yupResolver(schema) as Resolver<LeaveTypeFormValues>,
        defaultValues: leavesFormValues ?? {
            leaveTypeAssignments:
                leaveTypes?.map(l => ({
                    leaveTypeId: l.id,
                    leaveTypePolicyId: l.policies?.[0]?.id,
                })) ?? [],
        },
    });

    return (
        <SectionContainer title={t('onboarding_form.default')}>
            <Stack>
                <form id='leaves-form' onSubmit={formMethods.handleSubmit(onSubmitLeavesForm, console.error)}>
                    <FormProvider {...formMethods}>
                        <LeavesTypeSection leaveTypes={leaveTypes} />
                    </FormProvider>
                </form>
            </Stack>
        </SectionContainer>
    );
};
