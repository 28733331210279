import { BooleanFilter as BooleanFilterType } from '@/components/filters-bar/FilterBar.type';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import { FC } from 'react';

export const BooleanFilter: FC<{ filter: BooleanFilterType; onFilterUpdated: (filter: BooleanFilterType) => void }> = ({ filter, onFilterUpdated }) => {
    return (
        <Stack p={1}>
            <FormControlLabel
                control={<Switch checked={filter.value ?? false} onChange={e => onFilterUpdated({ ...filter, value: e.target.checked })} />}
                label={filter.filterName}
                labelPlacement={'end'}
            />
        </Stack>
    );
};
