import { getLeaveTypeById, getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { createQueryHook, createRequiredParamsQueryHook } from '@/page/Query.type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getNull } from '@/utils/object.util';

export const leaveTypeKeys = createQueryKeys('leaveTypes', {
    get: {
        queryKey: getNull(),
    },
    getById: (...params: Parameters<typeof getLeaveTypeById>) => ({
        queryKey: params,
    }),
});

export const useGetLeaveTypes = createQueryHook(leaveTypeKeys.get, getLeaveTypes);

export const useGetLeaveTypeById = createRequiredParamsQueryHook(leaveTypeKeys.getById, getLeaveTypeById);
