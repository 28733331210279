import { CountrySelect } from '@/components/country-select/CountrySelect';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldText } from '@/components/form/field-text/FieldText';
import { EditableDocumentField } from '@/components/section/SectionFieldComponent/EditableDocumentField/EditableDocumentField';
import { SectionField } from '@/components/section/types';
import { CustomList } from '@/domain/custom-list/CustomList.model';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { deleteWhitespace, fixPhoneCallingCode, formatIBAN } from '@/utils/strings.util';
import { FieldPhoneNumber } from '@/components/form/field-phone-number/FieldPhoneNumber';

export type EditableSectionFieldComponentProps = {
    field: SectionField;
};

export const EditableSectionFieldComponent: FC<EditableSectionFieldComponentProps> = ({ field }) => {
    if (!field?.formValueName) {
        return;
    }

    const commonProps = {
        formValueName: field.formValueName,
        disabled: field.disabled,
    };

    switch (field.valueType) {
        case 'CUSTOM_LIST_ITEM':
        case 'CUSTOM_MULTI_LIST_ITEM':
            return (
                <EditableCustomListItem
                    fieldType={field.valueType}
                    values={field.customListItemReferences?.map(item => item.id) ?? []}
                    customList={field.customList}
                    {...commonProps}
                />
            );

        case 'ENUM':
            return <EditableEnumListItem value={(field.stringValue ?? '') || getNull()} enumOptions={field.enumList} {...commonProps} />;
        case 'DATE':
            return <EditableDateField value={(field.dateValue ?? '') || getNull()} {...commonProps} />;
        case 'NUMBER':
            return <EditableNumberField value={field.numberValue?.toString()} {...commonProps} />;
        case 'AUTO_INCREMENT':
            return <EditableNumberField value={field.stringValue} precision={0} {...commonProps} />;
        case 'STRING':
        case 'AVS_NUMBER':
        case 'EMAIL':
            return <EditableTextField value={field.stringValue ?? ''} {...commonProps} />;
        case 'PHONE_NUMBER':
            // we delete all whitespaces to handle data incorrectly formatted from the DB
            // after validation, we send the phone number with the correct format
            return <EditablePhoneNumberField value={deleteWhitespace(field.stringValue ?? '')} {...commonProps} />;
        case 'IBAN_NUMBER':
            return <EditableTextField value={formatIBAN(field.stringValue ?? '')} {...commonProps} />;
        case 'COUNTRY':
            return <CountrySelect countryValue={field.countryValue ?? getNull()} {...commonProps} />;
        case 'SECTION_FIELD_DOCUMENT':
            return <EditableDocumentField documentsValue={field.documents ?? []} {...commonProps} />;
        default:
            return undefined;
    }
};

type EditableDateFieldProps = {
    value: LocalDate | null;
    formValueName: string;
    disabled?: boolean;
};

const EditableDateField: FC<EditableDateFieldProps> = ({ value, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableDateFieldProps['value']>>();

    return <FieldLocalDate control={control} name={formValueName} disabled={disabled} defaultValue={value} />;
};

type EditableTextFieldProps = {
    value: string | null;
    formValueName: string;
    disabled?: boolean;
};

const EditableTextField: FC<EditableTextFieldProps> = ({ value, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableTextFieldProps['value']>>();

    return <FieldText name={formValueName} control={control} defaultValue={value ?? ''} fullWidth variant='outlined' disabled={disabled} />;
};

type EditableNumberFieldProps = {
    value: string | undefined;
    formValueName: string;
    disabled?: boolean;
    precision?: number;
};
const EditableNumberField: FC<EditableNumberFieldProps> = ({ value, formValueName, disabled, precision }) => {
    const { control } = useFormContext<Record<string, EditableNumberFieldProps['value']>>();

    return (
        <FieldNumber
            control={control}
            name={formValueName}
            defaultValue={value ?? ''}
            precision={precision ?? 2}
            inputNumberProps={{
                inputProps: { 'aria-label': formValueName },
            }}
            disabled={disabled}
            fullWidth
        />
    );
};

type EditablePhoneNumberFieldProps = {
    value: string | null;
    formValueName: string;
    disabled?: boolean;
};
const EditablePhoneNumberField: FC<EditablePhoneNumberFieldProps> = ({ value, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableTextFieldProps['value']>>();
    // fix the phone number by adding the default calling code if it's missing
    const fixedPhoneNumber = fixPhoneCallingCode(value ?? '');

    return <FieldPhoneNumber name={formValueName} control={control} defaultValue={fixedPhoneNumber} fullWidth disabled={disabled} />;
};

type EditableCustomListItemProps = {
    fieldType: 'CUSTOM_LIST_ITEM' | 'CUSTOM_MULTI_LIST_ITEM';
    customList: CustomList | undefined;
    values: number[];
    formValueName: string;
    disabled?: boolean;
};

const EditableCustomListItem: FC<EditableCustomListItemProps> = ({ fieldType, customList, values, formValueName, disabled }) => {
    const nonArchivedItems = customList?.items.filter(item => !item.archived) ?? [];

    // available options does not contains archived items
    const options: number[] = nonArchivedItems.map(item => item.id);

    // get option label search in the whole list (including archived items)
    // to handle the case where the item was selected but archived now
    const getOptionLabel = (option: number): string => {
        const label = customList?.items.find(item => item.id === option)?.label;
        return getLabelTranslation(label);
    };

    if (fieldType === 'CUSTOM_LIST_ITEM') {
        return (
            <EditableCustomSingleListItem
                options={options}
                value={values[0]}
                getOptionLabel={getOptionLabel}
                formValueName={formValueName}
                disabled={disabled}
            />
        );
    } else {
        return (
            <EditableCustomMultiListItem options={options} value={values} getOptionLabel={getOptionLabel} formValueName={formValueName} disabled={disabled} />
        );
    }
};

type EditableCustomSingleListItemProps = Pick<EditableCustomListItemProps, 'formValueName' | 'disabled'> & {
    value: number | undefined;
    options: number[];
    getOptionLabel: (option: number) => string;
};
const EditableCustomSingleListItem: FC<EditableCustomSingleListItemProps> = ({ options, value, getOptionLabel, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, number>>();

    return (
        <FieldSelect
            name={formValueName}
            control={control}
            defaultValue={value}
            fullWidth
            disabled={disabled}
            options={options}
            getOptionLabel={getOptionLabel}
        />
    );
};

type EditableCustomMultiListItemProps = StrictOverwrite<EditableCustomSingleListItemProps, { value: number[] }>;
const EditableCustomMultiListItem: FC<EditableCustomMultiListItemProps> = ({ options, value: defaultValues, getOptionLabel, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, number[]>>();

    return (
        <FieldSelect
            name={formValueName}
            control={control}
            defaultValue={defaultValues}
            fullWidth
            disabled={disabled}
            options={options}
            getOptionLabel={getOptionLabel}
            multiple
        />
    );
};
type EditableEnumListItemProps = {
    enumOptions: { value: string; label: string }[] | undefined;
    value: string | null;
    formValueName: string;
    disabled?: boolean;
};
const EditableEnumListItem: FC<EditableEnumListItemProps> = ({ enumOptions = [], value: defaultValue, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableEnumListItemProps['value']>>();
    const options = enumOptions.map(enumOption => enumOption.value);

    return (
        <FieldSelect
            name={formValueName}
            control={control}
            // Empty string is not a valid value for enum fields
            defaultValue={defaultValue ?? getNull()}
            fullWidth
            disabled={disabled}
            options={options}
            getOptionLabel={enumValue => enumOptions.find(enumOption => enumOption.value === enumValue)?.label ?? ''}
        />
    );
};
