import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { canManageOtherEmployeePayrollLock, hasConfigurePayrollPolicy } from '@/domain/permission/Permission.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { Page } from '@/page/Pages';

export const getPayrollPages = (policies: EmployeePolicy[], employeeId: number): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'payroll.tabs.leaves',
            path: '/payroll/leaves',
            condition: () => hasRealmFeatureEnabled(RealmFeaturesType.LEAVES) && hasConfigurePayrollPolicy(policies),
        },
        {
            labelKey: 'payroll.tabs.profile_changes',
            path: '/payroll/profile-changes',
            condition: () => hasConfigurePayrollPolicy(policies),
        },
        {
            labelKey: 'payroll.tabs.timesheet_payments',
            path: '/payroll/timesheet-payments',
            condition: () => hasRealmFeatureEnabled(RealmFeaturesType.TIMESHEET),
        },
        {
            labelKey: 'payroll.tabs.employees_locks',
            path: '/payroll/locks',
            condition: () => canManageOtherEmployeePayrollLock(policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(policies));
};
