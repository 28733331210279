import * as yup from 'yup';
import i18next from 'i18next';
import { FC } from 'react';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FieldText } from '@/components/form/field-text/FieldText';

const getThirdPartyNewEmailSchema = () => {
    return yup.object({
        email: yup.string().email(i18next.t('general.forms.invalid_email')).required(),
    });
};

export type ThirdPartyNewEmailFormValues = yup.InferType<ReturnType<typeof getThirdPartyNewEmailSchema>>;

export const ThirdPartyEmailDialog: FC<
    DialogWrapperProps & {
        onSave: (email: ThirdPartyNewEmailFormValues) => void;
    }
> = ({ onSave, ...rest }) => {
    const { t } = useTranslation();

    const { control: controlNewEmail, handleSubmit: handleSubmitNewEmail } = useForm<ThirdPartyNewEmailFormValues>({
        resolver: yupResolver(getThirdPartyNewEmailSchema()),
        defaultValues: {
            email: '',
        },
    });

    return (
        <DialogWrapper header={t('third_party_setting_page.dialog.monitoring_email_dialog_title')} {...rest}>
            <Stack component={DialogContent} gap={2}>
                <FormControlLabel
                    aria-label={'third-party-email'}
                    label={t('third_party_setting_page.dialog.monitoring_email_dialog_email')}
                    control={<FieldText name='email' control={controlNewEmail} fullWidth />}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmitNewEmail(onSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
