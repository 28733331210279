import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { ExpenseTax } from '@/domain/expense-tax/ExpenseTax.model';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';

type ExpenseTaxDialogProps = {
    onSave: (data: ExpenseTaxFormValues) => void;
    tax: ExpenseTax | undefined;
} & DialogWrapperProps;

export const ExpenseTaxDialog: FC<ExpenseTaxDialogProps> = ({ open, onSave, onClose, tax }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<ExpenseTaxFormValues>({
        resolver: yupResolver(getTaxSchema()),
        defaultValues: {
            value: tax?.value,
        },
    });

    const handleOnSave = (data: ExpenseTaxFormValues) => {
        onSave(data);
    };

    const title = tax ? t('expense_settings_page.dialog.edit_title') : t('expense_settings_page.dialog.add_title');

    return (
        <DialogWrapper header={title} open={open} onClose={onClose}>
            <Stack component={DialogContent}>
                <FormControlLabel
                    aria-label={'tax-value'}
                    label={t('expense_settings_page.dialog.tax_input')}
                    control={<FieldNumber name={'value'} control={control} fullWidth precision={2} />}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getTaxSchema = () => {
    return yup.object().shape({
        value: yup.number().required().min(0).max(100),
    });
};

export type ExpenseTaxFormValues = yup.InferType<ReturnType<typeof getTaxSchema>>;
