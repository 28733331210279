// generate function like Job.api.ts
import { DocumentTag, DocumentTagMutation, DocumentTagSearch } from '@/domain/document-tag/DocumentTag.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';

export type DocumentTagDTO = DocumentTag;
export type DocumentTagRequestDTO = DocumentTagMutation;

const searchDocumentTags = async (search: DocumentTagSearch): Promise<DocumentTag[]> => {
    const { data } = await client.post<DocumentTagDTO[], AxiosResponse<DocumentTagDTO[]>, DocumentTagSearch>(API_BASE_URL + `/document-tags/search`, search);
    return data;
};

const createDocumentTag = async (mutation: DocumentTagMutation): Promise<DocumentTag> => {
    const { data } = await client.post<DocumentTagDTO, AxiosResponse<DocumentTagDTO>, DocumentTagRequestDTO>(API_BASE_URL + `/document-tags`, mutation);
    return data;
};

const updateDocumentTag = async (tagId: number, mutation: DocumentTagMutation): Promise<DocumentTag> => {
    const { data } = await client.put<DocumentTagDTO, AxiosResponse<DocumentTagDTO>, DocumentTagRequestDTO>(API_BASE_URL + `/document-tags/${tagId}`, mutation);
    return data;
};

const deleteDocumentTag = async (tagId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/document-tags/${tagId}`);
};

export const documentTagAPI = {
    searchDocumentTags,
    createDocumentTag,
    updateDocumentTag,
    deleteDocumentTag,
};
