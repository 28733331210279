import { SearchField } from '@/components/search-field/SearchField';
import { containsIgnoreCase } from '@/utils/strings.util';
import { EmployeeLeaveTypePolicy, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { Divider, List, ListItem, Paper, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelTranslation } from '@/utils/language.util';

type LeaveRequestDialogLeaveTypeStepProps = {
    employeeLeaveTypePolicies: EmployeeLeaveTypePolicy[];
    onLeaveTypeSelected: (leaveType: LeaveType) => void;
};

export const SelectLeaveType: FC<LeaveRequestDialogLeaveTypeStepProps> = ({ employeeLeaveTypePolicies, onLeaveTypeSelected }) => {
    const [employeeLeaveTypes, setEmployeeLeaveTypes] = useState<EmployeeLeaveTypePolicy[]>(employeeLeaveTypePolicies);
    const { t } = useTranslation();

    const handleLeaveTypeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        const filteredEmployeeLeaveTypes = event.target.value
            ? employeeLeaveTypePolicies.filter(empLeaveType => containsIgnoreCase(getLabelTranslation(empLeaveType?.leaveType?.name), event?.target?.value))
            : employeeLeaveTypePolicies;
        setEmployeeLeaveTypes(filteredEmployeeLeaveTypes);
    };

    const sortedLeaveTypeHistories = [...employeeLeaveTypes];

    sortedLeaveTypeHistories?.sort((a, b) => {
        return a.leaveType.order - b.leaveType.order;
    });

    const nonDuplicatedSortedLeaveTypeHistories = sortedLeaveTypeHistories.filter(
        (leaveType, index, self) => index === self.findIndex(lth => lth.leaveType.id === leaveType.leaveType.id),
    );
    const working = nonDuplicatedSortedLeaveTypeHistories.filter(leaveTypeHistory => leaveTypeHistory.leaveType.leaveActivityType === 'WORKING');
    const nonWorking = nonDuplicatedSortedLeaveTypeHistories.filter(leaveTypeHistory => leaveTypeHistory.leaveType.leaveActivityType !== 'WORKING');

    return (
        <Stack spacing={1}>
            <SearchField onChange={handleLeaveTypeFilter} label={t('request_leave_dialog.leave_type')} />

            <DisplayLeaveTypes
                employeeLeaveTypePolicies={working}
                onLeaveTypeSelected={onLeaveTypeSelected}
                title={t('request_leave_dialog.working_leave_types')}
            />
            <DisplayLeaveTypes
                employeeLeaveTypePolicies={nonWorking}
                onLeaveTypeSelected={onLeaveTypeSelected}
                title={t('request_leave_dialog.non_working_leave_types')}
            />
        </Stack>
    );
};

type DisplayLeaveTypesProps = {
    employeeLeaveTypePolicies: EmployeeLeaveTypePolicy[];
    onLeaveTypeSelected: (leaveType: LeaveType) => void;
    title: string;
};

const DisplayLeaveTypes: FC<DisplayLeaveTypesProps> = ({ employeeLeaveTypePolicies, onLeaveTypeSelected, title }) => {
    if (!employeeLeaveTypePolicies || employeeLeaveTypePolicies.length === 0) {
        return;
    }

    return (
        <Stack paddingTop={1}>
            <Typography paddingBottom={1} variant='body2bold'>
                {title}
            </Typography>
            <Paper
                sx={theme => ({
                    borderRadius: 1,
                    border: `1px solid ${theme.palette.grey[300]}`,
                })}
            >
                <List sx={{ p: 0 }}>
                    {employeeLeaveTypePolicies?.map((employeeLeaveType: EmployeeLeaveTypePolicy) => {
                        return (
                            <Fragment key={employeeLeaveType.leaveType.id}>
                                <ListItem
                                    dense
                                    onClick={() => {
                                        onLeaveTypeSelected(employeeLeaveType.leaveType);
                                    }}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: theme => theme.palette.grey[50],
                                        },
                                        py: 2,
                                    }}
                                    aria-label={getLabelTranslation(employeeLeaveType.leaveType.name)}
                                >
                                    <Typography variant='body1'>{getLabelTranslation(employeeLeaveType.leaveType.name)}</Typography>
                                </ListItem>
                                <Divider />
                            </Fragment>
                        );
                    })}
                </List>
            </Paper>
        </Stack>
    );
};
