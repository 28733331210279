import { FC } from 'react';
import { ExpenseCategoryGroup } from '@/domain/expense-category-group/ExpenseCategoryGroup.model';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import * as yup from 'yup';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { useTranslatableLabelInput } from '@/components/translatable-label-input/useTranslatableLabelInput';
import { useCreateExpenseCategoryGroupMutation, useUpdateExpenseCategoryGroupMutation } from '@/hooks/expense-category-group/ExpenseCategoryGroup.hook';
import { handleError } from '@/utils/api.util';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';

type ExpenseCategoryGroupDialogProps = {
    expenseCategoryGroup: ExpenseCategoryGroup | undefined;
    onSuccess: () => void;
} & DialogWrapperProps;

export const ExpenseCategoryGroupDialog: FC<ExpenseCategoryGroupDialogProps> = ({ expenseCategoryGroup, onSuccess, ...restDialog }) => {
    const { t } = useTranslation();
    const { translationLanguage, onLanguageChange } = useTranslatableLabelInput();

    const { mutateAsync: createExpenseCategoryGroupMutate, isPending: isCreatePending } = useCreateExpenseCategoryGroupMutation();
    const { mutateAsync: updateExpenseCategoryGroupMutate, isPending: isUpdatePending } = useUpdateExpenseCategoryGroupMutation();

    const handleSave = async ({ name }: ExpenseCategoryGroupFormValues) => {
        try {
            if (expenseCategoryGroup) {
                await updateExpenseCategoryGroupMutate({ id: expenseCategoryGroup.id, name });
            } else {
                await createExpenseCategoryGroupMutate({ name });
            }
            showSnackbar(t('general.success_saved'), 'success');
            onSuccess();
        } catch (e) {
            handleError(e);
        }
    };

    const { control, handleSubmit } = useForm<ExpenseCategoryGroupFormValues>({
        resolver: yupResolver(getExpenseCategoryGroupSchema(translationLanguage)),
        values: expenseCategoryGroup
            ? {
                  name: expenseCategoryGroup.name,
              }
            : undefined,
        defaultValues: {
            name: createDefaultLabel(),
        },
    });

    const DialogHeader = (
        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'} flex={1}>
            <Typography variant='body1bold'>{t('expense_category_group_settings_page.category_group_dialog.title')}</Typography>
            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={onLanguageChange} />
        </Stack>
    );

    return (
        <DialogWrapper header={DialogHeader} {...restDialog}>
            <DialogContent>
                <FieldLabel control={control} language={translationLanguage} name={'name'} fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)} loading={isCreatePending || isUpdatePending} fullWidth={true}>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getExpenseCategoryGroupSchema = (translationLanguage: UserLanguage) =>
    yup.object().shape({
        name: getLabelFormSchema(translationLanguage).required(),
    });

type ExpenseCategoryGroupFormValues = yup.InferType<ReturnType<typeof getExpenseCategoryGroupSchema>>;
