import cc from 'currency-codes';

export const getCurrencyLabel = (code: string): string => {
    const currencyDetails = cc.code(code);
    return currencyDetails ? `${code} - ${currencyDetails.currency}` : code;
};

export const isValidCurrencyCode = (code: string): boolean => {
    return !!cc.code(code);
};
